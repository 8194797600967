import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerReadingsModel, buildAPIGetLoggerReadingsModel } from "models/APIGetLoggerReadingsModel";
import { APIGetLoggerAlarmLevelModel, buildAPIGetLoggerAlarmLevelModel } from "models/APIGetLoggerAlarmLevelModel";
// DevExtreme
import { DataGrid, Column, Export, Scrolling, FilterRow, StateStoring, Pager, Paging, HeaderFilter, Format } from 'devextreme-react/data-grid';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

import IconButton from '@mui/material/IconButton';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';


//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import { withStyles, createStyles } from '@mui/styles';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';

import ReadingTooltip from 'components/Readings/ReadingTooltip';
import Button from '@mui/material/Button';

//DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Chart, { CommonSeriesSettings, ArgumentAxis, Series, Legend, Aggregation, ValueAxis, ZoomAndPan, ScrollBar, Point, ValueErrorBar, Tooltip, Crosshair, Label } from 'devextreme-react/chart';
import DateBox from 'devextreme-react/date-box';
import ChannelFilter from "./ChannelFilter";
import { User } from "oidc-client";
import { Last } from "react-bootstrap/esm/PageItem";
import dxDataGrid from 'devextreme/ui/data_grid';

const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serials?: string[];
    site?: number;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    channels: string[];
    channelFilters: boolean[];
    filterValue: Array<string | string[]>;
    startDate: Date;
    endDate: Date;
    maxValue: number[];
    minValue: number[];
    readingsData: Array<APIGetLoggerReadingsModel>;
    graphData: Array<
        {
            date: Date;
            channel: string;
            readingA: number | null;
            readingAset: number | null;
            readingAclr: number | null;
            readingB: number | null;
            readingBset: number | null;
            readingBclr: number | null;
            readingC: number | null;
            readingCmax: number | null;
            readingCmin: number | null;
            readingCset: number | null;
            readingCclr: number | null;
            readingD: number | null;
            readingDmax: number | null;
            readingDmin: number | null;
            readingDset: number | null;
            readingDclr: number | null;
            readingE: number | null;
            readingEmax: number | null;
            readingEmin: number | null;
            readingEset: number | null;
            readingEclr: number | null;
          }
    > | undefined;
    showAlarmLevels: boolean;
    btnAlarmLevels: string;
}

class ReadingsPanel extends React.Component<Props, State> {

    gridRef: React.RefObject<DataGrid>;

    constructor(props: Readonly<Props>) {
        super(props);
        this.gridRef = React.createRef();
        this.state = {
            loading: true,
            tableHidden: true,
            maxValue: [0,0,0,0,0],
            minValue: [Number.MAX_VALUE,Number.MAX_VALUE,Number.MAX_VALUE,Number.MAX_VALUE,Number.MAX_VALUE],
            channels: [],
            channelFilters: [true, true, true, true, true],
            filterValue: [],
            startDate: moment().subtract(2, 'days').toDate(),
            endDate: moment().toDate(),
            readingsData: [],
            graphData: undefined,
            showAlarmLevels: true,
            btnAlarmLevels: "Hide Alarm levels",
        };

    }
    //get dataGrid(): dxDataGrid | undefined {
    //    return this.gridRef.current?.instance;
    //}

    componentDidMount(): void {


        //get alarms for logger here
        this.reloadData(this.state.startDate, this.state.endDate);

    }

    reloadData(start: Date, end: Date): void {
        let readingsData = new Array<APIGetLoggerReadingsModel>();
        const graphData = new Array<
            {
                date: Date;
                channel: string;
                readingA: number | null;
                readingAset: number | null;
                readingAclr: number | null;
                readingB: number | null;
                readingBset: number | null;
                readingBclr: number | null;
                readingC: number | null;
                readingCmax: number | null;
                readingCmin: number | null;
                readingCset: number | null;
                readingCclr: number | null;
                readingD: number | null;
                readingDmax: number | null;
                readingDmin: number | null;
                readingDset: number | null;
                readingDclr: number | null;
                readingE: number | null;
                readingEmax: number | null;
                readingEmin: number | null;
                readingEset: number | null;
                readingEclr: number | null;
            }
            >();

        const channels = ["","","","",""];
        const maxValue = [0,0,0,0,0];
        const minValue = [Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE];

        let alarmLevels = new Array<APIGetLoggerAlarmLevelModel>();

        //get alarms for logger here
        const me = this;
        this.setState({ loading: true });

        if (me.props.serials != undefined) {

            const promises = new Array<Promise<any>>();
            // generate array of Promises each adding the readingsData for a logger
            me.props.serials.forEach((serial: string) => {
                promises.push(
                    CallGetAPI(CreateUrl('/api/aquaguard/GetLogReadings?companyid=0&logger=' + serial + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"), {})
                        .then(json => {
                            readingsData = readingsData.concat(buildAPIGetLoggerReadingsModel(json));
                        })
                        .catch(function (ex) {
                            me.setState(
                                {
                                    tableHidden: true,
                                    readingsData: []
                                });
                            console.log(ex);
                        })
                );
                promises.push(
                    CallGetAPI(CreateUrl('/api/aquaguard/LoggerAlarmLevels?logger=' + serial + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"), {})
                        .then(json => {
                            alarmLevels = buildAPIGetLoggerAlarmLevelModel(json);
                        })
                        .catch(function (ex) {
                            me.setState(
                                {
                                    tableHidden: true,
                                });
                            console.log(ex);
                        })
                );

            });

            Promise.all(promises).then(() => {
                readingsData.forEach((v: APIGetLoggerReadingsModel) => {
                    if (v.dateStamp != null) {
                        let value: number | null;
                        if (v.value == null) {
                            value = v.value2;
                        }
                        else {
                            value = v.value;
                        }

                        graphData.push({
                            date: adjustTime(new Date(v.dateStamp)),
                            channel: "Reading",
                            readingA: v.channelletter == 'A' && (value != -32768) ? value : null,
                            readingB: v.channelletter == 'B' && (value != -32768) ? value : null,
                            readingC: v.channelletter == 'C' && (value != -32768) ? value : null,
                            readingCmax: v.channelletter == 'C' && (v.maxValue != -32768) ? v.maxValue : null,
                            readingCmin: v.channelletter == 'C' && (v.minValue != -32768) ? v.minValue : null,
                            readingD: v.channelletter == 'D' && (value != -32768) ? value : null,
                            readingDmax: v.channelletter == 'D' && (v.maxValue != -32768) ? v.maxValue : null,
                            readingDmin: v.channelletter == 'D' && (v.minValue != -32768) ? v.minValue : null,
                            readingE: v.channelletter == 'E' && (value != -32768) ? value : null,
                            readingEmax: v.channelletter == 'E' && (v.maxValue != -32768) ? v.maxValue : null,
                            readingEmin: v.channelletter == 'E' && (v.minValue != -32768) ? v.minValue : null,
                            readingAset: null,
                            readingAclr: null,
                            readingBset: null,
                            readingBclr: null,
                            readingCset: null,
                            readingCclr: null,
                            readingDset: null,
                            readingDclr: null,
                            readingEset: null,
                            readingEclr: null,
                        });
                        // Don't graph invalid value
                        if (value != -32768) {
                            if (v.channelletter == 'A' && value != null && value > maxValue[0]) {
                                maxValue[0] = value;
                            }
                            if (v.channelletter == 'A' && value != null && value < minValue[0]) {
                                minValue[0] = value;
                            }
                            if (v.channelletter == 'B' && value != null && value > maxValue[1]) {
                                maxValue[1] = value;
                            }
                            if (v.channelletter == 'B' && value != null && value < minValue[1]) {
                                minValue[1] = value;
                            }
                            if (v.channelletter == 'C' && value != null && value > maxValue[2]) {
                                maxValue[2] = value;
                            }
                            if (v.channelletter == 'C' && value != null && value < minValue[2]) {
                                minValue[2] = value;
                            }
                            if (v.channelletter == 'D' && value != null && value > maxValue[3]) {
                                maxValue[3] = value;
                            }
                            if (v.channelletter == 'D' && value != null && value < minValue[3]) {
                                minValue[3] = value;
                            }
                            if (v.channelletter == 'E' && value != null && value > maxValue[4]) {
                                maxValue[4] = value;
                            }
                            if (v.channelletter == 'E' && value != null && value < minValue[4]) {
                                minValue[4] = value;
                            }
                        }
                        if (channels[0] == "" && v.channelletter == 'A') {
                            channels[0] = "A";
                        }
                        if (channels[1] == "" && v.channelletter == 'B') {
                            channels[1] = "B";
                        }
                        if (channels[2] == "" && v.channelletter == 'C') {
                            channels[2] = "C";
                        }
                        if (channels[3] == "" && v.channelletter == 'D') {
                            channels[3] = "D";
                        }
                        if (channels[4] == "" && v.channelletter == 'E') {
                            channels[4] = "E";
                        }
                    }
                });

                let lastASet = 0, lastAClr = 0, lastBSet = 0, lastBClr = 0, lastCSet = 0, lastCClr = 0, lastDSet = 0, lastDClr = 0, lastESet = 0, lastEClr = 0;
                let lastLevel = start;

                alarmLevels.forEach((v: APIGetLoggerAlarmLevelModel) => {
                    if (v.startTime != null) {

                        let levelTime = adjustTime(new Date(v.startTime));
                        if (levelTime < start) {
                            levelTime = start;
                        }

                        graphData.push({
                            date: levelTime,
                            channel: "Level",
                            readingAset: v.channelASetLevel,
                            readingAclr: v.channelAClearLevel,
                            readingBset: v.channelBSetLevel,
                            readingBclr: v.channelBClearLevel,
                            readingCset: v.channelCSetLevel,
                            readingCclr: v.channelCClearLevel,
                            readingDset: v.channelDSetLevel,
                            readingDclr: v.channelDClearLevel,
                            readingEset: v.channelESetLevel,
                            readingEclr: v.channelEClearLevel,
                            readingA: null,
                            readingB: null,
                            readingC: null,
                            readingCmax: null,
                            readingCmin: null,
                            readingD: null,
                            readingDmax: null,
                            readingDmin: null,
                            readingE: null,
                            readingEmax: null,
                            readingEmin: null,
                        });

                        if ((v.channelASetLevel || 0) > maxValue[0]) {
                            maxValue[0] = v.channelASetLevel || 0;
                        }
                        if ((v.channelAClearLevel || Number.MAX_VALUE) < minValue[0]) {
                            minValue[0] = v.channelAClearLevel || Number.MAX_VALUE;
                        }
                        if ((v.channelBSetLevel || 0) > maxValue[1]) {
                            maxValue[1] = v.channelBSetLevel || 0;
                        }
                        if ((v.channelBClearLevel || Number.MAX_VALUE) < minValue[1]) {
                            minValue[1] = v.channelBClearLevel || Number.MAX_VALUE;
                        }
                        if ((v.channelCSetLevel || 0) > maxValue[2]) {
                            maxValue[2] = v.channelCSetLevel || 0;
                        }
                        if ((v.channelCClearLevel || Number.MAX_VALUE) < minValue[2]) {
                            minValue[2] = v.channelCClearLevel || Number.MAX_VALUE;
                        }
                        if ((v.channelDSetLevel || 0) > maxValue[3]) {
                            maxValue[3] = v.channelDSetLevel || 0;
                        }
                        if ((v.channelDClearLevel || Number.MAX_VALUE) < minValue[3]) {
                            minValue[3] = v.channelDClearLevel || Number.MAX_VALUE;
                        }
                        if ((v.channelESetLevel || 0) > maxValue[4]) {
                            maxValue[4] = v.channelESetLevel || 0;
                        }
                        if ((v.channelEClearLevel || Number.MAX_VALUE) < minValue[4]) {
                            minValue[4] = v.channelEClearLevel || Number.MAX_VALUE;
                        }
                        
                        // Add extra level points every 00:00 for graph zooming
                        while (((new Date(v.startTime).getTime() - lastLevel.getTime()) / (1000 * 60 * 60)) > 24)
                        {
                            const midnight = new Date(new Date(lastLevel.getFullYear(), lastLevel.getMonth(), lastLevel.getDate()).setHours(24, 0, 0, 0));
                            graphData.push({
                                date: midnight,
                                channel: "Level",
                                readingAset: lastASet,
                                readingAclr: lastAClr,
                                readingBset: lastBSet,
                                readingBclr: lastBClr,
                                readingCset: lastCSet,
                                readingCclr: lastCClr,
                                readingDset: lastDSet,
                                readingDclr: lastDClr,
                                readingEset: lastESet,
                                readingEclr: lastEClr,
                                readingA: null,
                                readingB: null,
                                readingC: null,
                                readingCmax: null,
                                readingCmin: null,
                                readingD: null,
                                readingDmax: null,
                                readingDmin: null,
                                readingE: null,
                                readingEmax: null,
                                readingEmin: null,
                            });
                            lastLevel = midnight;
                        }

                        lastASet = v.channelASetLevel || 0;
                        lastAClr = v.channelAClearLevel || 0;
                        lastBSet = (v.channelBSetLevel || 0);
                        lastBClr = (v.channelBClearLevel || 0);
                        lastCSet = (v.channelCSetLevel || 0);
                        lastCClr = (v.channelCClearLevel || 0);
                        lastDSet = (v.channelDSetLevel || 0);
                        lastDClr = (v.channelDClearLevel || 0);
                        lastESet = (v.channelESetLevel || 0);
                        lastEClr = (v.channelEClearLevel || 0);

                    }
                });

                // Add last alarm levels at end
                graphData.push({
                    date: end,
                    channel: "Level",
                    readingAset: lastASet,
                    readingAclr: lastAClr,
                    readingBset: lastBSet,
                    readingBclr: lastBClr,
                    readingCset: lastCSet,
                    readingCclr: lastCClr,
                    readingDset: lastDSet,
                    readingDclr: lastDClr,
                    readingEset: lastESet,
                    readingEclr: lastEClr,
                    readingA: null,
                    readingB: null,
                    readingC: null,
                    readingCmax: null,
                    readingCmin: null,
                    readingD: null,
                    readingDmax: null,
                    readingDmin: null,
                    readingE: null,
                    readingEmax: null,
                    readingEmin: null,
                });

                // and any extra level points every 00:00 for graph zooming
                while (((end.getTime() - lastLevel.getTime()) / (1000 * 60 * 60)) > 24) {
                    const midnight = new Date(new Date(lastLevel.getFullYear(), lastLevel.getMonth(), lastLevel.getDate()).setHours(24, 0, 0, 0));
                    graphData.push({
                        date: midnight,
                        channel: "Level",
                        readingAset: lastASet,
                        readingAclr: lastAClr,
                        readingBset: lastBSet,
                        readingBclr: lastBClr,
                        readingCset: lastCSet,
                        readingCclr: lastCClr,
                        readingDset: lastDSet,
                        readingDclr: lastDClr,
                        readingEset: lastESet,
                        readingEclr: lastEClr,
                        readingA: null,
                        readingB: null,
                        readingC: null,
                        readingCmax: null,
                        readingCmin: null,
                        readingD: null,
                        readingDmax: null,
                        readingDmin: null,
                        readingE: null,
                        readingEmax: null,
                        readingEmin: null,
                    });

                    lastLevel = midnight;
                }

                graphData.sort((a, b) => a.date.getTime() - b.date.getTime());

                me.setState({
                    channels: channels,
                    readingsData: readingsData,
                    graphData: graphData,
                    maxValue: maxValue,
                    minValue: minValue,
                    tableHidden: false,
                    loading: false,
                });

            });

        }
        else if (me.props.site != undefined) {
            CallGetAPI(CreateUrl('/api/aquaguard/GetLogReadingsBySite?siteId=' + me.props.site + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"),{})
                .then(response => {
                    readingsData = buildAPIGetLoggerReadingsModel(response);

                    readingsData.forEach((v: any) => {
                        if (v.dateStamp != null) {
                            graphData.push({
                                date: adjustTime(new Date(v.dateStamp)),
                                channel: "Reading",
                                readingA: v.channelletter == 'A' && (v.value != -32768) ? v.value : null,
                                readingB: v.channelletter == 'B' && (v.value != -32768) ? v.value : null,
                                readingC: v.channelletter == 'C' && (v.value != -32768) ? v.value : null,
                                readingCmax: v.channelletter == 'C' && (v.maxValue != -32768) ? v.maxValue : null,
                                readingCmin: v.channelletter == 'C' && (v.minValue != -32768) ? v.minValue : null,
                                readingD: v.channelletter == 'D' && (v.value != -32768) ? v.value : null,
                                readingDmax: v.channelletter == 'D' && (v.maxValue != -32768) ? v.maxValue : null,
                                readingDmin: v.channelletter == 'D' && (v.minValue != -32768) ? v.minValue : null,
                                readingE: v.channelletter == 'E' && (v.value != -32768) ? v.value : null,
                                readingEmax: v.channelletter == 'E' && (v.maxValue != -32768) ? v.maxValue : null,
                                readingEmin: v.channelletter == 'E' && (v.minValue != -32768) ? v.minValue : null,
                                readingAset: null,
                                readingAclr: null,
                                readingBset: null,
                                readingBclr: null,
                                readingCset: null,
                                readingCclr: null,
                                readingDset: null,
                                readingDclr: null,
                                readingEset: null,
                                readingEclr: null,
                            });
                            // Don't graph invalid value
                            if (v.value != -32768) {
                                if (v.channelletter == 'A' && v.value != null && v.value > maxValue[0]) {
                                    maxValue[0] = v.value;
                                }
                                if (v.channelletter == 'A' && v.value != null && v.value < minValue[0]) {
                                    minValue[0] = v.value;
                                }
                                if (v.channelletter == 'B' && v.value != null && v.value > maxValue[1]) {
                                    maxValue[1] = v.value;
                                }
                                if (v.channelletter == 'B' && v.value != null && v.value < minValue[1]) {
                                    minValue[1] = v.value;
                                }
                                if (v.channelletter == 'C' && v.value != null && v.value > maxValue[2]) {
                                    maxValue[2] = v.value;
                                }
                                if (v.channelletter == 'C' && v.value != null && v.value < minValue[2]) {
                                    minValue[2] = v.value;
                                }
                                if (v.channelletter == 'D' && v.value != null && v.value > maxValue[3]) {
                                    maxValue[3] = v.value;
                                }
                                if (v.channelletter == 'D' && v.value != null && v.value < minValue[3]) {
                                    minValue[3] = v.value;
                                }
                                if (v.channelletter == 'E' && v.value != null && v.value > maxValue[4]) {
                                    maxValue[4] = v.value;
                                }
                                if (v.channelletter == 'E' && v.value != null && v.value < minValue[4]) {
                                    minValue[4] = v.value;
                                }
                            }
                            if (channels[0] == "" && v.channelletter == 'A') {
                                channels[0] = "A";
                            }
                            if (channels[1] == "" && v.channelletter == 'B') {
                                channels[1] = "B";
                            }
                            if (channels[2] == "" && v.channelletter == 'C') {
                                channels[2] = "C";
                            }
                            if (channels[3] == "" && v.channelletter == 'D') {
                                channels[3] = "D";
                            }
                            if (channels[4] == "" && v.channelletter == 'E') {
                                channels[4] = "E";
                            }
                        }
                    });

                    graphData.sort((a, b) => a.date.getTime() - b.date.getTime());

                    me.setState({
                        channels: channels,
                        readingsData: readingsData,
                        graphData: graphData,
                        maxValue: maxValue,
                        minValue: minValue,
                        tableHidden: false,
                        loading: false,
                    });

                })
                .catch(function (ex) {
                    me.setState(
                        {
                            tableHidden: true,
                            readingsData: []
                        });
                    console.log(ex);
                });
        }

    }

    getReadings(user: User, serial: string, start: Date, end: Date): Promise<Response> {
        return fetch(CreateUrl('/api/aquaguard/GetLogReadings?companyid=0&logger=' + serial + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"),
            {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            })

    }


    startDateChanged(e: any): void {
        this.setState({
            startDate: e.value
        });

        this.reloadData(this.state.startDate, this.state.endDate);
    }

    endDateChanged(e: any): void {
        this.setState({
            endDate: e.value
        });

        this.reloadData(this.state.startDate, this.state.endDate);
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm:ss");
    }

    dateLabelCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY");
    }

    filterChange(filters: boolean[]): void {

        const filter = new Array<string | string[]>();
        let addOr = false;

        if (filters[0]) {
            filter.push(["channelletter", "=", "A"]);
            addOr = true;
        }
        if (filters[1]) {
            if (addOr) {
                filter.push("or");
            }
            filter.push(["channelletter", "=", "B"]);
            addOr = true;
        }
        if (filters[2]) {
            if (addOr) {
                filter.push("or");
            }
            filter.push(["channelletter", "=", "C"]);
            addOr = true;
        }
        if (filters[3]) {
            if (addOr) {
                filter.push("or");
            }
            filter.push(["channelletter", "=", "D"]);
            addOr = true;
        }
        if (filters[4]) {
            if (addOr) {
                filter.push("or");
            }
            filter.push(["channelletter", "=", "E"]);
            addOr = true;
        }

        this.setState({
            channelFilters: filters,
            filterValue: filter
        });
        this.gridRef?.current?.instance.filter(filter);

    }

    applyFilter(grid: DataGrid): void {
        if (this.state.filterValue.length > 0) {
            this.gridRef?.current?.instance.filter(this.state.filterValue);
        }
    }

    toggleAlarmLevels(e: any): void {
        if (this.state.showAlarmLevels) {
            this.setState({ showAlarmLevels: !this.state.showAlarmLevels, btnAlarmLevels: "Show Alarm levels" });
        }
        else {
            this.setState({ showAlarmLevels: !this.state.showAlarmLevels, btnAlarmLevels: "Hide Alarm levels" });
        }
    }

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Readings');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }


    render(): ReactNode {

        // Determine minValue & maxValue for all displayed channels
        let maxValue = 0;
        let minValue = Number.MAX_VALUE;

        for (let i = 0; i < 5; i++) {
            if (this.state.channels[i] != "" && this.state.channelFilters[i]) {
                if (this.state.maxValue[i] > maxValue) {
                    maxValue = this.state.maxValue[i];
                }
                if (this.state.minValue[i] < minValue) {
                    minValue = this.state.minValue[i];
                }
            }
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={5} sm={5} md={5}>
                        Start date :&nbsp;<DateBox id="startDate" displayFormat={"dd/MM/yyyy"} value={this.state.startDate} onValueChanged={this.startDateChanged.bind(this)} />
                    </GridItem>
                    <GridItem xs={5} sm={5} md={5}>
                        End date :&nbsp;<DateBox id="endDate" displayFormat={"dd/MM/yyyy"} value={this.state.endDate} onValueChanged={this.endDateChanged.bind(this)} />
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2}>
                        <div style={{ display: 'inline', float: "right" }}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={(): void => this.reloadData(this.state.startDate, this.state.endDate)}
                                aria-label="refresh"
                            >
                                <RefreshRoundedIcon />
                            </IconButton>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                            <ChannelFilter channels={this.state.channels} channelFilters={this.state.channelFilters} onFilterChange={this.filterChange.bind(this)} />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <Button variant="contained" onClick={this.toggleAlarmLevels.bind(this)}>
                            {this.state.btnAlarmLevels}
                        </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={75}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                            {!this.state.tableHidden &&
                                    <CustomTabs
                                    headerColor="info"
                                    tabs={[
                                        {
                                            tabName: "Graph",
                                            tabIcon: PollOutlinedIcon,
                                            tabContent: (
                                                <Chart dataSource={this.state.graphData}>
                                                    <CommonSeriesSettings
                                                        argumentField="date"
                                                        type="line"
                                                        ignoreEmptyPoints={true}>
                                                        <Aggregation
                                                            enabled={true}
                                                            method="avg"
                                                        />
                                                        <Point size={4} />
                                                    </CommonSeriesSettings>
                                                    <ValueAxis
                                                        name="Value"
                                                        visualRange={{ startValue: minValue, endValue: maxValue }}
                                                        allowDecimals={false}
                                                    />
                                                    <ArgumentAxis
                                                        aggregationInterval={{ minutes: 1 }}
                                                        allowDecimals={false}
                                                        argumentType="datetime"
                                                        minValueMargin={0.1}
                                                        maxValueMargin={0.1}
                                                        wholeRange={[this.state.startDate, this.state.endDate]}
                                                    >
                                                    </ArgumentAxis>
                                                    <ZoomAndPan
                                                        argumentAxis="both"
                                                        valueAxis="none"
                                                        dragToZoom={true}
                                                    />
                                                    <ScrollBar
                                                        visible={true}
                                                    />
                                                    <Tooltip
                                                        enabled={true}
                                                        contentRender={ReadingTooltip}
                                                    />
                                                    <Crosshair
                                                        enabled={true}
                                                    />

                                                    {this.state.channels[0] != "" && this.state.channelFilters[0] && <Series valueField="readingA" name={this.state.channels[0]} color="blue" ignoreEmptyPoints={true} />}
                                                    {this.state.channels[0] != "" && this.state.channelFilters[0] && <Series valueField="readingAset" name={this.state.channels[0] + " Set"} color="blue" ignoreEmptyPoints={true} showInLegend={false} dashStyle="dash" visible={this.state.showAlarmLevels} />}
                                                    {this.state.channels[0] != "" && this.state.channelFilters[0] && <Series valueField="readingAclr" name={this.state.channels[0] + " Clear"} color="blue" ignoreEmptyPoints={true} showInLegend={false} dashStyle="dot" visible={this.state.showAlarmLevels} />}
                                                    {this.state.channels[1] != "" && this.state.channelFilters[1] && <Series valueField="readingB" name={this.state.channels[1]} color="green" ignoreEmptyPoints={true} />}
                                                    {this.state.channels[1] != "" && this.state.channelFilters[1] && <Series valueField="readingBset" name={this.state.channels[1] + " Set"} color="green" ignoreEmptyPoints={true} showInLegend={false} dashStyle="dash" visible={this.state.showAlarmLevels} />}
                                                    {this.state.channels[1] != "" && this.state.channelFilters[1] && <Series valueField="readingBclr" name={this.state.channels[1] + " Clear"} color="green" ignoreEmptyPoints={true} showInLegend={false} dashStyle="dot" visible={this.state.showAlarmLevels} />}
                                                    {this.state.channels[2] != "" && this.state.channelFilters[2] &&
                                                        <Series valueField="readingC" name={this.state.channels[2]} color="red" ignoreEmptyPoints={true}>
                                                            {/*   <ValueErrorBar highValueField="readingCmax" lowValueField="readingCmin" type="fixed" /> */}
                                                        </Series>
                                                    }
                                                    {this.state.channels[2] != "" && this.state.channelFilters[2] && <Series valueField="readingCset" name={this.state.channels[2] + " Set"} color="red" ignoreEmptyPoints={true} showInLegend={false} dashStyle="dash" visible={this.state.showAlarmLevels} />}
                                                    {this.state.channels[2] != "" && this.state.channelFilters[2] && <Series valueField="readingCclr" name={this.state.channels[2] + " Clear"} color="red" ignoreEmptyPoints={true} showInLegend={false} dashStyle="dot" visible={this.state.showAlarmLevels} />}
                                                    {this.state.channels[3] != "" && this.state.channelFilters[3] &&
                                                        <Series valueField="readingD" name={this.state.channels[3]} color="orange" ignoreEmptyPoints={true} >
                                                            {/*    <ValueErrorBar highValueField="readingDmax" lowValueField="readingDmin" type="fixed" /> */}
                                                        </Series>
                                                    }
                                                    {this.state.channels[3] != "" && this.state.channelFilters[3] && <Series valueField="readingDset" name={this.state.channels[3] + " Set"} color="orange" ignoreEmptyPoints={true} showInLegend={false} dashStyle="dash" visible={this.state.showAlarmLevels} />}
                                                    {this.state.channels[3] != "" && this.state.channelFilters[3] && <Series valueField="readingDclr" name={this.state.channels[3] + " Clear"} color="orange" ignoreEmptyPoints={true} showInLegend={false} dashStyle="dot" visible={this.state.showAlarmLevels} />}
                                                    {this.state.channels[4] != "" && this.state.channelFilters[4] &&
                                                        <Series valueField="readingE" name={this.state.channels[4]} color="purple" ignoreEmptyPoints={true} >
                                                            {/*    <ValueErrorBar highValueField="readingEmax" lowValueField="readingEmin" type="fixed" /> */}
                                                        </Series>
                                                    }
                                                    {this.state.channels[4] != "" && this.state.channelFilters[4] && <Series valueField="readingEset" name={this.state.channels[4] + " Set"} color="purple" ignoreEmptyPoints={true} showInLegend={false} dashStyle="dash" visible={this.state.showAlarmLevels} />}
                                                    {this.state.channels[4] != "" && this.state.channelFilters[4] && <Series valueField="readingEclr" name={this.state.channels[4] + " Clear"} color="purple" ignoreEmptyPoints={true} showInLegend={false} dashStyle="dot" visible={this.state.showAlarmLevels} />}
                                                    <Legend visible={true} />
                                                </Chart>
                                            )
                                        }

                                        ,{
                                            tabName: "Data",
                                            tabIcon: TableChartOutlinedIcon,
                                            tabContent: (
                                                <DataGrid
                                                    dataSource={this.state.readingsData}
                                                    keyExpr="id"
                                                    ref={this.gridRef}
                                                    filterSyncEnabled={true}
                                                    defaultFilterValue={this.state.filterValue}
                                                    columnAutoWidth={true}
                                                    columnMinWidth={80}
                                                    onExporting={this.onExporting}
                                                    onContentReady={this.applyFilter.bind(this)} >  {/* reapply filters when data changes */ }
                                                    <Scrolling mode="standard" useNative={true} />
                                                    <FilterRow visible={true} />
                                                    <HeaderFilter visible={true} />
                                                    <StateStoring enabled={true} type="localStorage" storageKey="readingsPanelGrid" />
                                                    <Export enabled={true} />
                                                    <Column dataField="id" visible={false} dataType="number" />
                                                    <Column dataField="loggerSerial" name="Logger" dataType="string" />
                                                    <Column dataField="dateStamp" customizeText={this.dateColumnCustomizeText} dataType="datetime" caption="Date Stamp (UTC)" sortIndex={1} sortOrder="desc" allowHeaderFiltering={false}/>
                                                    <Column dataField="type" dataType="string" />
                                                    <Column dataField="value" dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="value2" dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="minValue" dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="maxValue" dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="units" dataType="string" />
                                                    <Column dataField="flowRate" dataType="number" caption="Flow Rate (l/s)" allowHeaderFiltering={false}>
                                                        <Format type="fixedPoint" precision={3} />
                                                    </Column>
                                                    <Column dataField="channelletter" dataType="string" visible={false} />
                                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                    <Paging defaultPageSize={20} />
                                                </DataGrid>
                                            )
                                        }
                                    ]}
                                />

                    }
                    </div>
                }

                    </GridItem>
                </GridContainer>
            </div>
        );
    }

}

export default withStyles(style)(ReadingsPanel);