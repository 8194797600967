import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import FormField from 'components/Fields/FormField';
import PasswordField from 'components/Fields/PasswordField';

import './ResetPassword.scss';

interface Props {
    classes: {
        cardTitleWhite: string;
        cardCategoryWhite: string;
        formControl: string;
    };
    onSet: (newPassword: string) => Promise<boolean>;
    resetDone: () => void;
}

class ResetPassword extends React.Component<Props> {

    // Code cribed from https://www.digitalocean.com/community/tutorials/how-to-build-a-password-strength-meter-in-react

    // initialize state to hold validity of form fields
    state = { passwordConfirm: false, password: false, passwordValue: '', confirmValue: '', completed: false, failed: false };
    
    // higher-order function that returns a state change watch function
    // sets the corresponding state property to true if the form field has no errors
    fieldStateChanged = (field: string, value: string) => (state: any): void => this.setState({ [field]: state.errors.length === 0, [value]: state.value });

    // state change watch functions for each field
    confirmChanged = this.fieldStateChanged('passwordConfirm', 'confirmValue');
    passwordChanged = this.fieldStateChanged('password', 'passwordValue');

    doReset = (): void => {

        if (this.props.onSet) {
            this.props.onSet(this.state.passwordValue).then((result: boolean) => {
                this.setState({ completed: result, failed: result });
            });
        }
        else {
            this.setState({ failed: true });
        }
    }

    resetDone = (): void => {
        if (this.props.resetDone) {
            this.props.resetDone();
        }
    }

    render(): ReactNode {

        const { password, passwordConfirm, passwordValue } = this.state;
        const formValidated = passwordConfirm && password;
        const { classes } = this.props;


        // validation function for the fullname
        // ensures that fullname contains at least two names separated with a space
        const validatepasswordConfirm = (value: string): void => {
            if (value != passwordValue) throw new Error('Passwords do not match');
        };


        return (
                    <div>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                {!this.state.completed && //if we are authorized, show page, else redirect to login page
                                <div>
                                    <form action="/" method="POST" noValidate>
                                        <div className="py-5 border-gray border-top border-bottom">
                                            <span className="d-block form-hint">To conform with our Strong Password policy, you are required to use a sufficiently strong password including a mixture of upper & lower case, numbers and symbols until bar turns green. Password must be more than 7 characters.</span>
                                            <div>
                                                {/** Render the password field component using thresholdLength of 8 and minStrength of 3 **/}
                                                <PasswordField fieldId="password" label="Password" placeholder="Enter Password" onStateChanged={this.passwordChanged} thresholdLength={7} minStrength={3} required />
                                            </div>
                                            <div>
                                                {/** Render the fullname form field passing the name validation fn **/}
                                                <FormField type="password" fieldId="passwordConfirm" label="Confirm Password" placeholder="" validator={validatepasswordConfirm} onStateChanged={this.confirmChanged} required />
                                            </div>
                                        </div>
                                        {/** Show the form button only if all fields are valid **/}
                                {formValidated && <button type="button" className="btn btn-primary text-uppercase px-3 py-2" onClick={this.doReset.bind(this)}>Set Password</button>}

                                    </form>
                                    {(this.state.failed &&
                                        <div className="error">The system failed to reset your password, please contact system administrator</div>
                                    )}
                                </div>
                                }
                                {this.state.completed &&
                                    <div>
                                    <div className={classes.cardCategoryWhite}>
                                        Your password has been reset.
                                    </div>
                                    <button type="button" className="btn btn-primary text-uppercase px-3 py-2" onClick={this.resetDone.bind(this)}>Login</button>
                                    </div>
                                }
                            </GridItem>
                        </GridContainer>
                    </div>
        )
    }

}

export default ResetPassword;