//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';


export interface APIGetLoggerAuditModel {

    serial: string | null;
    details: string | null;
    timestamp: Date | null;
    type: string | null;
    email: string | null;
}


export function buildAPIGetLoggerAuditModel(r: any): APIGetLoggerAuditModel {


    return {
        serial: r.serial,
        timestamp: r.timeStamp,
        type: r.type,
        email: r.email.slice(0, r.email.indexOf('@')),
        details: r.details,
    }
}

