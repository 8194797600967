import React, { useState, useRef, useEffect } from 'react'
import ClipLoader from "react-spinners/ClipLoader";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ApiFailed from '../../Utils/ApiFailed';
import { compose, spacing, palette, border } from '@mui/system';
import { styled } from '@mui/material/styles';
import DateRange from '../Dates/DateRange';
import moment, { max, utc } from 'moment';
import Modal from 'react-bootstrap/Modal';
import { Button } from '@mui/material';
import { DataGrid, Column as GridColumn, Export, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring, HeaderFilter, Selection } from 'devextreme-react/data-grid';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { APILoggerDetail, buildAPILoggerDetail } from '../../models/APILoggerDetail';
import { APIGetLoggerReadingsModel, buildAPIGetLoggerReadingsModel } from '../../models/APIGetLoggerReadingsModel';
import './custom-modal.css'
import { filter } from 'esri/core/promiseUtils';

interface DeviceReportProps {
    show: boolean;
    title: string;
    onCancel: () => void;
    loggers: Array<number>;
    deviceData: Array<DeviceData>;
    buttonClick: boolean;
}

interface DeviceData {
    id: number;
    serial: string;
    site: string;
    model: string;
    channels: string;
    lastConnected: Date | null;
    alarmState: string;
    siteId: number | null;
    siteName: string;
    siteRef: string;
    deployed: string;
    sendToLogger: boolean;
    updateRequested: Date | null;
    updateConfirmed: Date | null;
    firmwareToUpdate: number | null;
    firmwareSent: Date | null;
    firmwareConfirmed: Date | null;
    gpsLatitude: number | null;
    gpsLongitude: number | null;
    configurationId: string;
    undeploy: boolean;
    shutdown: boolean;
    dormant: boolean;
    company: string;
    modelId: string;
    channelsAvail: number;
    meterConfig: number;
}



//STATE 
const SadborowReport: React.FC<DeviceReportProps> = ({show, title, onCancel, deviceData, loggers, buttonClick }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [tableHidden, setTableHidden] = useState<boolean>(false)
    const [startDate, setStartDate] = useState(moment().subtract(1, "days").startOf('day'))
    const [endDate, setEndDate] = useState(moment())
    const [eaSiteId, setEaSiteId] = useState<number>(0)
    const [dataToShow, setDataToShow] = useState()


    // REF

    const dataGridRef = useRef<DataGrid | null>(null);


    // VARIABLES

    const Box = styled('div')(compose(spacing, palette));


    // FUNCTIONS

    const clearState = (): void => {
        if (dataGridRef.current) {
            dataGridRef.current.instance?.state(undefined);
        }
    }

    const onDateChange = (startDate: Date, endDate: Date, rainfall: boolean): void => {
        const endDateTimeAdapt = endDate > moment() ? moment() : moment(endDate).endOf('day')

        setStartDate(moment(startDate).startOf('day'))
        setEndDate(endDateTimeAdapt)
    }

    //USE-EFFECT
    useEffect(() => {
        if (buttonClick) {
            const fetchData = async () => {
                setLoading(true)
                if (localStorage.getItem('portalUpdate') === 'true') {
                    clearState();
                }
                const records = [];
                const offset = sessionStorage.getItem('utcOffset') ? parseInt(sessionStorage.getItem('utcOffset').slice(1)) : 0
                let offsetStartDate
                let offsetEndDate
                if (sessionStorage.getItem('utcOffset').slice(0, 1) === '+') {
                    offsetStartDate = moment(startDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
                    offsetEndDate = moment(endDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
                } else if (sessionStorage.getItem('utcOffset').slice(0, 1) === '-') {
                    offsetStartDate = moment(startDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
                    offsetEndDate = moment(endDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
                } else {
                    offsetStartDate = moment(startDate).format("yyyy-MM-DDTHH:mm:ss");
                    offsetEndDate = moment(endDate).format("yyyy-MM-DDTHH:mm:ss");
                }
                
                if (loggers.length > 0) {
                    try {
                        const loggerIds = loggers.map(l => ({ Id: l }));

                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(loggerIds)
                        };

                        const data = await CallGetAPI(CreateUrl('/api/aquaguard/LoggerDetailsByIdList'), requestOptions);

                        if (data.length > 0) {

                            for (let i = 0; i < data.length; i++) {
                                const rec = buildAPILoggerDetail(data[i]);
                                records.push(rec);
                            }

                            // Fetch readings for each logger
                            const readingsData = [];
                            for (const logger of records) {

                                
                                const readings = await CallGetAPI(CreateUrl(`/api/aquaguard/GetLogReadings?companyid=0&logger=${logger.Serial}&startDate=${offsetStartDate}&endDate=${offsetEndDate}`), {});
                                readings.sort((a: { dateStamp: string }, b: { dateStamp: string }): number => {
                                    return new Date(a.dateStamp).getTime() - new Date(b.dateStamp).getTime();
                                })
                                
                                if (logger.SiteName === "Unassigned") {
                                    deviceData.map(item => {
                                        if (item.id === logger.LoggerId) {
                                            logger.SiteName = item.site
                                        }
                                    })
                                }
                                const hoursDifference = endDate.diff(startDate, 'hours');

                                const maxFlowRate = readings.reduce((max: number, obj) => obj.flowRate !== null && obj.flowRate > max ? obj.flowRate : max, -Infinity);
                                const minFlowRate = readings.reduce((min: number, obj) => obj.flowRate !== null && obj.flowRate < min ? obj.flowRate : min, 0);

                                const loss = minFlowRate * hoursDifference
                                const consumption = readings[0].value ? (readings[readings.length - 1].value - readings[0].value) : (readings[readings.length - 1].value2 - readings[0].value2)
                                logger.loss = loss.toFixed(4);
                                if (maxFlowRate === -Infinity) {
                                    logger.maxFlow = ""
                                } else {
                                    logger.maxFlow = maxFlowRate.toFixed(2);
                                }
                                logger.minFlow = minFlowRate.toFixed(2);
                                logger.consumption = consumption.toFixed(2);
                                logger.lossPer = ((loss / consumption) * 100).toFixed(2)
                                
                                readingsData.push(...buildAPIGetLoggerReadingsModel(readings));
                            }



                        }

                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                }
                
                setDataToShow(records);
                setLoading(false)
            };

            fetchData();
        }
    }, [startDate, endDate, buttonClick]);

    



    



//MAIN RENDER
    return (
        <div>
            {loading &&
                <div style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)', zIndex: 10000
                }}>
                    <ClipLoader
                        size={150}
                        color={"#123abc"}
                        loading={loading}
                    />
                </div>
            }
            {!loading && 
                <div>
                    
                    
                    {!tableHidden &&
                        <Box padding={1}>
                            
                            <GridContainer>
                                
                                <Modal show={show} style={{ zIndex: 1100, maxWidth: "90% !important", width: "100vw"}} onHide={(): void => onCancel()}>
                                        <Modal.Header closeButton >
                                            <Modal.Title id="example-custom-modal-styling-title">
                                                {title}
                                            </Modal.Title>
                                        </Modal.Header>
                                    <Modal.Body className="show-grid">
                                            <GridItem>
                                            <DateRange type={'date'} startDate={startDate} endDate={endDate} rainfall={eaSiteId > 0} onDateChange={onDateChange} />
                                        </GridItem>
                                        
                                        
                                        
                                        <DataGrid
                                            allowColumnResizing={true}
                                            allowColumnReordering={true}
                                            dataSource={dataToShow}

                                            ref={dataGridRef}
                                        //defaultFilterValue={this.state.filterValue}    
                                        >
                                            <StateStoring enabled={true} type="localStorage" storageKey="deviceListGrid" />
                                            <GroupPanel visible={true} />
                                            <Grouping autoExpandAll={true} />
                                            <FilterRow visible={true} />
                                            <HeaderFilter visible={true} />
                                            <GridColumn dataField="Loggerid" visible={false} dataType="number" />
                                            <GridColumn dataField="SiteName" dataType="string" caption="Device Site Name" />

                                            <GridColumn dataField="Serial" dataType="string" sortOrder={'asc'} />
                                            <GridColumn dataField="maxFlow" dataType="string" caption="Max Flow (m3/hour)" />
                                            <GridColumn dataField="minFlow" dataType="string" caption="Min Flow (m3/hour)" />
                                            <GridColumn dataField="loss" dataType="string" caption="Loss (m3)" />
                                            <GridColumn dataField="consumption" dataType="string" caption="Consumption (m3)" />
                                            <GridColumn dataField="lossPer" dataType="string" caption="Loss %" />


                                            <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                            <Paging defaultPageSize={20} />
                                        </DataGrid>
                                        
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button color="secondary" variant="contained" onClick={(): void => onCancel()}>Exit</Button>
                                        </Modal.Footer>
                                    </Modal>
                               
                                  
                            </GridContainer>
                        </Box>}
                </div>}
        </div>
            
                
                
            
    )
}
export default SadborowReport