import React, { ReactElement, FC, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { blackColor } from '../assets/jss/material-dashboard-react';
import { ReactComponent as Yoda } from '../assets/Ambox_warning_pn.svg';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
        },
        alignRight: {
            textAlign: "right",
        }
    })

);


const ApiFailed: FC<any> = (): ReactElement => {

    const classes = useStyles();

    return (
        <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)',
            display: "flex"
            
        }}>
            <div style={{width: "40%", marginTop: "20px"} }>
                <Yoda />
            </div>
            <div className="text-center">
                {!window.location.hostname.includes('zonelog.net') && 
                <div>
                <div className="fa-2x ">The Aquaguard API has failed to respond.</div>
                <div className="fa-2x ">Please check your internet connection and try again.</div>
                <div className="fa-2x ">If the problem continues, please contact <a href="mailto:support@ash-eng.com">support@ash-eng.com</a></div>
                    </div>}
                {window.location.hostname.includes('zonelog.net') &&
                    <div>
                        <div className="fa-2x ">The ZONELOG API has failed to respond.</div>
                        <div className="fa-2x ">Please check your internet connection and try again.</div>
                        <div className="fa-2x ">If the problem continues, please contact <a href="mailto:support@zonelog.net">support@zonelog.net</a></div>
                    </div>}
            </div>
        </div>

    );

};

export default ApiFailed;