/* /src/auth/authProvider.jsx */

import React, { Component } from "react";
import AuthService from "../auth/authService.js";

/*We are going to need one instance of the authService object in different places in our app,
 * for example in the register page or in our private routes to validate if the user is allowed to access the route.
 * The library said it is prepared to be instanced multiple times and itself is going to check if another object is currently created,
 * but for better organization of the code and to be sure to instance it only one time we are going to use the Context API of React.
 * So we are going to create the AuthContext, the AuthProvider with the instance of our AuthService and the AuthConsumer to be used in our application.
 */

export const AuthContext = React.createContext({
    signinRedirectCallback: () => ({}),
    logout: () => ({}),
    signoutRedirectCallback: () => ({}),
    isAuthenticated: () => ({}),
    signinRedirect: () => ({}),
    signinSilentCallback: () => ({}),
    createSigninRequest: () => ({}),
    getUser: async () => ({})
});

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
    authService;
    constructor(props) {
        super(props);
        this.authService = new AuthService();
    }
    render() {
        return <AuthContext.Provider value={this.authService}>{this.props.children}</AuthContext.Provider>;
    }
}

