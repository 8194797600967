export interface APIGetFirmwaresModel {
    /// <summary>
    /// Identity, Indexed, Required
    /// </summary>
    id: number;

    model: string;

    versionStr: string;

    version: number;

    filename: string;

    fls: string;

    devVersion: number;

}

export function buildAPIFirmwaresModel(r: any): APIGetFirmwaresModel[] {

    const result: APIGetFirmwaresModel[] = new Array<APIGetFirmwaresModel>();

    r?.forEach(function (rec: APIGetFirmwaresModel) {
        result.push(rec);
    });

    return result;
}




