import { enumChariotAlarmInput } from 'models/enumChariotAlarmInput';
import { enumChariotAlarmOperator } from 'models/enumChariotAlarmOperator';


export interface APIGetSiteAlarmConfigModel {
    /// <summary>
    /// Identity, Indexed, Required
    /// </summary>
    id: number;

    idx: number;

    alarmMessage: string;

    alarmMask: number;

    alarmClass: number;

    setInput: enumChariotAlarmInput;

    clrInput: enumChariotAlarmInput;

    setOperator: enumChariotAlarmOperator;

    clrOperator: enumChariotAlarmOperator;

    setThreshold: number;

    clrThreshold: number;

    setCount: number;

    clrCount: number;

    maxAlarms: number;

    setUnits: number;

    clrUnits: number;

    fkSiteId: number;

    // Fields below for editing
    setThresholdStr: string;

    clrThresholdStr: string;

    setCountStr: string;

    clrCountStr: string;

    maxAlarmsStr: string;

    setDiffChannel1: string;
    setDiffChannel2: string;
    clrDiffChannel1: string;
    clrDiffChannel2: string;


}

export function buildAPIGetSiteAlarmConfigModel(rec: any): APIGetSiteAlarmConfigModel {


        const temp: APIGetSiteAlarmConfigModel = {
            id: rec.id,
            idx: rec.idx,
            alarmMask: rec.alarmMask,
            alarmClass: rec.alarmClass,
            alarmMessage: rec.alarmMessage,
            setInput: rec.setInput,
            clrInput: rec.clrInput,
            setOperator: rec.setOperator,
            clrOperator: rec.clrOperator,
            setThreshold: rec.setThreshold,
            clrThreshold: rec.clrThreshold,
            setCount: rec.setCount,
            clrCount: rec.clrCount,
            maxAlarms: rec.maxAlarms,
            setUnits: rec.setUnits,
            clrUnits: rec.clrUnits,
            fkSiteId: rec.fkSiteId,
            setThresholdStr: rec.setThreshold.toString(),
            clrThresholdStr: rec.clrThreshold.toString(),
            setCountStr: rec.setCount.toFixed(0),
            clrCountStr: rec.clrCount.toFixed(0),
            maxAlarmsStr: rec.maxAlarms.toFixed(0),

            setDiffChannel1: rec.setDiffChannel1 ? rec.setDiffChannel1 : "",
            setDiffChannel2: rec.setDiffChannel2 ? rec.setDiffChannel2 : "",
            clrDiffChannel1: rec.clrDiffChannel1 ? rec.clrDiffChannel1 : "",
            clrDiffChannel2: rec.clrDiffChannel2 ? rec.clrDiffChannel2 : "",

        };


    return temp;
}

export function buildEmptySiteAlarmConfigModel(idx: number): APIGetSiteAlarmConfigModel {


    const temp: APIGetSiteAlarmConfigModel = {
        id: -idx,
        idx: idx,
        alarmMask: (0x01 << (idx - 1)),
        alarmClass: 7,      // Low
        alarmMessage: "",
        setInput: 0,
        clrInput: 0,
        setOperator: 0,
        clrOperator: 0,
        setThreshold: 0,
        clrThreshold: 0,
        setCount: 1,
        clrCount: 1,
        maxAlarms: 0,
        setUnits: 0,
        clrUnits: 0,
        fkSiteId: 0,
        setThresholdStr: "0",
        clrThresholdStr: "0",
        setCountStr: "24hour",
        clrCountStr: "24hour",
        maxAlarmsStr: "0",

        setDiffChannel1: "",
        setDiffChannel2: "",
        clrDiffChannel1: "",
        clrDiffChannel2: "",

    };


    return temp;
}





