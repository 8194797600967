import React, { useState, useEffect } from 'react'
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import ClipLoader from "react-spinners/ClipLoader";
import { Button } from '@mui/material';
import Modal from 'react-bootstrap/Modal'
interface SmartCameraMessageData {
    id: number;
    iccid: string;
    device_id: string;
    timestamp: number;
    received: Date;
    rssi: number;
    ambient_light: number;
    ambient_temp: number;
    trigger_source: string;
    device_status: string;
    md_cells: string;
    image: string;
}

const SmartCameraOverview: React.FC = () => {
    const [devices, setDevices] = useState<SmartCameraMessageData[]>()
    const [loading, setLoading] = useState<boolean>()
    const [imageModal, setImageModal] = useState<boolean>(false)
    const [selectedDevice, setSelectedDevice] = useState<SmartCameraMessageData>()
    useEffect(() => {
        const cameraData: Array<SmartCameraMessageData> = []
        CallGetAPI(CreateUrl(`/api/smartcamera`), {})
            .then(data => {
                for (let i = 0; i < data.length; i++) {
                    const rec: SmartCameraMessageData = {
                        id: data[i].id,
                        iccid: data[i].iccid,
                        device_id: data[i].device_id,
                        timestamp: data[i].timestamp,
                        received: data[i].received,
                        rssi: data[i].rssi,
                        image: data[i].image,
                        ambient_light: data[i].ambient_light,
                        ambient_temp: data[i].ambient_temp,
                        trigger_source: data[i].trigger_source,
                        device_status: data[i].device_status,
                        md_cells: data[i].md_cells
                    }                    
                    cameraData.push(rec)
                }
                setDevices(cameraData)
            })
    }, [])


    const buttonColumnTemplate = ({ data }) => {
        
        return <Button color="primary" onClick={(): void => handleImageButtonClick(data)} variant="contained">View Image</Button>
    }


    const handleImageButtonClick = (data):void => {
        setSelectedDevice(data)
        setImageModal(!imageModal)
    }

    return (
        <>
            {console.log(sessionStorage.getItem('userGuid')) }
            <div>
            {loading && <div style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={loading}
                />
            </div>}
            {!loading &&
                    <div>
                        <Modal show={imageModal} style={{ marginTop: "50px" }} dialogClassName="modal-50w">
                            <Modal.Header>
                                <Modal.Title>{`Device: ${selectedDevice?.iccid}`}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img src={selectedDevice?.image} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="contained" onClick={(): void => {setImageModal(!imageModal) } }>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    <h4>Laiwa Devices</h4>
                    <DataGrid
                    dataSource={devices}
                    allowColumnResizing={true}
                    repaintChangesOnly={true}
                    >
                        <FilterRow visible={true} />
                        <Column dataType="number" dataField='id' />
                        <Column dataType="string" dataField='iccid' />
                        <Column dataType="string" dataField='device_id' />
                        <Column dataType="number" dataField='rssi'/>
                        <Column dataType="datetime" dataField='received'/>
                        <Column dataType="number" dataField='timestamp'/>
                        <Column cellRender={buttonColumnTemplate } dataField='image' />
                        
                        </DataGrid>
                </div>}
            </div>
        </>
    )
}
export default SmartCameraOverview