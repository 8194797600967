import React, { ReactNode } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { MenuItem, Select } from '@mui/material';
// @mui/icons-material
import Clear from "@mui/icons-material/Clear";
import Check from "@mui/icons-material/Check";


interface Props {
    classes: any;
    labelText: ReactNode;
    labelProps: any;
    id: string;
    inputProps: any;
    formControlProps: any;
    error: boolean;
    success: boolean;
}


export default function WindowPeriod(props: Props) {
    const {
        classes,
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        success
    } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });
    const marginTop = classNames({
        [classes.marginTop]: labelText === undefined
    });
    // input value = 00:00-23:00
    const inputValue = inputProps.value;
    const valueFrom = inputValue.substr(0, 5);
    const valueTo = inputValue.substr(6, 5);

    return (
            <div>
            From <FormControl
                {...formControlProps}
                className={formControlProps.className + " " + classes.formControl}
                style={{ margin: 0, paddingBottom: 0 }}
            >
                {labelText !== undefined ? (
                    <InputLabel
                        className={classes.labelRoot + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                    >
                        {labelText}
                    </InputLabel>
                ) : null}
                <Select
                    classes={{
                        root: marginTop,
                        disabled: classes.disabled,
                    }}
                    id={id}
                    value={valueFrom}
                    disabled={inputProps.disabled}
                    name={inputProps.name + "Start"}
                    onChange={inputProps.onChange}
                >
                    <MenuItem value="">Never</MenuItem>
                    <MenuItem value="00:00">00:00</MenuItem>
                    <MenuItem value="01:00">01:00</MenuItem>
                    <MenuItem value="02:00">02:00</MenuItem>
                    <MenuItem value="03:00">03:00</MenuItem>
                    <MenuItem value="04:00">04:00</MenuItem>
                    <MenuItem value="05:00">05:00</MenuItem>
                    <MenuItem value="06:00">06:00</MenuItem>
                    <MenuItem value="07:00">07:00</MenuItem>
                    <MenuItem value="08:00">08:00</MenuItem>
                    <MenuItem value="09:00">09:00</MenuItem>
                    <MenuItem value="10:00">10:00</MenuItem>
                    <MenuItem value="11:00">11:00</MenuItem>
                    <MenuItem value="12:00">12:00</MenuItem>
                    <MenuItem value="13:00">13:00</MenuItem>
                    <MenuItem value="14:00">14:00</MenuItem>
                    <MenuItem value="15:00">15:00</MenuItem>
                    <MenuItem value="16:00">16:00</MenuItem>
                    <MenuItem value="17:00">17:00</MenuItem>
                    <MenuItem value="18:00">18:00</MenuItem>
                    <MenuItem value="19:00">19:00</MenuItem>
                    <MenuItem value="20:00">20:00</MenuItem>
                    <MenuItem value="21:00">21:00</MenuItem>
                    <MenuItem value="22:00">22:00</MenuItem>
                    <MenuItem value="23:00">23:00</MenuItem>
                </Select>
                {error ? (
                    <Clear className={classes.feedback + " " + classes.labelRootError} />
                ) : success ? (
                    <Check className={classes.feedback + " " + classes.labelRootSuccess} />
                ) : null}
            </FormControl>
            To <FormControl
                    {...formControlProps}
                    className={formControlProps.className + " " + classes.formControl}
                    style={{ margin: 0, paddingBottom: 0 }}
                >
                <Select
                    classes={{
                        root: marginTop,
                        disabled: classes.disabled,
                    }}
                    id={id}
                    value={valueTo}
                    disabled={inputProps.disabled}
                    name={inputProps.name + "End"}
                    onChange={inputProps.onChange}
                >
                    <MenuItem value="">Never</MenuItem>
                    <MenuItem value="00:00">00:00</MenuItem>
                    <MenuItem value="01:00">01:00</MenuItem>
                    <MenuItem value="02:00">02:00</MenuItem>
                    <MenuItem value="03:00">03:00</MenuItem>
                    <MenuItem value="04:00">04:00</MenuItem>
                    <MenuItem value="05:00">05:00</MenuItem>
                    <MenuItem value="06:00">06:00</MenuItem>
                    <MenuItem value="07:00">07:00</MenuItem>
                    <MenuItem value="08:00">08:00</MenuItem>
                    <MenuItem value="09:00">09:00</MenuItem>
                    <MenuItem value="10:00">10:00</MenuItem>
                    <MenuItem value="11:00">11:00</MenuItem>
                    <MenuItem value="12:00">12:00</MenuItem>
                    <MenuItem value="13:00">13:00</MenuItem>
                    <MenuItem value="14:00">14:00</MenuItem>
                    <MenuItem value="15:00">15:00</MenuItem>
                    <MenuItem value="16:00">16:00</MenuItem>
                    <MenuItem value="17:00">17:00</MenuItem>
                    <MenuItem value="18:00">18:00</MenuItem>
                    <MenuItem value="19:00">19:00</MenuItem>
                    <MenuItem value="20:00">20:00</MenuItem>
                    <MenuItem value="21:00">21:00</MenuItem>
                    <MenuItem value="22:00">22:00</MenuItem>
                    <MenuItem value="23:00">23:00</MenuItem>
                </Select>
                    {error ? (
                        <Clear className={classes.feedback + " " + classes.labelRootError} />
                    ) : success ? (
                        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
                    ) : null}
                </FormControl>
                </div>

    );
}

WindowPeriod.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool
};