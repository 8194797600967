import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from '@mui/material/Badge';

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-react/components/customTabsStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTabs(props) {
    const [value, setValue] = React.useState(props.value || 0);
    const handleChange = (event, value) => {
        setValue(value);
        if (props.onChange) {
            props.onChange(event, value);
        }
    };
    const classes = useStyles();
    const { headerColor, plainTabs, tabs, title, rtlActive } = props;
    const cardTitle = classNames({
        [classes.cardTitle]: true,
        [classes.cardTitleRTL]: rtlActive
    });
    if (props.value != undefined && props.value != value) {
        setValue(props.value);
    }
    return (
        <Card plain={plainTabs}>
            <CardHeader style={{
                display: 'flex',
                alignItems: 'center'
            }} color={headerColor} plain={plainTabs}>
                {title !== undefined ? <div className={cardTitle}>
                    <h3 className={classes.cardTitle}>
                        {title}
                    </h3></div> : null}
                <Tabs 
                    value={value}
                    onChange={handleChange}
                    classes={{
                        root: classes.tabsRoot,
                        indicator: classes.displayNone,
                        scrollButtons: classes.displayNone
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {tabs.map((prop, key) => {
                        let icon = {};
                        if (prop && prop.tabIcon) {
                            icon = {
                                icon: <prop.tabIcon />
                            };
                        }
                        return (
                                prop != null ?
                                <Tab
                                    classes={{
                                        root: classes.tabRootButton,
                                        selected: classes.tabSelected,
                                        wrapper: classes.tabWrapper
                                    }}
                                    key={key}
                                    disabled={prop.disabled}
                                    label={<Badge key={key} badgeContent={prop.tabAlerts ? prop.tabAlerts : 0} color="error" className={classNames(classes.itemIcon, {[classes.itemIconRTL]: props.rtlActive
                                    })}>{prop.tabName}</Badge>}
                                    {...icon}
                                />
                                :
                                <div key={key} />
                        );
                    })}
                </Tabs>
            </CardHeader>
            <CardBody>
                {tabs.map((prop, key) => {
                    if (key === value) {
                        return <div key={key}>{prop.tabContent}</div>;
                    }
                    return null;
                })}
            </CardBody>
        </Card>
    );
}

CustomTabs.propTypes = {
    headerColor: PropTypes.oneOf([
        "warning",
        "success",
        "danger",
        "info",
        "primary",
        "rose"
    ]),
    title: PropTypes.string,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            tabName: PropTypes.string.isRequired,
            tabIcon: PropTypes.object,
            tabContent: PropTypes.node.isRequired,
            tabAlerts: PropTypes.number,
            disabled: PropTypes.bool,
        })
    ),
    rtlActive: PropTypes.bool,
    plainTabs: PropTypes.bool,
    value: PropTypes.number,
    onChange: PropTypes.func,
};
