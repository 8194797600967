import { Checkbox } from "@mui/material";
import React, { ReactNode } from "react";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    filters: string[];
    onFilterChange: (MapFilter: boolean[]) => void;
}

interface State {
    showRegions: boolean;
    showAllSites: boolean;
    showLoggers: boolean;
    showGroups: boolean;
    showAlarms0: boolean;
    showAlarms1: boolean;
    showAlarmsN: boolean;
    showRainfall: boolean;
}

export class MapFilter extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            showRegions: false,
            showAllSites: false,
            showLoggers: false,
            showGroups: false,
            showAlarms0: false,
            showAlarms1: false,
            showAlarmsN: false,
            showRainfall: false,
        };
    }

    filterChange(e: any): void {
        const filter = [this.state.showRegions,
            this.state.showAllSites,
            this.state.showRainfall,
            this.state.showAlarms0,
            this.state.showAlarms1,
            this.state.showAlarmsN,
            this.state.showGroups
        ]
        if (e.target.value == "Regions") {
            this.setState({ showRegions: !this.state.showRegions });
            filter[0] = !this.state.showRegions;
        }
        if (e.target.value == "Sites") {
            this.setState({ showAllSites: !this.state.showAllSites });
            filter[1] = !this.state.showAllSites;
        }
        if (e.target.value == "Rainfall") {
            this.setState({ showRainfall: !this.state.showRainfall });
            filter[2] = !this.state.showRainfall;
        }
        if (e.target.value == "Alarms0") {
            this.setState({ showAlarms0: !this.state.showAlarms0 });
            filter[3] = !this.state.showAlarms0;
        }
        if (e.target.value == "Alarms1") {
            this.setState({ showAlarms1: !this.state.showAlarms1 });
            filter[4] = !this.state.showAlarms1;
        }
        if (e.target.value == "AlarmsN") {
            this.setState({ showAlarmsN: !this.state.showAlarmsN });
            filter[5] = !this.state.showAlarmsN;
        }

        this.props.onFilterChange(filter);
    }

    render(): ReactNode {

        return (
            <div >
                { this.props.filters[0] == "Regions" && <span><Checkbox checked={this.state.showRegions} value={"Regions"} onChange={this.filterChange.bind(this)} />{this.props.filters[0]}</span>}
                { this.props.filters[2] == "Rainfall" && <span><Checkbox checked={this.state.showRainfall} value={"Rainfall"} onChange={this.filterChange.bind(this)} />{this.props.filters[2]}</span>}
                { this.props.filters[1] == "Sites" && <span style={{ whiteSpace: "nowrap" }}><Checkbox checked={this.state.showAllSites} value={"Sites"} onChange={this.filterChange.bind(this)} />{this.props.filters[1]} (without loggers)</span>}
                { this.props.filters.includes("Alarms") &&
                    <span style={{ whiteSpace: "nowrap" }}>
                        <Checkbox checked={this.state.showAlarms0} value={"Alarms0"} onChange={this.filterChange.bind(this)} />No Alarms
                        <Checkbox checked={this.state.showAlarms1} value={"Alarms1"} onChange={this.filterChange.bind(this)} />1 Alarm
                        <Checkbox checked={this.state.showAlarmsN} value={"AlarmsN"} onChange={this.filterChange.bind(this)} />&gt;1 Alarm
                    </span>
                }
            </div>
        );

    }
}
export default MapFilter;