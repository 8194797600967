import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import CardBody from "components/Card/CardBody.js";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

import { withStyles, createStyles } from '@mui/styles';
const style = createStyles(styles);

export class GraphTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { classes } = this.props;

        return (
            <div >
                <Card>
                    <CardHeader color={this.props.color}>
                        <ChartistGraph
                            className="ct-chart"
                            data={this.props.chartData.data}
                            type={this.props.chartType}
                            options={this.props.chartData.options}
                            listener={this.props.chartData.animation}
                        />
                    </CardHeader>
                    <CardBody>
                        <h4 className={classes.cardTitle}>{this.props.title}</h4>
                        <div className={classes.cardCategory}>
                            {this.props.content}
                            <div style={{
                                backgroundColor: '#ffa726',
                            }}>
                                {this.props.legend.map((entry) => {
                                    return (<div style={{ color: entry.color }} key={entry.title}>{entry.title}</div>);
                                })
                            }
                            </div>
                        </div>
                    </CardBody>
                    <CardFooter chart>
                        <div className={classes.stats}>
                            {this.props.footerIcon}
                            {this.props.footerContent}
                    </div>
                    </CardFooter>
                </Card>
            </div>
        );

    }
}
export default withStyles(style)(GraphTile);