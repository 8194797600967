import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { CreateUrl, CallGetAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerConfigurationsModel, buildAPIGetLoggerConfigurationsModel } from "models/APIGetLoggerConfigurationsModel";
import { APIGetFirmwaresModel, buildAPIFirmwaresModel } from "models/APIGetFirmwaresModel";

// Modal
import Modal from 'react-bootstrap/Modal'
import { Container as BSContainer, Row as BSRow, Col as BSCol } from 'reactstrap';
import TextField from '@mui/material/TextField';
import MaterialButton from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import { withStyles, createStyles } from '@mui/styles';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import SimCardIcon from '@mui/icons-material/SimCard';
import FilePicker from 'components/File/FilePicker';
import BackupIcon from '@mui/icons-material/Backup';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


//DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { DataGrid, Column, Export, Scrolling, FilterRow, StateStoring, Pager, Paging, HeaderFilter } from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';
import Button from "@mui/material/Button";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { dxDataGridRowObject } from 'devextreme/ui/data_grid';
import { AppBar, Chip, Typography } from '@mui/material';
import ToolkitTOR from './ToolkitTOR';

const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    preWrap: {
        whiteSpace: "preWrap",
    }
};

const style = createStyles(styles);
let flsFile = 'No file selected';
let fileReader: FileReader;

let modalTitle = "";

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serial: string;
    model: string;
    modelId: string;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    maxAlarms: number;
    startDate: Date;
    endDate: Date;
    configurationsData: Array<APIGetLoggerConfigurationsModel>;
    firmwareData: Array<APIGetFirmwaresModel>;
    updates: number;
    firmware: number;
    newFirmware: number;
    modal: boolean;
    oldSiteId: string;
    newSiteId: string;
    updateJson: string;
    deviceConfig: string;
    firmwareFLSloaded: boolean;
    firmwareMajor: string;
    firmwareMajorError: boolean;
    firmwareMinor: string;
    firmwareMinorError: boolean;
    firmwareRc: string;
    firmwareRcError: boolean;
    updateFirmware: number;
    tempConfig: APIGetLoggerConfigurationsModel | undefined;
    anchorEl: EventTarget & HTMLButtonElement | null;
    configModal: boolean;
    configDetail: Array<string>;
    swVersion: number;
    devVersion: number;
}

class ConfigurationPanel extends React.Component<Props, State> {
    notificationSystem = React.createRef();

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            maxAlarms: 0,
            startDate: moment().subtract(30, 'days').toDate(),
            endDate: moment().toDate(),
            configurationsData: [],
            firmwareData: [],
            updates: 0,
            firmware: 0,
            newFirmware: 0,
            modal: false,
            oldSiteId: '',
            newSiteId: '',
            updateJson: '',
            deviceConfig: '',
            firmwareFLSloaded: false,
            firmwareMajor: "",
            firmwareMajorError: false,
            firmwareMinor: "",
            firmwareMinorError: false,
            firmwareRc: "",
            firmwareRcError: false,
            updateFirmware: 0,
            tempConfig: undefined,
            anchorEl: null,
            configModal: false,
            configDetail: [""],
            swVersion: 0,
            devVersion: 0,
        };
    }

    componentDidMount(): void {

        //get alarms for logger here
        if (this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({ loading: false, tableHidden: false });
        }
        else {
            this.setState({ loading: false, tableHidden: true });
        }

    }

    // Model only updated after initial render - reload data again
    componentDidUpdate(nextProps: Props): void {
        if (nextProps.model != this.props.model) {
            this.reloadData(this.state.startDate, this.state.endDate);
        }
    }

    reloadData(start: Date, end: Date): boolean {
        let configData = new Array<APIGetLoggerConfigurationsModel>();
        let firmwareData = new Array<APIGetFirmwaresModel>();
        let updates = 0;
        let siteId = '';
        let updateJson = '';
        let firmwareToUpdate = 0;
        let swVersion = 0;
        let devVersion = 0;

        //get alarms for logger here
        const me = this;

        if (me.props.serial != undefined) {

            const promises = new Array<Promise<any>>();
            // generate array of Promises each adding the configurationsData for a logger
            promises.push(
                CallGetAPI(CreateUrl('/api/aquaguard/LoggerConfigurations?serial=' + me.props.serial + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"), {})
                    .then(json => {
                        configData = configData.concat(buildAPIGetLoggerConfigurationsModel(json));
                    })
                    .catch(function (ex) {
                        me.setState(
                            {
                                authorized: false,
                                configurationsData: []
                            });
                        console.log(ex);
                    }),
                CallGetAPI(CreateUrl('/api/aquaguard/Firmware?model=' + me.props.model), {})
                    .then(json => {
                        firmwareData = firmwareData.concat(buildAPIFirmwaresModel(json));
                    })
                    .catch(function (ex) {
                        me.setState(
                            {
                                authorized: false,
                                firmwareData: []
                            });
                        console.log(ex);
                    })
            );

            Promise.all(promises).then(() => {

                configData.forEach((v: APIGetLoggerConfigurationsModel) => {
                    v.createdTime = adjustTime(v.createdTime);
                    v.startTime = adjustTime(v.startTime);
                    if (v.sendToLogger) {
                        updates++;
                        siteId = v.siteId;
                        updateJson = v.updateJson;
                    }
                    if (v.endTime == null) {
                        firmwareToUpdate = v.firmwareToUpdate;
                        swVersion = v.swVersion & 0x7FFF;
                        devVersion = v.devVersion;
                    }
                    else {
                        v.endTime = adjustTime(v.endTime);
                    }
                });

                // Determine which firmware to show (previous, current and any newer) and set alert if newer
                let newFw = 0;
                let prevFw = 0;
                let prevFwVersion = 0;
                const currentVersion = swVersion * 256 + devVersion;
                firmwareData.forEach((f: APIGetFirmwaresModel, i: number) => {
                    const fwVersion = f.version * 256 + f.devVersion;
                    if (fwVersion < currentVersion) {
                        // Older firmware
                        if (fwVersion > prevFwVersion) {
                            prevFw = i;
                            prevFwVersion = fwVersion;
                        }
                    }
                    else if (fwVersion > currentVersion) {
                        // Newer firmware
                        newFw++;
                    }
            })
                // Assuming entries are in date order
                for (let j = 0; j < prevFw; j++) {
                    firmwareData.shift();
                }

                me.setState({
                    configurationsData: configData,
                    firmwareData: firmwareData,
                    firmware: firmwareData.length,
                    updates: updates,
                    newFirmware: newFw,
                    oldSiteId: siteId,
                    newSiteId: siteId,
                    updateJson: updateJson,
                    updateFirmware: firmwareToUpdate,
                    swVersion: swVersion,
                    devVersion: devVersion,
                });
            });
        }
        return true;
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    };

    //edit row
    editSiteId = (): void => {
        this.setState({
            modal: !this.state.modal
        });
        modalTitle = "Update SiteId for " + this.props.serial;
    }

    //field changed events---
    deviceSiteChanged = (event: any): void => {
        this.setState({ newSiteId: event.target.value });
    }

    configUpdateChanged = (event: any): void => {
        this.setState({ deviceConfig: event.target.value });
    }

    configSend = (config: string): void => {

        this.setState({ deviceConfig: config });
        this.saveDevice(config);
    }

    //save changes, for both edit and new
    saveDevice = (config = ""): void => {

        this.setState({
            modal: !this.state.modal,
            updates: 1
        });

        //********************
        //send config update to API

        const me = this;
        let updateJson = '{';

        if (this.state.oldSiteId != this.state.newSiteId) {
            const encodedSiteId = this.strToHexBytes(this.state.newSiteId) + "000000000000000000000000000000";
            updateJson = updateJson + '"0081":"' + encodedSiteId.substr(0, 30) + '",';
        }

        if (this.state.deviceConfig != "") {
            const lines = this.state.deviceConfig.split(/\r?\n/);
            lines.map((line: string) => {
                if (line.indexOf('=') == -1) {
                    // CMD with no value
                    if (line != '') {
                        updateJson = updateJson + '"' + line + '":"",';
                    }
                }
                else {
                    const entry = line.split("=");
                    if (entry[0] != "") {
                        updateJson = updateJson + '"' + entry[0] + '":"' + entry[1] + '",';
                    }
                }
            })
        }

        updateJson = updateJson + "}";

        if (config != "") {
            updateJson = config;
        }

        if (updateJson != "{}") {
            this.setState({ updateJson: updateJson });

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ serial: me.props.serial, updateJson: updateJson })
            };

            CallPostAPI(CreateUrl('/api/Aquaguard/LoggerConfigurations'), requestOptions)
                .then(response => {
                    if (response.ok) {
                        me.createSuccessNotification('Device will be updated on next connection');
                    }
                    else {
                        me.createErrorNotification('Error Saving Changes');
                    }
                    console.log(response);
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Changes');
                    console.log(error);
                });
        }
        else {
            this.setState({ updateJson: '' });
        }

    }

    //do nothing, close the modal
    cancel = (): void => {
        this.toggleModal();
    }

    clearConfig = (): void => {
        const me = this;
        const updateJson = '{}';
        this.setState({ updateJson: '' });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ serial: me.props.serial, updateJson: updateJson })
        };

        CallPostAPI(CreateUrl('/api/Aquaguard/LoggerConfigurations'), requestOptions)
            .then(response => {
                if (response.ok) {
                    me.createSuccessNotification('Device will be updated on next connection');
                }
                else {
                    me.createErrorNotification('Error Saving Changes');
                }
                console.log(response);
            })
            .catch(function (error) {
                me.createErrorNotification('Error Saving Changes');
                console.log(error);
            });
    }

    createSuccessNotification = (msg: string): void => {

        NotificationManager.success(msg, 'Success')

    };

    createErrorNotification = (msg: string): void => {

        NotificationManager.error(msg, '', 5000);

    };

    sendFirmware = (id: number): void => {

        // Send logger update to load firmware
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json' },
            body: JSON.stringify({firmwareId: id, serial: parseInt(this.props.serial)})
        };

        const me = this;

        CallPostAPI(CreateUrl('/api/aquaguard/SendFirmware'), requestOptions)
            .then(data => {
                console.log(data);
                me.createSuccessNotification("Logger flagged for firmware update");
                this.reloadData(this.state.startDate, this.state.endDate);
            })
            .catch(function (error) {
                console.log(error);
                me.createErrorNotification('Error updating logger');
            });

    }

    majorVerChanged = (event: any): void => {
        try {
            if (!isNaN(parseInt(event.target.value))) {
                this.setState({ firmwareMajor: event.target.value, firmwareMajorError: false });
            }
            else {
                this.setState({ firmwareMajorError: true });
            }
        }
        catch (error) {
            this.setState({ firmwareMajorError: true });
        }
    }

    minorVerChanged = (event: any): void => {

        try {
            if (!isNaN(parseInt(event.target.value))) {
                this.setState({ firmwareMinor: event.target.value, firmwareMinorError: false });
            }
            else {
                this.setState({ firmwareMinorError: true });
            }
        }
        catch (error) {
            this.setState({ firmwareMinorError: true });

        }
    }

    rcChanged = (event: any): void => {

        try {
            if (!isNaN(parseInt(event.target.value))) {
                this.setState({ firmwareRc: event.target.value, firmwareRcError: false });
            }
            else {
                this.setState({ firmwareRcError: true });
            }
        }
        catch (error) {
            this.setState({ firmwareRcError: true });

        }
    }

    readFile = (file: Array<File>): void => {
        fileReader = new FileReader();
        fileReader.onloadend = this.handleFileRead;
        fileReader.readAsDataURL(file[0]);

        flsFile = file[0].name;
        this.setState({ firmwareFLSloaded: false });
    }

    removeFile = (): void => {
        flsFile = 'No file selected';
        this.setState({ firmwareFLSloaded: false });
    }

    handleFileRead = (e: any): void => {

        this.setState({ firmwareFLSloaded: true });
    }

    uploadFLSClick = (e: any): void => {
        let fileAsBase64 = fileReader.result as string;
        fileAsBase64 = fileAsBase64.replace("data:application/octet-stream;base64,", "");

        const versionStr = this.state.firmwareMajor + "." + this.state.firmwareMinor;
        const version = parseInt(this.state.firmwareMajor) * 256 + parseInt(this.state.firmwareMinor);

        const content: APIGetFirmwaresModel = {
            id: 0,
            model: this.props.model,
            versionStr: versionStr,
            version: version,
            filename: flsFile,
            fls: fileAsBase64,
            devVersion: parseInt(this.state.firmwareRc),
        };

        // Send Firmware file name and contents to API
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json' },
            body: JSON.stringify(content)
        };

        const me = this;

        CallPostAPI(CreateUrl('/api/aquaguard/FirmwareFLS'), requestOptions)
            .then(data => {
                console.log(data);
                me.createSuccessNotification("Firmware FLS file uploaded");
                flsFile = 'No file selected';
                this.setState({ firmwareFLSloaded: false });
                this.reloadData(this.state.startDate, this.state.endDate);
            })
            .catch(function (error) {
                console.log(error);
                me.createErrorNotification('Error uploading file');
            });


    };


    /**
     * Convert a string to a unicode byte array
     * @param {string} str
     * @return {string} ASCII encoded values
     */
    strToHexBytes(str: string): string {
        const utf8 = new Array<string>();
        for (let ii = 0; ii < str.length; ii++) {
            const charCode = str.charCodeAt(ii);
            if (charCode < 0x80) utf8.push(charCode.toString(16).toUpperCase());

        }
        return utf8.join("");
    }


    startDateChanged(e: any): void {
        this.setState({
            startDate: e.value
        });

        if (!this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({ tableHidden: true });
        }
    }

    endDateChanged(e: any): void {
        this.setState({
            endDate: e.value
        });

        if (!this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({  tableHidden: true });
        }
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm");
    }

    handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: dxDataGridRowObject): void {   //React.MouseEvent<HTMLButtonElement, MouseEvent>
        const config = this.state.configurationsData.find((row) => row.id === id.key);

        this.setState(
            {
                tempConfig: config,
                anchorEl: event.currentTarget
            });
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    stdConfigClick = (): void => {

        this.setState({
            configModal: true,
            configDetail: this.state.tempConfig?.stdParamJson ? this.state.tempConfig?.stdParamJson.replace("{","").replace("}","").split(',') : [""],
            anchorEl: null,
        });
    }

    calConfigClick = (): void => {
        this.setState({
            configModal: true,
            configDetail: this.state.tempConfig?.calParamJson ? this.state.tempConfig?.calParamJson.replace("{", "").replace("}", "").split(',') : [""],
            anchorEl: null,
        });

    }

    toggleConfigModal = (): void => {
        this.setState({ configModal: !this.state.configModal });
    }


    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Configurations');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    moreRender(key: dxDataGridRowObject): ReactNode {
        return (
            <IconButton size="small" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => { this.handleClick(e, key); }}><MoreHorizIcon /></IconButton>
        );
    }


    render(): ReactNode {

        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.state.modal}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={(): void => this.toggleModal()}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {this.props.model} Configuration
                            </Typography>
                        </Toolbar>
                    </AppBar>
                        <ToolkitTOR serial={this.props.serial} model={this.props.modelId} product={this.props.model} onConfigSend={this.configSend} />
                </Dialog>
                        {
                            /*
                        <BSContainer>
                            <BSRow>
                                <BSCol>
                                    <CustomTabs
                                        headerColor="info"
                                        tabs={[
                                            {
                                                tabName: "General",
                                                tabContent: (
                                                    <div>
                                                        <p>General Settings</p>
                                                        <TextField
                                                            id="outlined-input"
                                                            defaultValue={this.state.oldSiteId}
                                                            label="SiteId"
                                                            onChange={this.deviceSiteChanged}
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                    )
                                            },
                                            {
                                                tabName: "Channel A",
                                                tabContent: (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            Pulse Channel A Settings
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                            id="outlined-input-ARef"
                                                            defaultValue=""
                                                            label="Channel ref"
                                                            variant="outlined"
                                                        />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                        <TextField
                                                            id="outlined-input-AMult"
                                                            defaultValue=""
                                                            label="Multiplier"
                                                            variant="outlined"
                                                        />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            Alarms
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                        <TextField
                                                            id="outlined-input-ASet"
                                                            defaultValue=""
                                                            label="Alarm Set level"
                                                            variant="outlined"
                                                        />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                        <TextField
                                                            id="outlined-input-AClr"
                                                            defaultValue=""
                                                            label="Alarm Clear level"
                                                            variant="outlined"
                                                        />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            },
                                            {
                                                tabName: "Channel B",
                                                tabContent: (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            Pulse Channel B Settings
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-BRef"
                                                                defaultValue=""
                                                                label="Channel ref"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-BMult"
                                                                defaultValue=""
                                                                label="Multiplier"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            Alarms
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-BSet"
                                                                defaultValue=""
                                                                label="Alarm Set level"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-BClr"
                                                                defaultValue=""
                                                                label="Alarm Clear level"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            },
                                            {
                                                tabName: "Channel C",
                                                tabContent: (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            Analogue Channel C Settings
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-CRef"
                                                                defaultValue=""
                                                                label="Channel ref"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-CDp"
                                                                defaultValue=""
                                                                label="Decimal Places"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-CUnits"
                                                                defaultValue=""
                                                                label="Units"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            Alarms
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-CSet"
                                                                defaultValue=""
                                                                label="Alarm Set level"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-CClr"
                                                                defaultValue=""
                                                                label="Alarm Clear level"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            },
                                            {
                                                tabName: "Channel D",
                                                tabContent: (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            Analogue Channel D Settings
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-DRef"
                                                                defaultValue=""
                                                                label="Channel ref"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-DDp"
                                                                defaultValue=""
                                                                label="Decimal Places"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-DUnits"
                                                                defaultValue=""
                                                                label="Units"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            Alarms
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-DSet"
                                                                defaultValue=""
                                                                label="Alarm Set level"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-DClr"
                                                                defaultValue=""
                                                                label="Alarm Clear level"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                               )
                                            },
                                            {
                                                tabName: "Channel E",
                                                tabContent: (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            Analogue Channel E Settings
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-ERef"
                                                                defaultValue=""
                                                                label="Channel ref"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-EDp"
                                                                defaultValue=""
                                                                label="Decimal Places"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-EUnits"
                                                                defaultValue=""
                                                                label="Units"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            Alarms
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-ESet"
                                                                defaultValue=""
                                                                label="Alarm Set level"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                id="outlined-input-EClr"
                                                                defaultValue=""
                                                                label="Alarm Clear level"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            },
                                            {
                                                tabName: "Custom",
                                                tabContent: (
                                                    <div>
                                                       <p>Custom Settings</p>
                                                       <TextField
                                                            id="generalUpdate"
                                                            label="Updates 0000=xxx (one per line)"
                                                            onChange={this.configUpdateChanged}
                                                            variant="outlined"
                                                            multiline
                                                            fullWidth
                                                        />
                                                    </div>
                                                )
                                            },
                                        ]}
                                        />
                                </BSCol>
                            </BSRow>
                        </BSContainer>
                        */}

                <Dialog
                    fullWidth
                    open={this.state.configModal}
                    onClose={(): void => this.toggleConfigModal()}
                    aria-labelledby="example-custom-modal-styling-title">
                    <Toolbar>
                        <MaterialButton color="secondary" onClick={(): void => this.toggleConfigModal()}>Close</MaterialButton>
                    </Toolbar>
                    <Modal.Body className="show-grid">
                        <div style={{width: "500px"}}>
                        {this.state.configDetail.map((c: string) => {
                            return <div key={c}>{c.replace(/"/g,"").replace(":","=")}</div>;
                        })}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <MaterialButton color="secondary" onClick={(): void => this.toggleConfigModal()}>Close</MaterialButton>
                    </Modal.Footer>
                </Dialog>


                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                            <ApiFailed />
                        }
                    {!this.state.tableHidden &&
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                                Start date :&nbsp;<DateBox id="startDate" displayFormat={"dd/MM/yyyy"} value={this.state.startDate} onValueChanged={this.startDateChanged.bind(this)} />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                End date :&nbsp;<DateBox id="endDate" displayFormat={"dd/MM/yyyy"} value={this.state.endDate} onValueChanged={this.endDateChanged.bind(this)} />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomTabs
                                    headerColor="info"
                                    tabs={[
                                        {
                                            tabName: "Data",
                                            tabIcon: TableChartOutlinedIcon,
                                            tabContent: (
                                                <DataGrid
                                                    dataSource={this.state.configurationsData}
                                                    keyExpr="id"
                                                    columnAutoWidth={true}
                                                    columnMinWidth={80}
                                                    onExporting={this.onExporting} >
                                                    <Scrolling mode="standard" useNative={true} />
                                                    <FilterRow visible={true} />
                                                    <Export enabled={true} />
                                                    <HeaderFilter visible={true} />
                                                    <StateStoring enabled={true} type="localStorage" storageKey="configurationsPanelGrid" />
                                                    <Column dataField="id" visible={false} dataType="number" />
                                                    <Column dataField="createdTime" customizeText={this.dateColumnCustomizeText} dataType="datetime" defaultSortIndex={1} defaultSortOrder="desc" caption="Created" allowHeaderFiltering={false}/>
                                                    <Column dataField="startTime" customizeText={this.dateColumnCustomizeText} dataType="datetime" caption="Start" allowHeaderFiltering={false}/>
                                                    <Column dataField="endTime" customizeText={this.dateColumnCustomizeText} dataType="datetime" caption="End" allowHeaderFiltering={false}/>
                                                    <Column dataField="user1" caption="Site Id" dataType="string" />
                                                    <Column dataField="setLevels" caption="Alarm Set" dataType="string" encodeHtml={false}/>
                                                    <Column dataField="clrLevels" caption="Alarm Clear" dataType="string" encodeHtml={false}/>
                                                    <Column dataField="stdParamCrc" dataType="number"/>
                                                    <Column dataField="calParamCrc" dataType="number" />

                                                    <Column type="buttons" cellRender={this.moreRender.bind(this)} fixed={true} fixedPosition="right" />

                                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                    <Paging defaultPageSize={10} />
                                                </DataGrid>
                                            )
                                        },
                                        {
                                            tabName: "Update",
                                            tabIcon: PollOutlinedIcon,
                                            tabAlerts: this.state.updates,
                                            tabContent: (
                                                <div>
                                                    {this.state.updates > 0 &&
                                                        <div>
                                                        Config Updates to be sent:
                                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                            {this.state.updateJson.replace(/,/g, "\n")}
                                                            </div>
                                                            <Button onClick={(): void => { this.clearConfig() }} >
                                                                Click to clear config update
                                                            </Button>
                                                        </div>
                                                    }
                                                    <Button onClick={(): void => {this.setState({modal: true})}} >
                                                            Click to update device config
                                                    </Button>

                                                </div>

                                                 )
                                        },
                                        {
                                            tabName: "Firmware",
                                            tabIcon: SimCardIcon,
                                            tabAlerts: this.state.newFirmware,
                                            tabContent: (
                                                    <Grid container spacing={2}>
                                                     {this.state.firmware > 0 &&
                                                        <Grid item xs={12} >
                                                            Firmware Updates available:
                                                            <div>
                                                            {this.state.firmwareData.map((fw: APIGetFirmwaresModel) => {
                                                                return (<div key={fw.versionStr + "rc" + fw.devVersion}>
                                                                    Version: {fw.versionStr} {fw.devVersion > 0 ? "rc" + fw.devVersion : ""} &nbsp; {fw.filename} 
                                                                    {this.state.swVersion == fw.version && this.state.devVersion == fw.devVersion ?
                                                                        <span>&nbsp; - Currently installed</span>
                                                                    :
                                                                        this.state.updateFirmware == fw.id ?
                                                                                <span>
                                                                                    &nbsp; - Firmware to be uploaded on next connection
                                                                                    < Button onClick={(): void => this.sendFirmware(0)}>
                                                                                                Click to cancel upload
                                                                                    </Button>
                                                                                </span>
                                                                                :
                                                                                < Button onClick={(): void => this.sendFirmware(fw.id)}>
                                                                                    Click to send this Firmware
                                                                        </Button>
                                                                    }
                                                                </div>)
                                                            })}
                                                            </div>
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12} >
                                                        <h3>Firmware Upload</h3>
                                                        Model: {this.props.model}
                                                    </Grid>
                                                    <Grid item xs={8} >
                                                        Version:
                                                        <TextField
                                                            id="outlined-input-majorVer"
                                                            label="Major version"
                                                            onChange={this.majorVerChanged}
                                                            variant="outlined"
                                                            size="small"
                                                            error={this.state.firmwareMajorError}
                                                            helperText={this.state.firmwareMajorError ? "Version must be numeric" : ""}
                                                        />
                                                        &nbsp;.&nbsp;
                                                        <TextField
                                                            id="outlined-input-minorVer"
                                                            label="Minor version"
                                                            onChange={this.minorVerChanged}
                                                            variant="outlined"
                                                            size="small"
                                                            error={this.state.firmwareMinorError}
                                                            helperText={this.state.firmwareMinorError ? "Version must be numeric" : ""}
                                                        />
                                                        &nbsp;rc&nbsp;
                                                        <TextField
                                                            id="outlined-input-rc"
                                                            label="rc"
                                                            onChange={this.rcChanged}
                                                            variant="outlined"
                                                            size="small"
                                                            error={this.state.firmwareRcError}
                                                            helperText={this.state.firmwareRcError ? "RC must be numeric" : ""}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        Select firmware file: 
                                                        <FilePicker
                                                            onChange={this.readFile}
                                                        >
                                                            <IconButton aria-label="delete" size="large">
                                                                <BackupIcon />
                                                            </IconButton>
                                                        </FilePicker>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {flsFile &&
                                                            <Chip
                                                                style={{
                                                                    position: 'relative', left: '15%', top: '50%',
                                                                    transform: 'translate(-0%, -50%)'
                                                                }}
                                                            label={flsFile} onDelete={(): void => this.removeFile()} color="primary" />
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <Button color="primary" onClick={this.uploadFLSClick} disabled={!this.state.firmwareFLSloaded && !this.state.firmwareMajorError && !this.state.firmwareMinorError} >
                                                                Upload FLS
                                                        </Button>
                                                    </Grid>
                                            </Grid>

                                            )
                                        }
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>

                    }
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                    >
                        <MenuItem onClick={this.stdConfigClick}>STD Config</MenuItem>
                        <MenuItem onClick={this.calConfigClick}>CAL Config</MenuItem>
                    </Menu>
                    <NotificationContainer />

                    </div>
                }

            </div>
        );
    }

}

export default withStyles(style)(ConfigurationPanel);