
import React from "react";
import { AuthContext } from "../../auth/authProvider.jsx";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Redirect } from 'react-router-dom'
import IsometricMap from "../../components/IsometricMap/IsometricMap.js";
import { ReactComponent as AshLogo } from '../../assets/ashLogo.svg';
import GutermannLogo from '../../assets/gutermann/gutermnn-full-brand-white.png'
import { withStyles, createStyles } from '@mui/styles';
import CardFooter from '../../components/Card/CardFooter.js';
import BG from '../../assets/BG-Aquaguard.jpg';
import { CreateUrl } from 'Utils/ApiHelper.js';
import Footer from '../../components/Navbars/footer.tsx'

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});
const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontSize: 60,
        fontWeight: window.location.hostname.includes('zonelog.net') ? "500" : "100",
        fontFamily: window.location.hostname.includes('zonelog.net') ? "'Montserrat', 'Helvetica', 'Arial', sans-serif" : "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0 auto",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
            textAlign: "center"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
};
const style = createStyles(styles);

export class LandingPage extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            forgotPassword: false,
            newRegister: false,
            enableSelfRegistration: false,

        }
    }

    componentDidMount() {
 
        const me = this;

        fetch(CreateUrl('/api/aquaguard/Version'), {})
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
            },
            // Reject
                () => {
                    window.sessionStorage.setItem("enableSelfRegistration", "false");
                }
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(async data => {
                if (data && data.enableSelfRegistration) {
                    window.sessionStorage.setItem("enableSelfRegistration", data.enableSelfRegistration ? "true" : "false");
                    me.setState({ enableSelfRegistration: data.enableSelfRegistration });
                }
                else {
                    window.sessionStorage.setItem("enableSelfRegistration", "false");
                    me.setState({ enableSelfRegistration: false });
                }
            });

    }

    

    signOut = () => {
        this.context.signoutRedirect();
    }

    signIn = () => {

        window.sessionStorage.setItem("unknownUser", "false");

        //logout if logged in
        if (this.context.isAuthenticated())
            this.context.logout();

        this.context.signinRedirect();

    }

    forgotPassword = () => {
        this.setState({ forgotPassword: true });
    }

    newRegister = () => {
        this.setState({ newRegister: true });
    }


    render() {

        const { classes } = this.props;

        return (

            (!this.state.forgotPassword && !this.state.newRegister) ? //if forgot password - redirect to resetpassword page
                (
                    <div style={{
                        backgroundImage: `url(${BG})`,
                        width: '100vw',
                        height: '100vh',
                        overflow: 'hidden',
                        

                }}>

                    <div className='some-page-wrapper'>
                        <div className='row'>

                            <div className='column' >
                                    <div>
                                        <Card style={{
                                            margin: "3vw 0 0 0",
                                            background: 'linear-gradient(130deg, rgba(0, 0, 0, 0.7) 0%, rgb(20,60,103, 0.6) 70%',
                                            width: "30vw",
                                            height: "80vh",
                                            boxShadow: "2px 2px 10px black",
                                            padding: "5vh 0 0 0"

                                        }}>
                                            <div>
                                                <CardHeader className={'center'}>
                                                    {window.location.hostname.includes('zonelog.net') ? <img src={GutermannLogo} style={{ width: "90%", height: "90%", margin: "60px 0 20px 0" }} />
                                                     : <AshLogo />}
                                                    <h3 className={classes.cardTitleWhite}>{window.location.hostname.includes('zonelog.net') ? "ZONELOG" : "AquaGuard"}</h3>
                                                </CardHeader>
                                                <CardBody>
                                                    <div style={{display: "flex", justifyContent: "space-around"} }>
                                                        <Button
                                                            style={window.location.hostname.includes('zonelog.net') ? {width: "60%", marginTop: "30px", fontFamily: 'Montserrat', fontWeight: '600'} : { width: '80%'}}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.signIn}
                                                        >
                                                        Login
                                                        </Button>
                                                    </div>
                                                    {window.sessionStorage.getItem("unknownUser") == 'true' &&
                                                        <div className={classes.cardCategoryWhite}>
                                                            <h6>Unrecognised User on this Portal</h6> 
                                                        </div>
                                                    }    
                                                </CardBody>
                                                <CardFooter>
                                                    <div className={classes.cardCategoryWhite}>
                                                        <a href="#" onClick={this.forgotPassword} style={{ textShadow: "0 0 2px black" }}><u>Forgot password?</u></a>
                                                        <br /><br /><br />
                                                        {!window.location.hostname.includes('zonelog.net') && <p style={{ textShadow: "0 0 2px black", color: "white" }}>Not registered?<br /> Click <a href="#" onClick={this.newRegister}><u>here</u></a> to create an Account</p>}
                                                    </div>
                                                </CardFooter>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                            <div style={{position: "fixed", bottom: 0, width: "100%"} }>
                                <Footer />
                            </div>
                        </div>
                    </div>
                )
                :
                (
                    this.state.forgotPassword ?
                        <Redirect to={'/forgotpassword'} />
                    :
                        <Redirect to={'/register'} />
                )
            )
    }
}

export default withStyles(style)(LandingPage);

