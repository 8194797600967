import { adjustTime } from '../Utils/AdjustTime'

export interface APIGetLoggerSummaryModel {
    /// <summary>
    /// Identity, Indexed, Required
    /// </summary>
    Id: number;

    FkServerId: number;

    /// <summary>
    /// Indexed, Required
    /// </summary>
    Serial: number;

    Swversion: string;

    ConfigurationId: string;

    CurrentConfig: number;

    ExpectedCrc: number;

    /// <summary>
    /// CRC for firmware
    /// </summary>
    BootstrapCrc: number;

    /// <summary>
    /// CRC for Standard parameters 
    /// </summary>
    StdParamCrc: number;

    /// <summary>
    /// CRC of calibration parameter
    /// </summary>
    CalParamCrc: number;

    ProductIdentifier: number;

    /// <summary>
    /// Firmware version
    /// </summary>
    FPGAVersion: number;

    CpuType: number;

    Devversion: number;

    RtcTime: Date;

    AlarmState: number;

    AlarmsEnabled: number;

    LatestRssi: number;

    LogFirst: number;

    /// <summary>
    /// Last log block used
    /// </summary>
    LogLast: number;

    LogMax: number;

    LogLastUpload: number;

    DeletedDate: Date;

    /// <summary>
    /// Battery status
    /// </summary>
    ReadBatteryChannel: number;

    /// <summary>
    /// Max length = 50
    /// </summary>
    SiteId: string;

    FinalBlockDownloaded: number;

    LastDatapoint: Date;

    AlarmMessage0: string;

    AlarmMessage1: string;

    AlarmMessage2: string;

    AlarmMessage3: string;

    AlarmMessage4: string;

    AlarmMessage5: string;

    AlarmMessage6: string;

    AlarmMessage7: string;

    AlarmMessage8: string;

    AlarmMessage9: string;

    AlarmMessage10: string;

    AlarmMessage11: string;

    AlarmMessage12: string;

    AlarmMessage13: string;

    AlarmMessage14: string;

    AlarmMessage15: string;

    SiteName: string;
    Model: string;              // This is displayed as Product
    Channels: string;
    LastConnected: Date;
    LastReading: Date;
    EASiteId: number; 
    EAStation: string;
    SiteNameUserFriendly: string;
    ModelId: string;        // This is displayed as Model
    FkSiteId: number;
    Deployed: Date | null;
    MeterConfig: number | null;
    FlowMultiplierA: number | null;
    ChannelAUnits: string;
    FlowMultiplierB: number | null;
    ChannelBUnits: string;
    ChannelCDp: number | null;
    ChannelCUnits: string;
    ChannelDDp: number | null;
    ChannelDUnits: string;
    ChannelEDp: number | null;
    ChannelEUnits: string;
    GpsLatitude: number | null;
    GpsLongitude: number | null;
    Dormant: boolean;
    ChannelsAvail: number | null;
    TransmissionFrequency: number | null;
    RelayStatus: boolean | null;
    What3Words: string | null;
    networkType: string | null;
    carrier: string | null;

}

export function buildAPIGetLoggerSummaryModel(r: any): APIGetLoggerSummaryModel {
    return {
        Id: r.id,
        FkServerId: r.fkServerId,
        Serial: r.serial,
        Swversion: (r.swversion & 0x7F00)/256 + "." + (r.swversion & 0xFF),
        ConfigurationId: r.configurationId,
        CurrentConfig: r.currentConfig,
        ExpectedCrc: r.expectedCrc,
        BootstrapCrc: r.bootstrapCrc,
        StdParamCrc: r.stdParamCrc,
        CalParamCrc: r.calParamCrc,
        ProductIdentifier: r.productIdentifier,
        FPGAVersion: r.fpgaversion,
        CpuType: r.cpuType,
        Devversion: r.devversion,
        RtcTime: adjustTime(r.rtcTime),
        AlarmState: r.alarmState,
        AlarmsEnabled: r.alarmsEnabled,
        LatestRssi: r.latestRssi,
        LogFirst: r.logFirst,
        LogLast: r.logLast,
        LogMax: r.logMax,
        LogLastUpload: r.logLastUpload,
        DeletedDate: adjustTime(r.deletedDate),
        ReadBatteryChannel: r.readBatteryChannel,
        SiteId: r.siteId,
        FinalBlockDownloaded: r.finalBlockDownloaded,
        LastDatapoint: adjustTime(r.lastDatapoint),
        AlarmMessage0: r.alarmMessage0,
        AlarmMessage1: r.alarmMessage1,
        AlarmMessage2: r.alarmMessage2,
        AlarmMessage3: r.alarmMessage3,
        AlarmMessage4: r.alarmMessage4,
        AlarmMessage5: r.alarmMessage5,
        AlarmMessage6: r.alarmMessage6,
        AlarmMessage7: r.alarmMessage7,
        AlarmMessage8: r.alarmMessage8,
        AlarmMessage9: r.alarmMessage9,
        AlarmMessage10: r.alarmMessage10,
        AlarmMessage11: r.alarmMessage11,
        AlarmMessage12: r.alarmMessage12,
        AlarmMessage13: r.alarmMessage13,
        AlarmMessage14: r.alarmMessage14,
        AlarmMessage15: r.alarmMessage15,
        SiteName: r.siteName,
        Model: r.model,
        Channels: r.channels,
        LastConnected: adjustTime(r.lastConnected),
        LastReading: adjustTime(r.lastReading),
        EASiteId: r.eaSiteId,
        EAStation: r.eaStation,
        SiteNameUserFriendly: r.siteNameUserFriendly,
        ModelId: r.modelId,
        FkSiteId: r.fkSiteId,
        Deployed: adjustTime(r.deployed),
        MeterConfig: r.meterConfig,
        FlowMultiplierA: r.flowMultiplierA,
        ChannelAUnits: r.channelAUnits,
        FlowMultiplierB: r.flowMultiplierB,
        ChannelBUnits: r.channelBUnits,
        ChannelCDp: r.channelCDp,
        ChannelCUnits: r.channelCUnits,
        ChannelDDp: r.channelDDp,
        ChannelDUnits: r.channelDUnits,
        ChannelEDp: r.channelEDp,
        ChannelEUnits: r.channelEUnits,
        GpsLatitude: r.gpsLatitude,
        GpsLongitude: r.gpsLongitude,
        Dormant: r.dormant,
        ChannelsAvail: r.channelsAvail,
        TransmissionFrequency: r.transmissionFrequency,
        RelayStatus: r.relayStatus,
        What3Words: r.what3Words,
        networkType: r.networkType,
        carrier: r.carrier
    }
}

