import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';

//Moment date/time formatting
//https://momentjs.com/docs/
import Moment from 'moment';

import ClipLoader from "react-spinners/ClipLoader";
//modal window imports
//import InputLabel from '@mui/material/InputLabel';
//import Select from '@mui/material/Select';
//import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Select from '@mui/material/Select'
// import makeAnimated from 'react-select/animated';
import { CreateUrl, CallGetAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";


const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Company {
    id: number;
    parentId: number;
    parentCompany: string;
    name: string;
    companyGuid: string;
    contactEmail: string;
    listenerName: string;
    listenerIp: string;
    listenerPort: string;
    listenerCopies: number;
    more: React.ReactNode;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
}


interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    modal: boolean;
    selectedUser: number;
    colour: string;
    edit: boolean;
    orgCompany: string | undefined;
    editCompany: boolean;
    tempCompany: Company;
    anchorEl: HTMLElement | null;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        userName: string;
        userId: number;
    } | null;
    modalTitle: string;
    adminEmail: string;
    allowSave: boolean;
    canChange: boolean
}

export class Companies extends Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;
    parentCompanies: Array<Company> = [];


    constructor(props: Readonly<Props>) {

        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: []
        });

        this.state = {
            loading: true,
            tableHidden: false,
            visible: false,
            modal: false,
            selectedUser: 0,
            colour: '',
            edit: false,
            editCompany: false,
            orgCompany: undefined,
            tempCompany: {
                id: 0,
                parentId: 0,
                parentCompany: "",
                name: "",
                contactEmail: "",
                companyGuid: "",
                listenerName: "",
                listenerIp: "",
                listenerPort: "",
                listenerCopies: 0,
                more: <span></span>

            },
            anchorEl: null,
            authorized: true,
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            modalTitle: "",
            adminEmail: "",
            allowSave: false,
            canChange: (sessionStorage.getItem('userLevel') === 'identityadmin' || (sessionStorage.getItem('companyId') === '57' || sessionStorage.getItem('companyId') === '61') && sessionStorage.getItem('userLevel') === 'useradmin')
        };

        this.gridRef = React.createRef();
        this.store.clear();

    }



    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (err: string): void => {
        NotificationManager.error(err, 'Error creating new record', 5000);
    };

    componentDidMount(): void {
        if (sessionStorage.getItem('portalUpdate') === 'true') {
            this.clearState()
        }
        this.reloadData(null);
        
    }

    clearState = (): void => {
        this.gridRef.current?.instance.state(null);
    }

    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {
        const me = this;

      
        CallGetAPI(CreateUrl('/api/aquaguard/Companies'), {})
            .then(data => {
                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent
                    const records = [];
                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].id;

                        /*
                        let server = "None";
                        let ports = "None";
                        if (data[i].listener != null) {
                            if (data[i].listener.listenerIP == "") {
                                server = "All";
                            }
                            else {
                                server = data[i].listener.listenerIP;
                            }

                            let port = data[i].listener.listenerPort;
                            ports = "" + port;
                            for (let j = 1; j < data[i].listener.listenerCopies; j++) {
                                port++;
                                ports = ports + ", " + port;
                            }
                        }
                        */

                        const rec: Company = {
                            id: id,
                            parentId: data[i].parentId != null ? data[i].parentId : 0,
                            parentCompany: data[i].parentCompany,
                            name: data[i].name,
                            contactEmail: data[i].contactEmail,
                            companyGuid: data[i].companyGuid,
                            listenerName: data[i].listenerName == null ? "Not configured" : data[i].listenerName,
                            listenerIp: data[i].listenerIP == null ? "Not configured" : data[i].listenerIP,
                            listenerPort: data[i].listenerPort == null ? "Not configured" : data[i].listenerPort,
                            listenerCopies: data[i].listenerCopies == null ? "Not configured" : data[i].listenerCopies,
                            more: <IconButton onClick={(e): void => { me.handleClick(e, id) }} size="large"><MoreHorizIcon /></IconButton>
                        };

                        //used for companies grid
                        records.push({ type: 'insert', data: rec, index: i });

                        //used for parent company dropdown
                        me.parentCompanies.push(rec);
                    }

                    me.store.push(records);

                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: true
                    })
            });


    }

   
    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    //new row
    newCompany = (): void => {
        this.setState({
            modal: true,
            editCompany: false,
            tempCompany: {
                id: 0,
                parentId: 0,
                parentCompany: '',
                name: '',
                companyGuid: '',
                contactEmail: '',
                listenerName: '',
                listenerIp: '',
                listenerPort: '',
                listenerCopies: 0,
                more: <span></span>,
            },
            modalTitle: "Set Up a New Company",
            allowSave: false,
        });

    }
    //edit row
    editCompany = (): void => {
        this.setState({
            editCompany: true,
            modal: !this.state.modal,
            anchorEl: null,
            modalTitle: "Modify Company Details",
            orgCompany: this.state.tempCompany?.name,
            allowSave: true,
        });
    }

    //save changes, for both edit and new
    saveCompany = (): void => {

        const me = this;

        if (this.state.editCompany === false) { //adding a new Company

            this.setState({
                modal: false,
                loading: true,
            });

            //********************
            //send model to API
          
            let requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Name: me.state.tempCompany?.name,
                    contactEmail: me.state.tempCompany?.contactEmail,
                    companyGuid: null,
                    parentId: me.state.tempCompany?.parentId,
                    listenerIP: me.state.tempCompany?.listenerIp,
                    listenerPort: me.state.tempCompany?.listenerPort,
                    listenerCopies: me.state.tempCompany?.listenerCopies
                })
            };

            CallGetAPI(CreateUrl('/api/aquaguard/Company'), requestOptions)
                .then(data => {
                    if (data.status === undefined) {
                        const company: Company = me.state.tempCompany;
                        company.id = data.id
                        company.parentId = data.parentId;
                        company.parentCompany = data.parentCompany;
                        company.name = data.name;
                        company.companyGuid = data.companyGuid;
                        company.contactEmail = data.contactEmail;
                        company.listenerIp = data.listenerIp;
                        company.listenerPort = data.listenerPort;
                        company.listenerCopies = data.listenerCopies;

                        //add to grid array store
                        //me.store.push([{ type: 'insert', key: company.id, data: company }]);
                        //need to call reload data to get parent details in the grid - see below

                        me.setState({
                            tempCompany: company,
                        });

                        requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                userName: me.state.adminEmail,
                                companyGuid: company.companyGuid,
                                companyName: company.name,
                                level: "useradmin"
                            })
                        };

                        CallGetAPI(CreateUrl('/api/aquaguard/AdminUser'), requestOptions)
                            .then(data => {

                                if (data.status === undefined) {
                                    me.createSuccessNotification();
                                }
                                else {
                                    me.createFailedInsertErrorNotification(data.statusText);
                                    console.log(data);
                                }
                                me.toggleModal();
                                me.setState({
                                    loading: false,
                                });
                            })
                            .catch (function (error) {
                                me.createFailedInsertErrorNotification(error.statusText);
                                        console.log(error);
                                        me.toggleModal();
                                        me.setState({
                                            loading: false,
                                        });
                            });


                        //to refresh the grid properly
                        me.reloadData(null);
                    }
                    else {
                        me.createFailedInsertErrorNotification(data.statusText);
                        me.toggleModal();
                        console.log(data);
                        me.setState({
                            loading: false,
                        });
                    }
                })
                .catch(function (error) {
                    me.createFailedInsertErrorNotification(error.statusText);
                    console.log(error)
                    me.toggleModal();
                    me.setState({
                        loading: false,
                    });
                });

        }
        else {//modifying an existing Company

            //********************
            //send model to API
            
            //save User
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: me.state.tempCompany.id,
                    name: me.state.tempCompany.name,
                    companyGuid: me.state.tempCompany.companyGuid,
                    contactEmail: me.state.tempCompany.contactEmail,
                })
            };

            CallPutAPI(CreateUrl('/api/Aquaguard/Company/' + me.state.tempCompany.id), requestOptions)
                .then(async response => {
                    if (response.status == 204) {

                        me.createSuccessNotification()

                        me.store.push([{ type: 'update', key: me.state.tempCompany.id, data: me.state.tempCompany }]);

                        me.setState({
                            modal: !me.state.modal,
                            loading: false,
                        });
                    }
                    else {
                        me.toggleModal();
                        me.setState({
                            loading: false,
                        });
                    }
                    console.log(response)

                })
                .catch(function (error) {
                    me.createErrorNotification()
                    me.toggleModal();
                    console.log(error)
                    me.setState({
                        loading: false,
                    });
                });

        }
    }


    //do nothing, close the modal
    cancel = (): void => {
        this.setState({
            anchorEl: null
        });
        this.toggleModal();

    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    //field changed events---
    companyNameChanged = (event: any): void => {
        const tempCompany = this.state.tempCompany;
        tempCompany.name = event.target.value;
        this.setState( {
            tempCompany: tempCompany,
            allowSave: this.checkSave(event.target.value, this.state.tempCompany.contactEmail, this.state.adminEmail, this.state.tempCompany.listenerCopies, this.state.tempCompany.listenerIp, this.state.tempCompany.listenerPort),
            });
    }

    companyEmailChanged = (event: any): void => {
        const tempCompany = this.state.tempCompany;
        tempCompany.contactEmail = event.target.value;
        this.setState({
            tempCompany: tempCompany,
            allowSave: this.checkSave(this.state.tempCompany.name, event.target.value, this.state.adminEmail, this.state.tempCompany.listenerCopies, this.state.tempCompany.listenerIp, this.state.tempCompany.listenerPort),
        });
    }

    companyParentChanged = (event: any): void => {
        const tempCompany = this.state.tempCompany;
        tempCompany.parentId = event.target.value;
        this.setState({
            tempCompany: tempCompany,
        });
    }

    adminEmailChanged = (event: any): void => {
        this.setState({
            adminEmail: event.target.value,
            allowSave: this.checkSave(this.state.tempCompany.name, this.state.tempCompany.contactEmail, event.target.value, this.state.tempCompany.listenerCopies, this.state.tempCompany.listenerIp, this.state.tempCompany.listenerPort),
        });
    }

    listenerIpChanged = (event: any): void => {

        //ipaddress can be null - no listener, copies will be 0
        const tempCompany = this.state.tempCompany;
        tempCompany.listenerIp = event.target.value;
        this.setState({
            tempCompany: tempCompany,
            allowSave: this.checkSave(this.state.tempCompany.name, this.state.tempCompany.contactEmail, this.state.adminEmail, this.state.tempCompany.listenerCopies, event.target.value, this.state.tempCompany.listenerPort),
        });
    }

    listenerPortChanged = (event: any): void => {
        const tempCompany = this.state.tempCompany;
        tempCompany.listenerPort = event.target.value;
        // if not null check valid port
        this.setState({
            tempCompany: tempCompany,
            allowSave: this.checkSave(this.state.tempCompany.name, this.state.tempCompany.contactEmail, this.state.adminEmail, this.state.tempCompany.listenerCopies, this.state.tempCompany.listenerIp, event.target.value),
        });
    }

    listenerCopiesChanged = (event: any): void => {
        if (!isNaN(parseInt(event.target.value))) {
            const tempCompany = this.state.tempCompany;
            tempCompany.listenerCopies = parseInt(event.target.value);
            this.setState({
                tempCompany: tempCompany,
                allowSave: this.checkSave(this.state.tempCompany.name, this.state.tempCompany.contactEmail, this.state.adminEmail, tempCompany.listenerCopies, this.state.tempCompany.listenerIp, this.state.tempCompany.listenerPort),
            });
        }
    }

    checkSave = (name: string, contact: string, admin: string, copies: number, ip: string, port: string): boolean => {
        return name != ""
            && (contact != "" && this.checkIfValidEmail(contact))
            && ( (admin != "" && this.checkIfValidEmail(admin)) || this.state.editCompany)
            && copies <= 10
            && (ip == "" || (ip != "" && (this.checkIfValidIP(ip) && !isNaN(parseInt(port)))));
    }

    checkIfValidIP = (ipAddress: string): boolean => {
        // Regular expression to check if string is a IP address
        //https://melvingeorge.me/blog/check-if-string-is-valid-ip-address-javascript
        const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
        const result = regexExp.test(ipAddress);
        return result;
    }

    checkIfValidEmail = (emailAddress: string): boolean => {
        // Regular expression to check if string is a email address
        //https://stackoverflow.com/questions/940577/javascript-regular-expression-email-validation
        //const regexExp = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;  this fails if . before @
        const regexExp = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
        const result = regexExp.test(emailAddress);
        return result;
    }

    //-------------------------

    handleClick(event: any, id: number): void {
        this.store.byKey(id)
            .then((company) => {

                this.setState(
                    {
                        tempCompany: company,
                        anchorEl: event.currentTarget,
                    });
                },
                (error) => {
                    console.log(error);
                });
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return Moment(cellInfo.value).format("DD/MM/YYYY HH:mm");
    }

    moreRender(Task: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, Task.data.id); }}><MoreHorizIcon /></IconButton>
        );
    }



    render(): React.ReactNode {
        const { classes } = this.props;


        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                <div>

                    <Modal
                        show={this.state.modal}
                        onHide={(): void => this.toggleModal()}
                        dialogClassName="modal-50w"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                        style={{marginTop: "50px"} }
                        >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            {!this.state.editCompany &&
                                <div>
                                <small>If no TCP IP listener, enter zero and leave IP / Port blank. Otherwise enter IP address and starting port, ports will be auto incremented according to the number of Copies (listeners) required.</small>
                                </div>
                            }
                            <hr/>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10, width: "100%" }}>
                                        <TextField
                                            id="outlined-input-name"
                                            label="Company Name"
                                            value={this.state.tempCompany.name}
                                            onChange={this.companyNameChanged}
                                            variant="outlined"
                                            style={{width: "100%"} }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} >
                                    {this.state.editCompany &&
                                    <div style={{ padding: 10 }}>
                                        <TextField
                                            id="outlined-input-name"
                                            disabled={true}
                                            label="Parent Company Name"
                                            value={this.state.tempCompany.parentCompany}
                                            variant="outlined"
                                            inputProps={
                                                { readOnly: true, }
                                            }
                                            style={{width: "100%"} }
                                        />
                                    </div>
                                    }
                                </Grid>
                                {!this.state.editCompany &&
                                    <Grid item xs={12}>
                                        <div style={{ padding: 10 }}>
                                            <InputLabel>Select a Parent Company:</InputLabel>
                                            <Select
                                                id="outlined-select"
                                                label="Parent"
                                                value={this.state.tempCompany.parentId ? this.state.tempCompany.parentId : 0}
                                                onChange={this.companyParentChanged}
                                                variant="outlined"
                                                style={{width: "100%"} }
                                            >
                                                {this.parentCompanies.map((option): React.ReactNode => {
                                                    return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                                })

                                                }

                                            </Select>
                                        </div>
                                    </Grid>
                                }
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        <TextField
                                            id="outlined-input-email"
                                            label="Contact Email"
                                            value={this.state.tempCompany.contactEmail}
                                            onChange={this.companyEmailChanged}
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                </Grid>
                                {!this.state.editCompany &&
                                    <Grid item xs={12}>
                                        <div style={{ padding: 10 }}>
                                            <TextField
                                            id="outlined-input-listenerip"
                                            label="TCP Listener IP"
                                            value={this.state.tempCompany.listenerIp}
                                            onChange={this.listenerIpChanged}
                                            variant="outlined"
                                            style={{width: "100%"} }
                                            />
                                        </div>
                                    </Grid>
                                }
                                {!this.state.editCompany &&
                                    <Grid item xs={12}>
                                        <div style={{ padding: 10 }}>
                                            <TextField
                                                id="outlined-input-listenerport"
                                                label="TCP Listener Port"
                                                value={this.state.tempCompany.listenerPort}
                                                onChange={this.listenerPortChanged}
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                    </Grid>
                                }
                                {!this.state.editCompany &&
                                    <Grid item xs={12}>
                                        <div style={{ padding: 10 }}>
                                            <TextField
                                                id="outlined-input-listenercopies"
                                                label="TCP Listener Copies"
                                                value={this.state.tempCompany.listenerCopies}
                                                onChange={this.listenerCopiesChanged}
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                    </Grid>
                                }
                                {!this.state.editCompany &&
                                    <Grid item xs={12}>
                                        <div style={{ padding: 10 }}>
                                        <TextField
                                            id="outlined-input-admin"
                                            label="User Admin Email"
                                            value={this.state.adminEmail}
                                            onChange={this.adminEmailChanged}
                                            variant="outlined"
                                            style={{width: "100%"} }
                                        />
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.saveCompany()} disabled={!this.state.allowSave}>Save</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.cancel()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    {this.state.loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>

                    }
                    {!this.state.loading &&

                        <div>


                            {this.state.tableHidden &&
                                    <ApiFailed />
                            }
                            {!this.state.tableHidden &&
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                        <h4 className={classes.cardTitleWhite}>Companies</h4>
                                                {sessionStorage.getItem('userLevel') === 'identityadmin' && <div>
                                                    <IconButton aria-label="add" onClick={(): void => this.newCompany()} size="large" style={{ color: "white" }}>
                                                        <AddCircleOutlineIcon />
                                                    </IconButton>
                                                </div>}
                                            </CardHeader>
                                            <CardBody>
                                            <DataGrid

                                                allowColumnResizing={true}
                                                repaintChangesOnly={true}
                                                dataSource={new DataSource({ store: this.store })}
                                                ref={this.gridRef} >
                                                <StateStoring enabled={true} type="localStorage" storageKey="UserListGrid" />
                                                <FilterRow visible={true} />

                                                <Column dataField="name" caption="Company" visible={true} dataType="string" />
                                                <Column dataField="parentCompany" caption="Parent" visible={true} dataType="string" />
                                                <Column dataField="contactEmail" visible={true} dataType="string" />
                                                <Column dataField="companyGuid" visible={false} dataType="string" minWidth={100} />
                                                <Column dataField="listenerName" caption="Listener" visible={true} dataType="string" />
                                                <Column dataField="listenerIp" caption="IP" visible={true} dataType="string" width={100} />
                                                <Column dataField="listenerPort" caption="Start Port" visible={true} dataType="string" width={80} />
                                                <Column dataField="listenerCopies" caption="Listeners" visible={true} dataType="string" width={80} />

                                                {this.state.canChange && <Column fixed={true} fixedPosition='right' type="buttons" cellRender={this.moreRender.bind(this)} width={35} />}


                                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                <Paging defaultPageSize={10} />
                                            </DataGrid>
                                            </CardBody>
                                        </Card>
                                    </GridItem>

                                </GridContainer>
                            }


                        </div>
                    }


                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                            disableScrollLock={true}
                    >
                            <MenuItem onClick={this.editCompany.bind(this)}>Edit</MenuItem>
                    </Menu>

                    <NotificationContainer />

                </div>
                )

            :
            (
                <Redirect to={'/Login'} />
            );

    }

}

export default withStyles(style)(Companies);

