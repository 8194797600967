import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { DataGrid, Column, SortByGroupSummaryInfo, GroupItem, Summary } from 'devextreme-react/data-grid';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { APIGroupMemberModel } from 'models/APIGroupMemberModel';

import { withStyles, createStyles } from '@mui/styles';

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface SubGroup {
    id: number;
    name: string;
    from: string;
    generation: number;
}

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    groupId: number;
    groupOptions: { value: number; label: string } [];
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    subgroups: SubGroup[];
    clickGroup: SubGroup | null;
    anchorEl: HTMLElement | null;
}

class SubGroupsPanel extends React.Component<Props, State> {
    gridRef: React.RefObject<DataGrid>;

    constructor(props: Readonly<Props>) {
        super(props);
        this.gridRef = React.createRef();

        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            subgroups: [],
            clickGroup: null,
            anchorEl: null
        };

    }

    componentDidMount(): void {
        //get detail on logger here
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/GetAllGroupsForGroup?groupid=' + me.props.groupId),{})
            .then(data => {
                const groups: Array<SubGroup> = [];
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        const group: SubGroup = {
                            id: data[i].id,
                            name: me.props.groupOptions.filter(g => g.value == data[i].memberId)[0].label,
                            from: data[i].groupName,
                            generation: data[i].generation,
                        };
                        groups.push(group);
                    }
                }

                me.setState({
                    subgroups: groups
                });

            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false,
                    })
            });

        this.setState({ loading: false, tableHidden: false });
    }

    moreRender(key: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e: React.MouseEvent<HTMLElement>): void => { this.handleClick(e, key.key); }}><MoreHorizIcon /></IconButton>
        );
    }

    handleClick(event: React.MouseEvent<HTMLElement>, id: number): void {
        this.setState(
            {
                clickGroup: this.state.subgroups.filter(s => s.id == id)[0],
                anchorEl: event.currentTarget
            });
    }

    render(): ReactNode {

        return (

            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                    {!this.state.tableHidden &&
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                            <div>
                                <DataGrid
                                    dataSource={this.state.subgroups}
                                    ref={this.gridRef}
                                    key="id" >


                                    <Column dataField="id" visible={false} dataType="string" />
                                    <Column dataField="generation" visible={false} dataType="number" />
                                    <Column dataField="name" visible={true} dataType="string" />
                                    <Column dataField="from" visible={true} dataType="string" groupIndex={0} />

                                    <Summary>
                                        <GroupItem
                                            column="generation"
                                            summaryType="min"
                                            displayFormat="Level: {0}"
                                            showInColumn={true}
                                            />
                                    </Summary>
                                    <SortByGroupSummaryInfo 
                                        summaryItem={0}
                                        sortOrder="asc"
                                    />
                                </DataGrid>
                            </div>

                            </GridItem>
                        </GridContainer>

                    }
                    </div>
                }

            </div>

        )
    }

}

export default withStyles(style)(SubGroupsPanel);