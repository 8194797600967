import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import Moment from 'react-moment';
import { APIGetLoggerSummaryModel, buildAPIGetLoggerSummaryModel } from "models/APIGetLoggerSummaryModel";
import { adjustTime } from '../../Utils/AdjustTime'

import { withStyles, createStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { compose, spacing, palette, border } from '@mui/system';
import { AlarmSummary } from "./AlarmSummary";

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const overViewStyle = {
    backgroundColor: "#f7f7f7",
    borderRadius: "5px",
    padding: "10px 0 0 0",
    width: "90%",
    boxShadow: "2px 2px 10px #00000060",
    marginBottom: "30px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

const style = createStyles(styles);

const Box = styled('div')(compose(spacing, palette));

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serial: number;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    logger: APIGetLoggerSummaryModel | null;
}

class HYGSummary extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            logger: null,
        };
    }

    componentDidMount(): void {
        //get detail on logger here
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/LoggerBySerial?serial=' + me.props.serial),{})
            .then(response => {
                    me.setState({
                    logger: buildAPIGetLoggerSummaryModel(response)
                });

            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false
                    })
            });

        this.setState({ loading: false, tableHidden: false });
    }



    render(): ReactNode {

        return (

            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                    {!this.state.tableHidden && this.state.logger &&
                        <Box padding={1}>
                        <GridContainer direction="row" justify="center" alignItems="stretch" margin-bottom={10} spacing={3}>
                        <GridItem xs={12} sm={6} md={4} style={{ marginBottom: 10 }}>
                            <div style={{ borderLeft: "10px solid #00BF00", ...overViewStyle, width: "100%" }}>
                                <Box padding={2} style={{ borderRadius: "25px", height: "100%" }}>
                                    <h5 style={{textAlign: "center"} }><b>Device</b></h5>
                                <div>
                                    <b style={{fontWeight: 700} }>Device type</b>&nbsp;&nbsp; {this.state.logger.Model}
                                </div>
                                <div>
                                    <b style={{fontWeight: 700} }>Serial:</b>&nbsp;&nbsp; {this.state.logger.Serial}
                                </div>
                                 <div>
                                    <b style={{fontWeight: 700} }>SW Version:</b>&nbsp;&nbsp; {this.state.logger.Swversion}{this.state.logger.Devversion > 0 ? <span>rc{this.state.logger.Devversion}</span> : <span></span>}
                                </div>
                                <div>
                                    <b style={{fontWeight: 700} }>Channels:</b>&nbsp;&nbsp; {this.state.logger.Channels}
                                </div>
                                            </Box>
                            </div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} style={{ marginBottom: 10 }}>
                            <div style={{ borderLeft: "10px solid #009FE3", ...overViewStyle, width: "100%" }}>
                                <Box padding={2} style={{ borderRadius: "25px", height: "100%" }}>
                                <h5 style={{textAlign: "center"} }><b>Status</b></h5>
                                 <div>
                                    <b style={{fontWeight: 700} }>Last connect:</b>&nbsp;&nbsp; <Moment format="HH:mm DD/MM/YYYY">{this.state.logger.LastConnected}</Moment> 
                                </div>
                                <div>
                                    <b style={{fontWeight: 700} }>Last record:</b>&nbsp;&nbsp; <Moment format="HH:mm DD/MM/YYYY">{this.state.logger.LastReading}</Moment> 
                                </div>
                                <div>
                                    <b style={{fontWeight: 700} }>Battery level:</b>&nbsp;&nbsp; {this.state.logger.ReadBatteryChannel/1000} V
                                </div>
                                <div>
                                    <b style={{fontWeight: 700} }>Signal level:</b>&nbsp;&nbsp; {(this.state.logger.LatestRssi && 0xFF00) / 256}
                                </div>
                                </Box>
                            </div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} style={{ marginBottom: 10 }}>
                                <div style={{ borderLeft: "10px solid #FF0000", ...overViewStyle, width: "100%" }}>
                                <Box padding={2} style={{ borderRadius: "25px", height: "100%" }}>
                                <h5 style={{textAlign: "center"} }><b>Current Alarms</b></h5>
                                <AlarmSummary logger={this.state.logger} />
                                            </Box>
                                </div>
                        </GridItem>
                        </GridContainer>
                        </Box>
                    }
                    </div>
                }

            </div>

        )
    }

}

export default withStyles(style)(HYGSummary);