import React, { ReactElement, FC, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { blackColor } from '../../assets/jss/material-dashboard-react';
import { portalVersion } from 'variables/portalVersion';

import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
        },
        versionBox: {
            color: blackColor,
            zIndex: 2,
            padding: 10,
            fontSize: 10,
        },
        alignRight: {
            textAlign: "right",
        }
    })

);


const VersionBox: FC<any> = (): ReactElement => {

    const classes = useStyles();

    const [apiVersion, setApiVersion] = useState('');
    const [apiFailed, setApiFailed] = useState(false);

    // initialize and destroy the PerfectScrollbar plugin
    useEffect(() => {

        CallGetAPI(CreateUrl('/api/aquaguard/Version'), {})
            .then(data => {
                setApiVersion(data.version);
                window.sessionStorage.setItem("enableSelfRegistration", data.enableSelfRegistration ? "true" : "false");
            },
                // Reject
                () => {
                    setApiFailed(true);
                    window.sessionStorage.setItem("enableSelfRegistration", "false");
                }
            )
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    return (
        (apiFailed) ?
            <div className={classes.versionBox}>API failed</div>
        :
        <div className={classes.versionBox}>
            <div className={classes.alignRight}>Version details:</div>
            <div className={classes.alignRight}> API: {apiVersion}</div>
            <div className={classes.alignRight}> Portal: {portalVersion}</div>
        </div>
        
    );

};

export default VersionBox;