export interface APIUnproceeedBlocksModel {
    id: number;
    loggerSerial: number | null;
    connectionTime: Date;
    blockIds: Array<number>;

}

export function buildAPIUnproceeedBlocksModel(r: any): Array<APIUnproceeedBlocksModel> {

    const result = new Array<APIUnproceeedBlocksModel>();

    r?.forEach(function (rec: any) {

        result.push(rec);
    });

    return result;
}




