import { ReactNode } from 'react';

export interface TileModel {
    tileName: string;
    position: number;
    title: string;
    description?: string;
    tileColor: string;
    type: string;
    content: string;
    refreshTime: number;
    onRefresh: string;      // Name of function returning Promise<ReactNode>
//    onRefresh: () => Promise<ReactNode>;
//    onClick: () => void;
    chartData?: any;
    redirectPath?: string | undefined;
    redirectProps?: string | undefined;
}

export interface DashboardModel {
    position: number;
    groupName: string;
    expand: boolean;
    tiles: Array<TileModel>;
}

export function blankTile(): TileModel {
    const tile: TileModel = {
        tileName: "0",
        position: 0,
        title: "",
        description: undefined,
        tileColor: "",
        type: "",
        content: "",
        refreshTime: 0,
        onRefresh: "",
        chartData: undefined,
        redirectPath: undefined,
        redirectProps: undefined
    }

    return tile;
}
