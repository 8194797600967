/* /src/components/auth/silentRenew.jsx */
import React from "react";
import { AuthConsumer } from "../../auth/authProvider";

/*
 * silentRenew: Is going to be used by the event addAccessTokenExpired configured on the AuthService.
 */

export const SilentRenew = () => (
    <AuthConsumer>
        {({ signinSilentCallback }) => {
            console.log("SilentRenew - last call=" + sessionStorage.getItem("lastCallAPI"));
            signinSilentCallback();
            return <span>loading</span>;
        }}
    </AuthConsumer>
);