
import React from "react";
import { AuthContext } from "../../auth/authProvider.jsx";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Redirect } from "react-router-dom";
import IsometricMap from "../../components/IsometricMap/IsometricMap.js";
import { ReactComponent as AshLogo } from '../../assets/ashLogo.svg';
import { withStyles, createStyles } from '@mui/styles';
import CardFooter from '../../components/Card/CardFooter.js';

import { CreateUrl } from 'Utils/ApiHelper.js';


const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});
const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontSize: 60,
        fontWeight: "100",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
};
const style = createStyles(styles);

export class LockoutPage extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            forgotPassword: false,
            newRegister: false,
            enableSelfRegistration: false,
        }
    }

    componentDidMount() {

        const me = this;
        fetch(CreateUrl('/api/aquaguard/Version'), {})
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
            },
            // Reject
                () => {
                    window.sessionStorage.setItem("enableSelfRegistration", "false");
                }
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(async data => {
                window.sessionStorage.setItem("enableSelfRegistration", data.enableSelfRegistration ? "true" : "false");
                me.setState({ enableSelfRegistration: data.enableSelfRegistration });
            });
    }

    signOut = () => {
        this.context.logout();
    }

    signIn = () => {
        this.context.signinRedirect();
    }

    forgotPassword = () => {
        this.setState({ forgotPassword: true });
    }

    newRegister = () => {
        this.setState({ newRegister: true });
    }


    render() {

        const { classes } = this.props;

        return (

            (!this.state.forgotPassword && !this.state.newRegister) ? //if forgot password - redirect to resetpassword page
                (
                    <div style={{
                        backgroundColor: 'rgba(52, 52, 52, 0.8)',
                        width: '100vw',
                        height: '100vh',
                        overflow:'hidden'
                }}>

                    <div className='some-page-wrapper'>
                        <div className='row'>

                            <div className='column' >
                                    <div>
                                    <Card style={{
                                        height: '100vh',
                                        backgroundColor: 'rgba(52, 52, 52, 0.8)'
                                    }}>
                                        <CardHeader className={'center'}>
                                            <AshLogo />
                                            <h4 className={classes.cardTitleWhite}>AquaGuard</h4>
                                        </CardHeader>
                                        <CardBody style={{ height: '230px' }}>

                                            <Button
                                                style={{
                                                    width: '90%',
                                                    position: 'absolute', left: '50%', top: '50%',
                                                    transform: 'translate(-50%, -50%)'
                                                }}
                                                variant="contained"
                                                color="primary"
                                                onClick={this.signOut.bind(this)}>
                                                Return to Login
                                            </Button>


                                        </CardBody>
                                        <CardFooter>
                                                <h4 style={{ color: "red", backgroundColor: "white", padding: "10px" }}>
                                                    Your account has been locked out.<br />
                                                    Please contact your system administrator.
                                                </h4>
                                        </CardFooter>
                                    </Card>
                                </div>
                            </div>

                            </div>

                    </div>

                    </div>
                )
                :
                (
                    this.state.forgotPassword ?
                        <Redirect to={'/forgotpassword'} />
                    :
                        <Redirect to={'/register'} />
                )
            )
    }
}

export default withStyles(style)(LockoutPage);

