import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerAlarmsModel, buildAPIGetLoggerAlarmsModel } from "models/APIGetLoggerAlarmsModel";

//Moment date/time formatting
//https://momentjs.com/docs/
import moment, { Duration } from 'moment';

import { withStyles, createStyles } from '@mui/styles';

//DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Chart, { CommonSeriesSettings, ArgumentAxis, Series, Legend, ValueAxis, ZoomAndPan, ScrollBar, Tooltip, TickInterval, Size } from 'devextreme-react/chart';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { locale } from "devextreme/localization";

const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

// Data to display on graph - number of hours(decimal) in each alarm state per day
// e.g. No alarms: alarmClr=24, alarm0=0, alarm1=0, alarm2=0
interface AlarmsWithRainfall {
    date: Date;
    alarmClr: number;
    alarm0: number;
    alarm1: number;
    alarm2: number;
    rainfall: number;
}


interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serial: string;
    eaSiteId: number;
    startDate: Date;
    endDate: Date;
    showRainfall: boolean;
    channels: string;   // Comma separated list of channels
    isprimary: string;
    units: string;  // Comma separated list of units
    meterConfig: number;    //
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    startDate: Date;
    endDate: Date;
    showRainfall: boolean;
    maxAlarms: number;
    maxRainfall: number;
    alarms: APIGetLoggerAlarmsModel[];
    rainfall: Array<{ id: number; dateTime: Date; value: number }>;
    graphData: Array<AlarmsWithRainfall> | undefined;
    channels: Array<string>;    //props split into array
}

class AlarmsGraphSWG extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            maxAlarms: 0,
            maxRainfall: 0,
            startDate: props.startDate,
            endDate: props.endDate,
            showRainfall: false,
            alarms: [],
            rainfall: [],
            graphData: undefined,
            channels: ["A", "B", "C", "D", "E"],
        };

        // Set DevExtreme Locale (for date formats)
        locale("en-GB");
    }

    componentDidMount(): void {


        //get alarms for logger here
        if (this.reloadData(this.state.startDate, this.state.endDate, this.state.showRainfall)) {
            this.setState({
                loading: false,
                tableHidden: false,
                channels: this.props.channels.length > 4 ? this.props.channels.split(',') : ["A", "B", "C", "D", "E"],
            });
        }
        else {
            this.setState({ loading: false, tableHidden: true });
        }

    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.startDate != this.props.startDate
            || prevProps.endDate != this.props.endDate
            || prevProps.showRainfall != this.props.showRainfall
            || prevProps.channels != this.props.channels
        ) {
            this.setState({
                startDate: this.props.startDate,
                endDate: this.props.endDate,
                showRainfall: this.props.showRainfall,
                channels: this.props.channels.length > 4 ? this.props.channels.split(',') : ["A", "B", "C", "D", "E"],
            });
            this.reloadData(this.props.startDate, this.props.endDate, this.props.showRainfall)
        }

    }

    reloadData(start: Date, end: Date, rainfall: boolean): boolean {

        this.setState({ loading: true });

        let alarmData = new Array<APIGetLoggerAlarmsModel>();

        const graphData = new Array<AlarmsWithRainfall>();

        //get alarms for logger here
        const me = this;

        // Add entry for each day in range to graphData
        let addDate: Date = start;
        while (addDate <= end) {
            graphData.push({
                date: moment(addDate).startOf('day').toDate(),
                alarmClr: 24,
                alarm0: 0,
                alarm1: 0,
                alarm2: 0,
                rainfall: 0,
            });
            addDate = moment(addDate).add(1, "day").toDate();
        }


        CallGetAPI(CreateUrl('/api/aquaguard/LoggerAlarms?serial=' + this.props.serial + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"), {})
            .then(response => {
                let activeAlarms = 0;
                alarmData = buildAPIGetLoggerAlarmsModel(response);

                alarmData.forEach((v: APIGetLoggerAlarmsModel) => {
                    console.log("Processing: " + v.alarmMask + "/" + v.alarmState + " set " + v.dateRecorded + " clr " + v.dateCleared);
                    if (v.alarmState > activeAlarms) {
                        activeAlarms = v.alarmState;
                    }

                    let duration: Duration;
                    const almDate = moment(v.dateRecorded).startOf("day");

                    if (v.dateCleared != undefined) {
                        if (v.dateRecorded && moment(v.dateRecorded) >= moment(start).startOf("day")) {
                            // Duration specified and after start, calculate it
                            duration = moment.duration(moment(v.dateCleared).diff(moment(v.dateRecorded)));
                        }
                        else {
                            // Duration from start, calculate it
                            duration = moment.duration(moment(v.dateCleared).diff(moment(start).startOf("day")));
                        }

                    }
                    else {
                        if (v.dateRecorded && moment(v.dateRecorded) >= moment(start).startOf("day")) {
                            // Any current active alarms - create dummy graphdata at end of the search periods
                            duration = moment.duration(moment(end).endOf("day").diff(moment(v.dateRecorded)));
                        }
                        else {
                            // Duration is entire period displayed
                            duration = moment.duration(moment(end).endOf("day").diff(moment(start).startOf("day")));
                        }
                    }
                    const hours = duration.asHours();
                    console.log("Hours: " + hours);
                    let recorded = 0;
                    // Allow for rounding errors in addition of recorded
                    while (hours - recorded > 0.01) {
                        console.log("Checking " + almDate.clone().format("HH:mm DD/MM/YYYY") + " Recorded: " + recorded);
                        let idx = -1;
                        // Check for alarms occurring before start
                        // MUST clone() otherwise clears time on almDate
                        if (almDate.clone().startOf("day") >= moment(graphData[0].date).startOf("day")) {
                            idx = graphData.findIndex((g) => {
                                return almDate.clone().isSame(g.date, "day");
                            });
                        }
                        console.log("Idx: " + idx);
                        if (idx != -1) {
                            let today: number;
                            if (moment(graphData[idx].date).isSame(moment(v.dateRecorded),"day")) {
                                // Alarm starts on this day
                                if (v.dateCleared == undefined || !moment(graphData[idx].date).isSame(moment(v.dateCleared),"day")) {
                                    // Alarm not yet cleared, or not cleared 'today'
                                    today = moment(graphData[idx].date).endOf("day").diff(moment(v.dateRecorded), "hours", true);
                                }
                                else {
                                    //Alarm wholly in day
                                    today = hours;
                                }
                            }
                            else {
                                // Alarm from previous day
                                if (v.dateCleared == null || !moment(graphData[idx].date).isSame(moment(v.dateCleared),"day")) {
                                    // Alarm not yet cleared, or not cleared 'today'
                                    if (moment(graphData[idx].date).isSame(moment(end),"day") &&
                                        moment(end).isSame(moment(),"day")) {
                                        // if end is today, only up to now
                                        today = moment().hour();
                                    }
                                    else {
                                        today = 24;
                                    }
                                }
                                else {
                                    today = moment(v.dateCleared).diff(moment(graphData[idx].date), "hours", true);
                                }
                            }
                            if (v.alarmMask == 0x04) {
                                graphData[idx].alarm2 += today;
                                console.log("alm2: " + graphData[idx].date + " add " + today);
                                if ((v.alarmState & 0x02) != 0) {
                                    graphData[idx].alarm1 -= today;
                                    console.log("alm1: " + graphData[idx].date + " subtract " + today);
                                }
                                if ((v.alarmState & 0x01) != 0) {
                                    graphData[idx].alarm0 -= today;
                                    console.log("alm0: " + graphData[idx].date + " subtract " + today);
                                }
                            }
                            else if (v.alarmMask == 0x02) {
                                // Only count if no higher alarms
                                if ((v.alarmState & 0x04) == 0) {
                                    graphData[idx].alarm1 += today;
                                    console.log("alm1: " + graphData[idx].date + " add " + today);
                                }
                                if ((v.alarmState & 0x01) != 0) {
                                    graphData[idx].alarm0 -= today;
                                    console.log("alm0: " + graphData[idx].date + " subtract " + today);
                                }
                            }
                            else if (v.alarmMask == 0x01 && (v.alarmState & 0x06) == 0) {
                                // Only count if no higher alarms
                                graphData[idx].alarm0 += today;
                                console.log("alm0: " + graphData[idx].date + " add " + today);
                            }

                            recorded += today;
                        }
                        else {
                            if (almDate >= moment(end)) {
                                recorded = hours;
                            }
                        }
                        almDate.add(24, "hours");
                    }


                });

                graphData.sort((a, b) => a.date.getTime() - b.date.getTime());

                graphData.map((g) => {
                    if (g.alarm0 < 0) g.alarm0 = 0;
                    if (g.alarm1 < 0) g.alarm1 = 0;

                    if (moment(g.date).isSame(moment(),"day")) {
                        g.alarmClr = moment().hour() - (g.alarm0 + g.alarm1 + g.alarm2);
                    }
                    else {
                        g.alarmClr = 24 - (g.alarm0 + g.alarm1 + g.alarm2);
                    }
                    console.log(g.date + " 0:" + g.alarm0 + " 1:" + g.alarm1 + " 2:" + g.alarm2 + " clr:" + g.alarmClr);
                    if (g.alarmClr < 0) g.alarmClr = 0;
                    if (g.alarm2 > 24) g.alarm2 = 24;
                    if (g.alarm2 + g.alarm1 > 24) g.alarm1 = 24 - g.alarm2;
                    if (g.alarm2 + g.alarm1 + g.alarm0 > 24) g.alarm0 = 24 - g.alarm2 - g.alarm1;
                })

                let maxAlarms = 0;
                while (maxAlarms < 16 && activeAlarms >= 1) {
                    activeAlarms /= 2;
                    maxAlarms++;
                }

                me.setState({
                    maxAlarms: maxAlarms,
                    alarms: alarmData,
                    graphData: graphData,
                    loading: false,
                });

            })
            .catch(function (ex) {
                me.setState(
                    {
                        loading: false,
                        tableHidden: true,
                        alarms: [] 
                    });
                console.log(ex);
                return false;
            });

        // Rainfall data
        if (rainfall && this.props.eaSiteId > 0) {
            const rainfallData = new Array<{ id: number; dateTime: Date; value: number }>();

            CallGetAPI(CreateUrl('/api/aquaguard/EAMeasuresDaily?EASiteId=' + this.props.eaSiteId + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"), {})
                .then(response => {
                    let maxRainfall = 0;

                    for (let i = 0; i < response.length; i++) {
                        rainfallData.push({
                            id: response[i].id,
                            dateTime: response[i].dateTime,
                            value: response[i].value
                        });


                        const idx = graphData.findIndex((g) => {
                            return moment(response[i].dateTime).isSame(g.date, "day");
                        });

                        if (idx != -1) {
                            graphData[idx].rainfall = response[i].value;
                            if (graphData[idx].rainfall > maxRainfall) {
                                maxRainfall = graphData[idx].rainfall;
                            }
                        }

                    }

                    me.setState({
                        rainfall: rainfallData,
                        maxRainfall: maxRainfall == 0 ? 1 : maxRainfall,
                        graphData: graphData,
                    });

                })
                .catch(function (ex) {
                    me.setState(
                        {
                            authorized: false,
                            rainfall: []
                        });
                    console.log(ex);
                    return false;
                });

        }



        return true;
    }

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Alarms');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("HH:mm DD/MM/YYYY");
    }


    render(): ReactNode {

        const arrUnits = this.props.units.split(",");

        return (

            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'relative', left: '40%', top: '50%',
                    }}>
                        <ClipLoader
                            size={50}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                    {!this.state.tableHidden &&
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <p style={{ fontWeight: "bold", fontSize: "10pt", textAlign: "center" }}>Alarm Summary</p>
                                <Chart dataSource={this.state.graphData}>
                                <Size height={this.props.isprimary == "yes" ? 480 : 120} width="100%" />
                                    <CommonSeriesSettings
                                        argumentField="date"
                                        type="stackedBar"
                                        >
                                </CommonSeriesSettings>
                                {(this.props.meterConfig & 0x2000) > 0 &&
                                    <Series
                                        valueField="alarm2"
                                        name={arrUnits[4].length == 0 ? this.state.channels[4] : ">2 alarm"}
                                        stack="alarm"
                                        color="Red"
                                        axis="Hours"
                                    />
                                }
                                {(this.props.meterConfig & 0x0800) > 0 &&
                                    <Series
                                        valueField="alarm1"
                                        name={arrUnits[3].length == 0 ? this.state.channels[3] : "2 alarms"}
                                        stack="alarm"
                                        color="Orange"
                                        axis="Hours"
                                    />
                                }
                                    <Series
                                        valueField="alarm0"
                                        name={arrUnits[2].length == 0 ? this.state.channels[2] : "1 alarm"}
                                        stack="alarm"
                                        color="Yellow"
                                        axis="Hours"
                                    />
                                    <Series
                                        valueField="alarmClr"
                                        name="Clr"
                                        stack="alarm"
                                        color="Green"
                                        axis="Hours"
                                    />
                                {this.state.showRainfall &&
                                    <Series
                                        valueField="rainfall"
                                        name="Rainfall"
                                        stack="rainfall"
                                        color="cyan"
                                        axis="Rainfall"
                                    />
                                }
                                    <ValueAxis
                                    name="Hours"
                                    visualRange={{ startValue: 0, endValue: 24 }}
                                    allowDecimals={false}
                                    title={{ text: "Hours in alarm" }}
                                    grid={{ visible: false }}
                                    />
                                {this.state.showRainfall &&
                                    <ValueAxis
                                    name="Rainfall"
                                    position="right"
                                    visualRange={{ startValue: 0, endValue: this.state.maxRainfall }}
                                    allowDecimals={false}
                                    title={{ text: "Rainfall (mm)"}}
                                    grid={{ visible: true }}
                                    />
                                }
                                    <ArgumentAxis
                                    allowDecimals={false}
                                    argumentType="datetime"
                                    minValueMargin={0.2}
                                    maxValueMargin={0}
                                    wholeRange={[this.state.startDate, this.state.endDate]}
                                    title={{ text: "Date" }}
                                        >
                                    <TickInterval days={1} />
                                    </ArgumentAxis>
                                    {this.props.isprimary == "yes" &&
                                        <ZoomAndPan
                                            argumentAxis="both"
                                            valueAxis="none"
                                        />
                                    }
                                    <ScrollBar
                                        visible={this.props.isprimary == "yes"}
                                    />
                                    <Legend visible={this.props.isprimary == "yes"} />
                                    <Tooltip enabled={this.props.isprimary == "yes"} />
                                </Chart>
                            </GridItem>
                        </GridContainer>

                    }
                    </div>
                }

            </div>

        )
    }

}

export default withStyles(style)(AlarmsGraphSWG);