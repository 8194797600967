export interface APIGetLoggerConfigurationsModel {
    id: number;
    loggerSerial: number | null;
    createdTime: Date;
    startTime: Date;
    endTime: Date | null; 

    previousConfig: number | null;
    stdParamCrc: number;
    calParamCrc: number;
    readBatteryChannel: number;
    meterConfig: number;

    channelARef: string;
    flowMultiplierA: number | null;
    channelAUnits: string;
    channelBRef: string;
    flowMultiplierB: number | null;
    channelBUnits: string;
    channelCRef: string;
    channelCDp: number | null;
    channelCUnits: string;
    channelDRef: string;
    channelDDp: number | null;
    channelDUnits: string;
    channelERef: string;
    channelEDp: number | null;
    channelEUnits: string;

    ChannelASetLevel: number | null;
    ChannelAClearLevel: number | null;
    ChannelBSetLevel: number | null;
    ChannelBClearLevel: number | null;
    ChannelCSetLevel: number | null;
    ChannelCClearLevel: number | null;
    ChannelDSetLevel: number | null;
    ChannelDClearLevel: number | null;
    ChannelESetLevel: number | null;
    ChannelEClearLevel: number | null;

    siteId: string;
    stdParamJson: string;
    calParamJson: string;

    sendToLogger: boolean;
    updateJson: string;

    user1: string;
    user2: string;
    user3: string;
    user4: string;
    user5: string;
    user6: string;

    firmwareToUpdate: number;
    sendConfigAfter: Date | null;

    swVersion: number;
    devVersion: number;
    channelsAvail: number;

    // Following fields generated for display - use encodeHtml={false} to display in grid
    setLevels: string;
    clrLevels: string;

}

export function buildAPIGetLoggerConfigurationsModel(r: any): Array<APIGetLoggerConfigurationsModel> {

    const result = new Array<APIGetLoggerConfigurationsModel>();

    r?.forEach(function (rec: any) {
        const newrec = rec as APIGetLoggerConfigurationsModel;
        newrec.setLevels = "";
        newrec.clrLevels = "";
        if (rec.channelASetLevel != null && rec.channelAClearLevel != null) {
            newrec.setLevels += "A: " + rec.channelASetLevel + "<br/>";
            newrec.clrLevels += "A: " + rec.channelAClearLevel + "<br/>";
        }
        if (rec.channelBSetLevel != null && rec.channelBClearLevel != null) {
            newrec.setLevels += "B: " + rec.channelBSetLevel + "<br/>";
            newrec.clrLevels += "B: " + rec.channelBClearLevel + "<br/>";
        }
        if (rec.channelCSetLevel != null && rec.channelCClearLevel != null) {
            newrec.setLevels += "C: " + rec.channelCSetLevel + "<br/>";
            newrec.clrLevels += "C: " + rec.channelCClearLevel + "<br/>";
        }
        if (rec.channelDSetLevel != null && rec.channelDClearLevel != null) {
            newrec.setLevels += "D: " + rec.channelDSetLevel + "<br/>";
            newrec.clrLevels += "D: " + rec.channelDClearLevel + "<br/>";
        }
        if (rec.channelESetLevel != null && rec.channelEClearLevel != null) {
            newrec.setLevels += "E: " + rec.channelESetLevel + "<br/>";
            newrec.clrLevels += "E: " + rec.channelEClearLevel + "<br/>";
        }

        result.push(newrec);
    });

    return result;
}




