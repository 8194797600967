import React, { ReactNode } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerReadingsModel, buildAPIGetLoggerReadingsModel } from "models/APIGetLoggerReadingsModel";
import { APIGetLoggerAlarmLevelModel, buildAPIGetLoggerAlarmLevelModel } from "models/APIGetLoggerAlarmLevelModel";

import { decodeFlowMultiplier } from "Utils/FlowMultiplier";

//Moment date/time formatting
//https://momentjs.com/docs/
import moment, { Duration } from 'moment';

import { styled } from '@mui/material/styles';
import { compose, spacing, palette, border } from '@mui/system';
import zIndex from "@mui/material/styles/zIndex";

const Box = styled('div')(compose(spacing, palette, border));


interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    serial: string;
    channels: string[];
    startDate: Date;
    endDate: Date;
    title: string;
    channelsAvail: number;
    haveFlow: boolean;
    haveAnalog: boolean;
    haveTotaliser: boolean;
    readingsData: Array<APIGetLoggerReadingsModel>;
    ChannelAUnits: string;
    ChannelBUnits: string;
    ChannelCDp: number | null;
    ChannelCUnits: string;
    ChannelDDp: number | null;
    ChannelDUnits: string;
    ChannelEDp: number | null;
    ChannelEUnits: string;
    flowUnits: string;
    flowFactor: number;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    maxValue: number;
    minValue: number;
    maxFlow: number;
    minFlow: number;
    startVol: number;
    endVol: number;
    totalVolume: number;
    totalRainfall: number;
    readingsData: Array<APIGetLoggerReadingsModel>;
//    flowMultiplierA: FlowMultiplier;
//    flowMultiplierB: FlowMultiplier;
    intervalAAvg: number;
    maxCValue: number;
    minCValue: number;
    maxDValue: number;
    minDValue: number;
}

export class PeriodStatsCHR extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            //maxValue: -1,
            maxValue: Number.NEGATIVE_INFINITY,
            minValue: Number.MAX_VALUE,
            //maxFlow: -1,
            maxFlow: Number.NEGATIVE_INFINITY,
            minFlow: Number.MAX_VALUE,
            startVol: -1,
            endVol: -1,
            totalVolume: -1,
            totalRainfall: -1,
            readingsData: [],
//            flowMultiplierA: decodeFlowMultiplier(0),
//            flowMultiplierB: decodeFlowMultiplier(0),
            intervalAAvg: 0,
            //maxCValue: -1,
            maxCValue: Number.NEGATIVE_INFINITY,
            minCValue: Number.MAX_VALUE,
            //maxDValue: -1,
            maxDValue: Number.NEGATIVE_INFINITY,
            minDValue: Number.MAX_VALUE,
        };
    }

    componentDidMount(): void {

        //get alarms for logger here
        if (this.rescanData(this.props.startDate, this.props.endDate)) {
            this.setState({ tableHidden: false });
        }
        else {
            this.setState({ tableHidden: true });
        }

    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.startDate != this.props.startDate
            || prevProps.endDate != this.props.endDate
        ) {
            this.rescanData(this.props.startDate, this.props.endDate);
        }

    }

    rescanData(start: Date, end: Date): boolean {

        /*
        let maxValue = -1;
        let minValue = Number.MAX_VALUE;
        let maxFlow = -1;
        let minFlow = Number.MAX_VALUE;
        let maxCValue = -1;
        let minCValue = Number.MAX_VALUE;
        let maxDValue = -1;
        let minDValue = Number.MAX_VALUE;
        */

        let maxValue = Number.NEGATIVE_INFINITY;
        let minValue = Number.MAX_VALUE;
        let maxFlow = Number.NEGATIVE_INFINITY;
        let minFlow = Number.MAX_VALUE;
        let maxCValue = Number.NEGATIVE_INFINITY;
        let minCValue = Number.MAX_VALUE;
        let maxDValue = Number.NEGATIVE_INFINITY;
        let minDValue = Number.MAX_VALUE;

        let firstVolTS = moment(this.props.endDate);
        let startVol = -1;
        let lastVolTS = moment(this.props.startDate);
        let endVol = -1;
        console.log(this.props.startDate)
        let totalRainfall = 0;

        let intervalATotal = 0;
        let intervalACount = 0;

        //get alarms for logger here
        const me = this;

        this.props.readingsData.forEach((v: APIGetLoggerReadingsModel) => {
            if (v.dateStamp != null) {

                let multiplier = decodeFlowMultiplier(0);
                if (v.multiplier != null) {
                    multiplier = decodeFlowMultiplier(v.multiplier);
                }
                let value = v.value;
                if (value == null) {
                    value = v.value2;
                }

                if (this.props.haveFlow) {
                    if (v.channelletter == 'A') {
                        if (this.props.ChannelAUnits != "mm") {
                            if (value != -32768) {
                                if (value != null && moment(v.dateStamp).isBefore(firstVolTS)) {
                                    firstVolTS = moment(v.dateStamp);
                                    startVol = value;
                                }
                                if (value != null && moment(v.dateStamp).isAfter(lastVolTS)) {
                                    lastVolTS = moment(v.dateStamp);
                                    endVol = value;
                                }
                            }
                            if (v.flowRate != 0) {
                                if (v.flowRate != null && v.flowRate > maxFlow) {
                                    maxFlow = v.flowRate;
                                }
                                if (v.flowRate != null && v.flowRate < minFlow) {
                                    minFlow = v.flowRate;
                                }
                            }
                        }
                        else {
                            // Rain gauge
                            if (value != -32768) {
                                if (v.difference != null && v.difference > 0) {
                                    totalRainfall += v.difference * multiplier.factor;
                                }
                            }
                            if (v.difference != null && (v.difference * multiplier.factor) > maxFlow) {
                                maxFlow = v.difference * multiplier.factor;
                            }

                        }
                        intervalATotal += v.interval ? v.interval : 0;
                        intervalACount++;
                    }
                }

                if (this.props.haveFlow) {
                    if (v.channelletter == 'B') {
                        if (value != -32768) {
                            if (value != null && value > maxValue) {
                                maxValue = value;
                            }
                            if (value != null && value < minValue) {
                                minValue = value;
                            }
                        }
                    }
                }

                if (this.props.haveAnalog) {
                    if (v.channelletter == 'C') {
                        if (value != -32768) {
                            if (value != null && value > maxCValue) {
                                maxCValue = value;
                            }
                            if (value != null && value < minCValue) {
                                minCValue = value;
                            }
                        }
                    }
                }

                if (this.props.haveAnalog) {
                    if (v.channelletter == 'D') {
                        if (value != -32768) {
                            if (value != null && value > maxDValue) {
                                maxDValue = value;
                            }
                            if (value != null && value < minDValue) {
                                minDValue = value;
                            }
                        }
                    }
                }

            }
        });

            console.log("Channel " + me.props.channels + " Min: " + minValue + " Max: " + maxValue);
            console.log(this.props.flowFactor)
            me.setState({
                minValue: minValue,
                maxValue: maxValue,
                minFlow: this.props.flowUnits !== 'm3/h' ? minFlow * this.props.flowFactor : minFlow * 3.6,
                maxFlow: this.props.flowUnits !== 'm3/h' ? maxFlow * this.props.flowFactor : maxFlow * 3.6,
                startVol: startVol,
                endVol: endVol,
                totalVolume: endVol - startVol,
                totalRainfall: totalRainfall,
//                flowMultiplierA: decodeFlowMultiplier(this.props.FlowMultiplierA),
//                flowMultiplierB: decodeFlowMultiplier(this.props.FlowMultiplierB),
                intervalAAvg: intervalATotal / intervalACount,
                maxCValue: maxCValue,
                minCValue: minCValue,
                maxDValue: maxDValue,
                minDValue: minDValue,
                loading: false,
            });


        return true;
    }




    render(): ReactNode {

        return (
        <div>
            {
                this.state.loading &&
                    <div style={{
                        position: 'relative', left: '40%', top: '50%',
                    }}>
                        <ClipLoader
                            size={50}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
            }
                {!this.state.loading &&

                    <div>
                        <GridContainer spacing={1}>
                            {/*
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <b>Stats for Period : </b>
                            </GridItem>
                           */}

                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div style={{ whiteSpace: "nowrap", fontSize: "18px", marginBottom: "5px" }}>
                                    <b>Stats for Period : </b>
                                </div>
                            </GridItem>

                            {/*
                            {(this.props.channelsAvail & 0x03) > 0 &&
                                    <GridItem xs={12} sm={6} md={4} lg={2}>
                                        <div style={{ whiteSpace: "nowrap" }}>
                                Max : {this.state.endVol == -1 ? "(N/A)" : this.state.endVol} {this.props.ChannelAUnits == "m3" ? <span>m<sup>3</sup></span> : <span>l</span>}
                                        </div>
                                    </GridItem>
                            }
                            {(this.props.channelsAvail & 0x03) > 0 &&
                                    <GridItem xs={12} sm={6} md={4} lg={2}>
                                        <div style={{ whiteSpace: "nowrap" }}>
                                Min : {this.state.startVol == -1 ? "(N/A)" : this.state.startVol} {this.props.ChannelAUnits == "m3" ? <span>m<sup>3</sup></span> : <span>l</span>}
                                        </div>
                                    </GridItem>
                            }
                           */}

                            {/*
                            {(this.props.meterConfig & 0x04FF) > 0 && this.props.ChannelAUnits != "mm" &&
                                <GridItem xs={12} sm={6} md={4} lg={2}>
                                <div style={{whiteSpace: "nowrap"}}>
                                    Max flow : {this.state.maxFlow == -1 ? "Not set" : this.state.maxFlow.toFixed(4)} {this.props.ChannelAUnits == "m3" ? <span>m<sup>3</sup></span> : this.props.ChannelAUnits}/h
                                </div>
                                </GridItem>
                            }
                            */}
                        {(this.props.channelsAvail & 0x01) > 0 && this.props.ChannelAUnits != "mm" &&
                                <GridItem xs={12} sm={6} md={4} lg={3}>
                                    <div style={{ whiteSpace: "nowrap", fontSize: "18px" }}>
                            Max flow : <span style={{ whiteSpace: 'nowrap' }}>{this.state.maxFlow == -1 ? "Not set" : this.state.maxFlow.toFixed(3)} {this.props.flowUnits}</span>
                                    </div>
                                </GridItem>
                        }

                        {(this.props.channelsAvail & 0x01) > 0 && this.props.ChannelAUnits == "mm" &&
                                <GridItem xs={12} sm={6} md={4} lg={3}>
                                    <div style={{ whiteSpace: "nowrap", fontSize: "18px" }}>
                                Max rainfall rate : <span style={{ whiteSpace: 'nowrap' }}>{this.state.maxFlow == -1 ? "Not set" : (this.state.maxFlow * (3600 / this.state.intervalAAvg)).toFixed(3)} {this.props.ChannelAUnits}/h</span>
                                    </div>
                                </GridItem>
                        }

                        {/*
                        {(this.props.meterConfig & 0x04FF) > 0 && this.props.ChannelAUnits != "mm" &&
                            <GridItem xs={12} sm={6} md={4} lg={2}>
                            <div style={{whiteSpace: "nowrap"}}>
                                Min flow : {this.state.minFlow == Number.MAX_VALUE ? "Not set" : this.state.minFlow.toFixed(4)} {this.props.ChannelAUnits == "m3" ? <span>m<sup>3</sup></span> : this.props.ChannelAUnits}/h
                            </div>
                            </GridItem>
                        }
                        */}
                        {(this.props.channelsAvail & 0x01) > 0 && this.props.ChannelAUnits != "mm" &&
                                <GridItem xs={12} sm={6} md={4} lg={3}>
                                    <div style={{ whiteSpace: "nowrap", fontSize: "18px" }}>
                                    Min flow : <span style={{ whiteSpace: 'nowrap' }}>{this.state.minFlow == Number.MAX_VALUE ? "Not set" : this.state.minFlow.toFixed(3)} {this.props.flowUnits}</span>
                                    </div>
                                </GridItem>
                        }

                        {(this.props.channelsAvail & 0x01) > 0 && this.props.ChannelAUnits != "mm" &&
                                <GridItem xs={12} sm={6} md={4} lg={3}>
                                    <div style={{ whiteSpace: "nowrap", fontSize: "18px" }}>
                                        Consumption : <span style={{ whiteSpace: 'nowrap' }}>{this.state.totalVolume == -1 ? "Not set" : this.props.ChannelAUnits == "m3" ? this.state.totalVolume.toFixed(3) : (this.state.totalVolume / 1000).toFixed(3)} <span>m<sup>3</sup></span></span>
                                    </div>
                                </GridItem>
                        }
                        {(this.props.channelsAvail & 0x01) > 0 && this.props.ChannelAUnits == "mm" &&
                                <GridItem xs={12} sm={6} md={4} lg={3}>
                                    <div style={{ whiteSpace: "nowrap", fontSize: "18px" }}>
                                            Total rainfall : <span style={{ whiteSpace: 'nowrap' }}>{this.state.totalRainfall == -1 ? "Not set" : this.state.totalRainfall.toFixed(3)} {this.props.ChannelAUnits}</span>
                                    </div>
                                </GridItem>
                        }
                        </GridContainer>
                        {this.props.haveAnalog &&
                            <div style={{ marginTop: "20px" }}>
                            <GridContainer spacing={3} >
                                
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ whiteSpace: "nowrap", fontSize: "18px", marginBottom: "5px" }}>
                                            <b>{window.location.hostname.includes('zonelog.net') ? "Pressure" : "Analogue"} :</b>
                                        </div>
                                </GridItem>
                                {(this.props.channelsAvail & 0x04) > 0 &&
                                    <GridItem xs={12} sm={6} md={3} lg={3}>
                                            <div style={{ whiteSpace: "nowrap", fontSize: "18px", marginBottom: "10px" }}>
                                Chan B Max pressure : <span style={{ whiteSpace: 'nowrap' }}>{this.state.maxCValue == -1 ? "Not set" : this.state.maxCValue.toFixed(3) + " " + this.props.ChannelCUnits}</span>
                                        </div>
                                    </GridItem>
                                }
                                {(this.props.channelsAvail & 0x04) > 0 &&
                                    <GridItem xs={12} sm={6} md={3} lg={3}>
                                            <div style={{ whiteSpace: "nowrap", fontSize: "18px", marginBottom: "10px" }}>
                                Chan B Min pressure : <span style={{ whiteSpace: 'nowrap' }}>{this.state.minCValue == Number.MAX_VALUE ? "Not set" : this.state.minCValue.toFixed(3) + " " + this.props.ChannelCUnits}</span>
                                        </div>
                                    </GridItem>
                                }
                                <GridItem xs={0} sm={12} md={12} lg={12}></GridItem>
                                {(this.props.channelsAvail & 0x08) > 0 &&
                                    <GridItem xs={12} sm={6} md={4} lg={3}>
                                            <div style={{ whiteSpace: "nowrap", fontSize: "18px", marginBottom: "10px" }}>
                                Chan C Max pressure : <span style={{ whiteSpace: 'nowrap' }}>{this.state.maxDValue == -1 ? "Not set" : this.state.maxDValue.toFixed(3) + " " + this.props.ChannelDUnits}</span>
                                        </div>
                                    </GridItem>
                                }
                                {(this.props.channelsAvail & 0x08) > 0 &&
                                    <GridItem xs={12} sm={6} md={4} lg={3}>
                                            <div style={{ whiteSpace: "nowrap", fontSize: "18px", marginBottom: "10px" }}>
                                Chan C Min pressure : <span style={{ whiteSpace: 'nowrap' }}>{this.state.minDValue == Number.MAX_VALUE ? "Not set" : this.state.minDValue.toFixed(3) + " " + this.props.ChannelDUnits}</span>
                                        </div>
                                    </GridItem>
                                    }
                                
                                </GridContainer>
                            </div>
                        }
                    </div>
            }
        </div>
        );

    }
}
export default PeriodStatsCHR;