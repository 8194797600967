import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerConnectionsModel, buildAPIGetLoggerConnectionsModel } from "models/APIGetLoggerConnectionsModel";
// DevExtreme
import { DataGrid, Column, Export, Scrolling, FilterRow, StateStoring, Pager, Paging, HeaderFilter } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

import IconButton from '@mui/material/IconButton';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';


//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import { withStyles, createStyles } from '@mui/styles';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';

//DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Chart, { CommonSeriesSettings, ArgumentAxis, Series, Legend, Aggregation, ValueAxis, ZoomAndPan, ScrollBar, Point } from 'devextreme-react/chart';
import DateBox from 'devextreme-react/date-box';

const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serials?: string[];
    site?: number;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    maxValue: number;
    minValue: number;
    channels: string[];
    channelFilters: boolean[];
    filterValue: Array<string | string[]>;
    startDate: Date;
    endDate: Date;
    connectionsData: Array<APIGetLoggerConnectionsModel>;
    graphData: Array<
        {
            date: Date;
            signal: number | null;
            battery: number | null;
        }
    > | undefined;
}

class ConnectionsPanel extends React.Component<Props, State> {

    gridRef: React.RefObject<DataGrid>;

    constructor(props: Readonly<Props>) {
        super(props);
        this.gridRef = React.createRef();
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            maxValue: 0,
            minValue: Number.MAX_VALUE,
            channels: [],
            channelFilters: [true, true, true, true, true],
            filterValue: [],
            startDate: moment().subtract(7, 'days').toDate(),
            endDate: moment().toDate(),
            connectionsData: [],
            graphData: undefined
        };
    }
    //get dataGrid(): dxDataGrid | undefined {
    //    return this.gridRef.current?.instance;
    //}

    componentDidMount(): void {


        //get alarms for logger here
        if (this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({ loading: false, tableHidden: false });
        }
        else {
            this.setState({ loading: false, tableHidden: true });
        }

    }

    reloadData(start: Date, end: Date): boolean {
        let connectionsData = new Array<APIGetLoggerConnectionsModel>();
        const graphData = new Array<
            {
                date: Date;
                signal: number | null;
                battery: number | null;
            }
            >();

        //get alarms for logger here
        const me = this;

        if (me.props.serials != undefined) {

            const promises = new Array<Promise<any>>();
            // generate array of Promises each adding the connectionsData for a logger
            me.props.serials.forEach((serial: string) => {
                promises.push(
                    CallGetAPI(CreateUrl('/api/aquaguard/LoggerConnections?serial=' + serial + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"), {})
                        .then(json => {
                            connectionsData = connectionsData.concat(buildAPIGetLoggerConnectionsModel(json));
                        })
                        .catch(function (ex) {
                            me.setState(
                                {
                                    authorized: false,
                                    connectionsData: []
                                });
                            console.log(ex);
                        })
                );
            });

            Promise.all(promises).then(() => {
                connectionsData.forEach((v: any) => {
                    if (v.connectionTime != null) {

                        v.connectionTime = adjustTime(v.connectionTime);
                        graphData.push({
                            date: new Date(v.connectionTime),
                            signal: v.latestRssi == 0 ? null : v.latestRssi,
                            battery: v.readBatteryChannel == 0 ? null : v.readBatteryChannel
                        });
                    }
                });

                graphData.sort((a, b) => a.date.getTime() - b.date.getTime());

                me.setState({
                    connectionsData: connectionsData,
                    graphData: graphData,
                });
            });
        }
        return true;
    }

    startDateChanged(e: any): void {
        this.setState({
            startDate: e.value
        });

        if (!this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({ tableHidden: true });
        }
    }

    endDateChanged(e: any): void {
        this.setState({
            endDate: e.value
        });

        if (!this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({  tableHidden: true });
        }
    }

    /// Customise Date/Time display
    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm:ss");
    }

    /// Customise ms duration into seconds
    columnCustomizeDuration(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return (Math.round(cellInfo.value) / 1000).toFixed(3) + "s";
    }

    /// Customise connection result
    columnCustomizeResult(cellInfo: any): string {

        const results = ["FAILED",
            "COMPLETED",
            "SEND_ERROR",
            "READ_ERROR",
            "READ_TIMEOUT",
            "CLIENT_DISCONNECTED",
            "PARSE_ERROR",
            "UNSUPPORTED_CLIENT",
            "LOG_DOWNLOAD_FAILED",
            "LOG_CRC_MISSMATCH",
            "SUCCESS",
            "FAILED_TO_AUTHENTICATE",
            "UNKNOWN_REQUEST",
            "DATABASE_ERROR",
            "UNIDENTIFIED",
            "INVALID"
        ];

        const intVal = parseInt(cellInfo.value);

        if (cellInfo.value == null
            || intVal == undefined
            || intVal < 0
            || intVal >= results.length) {
            return "";
        }
        else {
            return results[intVal]; 
        }
    }

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Connections');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }


    render(): ReactNode {

        return (

            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                    {!this.state.tableHidden &&
                        <GridContainer>
                            <GridItem xs={5} sm={5} md={5}>
                                Start date :&nbsp;<DateBox id="startDate" displayFormat={"dd/MM/yyyy"} value={this.state.startDate} onValueChanged={this.startDateChanged.bind(this)} />
                            </GridItem>
                            <GridItem xs={5} sm={5} md={5}>
                                End date :&nbsp;<DateBox id="endDate" displayFormat={"dd/MM/yyyy"} value={this.state.endDate} onValueChanged={this.endDateChanged.bind(this)} />
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                                <div style={{ display: 'inline', float: "right" }}>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={(): boolean => this.reloadData(this.state.startDate, this.state.endDate)}
                                        aria-label="refresh"
                                    >
                                        <RefreshRoundedIcon />
                                    </IconButton>
                                </div>
                            </GridItem>
                           <GridItem xs={12} sm={12} md={12}>
                                <CustomTabs
                                    headerColor="info"
                                    tabs={[
                                        {
                                            tabName: "Data",
                                            tabIcon: TableChartOutlinedIcon,
                                            tabContent: (
                                                <DataGrid 
                                                    dataSource={this.state.connectionsData}
                                                    keyExpr="id"
                                                    ref={this.gridRef} 
                                                    defaultFilterValue={this.state.filterValue}
                                                    allowColumnResizing={true}
                                                    columnAutoWidth={true}
                                                    columnMinWidth={80}
                                                    onExporting={this.onExporting} >
                                                    <Scrolling mode="standard" useNative={true}/>
                                                    <FilterRow visible={true} />
                                                    <HeaderFilter visible={true} />
                                                    <Export enabled={true} />
                                                    <StateStoring enabled={true} type="localStorage" storageKey="connectionsPanelGrid" />
                                                    <Column dataField="id" visible={false} dataType="number" />
                                                    <Column dataField="loggerSerial" name="Logger" dataType="string" />
                                                    <Column dataField="siteId" name="SiteId" dataType="string" />
                                                    <Column dataField="clientEndPoint" name="From IP" dataType="string" />
                                                    <Column dataField="connectionTime" name="Connection" customizeText={this.dateColumnCustomizeText} dataType="datetime" width="120" defaultSortIndex={1} defaultSortOrder="desc" caption="Connection Time" allowHeaderFiltering={false}/>
                                                    <Column dataField="alarmState" dataType="number" />
                                                    <Column dataField="rtcTime" name="Logger RTC" customizeText={this.dateColumnCustomizeText} dataType="datetime" width="120" caption="RTC Time (UTC)" allowHeaderFiltering={false}/>
                                                    <Column dataField="latestRssi" name="RSSI" dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="readBatteryChannel" name="Battery" dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="logFirst" dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="logLast" dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="logLastUpload" dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="totalConnectionTime" customizeText={this.columnCustomizeDuration} dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="logDownloadTime" customizeText={this.columnCustomizeDuration} dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="stdParamsDlTime" customizeText={this.columnCustomizeDuration} dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="calParamsDlTime" customizeText={this.columnCustomizeDuration} dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="stdParamsUpdateTime" customizeText={this.columnCustomizeDuration} dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="firmwareUploadTime" customizeText={this.columnCustomizeDuration} dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="firmwareUploadResult" customizeText={this.columnCustomizeResult} dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="logsDownloaded" dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="logsAvailable" dataType="number" allowHeaderFiltering={false}/>
                                                    <Column dataField="connectionResult" customizeText={this.columnCustomizeResult} dataType="number" />
                                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                    <Paging defaultPageSize={10} />
                                                </DataGrid>
                                            )
                                        },
                                        {
                                            tabName: "Graph",
                                            tabIcon: PollOutlinedIcon,
                                            tabContent: (
                                                <Chart dataSource={this.state.graphData}>
                                                    <CommonSeriesSettings
                                                        argumentField="date"
                                                        type="line"
                                                        ignoreEmptyPoints={true}>
                                                        <Aggregation
                                                            enabled={true}
                                                            method="avg"
                                                        />
                                                        <Point size={4} />
                                                    </CommonSeriesSettings>
                                                    <ValueAxis
                                                        name="Signal"
                                                        visualRange={{ startValue: 0, endValue: 30 }}
                                                        allowDecimals={false}
                                                        position="left"
                                                        title={{ text: "Signal strength" }}
                                                    />
                                                    <ValueAxis
                                                        name="Battery"
                                                        visualRange={{ startValue: 0, endValue: 5 }}
                                                        allowDecimals={true}
                                                        position="right"
                                                        title={{ text: "Battery(v)" }}
                                                    />
                                                    <ArgumentAxis
                                                        aggregationInterval={{ minutes: 1 }}
                                                        allowDecimals={false}
                                                        argumentType="datetime"
                                                        minValueMargin={0.1}
                                                        maxValueMargin={0.1}
                                                    />
                                                    <ZoomAndPan
                                                        argumentAxis="both"
                                                        valueAxis="none"
                                                    />
                                                    <ScrollBar
                                                        visible={true}
                                                    />
                                                    <Series valueField="signal" name="Signal" color="blue" axis="Signal" />
                                                    <Series valueField="battery" name="Battery" color="green" axis="Battery" />
                                                    <Legend visible={true} />
                                                </Chart>
                                            )
                                        }
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>

                    }
                    </div>
                }

            </div>

        )
    }

}

export default withStyles(style)(ConnectionsPanel);