export interface APIGetLoggerAlarmLevelModel {
    id: number;

    startTime: Date;

    channels: string;

    channelASetLevel: number | null;

    channelAClearLevel: number | null;

    channelBSetLevel: number | null;

    channelBClearLevel: number | null;

    channelCSetLevel: number | null;

    channelCClearLevel: number | null;

    channelDSetLevel: number | null;

    channelDClearLevel: number | null;

    channelESetLevel: number | null;

    channelEClearLevel: number | null;

    batterySetLevel: number | null;

    batteryClearLevel: number | null;

}

export function buildAPIGetLoggerAlarmLevelModel(r: any): Array<APIGetLoggerAlarmLevelModel> {

    const result = new Array<APIGetLoggerAlarmLevelModel>();

    r?.forEach(function (rec: any) {
        result.push(rec);
    });

    return result;
}