export interface APIGetLoggerConnectionsModel {
    id: number;
    loggerSerial: number | null;
    connectionTime: Date;
    rtcTime: Date | null; 

    latestRssi: number | null;
    latestBer: number | null;
    readBatteryChannel: number | null;
    alarmState: number | null;

    logFirst: number;
    logLast: number;
    logLastUpload: number | null;

    blockRetries: number;
    connectionRetries: number;

    siteId: string;
    clientEndPoint: string;

    totalConnectionTime: number;
    logDownloadTime: number | null;
    stdParamsDlTime: number | null;
    stdParamsUpdateTime: number | null;
    calParamsDlTime: number | null;
    calParamsUpdateTime: number | null;

    downloadedStdParams: boolean;
    downloadedCalParams: boolean;

    logsDownloaded: number;
    logsAvailable: number; 

    logDownloadResult: number | null;
    connectionResult: number;

    firmwareUploadResult: number | null;
    firmwareUploadTime: number | null;

    sendToLogger: number | null; 
    updateRequested: Date | null; 
    updateConfirmed: Date | null;
    firmwareToUpdate: number | null;
    firmwareSent: number | null;
    firmwareConfirmed: Date | null;

}

export function buildAPIGetLoggerConnectionsModel(r: any): Array<APIGetLoggerConnectionsModel> {

    const result = new Array<APIGetLoggerConnectionsModel>();

    r?.forEach(function (rec: any) {
        rec.latestRssi = (rec.latestRssi & 0xff00) / 256;
        rec.readBatteryChannel = rec.readBatteryChannel / 1000;

        result.push(rec);
    });

    return result;
}




