import React, { ReactNode } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { APIGetLoggerConnectionsModel } from "models/APIGetLoggerConnectionsModel";

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';

import { withStyles, createStyles } from '@mui/styles';

//DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Chart, { CommonSeriesSettings, ArgumentAxis, Series, Legend, ValueAxis, Title, Subtitle, CommonAxisSettings, Size, TickInterval, Tooltip } from 'devextreme-react/chart';


const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Data {
    date: Date;
    good: number;
    failed: number;
}

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    connectionsData: Array<APIGetLoggerConnectionsModel>;
    lastConnection: Date | undefined;
}

interface State {
    loading: boolean;
    noData: boolean;
    chartHidden: boolean;
    authorized: boolean;
    maxValue: number;
    graphData: Array<Data>;
}

class ConnectionsGraph extends React.Component<Props, State> {

    timer: any;

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            noData: false,
            chartHidden: true,
            authorized: true,
            maxValue: 0,
            graphData: [],
        };
    }
    //get dataGrid(): dxDataGrid | undefined {
    //    return this.gridRef.current?.instance;
    //}

    componentDidMount(): void {
        const data: Array<Data> = [];
        for (let i = 6; i >= 0; i--) {
            data.push({
                date: moment().subtract(i, "days").startOf("day").toDate(),
                good: 0,
                failed: 0
            })
        }

        this.setState({ graphData: data });

        this.timer = setInterval(this.loadTimeout.bind(this), 5000); 



        if (this.props.connectionsData.length > 0) {
            const graphData: Array<Data> = data;
            let maxValue = this.state.maxValue;

            const dataConnections: Array<APIGetLoggerConnectionsModel> = [];

            for (let i = 0; i < this.props.connectionsData.length; i++) {
                if (this.props.connectionsData[i].clientEndPoint.includes("DATA")
                    || this.props.connectionsData[i].clientEndPoint.includes("MQTT_Flow")
                    || this.props.connectionsData[i].clientEndPoint.includes("MQTT_Analogue")
                    || (this.props.connectionsData[i].logDownloadTime != null && this.props.connectionsData[i].logDownloadTime > 0)
                )
                    dataConnections.push(this.props.connectionsData[i]);

            }

            //this.props.connectionsData.map((c) => {
            dataConnections.map((c) => {
                const idx = graphData.findIndex((g) => {
                    return moment(c.connectionTime).startOf("day").isSame(g.date, "day");
                });
                if (idx != -1) {
                    if (c.connectionResult == 10) {
                        graphData[idx].good++;
                    }
                    else {
                        graphData[idx].failed++;
                    }

                    if ((graphData[idx].good + graphData[idx].failed) > maxValue) {
                        maxValue = graphData[idx].good + graphData[idx].failed;
                    }
                }
            });

            this.setState({ graphData: graphData, maxValue: maxValue, loading: false, noData: false });
        }


    }

    componentWillUnmount(): void {
        clearInterval(this.timer);
    }

   

    loadTimeout(): void {
        if (this.state.loading) {
            this.setState({ loading: false, noData: true });
        }
    }


    /// Customise connection result
    columnCustomizeResult(cellInfo: any): string {

        const results = ["FAILED",
            "COMPLETED",
            "SEND_ERROR",
            "READ_ERROR",
            "READ_TIMEOUT",
            "CLIENT_DISCONNECTED",
            "PARSE_ERROR",
            "UNSUPPORTED_CLIENT",
            "LOG_DOWNLOAD_FAILED",
            "LOG_CRC_MISSMATCH",
            "SUCCESS",
            "FAILED_TO_AUTHENTICATE",
            "UNKNOWN_REQUEST",
            "DATABASE_ERROR",
            "UNIDENTIFIED",
            "INVALID"
        ];

        const intVal = parseInt(cellInfo.value);

        if (cellInfo.value == null
            || intVal == undefined
            || intVal < 0
            || intVal >= results.length) {
            return "";
        }
        else {
            return results[intVal]; 
        }
    }

    render(): ReactNode {
        const subTitle = "Last connect " + this.props.lastConnection ? moment(this.props.lastConnection).format("HH:mm DD/MM/YYYY") : "Never";

        return (

            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'relative', left: '40%', top: '50%',
                    }}>
                        <ClipLoader
                            size={50}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading && this.state.noData &&
                    <div style={{ textAlign: "center" }}>
                    <h5 ><b>Daily data connections</b></h5>
                        <div style={{marginBottom: "10px"} }>
                    No data
                    </div>
                    </div>
                }
                {!this.state.loading && !this.state.noData &&
                    <div>
                        <h5 style={{textAlign: "center"} }><b>Daily data connections</b></h5>
                    <Chart dataSource={this.state.graphData} >
                        <Size height={250} width={250} />
                        <Title text="">
                            <Subtitle text={subTitle} font={{ size: "10px" }}/>
                        </Title>
                        <CommonSeriesSettings
                            argumentField="date"
                            type="stackedBar"
                        >
                        </CommonSeriesSettings>
                        <CommonAxisSettings
                            discreteAxisDivisionMode="crossLabels"
                        />
                        <ValueAxis
                            name="Count"
                            visualRange={{ startValue: 0, endValue: this.state.maxValue }}
                            allowDecimals={false}
                            position="left"
                            title={{ text: "Count" }}
                        />
                        <ArgumentAxis
                            aggregationInterval={{ days: 1 }}
                            allowDecimals={false}
                            argumentType="datetime"
                            minValueMargin={0.1}
                            maxValueMargin={0.1}
                        >
                        <TickInterval days={1} />
                        </ArgumentAxis>
                            <Series valueField="failed" name="Failed" color="#FF0000" />
                            <Series valueField="good" name="Good" color="#00BF00" />
                        <Legend position="outside" horizontalAlignment="center" verticalAlignment="bottom" />
                        <Tooltip enabled={true} />
                    </Chart>
                </div>}

            </div>

        )
    }

}

export default withStyles(style)(ConnectionsGraph);