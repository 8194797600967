import React, { ReactNode } from "react";
import { Redirect } from "react-router-dom";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Modal from 'react-bootstrap/Modal'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import ClipLoader from "react-spinners/ClipLoader";
import { Store } from 'react-notifications-component';
//import makeAnimated from 'react-select/animated';
//import { withRouter } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from "@mui/material/IconButton";
import MaterialButton from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { Collapse, Tab } from "react-bootstrap";
import { buildAPIGetLoggerSummaryModel } from "models/APIGetLoggerSummaryModel";
import BasicSummary from "components/DeviceSummary/BasicSummary";
import TextlogSummary from "components/DeviceSummary/TextlogSummary";
import SWGSummary from "components/DeviceSummary/SWGSummary";
import HYGSummary from "components/DeviceSummary/HYGSummary";
import TORSummary from "components/DeviceSummary/TORSummary";
import AlarmsGraphSWG from "components/Alarms/AlarmsGraphSWG";
import DateRange from '../../components/Dates/DateRange';
import { Button } from 'devextreme-react/button';
import { Accordion, Typography } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ImagesPanel from 'components/ImageUpload/ImagesPanel'

//Moment date/time formatting
//https://momentjs.com/docs/
import moment, { Duration } from 'moment';

// ExcelJS
import ExcelJS, { Column } from 'exceljs';
import { saveAs } from 'file-saver';
import { APIGetLoggerReadingsModel } from "models/APIGetLoggerReadingsModel";
import JSZip from 'jszip';


import Selector from '../../components/Grid/Selector';
import ReadingsGraphSWG from '../../components/Readings/ReadingsGraphSWG';
//import CombinedGraphTOR from '../../components/Readings/CombinedGraphTOR';
import PeriodStatsTOR from '../../components/Readings/PeriodStatsTOR';
import ReadingsGraphTOR from '../../components/Readings/ReadingsGraphTOR';
//import RainfallGraph from '../../components/EA/RainfallGraph';
import StripedGraphSWG from '../../components/Readings/StripedGraphSWG';
import CombinedGraphTL from '../../components/Readings/CombinedGraphTL';
//import RainGaugeGraphTOR from '../../components/Readings/RainGaugeGraphTOR';
import Grid from '@mui/material/Grid';
import MultiLoggerGraph from '../Groups/MultiLoggerGraph';
//import Button from '@mui/material/Button';
import {
    Popup
} from 'devextreme-react/popup';


const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);
//const animatedComponents = makeAnimated();

const deviceSummaries = {
    unknown: BasicSummary,
    textlog: TextlogSummary,
    hyg: HYGSummary,
    tor: TORSummary,
    swg: SWGSummary,
    'tor-n': TORSummary,
    'tor-r': TORSummary,
    'tor-s': TORSummary,
};


interface Props {
    classes: {
        cardTitleWhite: string;
        mlgLinkStyle: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: {
            serial: string;
        };
        search: string;
    };
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    summaryExpanded: boolean;
    readingsExpanded: boolean;
    loggerType: string;
    serial: string;
    eaSiteId: number;
    allowBack: boolean;
    startDate: Date;
    endDate: Date;
    showRainfall: boolean;
    channels: string[];
    meterConfig: number;
    ChannelAUnits: string;
    ChannelBUnits: string;
    ChannelCDp: number | null;
    ChannelCUnits: string;
    ChannelDDp: number | null;
    ChannelDUnits: string;
    ChannelEDp: number | null;
    ChannelEUnits: string;
    exportDialog: boolean;
    exportType: string;
    exportData: boolean[];
    exportEnable: boolean;
    isDormant: boolean;
    loggerIds: number[];
    showMLGPopUp: boolean;
    siteName: string;
    imageCount: number;
    imagesExpanded: boolean;
    mapKey: string;
}

interface ReadingsState {
    state: {
        loading: boolean;
        readingsData: Array<APIGetLoggerReadingsModel>;
    };
}



class DeviceOverview extends React.Component<Props, State> {

    // REFs to the channel reading components for Data export
    ChannelMLGReadings: React.RefObject<ReadingsState>;

    ChannelAReadings: React.RefObject<ReadingsState>;
    ChannelBReadings: React.RefObject<ReadingsState>;
    ChannelCReadings: React.RefObject<ReadingsState>;
    ChannelDReadings: React.RefObject<ReadingsState>;
    ChannelEReadings: React.RefObject<ReadingsState>;
    RainfallReadings: React.RefObject<ReadingsState>;
    // Cache for readings data, to stop reload on Selector switch
    CacheAReadings: Array<APIGetLoggerReadingsModel>;
    CacheBReadings: Array<APIGetLoggerReadingsModel>;
    CacheAllReadings: Array<APIGetLoggerReadingsModel>;

    timer: any = null;     // Check for all readings loaded ready for export


    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            summaryExpanded: true,
            readingsExpanded: true,
            loggerType: "",
            serial: "",
            eaSiteId: 0,
            allowBack: true,
            startDate: moment().subtract(7, "days").toDate(),
            endDate: new Date(),
            showRainfall: false,
            channels: [],
            meterConfig: 0,
            ChannelAUnits: "",
            ChannelBUnits: "",
            ChannelCDp: 0,
            ChannelCUnits: "",
            ChannelDDp: 0,
            ChannelDUnits: "",
            ChannelEDp: 0,
            ChannelEUnits: "",
            exportDialog: false,
            exportType: "XLSX",
            exportData: [false, false, false, false, false],
            exportEnable: false,
            isDormant: false,
            loggerIds: [],
            showMLGPopUp: false,
            siteName: "",
            imageCount: 0,
            imagesExpanded: false,
            mapKey: ""
        };

        this.ChannelMLGReadings = React.createRef();

        this.ChannelAReadings = React.createRef();
        this.ChannelBReadings = React.createRef();
        this.ChannelCReadings = React.createRef();
        this.ChannelDReadings = React.createRef();
        this.ChannelEReadings = React.createRef();
        this.RainfallReadings = React.createRef();

        this.CacheAReadings = new Array<APIGetLoggerReadingsModel>();
        this.CacheBReadings = new Array<APIGetLoggerReadingsModel>();
        this.CacheAllReadings = new Array<APIGetLoggerReadingsModel>();

        this.showMLGPopUp = this.showMLGPopUp.bind(this)
        this.hideMLGPopUp = this.hideMLGPopUp.bind(this)        
    }

    createSuccessNotification = (): void => {
        Store.addNotification({
            title: "Saved Changes",
            message: "Success",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });

    };

    createErrorNotification = (): void => {
        Store.addNotification({
            title: "Error Saving Changes",
            message: "Click me!",
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                click: true
            },
            onRemoval: () => {
                alert('callback');
            }
        });

    };

    componentDidMount(): void {
        // Get logger serial - either from location.state (link from React page), or query string (?serial=)

        let serial = "";
        let allowBack = true;
        if (this.props.location.state) {
            serial = this.props.location.state.serial;
        }
        else {
            serial = (this.props.location.search.split('='))[1];
            allowBack = false;
        }

        this.setState({ serial: serial, allowBack: allowBack });

        //get detail on logger here
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/LoggerBySerial?serial=' + serial),{})
            .then(response => {
                const data = buildAPIGetLoggerSummaryModel(response);

                const channels = data.Channels.length > 4 ? data.Channels.split(",") : ["A", "B", "C", "D", "E"];
                const meterConfig = data.MeterConfig ? data.MeterConfig: 0
                if ((meterConfig & 0x01) == 0) {
                    channels[0] = "";
                }
                if ((meterConfig & 0x08) == 0) {
                    channels[1] = "";
                }
                if ((meterConfig & 0x0100) == 0) {
                    channels[2] = "";
                }
                if ((meterConfig & 0x0800) == 0) {
                    channels[3] = "";
                }
                if ((meterConfig & 0x2000) == 0) {
                    channels[4] = "";
                }

                me.setState({
                    loggerType: data.Model,
                    eaSiteId: data.EASiteId ? data.EASiteId : 0,
                    channels: channels,
                    meterConfig: meterConfig,
                    ChannelAUnits: data.ChannelAUnits != null ? data.ChannelAUnits : "",
                    ChannelBUnits: data.ChannelBUnits != null ? data.ChannelBUnits : "",
                    ChannelCDp: data.ChannelCDp,
                    ChannelCUnits: data.ChannelCUnits != null ? data.ChannelCUnits : "",
                    ChannelDDp: data.ChannelDDp,
                    ChannelDUnits: data.ChannelDUnits != null ? data.ChannelDUnits : "",
                    ChannelEDp: data.ChannelEDp,
                    ChannelEUnits: data.ChannelEUnits != null ? data.ChannelEUnits : "",
                    //startDate: data.Model.toLowerCase() == "textlog" ? moment().subtract(1, "days").toDate() : moment().subtract(2, "days").toDate(),
                    startDate: moment().subtract(7, "days").toDate(),

                    isDormant: data.Dormant,
                    loggerIds: [data.Id],
                    siteName: data.SiteNameUserFriendly + "(" + data.SiteName + ")",
                });

                CallGetAPI(CreateUrl('/api/aquaguard/mapkey'), {})
                    .then(res => {
                        this.setState({ mapKey: res })
                    })

                CallGetAPI(CreateUrl('/api/aquaguard/ImageCount?loggerId=' + data.Id), {})
                .then(data => {

                    me.setState(
                        {
                            imageCount: data,
                        })

                })
                .catch(function () {
                    me.setState(
                        {
                            imageCount: 0,
                        })
                });

                this.setState({ loading: false, tableHidden: false, summaryExpanded: true });

            })
            .catch((error: Error) => {
                me.setState(
                    {
                        loading: false,
                        authorized: false,
                        tableHidden: true,
                        loggerType: 'unknown'
                    })
                console.log(error)
            });


    }

    componentWillUnmount(): void {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    // Function passed to display components to access cached readings
    updateCache(channel: string, readings: Array<APIGetLoggerReadingsModel>): void {
         // Save stores readings in cache
        if (channel == "") {
            this.CacheAllReadings.splice(0, this.CacheAllReadings.length, ...readings);
        }
        if (channel == "A") {
            this.CacheAReadings.splice(0, this.CacheAReadings.length, ...readings);
        }
        if (channel == "B") {
            this.CacheBReadings.splice(0, this.CacheBReadings.length, ...readings);
        }

    }

    onDateChange(startDate: Date, endDate: Date, rainfall: boolean): void {
        this.CacheAReadings = [];
        this.CacheBReadings = [];
        this.CacheAllReadings = [];
        this.setState({ startDate: startDate, endDate: endDate, showRainfall: rainfall });
    }

    blankFunction = function ():void {
        return undefined;
    };

    toggleExportModal(): void {
        this.setState({ exportDialog: !this.state.exportDialog });
        // NOTE Test checks PREVIOUS state
        if (!this.state.exportDialog) {
            this.timer = setInterval(this.exportEnable.bind(this), 1000);
        }
        else {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    exportEnable(): void {
        const enabled = (this.state.exportData[0] && !this.ChannelAReadings.current?.state.loading)
            || (this.state.exportData[1] && !this.ChannelBReadings.current?.state.loading)
            || (this.state.exportData[2] && !this.ChannelCReadings.current?.state.loading)
            || (this.state.exportData[3] && !this.ChannelDReadings.current?.state.loading)
            || (this.state.exportData[4] && !this.ChannelEReadings.current?.state.loading)
            || (this.state.exportData[5] && !this.ChannelMLGReadings.current?.state.loading);

        this.setState({
            exportEnable: enabled
        });
        if (enabled) {
            clearInterval(this.timer);
            this.timer = null;

        }
    }

    handleExportAsChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ exportType: (event.target as HTMLInputElement).value });
    }

    handleExportSelectChange(event: React.ChangeEvent<HTMLInputElement>): void {

        const data = this.state.exportData;
        const idx = parseInt((event.target as HTMLInputElement).name);
        data[idx] = (event.target as HTMLInputElement).checked;

        this.setState({ exportData: data });
    }


    exportConfirm(): void {

        const workbook = new ExcelJS.Workbook();
        this.state.exportData.map((d, i) => {
            if (d) {
                let readingsData: Array<APIGetLoggerReadingsModel> = [];
                switch (i) {
                    case 0:
                        if (!this.ChannelAReadings.current?.state.loading) {
                            readingsData = this.ChannelAReadings.current?.state.readingsData ? this.ChannelAReadings.current?.state.readingsData : [];
                        }
                        break;
                    case 1:
                        if (!this.ChannelBReadings.current?.state.loading) {
                            readingsData = this.ChannelBReadings.current?.state.readingsData ? this.ChannelBReadings.current?.state.readingsData : [];
                        }
                        break;
                    case 2:
                        if (!this.ChannelCReadings.current?.state.loading) {
                            readingsData = this.ChannelCReadings.current?.state.readingsData ? this.ChannelCReadings.current?.state.readingsData : [];
                        }
                        break;
                    case 3:
                        if (!this.ChannelDReadings.current?.state.loading) {
                            readingsData = this.ChannelDReadings.current?.state.readingsData ? this.ChannelDReadings.current?.state.readingsData : [];
                        }
                        break;
                    case 4:
                        if (!this.ChannelEReadings.current?.state.loading) {
                            readingsData = this.ChannelEReadings.current?.state.readingsData ? this.ChannelEReadings.current?.state.readingsData : [];
                        }
                        break;
                    case 5:
                        if (!this.ChannelMLGReadings.current?.state.loading) {
                            readingsData = this.ChannelMLGReadings.current?.state.readingsData ? this.ChannelMLGReadings.current?.state.readingsData : [];
                        }
                        break;
                }

                let worksheet = null;
                if (readingsData.length > 0) {

                    if (!this.state.loggerType.toLowerCase().includes('tor')) {
                        worksheet = workbook.addWorksheet(this.state.channels[i].replace(":", "#"), { views: [{ state: 'frozen', xSplit: 0, ySplit: 1 }] });
                    }
                    else
                    {
                        worksheet = workbook.addWorksheet("All Channels", { views: [{ state: 'frozen', xSplit: 0, ySplit: 1 }] });
                    }

                    worksheet.columns = [
                        { header: "Id", key: "id" },
                        { header: "Serial", key: "loggerSerial" },
                        { header: "Site", key: "site" },
                        { header: "Date", key: "dateStamp", style: { numFmt: 'yyyy-mm-dd hh:mm:ss'}, width: 25 },
                        { header: "Type", key: "type" },
                        { header: "Value", key: "value" },
                        { header: "Units", key: "units" },
                        { header: "Min", key: "minValue" },
                        { header: "Max", key: "maxValue" },
                        { header: "ChannelRef", key: "channelRef" },
                        { header: "ChannelSerial", key: "channelSerial" },
                        { header: "Letter", key: "channelLetter" },
                        { header: "Interval", key: "interval" },
                        { header: "Decimals", key: "dp" },
                        { header: "Forward", key: "forward" },
                        { header: "Reverse", key: "reverse" },
                        { header: "Total", key: "total" },
                        { header: "Multiplier", key: "multiplier" },
                        { header: "Difference", key: "difference" },
                        { header: "Flow Rate", key: "flowRate" },

                    ] as Column[]; // Fix to stop TS erroring

                    readingsData.sort((a, b) => { return (a.dateStamp ? new Date(a.dateStamp).valueOf() : 0) - (b.dateStamp ? new Date(b.dateStamp).valueOf() : 0) })
                    worksheet.addRows(readingsData);

                }
            }

        });

        const serial = this.state.serial;

        if (this.state.exportType == "CSV") {

            const zip = new JSZip();
            const promises = new Array<Promise<any>>();

            // Generate CSV files
            workbook.worksheets.map((sht) => {
                const options: ExcelJS.CsvWriteOptions = {
                    sheetName: sht.name,
                    sheetId: sht.id,
                    dateUTC: true,
                } as ExcelJS.CsvWriteOptions;
                promises.push(workbook.csv.writeBuffer(options)
                        .then(function (buffer: Buffer) {
                            zip.file(sht.name + ".csv", buffer.buffer);
                        })
                );
            });

            Promise.all(promises).then(() => {
                if (workbook.worksheets.length > 0) {
                    zip.generateAsync({ type: "blob" }).then(function (content: Blob) {
                        saveAs(content, 'Aquaguard Export for ' + serial + '.zip');
                    });

                }
            });

        }
        else {
            // Generate XLSX files
            // Format the Date column as Date on all worksheets
            workbook.worksheets.forEach(worksheet => {
                worksheet.getColumnKey("dateStamp").eachCell(cell => {
                    if (cell.value?.toString() != "Date") {
                        const dateStr = cell.value?.toString().replace('T', ' ');
                        cell.value = { formula: "DATEVALUE(\"" + dateStr + "\") + TIMEVALUE(\"" + dateStr + "\")", date1904: true };
                    }
                });
            });
            // Download the workbook
            if (workbook.worksheets.length > 0) {
                workbook.xlsx.writeBuffer().then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export for ' + serial + '.xlsx');
                });
            }

        }

        this.toggleExportModal();
    }

    showMLGPopUp(): void {
        this.setState({ showMLGPopUp: true });
    }

    hideMLGPopUp(): void {
        this.setState({ showMLGPopUp: false });
    }

    renderPanels(): ReactNode {

        const channels = this.state.channels;

        return <FormGroup>
            {this.state.loggerType.toLowerCase().includes('tor') && <FormControlLabel
                control={<Checkbox checked={this.state.exportData[5]} onChange={this.handleExportSelectChange.bind(this)} name="5" />}
                label="All"
            />}
            {!this.state.loggerType.toLowerCase().includes('tor') && channels[0] != "" && <FormControlLabel
                control={<Checkbox checked={this.state.exportData[0]} onChange={this.handleExportSelectChange.bind(this)} name="0" />}
                label={channels[0]}
            />}
            {!this.state.loggerType.toLowerCase().includes('tor') && channels[1] != "" && <FormControlLabel
                control={<Checkbox checked={this.state.exportData[1]} onChange={this.handleExportSelectChange.bind(this)} name="1" />}
                label={channels[1]}
            />}
            {!this.state.loggerType.toLowerCase().includes('tor') && channels[2] != "" && <FormControlLabel
                control={<Checkbox checked={this.state.exportData[2]} onChange={this.handleExportSelectChange.bind(this)} name="2" />}
                label={channels[2]}
            />}
            {!this.state.loggerType.toLowerCase().includes('tor') && channels[3] != "" && <FormControlLabel
                control={<Checkbox checked={this.state.exportData[3]} onChange={this.handleExportSelectChange.bind(this)} name="3" />}
                label={channels[3]}
            />}
            {!this.state.loggerType.toLowerCase().includes('tor') && channels[4] != "" && <FormControlLabel
                control={<Checkbox checked={this.state.exportData[4]} onChange={this.handleExportSelectChange.bind(this)} name="4" />}
                label={channels[4]}
            />}
        </FormGroup>;
    }

    renderReadings(): ReactNode {

        const classes = this.props.classes;

        const loggerIds = this.state.loggerIds;
        const channels = this.state.channels;
        const units = this.state.ChannelAUnits + "," + this.state.ChannelBUnits + "," + this.state.ChannelCUnits + "," + this.state.ChannelDUnits + "," + this.state.ChannelEUnits;

        switch (this.state.loggerType.toLowerCase()) {

            case 'textlog':
                return <Selector>
                    <CombinedGraphTL
                        serial={this.state.serial}
                        channels={channels}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        isprimary="yes"
                        title="All Channels"
                        units={this.state.ChannelAUnits}
                        cache={this.CacheAllReadings}
                        updateCache={this.updateCache.bind(this)}
                    />
                    {this.state.channels[0] != "" &&
                        <ReadingsGraphTOR serial={this.state.serial} channel="A" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="no" title={channels[0]} units={this.state.ChannelAUnits} colour="blue" ref={this.ChannelAReadings} cache={this.CacheAReadings} updateCache={this.updateCache.bind(this)} />
                    }
                    {this.state.channels[1] != "" &&
                        <ReadingsGraphTOR serial={this.state.serial} channel="B" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="no" title={channels[1]} units={this.state.ChannelBUnits} colour="green" ref={this.ChannelBReadings} cache={this.CacheBReadings} updateCache={this.updateCache.bind(this)} />
                    }
                    {(this.state.meterConfig & 0x0100) > 0 &&
                        <ReadingsGraphTOR serial={this.state.serial} channel="C" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="no" title={channels[2]} units={this.state.ChannelCUnits} colour="red" ref={this.ChannelCReadings} />
                    }
                    {(this.state.meterConfig & 0x0800) > 0 &&
                        <ReadingsGraphTOR serial={this.state.serial} channel="D" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="no" title={channels[3]} units={this.state.ChannelDUnits} colour="orange" ref={this.ChannelDReadings} />
                    }
                    {(this.state.meterConfig & 0x2000) > 0 &&
                        <ReadingsGraphTOR serial={this.state.serial} channel="E" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="no" title={channels[4]} units={this.state.ChannelEUnits} colour="purple" ref={this.ChannelEReadings} />
                    }
                </Selector>;
            case 'hyg':
                return <div>
                    Hydroguard logger type not yet supported
                </div>
            case 'swg':
                if (this.state.serial == "47650") {
                    return <Selector>
                        <AlarmsGraphSWG serial={this.state.serial} eaSiteId={this.state.eaSiteId} channels={channels.join(",")} startDate={this.state.startDate} endDate={this.state.endDate} showRainfall={this.state.showRainfall} isprimary="yes" units={units} meterConfig={this.state.meterConfig} />
                        <StripedGraphSWG serial={this.state.serial} channel="C" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="no" title={channels[2]} ref={this.ChannelCReadings} />
                        <ReadingsGraphSWG serial={this.state.serial} channel="D" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="no" title={channels[3]} ref={this.ChannelDReadings} />
                        <ReadingsGraphSWG serial={this.state.serial} channel="E" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="no" title={channels[4]} ref={this.ChannelEReadings} />
                    </Selector>;
                }
                else {
                    return <Selector>
                        <AlarmsGraphSWG serial={this.state.serial} eaSiteId={this.state.eaSiteId} channels={channels.join(",")} startDate={this.state.startDate} endDate={this.state.endDate} showRainfall={this.state.showRainfall} isprimary="yes" units={units} meterConfig={this.state.meterConfig}/>
                        <ReadingsGraphSWG serial={this.state.serial} channel="C" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="no" title={channels[2]} ref={this.ChannelCReadings} />
                        {(this.state.meterConfig & 0x0800) > 0 &&
                            <ReadingsGraphSWG serial={this.state.serial} channel="D" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="no" title={channels[3]} ref={this.ChannelDReadings} />
                        }
                        {(this.state.meterConfig & 0x2000) > 0 &&
                            <ReadingsGraphSWG serial={this.state.serial} channel="E" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="no" title={channels[4]} ref={this.ChannelEReadings} />
                        }
                    </Selector>;
                }
            case 'tor-n':
            case 'tor':
            case 'tor-r':
            case 'tor-s':
                return <div id="torPanel">

                    <Popup
                        id="popupMLG"
                        showTitle={true}
                        title={"Logger Serial " + this.state.serial + " [" + this.state.siteName + "]"}
                        visible={this.state.showMLGPopUp}
                        onHiding={this.hideMLGPopUp.bind(this)}
                        dragEnabled={true}
                        closeOnOutsideClick={true}
                        showCloseButton={true}
                        fullScreen={true}
                    >
                        <Grid container>
                            <Grid item className="grid" lg={12} md={12} sm={12}>
                                <div>
                                    <MultiLoggerGraph ref={this.ChannelMLGReadings} showDates={true} startDate={this.state.startDate} endDate={this.state.endDate} location={{ state: { groupId: 0, loggers: this.state.loggerIds, showBackButton: false, site: null } }} history={{ goBack: this.blankFunction }}/>
                                </div>
                            </Grid>
                        </Grid>
                    </Popup>

                        <Grid container>
                            <Grid item className="grid" lg={12} md={12} sm={12}>
                            <div>
                                <MultiLoggerGraph ref={this.ChannelMLGReadings} showDates={false} startDate={this.state.startDate} endDate={this.state.endDate} location={{ state: { groupId: 0, loggers: this.state.loggerIds, showBackButton: false, site: null } }} history={{ goBack: this.blankFunction }}/>
                            </div>
                            </Grid>

                        {/* All graphing for TOR handled by MLG
                             
                            <Grid item className="grid" lg={12} md={12} sm={12}>
                                    <CombinedGraphTOR
                                    serial={this.state.serial}
                                    channels={channels}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    isprimary="yes"
                                    title="All Channels"
                                    units={[this.state.ChannelAUnits, this.state.ChannelBUnits, this.state.ChannelCUnits, this.state.ChannelDUnits, this.state.ChannelEUnits]}
                                />
                            </Grid>
                            
                            {this.state.channels[0] != "" && this.state.ChannelAUnits != "mm" &&
                            <Grid item className="grid" lg={12} md={12} sm={12}>
                            <ReadingsGraphTOR serial={this.state.serial} channel="A" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="yes" title={channels[0]} units={this.state.ChannelAUnits} colour="blue" ref={this.ChannelAReadings} />
                            </Grid>
                            }

                            {this.state.channels[0] != "" && this.state.ChannelAUnits == "mm" &&
                            <Grid item className="grid" lg={12} md={12} sm={12}>
                            <RainGaugeGraphTOR serial={this.state.serial} channel="A" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="yes" title={channels[0] + " Hourly Rainfall"} units={this.state.ChannelAUnits} colour="blue" ref={this.ChannelAReadings} />
                            </Grid>
                            }

                            {this.state.channels[1] != "" &&
                            <Grid item className="grid" lg={12} md={12} sm={12}>
                            <ReadingsGraphTOR serial={this.state.serial} channel="B" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="yes" title={channels[1]} units={this.state.ChannelBUnits} colour="green" ref={this.ChannelBReadings} />
                            </Grid>
                            }

                            {(this.state.meterConfig & 0x0100) > 0 &&
                            <Grid item className="grid" lg={12} md={12} sm={12}>
                            <ReadingsGraphTOR serial={this.state.serial} channel="C" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="yes" title={channels[2]} units={this.state.ChannelCUnits} colour="red" ref={this.ChannelCReadings} />
                            </Grid>
                            }

                            {(this.state.meterConfig & 0x0800) > 0 &&
                            <Grid item className="grid" lg={12} md={12} sm={12}>
                            <ReadingsGraphTOR serial={this.state.serial} channel="D" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="yes" title={channels[3]} units={this.state.ChannelDUnits} colour="orange" ref={this.ChannelDReadings} />
                            </Grid>
                            }

                            {(this.state.meterConfig & 0x2000) > 0 &&
                            <Grid item className="grid" lg={12} md={12} sm={12}>
                            <ReadingsGraphTOR serial={this.state.serial} channel="E" startDate={this.state.startDate} endDate={this.state.endDate} isprimary="yes" title={channels[4]} units={this.state.ChannelEUnits} colour="purple" ref={this.ChannelEReadings} />
                            </Grid>
                            }

                            {this.state.showRainfall &&
                            <Grid item className="grid" lg={12} md={12} sm={12}>
                                <RainfallGraph EASiteId={this.state.eaSiteId} startDate={this.state.startDate} endDate={this.state.endDate} isprimary="yes" ref={this.RainfallReadings}/>
                            </Grid>
                            }
                        */}
                        </Grid>
                    </div>
        }

        return <div>
            Unknown logger type
        </div>

    }

    render(): ReactNode {
        const { classes, history } = this.props;
        const DeviceSummary = deviceSummaries[(this.state.loggerType || 'unknown').toLowerCase()];

        return (
            <div>
                <Modal
                    show={this.state.exportDialog}
                    centered
                    onHide={(): void => this.toggleExportModal()}
                    dialogClassName="modal-100w"
                    aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Export Data
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6} >
                                Export as:&nbsp;
                                    <RadioGroup aria-label="exportAs" name="exportAs" value={this.state.exportType} onChange={this.handleExportAsChange.bind(this)}>
                                    <FormControlLabel value="CSV" control={<Radio />} label="CSV" />
                                    <FormControlLabel value="XLSX" control={<Radio />} label="XLSX" />
                                    </RadioGroup>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                Choose channels:&nbsp;
                                {this.renderPanels()}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                {!this.state.exportEnable && 
                                    <div>Export not enabled until all readings are loaded</div>
                                }
                            </GridItem>
                        </GridContainer>
                    </Modal.Body>
                    <Modal.Footer>
                        <MaterialButton color="primary" onClick={(): void => this.exportConfirm()} disabled={!this.state.exportEnable}>Export</MaterialButton>
                        <MaterialButton color="secondary" onClick={(): void => this.toggleExportModal()}>Cancel</MaterialButton>
                    </Modal.Footer>
                </Modal>

                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                    {this.state.tableHidden &&
                        <ApiFailed />
                    }
                    {!this.state.tableHidden &&
                    <GridContainer >
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                    <div style={{display: 'inline', height: '40px', width: '100%'}}>
                                        {this.state.allowBack && <IconButton
                                            style={{ display: 'inline', marginTop: -5 }}
                                            onClick={(): void => history.goBack()}
                                            size="large"><ArrowBackIcon style={{ fill: "white" }} /></IconButton>}
                                        <h4 style={{ display: 'inline' }} className={classes.cardTitleWhite}>{this.state.serial + " (Site : " + this.state.siteName + ")"}</h4>
                                        {this.state.isDormant &&
                                                <h4 style={{ display: 'inline', color: "white", margin: " 5px 50px 0 0", float: "right", position: "relative" }} >DORMANT</h4>
                                        }
                                    </div>
                                </CardHeader>
                                <CardBody>

                                </CardBody>

                                <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                    <div style={{ display: 'inline' }}>
                                        <h4 style={{ display: 'inline', width: '100%' }} className={classes.cardTitleWhite} onClick={(): void => this.setState({ summaryExpanded: !this.state.summaryExpanded })}>Summary</h4>
                                    </div>
                                    <div>
                                        <IconButton
                                            onClick={(): void => this.setState({ summaryExpanded: !this.state.summaryExpanded })}
                                            size="large">
                                        {!this.state.summaryExpanded && <ExpandMoreIcon color="primary"/>}
                                        {this.state.summaryExpanded && <ExpandLessIcon color="primary"/>}
                                        </IconButton>
                                    </div>
                                </CardHeader>
                                <Collapse in={this.state.summaryExpanded} >
                                    <CardBody>
                                        <div >
                                            <DeviceSummary serial={this.state.serial} />
                                        </div>
                                    </CardBody>
                                </Collapse>

                                {this.state.imageCount > 0 &&
                                    <div>
                                        <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3" >
                                            <div style={{ display: 'inline' }}>
                                                        <h4 style={{ display: 'inline', width: '100%' }} className={classes.cardTitleWhite} onClick={(): void => this.setState({ imagesExpanded: !this.state.imagesExpanded })}>Images</h4>

                                            </div>
                                            <div>
                                                <IconButton
                                                            onClick={(): void => this.setState({ imagesExpanded: !this.state.imagesExpanded })}
                                                    size="large">
                                                            {!this.state.imagesExpanded && <ExpandMoreIcon color="primary" />}
                                                            {this.state.imagesExpanded && <ExpandLessIcon color="primary" />}
                                                </IconButton>
                                            </div>
                                        </CardHeader>
                                                <Collapse in={this.state.imagesExpanded} >
                                            <CardBody>
                                                <ImagesPanel loggerId={this.state.loggerIds[0]} />
                                            </CardBody>
                                        </Collapse>
                                </div>
                            }

                                <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3" >
                                    <div style={{ display: 'inline' }}>
                                        <h4 style={{ display: 'inline', width: '100%' }} className={classes.cardTitleWhite} onClick={(): void => this.setState({ readingsExpanded: !this.state.readingsExpanded })}>Readings</h4>

                                    </div>
                                    <div>
                                        <IconButton
                                            onClick={(): void => this.setState({ readingsExpanded: !this.state.readingsExpanded })}
                                            size="large">
                                            {!this.state.readingsExpanded && <ExpandMoreIcon color="primary" />}
                                            {this.state.readingsExpanded && <ExpandLessIcon color="primary" />}
                                        </IconButton>
                                    </div>
                                </CardHeader>
                                    <Collapse in={this.state.readingsExpanded} >
                                    <CardBody>
                                        <GridContainer spacing={3}>
                                                    <GridItem xs={12} md={7}>
                                                <DateRange type="date" startDate={this.state.startDate} endDate={this.state.endDate} rainfall={this.state.eaSiteId > 0} onDateChange={this.onDateChange.bind(this)} />
                                            </GridItem>
                                                    <GridItem xs={12} md={4} style={{margin: "20px 0 0 40px" } }>
                                                        <MaterialButton style={{marginRight: "10px"}} variant="contained" color="secondary" >Fullscreen Graph</MaterialButton>
                                                        <div style={{display: "inline"}} aria-label="export-excel-button" title="Export all data" tabIndex={0} role="button" onClick={this.toggleExportModal.bind(this)}>
                                                            <b>click to <u>Export</u> data -&gt;</b>&nbsp;&nbsp;
                                                            <Button onClick={this.showMLGPopUp}  style={{ backgroundColor: '#EED202', border: "none"}} icon="exportxlsx" />
                                                        </div>
                                                        {console.log(this.state.exportData) }
                                            </GridItem>
                                        </GridContainer>
                                        {this.renderReadings()}
                                    </CardBody>
                                    </Collapse>
                            </Card>
                        </GridItem>

                    </GridContainer>
                }

                    </div>
            }

                </div>
        );

    }

}

export default withStyles(style)(DeviceOverview);

