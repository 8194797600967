import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import ClipLoader from "react-spinners/ClipLoader";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from "@mui/material/IconButton";

import { Row, Col } from 'reactstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Accordion, Typography } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import SubGroupsPanel from 'components/Groups/SubGroupsPanel';
import UsersPanel from 'components/Users/UsersPanel';
import RegionsPanel from 'components/Regions/RegionsPanel';
import SitesPanel from 'components/Sites/SitesPanel';



const styles = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

export class GroupDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authorized: true,
            group: undefined,
            loading: true,
            members: undefined
        };
    }

    createSuccessNotification = () => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = () => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    componentDidMount() {
        //get detail on logger here

        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/AllMembersForGroup?groupid=' + me.props.location.state.group.groupId), {})
            .then(data => {
                   
                me.setState(
                    {
                        group: me.props.location.state.group,
                        members: data,
                        loading: false
                    })
                console.log(data)
                       
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false
                    })
            });

    }

    render() {
        const { classes } = this.props;


        return (
            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                        {!this.state.tableHidden &&
                    <GridContainer >
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                        <div style={{display: 'inline'}}>
                                        <IconButton
                                            style={{display: 'inline'}}
                                            onClick={() =>  this.props.history.goBack()}
                                            size="large"><ArrowBackIcon style={{fill: "white"}}/></IconButton>
                                        <h4 style={{display: 'inline'}} className={classes.cardTitleWhite}>{this.props.location.state.group.name}</h4>
                                    </div>
                                           
                                </CardHeader>
                                <CardBody>
                                    <Accordion expanded={true}>
                                        <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <Typography className={classes.heading}>Sub-Groups</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <SubGroupsPanel groupId={this.props.location.state.group.groupId} groupOptions={this.props.location.state.groupOptions} />
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={true}>
                                        <AccordionSummary
                                             aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>Users</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <UsersPanel groupId={this.props.location.state.group.groupId} userOptions={this.props.location.state.userOptions} />
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={true}>
                                        <AccordionSummary
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        >
                                        <Typography className={classes.heading}>Regions</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <RegionsPanel groupId={this.props.location.state.group.groupId} regionOptions={this.props.location.state.regionOptions } />
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={true}>
                                        <AccordionSummary
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>Sites</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <SitesPanel groupId={this.props.location.state.group.groupId} siteOptions={this.props.location.state.siteOptions } />
                                        </AccordionDetails>
                                    </Accordion>
                                </CardBody>
                            </Card>
                        </GridItem>

                    </GridContainer>
                        }

                    </div>
                }
                <NotificationContainer />

            </div>
        );

    }

}

export default withStyles(style)(GroupDetail);

