import React, { ReactNode } from "react";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from '@mui/material/Card';
import { ReactComponent as AshLogo } from 'assets/ashLogo.svg';
import { Redirect } from "react-router-dom";
import { withStyles, createStyles } from '@mui/styles';

function iframe() {
    return {
        __html: '<iframe src="./info.html" width="100%" height="100%" frameborder="0"></iframe>'
    }
}

//termly.io
//<!--<div dangerouslySetInnerHTML={iframe()} />-->
//https://stackoverflow.com/questions/33973757/how-can-i-render-html-from-another-file-in-a-react-component


const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#AAAAAA",
            backgroundColor: "white",
            margin: "0",
            fontSize: "16px",
            marginTop: "20",
            marginBottom: "0",
            height: "80vh",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Props {
    classes: {
        formControl: string;
        cardCategoryWhite: string;
        cardTitleWhite: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: any;
        search: string;
    };
}

interface State {
    redirect: boolean;
}

export class Welcome extends React.Component<Props, State>  {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            redirect: false,
        }
    }

    //do nothing, close the window
    cancel = (): void => {
        this.setState({
            redirect: true,
        });
    }


    render(): ReactNode {

        const { classes, history } = this.props;

        return (!this.state.redirect ?
            (
                <div style={{
                    backgroundImage: 'linear-gradient(#6b6a77,#262730)',
                    width: '100vw',
                    height: '100%',
                    overflow: 'hidden'
                }}>

                    <div className='some-page-wrapper'>
                        <div className='row'>

                            <div className='column' >
                                <div className='blue-column'>
                                    <Card style={{
                                        width: '80vw',
                                        backgroundColor: 'rgba(52, 52, 52, 0.8)'
                                    }}>
                                        <CardHeader className={'center'}>
                                            <AshLogo />
                                            <h4 className={classes.cardTitleWhite}>CharIot - Getting started</h4>
                                        </CardHeader>
                                        <CardBody className={classes.cardCategoryWhite}>
                                            <div dangerouslySetInnerHTML={iframe()} style={{ height: '100%' }} />
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )
            :
            (
                <Redirect to={'/Login'} />
            )
        )
    }

}

export default withStyles(style)(Welcome);