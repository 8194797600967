export interface APIImageUpload {

    Id: string;
    Caption: string;
    ContentType: string;
    Filename: string;
    FkSiteId: number;
    FkLCLoggerId: number;
    FkLoggerId: number;
    Me: any;

}

export function buildAPIImageUpload(r: any, me: any): APIImageUpload {
    return {
        Id: r.id,
        Caption: r.caption,
        ContentType: r.contentType,
        Filename: r.filename,
        FkSiteId: r.fkSiteId,
        FkLCLoggerId: r.fkLCLoggerId,
        FkLoggerId: r.fkLoggerId,
        Me: me
    }
}

