import React, {useState} from 'react'
import makeStyles from '@mui/styles/makeStyles';
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import { Link } from 'react-router-dom';


const Footer: React.FC = (BGColor) => {

    const [privacy, setPrivacy] = useState<boolean>(false);
    const useStyles = makeStyles(styles);
    
    // styles
    const classes = useStyles();

    const handlePrivacyClick = ():void => {
        setPrivacy(!privacy)
    }
    return (
        
        <div className={classes.appBar} >
            <div style={{display: "flex", justifyContent: "space-around", alignItems: "center", width: "30%", margin: "auto auto 0 auto", padding: "10px 0 10px 0" } }>
                
                <Link to="/privacy">
                    <p style={{ textAlign: "center", color: "white", fontSize: "16px"  }}>Privacy</p>
                </Link>
                
                <Link to="/legal">
                    <p style={{ textAlign: "center", color: "white", fontSize: "16px" }}>Terms and Conditions</p>
                </Link>
                <p style={{ textAlign: "center", fontSize: "16px" }}><a href={window.location.hostname.includes("zonelog.net") ? "mailto:support@zonelog.net" : "mailto:support@ash-eng.com"} style={{ color: "white" } }>Contact</a></p>
            </div>
        </div> 
        
    )
}

export default Footer