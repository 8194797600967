import React from "react";
import { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import ClipLoader from "react-spinners/ClipLoader";
import { Store } from 'react-notifications-component';
//import makeAnimated from 'react-select/animated';
//import { withRouter } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Collapse } from "react-bootstrap";

import { styled } from '@mui/material/styles';
import { compose, spacing, palette, borders } from '@mui/system';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring, HeaderFilter } from 'devextreme-react/data-grid';

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    table: {
        minWidth: "250",
        border: "black",
    },
};

const style = createStyles(styles);
//const animatedComponents = makeAnimated();

const Box = styled('div')(compose(spacing, palette, borders));

interface Contact {
    id: number;
    fullname: string;
    userGuid: string | null;
    mobile: string;
    email: string;
    companyId: number;
    lastUpdate: Date | null;
    created: Date | null;
    contactCategories: Array<ContactCategory>;
}

interface ContactCategory {
    id: number;
    contactId: number;
    notificationCategoryId: number;
    notifyEmail: boolean;
    notifySms: boolean;
    groupId: number;
}

interface NotificationCategories {
    id: number;
    description: string;
    isPriority: boolean;
}

interface Notification {
    id: number;
    sent: Date;
    contactId: number;
    notificationId: number;
    notificationCategoryId: number;
    sentEmail: string;
    sentSms: string;
    smsId: number | null;
    notification: {
        id: number;
        messageSubject: string;
        messageText: string;
    };

}

interface Props {
    classes: {
        cardTitleWhite: string;
        table: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: {
            contact: Contact;
            notificationCategories: Array<NotificationCategories>;
            groupFilterName: string;
        };
    };
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    contact: Contact | null;
    summaryExpanded: boolean;
    notificationsExpanded: boolean;
    historyExpanded: boolean;
}

class ContactDetail extends Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;

    constructor(props: Readonly<Props>) {
        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: [],
            errorHandler: (error: Error): void => {
                console.log(error.message);
            }
        });

        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            contact: null,
            summaryExpanded: false,
            notificationsExpanded: false,
            historyExpanded: false,
        };

        this.gridRef = React.createRef();
        this.store.clear();

    }

    createSuccessNotification = (): void => {
        Store.addNotification({
            title: "Saved Changes",
            message: "Success",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });

    };

    createErrorNotification = (): void => {
        Store.addNotification({
            title: "Error Saving Changes",
            message: "Click me!",
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                click: true
            },
            onRemoval: () => {
                alert('callback');
            }
        });

    };

    componentDidMount(): void {
        //get detail on logger here
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/ContactHistory?contactId=' + me.props.location.state.contact.id),{})
            .then(response => {
                const records: Array<{ type: string; data: Notification; key: number }> = [];
                response.map((data: any) => {
                    const rec = ({type: "insert", data: data, key: data.id});
                    records.push(rec);
                });

                me.store.push(records);

            })
            .catch((error: Error) => {
                me.setState(
                    {
                        authorized: false,
                    })
                console.log(error);
            });

        this.setState({ loading: false, tableHidden: false, summaryExpanded: true });
    }

    renderCategory(cellInfo: any): string {
        const ncid = cellInfo.value;
        const nc = this.props.location.state.notificationCategories.filter(n => n.id == ncid);
        if (nc.length == 1) {
            return nc[0].description
        }
        return "";
    }

    render(): React.ReactNode {
        const { classes, history, location } = this.props;

        return (
            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                    {this.state.tableHidden &&
                            <ApiFailed />
                    }
                    {!this.state.tableHidden &&
                    <GridContainer >
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                    <div style={{display: 'inline'}}>
                                        <IconButton
                                            style={{display: 'inline'}}
                                            onClick={(): void => history.goBack()}
                                            size="large"><ArrowBackIcon style={{fill: "white"}}/></IconButton>
                                        <h4 style={{display: 'inline'}} className={classes.cardTitleWhite}>{location.state.contact.fullname}</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>

                                </CardBody>

                                <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                    <div style={{ display: 'inline' }}>
                                        <h4 style={{ display: 'inline', width: '100%' }} className={classes.cardTitleWhite} onClick={(): void => this.setState({ summaryExpanded: !this.state.summaryExpanded })}>Summary</h4>
                                    </div>
                                    <div>
                                        <IconButton
                                            onClick={(): void => this.setState({ summaryExpanded: !this.state.summaryExpanded })}
                                            size="large">
                                        {!this.state.summaryExpanded && <ExpandMoreIcon color="secondary"/>}
                                        {this.state.summaryExpanded && <ExpandLessIcon color="secondary"/>}
                                        </IconButton>
                                    </div>
                                </CardHeader>
                                <Collapse in={this.state.summaryExpanded} >
                                    <CardBody>
                                        <Box border={1} margin={1} marginTop={-2}  padding={1}>
                                            <p><b>Email:</b> {this.props.location.state.contact.email}</p>
                                            <p><b>Mobile:</b> {this.props.location.state.contact.mobile}</p>
                                            <p><b>Filter by Sites in Group:</b> {this.props.location.state.groupFilterName}</p>
                                        </Box>
                                    </CardBody>
                                </Collapse>

                                <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                    <div style={{ display: 'inline' }}>
                                        <h4 style={{ display: 'inline', width: '100%' }} className={classes.cardTitleWhite} onClick={(): void => this.setState({ notificationsExpanded: !this.state.notificationsExpanded })}>Contact Categories</h4>

                                    </div>
                                    <div>
                                        <IconButton
                                            onClick={(): void => this.setState({ notificationsExpanded: !this.state.notificationsExpanded })}
                                            size="large">
                                            {!this.state.notificationsExpanded && <ExpandMoreIcon color="secondary" />}
                                            {this.state.notificationsExpanded && <ExpandLessIcon color="secondary" />}
                                        </IconButton>
                                    </div>
                                </CardHeader>
                                <Collapse in={this.state.notificationsExpanded} >
                                    <CardBody>
                                        <div>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Category</TableCell>
                                                        <TableCell align="center">By SMS</TableCell>
                                                        <TableCell align="center">By Email</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.props.location.state.notificationCategories.map((cat) => (
                                                        <TableRow key={cat.id}>
                                                            <TableCell component="th" scope="row">
                                                                {cat.description}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={this.props.location.state.contact.contactCategories.filter(c => c.notificationCategoryId == cat.id)[0]?.notifySms}
                                                                    inputProps={{ 'aria-label': 'primary checkbox', value: 'bySms_' + cat.id, readOnly: true }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={this.props.location.state.contact.contactCategories.filter(c => c.notificationCategoryId == cat.id)[0]?.notifyEmail}
                                                                    inputProps={{ 'aria-label': 'primary checkbox', value: 'byEmail_' + cat.id, readOnly: true }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Collapse>

                                <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                    <div style={{ display: 'inline' }}>
                                        <h4 style={{ display: 'inline', width: '100%' }} className={classes.cardTitleWhite} onClick={(): void => this.setState({ historyExpanded: !this.state.historyExpanded })}>Notification History</h4>

                                    </div>
                                    <div>
                                        <IconButton
                                            onClick={(): void => this.setState({ historyExpanded: !this.state.historyExpanded })}
                                            size="large">
                                            {!this.state.historyExpanded && <ExpandMoreIcon color="secondary" />}
                                            {this.state.historyExpanded && <ExpandLessIcon color="secondary" />}
                                        </IconButton>
                                    </div>
                                </CardHeader>
                                <Collapse in={this.state.historyExpanded} >
                                    <CardBody>
                                        <div>
                                            <DataGrid

                                                repaintChangesOnly={true}
                                                dataSource={new DataSource({ store: this.store })}
                                                ref={this.gridRef} >
                                                <StateStoring enabled={true} type="localStorage" storageKey="ContactListGrid" />
                                                <FilterRow visible={true} />
                                                <HeaderFilter visible={true} />

                                                <Column dataField="sent" visible={true} dataType="datetime" width={140}/>
                                                <Column dataField="sentEmail" visible={true} dataType="string" width={60} />
                                                <Column dataField="sentSms" visible={true} dataType="string" width={60}/>
                                                <Column dataField="notificationCategoryId" caption="Category" visible={true} dataType="string" allowFiltering={true} customizeText={this.renderCategory.bind(this)} width={150}/>
                                                <Column dataField="notification.messageSubject" caption="Subject" name="Subject" visible={true} dataType="string" width={150}/>
                                                <Column dataField="notification.messageText" name="Content" visible={true} dataType="string" encodeHtml={false} />

                                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                <Paging defaultPageSize={10} />
                                            </DataGrid>
                                        </div>
                                    </CardBody>
                                </Collapse>

                            </Card>
                        </GridItem>

                    </GridContainer>
                }

                    </div>
            }

            </div>
        );

    }

}

export default withStyles(style)(ContactDetail);

