import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring, HeaderFilter, GroupPanel } from 'devextreme-react/data-grid';

//Moment date/time formatting
//https://momentjs.com/docs/
import Moment from 'moment';

import ClipLoader from "react-spinners/ClipLoader";
//modal window imports
//import InputLabel from '@mui/material/InputLabel';
//import Select from '@mui/material/Select';
//import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Select from '@mui/material/Select'
import { DateBox } from 'devextreme-react/date-box';
// import makeAnimated from 'react-select/animated';
import { CreateUrl, CallGetAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import Tooltip from '@mui/material/Tooltip';


import { APIMQTTResendSummaryModel } from "../../models/APIMQTTResendSummary"
import { APIMQTTRequestModel } from "../../models/APIMQTTRequestModel"
import { APIClearResendsModel } from "../../models/APIClearResendsModel"

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
}


interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    modal: boolean;
    colour: string;
    edit: boolean;
    anchorEl: HTMLElement | null;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        userName: string;
        userId: number;
    } | null;
    modalTitle: string;
    clear: boolean;
    serial: number;
    toDate: Date | null;
    toSeq: number | null;
    cleared: number;
    missing: Array<APIMQTTRequestModel>;
    request: Array<number>;
}

export class MQTTResends extends Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;


    constructor(props: Readonly<Props>) {

        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: []
        });

        this.state = {
            loading: true,
            tableHidden: false,
            visible: false,
            modal: false,
            colour: '',
            edit: false,
            anchorEl: null,
            authorized: true,
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            modalTitle: "",
            clear: false,
            serial: 0,
            toDate: null,
            toSeq: null,
            cleared: -1,
            missing: [],
            request: [],
        };

        this.gridRef = React.createRef();
        this.store.clear();

    }



    createSuccessNotification = (): void => {
        NotificationManager.success('Requested resends', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error loading data', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedErrorNotification = (err: string): void => {
        NotificationManager.error(err, 'Error executing update', 5000);
    };

    componentDidMount(): void {

        this.reloadData(null);
        if (sessionStorage.getItem('portalUpdate') === 'true') {
            this.clearState()
        }
    }

    clearState = (): void => {
        this.gridRef.current?.instance.state(null);
    }

    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {
        const me = this;
        this.setState(
            {
                tableHidden: true,
                loading: true
            })

      
        me.store.clear();
        CallGetAPI(CreateUrl('/api/aquaguard/MQTTResendSummary'), {})
            .then(data => {
                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent
                    const records: Array<{
                        type: "insert" | "update" | "remove";
                        data: any;
                        index: number | undefined
                    }> = [];
                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].id;
                        const rec: APIMQTTResendSummaryModel = data[i];

                        //used for companies grid
                        records.push({ type: 'insert', data: rec, index: i });

                    }

                    me.store.push(records);

                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: true
                    })
            });


    }

   
    handleClose = (): void => {
        this.setState({ anchorEl: null, serial: 0 });
    }

    //do nothing, close the modal

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleClear = (reload: boolean): void => {
        this.setState({
            clear: !this.state.clear,
            cleared: -1,
        });

        if (reload) {
            this.reloadData(null);
        }
    }

    // Filed change handlers

    toDateChanged = (target: any): void => {
        this.setState({ toDate: target.value, toSeq: null });
    };

    toSeqChanged = (target: any): void => {
        if (!isNaN(parseInt(target.currentTarget.value))) {
            this.setState({ toSeq: parseInt(target.currentTarget.value), toDate: null });
        }
    };

    //-------------------------

    handleClick(event: any, id: number): void {
        this.store.byKey(id)
            .then((resend : APIMQTTResendSummaryModel) => {

                this.setState(
                    {
                        anchorEl: event.currentTarget,
                        serial: resend.serial,
                        toDate: null,
                        toSeq: null,
                    });
                },
                (error) => {
                    console.log(error);
                });
    }

    handleRerequest(): void {

        // Get Seqnums missing for logger
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/MQTTRequests?Serial=' + this.state.serial), {})
            .then(data => {
                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent
                    const records: Array<APIMQTTRequestModel> = [];
                    for (let i = 0; i < data.length; i++) {
                        records.push(data[i]);
                    }

                    me.setState(
                        {
                            missing: records,
                            request: [],
                            modalTitle: "Rerequest Messages for " + this.state.serial,
                            anchorEl: null
                        })
                    this.toggleModal();
                }
                else {
                    me.createErrorNotification();
                }
            })
            .catch(function () {
                me.createErrorNotification();
            });

    }

    toggleSelect = (target: any): void => {
        // Copy seqnum from this.state.missing to this.state.request
        const requests = this.state.request;
        const index = requests.indexOf(parseInt(target.currentTarget.value));
        if (index == -1) {
            if (requests.length < 10) {
                requests.push(parseInt(target.currentTarget.value));
            }
        }
        else {
            requests.splice(index, 1); // 2nd parameter means remove one item only
        }
        this.setState({ request: requests });
    }

    doRerequest(): void {
        console.log(this.state.request);

        const me = this;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                Serial: this.state.serial,
                SeqNums: this.state.request,
            })
        };

        CallPostAPI(CreateUrl('/api/aquaguard/MqttRerequest'), requestOptions)
            .then((response: Response) => {
                if (response.status == 200 ) {

                    me.createSuccessNotification();
                    me.reloadData(null);
                }
                else {
                    me.createFailedErrorNotification("Error from request");
                }
            })

            .catch(function (error) {
                me.createFailedErrorNotification("API returned error");
                console.log(error)
            });

        this.toggleModal();

    }

    handleClear(): void {
        this.toggleClear(false);
        this.setState({
            modalTitle: "Clear Resends for " + this.state.serial,
            toDate: new Date(),
            anchorEl: null
        });
    }

    doClear(): void {

        const me = this;

        const body: APIClearResendsModel = new APIClearResendsModel();
        body.serial = this.state.serial;

        if (this.state.toDate != null)
        {
            body.toDate = Moment(this.state.toDate).format('YYYY-MM-DD 23:59:59');
        }
        if (this.state.toSeq != null) {
            body.toSeq = this.state.toSeq;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        };

        CallGetAPI(CreateUrl('/api/aquaguard/ClearResends'), requestOptions)
            .then(data => {
                if (data.status === undefined) {

                    me.setState({
                        cleared: data
                    });
                    me.reloadData(null);
                }
                else {
                    me.createFailedErrorNotification("Error from update");
                }
            })

            .catch(function (error) {
                me.createFailedErrorNotification("API returned error");
                console.log(error)
            });

    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return Moment(cellInfo.value).format("DD/MM/YYYY HH:mm");
    }

    moreRender(Task: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, Task.data.id); }}><MoreHorizIcon /></IconButton>
        );
    }

    renderRequestRows(): React.ReactNode {
        const me = this;
        return this.state.missing.map((rec: APIMQTTRequestModel) => {
                return (<tr key={rec.seqNum}>
                    <td>{rec.seqNum}</td>
                    <td>{rec.lastRequestTime}</td>
                    <td>
                        <Checkbox
                            value={rec.seqNum}
                            onChange={me.toggleSelect}
                            checked={me.state.request.indexOf(rec.seqNum) >= 0}
                        />
                    </td>
                </tr>)
                })
            
     }


    render(): React.ReactNode {
        const { classes } = this.props;


        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                <div>

                    <Modal
                        show={this.state.modal}
                            onHide={(): void => this.toggleModal()}
                        dialogClassName="modal-100w"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                        >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <div>
                                Select up to 10 messages for resend request
                            </div>
                            <div style={{ color: 'red' }}>
                                {this.state.request.length} messages selected
                            </div>
                            <hr/>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <table style={{ border: "1" }}>
                                        <thead>
                                        <tr>
                                            <th>SeqNum</th>
                                            <th>Last Request</th>
                                            <th>Resend</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderRequestRows()}
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" onClick={(): void => this.doRerequest()}>Request</Button>
                            <Button color="secondary" onClick={(): void => this.toggleModal()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.clear}
                        onHide={(): void => this.toggleClear(false)}
                        dialogClassName="modal-100w"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                                    Clear resend records before date OR Seq Number
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        Clear before date (default = Now i.e. ALL):&nbsp;<DateBox id="toDate" displayFormat={"dd/MM/yyyy"} value={this.state.toDate?.valueOf()} onValueChanged={this.toDateChanged.bind(this)} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        Clear before Seq Number:&nbsp;<TextField id="toSeq" value={this.state.toSeq?.valueOf()} onChange={this.toSeqChanged.bind(this)} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} >
                                    {this.state.cleared >= 0 &&
                                        <div style={{color: 'red'}}>
                                        Cleared {this.state.cleared} Resends.
                                    </div>
                                    }
                                </Grid>
                            </Grid>
                        </Modal.Body>
                        <Modal.Footer>
                            {this.state.cleared == -1 ?
                                <Button color="primary" onClick={(): void => this.doClear()}>Clear</Button>
                                :
                                <Button color="primary" onClick={(): void => this.toggleClear(true)}>Close</Button>
                                }
                            <Button color="secondary" onClick={(): void => this.toggleClear(false)}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>


                    {this.state.loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>

                    }
                    {!this.state.loading &&

                        <div>


                            {this.state.tableHidden &&
                                    <ApiFailed />
                            }
                            {!this.state.tableHidden &&
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="primary" >
                                        <div style={{ display: 'inline', float: "right" }}>
                                            <Tooltip title="refresh data">
                                                <IconButton
                                                    edge="start"
                                                    color="inherit"
                                                    onClick={(): void => this.reloadData(null)}
                                                    aria-label="refresh"
                                                >
                                                    <RefreshRoundedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <h4 className={classes.cardTitleWhite}>MQTT Resend Summary</h4>
                                            </CardHeader>
                                            <CardBody>
                                            <DataGrid

                                                allowColumnResizing={true}
                                                repaintChangesOnly={true}
                                                dataSource={new DataSource({ store: this.store })}
                                                ref={this.gridRef} >
                                            <StateStoring enabled={true} type="localStorage" storageKey="MqttResendListGrid" />
                                            <FilterRow visible={true} />
                                            <HeaderFilter visible={true} />
                                            <GroupPanel visible={true} />
                                            <Column dataField="company" visible={true} dataType="string" groupIndex={1} />
                                            <Column dataField="serial" caption="Serial" visible={true} dataType="number" width={100} sortIndex={1}/>
                                                <Column dataField="received24Hour" visible={true} dataType="number" width={80} caption="Rx 24h"/>
                                            <Column dataField="requested24Hour" visible={true} dataType="number" width={80} caption="Rq 24h"/>
                                            <Column dataField="outstanding24Hour" visible={true} dataType="number" width={80} caption="Os 24h"/>
                                            <Column dataField="sitename" visible={true} dataType="string" width={150} />
                                            <Column dataField="siteUserFriendlyName" visible={true} dataType="string" width={150} />
                                            <Column dataField="totalRequested" visible={true} dataType="number" width={80} caption="Rq" />
                                            <Column dataField="totalOutstanding" visible={true} dataType="number" width={80} caption="Os"/>
                                            <Column dataField="lastRequestTime" visible={true} dataType="string" customizeText={this.dateColumnCustomizeText} caption="Last Rq"/>
                                            <Column dataField="lastRequestSeq" visible={true} dataType="number" width={80} caption="Last Rq Seq"/>
                                                <Column dataField="latestSeq" visible={true} dataType="number" width={80} />

                                                <Column fixed={true} fixedPosition='right' type="buttons" cellRender={this.moreRender.bind(this)} width={35} />


                                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                <Paging defaultPageSize={10} />
                                            </DataGrid>
                                            </CardBody>
                                        </Card>
                                    </GridItem>

                                </GridContainer>
                            }

                        </div>
                    }


                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                        disableScrollLock={true}
                    >
                            <MenuItem onClick={this.handleRerequest.bind(this)}>Rerequest</MenuItem>
                            <MenuItem onClick={this.handleClear.bind(this)}>Clear</MenuItem>
                    </Menu>

                    <NotificationContainer />

                </div>
                )

            :
            (
                <Redirect to={'/Login'} />
            );

    }

}

export default withStyles(style)(MQTTResends);

