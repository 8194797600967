import React, { ReactNode } from "react";
import Modal from 'react-bootstrap/Modal';
import { CreateUrl, CallPostAPI } from 'Utils/ApiHelper.js';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import MaterialButton from '@mui/material/Button';

import { withStyles, createStyles } from '@mui/styles';

const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    show: boolean;
    title: string;
    loggerId: Array<number>;
    onSubmit: (result: number, update: boolean) => void;    //result = 0 (OK) 1 (Error) -1 (Exception), update = true to refresh data
    onCancel: () => void;
    isChariot: boolean;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    force: boolean;
    shutdown: boolean;
}

class UndeployDialog extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            force: false,
            shutdown: false,
        };
    }

    handleForceChanged(event: any): void {
        this.setState({ force: event.target.checked });
    }

    handleShutdownChanged(event: any): void {
        this.setState({ shutdown: event.target.checked });
    }

    undeploy(): void {

        const me = this;

        // undeploy logger
        const loggerIds: Array<{ Id: number }> = [];
        this.props.loggerId.map((l) => {
            loggerIds.push({ Id: l });
        });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                loggers: loggerIds,
                shutdown: this.state.shutdown,
                force: this.state.force,
            })
        };

        CallPostAPI(CreateUrl('/api/Aquaguard/UndeployLoggers'), requestOptions)
            .then(async response => {
                if (response.status == 200) {
                    me.props.onSubmit(0, true);
                }
                else {
                    me.props.onSubmit(1, false);
                }
                
            })
            .catch(function (error) {
                me.props.onSubmit(-1, false);
                console.log(error);
            });
    }

    render(): ReactNode {

        return (

            <Modal
                show={this.props.show}
                onHide={(): void => this.props.onCancel()}
                dialogClassName="modal-50w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div>
                            The device(s) will be flagged for undeployment, but remains operational until all data has been collected.
                            To force immediate undeployment (e.g. if logger is unresponsive) tick below.
                            </div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="outlined-checkbox"
                                        value="Force"
                                        checked={this.state.force}
                                        onChange={this.handleForceChanged.bind(this)}
                                    />}
                                label="Undeploy immediately" style={{ color: "black" }}
                            />
                        </Grid>
                        {/*{!this.state.force && !this.props.isChariot &&*/}
                        {/*    <Grid item xs={12} md={12}>*/}
                        {/*    <div>*/}
                        {/*        Once a device is undeployed it can be placed in &quot;Dormant&quot; mode to conserve battery.*/}
                        {/*        In this mode it will no longer record, but still listen/connect on schedule.*/}
                        {/*    </div>*/}
                        {/*    <FormControlLabel*/}
                        {/*            control={*/}
                        {/*                <Checkbox*/}
                        {/*                    id="outlined-checkbox"*/}
                        {/*                    value="Dormant"*/}
                        {/*                    checked={this.state.shutdown}*/}
                        {/*                    onChange={this.handleShutdownChanged.bind(this)}*/}
                        {/*                />}*/}
                        {/*            label="Place in Dormant" style={{color: "black"} }*/}
                        {/*    />*/}
                        {/*    </Grid>*/}
                        {/*}*/}
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <MaterialButton color="primary" variant="contained" style={{marginRight: "10px"}} onClick={(): void => this.undeploy()} >Undeploy Device</MaterialButton>
                    <MaterialButton color="secondary" variant="contained" onClick={(): void => this.props.onCancel()}>Cancel</MaterialButton>
                </Modal.Footer>
            </Modal>

        )
    }

}

export default withStyles(style)(UndeployDialog);