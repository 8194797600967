import React, { ReactNode } from "react";
import Modal from 'react-bootstrap/Modal'
import { CreateUrl, CallGetAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import { Grid, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { DateBox } from 'devextreme-react/date-box';
import TextField from '@mui/material/TextField';
import MaterialButton from '@mui/material/Button';

import { withStyles, createStyles } from '@mui/styles';


const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Site {
    id: number;
    SiteNameUserFriendly: string;
    SiteName: string;
    created: Date;
    deleted: Date | null;
    Latitude: number | null;
    Longitude: number | null;
    defaultSite: boolean;
    fkCompanyId: number;
    fkEASiteId: number | null;
    EASite: string;
    lastUpdate: Date | null;
}

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    show: boolean;
    title: string;
    site: Site | undefined;
    loggerId: number | undefined;
    configurationId: string | undefined;
    onSubmit: (result: number, update: boolean) => void;    //result = 0 (OK) 1 (Error) -1 (Exception), update = true to refresh data
    onCancel: () => void;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    fromDate: Date;
    tempSite: Site | undefined;
    sites: Array<Site>;
    hideNewSite: boolean;
    deployToSite: number;
    sitenameError: boolean;
    sitenameMsg: string;
    siteLatError: boolean;
    siteLatMsg: string;
    siteLongError: boolean;
    siteLongMsg: string;
    saveEnabled: boolean;
}

class DeployDialog extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            tempSite: props.site,
            deployToSite: 0,
            sites: [],
            hideNewSite: true,
            sitenameError: false,
            sitenameMsg: "",
            siteLatError: false,
            siteLatMsg: "",
            siteLongError: false,
            siteLongMsg: "",
            saveEnabled: false,
            fromDate: new Date(),
        };
    }

    componentDidMount(): void {
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/SitesForCompany?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {

                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent
                    const records = [];

                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].siteId;

                        const rec: Site = {
                            id: id,
                            SiteName: data[i].siteName,
                            SiteNameUserFriendly: data[i].siteNameUserFriendly,
                            created: data[i].created,
                            deleted: data[i].deleted,
                            Latitude: data[i].latitude,
                            Longitude: data[i].longitude,
                            fkCompanyId: data[i].fkCompanyId,
                            lastUpdate: data[i].lastUpdate,
                            defaultSite: data[i].defaultSite,
                            EASite: data[i].eaSite,
                            fkEASiteId: data[i].fkEASiteId,
                        };

                        // Cannot select default site
                        if (!rec.defaultSite) {
                            records.push(rec);
                        }
                    }

                    let siteError = false;
                    if (me.props.site?.SiteNameUserFriendly.length == 0) {
                        siteError = true;
                    }
                    let latError = false;
                    if (me.props.site?.Latitude == null || me.props.site?.Latitude == 0) {
                        latError = true;
                    }
                    let lngError = false;
                    if (me.props.site?.Longitude == null || me.props.site?.Longitude == 0) {
                        lngError = true;
                    }

                    me.setState(
                        {
                            sites: records.sort((a, b) => {
                                if (a.SiteNameUserFriendly != null && b.SiteNameUserFriendly != null) {
                                    return a.SiteNameUserFriendly.localeCompare(b.SiteNameUserFriendly);
                                }
                                else {
                                    return a.SiteName.localeCompare(b.SiteName);
                                }
                            }),
                            tempSite: me.props.site,
                            saveEnabled: this.validateSite(!siteError, !latError, !lngError)
                        });

                }
            })
            .catch(function (error) {
                me.setState(
                    {
                        sites: []
                    });
                console.log(error);

            });
    }

    componentDidUpdate(prevProps: Props): void {

        if (prevProps.site != this.props.site) {
            let siteError = false;
            if (this.props.site?.SiteNameUserFriendly.length == 0) {
                siteError = true;
            }
            let latError = false;
            if (this.props.site?.Latitude == null) {
                latError = true;
            }
            let lngError = false;
            if (this.props.site?.Longitude == null) {
                lngError = true;
            }
            this.setState({ tempSite: this.props.site, saveEnabled: this.validateSite(!siteError, !latError, !lngError)});
        }
    }

    //field changed events---
    handleSiteChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        
        if (event.target.value.length == 0) {
            this.setState({ sitenameError: true, sitenameMsg: "Site name must be specified", saveEnabled: false });
        }
        else {
            this.setState({ sitenameError: false, sitenameMsg: "" });
            if (tempSite) {
                
                tempSite.SiteNameUserFriendly = event.target.value;
                this.setState({ tempSite: tempSite, saveEnabled: this.validateSite(true, !this.state.siteLatError, !this.state.siteLongError) });
            }
        }
    }

    handleSiteRefChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        if (tempSite) {
            tempSite.SiteName = event.target.value;
            this.setState({ tempSite: tempSite });
        }
    };

    handleLatitudeChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        const lat = parseFloat(event.target.value);

        if (isNaN(lat)) {
            this.setState({ siteLatError: true, siteLatMsg: "Latitude must be numerical", saveEnabled: false });
        }
        else {
            if (lat > 90 || lat < -90) {
                this.setState({ siteLatError: true, siteLatMsg: "Latitude must be between -90 and 90", saveEnabled: false });
            }
            else {
                this.setState({ siteLatError: false, siteLatMsg: "" });
                if (tempSite) {
                    tempSite.Latitude = lat;
                    this.setState({ tempSite: tempSite, saveEnabled: this.validateSite(!this.state.sitenameError, true, !this.state.siteLongError) });
                }
            }
        }
    };
    handleLongitudeChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        const lng = parseFloat(event.target.value);

        if (isNaN(lng)) {
            this.setState({ siteLongError: true, siteLongMsg: "Longitude must be numerical", saveEnabled: false });
        }
        else {
            if (lng > 180 || lng < -180) {
                this.setState({ siteLongError: true, siteLongMsg: "Longitude must be between -180 and 180", saveEnabled: false });
            }
            else {
                this.setState({ siteLongError: false, siteLongMsg: "" });
                if (tempSite) {
                    tempSite.Longitude = lng;
                    this.setState({ tempSite: tempSite, saveEnabled: this.validateSite(!this.state.sitenameError, !this.state.siteLatError, true) });
                }
            }
        }
    };

    fromDateChanged = (target: any): void => {
        this.setState({ fromDate: target.value });
    };


    // validate tempsite
    validateSite = (name: boolean, lat: boolean, lng: boolean): boolean => {
        return (name && lat && lng);
    };

    // Select existing site
    handleSiteChange = (event: any): void => {
       
        this.setState({
            deployToSite: event.target.value,
            hideNewSite: event.target.value > 0,
            saveEnabled: (event.target.value > 0) || this.validateSite(!this.state.sitenameError, !this.state.siteLatError, !this.state.siteLongError),
        });
    }

    //save changes, for both edit and new
    saveSite = (): void => {

        if (this.state.deployToSite == 0) {
            // New site - need to create
            const me = this;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SiteName: this.state.tempSite?.SiteName,
                    SiteNameUserFriendly: this.state.tempSite?.SiteNameUserFriendly,
                    Latitude: this.state.tempSite?.Latitude || 0,
                    Longitude: this.state.tempSite?.Longitude || 0,
                    createdDate: new Date(),
                    FkCompanyId: parseInt(sessionStorage.getItem('companyId') || '0'),
                    DefaultSite: this.state.tempSite?.defaultSite,
                    FkEASiteId: this.state.tempSite?.fkEASiteId,
                })
            };

            CallGetAPI(CreateUrl('/api/aquaguard/Site'), requestOptions)
                .then(data => {
                    if (me.state.tempSite) {
                        const site = me.state.tempSite;
                        site.id = data.id;
                        site.lastUpdate = data.lastUpdate;
                        me.setState({
                            tempSite: site
                        });

                        this.assignLogger(site, me);
                    }
                })

                .catch(function (error) {
                    me.props.onSubmit(-1, false);
                    console.log(error);
                });


        }
        else {
            //********************
            // Just Assign the logger to existing site
            const site = this.state.sites.filter(s => s.id == this.state.deployToSite);
            if (site.length == 1) {
                this.assignLogger(site[0], this);
            }
            else {
                this.props.onSubmit(1, false);
                console.log("Error on site lookup");
            }

        }

    }

    assignLogger(site: Site, me: this): void {

        if (me.props.loggerId) {
            //save loggers connected to sites
            const loggerIds: Array<{ Id: number }> = [];
            loggerIds.push({ Id: me.props.loggerId });
            
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    siteId: site.id,
                    lastUpdate: site.lastUpdate,
                    loggers: loggerIds,
                    assignLogReadingsFromDate: this.state.fromDate,
                    deploymentGuid: me.props.configurationId,
                })
            };
            
            CallPostAPI(CreateUrl('/api/Aquaguard/AssignLoggersToSite'), requestOptions)
                .then(async response => {
                    if (response.status == 200) {
                        me.props.onSubmit(0, true);
                    }
                    else {
                        me.props.onSubmit(1, false);
                    }
                    
                })
                .catch(function (error) {
                    me.props.onSubmit(-1, false);
                    console.log(error);
                });
        }
        else {
            me.props.onSubmit(1, false);
        }
    }


    render(): ReactNode {

        return (

            <Modal
                show={this.props.show}
                onHide={(): void => this.props.onCancel()}
                dialogClassName="modal-50w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    {this.state.hideNewSite && <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h4>Select existing site:</h4>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Select
                                style={{ width: "100%" }}
                                label="Select Site"
                                value={this.state.deployToSite}
                                onChange={(event) => this.handleSiteChange(event)} >

                                {/*<MenuItem value={0}>Create NEW site</MenuItem>*/}
                                {
                                    this.state.sites.map((site: Site): ReactNode => {
                                        return <MenuItem key={site.id} value={site.id}>{site.SiteNameUserFriendly}({site.SiteName})</MenuItem>;
                                    })
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MaterialButton variant="contained" color="primary" style={{ marginTop: "10px" }} onClick={() => this.setState({ hideNewSite: false })}>Add new site</MaterialButton>
                        </Grid>

                    </Grid>}
                    <Grid item xs={12} md={12}>
                            Enter effective date for device changes (default = Now)
                                            <div style={{ padding: 10 }}>
                                Assign from:&nbsp;<DateBox id="fromDate" displayFormat={"dd/MM/yyyy"} value={this.state.fromDate} onValueChanged={this.fromDateChanged.bind(this)} size="large" />
                            </div>
                    </Grid>
                    <Grid container spacing={2} hidden={this.state.hideNewSite}>
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                        <Grid item xs={12}>
                            <h4>Create New site:</h4>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-input-sitename"
                                defaultValue={this.state.tempSite?.SiteNameUserFriendly}
                                label="Site Name"
                                onChange={this.handleSiteChanged}
                                variant="outlined"
                                error={this.state.sitenameError}
                                helperText={this.state.sitenameMsg}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-input-siteref"
                                defaultValue={this.state.tempSite?.SiteName}
                                label="Site Ref"
                                onChange={this.handleSiteRefChanged}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-input-latitude"
                                defaultValue={this.state.tempSite?.Latitude}
                                label="Latitude"
                                onChange={this.handleLatitudeChanged}
                                variant="outlined"
                                error={this.state.siteLatError}
                                helperText={this.state.siteLatMsg}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-input-longitude"
                                defaultValue={this.state.tempSite?.Longitude}
                                label="Longitude"
                                onChange={this.handleLongitudeChanged}
                                variant="outlined"
                                error={this.state.siteLongError}
                                helperText={this.state.siteLongMsg}
                            />
                        </Grid>

                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <MaterialButton color="primary" variant="contained" style={{marginRight: "10px"}} onClick={(): void => this.saveSite()} disabled={!this.state.saveEnabled}>Save</MaterialButton>
                    <MaterialButton color="secondary" variant="contained" onClick={(): void => this.props.onCancel()}>Cancel</MaterialButton>
                </Modal.Footer>
            </Modal>

        )
    }

}

export default withStyles(style)(DeployDialog);