import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { withStyles, createStyles } from '@mui/styles';
import { GeoDistance } from 'Utils/geodistance.js';
import { Column, DataGrid, Format } from 'devextreme-react/data-grid';
import { Col } from 'react-bootstrap';
import { Chip, Menu, MenuItem } from '@mui/material';

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface EASite {
    id: number;
    eaId: string;
    name: string;
    eARegion: string;
    gridReference: string;
    latitude: number;
    longitude: number;
    eAlabel: string;
    measure: string;
    notation: string;
    lastReading: Date | undefined;
    parameterName: string;
    unitName: string;
    period: number;
    active: boolean;
    distance: number;
}

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    siteId: number;
    siteLat: number | null;
    siteLong: number | null;
    eaSiteId: number | null;
    callback: (sites: Array<EASite>) => void;
    mapSite: (site: EASite | null) => void;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    sites: EASite[];
    clickSite: EASite | null;
    anchorEl: HTMLElement | null;
    eaSiteLoaded: boolean;
}

class EASitePanel extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            sites: [],
            clickSite: null,
            anchorEl: null,
            eaSiteLoaded: false,
        };

    }

    componentDidMount(): void {

        this.reload();
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.eaSiteId != prevProps.eaSiteId) {
            this.reload();
        }
    }

    reload(): void {
        //get detail on EA Site here
        const me = this;

        if (this.props.eaSiteId == null
            && (this.props.siteLat && this.props.siteLat != 0)
            && (this.props.siteLong && this.props.siteLong != 0)) {
            // No EA site selected - get list to choose from

            CallGetAPI(CreateUrl('/api/aquaguard/NearbyEASites?siteId=' + me.props.siteId), {})
                .then(data => {
                    const sites: Array<EASite> = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {

                            const distance = GeoDistance.distance(me.props.siteLat, me.props.siteLong, data[i].lat, data[i].long, 'K');

                            const site: EASite = {
                                id: data[i].stationReference,
                                eaId: data[i].id,
                                name: data[i].gridReference,
                                eARegion: "",
                                gridReference: data[i].gridReference,
                                latitude: data[i].lat,
                                longitude: data[i].long,
                                eAlabel: data[i].label,
                                measure: data[i].measures[0].id,
                                notation: data[i].stationReference,
                                lastReading: undefined,
                                parameterName: data[i].measures[0].parameterName,
                                unitName: data[i].measures[0].unitName,
                                period: data[i].measures[0].period,
                                active: true,
                                distance: distance,

                            };
                            sites.push(site);
                        }
                    }

                    me.setState({
                        sites: sites
                    });

                    if (me.props.callback) {
                        me.props.callback(sites);
                    }

                })
                .catch(function () {
                    me.setState(
                        {
                            authorized: false,
                        });
                });

            this.setState({ loading: false, tableHidden: false });
        }
        else {
            // EA site associated - get details
            CallGetAPI(CreateUrl('/api/aquaguard/EASite?Id=' + this.props.eaSiteId), {})
                .then(data => {
                    if (data) {

                        const distance = GeoDistance.distance(me.props.siteLat, me.props.siteLong, data.latitude, data.longitude, 'K');

                        const site: EASite = {
                            id: data.id,
                            eaId: data.eAid,
                            name: data.notation,
                            eARegion: data.eaRegion,
                            gridReference: data.gridReference,
                            latitude: data.latitude,
                            longitude: data.longitude,
                            eAlabel: data.eAlabel,
                            measure: data.measure,
                            notation: data.notation,
                            lastReading: data.lastReading,
                            parameterName: data.parameterName,
                            unitName: data.unitName,
                            period: data.period,
                            active: data.active,
                            distance: distance,
                        };

                        me.setState({
                            sites: [site],
                            eaSiteLoaded: true,
                        });

                        if (me.props.callback) {
                            me.props.callback([site]);
                        }

                    }


                })
                .catch(function () {
                    me.setState(
                        {
                            authorized: false,
                        });
                });

            this.setState({ loading: false, tableHidden: false });
        }
    }

    moreRender(key: any): React.ReactNode {
//        return (
//            <IconButton size="small" onClick={(e: React.MouseEvent<HTMLElement>): void => { this.handleClick(e, key.key); }}><MoreHorizIcon /></IconButton>
//        );
        let icon;
        return (
            <Chip style={{ margin: '2px' }}
                icon={icon}
                color="primary"
                label="Select"
                onClick={(): void => this.selectSite(key.key)}
            />

            );
    }

    handleClick(event: React.MouseEvent<HTMLElement>, id: number): void {
        this.setState(
            {
                clickSite: this.state.sites.filter(s => s.id == id)[0],
                anchorEl: event.currentTarget
            });
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    selectSite = (key: EASite | null): void => {
        if (this.props.mapSite) {
            this.props.mapSite(key);
        }
        if (key == null) {
            this.setState({ eaSiteLoaded: false });
        }
    }

    render(): ReactNode {

        return (
            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                    {!this.state.tableHidden && !this.state.eaSiteLoaded &&
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                            <div>
                                <DataGrid
                                    dataSource={this.state.sites}
                                    key="eaId" >


                                    <Column dataField="eaId" visible={false} dataType="string" />
                                    <Column dataField="notation" caption="EA ref" visible={true} dataType="string" />
                                    <Column dataField="gridReference" visible={true} dataType="string" />
                                    <Column dataField="distance" caption="Dist (km)" visible={true} dataType="number" sortIndex={1} sortOrder="asc">
                                        <Format type="fixedPoint" precision={2} />
                                    </Column>

                                    <Column fixed={true} fixedPosition='right' type="buttons" cellRender={this.moreRender.bind(this)} />
                                </DataGrid>
                            </div>

                            </GridItem>
                        </GridContainer>

                    }
                    {!this.state.tableHidden && this.state.eaSiteLoaded &&
                        <GridContainer>
                            <GridItem xs={10} sm={10} md={10}>
                                <div>
                                EA Site: {this.state.sites[0].gridReference}<br />
                                Station: {this.state.sites[0].notation}<br />
                                Last Reading: {this.state.sites[0].lastReading}<br />
                                </div>

                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <IconButton
                                aria-label="delete"
                                onClick={(): void => this.selectSite(null)}
                                size="large">
                                    <DeleteForeverIcon />
                            </IconButton>
                            </GridItem>
                        </GridContainer>

                    }
                    </div>
                }
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={this.selectSite.bind(this)}>Select</MenuItem>
                </Menu>
            </div>
        );
    }

}

export default withStyles(style)(EASitePanel);