import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { withStyles, createStyles } from '@mui/styles';
const style = createStyles(styles);

export class SiteDeviceChips extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { classes } = this.props;

        return (
            <Paper component="ul"  style={{
                display: 'flex',
                //justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                padding: 0.5,
                margin: "0 0 0 5px",
                boxShadow: "none",
                background: "none"}}>
                {this.props.data?.map((data) => {
                    let icon;
                    return (
                        <li key={data.key}>
                            
                            <Chip style={{ margin: '2px'}}
                                icon={icon}
                                color="primary"
                                label={data.label}
                                onClick={() => this.props.deviceDetailsClick(data.label, data.value)}
                            />
                        </li>
                    );
                })}
            </Paper>
        );
    
    }
}
export default withStyles(style)(SiteDeviceChips);