export interface APIGetLoggerReadingsModel {
    id: number;

    loggerSerial: string;

    site: string;

    dateStamp: Date | null;

    type: string;

    value: number | null;
    value2: number | null;

    units: string; 

    minValue: number | null;

    maxValue: number | null;

    channelRef: string;

    channelSerial: string;

    channelletter: string;

    interval: number | null;
    dp: number | null;
    forward: number | null;
    reverse: number | null;
    total: number | null;
    multiplier: number | null;
    difference: number | null;
    flowRate: number | null;
}

export function buildAPIGetLoggerReadingsModel(r: any): Array<APIGetLoggerReadingsModel> {

    const result = new Array<APIGetLoggerReadingsModel>();

    r?.forEach(function (rec: any) {

        // Convert the dateStamp from UTC to local browser time
        //if (rec.dateStamp) {
        //    rec.dateStamp = new Date(rec.dateStamp + 'Z'); // Assuming 'Z' indicates UTC
        //}

        // Use RegEx /[^ -~]/g to strip non prinatble chars e.g. /00
        rec.site = rec.site?.replace(/[^ -~]/g, '');
        rec.units = rec.units?.replace(/[^ -~]/g, '');
        rec.channelRef = rec.channelRef?.replace(/[^ -~]/g, '');
        if (rec.dp && rec.dp != 0) {
            const scale = 10 ** rec.dp;
            if (rec.value)
                rec.value = rec.value / scale;
            if (rec.value2)
                rec.value2 = rec.value2 / scale;
            if (rec.maxValue)   
                rec.maxValue = rec.maxValue / scale;
            if (rec.minValue)
                rec.minValue = rec.minValue / scale;
        }
        
        result.push(rec);
    });

    return result;
}




