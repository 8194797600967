import React from "react";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { ReactComponent as AshLogo } from '../../assets/ashLogo.svg';
import CardFooter from '../../components/Card/CardFooter.js';
import BG from '../../assets/BG-Aquaguard.jpg';
import Footer from 'components/Navbars/footer'
import { Link } from 'react-router-dom'

const TermsAndConditions: React.FC = () => {
    return (
        <>
            <div style={{
                backgroundImage: `url(${BG})`,
                width: '100vw',
                height: '100vh',



            }}>

                <div className='some-page-wrapper'>
                    <div>

                        <div className='column' >
                            <div>
                                <Card style={{
                                    margin: "3vw 0 0 0",
                                    background: 'linear-gradient(130deg, rgba(0, 0, 0, 0.7) 0%, rgb(20,60,103, 0.6) 70%',
                                    width: "90vw",
                                    height: "80vh",
                                    boxShadow: "2px 2px 10px black",
                                    padding: "5vh 0 0 0",
                                    overflow: "auto",
                                    msOverflowStyle: "none",
                                    scrollbarWidth: "none"
                                }}>
                                    <div>
                                        <CardHeader>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <AshLogo style={{ width: "50%", marginTop: "-50px", textAlign: "center" }} />
                                                <h3 style={{ color: "white", marginTop: "-30px", fontSize: "28px"  }}>AquaGuard</h3>
                                                <h1 style={{ marginTop: "10px", color: "white", fontSize: "33px" }}>Terms & Conditions</h1>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div style={{ color: "white", margin: "0 20px", fontSize: "14px"  }}>
                                                <h4 style={{ fontSize: "22px" }}>API License Agreement</h4>
                                                <p>This API License Agreement (this “Agreement”) governs the implementation and use of the APIs of Ashridge Engineering Limited (“Ashridge”) to allow an Application (as defined below) to interoperate with the Ashridge platform known as Aquaguard (“Services”).

                                                    If Licensee is entering into this Agreement on behalf of an organisation, Licensee represents that it has the authority to bind Licensee’s organization to this Agreement. If Licensee does not have that authority, or if Licensee does not agree with the terms of this Agreement, Licensee may not implement the Ashridge APIs or use the API Specification. By clicking a button denoting acceptance of this Agreement, or by implementing, downloading or otherwise accessing the Ashridge APIs, Licensee agrees to be legally bound by the terms of this Agreement.

                                                    This Agreement is effective between Licensee and Ashridge as of the date of Licensee’s signature of the Service Agreement between Licensee and Ashridge governing Licensee’s use of the Services (“Service Agreement”), this Agreement shall govern Licensee’s use of the Ashridge APIs. This Agreement may change from time to time. When Ashridge makes changes to this Agreement, Ashridge will update the “Last updated” date at the beginning of this Agreement. All changes will be effective from the date of publication unless otherwise stated. By clicking a button denoting acceptance of this Agreement, or by implementing, downloading or otherwise accessing the Ashridge APIs, Licensee agrees to be legally bound by the amended terms of this Agreement.  
                                                </p>
                                                <h4 style={{ fontSize: "22px" }}>1. Definitions</h4>
                                                <p> “API” means an application programming interface that enables Licensee to access data that currently is available via the Services as expressly set out in the Service Agreement. API includes all Modifications as defined in Section 6, to the extent the same are made available to Licensee under this Agreement.</p>

                                                <p> “Applications” means one or more software programs that can be used to call the API, solely for internal use by or for the benefit of Licensee.</p>

                                                <p> “General API Policies” means any of Ashridge’s policies regarding use of or access to the API, which may be updated from time to time by written notice to Licensee; provided, however that such updates shall not serve to materially diminish the rights to Licensee granted herein.</p>

                                                <p> “Intellectual Property Rights” means patents, inventions, copyrights, trademarks, domain names, trade secrets, know-how and any other intellectual property and/or proprietary rights.</p>

                                                <p> “Limitations” means any technical limitations or restrictions regarding access or use of the API.</p>

                                                <p> “Licensee,” “you,” or “your” means and refers to an individual or an entity that has agreed to a Service Agreement for use of the Services.</p>

                                                <p> “Service Data” means any data that is transmitted to Licensee or the Applications though the API, including any copies or other tangible embodiments of the same.</p>

                                                <p> “Term” means that period commencing on the date specified in the Service Agreement and continuing for as long as there is an active Service Agreement for Licensee’s use of the Services, unless one of the parties to this Agreement terminates the Service Agreement or this Agreement for breach in accordance with the terms of this Agreement.</p>

                                                <p> “Unaffiliated Third Party” means a third party who is not a Licensee Party. </p>
                                                
                                                <h4 style={{ fontSize: "22px" }}>2. Scope of License</h4>
                                                <p>2.1. API License Grant. Subject to this Agreement, including the restrictions set forth below, Ashridge grants to Licensee a non-exclusive, non-transferable, non-sublicensable, worldwide, revocable right and license:</p>


                                                <p> (a) use and make calls to the API to develop, implement and distribute Applications solely for Internal Use by Licensee in connection with the Services; and</p>

                                                <p>    (b) use, reproduce, store, distribute, and transmit Service Data to the extent necessary to format and display it through the Applications.</p>

                                                <p>    2.2. Service Data License Grant. Subject to this Agreement, including the restrictions set forth below, Ashridge grants to Licensee a non-exclusive, non-transferable, non-sublicensable, worldwide, license during the Term to:</p>

                                                <p>    a) use, reproduce, store, distribute, and transmit Service Data, in each case internally, to the extent necessary to format and display the Service Data internally through the Applications;</p>

                                                <p>    b) use, reproduce, store, distribute, and transmit Service Data, in each case internally, for Licensee’s own internal business use and not for the purpose of providing competitive and/or similar products or services to Ashridge products and services; and</p>

                                                <p>    c) make derivative works of the Service Data (“Derivatives”) solely for Licensee’s own internal business use. </p>
                                                
                                                <h4 style={{ fontSize: "22px" }}>3. Restrictions and Responsibilities</h4>
                                                <p>3.1. Licensee agrees to use the API, Applications and the Service Data solely for its own internal business purposes and in accordance with the terms set forth in this Agreement. Any breach of this Agreement will be deemed to be a breach of this Agreement by Licensee.</p>

                                                <p>3.2. Licensee shall not license, sublicense, sell, assign, resell, rent, lease, transfer, distribute or otherwise commercially exploit or make the API, Applications or Service Data available to any third party and will use all commercially reasonable efforts to prevent unauthorised access to, or use of, the API, Applications or Service Data. Licensee shall not treat the Service Data with any less care than its own highly sensitive information. Licensee shall notify Ashridge promptly as soon as it becomes aware of any such unauthorised use and Licensee will follow all commercial reasonable instructions from Licensee to prevent such unauthorized use from reoccurring.</p>

                                                <p>3.3. Licensee will comply with all applicable laws in using the API, Applications and the Service Data.</p>

                                                <p>3.4. Access and use of the Service Data is restricted to Licensee. Access to the data by Licensee shall mean the organisation set out in the Service Agreement only.</p>

                                                <p>3.5. In order to use and access the API, Ashridge will assign Licensee with API credentials (for instance, an “API Key” or Company GUID - Globally Unique Company Identifier, together with a Client ID and Client Secret or Password) (the &quot;Credentials&quot;). Licensee may not share any of these details with any third party.</p>

                                                <p>3.7.  Licensee shall keep Credentials and all login information secure and shall use the Credentials as Licensee’s sole means of accessing the API.</p>

                                                <p>3.8. In the event that Licensee suspects that any third party may be making use of the Credentials, Licensee shall immediately inform Ashridge of this fact and provide all reasonable assistance to Ashridge in relation to any subsequent investigation or other activities undertaken by Ashridge as a result. In addition, Licensee must immediately redact and replace the API Key or GUID (as applicable) in case this is compromised.</p>

                                                <p>3.9. Licensee shall not distribute any materials or insights derived from Service Data to any third party; provided, however, this limitation shall not apply to Licensee’s trading strategies, promotions, campaigns, business or strategic plans or any other derivative works resulting from Licensee’s use of the Service Data that do not publish or disclose any Service Data.</p>

                                                <p>3.10. Applications may not use or access the API in order to monitor the availability, performance, or functionality of the API or for any similar benchmarking purposes.</p>

                                                <p>3.11. If Ashridge believes, in its sole discretion, that Licensee has breached or attempted to breach any term of this Agreement, the license afforded to Licensee pursuant to this Agreement may be temporarily or permanently suspended or revoked, with or without notice to Licensee.</p>

                                                <p>3.12. Licensee shall not substantially replicate products or services offered by Ashridge, including, without limitation, functions where Ashridge offers its own similar function. Subject to the preceding sentence and the parties’ other rights and obligations under this Agreement, each party agrees that the other party will not develop applications that are similar to or otherwise compete with such party’s applications.</p >

                                                <p>3.13.Licensee shall not, under any circumstances, through Applications or otherwise, repackage or resell the API or Service Data.Licensee is not permitted to use the API or data in any manner that does or could potentially undermine the security of the Services, the API or any other data or information stored or transmitted using the Services.In addition, Licensee shall not, and shall not attempt to: (a) interfere with, modify or disable any features, functionality or security controls of the Services or the API, (b) defeat, avoid, bypass, remove, deactivate or otherwise circumvent any protection mechanisms for the Service or the API, (c) reverse engineer, decompile, disassemble or derive source code, underlying ideas, algorithms, structure or organizational form from the Services or the API, or(d) train a machine learning model, or any similar function, against the Ashridge dataset, (e) seek to derive training data for the purposes of building any automated recognition, machine learning, statistical or other inference models.</p >

                                                <p>3.14. Licensee will respect and comply with the technical and policy-implemented limitations of the API. Without limiting the foregoing, Licensee shall not attempt to circumvent any explicit rate limitations on calling or otherwise utilising the API.</p>
                                                
                                                <h4 style={{ fontSize: "22px" }}>4. Ownership of Intellectual Property</h4>
                                                <p>4.1. Ashridge owns all right, title, and interest, including all Intellectual Property Rights, in and to the API, the Service Data, the Augmented Data and Ashridge’s other technology. Except as expressly set forth in this Agreement, neither party grants any rights or licensee under its Intellectual Property Rights pursuant to this Agreement.</p>

                                                <p>4.2. No licence to either Party of any trademark, patent, copyright or any other intellectual property right is either granted or implied by this Agreement. </p>
                                                
                                                <h4 style={{ fontSize: "22px" }}>5. Term and Termination </h4>
                                                <p>5.1. The Agreement shall run coterminous with the Service Agreement. Any termination of this Agreement shall result in all licenses and other granted rights in this Agreement terminating immediately.</p>

                                                <p>5.2   Upon termination for any reason, Licensee shall cease using the API. Licensee shall return to Ashridge, or destroy and remove from all computers, hard drives, networks, and other storage media, all copies of any materials licensed pursuant to this Agreement, including but not limited to, previously ingested data, sample data, data dictionaries or API references, and any Confidential Information in Licensee’s possession, and shall certify to Ashridge that such actions have occurred.</p>

                                                <p>5.3. Upon termination or expiration of this Agreement, all rights and obligations hereunder will immediately terminate; except that all liabilities incurred prior to termination or expiration will survive and continue in full force and effect after this Agreement expires or is terminated.</p>

                                                <p>5.4. In addition to any other remedies it may have, Ashridge may also terminate this Agreement upon thirty (30) days’ notice (or without notice in the case of nonpayment), if the other party materially breaches any of the terms or conditions of this Agreement.</p>

                                                <p>5.5. Ashridge may temporarily or permanently suspended or revoke Licensee’s access to and use of the API, and will immediately provide Licensee with notice of the same, if: (a) Ashridge suspends or shuts off access to and use of the API to all its other customers with similar API access due to verifiable, unauthorized distribution of the Service Data or a verifiable breach of Ashridge’s Intellectual Property Rights with respect to the API or the Service Data (provided, however, that Ashridge will use its commercial best efforts to promptly investigate to identify the source of such unauthorized distribution, and Ashridge will reinstate access under this Agreement as soon as possible but within three (3) day of any such</p>
                                                    suspension unless such unauthorized distribution falls within the scope of (b) below); or

                                                <p>(b) Ashridge in good faith suspects that Licensee has (i) materially breached any of Ashridge’s Intellectual Property Rights with respect to the API or the Service Data or (ii) materially breached Section 1 (Scope of License) or Section 2 (Restrictions and Responsibilities) of this Agreement or (iii) a data breach has occurred as a result of unauthorized third party access to the API, Applications or Service Data.</p>

                                                <p>5.6. At the time of any suspension or revocation due to clause 5.5(b), Ashridge shall provide evidence of any such breach giving rise to Ashridge’s suspicion, and if the parties in good faith agree the confirmed breach under clause 5.5(b) was not caused by Licensee’s intentional misconduct or gross negligence, the parties agree to cooperate in good faith to determine whether the cause of such breach can be resolved and/or access to and use of the API restored.</p>


                                                <p>5.7.  Ashridge may temporarily or permanently suspend or revoke Licensee’s access to and use of the API if Licensee has materially breached any other term or condition of this Agreement and such breach remains uncured for more than thirty (30) days. </p>
                                                <h4 style={{ fontSize: "22px" }}>6. Warranty and Disclaimer </h4>
                                                
                                                <p>6.1. Notwithstanding anything to the contrary in this Agreement, all aspects of the services and the API, including all server and network components are provided on an “as is” and “as available” basis, without any warranties of any kind to the fullest extent permitted by law, and Ashridge expressly disclaims any and all warranties, whether express or implied, including, but not limited to, any implied warranties of accuracy, completeness, merchantability, title, fitness for a particular purpose, and non-infringement.</p>

                                                <p>6.2. Licensee acknowledges that Ashridge does not warrant that the service, service data or API will be uninterrupted, timely, secure, error-free or free from viruses or other malicious software, and no information or advice obtained from Ashridge or through the service shall create any warranty not expressly stated in this Agreement.</p>

                                                <p>6.3. To the maximum extent permitted by applicable law, in no event shall Ashridge be responsible or liable, with respect to any subject matter of this Agreement, under any contract, negligence, strict liability or other theory, for: (i) any indirect, incidental, consequential, special exemplary, punitive, or other pecuniary damages, including, but not limited to loss of revenues or profits, loss, corruption or inaccuracy of data, loss of use, loss of goodwill, loss of business, or cost of procurement of substitute goods, services, or technology; (ii) any matter beyond its reasonable control; or (iii) any direct damages in the aggregate over £100.</p>

                                                <p>6.4    Unless prohibited by applicable law, you will indemnify Ashridge against all liabilities, damages, losses, costs, fees (including legal fees), and expenses relating to any allegation or third-party legal proceeding to the extent arising from:</p>

                                                <p>(a) your misuse or your end user&apos;s misuse of the APIs;</p>

                                                <p>(b)  your breach or your end user&apos;s breach of this Agreement; or</p>

                                                <p>(c)  any content or data routed into or used with the APIs by you, those acting on your behalf, or your end users. </p>
                                                <h4 style={{ fontSize: "22px" }}>7. Modifications </h4>
                                                <p>7.1. Licensee acknowledges and agrees that Ashridge may modify the API and the General API Policies from time to time (a “Modification”); provided that a Modification shall not materially diminish the features or functionality of the API. Licensee will be notified of any Modification in writing with no less than fourteen (14) days advance notice.</p>

                                                <p>7.2. Licensee shall use and make calls to the most current version of the API. Licensee acknowledges that a Modification may have an adverse effect on their ability to communicate with the API and display or transmit Service Data. Except as expressly set forth in this Agreement, Ashridge shall have no liability of any kind to Licensee with respect to such Modifications or any adverse effects resulting from such Modifications. </p>
                                                
                                                <h4 style={{ fontSize: "22px" }}>8. Confidentiality </h4>
                                                <p>8.1.  Licensee may from time to time, gain access to Confidential Information. Licensee may use Confidential Information only to the extent necessary to exercise its rights under this Agreement. Subject to the express permissions set forth herein, Licensee may not disclose Confidential Information to a third party without the prior express consent of Ashridge provided in writing or by email. Without limiting any other obligation of Licensee under this Agreement, Licensee agrees that it will protect Confidential Information from unauthorized use, access, or disclosure in the same manner that Licensee would use to protect its own confidential and proprietary information of a similar nature and in any event with no less than a reasonable degree of care.</p>

                                                <p>8.2.  Notwithstanding anything to the contrary, Ashridge shall have the right collect and analyse information relating to the provision, use and performance of various aspects of the API, Services and related systems and technologies (including, without limitation, information concerning Licensee API calls), and Ashridge will be free (during and after the term hereof) to (i) use such information and data to improve and enhance the API and Service and for other development, diagnostic and corrective purposes in connection with the API, Services and other Ashridge offerings, and (ii) disclose such data solely in aggregate or other de-identified form in connection with its business (provided that no personal information is collected or analysed and Licensee is not identified as the source of such data). No rights or licenses are granted except as expressly set forth herein.</p>

                                                <p>As used in this Agreement, “ Confidential Information ” means all information whether of a technical, business, financial or other nature (including, without limitation, trade secrets, know-how and information relating to the technology, customers, business plan, copyrights, trademarks, patents, promotional and marketing activities, finances and other business affairs) that is or may be disclosed or imparted by one party to this Agreement to the other.</p> 
                                                
                                                <h4 style={{ fontSize: "22px" }}>9. Miscellaneous </h4>
                                                
                                                <p>9.1. This Agreement, together with the Service Agreement related to Licensee’s subscription to the Services by and between Licensee and Ashridge, constitute the entire agreement among the parties with respect to the subject matter of this Agreement. Either party’s failure to enforce at any time any provision of this Agreement does not constitute a waiver of that provision or of any other provision of this Agreement.</p>

                                                <p>9.2. If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable. This Agreement is not assignable, transferable or sublicensable by Licensee except with Ashridge’s prior written consent. Ashridge may transfer and assign any of its rights and obligations under this Agreement without consent.</p>

                                                <p>9.3. In any action or proceeding to enforce rights under this Agreement, the prevailing party will be entitled to recover costs and attorneys’ fees. This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims), shall be governed by, and construed in accordance with, the law of England and Wales. All notices under this Agreement will be in writing and will be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; the day after it is sent, if sent for next day delivery by recognized overnight delivery service; and upon receipt, if sent by certified or registered mail, return receipt requested. </p>
                                                
                                                <h4>10. Technical Support</h4>
                                                
                                                <p>10.1. Technical Support is provided with the following conditions:</p>

                                                <p>10.2. Company shall use reasonable efforts to solve problems identified by Licensee. However, Company does not warrant that it will solve any particular problem in a given timescale, or at all.</p>

                                                <p>10.3. Company will typically provide support for the Product whether the Product has been discontinued or not. However, Company reserves the right to cancel support at any time and for any reason. </p>
                                                <h5 style={{ fontSize: "18px" }}>Last updated: May 2024</h5>
                                            </div>
                                            
                                        </CardBody>
                                        <CardFooter>
                                            <div style={{ margin: "20px auto" }}>
                                                <Link to={sessionStorage.getItem('userId') !== "" ? "/Portal/Dashboard" : "/"}>
                                                    <Button variant="contained">Back to homepage</Button>
                                                </Link>
                                            </div>
                                        </CardFooter>
                                    </div>
                                </Card>

                            </div>
                        </div>

                    </div>
                    <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
                        <Footer />
                    </div>
                </div>

            </div>
        </>
    )
}
export default TermsAndConditions