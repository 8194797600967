import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import ClipLoader from "react-spinners/ClipLoader";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import Dialog from '@mui/material/Dialog';
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, InputLabel, Typography, Select, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';

import { APIGetSiteAlarmConfigModel, buildAPIGetSiteAlarmConfigModel, buildEmptySiteAlarmConfigModel } from 'models/APIGetSiteAlarmConfigModel';
import { enumChariotAlarmInput } from 'models/enumChariotAlarmInput';
import { enumChariotAlarmOperator } from 'models/enumChariotAlarmOperator';



const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface SiteChannel {
    id: number;
    loggerSerial: number;
    channel: string;
    channelRef: string;
    units: string;
    fkSiteId: number;
}

interface Props {
    classes: {
        root: string;
        heading: string;
        cardTitleWhite: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: {
            siteId: number;
            siteName: string;
        };
        search: string;
    };

}

interface State {
    loading: boolean;
    authorized: boolean;
    tableHidden: boolean;
    anchorEl: HTMLElement | null;
    tempConfig: APIGetSiteAlarmConfigModel | undefined;
    modal: boolean;
    modalTitle: string;
    editAlarm: boolean;
    // Field error flags
    fieldErrors: {
        setThreshold: boolean;
        clrThreshold: boolean;
        setCount: boolean;
        clrCount: boolean;
        maxAlarms: boolean;
        setDiffChannel: boolean;
        clrDiffChannel: boolean;
    };
    channelsAvail: number;
    channels: Array<SiteChannel>;
    transmissionFrequency: number;
}

export class SiteAlarmConfigs extends React.Component<Props, State> {

    store = new ArrayStore({
        key: 'id',
        data: [],
    });
    gridRef: React.RefObject<DataGrid>;
    dataSource = new DataSource({
        store: this.store,
        reshapeOnPush: true
    });

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            authorized: true,
            tableHidden: true,
            loading: true,
            anchorEl: null,
            tempConfig: undefined,
            modal: false,
            modalTitle: "",
            editAlarm: false,
            fieldErrors: {
                setThreshold: false,
                clrThreshold: false,
                setCount: false,
                clrCount: false,
                maxAlarms: false,
                setDiffChannel: false,
                clrDiffChannel: false,
            },
            channelsAvail: 1,
            channels: [],
            transmissionFrequency: null
        };

        this.gridRef = React.createRef();
        this.store.clear();

    }

    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createFailedInsertErrorNotification = (): void => {
        NotificationManager.error('Error creating new record', '', 5000);
    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    componentDidMount(): void {
        const me = this;

        this.reloadAlarmData();

        CallGetAPI(CreateUrl('/api/aquaguard/SiteChannels?siteId=' + me.props.location.state.siteId), {})
            .then(async (data) => {

                const records: Array<SiteChannel> = [];
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        const rec: SiteChannel = {
                            id: data[i].id,
                            loggerSerial: data[i].loggerSerial,
                            channel: data[i].channel,
                            channelRef: data[i].channelRef,
                            units: data[i].units ? data[i].units : "",
                            fkSiteId: data[i].fkSiteId,
                        };

                        records.push(rec);
                    }
                }

                const loggers = Array.from(new Set(records.map((item: SiteChannel) => item.loggerSerial)));

                const transmissionFrequencyPromises = loggers.map(loggerSerial =>
                    CallGetAPI(CreateUrl(`/api/aquaguard/LoggerBySerial?serial=${loggerSerial}`), {})
                        .then(data => {
                            if (data.transmissionFrequency) {
                                return data.transmissionFrequency;
                            }
                            return null;
                        })
                        .catch(error => {
                            console.error(`Error fetching logger data for serial ${loggerSerial}:`, error);
                            return null;
                        })
                );

                let transmissionFrequencies = (await Promise.all(transmissionFrequencyPromises))
                    .filter(frequency => frequency);

                if (transmissionFrequencies.length > 1) {
                    alert('Loggers at this site have different dial in rates, the alarm will be set for the most frequent dial in')
                    transmissionFrequencies = Math.max(...transmissionFrequencies)
                } else {
                    transmissionFrequencies = transmissionFrequencies[0]
                }

                me.setState({
                    transmissionFrequency: transmissionFrequencies,
                    channels: records,
                    loading: false,
                    tableHidden: false,
                });
            })
            .catch((error) => {
                console.error('Error fetching site channels:', error);
                me.setState({
                    channels: [],
                    authorized: false,
                    loading: false,
                });
            });
    }



    reloadAlarmData = (): void => {
        const me = this;
        this.setState(
            {
                tableHidden: true,
                loading: true,
            }
        );

        CallGetAPI(CreateUrl('/api/aquaguard/SiteAlarmConfigurations?siteId=' + me.props.location.state.siteId), {})
            .then(data => {
                const updates: Array<{
                    type: "insert" | "update" | "remove";
                    data: any;
                    index: number | undefined
                }> = [];

                const records: Array<APIGetSiteAlarmConfigModel> = [];
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        const rec: APIGetSiteAlarmConfigModel = buildAPIGetSiteAlarmConfigModel(data[i]);
                        records.push(rec);
                    }
                }
                
                // Fill in any missing slots
                for (let i = 1; i <= 16; i++) {
                    if (records.filter(r => r.idx == i).length == 0) {
                        const rec1 = buildEmptySiteAlarmConfigModel(i);
                        updates.push({ type: "insert", data: rec1, index: i });
                    }
                    else {
                        const rec2 = records.filter(r => r.idx == i)[0];
                        updates.push({ type: "insert", data: rec2, index: i });
                    }
                }

                me.store.clear();
                me.store.push(updates);
                me.setState(
                    {
                        loading: false,
                        tableHidden: false,
                    })

            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false,
                        loading: false,
                    })
            });
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    //new row
    newAlarm = (): void => {
        this.setState({
            modal: !this.state.modal,
            editAlarm: false,
            tempConfig: {
                id: 0,
                idx: 0,
                alarmMessage: "",
                alarmMask: 0,
                alarmClass: 0,
                setInput: enumChariotAlarmInput.Disabled,
                clrInput: enumChariotAlarmInput.Disabled,
                setOperator: enumChariotAlarmOperator.Off,
                clrOperator: enumChariotAlarmOperator.Off,
                setThreshold: 0,
                clrThreshold: 0,
                setCount: 1,
                clrCount: 1,
                maxAlarms: 0,
                setUnits: 0,
                clrUnits: 0,
                fkSiteId: this.props.location.state.siteId,
                setThresholdStr: "0",
                clrThresholdStr: "0",
                setCountStr: "24hour",
                clrCountStr: "24hour",
                maxAlarmsStr: "0",
                setDiffChannel1: "",
                setDiffChannel2: "",
                clrDiffChannel1: "",
                clrDiffChannel2: "",
            },
            modalTitle: "New Site Alarm Config"
        });


    }
    //edit row
    editAlarm = (): void => {

        this.checkChannelUnits(this.state.tempConfig);

        this.setState({
            editAlarm: true,
            modal: !this.state.modal,
            anchorEl: null,
            modalTitle: "Edit Site Alarm Config"
        });
    }

    deleteAlarm = (): void => {
        if (window.confirm('Are you sure you want to delete this alarm?')) {
            console.log(this.state.tempConfig)
            CallDeleteAPI(CreateUrl(`/api/aquaguard/SiteAlarmConfig/${this.state.tempConfig.id}`), {})
                .then(data => window.location.reload())
        }
    }

    //save changes, for both edit and new
    saveAlarm = (): void => {

        const me = this;
        const tempConfig = this.state.tempConfig;
        
        // Set units from channel if CompareChannel input
        if (tempConfig?.setInput == 20) {
            tempConfig.setUnits = 9;
        }
        if (tempConfig?.clrInput == 20) {
            tempConfig.clrUnits = 9;
        }

        if (this.state.tempConfig && this.state.tempConfig?.id < 0) { //adding a new alarm config
            if (tempConfig) {
                tempConfig.id = 0;
                tempConfig.fkSiteId = this.props.location.state.siteId;
            }

            //push new model to api
            me.setState({
                tempConfig: tempConfig,
                modal: !me.state.modal,
            });

            //********************
            //send model to API

            //save site
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(this.state.tempConfig),
            };

            CallGetAPI(CreateUrl('/api/aquaguard/SiteAlarmConfiguration'), requestOptions)
                .then(data => {
                    const config = me.state.tempConfig;
                    const updates: Array<{
                        type: "insert" | "update" | "remove";
                        key: number;
                        data: any | undefined;
                        index: number | undefined;
                    }> = [];

                    if (config) {
                        config.id = data.id;
                        // Updates don't work for new records'
                        updates.push({ type: 'remove', key: -(config?.idx), index: config?.idx, data: undefined });
                        updates.push({ type: 'insert', key: config?.id, index: config?.idx, data: config });
                        me.store.push(updates);

//                        me.reloadData();

                        me.setState({
                            tempConfig: config
                        });
                    }
                    me.createSuccessNotification();

                })

                .catch(function (error) {
                    me.createFailedInsertErrorNotification()
                    console.log(error)
                });


        }
        else {//modifying an existing site

            this.store.push([{ type: 'update', key: tempConfig?.id, data: tempConfig }]);

            this.setState({
                modal: !this.state.modal,
            });

            //********************
            //send model to API

            const me = this;

            //save site
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(this.state.tempConfig)
            };

            CallGetAPI(CreateUrl('/api/Aquaguard/SiteAlarmConfiguration'), requestOptions)
                .then(async response => {
                    console.log(response)

                    me.createSuccessNotification();

                })
                .catch(function (error) {
                    me.createErrorNotification()
                    console.log(error)
                });

        }
    }


    // Edit functions
    handleAlarmTextfieldChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        const alarm = Object.assign({}, this.state.tempConfig);
        const errors = Object.assign({}, this.state.fieldErrors);
        if (inputName == "alarmMessage") {
            if (inputValue.length <= 4000) {
                alarm[inputName] = inputValue;
            }
        }
        else {
            if (inputName.endsWith("Threshold")) {
                // Check it's decimal value
                // NOTE parseFloat ignores any non-digits AFTER a number, so check last char is digit
                if (isNaN(parseFloat(inputValue)) || (inputValue.slice(-1) != "." && isNaN(parseFloat(inputValue.slice(-1))))) {
                    errors[inputName] = true;
                }
                else {
                    errors[inputName] = false;
                    alarm[inputName] = parseFloat(inputValue);
                }
                alarm[inputName + "Str"] = inputValue;

            }
            else {
                // Check it's integer value
                if (isNaN(parseInt(inputValue)) || inputValue.indexOf(".") != -1 || isNaN(parseInt(inputValue.slice(-1))) || inputValue.slice(0,1) == "-") {
                    errors[inputName] = true;
                }
                else {
                    errors[inputName] = false;
                    alarm[inputName] = parseInt(inputValue);
                }
                alarm[inputName + "Str"] = inputValue;
            }
        }
        this.setState({ tempConfig: alarm, fieldErrors: errors });

    };

    handleAlarmToggleChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: boolean = e.target.checked;
        const alarm = Object.assign({}, this.state.tempConfig);
        alarm[inputName] = inputValue;

        this.setState({ tempConfig: alarm });

    };

    handleAlarmSelectChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        const frequency = this.state.transmissionFrequency
        
        const alarm = Object.assign({}, this.state.tempConfig);
        if (inputName === 'setCountStr') {
            alarm[inputName] = inputValue;
            alarm.setCount = this.frequencyModify(frequency, inputValue)
        }
        else if (inputName === 'clrCountStr') {
            alarm[inputName] = inputValue;
            alarm.clrCount = this.frequencyModify(frequency, inputValue)
        }
        else {
            alarm[inputName] = parseInt(inputValue);
        }
        this.setState({ tempConfig: alarm });

    }
    

    frequencyModify = (frequency: number, inputValue: string): number => {
        if (frequency >= 96) {
            if (inputValue === '15min') {
                    return 1
                } else if (inputValue === '30min') {
                    return 2
                } else if (inputValue === '1hour') {
                    return 4
                } else if (inputValue === '6hour') {
                    return 24
                } else if (inputValue === '12hour') {
                    return 48
                } else if (inputValue === '24hour') {
                    return 96
                }
        } else if (frequency >= 48 && frequency < 96) {
                if (inputValue === '30min') {
                    return 1
                } else if (inputValue === '1hour') {
                    return 2
                } else if (inputValue === '6hour') {
                    return 12
                } else if (inputValue === '12hour') {
                    return 24
                } else if (inputValue === '24hour') {
                    return 48
                }
        } else if (frequency >= 24 && frequency < 48) {
                if (inputValue === '1hour') {
                    return 1
                } else if (inputValue === '6hour') {
                    return 6
                } else if (inputValue === '12hour') {
                    return 12
                } else if (inputValue === '24hour') {
                    return 24
                }
        } else if (frequency >= 12 && frequency < 24) {
                if (inputValue === '6hour') {
                    return 3
                } else if (inputValue === '12hour') {
                    return 6
                } else if (inputValue === '24hour') {
                    return 12
                }
        } else if (frequency >= 4 && frequency < 12) {
                if (inputValue === '6hour') {
                    return 1
                } else if (inputValue === '12hour') {
                    return 2
                } else if (inputValue === '24hour') {
                    return 4
                }
        } else if (frequency >= 2 && frequency < 4) {
                if (inputValue === '12hour') {
                    return 1
                } else if (inputValue === '24hour') {
                    return 2
                }
        } else {
                return 1
        }
    }

    handleChannelSelectChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        
        const alarm = Object.assign({}, this.state.tempConfig);
        alarm[inputName] = inputValue;

        this.checkChannelUnits(alarm);

        this.setState({ tempConfig: alarm });


    }
    checkChannelUnits(alarm: APIGetSiteAlarmConfigModel | undefined): void {
        if (alarm != undefined) {
            // Check Units same on channels selected
            const errors = Object.assign({}, this.state.fieldErrors);
            errors.setDiffChannel = false;
            errors.clrDiffChannel = false;
            if (alarm.setDiffChannel1 != "" && alarm.setDiffChannel2 != "") {
                if (this.state.channels.filter((c) => c.channelRef == alarm.setDiffChannel1)[0].units
                    != this.state.channels.filter((c) => c.channelRef == alarm.setDiffChannel2)[0].units) {
                    errors.setDiffChannel = true;
                }
            }
            if (alarm.clrDiffChannel1 != "" && alarm.clrDiffChannel2 != "") {
                if (this.state.channels.filter((c) => c.channelRef == alarm.clrDiffChannel1)[0].units
                    != this.state.channels.filter((c) => c.channelRef == alarm.clrDiffChannel2)[0].units) {
                    errors.clrDiffChannel = true;
                }
            }

            this.setState({ fieldErrors: errors });
        }
    }

    getUnitsForChannel(channel: string): string {
        let units = "";
        const selChan = this.state.channels.filter((c) => c.channelRef == channel);
        if (selChan.length > 0) {
            units = selChan[0].units;
        }
        return units;
    }

    // Alarm settings
    chariotSetUnitsCalculateCellValue(rowInfo: APIGetSiteAlarmConfigModel): string {
        switch (rowInfo.setUnits) {
            case 1:
                return "litres(/s)";
            case 2:
                return "m3(/h)";
            case 3:
                return "bar";
            case 4:
                return "mb";
            case 5:
                return "mH2O";
            case 6:
                return "psi";
            case 7:
                return "RSSI";
            case 8:
                return "mV";
            case 9:
                return this.getUnitsForChannel(rowInfo.setDiffChannel1);
            default:
                return "";

        }
    }

    chariotClrUnitsCalculateCellValue(rowInfo: APIGetSiteAlarmConfigModel): string {
        switch (rowInfo.clrUnits) {
            case 1:
                return "litres(/s)";
            case 2:
                return "m3(/h)";
            case 3:
                return "bar";
            case 4:
                return "mb";
            case 5:
                return "mH2O";
            case 6:
                return "psi";
            case 7:
                return "RSSI";
            case 8:
                return "mV";
            case 9:
                return this.getUnitsForChannel(rowInfo.clrDiffChannel1);
            default:
                return "";

        }
    }

    blankFunction = function () {
        return undefined;
    };



    alarmLoadCountModify = (frequency: number, inputValue :number ):string => {
        if (frequency >= 96) {
            if (inputValue === 1) {
                return '15min';
            } else if (inputValue === 2) {
                return '30min';
            } else if (inputValue === 4) {
                return '1hour';
            } else if (inputValue === 24) {
                return '6hour';
            } else if (inputValue === 48) {
                return '12hour';
            } else if (inputValue === 96) {
                return '24hour';
            }
        } else if (frequency >= 48 && frequency < 96) {
            if (inputValue === 1) {
                return '30min';
            } else if (inputValue === 2) {
                return '1hour';
            } else if (inputValue === 12) {
                return '6hour';
            } else if (inputValue === 24) {
                return '12hour';
            } else if (inputValue === 48) {
                return '24hour';
            }
        } else if (frequency >= 24 && frequency < 48) {
            if (inputValue === 1) {
                return '1hour';
            } else if (inputValue === 6) {
                return '6hour';
            } else if (inputValue === 12) {
                return '12hour';
            } else if (inputValue === 24) {
                return '24hour';
            }
        } else if (frequency >= 12 && frequency < 24) {
            if (inputValue === 3) {
                return '6hour';
            } else if (inputValue === 6) {
                return '12hour';
            } else if (inputValue === 12) {
                return '24hour';
            }
        } else if (frequency >= 4 && frequency < 12) {
            if (inputValue === 1) {
                return '6hour';
            } else if (inputValue === 2) {
                return '12hour';
            } else if (inputValue === 4) {
                return '24hour';
            }
        } else if (frequency >= 2 && frequency < 4) {
            if (inputValue === 1) {
                return '12hour';
            } else if (inputValue === 2) {
                return '24hour';
            }
        } else {
            return '24hour';
        }
        return '24hour';
    };


    handleClick(event: any, id: number): void {
        const frequency = this.state.transmissionFrequency
        this.store.byKey(id)
            .then((config: APIGetSiteAlarmConfigModel) => {
                config.setCountStr = this.alarmLoadCountModify(frequency, config.setCount)
                config.clrCountStr = this.alarmLoadCountModify(frequency, config.clrCount)
                this.setState(
                    {
                        tempConfig: config,
                        anchorEl: event.currentTarget,
                        editAlarm: true,
                    });
            },
                (error) => {
                    console.log(error);
                });
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    anyErrors = (): boolean => {
        return this.state.fieldErrors.setCount
            || this.state.fieldErrors.clrCount
            || this.state.fieldErrors.setThreshold
            || this.state.fieldErrors.clrThreshold
            || this.state.fieldErrors.maxAlarms
            || this.state.fieldErrors.setDiffChannel
            || this.state.fieldErrors.clrDiffChannel;

   }

    moreRender(Task: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, Task.data.id); }}><MoreHorizIcon /></IconButton>
        );
    }

    alarmClassCustomizeText(cellInfo: any): string {
        switch (cellInfo.value) {
            case 4:
                return "Critical";
            case 5:
                return "High";
            case 6:
                return "Normal";
            case 7:
                return "Low";
        }
        return "";
    }

    alarmInputCustomizeText(cellInfo: any): string {
        switch (cellInfo.value) {
            case 1:
                return "Flow";
            case 2:
                return "Consumption";
            case 3:
                return "Latest Pressure 1";
            case 7:
                return "Latest Pressure 2";
            case 11:
                return "Pressure 1 Difference";
            case 12:
                return "Pressure 2 Difference";
            case 20:
                return "Channel compare";
            case 30:
                return "Signal Strength (0-31)";
            case 31:
                return "Battery (mV)";
        }
        return "";
    }

    alarmSetInputCalculateCellValue(rowInfo: APIGetSiteAlarmConfigModel): string {
        switch (rowInfo.setInput) {
            case 1:
                return "Flow";
            case 2:
                return "Total";
            case 3:
                return "Latest Pressure 1";
            case 7:
                return "Latest Pressure 2";
            case 11:
                return "Pressure 1 Difference";
            case 12:
                return "Pressure 2 Difference";
            case 20:
                return "Channel compare: " + rowInfo.setDiffChannel1 + " - " + rowInfo.setDiffChannel2;
            case 21:
                return "Consumption";
            case 30:
                return "Signal Strength (0-31)";
            case 31:
                return "Battery (mV)";
        }
        return "";
    }
    alarmClrInputCalculateCellValue(rowInfo: APIGetSiteAlarmConfigModel): string {
        switch (rowInfo.setInput) {
            case 1:
                return "Flow";
            case 2:
                return "Consumption";
            case 3:
                return "Latest Pressure 1";
            case 7:
                return "Latest Pressure 2";
            case 11:
                return "Pressure 1 Difference";
            case 12:
                return "Pressure 2 Difference";
            case 20:
                return "Channel compare: " + rowInfo.clrDiffChannel1 + " - " + rowInfo.clrDiffChannel2;
            case 30:
                return "Signal Strength (0-31)";
            case 31:
                return "Battery (mV)";
        }
        return "";
    }

    render(): ReactNode {
        const { classes } = this.props;

        return (
            <div>
                <Modal
                    show={this.state.modal}
                    style={{ marginTop: "75px" }}
                    onHide={():void => this.setState({modal: false}) }
                    
                >
                    
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {`${this.state.modalTitle} ${this.state.tempConfig?.alarmMask}`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Tooltip title='The message that will be displayed in the alarm' placement="top">
                                <TextField name="alarmMessage" label="Message" fullWidth={true} variant="outlined" size="small" value={this.state.tempConfig?.alarmMessage} onChange={this.handleAlarmTextfieldChange.bind(this)} />
                            </Tooltip>
                            </Grid>

                            
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="operator-select-label">Severity</InputLabel>
                                    <Tooltip placement="top" title='Sets the level of notification to the company (see user guide for more details)'>
                                    <Select
                                        name="alarmClass"
                                        label="Severity"
                                        labelId="severity-select-label"
                                        size="small"
                                        style={{ margin: 0, paddingBottom: 0 }}
                                        value={this.state.tempConfig?.alarmClass}
                                        onChange={this.handleAlarmSelectChange.bind(this)}
                                    >
                                        <MenuItem value="0">Not set</MenuItem>
                                        <MenuItem value="7">Low</MenuItem>
                                        <MenuItem value="6">Normal</MenuItem>
                                        <MenuItem value="5">High</MenuItem>
                                        <MenuItem value="4">Critical</MenuItem>
                                        </Select>
                                    </Tooltip>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip placement="top" title="Sets the max number of alarms to send per day to avoid alarm message spamming">
                                <TextField name="maxAlarms"
                                
                                    label="Max Alarms per day"
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    value={this.state.tempConfig?.maxAlarmsStr}
                                    onChange={this.handleAlarmTextfieldChange.bind(this)}
                                    error={this.state.fieldErrors.maxAlarms}
                                    helperText={this.state.fieldErrors.maxAlarms ? "Must be INTEGER number" : ""}
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12 }>Set your alarm</Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="input-select-label">Set Input</InputLabel>
                                    <Tooltip placement="top" title="The metric you would like to measure for your alarm">
                                    <Select
                                        name="setInput"
                                        label="Set Input"
                                        labelId="input-select-label"
                                        size="small"
                                        style={{ margin: 0, paddingBottom: 0 }}
                                        value={this.state.tempConfig?.setInput}
                                        onChange={this.handleAlarmSelectChange.bind(this)}
                                    >
                                        <MenuItem value="0">Alarm Off</MenuItem>
                                        <MenuItem value="1">Flow</MenuItem>
                                        <MenuItem value="2">Total</MenuItem>
                                        <MenuItem value="3">Latest Pressure 1</MenuItem>
                                        <MenuItem value="11">Pressure 1 Difference</MenuItem>
                                        <MenuItem value="7">Latest Pressure 2</MenuItem>
                                        <MenuItem value="12">Pressure 2 Difference</MenuItem>
                                        <MenuItem value="20">Channel compare</MenuItem>
                                        <MenuItem value="21">Consumption</MenuItem>
                                        </Select>
                                    </Tooltip>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="operator-select-label">Set Operator</InputLabel>
                                    <Tooltip placement="top" title="How this alarm will measure the threshold">
                                    <Select
                                        name="setOperator"
                                        label="Set Operator"
                                        labelId="operator-select-label"
                                        size="small"
                                        style={{ margin: 0, paddingBottom: 0 }}
                                        value={this.state.tempConfig?.setOperator}
                                        onChange={this.handleAlarmSelectChange.bind(this)}
                                    >
                                        <MenuItem value="0">Alarm Off</MenuItem>
                                        <MenuItem value="1">is Equal to</MenuItem>
                                        <MenuItem value="2">is Not Equal to</MenuItem>
                                        <MenuItem value="3">is Less than</MenuItem>
                                        <MenuItem value="4">is Greater than</MenuItem>
                                        <MenuItem value="5">is Less than or equal to</MenuItem>
                                        <MenuItem value="6">is Greater than or equal to</MenuItem>
                                        </Select>
                                    </Tooltip>
                                </FormControl>
                            </Grid>
                            

                            


                            <Grid item xs={2}>
                                <Tooltip placement="top" title="The value or limit you would like to test against">
                                <TextField name="setThreshold"
                                    label="Set Threshold"
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    value={this.state.tempConfig?.setThresholdStr}
                                    onChange={this.handleAlarmTextfieldChange.bind(this)}
                                    error={this.state.fieldErrors.setThreshold}
                                    helperText={this.state.fieldErrors.setThreshold ? "Must be DECIMAL number" : ""}
                                    />
                                </Tooltip>
                            </Grid>
                            {this.state.tempConfig?.setInput != enumChariotAlarmInput.ChannelCompare &&
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="operator-select-label">Set Units</InputLabel>
                                        <Tooltip placement="top" title="The unit of the value inputted into the threshold">
                                        <Select
                                            name="setUnits"
                                            size="small"
                                            style={{ margin: 0, paddingBottom: 0 }}
                                            value={this.state.tempConfig?.setUnits}
                                            onChange={this.handleAlarmSelectChange.bind(this)}
                                        >
                                            {this.state.tempConfig?.setInput == 1  &&
                                                <MenuItem value="1">l/s</MenuItem>
                                            }
                                            {this.state.tempConfig?.setInput == 1 &&
                                                <MenuItem value="2">m3/h</MenuItem>
                                            }
                                            {(this.state.tempConfig?.setInput == 2 || this.state.tempConfig?.setInput == enumChariotAlarmInput.Consumption) &&
                                                <MenuItem value="1">litres</MenuItem>
                                            }
                                            {(this.state.tempConfig?.setInput == 2 || this.state.tempConfig?.setInput == enumChariotAlarmInput.Consumption) &&
                                                <MenuItem value="2">m3</MenuItem>
                                            }
                                            {(this.state.tempConfig?.setInput == 3 || this.state.tempConfig?.setInput == 7 || this.state.tempConfig?.setInput == 11 || this.state.tempConfig?.setInput == 12) &&
                                                <MenuItem value="3">Bar</MenuItem>
                                            }
                                            {(this.state.tempConfig?.setInput == 3 || this.state.tempConfig?.setInput == 7 || this.state.tempConfig?.setInput == 11 || this.state.tempConfig?.setInput == 12) &&
                                                <MenuItem value="4">mbar</MenuItem>
                                            }
                                            {(this.state.tempConfig?.setInput == 3 || this.state.tempConfig?.setInput == 7 || this.state.tempConfig?.setInput == 11 || this.state.tempConfig?.setInput == 12) &&
                                                <MenuItem value="5">mH2O</MenuItem>
                                            }
                                            {(this.state.tempConfig?.setInput == 3 || this.state.tempConfig?.setInput == 7 || this.state.tempConfig?.setInput == 11 || this.state.tempConfig?.setInput == 12) &&
                                                <MenuItem value="6">psi</MenuItem>
                                            }
                                            {this.state.tempConfig?.setInput == 30 &&
                                                <MenuItem value="7">RSSI</MenuItem>
                                            }
                                            {this.state.tempConfig?.setInput == 31 &&
                                                <MenuItem value="8">mV</MenuItem>
                                            }
                                            </Select>
                                        </Tooltip>
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="timeframe-select-label">Set Timeframe</InputLabel>
                                    <Tooltip placement="top" title="Sets the timeframe of the metric based on the loggers dial in frequency">
                                    <Select
                                        name="setCountStr"
                                        size="small"
                                        style={{ margin: 0, paddingBottom: 0 }}
                                        value={this.state.tempConfig?.setCountStr}
                                        onChange={this.handleAlarmSelectChange.bind(this)}
                                    >
                                    {this.state.transmissionFrequency >= 96 && <MenuItem value="15min">every 15 Minutes</MenuItem>}
                                    {this.state.transmissionFrequency >= 48 && <MenuItem value="30min">every 30 Minutes</MenuItem>}
                                    {this.state.transmissionFrequency >= 24 && <MenuItem value="1hour">every hour</MenuItem>}
                                    {this.state.transmissionFrequency >= 4 && <MenuItem value="6hour">every 6 hours</MenuItem>}
                                    {this.state.transmissionFrequency >= 2 && <MenuItem value="12hour">every 12 hours</MenuItem>}
                                    {this.state.transmissionFrequency >= 1 && <MenuItem value="24hour">every 24 hours</MenuItem>}

                                        </Select>
                                    </Tooltip>
                                </FormControl>
                            </Grid>

                            {this.state.tempConfig?.setInput == enumChariotAlarmInput.ChannelCompare &&
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <FormControl fullWidth>
                                                <InputLabel id="operator-select-label">Channel 1</InputLabel>
                                                <Select
                                                    name="setDiffChannel1"
                                                    label="Set Channel 1"
                                                    labelId="channel-select-label"
                                                    size="small"
                                                    style={{ margin: 0, paddingBottom: 0 }}
                                                    value={this.state.tempConfig?.setDiffChannel1}
                                                    onChange={this.handleChannelSelectChange.bind(this)}
                                                >
                                                    <MenuItem value="">None</MenuItem>
                                                    {this.state.channels.map((chan: SiteChannel, i: number) => {
                                                        return <MenuItem value={chan.channelRef} key={chan.channel}>{chan.channelRef}</MenuItem>
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField name="units1"
                                                label="Units"
                                                fullWidth={true}
                                                variant="outlined"
                                                size="small"
                                                disabled={true}
                                                value={this.getUnitsForChannel(this.state.tempConfig?.setDiffChannel1)}
                                                error={this.state.fieldErrors.setDiffChannel}
                                                helperText={this.state.fieldErrors.setDiffChannel ? "Channel Units MUST match" : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl fullWidth>
                                                <InputLabel id="operator-select-label">Channel 2</InputLabel>
                                                <Select
                                                    name="setDiffChannel2"
                                                    label="Set Channel 2"
                                                    labelId="channel-select-label"
                                                    size="small"
                                                    style={{ margin: 0, paddingBottom: 0 }}
                                                    value={this.state.tempConfig?.setDiffChannel2}
                                                    onChange={this.handleChannelSelectChange.bind(this)}
                                                >
                                                    <MenuItem value="">None</MenuItem>
                                                    {this.state.channels.map((chan: SiteChannel, i: number) => {
                                                        return <MenuItem value={chan.channelRef} key={chan.channel}>{chan.channelRef}</MenuItem>
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField name="units2"
                                                label="Units"
                                                fullWidth={true}
                                                variant="outlined"
                                                size="small"
                                                disabled={true}
                                                value={this.getUnitsForChannel(this.state.tempConfig?.setDiffChannel2)}
                                                error={this.state.fieldErrors.setDiffChannel}
                                                helperText={this.state.fieldErrors.setDiffChannel ? "Channel Units MUST match" : ""}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={12 }>When to clear your alarm</Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="input-select-label">Clear Input</InputLabel>
                                    <Select
                                        name="clrInput"
                                        label="ClrInput"
                                        labelId="input-select-label"
                                        size="small"
                                        style={{ margin: 0, paddingBottom: 0 }}
                                        value={this.state.tempConfig?.clrInput}
                                        onChange={this.handleAlarmSelectChange.bind(this)}
                                    >
                                        <MenuItem value="0">Alarm Off</MenuItem>
                                        <MenuItem value="1">Flow</MenuItem>
                                        <MenuItem value="2">Total</MenuItem>
                                        <MenuItem value="3">Latest Pressure 1</MenuItem>
                                        <MenuItem value="11">Pressure 1 Difference</MenuItem>
                                        <MenuItem value="7">Latest Pressure 2</MenuItem>
                                        <MenuItem value="12">Pressure 2 Difference</MenuItem>
                                        <MenuItem value="20">Channel compare</MenuItem>
                                        <MenuItem value="21">Consumption</MenuItem>
                                   </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="operator-select-label">Clear Operator</InputLabel>
                                    <Select
                                        name="clrOperator"
                                        label="Clear Operator"
                                        labelId="operator-select-label"
                                        size="small"
                                        style={{ margin: 0, paddingBottom: 0 }}
                                        value={this.state.tempConfig?.clrOperator}
                                        onChange={this.handleAlarmSelectChange.bind(this)}
                                    >
                                        <MenuItem value="0">Alarm Off</MenuItem>
                                        <MenuItem value="1">is Equal to</MenuItem>
                                        <MenuItem value="2">is Not Equal to</MenuItem>
                                        <MenuItem value="3">is Less than</MenuItem>
                                        <MenuItem value="4">is Greater than</MenuItem>
                                        <MenuItem value="5">is Less than or equal to</MenuItem>
                                        <MenuItem value="6">is Greater than or equal to</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                           

                            {this.state.tempConfig?.clrInput == 20 &&
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id="operator-select-label">Channel 1</InputLabel>
                                                <Select
                                                    name="clrDiffChannel1"
                                                    label="Clear Channel 1"
                                                    labelId="channel-select-label"
                                                    size="small"
                                                    style={{ margin: 0, paddingBottom: 0 }}
                                                    value={this.state.tempConfig?.clrDiffChannel1}
                                                    onChange={this.handleChannelSelectChange.bind(this)}
                                                >
                                                    <MenuItem value="">None</MenuItem>
                                                    {this.state.channels.map((chan: SiteChannel, i: number) => {
                                                        return <MenuItem value={chan.channelRef} key={chan.channel}>{chan.channelRef}</MenuItem>
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField name="units3"
                                                label="Units"
                                                fullWidth={false}
                                                variant="outlined"
                                                size="small"
                                                disabled={true}
                                                value={this.getUnitsForChannel(this.state.tempConfig?.clrDiffChannel1)}
                                                error={this.state.fieldErrors.clrDiffChannel}
                                                helperText={this.state.fieldErrors.clrDiffChannel ? "Channel Units MUST match" : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id="operator-select-label">Channel 2</InputLabel>
                                                <Select
                                                    name="clrDiffChannel2"
                                                    label="Clear Channel 2"
                                                    labelId="channel-select-label"
                                                    size="small"
                                                    style={{ margin: 0, paddingBottom: 0 }}
                                                    value={this.state.tempConfig?.clrDiffChannel2}
                                                    onChange={this.handleChannelSelectChange.bind(this)}
                                                >
                                                    <MenuItem value="">None</MenuItem>
                                                    {this.state.channels.map((chan: SiteChannel, i: number) => {
                                                        return <MenuItem value={chan.channelRef} key={chan.channel}>{chan.channelRef}</MenuItem>
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField name="units4"
                                                label="Units"
                                                fullWidth={false}
                                                variant="outlined"
                                                size="small"
                                                disabled={true}
                                                value={this.getUnitsForChannel(this.state.tempConfig?.clrDiffChannel2)}
                                                error={this.state.fieldErrors.clrDiffChannel}
                                                helperText={this.state.fieldErrors.clrDiffChannel ? "Channel Units MUST match" : ""}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }


                            <Grid item xs={2}>
                                <TextField name="clrThreshold"
                                    label="Clear Threshold"
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    value={this.state.tempConfig?.clrThresholdStr}
                                    onChange={this.handleAlarmTextfieldChange.bind(this)}
                                    error={this.state.fieldErrors.clrThreshold}
                                    helperText={this.state.fieldErrors.clrThreshold ? "Must be DECIMAL number" : ""}
                                />
                            </Grid>
                            {this.state.tempConfig?.clrInput != enumChariotAlarmInput.ChannelCompare &&
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="operator-select-label">Clear Units</InputLabel>
                                    <Select
                                        name="clrUnits"
                                        size="small"
                                        style={{ margin: 0, paddingBottom: 0 }}
                                        value={this.state.tempConfig?.clrUnits}
                                        onChange={this.handleAlarmSelectChange.bind(this)}
                                    >
                                        {this.state.tempConfig?.clrInput == 1 &&
                                            <MenuItem value="1">l/s</MenuItem>
                                        }
                                        {this.state.tempConfig?.clrInput == 1 &&
                                            <MenuItem value="2">m3/h</MenuItem>
                                        }
                                        {(this.state.tempConfig?.clrInput == 2 || this.state.tempConfig?.clrInput == enumChariotAlarmInput.Consumption) &&
                                            <MenuItem value="1">litres</MenuItem>
                                        }
                                        {(this.state.tempConfig?.clrInput == 2 || this.state.tempConfig?.clrInput == enumChariotAlarmInput.Consumption) &&
                                            <MenuItem value="2">m3</MenuItem>
                                        }
                                        {(this.state.tempConfig?.clrInput == 3 || this.state.tempConfig?.clrInput == 7 || this.state.tempConfig?.clrInput == 11 || this.state.tempConfig?.clrInput == 12) &&
                                            <MenuItem value="3">Bar</MenuItem>
                                        }
                                        {(this.state.tempConfig?.clrInput == 3 || this.state.tempConfig?.clrInput == 7 || this.state.tempConfig?.clrInput == 11 || this.state.tempConfig?.clrInput == 12) &&
                                            <MenuItem value="4">mbar</MenuItem>
                                        }
                                        {(this.state.tempConfig?.clrInput == 3 || this.state.tempConfig?.clrInput == 7 || this.state.tempConfig?.clrInput == 11 || this.state.tempConfig?.clrInput == 12) &&
                                            <MenuItem value="5">mH2O</MenuItem>
                                        }
                                        {(this.state.tempConfig?.clrInput == 3 || this.state.tempConfig?.clrInput == 7 || this.state.tempConfig?.clrInput == 11 || this.state.tempConfig?.clrInput == 12) &&
                                            <MenuItem value="6">psi</MenuItem>
                                        }
                                        {this.state.tempConfig?.clrInput == 30 &&
                                            <MenuItem value="7">RSSI</MenuItem>
                                        }
                                        {this.state.tempConfig?.clrInput == 31 &&
                                            <MenuItem value="8">mV</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            }
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="timeframe-select-label">Set Clear Timeframe</InputLabel>
                                    <Select
                                        name="clrCountStr"
                                        size="small"
                                        style={{ margin: 0, paddingBottom: 0 }}
                                        value={this.state.tempConfig?.clrCountStr}
                                        onChange={this.handleAlarmSelectChange.bind(this)}
                                    >
                                        {this.state.transmissionFrequency >= 96 && <MenuItem value="15min">every 15 Minutes</MenuItem>}
                                        {this.state.transmissionFrequency >= 48 && <MenuItem value="30min">every 30 Minutes</MenuItem>}
                                        {this.state.transmissionFrequency >= 24 && <MenuItem value="1hour">every hour</MenuItem>}
                                        {this.state.transmissionFrequency >= 4 && <MenuItem value="6hour">every 6 hours</MenuItem>}
                                        {this.state.transmissionFrequency >= 2 && <MenuItem value="12hour">every 12 hours</MenuItem>}
                                        {this.state.transmissionFrequency >= 1 && <MenuItem value="24hour">every 24 hours</MenuItem>}

                                    </Select>
                                </FormControl>
                            </Grid>


                        </Grid>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="primary" variant="contained" style={{marginRight: "10px"}} onClick={(): void => this.saveAlarm()} disabled={this.anyErrors()}>Continue</Button>
                        <Button color="secondary" variant="contained" onClick={(): void => this.toggleModal()}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                        
                        {!this.state.tableHidden &&
                            <GridContainer >
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                             <div style={{display: 'inline'}}>
                                                <IconButton
                                                    style={{display: 'inline'}}
                                                    onClick={(): void =>  this.props.history.goBack()}
                                                    size="large"><ArrowBackIcon style={{fill: "white"}}/></IconButton>
                                                <h4 style={{display: 'inline'}} className={classes.cardTitleWhite}>{this.props.location.state.siteName} - Site Alarms</h4>
                                             </div>
                                            
                                        </CardHeader>
                                        <CardBody>
                                    <DataGrid

                                        allowColumnResizing={true}
                                        repaintChangesOnly={true}
                                        dataSource={this.dataSource}
                                        ref={this.gridRef}>
                                        <StateStoring enabled={true} type="localStorage" storageKey="SiteAlarmConfigListGrid" />
                                        <FilterRow visible={false} />

                                                <Column fixed={true} dataField="idx" caption="Slot" fixedPosition="left" visible={true} dataType="number" sortIndex={0} width={80}/>
                                                <Column dataField="alarmMask" visible={false} dataType="number" />
                                                <Column dataField="alarmMessage" caption="Alarm" visible={true} dataType="string" width={200} />
                                                <Column dataField="alarmClass" caption="Severity" visible={true} dataType="string" customizeText={this.alarmClassCustomizeText} width={80} />
                                                <Column dataField="setInput" caption="Set Input" visible={true} dataType="string" width={250} calculateCellValue={this.alarmSetInputCalculateCellValue}/>
                                                <Column dataField="setThreshold" caption="Set Threshold" visible={true} dataType="number" width={150} />
                                                <Column dataField="setUnits" caption="Set Units" visible={true} dataType="string" calculateCellValue={this.chariotSetUnitsCalculateCellValue.bind(this)} width={80} />
                                                <Column dataField="clrInput" caption="Clear Input" visible={true} dataType="string" width={250} calculateCellValue={this.alarmClrInputCalculateCellValue} />
                                                <Column dataField="clrThreshold" caption="Clear Threshold" visible={true} dataType="number" width={150} />
                                                <Column dataField="clrUnits" caption="Clear Units" visible={true} dataType="string" calculateCellValue={this.chariotClrUnitsCalculateCellValue.bind(this)} width={80} />
                                                <Column fixed={true} fixedPosition='right' type="buttons" cellRender={this.moreRender.bind(this)} width={35} />


                                        <Paging enabled={false} defaultPageSize={16} />
                                    </DataGrid>
                                </CardBody>
                                    </Card>
                                </GridItem>

                            </GridContainer>
                        }

                    </div>
                }
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                    disableScrollLock={true}
                >
                    <MenuItem onClick={this.editAlarm}>Edit</MenuItem>
                    <MenuItem onClick={this.deleteAlarm}>Delete</MenuItem>
                </Menu>
                <NotificationContainer />

            </div>
        );

    }

}

export default withStyles(style)(SiteAlarmConfigs);

