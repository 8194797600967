import React, { ReactNode } from "react";
import { APIGetLoggerSummaryModel } from "models/APIGetLoggerSummaryModel";


interface Props {
    logger: APIGetLoggerSummaryModel;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    alarms: string[];
}

export class AlarmSummary extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            alarms: []
        };
    }

    componentDidMount(): void {

        let mask = 1;
        const alarmState = this.props.logger.AlarmState;
        const tmpAlarms: string[] = [];

        for (let i = 0; i < 15; i++) {
            if ((alarmState & mask) > 0) {
                const msg = "AlarmMessage" + i;
                if (this.props.logger[msg] == null || this.props.logger[msg].length == 0) {
                    tmpAlarms.push("Alarm bit " + i + " active");
                }
                else {
                    tmpAlarms.push(this.props.logger[msg]);
                }
            }
            mask = mask << 1;
        }

        this.setState({ alarms: tmpAlarms });
    }

    render(): ReactNode {

        return (
            <div>
                {this.props.logger.AlarmState == 0 &&
                        <p>No current Alarms</p>
                }
                {this.props.logger.AlarmState > 0 &&
                    <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }} >
                    {
                            this.state.alarms.map((alm, idx) => {
                                return <div style={{ margin: "10px" }} key={idx}><b style={{ fontWeight: 700 }}>{alm.slice(0, 12)}</b>{alm.slice(alm.length -7, alm.length) }</div>;
                        })
                    }
                    </div>
                }
            </div>
        );
    }
}
