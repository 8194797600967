import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Modal from 'react-bootstrap/Modal'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import ClipLoader from "react-spinners/ClipLoader";
import TextField from '@mui/material/TextField';
import MaterialButton from '@mui/material/Button';
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// DevExtreme
import { DataGrid, Column, Export, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import { CreateUrl, CallGetAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import dxDataGrid, { dxDataGridRowObject } from "devextreme/ui/data_grid";
import { Grid, Select } from '@mui/material';
import { DateBox } from 'devextreme-react/date-box';
import DeployDialog from '../../components/Device/DeployDialog';

import { isLCLLogger } from 'variables/chariotProductIds';

const styles: Record<string, any> = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

let modalTitle = "";

const style = createStyles(styles);

interface DeviceData {
    id: number;
    serial: string;
    site: string;
    model: string;
    channels: string;
    lastConnected: Date | null;
    alarmState: string;
    siteId: number | null;
    siteName: string;
    gpsLatitude: number | null;
    gpsLongitude: number | null;
    configurationId: string;
    company: string;
}

interface Site {
    id: number;
    SiteNameUserFriendly: string;
    SiteName: string;
    created: Date;
    deleted: Date | null;
    Latitude: number | null;
    Longitude: number | null;
    defaultSite: boolean;
    fkCompanyId: number;
    fkEASiteId: number | null;
    EASite: string;
    lastUpdate: Date | null;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        cardCategoryWhite: string;
    };
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    selectedLogger: number;
    devices: Array<DeviceData>;
    sites: Array<Site>;
    filterValue: Array<string | string[]>;
    tempDevice: DeviceData | undefined;
    tempSite: Site | undefined;
    anchorEl: EventTarget & HTMLButtonElement | null;
    modal: boolean;
    redirect: boolean;
    redirectPath: string | undefined;
    redirectProps: { serial: string | undefined; deviceId: number | undefined } | undefined;
    hideNewSite: boolean;
    deployToSite: number;
    sitenameError: boolean;
    sitenameMsg: string;
    siteLatError: boolean;
    siteLatMsg: string;
    siteLongError: boolean;
    siteLongMsg: string;
    saveEnabled: boolean;
    fromDate: Date;
}

class UndeployedDeviceList extends React.Component<Props, State> {

    gridRef: React.RefObject<DataGrid>;

    constructor(props: Props | Readonly<Props>) {
        super(props);
        // Ref to Datagrid to store Page, Sort & Filters
        this.gridRef = React.createRef();

        this.state = {
            tableHidden: false,
            visible: false,
            selectedLogger: 0,
            devices: [],
            sites: [],
            loading: true,
            authorized: true,
            filterValue: [],
            tempDevice: undefined,
            tempSite: undefined,
            anchorEl: null,
            modal: false,
            redirect: false,
            redirectPath: undefined,
            redirectProps: undefined,
            hideNewSite: false,
            deployToSite: 0,
            sitenameError: false,
            sitenameMsg: "",
            siteLatError: false,
            siteLatMsg: "",
            siteLongError: false,
            siteLongMsg: "",
            saveEnabled: false,
            fromDate: new Date(),
        };

    }
    get dataGrid(): dxDataGrid | undefined {
        return this.gridRef.current?.instance;
    }

    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success');

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (): void => {
        NotificationManager.error('Error creating new record', '', 5000);
    };


    componentDidMount(): void {

        this.reloadData();

    }

    reloadData(): void {
        const deviceData = new Array<DeviceData>();
 
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/GetLoggers?companyId=' + sessionStorage.getItem('companyId') + "&deployed=false"),{})
            .then(data => {
                if (data?.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    deviceData.length = 0; //clear dummy data
                    for (let i = 0; i < data.length; i++) {
                        const serial = data[i].serial;
                        const id = parseInt(data[i].id);
                        const rec: DeviceData = {
                            id: id,
                            serial: serial,
                            site: data[i].site.replace(/[^A-Za-z0-9]/g, ''),
                            model: data[i].model,
                            channels: data[i].channels,
                            lastConnected: data[i].lastConnected? adjustTime(moment(data[i].lastConnected).toDate()) : null,
                            alarmState: data[i].alarmState.toString(),
                            siteId: data[i].siteId,
                            siteName: data[i].siteName,
                            gpsLatitude: data[i].gpsLatitude,
                            gpsLongitude: data[i].gpsLongitude,
                            configurationId: data[i].configurationId,
                            company: data[i].company

                        };
                        deviceData.push(rec);
                    }

                    me.setState(
                        {
                            devices: deviceData,
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            devices: [],
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false
                    })
            });

        if (this.state.sites.length == 0) {
            CallGetAPI(CreateUrl('/api/aquaguard/SitesForCompany?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
                .then(data => {

                    if (data.length > 0) {

                        // Copy the data records into deviceData, adding the clickEvent
                        const records = [];

                        for (let i = 0; i < data.length; i++) {
                            const id = data[i].siteId;

                            const rec: Site = {
                                id: id,
                                SiteName: data[i].siteName,
                                SiteNameUserFriendly: data[i].siteNameUserFriendly,
                                created: data[i].created,
                                deleted: data[i].deleted,
                                Latitude: data[i].latitude,
                                Longitude: data[i].longitude,
                                fkCompanyId: data[i].fkCompanyId,
                                lastUpdate: data[i].lastUpdate,
                                defaultSite: data[i].defaultSite,
                                EASite: data[i].eaSite,
                                fkEASiteId: data[i].fkEASiteId,
                            };

                            records.push(rec);
                        }


                        me.setState(
                            {
                                sites: records,
                            });

                    }
                })
                .catch(function (error) {
                    me.setState(
                        {
                            sites: []
                        });
                    console.log(error);

                });
        }
            
    }

    handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: dxDataGridRowObject): void {   //React.MouseEvent<HTMLButtonElement, MouseEvent>
        const device = this.state.devices.find((row) => row.id === id.key);
        const site = {
            id: 0,
            SiteNameUserFriendly: device ? device.site: "",
            SiteName: "",
            created: new Date(),
            deleted: null,
            Latitude: device ? device.gpsLatitude : null ,
            Longitude: device ? device.gpsLongitude : null,
            defaultSite: false,
            fkCompanyId: parseInt("0" + sessionStorage.getItem('companyId')),
            fkEASiteId: null,
            EASite: "",
            lastUpdate: null,
        };

        let siteError = false;
        if (site.SiteNameUserFriendly.length == 0) {
            siteError = true;
        }
        let latError = false;
        if (site.Latitude == null) {
            latError = true;
        }
        let lngError = false;
        if (site.Longitude == null) {
            lngError = true;
        }


        this.setState(
            {
                tempDevice: device,
                tempSite: site,
                anchorEl: event.currentTarget,
                sitenameError: siteError,
                siteLatError: latError,
                siteLongError: lngError,
                saveEnabled: this.validateSite(!siteError, !latError, !lngError),
            });
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    //edit row
    deployDevice = (): void => {
        this.setState({
            modal: !this.state.modal,
            anchorEl: null
        });
        modalTitle = "Deploy Device"
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    //field changed events---
    handleSiteChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        if (event.target.value.length == 0) {
            this.setState({ sitenameError: true, sitenameMsg: "Site name must be specified", saveEnabled: false });
        }
        else {
            this.setState({ sitenameError: false, sitenameMsg: "" });
            if (tempSite) {
                tempSite.SiteNameUserFriendly = event.target.value;
                this.setState({ tempSite: tempSite, saveEnabled: this.validateSite(true, !this.state.siteLatError, !this.state.siteLongError) });
            }
        }
    }

    handleSiteRefChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        if (tempSite) {
            tempSite.SiteName = event.target.value;
            this.setState({ tempSite: tempSite });
        }
    }

    handleLatitudeChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        const lat = parseFloat(event.target.value);

        if (isNaN(lat)) {
            this.setState({ siteLatError: true, siteLatMsg: "Latitude must be numerical", saveEnabled: false });
        }
        else {
            if (lat > 90 || lat < -90) {
                this.setState({ siteLatError: true, siteLatMsg: "Latitude must be between -90 and 90", saveEnabled: false });
            }
            else {
                this.setState({ siteLatError: false, siteLatMsg: "" });
                if (tempSite) {
                    tempSite.Latitude = lat;
                    this.setState({ tempSite: tempSite, saveEnabled: this.validateSite(!this.state.sitenameError, true, !this.state.siteLongError) });
                }
            }
        }
    }
    handleLongitudeChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        const lng = parseFloat(event.target.value);

        if (isNaN(lng)) {
            this.setState({ siteLongError: true, siteLongMsg: "Longitude must be numerical", saveEnabled: false });
        }
        else {
            if (lng > 180 || lng < -180) {
                this.setState({ siteLongError: true, siteLongMsg: "Longitude must be between -180 and 180", saveEnabled: false });
            }
            else {
                this.setState({ siteLongError: false, siteLongMsg: "" });
                if (tempSite) {
                    tempSite.Longitude = lng;
                    this.setState({ tempSite: tempSite, saveEnabled: this.validateSite(!this.state.sitenameError, !this.state.siteLatError, true) });
                }
            }
        }
    }

    fromDateChanged = (newDate: Date): void => {
        this.setState({ fromDate: newDate });
    }


    // validate tempsite
    validateSite = (name: boolean, lat: boolean, lng: boolean): boolean => {
        return (name && lat && lng);
    }

    // Select existing site
    handleSiteChange = (event: any): void => {
        this.setState({
            deployToSite: event.target.value,
            hideNewSite: event.target.value > 0,
            saveEnabled: (event.target.value > 0) || this.validateSite(!this.state.sitenameError, !this.state.siteLatError, !this.state.siteLongError),
        });
    }

    //-------------------------

    //save changes, for both edit and new
    saveSite = (): void => {

        if (this.state.tempSite?.id == 0) {
            // New site - need to create
            const me = this;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SiteName: this.state.tempSite.SiteName,
                    SiteNameUserFriendly: this.state.tempSite.SiteNameUserFriendly,
                    Latitude: this.state.tempSite.Latitude || 0,
                    Longitude: this.state.tempSite.Longitude || 0,
                    createdDate: new Date(),
                    FkCompanyId: parseInt(sessionStorage.getItem('companyId') || '0'),
                    DefaultSite: this.state.tempSite.defaultSite,
                    FkEASiteId: this.state.tempSite.fkEASiteId,
                })
            };

            CallGetAPI(CreateUrl('/api/aquaguard/Site'), requestOptions)
                .then(data => {
                    if (me.state.tempSite) {
                        const site = me.state.tempSite;
                        site.id = data.id;
                        site.lastUpdate = data.lastUpdate;
                        me.setState({
                            tempSite: site
                        });

                        this.assignLogger(site, me);
                    }
                })

                .catch(function (error) {
                    me.createFailedInsertErrorNotification();
                    console.log(error);
                });


        }
        else {
            //********************
            // Just Assign the logger to existing site
            const site = this.state.sites.filter(s => s.id == this.state.deployToSite);
            if (site.length == 1) {
                this.assignLogger(site[0], this);
            }
            else {
                this.createErrorNotification();
                console.log("Error on site lookup");
            }

        }

    }

    assignLogger(site: Site, me: this): void {

        if (me.state.tempDevice) {
            //save loggers connected to sites
            const loggerIds: Array<{ Id: number }> = [];
            loggerIds.push({ Id: me.state.tempDevice?.id });

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    siteId: site.id,
                    lastUpdate: site.lastUpdate,
                    loggers: loggerIds,
                    assignLogReadingsFromDate: this.state.fromDate,
                    deploymentGuid: me.state.tempDevice?.configurationId,
                })
            };

            CallPostAPI(CreateUrl('/api/Aquaguard/AssignLoggersToSite'), requestOptions)
                .then(async response => {
                    if (response.status == 200) {
                        me.createSuccessNotification();
                        me.toggleModal();
                        //UI update
                        me.reloadData();
                    }
                    else {
                        me.createErrorNotification();
                    }
                    console.log(response);
                })
                .catch(function (error) {
                    me.createErrorNotification();
                    console.log(error);
                });
        }
    }

    dialogResponse(result: number, update: boolean): void {

        if (result == 0) {
            this.createSuccessNotification();
            this.toggleModal();
            if (update) {
                //UI update
                this.reloadData();
            }
        }
        else {
            this.createErrorNotification();
        }
    }


    //do nothing, close the modal
    cancel = (): void => {
        this.toggleModal();
    }



    deviceDetailsClick = (): void => {

        this.setState(
            {
                redirect: true,
                //redirectPath: this.state.tempDevice?.model != "LCL" ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail',
                redirectPath: !isLCLLogger(this.state.tempDevice?.model) ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail',
                redirectProps: {
                    serial: this.state.tempDevice?.serial,
                    deviceId: this.state.tempDevice?.id
                }
            });
    }

    deviceOverviewClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/DeviceOverview',
                redirectProps: {
                    serial: this.state.tempDevice?.serial,
                    deviceId: this.state.tempDevice?.id
                }
            });
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm:ss");
    }

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('UndeployedLoggers');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    moreRender(key: dxDataGridRowObject): ReactNode {
        return (
            <IconButton size="small" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => { this.handleClick(e, key); }}><MoreHorizIcon /></IconButton>
        );
    }

    render(): ReactNode {
        const { classes } = this.props;

        modalTitle = "Deploy device " + this.state.tempDevice?.serial;

        return (
            (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
                (
                     (!this.state.redirect) ?
                        (<div>
                            <DeployDialog
                                show={this.state.modal}
                                title={modalTitle}
                                site={this.state.tempSite}
                                loggerId={this.state.tempDevice?.id}
                                configurationId={this.state.tempDevice?.configurationId}
                                onSubmit={this.dialogResponse.bind(this)}
                                onCancel={this.toggleModal.bind(this)}

                            />
                            {/*                        <Modal
                            show={this.state.modal}
                            onHide={(): void => this.toggleModal()}
                            dialogClassName="modal-100w"
                            aria-labelledby="example-custom-modal-styling-title">
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="show-grid">
                                    <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <h4>Select existing site:</h4>
                                            </Grid>
                                            <Grid item xs={6} md={4}>
                                            <Select
                                                    
                                            label="Select Site"
                                            value={this.state.deployToSite}
                                            onChange={this.handleSiteChange.bind(this)} >

                                                <MenuItem value={0}>Create NEW site</MenuItem>
                                                */}
                                                {
                                                   // this.state.sites.map((site: Site): ReactNode => {
                                                   //     return <MenuItem key={site.id} value={site.id}>{site.SiteNameUserFriendly}({site.SiteName})</MenuItem>
                                                   // })
                                                }
                            {/*
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            Enter effective date for device changes (default = Now)
                                            <div style={{ padding: 10 }}>
                                                Assign from:&nbsp;<DateBox id="fromDate" displayFormat={"dd/MM/yyyy"} value={this.state.fromDate} onValueChanged={this.fromDateChanged.bind(this)} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} hidden={this.state.hideNewSite}>
                                        <Grid item xs={12}>
                                            <hr/>
                                        </Grid>
                                        <Grid item xs={12}>
                                                <h4>OR Create NEW site:</h4>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                                <TextField
                                                id="outlined-input-sitename"
                                                defaultValue={this.state.tempSite?.SiteNameUserFriendly}
                                                label="Site Name"
                                                onChange={this.handleSiteChanged}
                                                variant="outlined"
                                                error={this.state.sitenameError}
                                                helperText={this.state.sitenameMsg}
                                                />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="outlined-input-siteref"
                                                    defaultValue={this.state.tempSite?.SiteName}
                                                    label="Site Ref"
                                                    onChange={this.handleSiteRefChanged}
                                                variant="outlined"
                                                />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                                <TextField
                                                id="outlined-input-latitude"
                                                defaultValue={this.state.tempSite?.Latitude}
                                                label="Latitude"
                                                onChange={this.handleLatitudeChanged}
                                                variant="outlined"
                                                error={this.state.siteLatError}
                                                helperText={this.state.siteLatMsg}
                                                />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                                <TextField
                                                id="outlined-input-longitude"
                                                defaultValue={this.state.tempSite?.Longitude}
                                                label="Longitude"
                                                onChange={this.handleLongitudeChanged}
                                                variant="outlined"
                                                error={this.state.siteLongError}
                                                helperText={this.state.siteLongMsg}
                                                />
                                        </Grid>

                                    </Grid>
                                </Modal.Body>
                            <Modal.Footer>
                                    <MaterialButton color="primary" onClick={(): void => this.saveSite()} disabled={!this.state.saveEnabled}>Save</MaterialButton>
                                    <MaterialButton color="secondary" onClick={(): void => this.toggleModal()}>Cancel</MaterialButton>
                            </Modal.Footer>
                            </Modal>
                            */}

                        {this.state.loading &&
                            <div style={{
                                position: 'absolute', left: '50%', top: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <ClipLoader
                                    size={150}
                                    color={"#123abc"}
                                    loading={this.state.loading}
                                />
                            </div>
                        }
                        {!this.state.loading &&
                            <div>

                                {this.state.tableHidden &&

                                        <ApiFailed />
                               }
                                {!this.state.tableHidden &&
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Card>
                                                <CardHeader color="primary">
                                                    <h4 className={classes.cardTitleWhite}>Undeployed Devices</h4>
                                                    <p className={classes.cardCategoryWhite}>
                                                        Spare devices identified with the server
                                                    </p>
                                                </CardHeader>
                                                <CardBody>
                                                    <DataGrid
                                                    dataSource={this.state.devices}
                                                    keyExpr="id"
                                                    ref={this.gridRef}
                                                    defaultFilterValue={this.state.filterValue}
                                                    onExporting={this.onExporting} >
                                                    <StateStoring enabled={true} type="localStorage" storageKey="deviceListGrid" />
                                                    <Export enabled={true} />
                                                    <GroupPanel visible={true} />
                                                    <Grouping autoExpandAll={true} />
                                                    <FilterRow visible={true} />
                                                    <Column dataField="id" visible={false} dataType="number" />
                                                    <Column caption="Company" dataField="company" dataType="string" groupIndex={0} />
                                                    <Column dataField="serial" dataType="string" />
                                                    <Column dataField="site" dataType="string" />
                                                    <Column dataField="model" dataType="string" />
                                                    <Column dataField="channels" dataType="string" />
                                                    <Column dataField="lastConnected" dataType="datetime" customizeText={this.dateColumnCustomizeText}  sortIndex="1" sortOrder={'Desc'}/>

                                                    <Column type="buttons" cellRender={this.moreRender.bind(this)} fixed={true} fixedPosition="right" />

                                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                    <Paging defaultPageSize={10} />
                                                    </DataGrid>
                                                </CardBody>
                                            </Card>
                                        </GridItem>
                                    </GridContainer>
                                }
                            </div>
                        }
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={this.handleClose}
                                disableScrollLock={true}
                            >
                                {!isLCLLogger(this.state.tempDevice?.model) &&
                                    <MenuItem onClick={this.deployDevice}>Deploy</MenuItem>
                                }
                                {!isLCLLogger(this.state.tempDevice?.model) &&
                                    <MenuItem onClick={this.deviceOverviewClick}>Overview</MenuItem>
                                }
                                <MenuItem onClick={this.deviceDetailsClick}>Details</MenuItem>
                            </Menu>

                            <NotificationContainer />

                    </div>)
                    :
                     (<Redirect push to={{
                        pathname: this.state.redirectPath,
                        state: this.state.redirectProps
                    }}/>)
                )
                :
                (
                    <Redirect to={'/Login'} />
                )
        )
    }
}

export default withStyles(style)(UndeployedDeviceList);

