import React, { ChangeEvent, ReactNode } from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Modal from 'react-bootstrap/Modal'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Container, Row, Col } from "react-bootstrap";
import { Collapse } from 'react-collapse';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Tooltip from '@mui/material/Tooltip';

//https://www.npmjs.com/package/react-collapse
//modal window imports
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyleNew";
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

// Drag n Drop
import { Draggable, Droppable, DraggableProvided, DraggableStateSnapshot, DroppableProvided } from 'react-beautiful-dnd';
import { Tile } from './Tile';
import { DashboardModel, TileModel, blankTile } from '../../models/DashboardModel';

const style = createStyles(styles);

interface ShareChip {
    key: number;
    label: string;
    icon: string;
}

interface Props extends WithStyles<typeof styles> {
    group: DashboardModel;
    edit: boolean;
    key: string;
    onGroupChange: (oldName: string, newName: string, by: number | null, expand: boolean) => void;
    onGroupUpdate: (groupName: string, tiles: Array<TileModel>) => void;
    groupCount: number;
    onTileClick: (id: string) => void;
    tileLibrary: Array<TileModel>;
    usedTiles: Array<string>;

}

interface State {
    newTileName: string;
    open: boolean;
    modal: boolean;
    editModal: boolean;
    shareModal: boolean;
    group: DashboardModel;
    layout: {
        smallTile_xs: number;
        smallTile_sm: number;
        smallTile_md: number;

        mediumTile_xs: number;
        mediumTile_sm: number;
        mediumTile_md: number;

        largeTile_xs: number;
        largeTile_sm: number;
        largeTile_md: number;
    };
    newTiles: Array<TileModel>;
    selTile: TileModel;

}


export class TileGroup extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            newTileName: 'New Tile',
            open: this.props.group.expand,
            modal: false,
            editModal: false,
            shareModal: false,
            group: this.props.group,
            layout: {
                smallTile_xs: 12, //1 per row
                smallTile_sm: 6, // 2 per row
                smallTile_md: 3, // 4 per row

                mediumTile_xs: 12, //1 per row
                mediumTile_sm: 12, //1 per row
                mediumTile_md: 4,// 3 per row

                largeTile_xs: 12, //1 per row
                largeTile_sm: 12, //1 per row
                largeTile_md: 4 // 3 per row
            },
            newTiles: [],
            selTile: blankTile(),
        };
    }

    componentDidMount(): void {

        //adjust grid layouts
        if (this.props.group.tiles.length < 4) {
            this.setState(
                {
                    layout: {
                        smallTile_xs: 12, //1 per row
                        smallTile_sm: 6, // 2 per row
                        smallTile_md: 12 / this.props.group.tiles.length, // 3 per row

                        mediumTile_xs: 12, //1 per row
                        mediumTile_sm: 12, //1 per row
                        mediumTile_md: 4,// 3 per row

                        largeTile_xs: 12, //1 per row
                        largeTile_sm: 12, //1 per row
                        largeTile_md: 4 // 3 per row
                    }
                }
            )
        }

    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.group.expand != this.props.group.expand) {
            this.setState({ open: this.props.group.expand });
        }
    }

    toggleModal = (): void => {

        const newTiles = this.props.tileLibrary.filter((t) => !this.props.usedTiles.includes(t.tileName));

        this.setState({
            modal: !this.state.modal,
            newTiles: newTiles
        });
    }
    newTileNameChanged = (event: ChangeEvent): void => {
        if (event.target) {
            this.setState({
                newTileName: (event.target as HTMLInputElement).value
            });
        }
    }

    toggleEdit = (group: string): void => {
        this.setState({
            newTileName: group,
            editModal: !this.state.editModal
        });
    }

    toggleExpand = (): void => {
        if (this.props.edit && this.props.onGroupChange) {
            this.props.onGroupChange(this.props.group.groupName, this.props.group.groupName, 0, !this.state.open);
        }
        this.setState({
            open: !this.state.open
        });
    }


    handleTileChange = (event: MouseEvent): void => {
        const tile = this.props.tileLibrary.filter((t) => { return t.tileName == (event.target as HTMLButtonElement).value; });
        this.setState({
            selTile: tile[0]
        });
    }

    editGroup = (): void => {
        if (this.props.onGroupChange) {
            this.props.onGroupChange(this.props.group.groupName, this.state.newTileName, 0, this.state.open);
        }
        this.toggleEdit("");
    }

    moveGroup = (by: number | null): void => {
        if (this.props.onGroupChange) {
            this.props.onGroupChange(this.props.group.groupName, this.props.group.groupName, by, this.state.open);
        }

    }

    deleteTile = (tile: TileModel): void => {
        console.log(tile)
        const filteredTiles = this.state.group.tiles.filter(function (value) { return value.title !== tile.title });
        const newGroup = this.state.group;
        newGroup.tiles = filteredTiles
        this.setState({
            group: newGroup
        });

        // Update DashboardLayout
        if (this.props.onGroupUpdate) {
            this.props.onGroupUpdate(newGroup.groupName, newGroup.tiles);
        }
    }

    addTile = (): void => {

        const tileNew = this.state.selTile;
        let lastPos = 0;
        
        const newGroup = this.state.group;
        newGroup.tiles.map((t) => {
            if (t.position > lastPos) {
                lastPos = t.position;
            }
        });

        tileNew.position = lastPos + 1;
        newGroup.tiles.push(tileNew)
        this.setState({
            modal: !this.state.modal,
            group: newGroup,
            selTile: blankTile(),
        });

        // Update DashboardLayout
        if (this.props.onGroupUpdate) {
            this.props.onGroupUpdate(newGroup.groupName, newGroup.tiles);
        }


    }

    render(): ReactNode {
        
        const { classes } = this.props;
        const me = this;

        return (
            <div >

                <Modal
                    style={{ backgroundColor: 'transparent' }}
                    show={this.state.modal}
                    onHide={(): void => this.toggleModal()}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            New Tile
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Container>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Select
                                        label="Select Tile"
                                        value={this.state.selTile.tileName}
                                        onChange={this.handleTileChange.bind(this)} >

                                        <MenuItem value={"0"}>Select a tile</MenuItem>
                                        {
                                            this.state.newTiles.map((tile) => {
                                                return <MenuItem key={tile.tileName} value={tile.tileName}>{tile.title}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>
                            {this.state.selTile.tileName != "0" &&
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div>
                                        {this.state.selTile.description}
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="primary" variant="contained" style={{ marginRight: "10px" }}  onClick={(): void => this.addTile()}>Save</Button>
                        <Button color="secondary" variant="contained" onClick={(): void => this.toggleModal()}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    style={{ backgroundColor: 'transparent' }}
                    show={this.state.editModal}
                    onHide={(): void => this.toggleEdit(this.props.group.groupName)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Edit Group Title
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Container>
                            <Row>
                                <Col xs={6} md={4}>
                                    <TextField
                                        id="outlined-input"
                                        label="Name"
                                        defaultValue={this.state.newTileName}
                                        variant="outlined"
                                        onChange={this.newTileNameChanged}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="primary" variant="contained" style={{marginRight: "10px"}} onClick={(): void => this.editGroup()}>Save</Button>
                        <Button color="secondary" variant="contained" onClick={(): void => this.toggleEdit(this.props.group.groupName)}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <Card className={classes.cardDashboard} >

                    <CardHeader color={'primary'} className="view view-cascade  d-flex justify-content-between align-items-center py-0 mx-4 mb-3">
                        <h4 className={classes.cardTitleWhite}>{this.props.group.groupName}</h4>
                        {this.props.edit &&
                            <Tooltip title="Edit Group Title">
                                <IconButton
                                    aria-label="edit"
                                    onClick={(): void => this.toggleEdit(this.props.group.groupName)}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {this.props.edit &&
                            <div>
                            <Tooltip title="Add Tile to Group">
                                <IconButton aria-label="add" onClick={(): void => this.toggleModal()} size="large">
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </Tooltip>
                            {this.props.group.position > 0 &&
                            <Tooltip title="Move Group UP">
                                <IconButton aria-label="up" onClick={(): void => this.moveGroup(-1)} size="large">
                                    <ArrowUpwardIcon />
                                </IconButton>
                                </Tooltip>
                            }
                            {this.props.group.position < (this.props.groupCount - 1) &&
                            <Tooltip title="Move Group DOWN">
                                <IconButton aria-label="down" onClick={(): void => this.moveGroup(1)} size="large">
                                    <ArrowDownwardIcon />
                                </IconButton>
                            </Tooltip>
                            }
                            {this.props.group.tiles.length == 0 &&
                            <Tooltip title="DELETE Group">
                                <IconButton
                                    aria-label="delete"
                                    onClick={(): void => this.moveGroup(null)}
                                    size="large">
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Tooltip>
                            }
                            </div>
                        }
                        <div>
                            <Tooltip title={this.state.open ? "Collapse Group" : "Expand Group"}>
                                <IconButton onClick={(): void => this.toggleExpand()} size="large">
                                    <MenuIcon />
                                </IconButton>
                            </Tooltip>
                        </div>

                    </CardHeader>

                    <Collapse isOpened={this.state.open} >
                        <Droppable droppableId={this.props.group.groupName} direction="horizontal" >
                            {(provided: DroppableProvided): any => (
                                <GridContainer innerRef={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {me.state.group.tiles.sort(function (a, b) {
                                        return a.position - b.position;
                                    }).map((tile) => {
                                        if (this.props.edit) {
                                            return (
                                                <Draggable key={tile.tileName} draggableId={tile.tileName} index={tile.position - 1} >
                                                    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot): any => (
                                                            <Tile
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                innerRef={provided.innerRef}
                                                                isDragging={snapshot.isDragging}
                                                                tile={tile}
                                                                edit={this.props.edit}
                                                                onDelete={this.deleteTile.bind(this)}
                                                                layout={this.state.layout}
                                                                onTileClick={this.props.onTileClick}
                                                            >
                                                            </Tile>

                                                        )
                                                    }
                                                </Draggable>
                                            );
                                        }
                                        else {
                                            return (
                                                <Tile
                                                    key={tile.tileName}
                                                    tile={tile}
                                                    edit={this.props.edit}
                                                    onDelete={this.deleteTile.bind(this)}
                                                    layout={this.state.layout}
                                                    onTileClick={this.props.onTileClick}
                                                />
                                            );
                                        }
                                    })
                                    }
                                    {provided.placeholder}
                                </GridContainer>
                                )
                            }
                        </Droppable>
                    </Collapse >

                </Card>
            </div >
        );
    }
}
export default withStyles(style)(TileGroup);