import React, { Component } from 'react';
import Dropzone, { DropzoneRootProps } from 'react-dropzone';
import styled from 'styled-components'

const getColor = (props: DropzoneRootProps): string => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props: DropzoneRootProps): string => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  width: 100%;
`;

interface Props {
    onChange: (acceptedFiles: Array<File>) => void;
}

class FilePicker extends Component<Props> {
    callback: (acceptedFiles: Array<File>) => void;
    constructor(props: Readonly<Props>) {
        super(props);
        this.callback = props.onChange;
    }

    onDrop = (acceptedFiles: Array<File>): void => {
        console.log(acceptedFiles);
        this.callback(acceptedFiles);
    };

    render(): React.ReactNode {
        return (
            <div className="text-center">
                <Dropzone onDrop={this.onDrop} >
                    {({ getRootProps, getInputProps, isDragActive }): any => (
                        <Container>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <p style={{color: "black"} }>Drop the file here ...........</p> :
                                        <p style={{color: "black"} }>Drag and drop a file here, or click to select file</p>
                                }
                            </div>
                        </Container>
                    )}
                </Dropzone>
            </div>
        );
    }
}

export default FilePicker