import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import { withStyles, createStyles } from '@mui/styles';
const style = createStyles(styles);

export class SimpleTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { classes } = this.props;

        return (
            <div >
                <Card>
                    <CardHeader  stats icon>

                        <CardIcon color={this.props.color}>
                            {this.props.icon} 
                        </CardIcon>

                        <p className={classes.cardCategory}>{this.props.title}</p>
                        <h3 className={classes.cardTitle}>
                            {this.props.content}
                             
                        </h3>
                    </CardHeader>
                    <CardFooter stats>
                        <div className={classes.stats}>
                            {this.props.footerIcon}
                            {this.props.footerContent}
                        </div>
                    </CardFooter>
                </Card>
            </div>
        );
    
    }
}
export default withStyles(style)(SimpleTile);