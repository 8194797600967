export interface FlowMultiplier {
    factor: number;
    units: string;
}

export const decodeFlowMultiplier = (value: number | null): FlowMultiplier => {
    switch (value) {
        case 0x00:
            return { factor: 0, units: "" };
        case 0x01:
            return { factor: 0.0001, units: "" };
        case 0x02:
            return { factor: 0.0005, units: "" };
        case 0x03:
            return { factor: 0.001, units: "" };
        case 0x04:
            return { factor: 0.0025, units: "" };
        case 0x05:
            return { factor: 0.005, units: "" };
        case 0x06:
            return { factor: 0.01, units: "" };
        case 0x07:
            return { factor: 0.025, units: "" };
        case 0x08:
            return { factor: 0.05, units: "" };
        case 0x09:
            return { factor: 0.1, units: "" };
        case 0x0A:
            return { factor: 0.25, units: "" };
        case 0x0B:
            return { factor: 0.5, units: "" };
        case 0x0C:
            return { factor: 1, units: "" };
        case 0x0D:
            return { factor: 2.5, units: "" };
        case 0x0E:
            return { factor: 5, units: "" };
        case 0x0F:
            return { factor: 10, units: "" };
        case 0x10:
            return { factor: 0, units: "m3" };
        case 0x11:
            return { factor: 0.0001, units: "m3" };
        case 0x12:
            return { factor: 0.0005, units: "m3" };
        case 0x13:
            return { factor: 0.001, units: "m3" };
        case 0x14:
            return { factor: 0.0025, units: "m3" };
        case 0x15:
            return { factor: 0.005, units: "m3" };
        case 0x16:
            return { factor: 0.01, units: "m3" };
        case 0x17:
            return { factor: 0.025, units: "m3" };
        case 0x18:
            return { factor: 0.05, units: "m3" };
        case 0x19:
            return { factor: 0.1, units: "m3" };
        case 0x1A:
            return { factor: 0.25, units: "m3" };
        case 0x1B:
            return { factor: 0.5, units: "m3" };
        case 0x1C:
            return { factor: 1, units: "m3" };
        case 0x1D:
            return { factor: 2.5, units: "m3" };
        case 0x1E:
            return { factor: 5, units: "m3" };
        case 0x1F:
            return { factor: 10, units: "m3" };
        case 0x20:
            return { factor: 0, units: "litre" };
        case 0x21:
            return { factor: 0.1, units: "litre" };
        case 0x22:
            return { factor: 0.5, units: "litre" };
        case 0x23:
            return { factor: 1, units: "litre" };
        case 0x24:
            return { factor: 2.5, units: "litre" };
        case 0x25:
            return { factor: 5, units: "litre" };
        case 0x26:
            return { factor: 10, units: "litre" };
        case 0x27:
            return { factor: 25, units: "litre" };
        case 0x28:
            return { factor: 50, units: "litre" };
        case 0x29:
            return { factor: 100, units: "litre" };
        case 0x2A:
            return { factor: 250, units: "litre" };
        case 0x2B:
            return { factor: 500, units: "litre" };
        case 0x2C:
            return { factor: 1000, units: "litre" };
        case 0x2D:
            return { factor: 2500, units: "litre" };
        case 0x2E:
            return { factor: 5000, units: "litre" };
        case 0x2F:
            return { factor: 10000, units: "litre" };        
        case 0x30:
            return { factor: 0, units: "mm" };
        case 0x31:
            return { factor: 0.05, units: "mm" };
        case 0x32:
            return { factor: 0.1, units: "mm" };
        case 0x33:
            return { factor: 0.15, units: "mm" };
        case 0x34:
            return { factor: 0.2, units: "mm" };
        case 0x35:
            return { factor: 0.25, units: "mm" };
        case 0x36:
            return { factor: 0.3, units: "mm" };
        case 0x37:
            return { factor: 0.35, units: "mm" };
        case 0x38:
            return { factor: 0.4, units: "mm" };
        case 0x39:
            return { factor: 0.45, units: "mm" };
        case 0x3A:
            return { factor: 0.5, units: "mm" };
        case 0x3B:
            return { factor: 0.55, units: "mm" };
        case 0x3C:
            return { factor: 0.6, units: "mm" };
        case 0x3D:
            return { factor: 0.65, units: "mm" };
        case 0x3E:
            return { factor: 0.7, units: "mm" };
        case 0x3F:
            return { factor: 0.75, units: "mm" };
    }

    return { factor: 1, units: "??" };
}