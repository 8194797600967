import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { APIGetLoggerAlarmsModel, buildAPIGetLoggerAlarmsModel } from "models/APIGetLoggerAlarmsModel";

// DevExtreme
import { DataGrid, Column, Export, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';

import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import dxDataGrid, { dxDataGridRowObject } from "devextreme/ui/data_grid";

const styles: Record<string, any> = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        display: "inline",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Props {
    classes: {
        cardTitleWhite: string;
        cardCategoryWhite: string;
    };
    history: {
        showBackButton: boolean;      
        goBack: () => void;
    };
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    alarms: APIGetLoggerAlarmsModel[];
    tempAlarm: APIGetLoggerAlarmsModel | undefined;
    anchorEl: EventTarget & HTMLButtonElement | null;
    redirect: boolean;
    redirectPath: string | undefined;
    redirectProps: { serial: number | undefined } | undefined;
}

class AlarmList extends React.Component<Props, State> {

    gridRef: React.RefObject<DataGrid>;

    constructor(props: Props | Readonly<Props>) {
        super(props);
        // Ref to Datagrid to store Page, Sort & Filters
        this.gridRef = React.createRef();

        this.state = {
            tableHidden: false,
            visible: false,
            alarms: [],
            tempAlarm: undefined,
            anchorEl: null,
            loading: true,
            authorized: true,
            redirect: false,
            redirectPath: undefined,
            redirectProps: undefined
        };

    }
    get dataGrid(): dxDataGrid | undefined {
        return this.gridRef.current?.instance;
    }

    componentDidMount(): void {
        let alarmData = new Array<APIGetLoggerAlarmsModel>();

        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/ActiveAlarms?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data?.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    alarmData = buildAPIGetLoggerAlarmsModel(data);

                    me.setState(
                        {
                            alarms: alarmData,
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            alarms: [],
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function (ex) {
                console.log(ex);
                me.setState(
                    {
                        authorized: false
                    })
            });

    }
    handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: dxDataGridRowObject): void {   //React.MouseEvent<HTMLButtonElement, MouseEvent>
        const alarm = this.state.alarms.find((row) => row.id === id.key);

        this.setState(
            {
                tempAlarm: alarm,
                anchorEl: event.currentTarget
            });
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }


    alarmDetailsClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/DeviceDetail',
                redirectProps: {
                            serial: this.state.tempAlarm?.loggerSerial
                        }
            });
    }

    alarmOverviewClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/DeviceOverview',
                redirectProps: {
                    serial: this.state.tempAlarm?.loggerSerial
                }
            });
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm:ss");
    }

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Readings');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), window.location.hostname.includes('zonelog.net') ? 'ZONELOG Export.xlsx' : 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    moreRender(key: dxDataGridRowObject): ReactNode {
        return (
            <IconButton size="small" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => { this.handleClick(e, key); }}><MoreHorizIcon /></IconButton>
        );
    }

    render(): ReactNode {
        const { classes, history } = this.props;

        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                 (!this.state.redirect) ?
                (<div>

                    {this.state.loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    }
                    {!this.state.loading &&
                        <div>

                            {this.state.tableHidden &&

                                    <ApiFailed />
                            }
                            {!this.state.tableHidden &&
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                        <CardHeader color="primary">
                                            <div style={{ display: 'inline' }}>

                                              {this.props.history.showBackButton &&
                                              <IconButton
                                                  style={{ display: 'inline' }}
                                                  onClick={(): void => this.props.history.goBack()}
                                                  size="large"><ArrowBackIcon style={{ fill: "white" }} /></IconButton>
                                              }
                                              <h4 className={classes.cardTitleWhite}>Active Alarms</h4>
                                            </div>
                                            <p className={classes.cardCategoryWhite}>
                                                Active alarms identified with the server
                                            </p>
                                        </CardHeader>
                                        <CardBody>
                                            <DataGrid
                                            dataSource={this.state.alarms}
                                            keyExpr="id"
                                            ref={this.gridRef}
                                            onExporting={this.onExporting} >
                                            <StateStoring enabled={true} type="localStorage" storageKey="alarmListGrid" />
                                            <Export enabled={true} />
                                            <GroupPanel visible={true} />
                                            <Grouping autoExpandAll={true} />
                                            <FilterRow visible={true} />
                                            <Column dataField="id" visible={false} dataType="number" />
                                            <Column dataField="alarmMask" visible={false} dataType="number" />
                                            <Column dataField="siteName" groupIndex={0} />
                                            <Column dataField="loggerSerial" />
                                            <Column dataField="alarmMessage" dataType="string" />
                                            <Column dataField="dateCreated" customizeText={this.dateColumnCustomizeText} dataType="datetime" />
                                            <Column dataField="dateRecorded" customizeText={this.dateColumnCustomizeText} dataType="datetime" />
                                            <Column dataField="dateCleared" customizeText={this.dateColumnCustomizeText} dataType="datetime" />
                                            <Column dataField="source" dataType="string" />
                                            <Column dataField="cleared" dataType="string" />
                                            <Column dataField="alarmState" dataType="number" />

                                            <Column type="buttons" cellRender={this.moreRender.bind(this)} fixed={true} fixedPosition="right" />

                                            <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                            <Paging defaultPageSize={10} />
                                            </DataGrid>
                                        </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            }
                        </div>
                    }
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                    >
                        <MenuItem onClick={this.alarmOverviewClick}>Overview</MenuItem>
                        <MenuItem onClick={this.alarmDetailsClick}>Details</MenuItem>
                    </Menu>
                </div>)
                :
                 (<Redirect push to={{
                    pathname: this.state.redirectPath,
                    state: this.state.redirectProps
                }}/>)
            )
            :
            (
                <Redirect to={'/Login'} />
            );
    }
}

export default withStyles(style)(AlarmList);
