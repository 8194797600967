import React, { ChangeEvent, ReactNode } from "react";
import { Redirect } from "react-router-dom";

// @mui/material
import TileGroup from "components/Tiles/TileGroup";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Box } from '@mui/material';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';


//modal window imports
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';

import { CreateUrl, CallGetAPI, CallPutAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import { APIDashboardLayout } from "models/APIDashboardLayout";

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

// Drag n Drop
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import {
    dailyConnectionsChart,
    dailyIssuesChart,
    dailyRecordsGeneratedChart,
    hourlyConnectionsChart,
    hourlyIssuesChart,
    hourlyRecordsGeneratedChart,
} from "variables/charts";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import { Container, Row, Col } from "react-bootstrap";

import { withStyles, createStyles } from '@mui/styles';

const style = createStyles(styles as Record<string, any>);

import { DashboardModel, TileModel } from "models/DashboardModel";


interface Props {
    classes: {
        root: string;
        heading: string;
        cardTitleWhite: string;
    };
}

interface State {
    showAlarmOverlay: boolean;
    connections24hour: number;
    loggersActive24hour: number;
    loggersInactive24hour: number;
    connections7day: number;
    loggersActive7day: number;
    loggersInactive7day: number;
    firmwareUpdates: number;
    updatesPending: number;
    dormantLoggers: number;
    databaseSize: string;
    loggersInAlarm: number;
    data: string;
    totalAlarm: number;
    groups: Array<DashboardModel>;
    newGroupName: string;
    edit: boolean;
    redirect: boolean;
    redirectPath: string | undefined;
    redirectProps: string | undefined;
    groupFilter: number;
    modal: boolean;
    tileLibrary: Array<TileModel>;
    usedTiles: Array<string>;
    templates: Array<APIDashboardLayout>;
    selTemplateId: number;
    newTemplateName: string;
    enableLoad: boolean;
    enableSave: boolean;
}


export class DndDashboard extends React.Component<Props, State> {
     dashboardModel: Array<DashboardModel> = [];

    constructor(props: Readonly<Props>) {
        super(props);
        let groupFilter = window.sessionStorage.getItem("filterGroupId");
        if (groupFilter === null) {
            groupFilter = "0";
        }


        // Setup any saved dashboard layout
        const dashboardJson = sessionStorage.getItem('dashboardJson')
        if (dashboardJson && dashboardJson?.length > 0) {
            this.dashboardModel = JSON.parse(dashboardJson);
        }

        const usedTiles: Array<string> = [];
        this.dashboardModel.map((g) => {
            g.tiles.map((t) => {
                usedTiles.push(t.tileName);
            });
        });

        this.state = {
//            trunkSegments: null,
//            zones: null,
//            chambers: null,
            showAlarmOverlay: false,
            connections24hour: 0,
            loggersActive24hour: 0,
            loggersInactive24hour: 0,
            connections7day: 0,
            loggersActive7day: 0,
            loggersInactive7day: 0,
            firmwareUpdates: 0,
            updatesPending: 0,
            dormantLoggers: 0,
            databaseSize: "-",
            loggersInAlarm: 0,
            data: "-",
            totalAlarm: 0,
            groups: this.dashboardModel,
            newGroupName: 'New Group',
            edit: usedTiles.length == 0,        // Force edit mode to select template if no tiles in layout
            redirect: false,
            redirectPath: undefined,
            redirectProps: undefined,
            groupFilter: parseInt(groupFilter),
            modal: false,
            tileLibrary: [],
            usedTiles: usedTiles,
            templates: [],
            selTemplateId: 0,
            newTemplateName: "",
            enableLoad: false,
            enableSave: false,
        };
        this.handleChange = this.handleChange.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this);
        this.tileClick = this.tileClick.bind(this);


    }

    createSuccessNotification = (type: string): void => {
        if (type == "Dashboard") {
            NotificationManager.success('Saved Dashboard to User Profile', 'Success');
        }
        else {
            NotificationManager.success('Saved Dashboard as template', 'Success');
        }

    };

    createErrorNotification = (type: string): void => {
        NotificationManager.error('Error Saving ' + type, 'Click me!', 5000, () => {
            alert('callback');
        });
    };



    handleChange(): void {
        this.setState(
            {
                showAlarmOverlay: !this.state.showAlarmOverlay
            }
        )
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    newGroupNameChanged = (event: any): void => {
        this.setState({
            newGroupName: event.target.value
        });
    }

    toggleEdit = (): void => {
        this.setState({
            edit: !this.state.edit
        });
    }

    addGroup = (): void => {

        const groupsNew = this.state.groups;
        // Add new group at bottom
        groupsNew.push({
            position: this.state.groups.length,
            groupName: this.state.newGroupName,
            expand: true,
            tiles: []
        })

        this.setState({
            groups: groupsNew,
            modal: !this.state.modal
        });
    }

    tileClick(id: string): void {
        this.dashboardModel.forEach((group) => {
            group.tiles.forEach((tile) => {
                if (tile.tileName == id) {
                    if (tile.redirectPath && tile.redirectPath != "") {
                        this.setState({
                            redirect: true,
                            redirectPath: tile.redirectPath,
                            redirectProps: tile.redirectProps
                        });
                    }
                }
            });

        });
    }


    componentDidMount(): void {

        const me = this;

        const companyId = sessionStorage.getItem('companyId');
        if (companyId) {
            CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + companyId + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
                .then(data => {
                    if (data.success === true) {
                        me.setState(
                            {
//                                trunkSegments: data.trunkSegments,
//                                zones: data.zones,
//                                chambers: data.chambers,
                                connections24hour: data.connections24hour,
                                loggersActive24hour: data.loggersActive24hour,
                                loggersInactive24hour: data.loggersInactive24hour,
                                connections7day: data.connections7day,
                                loggersActive7day: data.loggersActive7day,
                                loggersInactive7day: data.loggersInactive7day,
                                databaseSize: data.databaseData.database_size,
                                data: data.databaseData.data,
                                loggersInAlarm: data.loggersInAlarm,
                                firmwareUpdates: data.firmwareUpdates,
                                updatesPending: data.updatesPending,
                                dormantLoggers: data.dormantLoggers,
                            });

                        /*
                        refreshDailyConnections();
                        refreshDailyLogRecords();
                        refreshDailyIssues();
                        refreshHourlyConnections();
                        refreshHourlyLogRecords();
                        refreshHourlyIssues();
                        */

                        // Update Tile in groupModel
                        const model = me.dashboardModel;
                        model.forEach((group) => {
                            group.tiles.forEach((tile) => {
                                if (tile.title === "Daily Connections") {
                                    tile.chartData = dailyConnectionsChart;
                                }
                                if (tile.title === "Daily records generated") {
                                    tile.chartData = dailyRecordsGeneratedChart;
                                }
                                if (tile.title === "Daily issues") {
                                    tile.chartData = dailyIssuesChart;
                                }
                                if (tile.title === "Hourly Connections") {
                                    tile.chartData = hourlyConnectionsChart;
                                }
                                if (tile.title === "Hourly records generated") {
                                    tile.chartData = hourlyRecordsGeneratedChart;
                                }
                                if (tile.title === "Hourly issues") {
                                    tile.chartData = hourlyIssuesChart;
                                }
                            });
                        });
                        me.setState({ groups: model });
                    }
                    else {
                        console.log("Error!");
                        console.log(data.statusCode);
                        console.log(data.reasonPhrase);
                    }

                })
                .catch(console.log);
        }

        // Load Tile Library
        CallGetAPI(CreateUrl('/api/Aquaguard/TileLibrary'), {})
            .then(data => {
                if (data) {
                    const tiles: Array<TileModel> = [];
                    for (let i = 0; i < data.length; i++) {
                        const rec: TileModel = {
                            tileName: data[i].tileName,
                            position: 0,
                            title: data[i].title,
                            description: data[i].description,
                            tileColor: data[i].tileColor,
                            type: data[i].type,
                            content: data[i].content,
                            refreshTime: data[i].refreshTime,
                            onRefresh: data[i].onRefresh,
                            redirectPath: data[i].redirectPath,
                            redirectProps: data[i].redirectprops
                        };
                        tiles.push(rec);
                    }

                    this.setState({ tileLibrary: tiles });

                }
                else {
                    console.log("Error!");
                    console.log(data.statusCode);
                    console.log(data.reasonPhrase);
                }

            })
            .catch((error) => console.log(error));

        // Load Layout templates
        CallGetAPI(CreateUrl('/api/Aquaguard/TemplatesForCompany?companyid=' + companyId), {})
            .then(data => {
                if (data) {
                    const templates: Array<APIDashboardLayout> = [];
                    for (let i = 0; i < data.length; i++) {
                        const rec: APIDashboardLayout = {
                            Id: data[i].id,
                            UserGuid: "",
                            TemplateName: data[i].templateName,
                            LayoutJson: data[i].layoutJson,
                            LastUpdate: data[i].lastUpdate,
                            FkCompanyId: data[i].fkCompanyId,
                        };
                        templates.push(rec);
                    }

                    this.setState({ templates: templates });

                }
                else {
                    console.log("Error!");
                    console.log(data.statusCode);
                    console.log(data.reasonPhrase);
                }

            })
            .catch((error) => console.log(error));
    }

    //     <PieChart
    //    data={[
    //        { title: 'Used: 19.44 MB', value: 23, color: '#ffffff' },
    //        { title: 'Free: 60.56 MB', value: 77, color: '#ffffff66' }
    //    ]}
    ///>

    onGroupChanged(oldName: string, newName: string, by: number, expand: boolean): void {
        const newGroups = this.state.groups;
        let conflict = false;
        for (let i = 0; i < newGroups.length; i++) {
            if (newGroups[i].groupName == oldName) {
                if (newName != oldName) {
                    newGroups[i].groupName = newName;
                }
                else {
                    if (by != null) {
                        if (by != 0) {
                            const oldPos = newGroups[i].position;
                            newGroups[i].position = oldPos + by;
                            for (let j = 0; j < newGroups.length; j++) {
                                if (i != j && newGroups[j].position == newGroups[i].position) {
                                    newGroups[j].position = oldPos;
                                }
                            }
                        }
                        newGroups[i].expand = expand;
                    }
                    else {
                        // by=null and same name => delete group
                        newGroups.splice(i, 1);
                    }
                }
            }
            else {
                if (newName != oldName &&
                    newGroups[i].groupName == newName) {
                    conflict = true;
                }
            }
        }

        if (!conflict) {
            this.setState({ groups: newGroups });
        }

    }

    onGroupUpdate(groupName: string, tiles: Array<TileModel>): void {

        const newGroups = this.state.groups;
        for (let i = 0; i < newGroups.length; i++) {
            if (newGroups[i].groupName == groupName) {
                newGroups[i].tiles = tiles;
            }
        }
        this.setState({ groups: newGroups });
    }

    onDragEnd(result: DropResult): void {
        // TODO:
        const { destination, source, draggableId } = result;

        // Dropped outside drop zones
        if (!destination) {
            return;
        }

        // Has it actually moved?
        if (destination.droppableId === source.droppableId &&
            destination.index == source.index) {
            return;
        }

        const newGroups = this.state.groups;
        if (destination.droppableId === source.droppableId) {
            // Moved within the group - just update tile position
            for (let i = 0; i < newGroups.length; i++) {
                if (newGroups[i].groupName == source.droppableId) {
                    const newTiles = newGroups[i].tiles.sort(function (a, b) {
                        return a.position - b.position;
                    });
                    const movedTile = { ...newTiles[source.index] };
                    console.log("Moving tile " + movedTile.tileName);
                    // Remove meved tile
                    newTiles.splice(source.index, 1);
                    console.log("Remove index " + source.index);
                    // Add back at right position
                    newTiles.splice(destination.index, 0, movedTile);
                    console.log("Add at " + destination.index);
                    // Now update positions
                    for (let j = 0; j < newGroups[i].tiles.length; j++) {
                        newGroups[i].tiles[j].position = j + 1;
                        console.log(newGroups[i].tiles[j].tileName);
                    }
                }
            }

        }
        else {
            // Changed group - need to move tile & update position
            let movedTile = null;
            // Get tile details
            for (let i = 0; i < newGroups.length; i++) {
                if (newGroups[i].groupName == source.droppableId) {
                    const newTiles = newGroups[i].tiles.sort(function (a, b) {
                        return a.position - b.position;
                    });
                    movedTile = { ...newTiles[source.index] };
                    console.log("Moving tile " + movedTile.tileName);
                    // Remove meved tile
                    newTiles.splice(source.index, 1);
                    console.log("Remove index " + source.index);

                    newGroups[i].tiles = newTiles;
                    // Now update positions
                    console.log("From:");
                    for (let j = 0; j < newGroups[i].tiles.length; j++) {
                        newGroups[i].tiles[j].position = j + 1;
                        console.log(newGroups[i].tiles[j].tileName);
                    }
                }
            }
            for (let i = 0; i < newGroups.length; i++) {
                if (newGroups[i].groupName == destination.droppableId) {
                    const newTiles = newGroups[i].tiles.sort(function (a, b) {
                        return a.position - b.position;
                    });
                    if (movedTile != null) {
                        // Add back at right position
                        newTiles.splice(destination.index, 0, movedTile);
                        console.log("Add at " + destination.index);
                        newGroups[i].tiles = newTiles;
                        // Now update positions
                        console.log("To:");
                        for (let j = 0; j < newGroups[i].tiles.length; j++) {
                            newGroups[i].tiles[j].position = j + 1;
                            console.log(newGroups[i].tiles[j].tileName);
                        }
                    }
                }
            }
        }

        this.setState({ groups: newGroups });

    }

    // Save new layout back to user profile & session
    saveLayout = (): void => {

        const dashboardJson = JSON.stringify(this.state.groups);
        const me = this;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userGuid: sessionStorage.getItem('userGuid'),
                layoutJson: dashboardJson,
            })
        };

        CallPostAPI(CreateUrl('/api/aquaguard/SaveDashboard'), requestOptions)
            .then(data => {
                if (data.status === 200) {
                    me.createSuccessNotification("Dashboard");
                }
                else {
                    me.createErrorNotification("Dashboard");
                }

            })

            .catch(function (error) {
                me.createErrorNotification("Dashboard");
                console.log(error);
            });

        const usedTiles: Array<string> = [];
        this.state.groups.map((g) => {
            g.tiles.map((t) => {
                usedTiles.push(t.tileName);
            });
        });

        sessionStorage.setItem('dashboardJson', dashboardJson);

        this.setState({ usedTiles: usedTiles });
        this.toggleEdit();

    }

    // Drop changes - revert to session layout
    restoreLayout = (): void => {
        // Setup any saved dashboard layout
        const dashboardJson = sessionStorage.getItem('dashboardJson');
        if (dashboardJson && dashboardJson?.length > 0) {
            this.dashboardModel = JSON.parse(dashboardJson);
        }

        const usedTiles: Array<string> = [];
        this.dashboardModel.map((g) => {
            g.tiles.map((t) => {
                usedTiles.push(t.tileName);
            });
        });

        this.setState({
           groups: this.dashboardModel,
           usedTiles: usedTiles,
           edit: !this.state.edit
        });
    }

    ///   Layout Templates
    handleTemplateChange = (event: ChangeEvent): void => {

        const newId = parseInt((event.target as HTMLSelectElement).value);
        this.setState({
            selTemplateId: newId,
            enableLoad: newId != 0,
            enableSave: newId > 1 || this.state.newTemplateName.length > 0
        });
    }

    loadTemplate = (): void => {
        if (this.state.selTemplateId > 0) {
            const template = this.state.templates.filter(t => t.Id == this.state.selTemplateId)[0];

            const layout = JSON.parse(template.LayoutJson);
            this.dashboardModel = layout;

            const usedTiles: Array<string> = [];
            this.dashboardModel.map((g) => {
                g.tiles.map((t) => {
                    usedTiles.push(t.tileName);
                });
            });


            this.setState({
                groups: layout,
                usedTiles: usedTiles,
            });
        }
    }

    saveTemplate = (): void => {

        let template: APIDashboardLayout;
        if (this.state.selTemplateId > 0) {
            // Update existing template
            template = this.state.templates.filter(t => t.Id == this.state.selTemplateId)[0];
            template.LayoutJson = JSON.stringify(this.state.groups);
        }
        else {
            // Create new template
            template = {
                Id: 0,         // Id for use on layout template updates
                UserGuid: "",   // Used when saving layout in User profile
                FkCompanyId: sessionStorage.getItem('companyId') == null ? null : parseInt(sessionStorage.getItem('companyId') || "0"),  // Used for Layout Templates
                TemplateName: this.state.newTemplateName,    // Used for Layout Templates
                LayoutJson: JSON.stringify(this.state.groups),   // JSON definition of Layout
                LastUpdate: new Date(),

            }
        }

        //********************
        //send model to API
        const me = this;

        const requestOptions = {
            method: this.state.selTemplateId > 0 ? 'PUT' : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(template),
        };

        if (this.state.selTemplateId == 0) {
            // New template
            CallGetAPI(CreateUrl('/api/aquaguard/LayoutTemplate'), requestOptions)
                .then(data => {
                    if (data.status === undefined) {
                        const templates = me.state.templates;
                        template.Id = data.id;
                        templates.push(template);
                        this.setState({ templates: templates });

                        me.createSuccessNotification("Dashboard Template");
                    }
                    else {
                        me.createErrorNotification("Dashboard Template");
                        console.log(data.title);
                    }
                })

                .catch(function (error) {
                    me.createErrorNotification("Dashboard Template");
                    console.log(error);
                });
        }
        else {
            // Update existing template
            CallPutAPI(CreateUrl('/api/aquaguard/LayoutTemplate?Id=' + this.state.selTemplateId), requestOptions)
                .then(data => {
                    if (data.status === 204) {

                        const templates: Array<APIDashboardLayout> = [];
                        me.state.templates.map((t) => {
                            if (t.Id != me.state.selTemplateId) {
                                templates.push(t);
                            }
                            else {
                                templates.push(template);
                            }
                        })
                            
                        this.setState({ templates: templates });

                        me.createSuccessNotification("Dashboard Template");
                    }
                    else {
                        me.createErrorNotification("Dashboard Template");
                        console.log(data.title);
                    }
                })

                .catch(function (error) {
                    me.createErrorNotification("Dashboard Template");
                    console.log(error);
                });
        }

    }

    newTemplateChanged = (event: any): void => {
        this.setState({
            newTemplateName: event.target.value,
            enableSave: this.state.selTemplateId > 1 || event.target.value.length > 0
        });
    }

    render(): ReactNode {
       

        return (!this.state.redirect) ?
            (<div >
            {!this.state.edit &&
                <div>
                    <IconButton aria-label="edit" onClick={(): void => this.toggleEdit()} size="large">
                        <EditIcon />
                    </IconButton>
                    {this.state.groupFilter > 0 &&
                        <h2 style={{ display: "inline" }}>
                            All values filtered to sites in group {window.sessionStorage.getItem("filterGroupName")}
                        </h2>
                    }
                </div>
            }
            {this.state.edit &&
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6} >
                            <div>
                            You can drag and drop tiles within and between groups.<br />
                            Add tiles using the + icon in group header. Remove tile using the bin icon.<br />
                            The state of each group collapse/expand will also be saved with the layout.<br />
                            The layout will be saved on your User profile on exit.
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} >
                            <Box border={1} padding={2}>
                                <h5>Layout templates</h5>
                                <div>
                                    Saved Layout Templates are available to any users to load.
                                </div>
                                Load from template:&nbsp;
                               <Select
                                    label="Select Template"
                                    value={this.state.selTemplateId}
                                    onChange={this.handleTemplateChange.bind(this)} >

                                    <MenuItem value={"0"}>Select a template</MenuItem>
                                    {
                                        this.state.templates.map((template) => {
                                            return <MenuItem key={template.Id} value={template.Id}>{template.TemplateName}</MenuItem>;
                                        })
                                    }
                                </Select>
                                <Button color="primary" onClick={(): void => this.loadTemplate()} disabled={!this.state.enableLoad}>Load</Button>
                                {sessionStorage.getItem("userLevel") == "useradmin" &&
                                <div>
                                    <hr />
                                    Save as template:&nbsp;
                                   <Select
                                        label="Save Template"
                                        value={this.state.selTemplateId}
                                        onChange={this.handleTemplateChange.bind(this)} >

                                        <MenuItem value={"0"}>New Template</MenuItem>
                                        {
                                            this.state.templates.map((template) => {
                                                if (template.TemplateName != "Default Layout") {
                                                    return <MenuItem key={template.Id} value={template.Id}>{template.TemplateName}</MenuItem>;
                                                }
                                                else {
                                                    return null;
                                                }
                                            })
                                        }
                                    </Select>
                                    {this.state.selTemplateId == 0 &&
                                        <div style={{ marginTop: "6px" }}>
                                        <TextField
                                            id="outlined-input-template"
                                            label="Template Name"
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.newTemplateChanged}
                                        />
                                        </div>

                                    }
                                    <Button color="primary" onClick={(): void => this.saveTemplate()} disabled={!this.state.enableSave}>Save</Button>
                                </div>
                                }
                            </Box>
                        </GridItem>
                    </GridContainer>
                    <Tooltip title="Add Group">
                        <IconButton aria-label="add" onClick={(): void => this.toggleModal()} size="large">
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Save Layout">
                        <IconButton aria-label="save" onClick={(): void => this.saveLayout()} size="large">
                            <DoneIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Abandon layout updates">
                        <IconButton
                            aria-label="cancel"
                            onClick={(): void => this.restoreLayout()}
                            size="large">
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            }
            <Modal
                style={{ backgroundColor: 'transparent' }}
                show={this.state.modal}
                    onHide={(): void => this.toggleModal()}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        New Group
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col xs={6} md={4}>
                                <TextField
                                    id="outlined-input"
                                    label="Name"
                                    defaultValue="New Group"
                                    variant="outlined"
                                    onChange={this.newGroupNameChanged}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                        <Button color="primary" onClick={(): void => this.addGroup()}>Save</Button>
                        <Button color="secondary" onClick={(): void => this.toggleModal()}>Cancel</Button>
                </Modal.Footer>
            </Modal>

                <DragDropContext onDragEnd={this.onDragEnd} >
                    {this.state.groups.sort(function (a, b) {
                        return a.position - b.position;
                    }).map((groupModel) => {
                        return (<TileGroup
                            group={groupModel}
                            edit={this.state.edit}
                            key={groupModel.groupName}
                            onGroupChange={this.onGroupChanged.bind(this)}
                            onGroupUpdate={this.onGroupUpdate.bind(this)}
                            groupCount={this.state.groups.length}
                            onTileClick={this.tileClick}
                            tileLibrary={this.state.tileLibrary}
                            usedTiles={this.state.usedTiles}
                        />)
                    })}
                </DragDropContext>

                <NotificationContainer />
            </div>)
            :
            (<Redirect push to={{
                pathname: this.state.redirectPath,
                state: this.state.redirectProps
            }} />);
    }
}
export default withStyles(style)(DndDashboard);