import React, { ReactNode } from "react";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { AppBar, Box, FormControl, InputLabel, Switch, Toolbar, Typography, Checkbox } from '@mui/material';
import { Select } from '@mui/material';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


//import cardStyles from "assets/jss/material-dashboard-react/components/customTabsStyle.js";

//Moment date/time formatting
//https://momentjs.com/docs/
import moment, { Moment } from 'moment';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import NumericInput from 'react-numeric-input';
import Modal from 'react-bootstrap/Modal'

import { NotificationContainer, NotificationManager } from 'react-notifications';

import { withStyles, createStyles } from '@mui/styles';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import { CreateUrl, CallGetAPI, CallPutAPI, CallPostAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import { APIPhoneNumber } from "models/APIPhoneNumber"
import { APIGprsDetail } from "models/APIGprsDetail"
import { APISimDetail } from "models/APISimDetail"
import { APIConfigTemplate } from "models/APIConfigTemplate"
import { DeviceConfig, DeviceAlarm, configFromJson, jsonFromConfig, ModbusEntry } from "models/DeviceConfig"

import SampleRate from './SampleRate';
import WindowPeriod from './WindowPeriod';
import WebMapView from '../Map/WebMapView';

//DevExtreme data grid
import 'devextreme/dist/css/dx.light.css';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, Editing, Selection } from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';


const style = createStyles(styles as Record<string, any>);


interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serial: string;
    product: string;
    model: string;
    onConfigSend?: (deviceConfig: string | void) => void;
}

interface State {
    orgConfig?: DeviceConfig;
    deviceConfig?: DeviceConfig;
    ready: boolean;
    openViewMenu: boolean;
    openTemplateMenu: boolean;
    enableSend: boolean;
    anchorEl: Element | null;
    panel: string;
    simDetails: DataSource;
    gprsDetails: DataSource;
    phoneNumbers: DataSource;
    templates: Array<APIConfigTemplate>;
    simEdit: number | null;
    gprsEdit: number | null;
    phoneEdit: number | null;
    templateName: string;
    templateSelect: number;
    templateNameError: boolean;
    templateNameErrorMsg: string;
    smsTxTime: Date;
    meterConfig: number;
    channelsAvail: number;
    configDate?: Date;
    battery?: number;
    editAlarm?: DeviceAlarm;
    alarmTab: number;
    loggerHold: boolean;
    sendAfter: Date | null;
    modbusModal: boolean;
    modbusModalTitle: string;
    tempModbus: ModbusEntry | undefined;
    companyId: number;
    loading: boolean;
    decimalPlaces: {
        c: number,
        d: number,
        e: number
    }
}


class ToolkitTOR extends React.Component<Props, State> {
    notificationSystem = React.createRef();

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            orgConfig: undefined,
            deviceConfig: undefined,
            ready: false,
            openViewMenu: false,
            openTemplateMenu: false,
            enableSend: false,
            anchorEl: null,
            panel: "Config",
            simDetails: new DataSource({ store: { type: "array", key: "id", data: [] } }),
            gprsDetails: new DataSource({ store: { type: "array", key: "id", data: [] } }),
            phoneNumbers: new DataSource({ store: { type: "array", key: "id", data: [] } }),
            templates: [],
            simEdit: null,
            gprsEdit: null,
            phoneEdit: null,
            templateName: "",
            templateSelect: 0,
            templateNameError: false,
            templateNameErrorMsg: "",
            smsTxTime: new Date(),
            meterConfig: 0,
            channelsAvail: 0,
            configDate: undefined,
            battery: undefined,
            editAlarm: undefined,
            alarmTab: 0,
            loggerHold: false,
            sendAfter: null,
            modbusModal: false,
            modbusModalTitle: "",
            tempModbus: undefined,
            companyId: 0,
            loading: false,
            decimalPlaces: {c: 0, d: 0, e: 0}
            
        };

    }

    componentDidMount(): void {

        //get SimDetails, GprsDetails & PhoneNumbers
        this.loadConfigTemplates();
        this.loadSimDetails();
        this.loadGprsDetails();
        this.loadPhoneDetails();
    }

    loadSimDetails(): void {
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/SimDetailsForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {
                if (data.length > 0) {
                    const store = new ArrayStore(
                        {
                            key: "id",
                            data: data as APISimDetail[]
                        });

                    me.setState(
                        {
                            simDetails: new DataSource({ store: store }),
                        });
                }
                else {
                    console.log("No simDetails");
                }
            },
                // reject() - API error
                () => {
                    me.createLoadErrorNotification();
                    console.log("API Error");

                })
            .catch(function () {
                me.createLoadErrorNotification();
            });
    }

    loadGprsDetails(): void {

        const me = this;
        CallGetAPI(CreateUrl('/api/aquaguard/GprsDetailsForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {
                if (data.length > 0) {
                    const store = new ArrayStore(
                        {
                            key: "id",
                            data: data as APIGprsDetail[]
                        });

                    me.setState(
                        {
                            gprsDetails: new DataSource({ store: store }),
                        });
                }
                else {
                    console.log("No gprsDetails");
                }
            },
                // reject() - API error
                () => {
                    me.createLoadErrorNotification();
                    console.log("API Error");

                })
            .catch(function () {
                me.createLoadErrorNotification();
            });
    }

    loadPhoneDetails(): void {
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/PhoneNumbersForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {
                if (data.length > 0) {
                    const store = new ArrayStore(
                        {
                            key: "id",
                            data: data as APIPhoneNumber[]
                        });

                    me.setState(
                        {
                            phoneNumbers: new DataSource({ store: store }),
                        });
                }
                else {
                    console.log("No phonenumbers");
                }
            },
                // reject() - API error
                () => {
                    me.createLoadErrorNotification();
                    console.log("API Error");

                })
            .catch(function () {
                me.createLoadErrorNotification();
            });

    }

    loadConfigTemplates(): void {
        const me = this;
        CallGetAPI(CreateUrl('/api/aquaguard/ConfigTemplatesForCompany?companyId=' + sessionStorage.getItem('companyId') + '&product=' + this.props.product + '&model=' + this.props.model), {})
            .then(data => {
                if (data.length > 0) {

                    me.setState(
                        {
                            templates: data as APIConfigTemplate[],
                        });
                }
                else {
                    console.log("No config templates");
                }
            },
                // reject() - API error
                () => {
                    me.createLoadErrorNotification();
                    console.log("API Error");

                })
            .catch(function () {
                me.createLoadErrorNotification();
            });
    }

    createSuccessNotification = (msg: string): void => {
        NotificationManager.success(msg, 'Success');
 
    };

    createErrorNotification = (type: string): void => {
        NotificationManager.error('Error Saving ' + type, 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createLoadErrorNotification = (): void => {
        NotificationManager.error('Error Loading config data ', 'Click me!', 5000, () => {
            alert('callback');
        });
    };


    //save changes, for both edit and new
    saveConfig = (): void => {

        //********************
        //send config update to API

        const me = this;

        const updateJson = jsonFromConfig(this.state.simDetails.store(),
                                        this.state.gprsDetails.store(),
                                        this.state.phoneNumbers.store(),
                                        this.state.orgConfig,
                                        this.state.deviceConfig
                                        );
        if (updateJson != "{}") {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ serial: me.props.serial, updateJson: updateJson, sendConfigAfter: this.state.sendAfter, isChariot: false })
            };

            CallPostAPI(CreateUrl('/api/Aquaguard/LoggerConfigurations'), requestOptions)
                .then(response => {
                    if (response.ok) {
                        me.createSuccessNotification('Device will be updated on next connection');
                    }
                    else {
                        me.createErrorNotification('Error Saving Config updates');
                    }
                    console.log(response);
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Config updates');
                    console.log(error);
                });
        }

    }

    // Menu click handlers

    handleReadClick = (): void => {

        const me = this;
        me.setState({loading: true})
        CallGetAPI(CreateUrl('/api/aquaguard/CurrentLoggerConfiguration?serial=' + this.props.serial), {})
            .then(data => {
                if (data.status === undefined) {
                    const config = configFromJson(this.props.product, this.props.model, data.channelsAvail,
                        data,
                        me.state.simDetails.store(),
                        me.state.gprsDetails.store(),
                        me.state.phoneNumbers.store(),
                        this.newSimDetails,
                        this.newGprsDetails,
                        this.newPhoneDetails
                    );

                    console.log(config)
                    
                    this.createDPforOffset('c', config.AnalogueCDp)
                    this.createDPforOffset('d', config.AnalogueDDp)
                    this.createDPforOffset('e', config.AnalogueEDp)
                    
                    me.setState(
                        {
                            orgConfig: JSON.parse(JSON.stringify(config)),   // Save DEEP CLONE of original
                            deviceConfig: config,
                            anchorEl: null,
                            ready: true,
                            enableSend: false,
                            meterConfig: config.MeterConfig,
                            channelsAvail: data.channelsAvail,
                            configDate: data.startTime,
                            battery: data.readBatteryChannel / 1000,
                            companyId: data.companyId,
                            loading: false
                        });

                }
            },
                // reject() - API error
                () => {
                    console.log("API Error");
                    this.setState({loading: false})
                })
            .catch(function (error) {
                console.log(error);
                me.setState({ loading: false })
            });

    }

    createDPforOffset = (channel: string, value: number | null): void => {
        const decimalPlaces = this.state.decimalPlaces
        if (value) {
            
            if (channel === "c") {
                decimalPlaces.c = Math.pow(10, -value);
            }
            if (channel === "d") {
                decimalPlaces.d = Math.pow(10, -value);
            }
            if (channel === "e") {
                decimalPlaces.e = Math.pow(10, -value);
            }
        }
        this.setState({decimalPlaces: decimalPlaces})
    }

    handleSendClick = (): void => {
        
            this.setState({ panel: "Save Config" })
        
    }

    handleSendAfterClick = (): void => {
        if (this.state.deviceConfig) {
            if (this.props.onConfigSend ) {
                this.props.onConfigSend(jsonFromConfig(this.state.simDetails.store(),
                    this.state.gprsDetails.store(),
                    this.state.phoneNumbers.store(),
                    this.state.orgConfig,
                    this.state.deviceConfig,
                ));
            }
            else {
                this.saveConfig();
            }
        }
        this.setState({ enableSend: false, panel: "Config" });
    }

    // Config Templates
    handleLoadTemplate = (): void => {

        const template = this.state.templates.filter((t) => t.id == this.state.templateSelect)[0];
        const deviceConfig: DeviceConfig = JSON.parse(template.configJson);

        if (this.state.deviceConfig) {
            deviceConfig.Sitename = this.state.deviceConfig.Sitename;
            deviceConfig.Latitude = this.state.deviceConfig.Latitude;
            deviceConfig.Longitude = this.state.deviceConfig.Longitude;
            deviceConfig.CurrentState = this.state.deviceConfig.CurrentState;
            deviceConfig.Guid = this.state.deviceConfig.Guid;
            deviceConfig.Battery = this.state.deviceConfig.Battery;
            deviceConfig.SWversion = this.state.deviceConfig.SWversion;
            deviceConfig.ServerTime = this.state.deviceConfig.ServerTime;
            deviceConfig.DeviceTime = this.state.deviceConfig.DeviceTime;
            deviceConfig.ModemIMEI = this.state.deviceConfig.ModemIMEI;
            deviceConfig.ModemType = this.state.deviceConfig.ModemType;
        }
        else {
            deviceConfig.Sitename = "";
            deviceConfig.Latitude = null;
            deviceConfig.Longitude = null;
            deviceConfig.CurrentState = "Unknown";
            deviceConfig.Guid = "";
            deviceConfig.Battery = null;
            deviceConfig.SWversion = "";
            deviceConfig.ServerTime = null;
            deviceConfig.DeviceTime = null;
            deviceConfig.ModemIMEI = "";
            deviceConfig.ModemType = "Unknown";

        }

        this.setState({
            deviceConfig: deviceConfig,
            anchorEl: null,
            ready: true,
            enableSend: false,
            meterConfig: deviceConfig.MeterConfig,
            channelsAvail: deviceConfig.ChannelsAvail,
            configDate: new Date(),
            battery: 0,
        });

        this.handleTemplateMenuClose();
    }

    handleSaveTemplate = (): void => {
        const me = this;
        const existing = this.state.templates.filter((t) => t.name == this.state.templateName);

        if (existing.length == 0) {
            let company = parseInt(sessionStorage.getItem('companyId') || "0");
            if (company == 0) {
                // Superadmin - get company from logger
                company = this.state.companyId;
            }
            const template: APIConfigTemplate = {
                id: 0,
                fkCompanyId: company,
                name: this.state.templateName,
                product: this.props.product,
                model: this.props.model,
                lastUpdate: new Date(),
                configJson: JSON.stringify(this.state.deviceConfig)
            };
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(template)
            };

            CallGetAPI(CreateUrl('/api/Aquaguard/ConfigTemplate'), requestOptions)
                .then(data => {
                    if (data.status === undefined) {
                        template.id = data.id;
                        template.lastUpdate = data.lastUpdate;
                        const templates = me.state.templates;
                        templates.push(template);
                        me.setState({ templates: templates, templateSelect: template.id });

                        me.createSuccessNotification("Config template saved");
                    }
                    else {
                        me.createErrorNotification('Error Saving Config template Changes');
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Config template Changes');
                    console.log(error);
                });
        }
        else {
            // Update existing template
            const template = existing[0];
            template.configJson = JSON.stringify(this.state.deviceConfig);

            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(template)
            };

            CallGetAPI(CreateUrl('/api/Aquaguard/ConfigTemplate?id=' + template.id), requestOptions)
                .then(data => {
                    if (data.status === undefined) {
                        template.lastUpdate = data.lastUpdate;
                        const templates = me.state.templates;
                        templates.push(template);
                        me.setState({ templates: templates, templateSelect: template.id });

                        me.createSuccessNotification("Config template updated");
                    }
                    else {
                        me.createErrorNotification('Error Saving Config template Changes');
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Config template Changes');
                    console.log(error);
                });
        }

        this.handleTemplateMenuClose();
    }

    // Menu handling
    handleViewClick = (event: any): void => {
        this.setState({
            openViewMenu: !this.state.openViewMenu,
            anchorEl: event.currentTarget
        });
    }
    handleTemplateClick = (event: any): void => {
        this.setState({
            openTemplateMenu: !this.state.openTemplateMenu,
            anchorEl: event.currentTarget
        });
    }

    showSimDetails = (): void => {
        this.setState({ openViewMenu: false, panel: "SIM Details" });
    }

    showGprsDetails = (): void => {
        this.setState({ openViewMenu: false, panel: "GPRS Details" });
    }

    showPhoneNumbers = (): void => {
        this.setState({ openViewMenu: false, panel: "Phone Numbers" });
    }
    showLoadTemplate = (): void => {
        this.setState({ openTemplateMenu: false, panel: "Load Template" });
    }
    showSaveTemplate = (): void => {
        this.setState({ openTemplateMenu: false, panel: "Save Template" });
    }

    handleViewMenuClose = (): void => {
        this.setState({ openViewMenu: false, anchorEl: null, panel: "Config" });
    }
    handleTemplateMenuClose = (): void => {
        this.setState({ openTemplateMenu: false, anchorEl: null, panel: "Config" });
    }

    // Field change handlers
    handleTextfieldChange = (e: any): void => {

        // Max text field lengths
        const maxLen = {
            Sitename: 15,
            FlowARef: 16,
            FlowATotal: 12,
            FlowBRef: 16,
            FlowBTotal: 12,
            AnalogueCRef: 16,
            AnalogueDRef: 16,
            AnalogueERef: 16,
            AnalogueCDp: 2,
            AnalogueDDp: 2,
            AnalogueEDp: 2,
            AnalogueCUnits: 3,
            AnalogueDUnits: 3,
            AnalogueEUnits: 3,
        };


        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        const config = Object.assign({}, this.state.deviceConfig);
        if (inputValue.length <= maxLen[inputName]) {
            if (inputName.endsWith("Total")) {
                if (!isNaN(parseFloat(inputValue))) {
                    config[inputName] = inputValue;
                }
            }
            else {
                if (inputName.endsWith("Dp")) {
                    if (!isNaN(parseInt(inputValue))) {
                        config[inputName] = inputValue;
                    }
                }
                else {
                    config[inputName] = inputValue;
                }
            }
        }
        this.setState({ deviceConfig: config, enableSend: true, });

    }

    handleTransferChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        const config = Object.assign({}, this.state.deviceConfig);
        if (inputValue.length <= 5) {

            if (inputName.endsWith("StartPoint")) {
                if (!isNaN(parseInt(inputValue)) && parseInt(inputValue) <= 32767) {
                    if (inputName.startsWith("AnalogueC")) {
                        config.AnalogueCTransfer.StartPoint = parseInt(inputValue);
                        config.AnalogueCTransfer.hasUpdated = true;
                    }
                    else {
                        config.AnalogueDTransfer.StartPoint = parseInt(inputValue);
                        config.AnalogueDTransfer.hasUpdated = true;
                    }
                }
            }

            if (inputName.endsWith("EndPoint")) {
                if (!isNaN(parseInt(inputValue)) && parseInt(inputValue) <= 32767) {
                    if (inputName.startsWith("AnalogueC")) {
                        config.AnalogueCTransfer.EndPoint = parseInt(inputValue);
                        config.AnalogueCTransfer.hasUpdated = true;
                    }
                    else {
                        config.AnalogueDTransfer.EndPoint = parseInt(inputValue);
                        config.AnalogueDTransfer.hasUpdated = true;
                    }
                }
            }

        }
        this.setState({ deviceConfig: config, enableSend: true, });

    }

    handleToggleChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: boolean = e.target.checked;
        const config = Object.assign({}, this.state.deviceConfig);
        config[inputName] = inputValue;

        // Check for updates to channel enable - update meterConfig
        let meterConfig = this.state.deviceConfig?.MeterConfig || 0;
        if (inputName == "FlowA") {
            meterConfig ^= 0x01;        //Toggle bit 0
        }
        if (inputName == "FlowB") {
            meterConfig ^= 0x08;        //Toggle bit 3
        }
        if (inputName == "AnalogueC") {
            meterConfig ^= 0x0100;        //Toggle bit 9
        }
        if (inputName == "AnalogueD") {
            meterConfig ^= 0x0800;        //Toggle bit 12
        }
        if (inputName == "AnalogueE") {
            meterConfig ^= 0x2000;        //Toggle bit 14
        }

        config.MeterConfig = meterConfig;

        this.setState({ deviceConfig: config, enableSend: true, });

    }

    handleSelectChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        // Check for conflicting units between C & D  - 0370 transform
        if (inputName == "AnalogueCUnits" ) {
            if (inputValue == "mwg" && this.state.deviceConfig?.AnalogueDUnits == "psi"
                || inputValue == "psi" && this.state.deviceConfig?.AnalogueDUnits == "mwg") {
                alert("CONFLICT - Analogue C Units cannot be set " + inputValue + " when Analogue D Units is " + this.state.deviceConfig?.AnalogueDUnits);
                return;
            }
        }
        if (inputName == "AnalogueDUnits") {
            if (inputValue == "mwg" && this.state.deviceConfig?.AnalogueCUnits == "psi"
                || inputValue == "psi" && this.state.deviceConfig?.AnalogueCUnits == "mwg") {
                alert("CONFLICT - Analogue D Units cannot be set " + inputValue + " when Analogue C Units is " + this.state.deviceConfig?.AnalogueDUnits);
                return;
            }
        }
        const config = Object.assign({}, this.state.deviceConfig);
        config[inputName] = inputValue;
        this.setState({ deviceConfig: config, enableSend: true, });

    }

    handleAlarmTextfieldChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        const alarm = Object.assign({}, this.state.editAlarm);
        if (inputName == "Message") {
            if (inputValue.length <= 15) {
                alarm[inputName] = inputValue;
            }
        }
        else {
            if (inputName.endsWith("Threshold"))
            {
                // Check it's numeric value
                if (!isNaN(parseFloat(inputValue))) {
                    alarm[inputName] = parseFloat(inputValue);
                }

            }
            else {
                // Check it's integer value
                if (!isNaN(parseInt(inputValue))) {
                    alarm[inputName] = parseInt(inputValue);
                }
            }
        }
        this.setState({ editAlarm: alarm });

    };

    handleAlarmToggleChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: boolean = e.target.checked;
        const alarm = Object.assign({}, this.state.editAlarm);
        alarm[inputName] = inputValue;

        this.setState({ editAlarm: alarm });

    };

    handleAlarmSelectChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        const alarm = Object.assign({}, this.state.editAlarm);
        alarm[inputName] = inputValue;
        this.setState({ editAlarm: alarm });

    }

    handleAlarmRowDblClick = (e: any): void => {

        const alarm = Object.assign({}, this.state.deviceConfig?.Alarms[e.data.Idx - 1]);
        this.setState({ editAlarm: alarm, alarmTab: 1 });

    }
    handleAlarmTabChange = (e: any, v: any): void => {

        this.setState({ alarmTab: v });

    }
    updateAlarm = (): void => {
        // Save current editAlarm details in config
        const config = this.state.deviceConfig
        if (config && this.state.editAlarm) {
            config.Alarms[this.state.editAlarm?.Idx - 1] = this.state.editAlarm;
            this.setState({ deviceConfig: config });
        }
        this.setState({ editAlarm: undefined, alarmTab: 0, enableSend: true, });
    }

    handleFlowTypeChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        const config = Object.assign({}, this.state.deviceConfig);
        config[inputName] = inputValue;
        if (inputName == "FlowAType" && inputValue == "OFF") {
            if (config.FlowBType == "Direction" || config.FlowBType == "Reverse") {
                alert("Conflict with Flow B setting - Flow A cannot be OFF");
                return;
            }
            else {
                config.MeterConfig = (config.MeterConfig & 0xFFFC);
            }
        }
        if (inputName == "FlowAType" && inputValue == "Forward") {
            if (config.FlowBType == "Direction") {
                alert("Conflict with Flow B setting - Flow A cannot be Forward");
                return;
            }
            if (config.FlowBType == "Reverse") {
                config.MeterConfig = (config.MeterConfig & 0xFFF4) | 0x0002;
            }
            else {
                config.MeterConfig = (config.MeterConfig & 0xFFFC) | 0x0001;
            }
        }
        if (inputName == "FlowAType" && inputValue == "Pulse") {
            config.MeterConfig = (config.MeterConfig & 0xFFF4) | 0x0003;
            // Force Channel B enabled and type direction
            if (!config.FlowB || config.FlowBType != "Direction") {
                config.FlowB = true;
                config.FlowBType = "Direction";
            }
        }
        if (inputName == "FlowBType" && inputValue == "OFF") {
            config.MeterConfig = (config.MeterConfig & 0xFFF7);
            if (config.FlowAType == "Pulse") {
                config.MeterConfig = (config.MeterConfig & 0xFFFC);
                config.FlowAType = "OFF";
            }
        }
        if (inputName == "FlowBType" && inputValue == "Forward") {
            config.MeterConfig = (config.MeterConfig & 0xFFF7) | 0x0008;
            if (config.FlowAType == "Forward") {
                config.MeterConfig = (config.MeterConfig & 0xFFFC) | 0x0001;
            }
            if (config.FlowAType == "Pulse") {
                config.MeterConfig = (config.MeterConfig & 0xFFFC);
                config.FlowAType = "OFF";
            }
        }
        if (inputName == "FlowBType" && inputValue == "Reverse") {
            config.MeterConfig = (config.MeterConfig & 0xFFF4) | 0x0002;
            // Force Channel A enabled and type Forward
            if (!config.FlowA || config.FlowAType != "Forward") {
                config.FlowA = true;
                config.FlowAType = "Forward";
            }
        }
        if (inputName == "FlowBType" && inputValue == "Direction") {
            config.MeterConfig = (config.MeterConfig & 0xFFF4) | 0x0003;
            // Force Channel A enabled and type Forward
            if (!config.FlowA || config.FlowAType != "Pulse") {
                config.FlowA = true;
                config.FlowAType = "Pulse";
            }
        }
        
        this.setState({ deviceConfig: config, enableSend: true, });
    }
    handleNumericChange = (valueAsNumber: number, valueAsString: string, input: any): void => {
        
        const inputName: string = input.name;
        
        const inputValue: number = valueAsNumber;
        if (inputName === 'AnalogueCDp') {
            this.createDPforOffset('c', inputValue)
        }
        if (inputName === 'AnalogueDDp') {
            this.createDPforOffset('d', inputValue)
        }
        if (inputName === 'AnalogueEDp') {
            this.createDPforOffset('e', inputValue)
        }
        const config = Object.assign({}, this.state.deviceConfig);
        config[inputName] = inputValue;
        //if (inputName == '')
        this.setState({ deviceConfig: config, enableSend: true, });

    }


    handleTemplateChange = (e: any): void => {
        this.setState({ templateSelect: e.target.value, });
    }
    handleTemplateNameChange = (e: any): void => {
        const newName = e.target.value;
        if (newName.length < 50) {
            const existing = this.state.templates.filter((t) => t.name == newName);
            if (existing.length == 0) {
                this.setState({ templateName: newName, templateNameError: false, });
            }
            else {
                this.setState({
                    templateName: newName,
                    templateNameError: true,
                    templateNameErrorMsg: "Template exists - config will be updated"
                });
            }
        }
        else {
            this.setState({templateNameError: true, templateNameErrorMsg: "Too long - max 50 chars"})
        }
    }

    handleDataTxDateChange = (e: Moment): void => {
        const config = this.state.deviceConfig;
        if (config) {
            config.DataTxTime = e.toDate();
            console.log(moment(config.DataTxTime).hour(), moment(config.DataTxTime).minute())
            this.setState({ deviceConfig: config, enableSend: true, });
        }
    }

    handleDataTxIntervalChange = (e: any): void => {
        const config = this.state.deviceConfig;
        if (config) {
            config.DataTxInterval = e.target.value;
            this.setState({ deviceConfig: config, enableSend: true, });
        }
    }

    handleListenDateChange = (e: Moment): void => {
        const config = this.state.deviceConfig;
        if (config) {
            config.ListenTime = e.toDate();
            this.setState({ deviceConfig: config, enableSend: true, });
        }
    }

    handleSMSTxDateChange = (e: Moment): void => {
        this.setState({ smsTxTime: e.toDate() });
    }

    handleLoggerHold = (latitude: number, longitude: number): void => {
        
        // Check if hold is on logger spot
        if (Math.abs(latitude - (this.state.deviceConfig?.Latitude || 0)) < 0.001
            && Math.abs(longitude - (this.state.deviceConfig?.Longitude || 0)) < 0.001
        ) {
            this.setState({ loggerHold: true });
        }
    }

    handleLoggerHoldCancel = (): void => {
        this.setState({ loggerHold: false });
    }

    setLoggerLocation = (latitude: number, longitude: number): void => {
        if (this.state.loggerHold) {
            
            const config = this.state.deviceConfig;
            if (config) {
                config.Latitude = latitude;
                config.Longitude = longitude;
                this.setState({ deviceConfig: config, enableSend: true, loggerHold: false });
            }
        }

    }

    handleLatitudeChange = (event: any): void => {
        const config = this.state.deviceConfig;
        if (config) {
            const val = event.target.value == null ? config.Latitude?.toString() || "0" : event.target.value;
            config.Latitude = parseFloat(val);
            this.setState({ deviceConfig: config, enableSend: true, });
        }

    }
    handleLongitudeChange = (event: any): void => {
        const config = this.state.deviceConfig;
        if (config) {
            const val = event.target.value == null ? config.Latitude?.toString() || "0" : event.target.value;
            config.Longitude = parseFloat(val);
            this.setState({ deviceConfig: config, enableSend: true, });
        }

    }

    handleSendAfterChange = (e: Moment): void => {
        this.setState({ sendAfter: e.seconds(0).toDate() });
    }

    configUpdateChanged = (event: any): void => {
        const config = this.state.deviceConfig;
        if (config) {
            config.Custom = event.target.value;
            this.setState({ deviceConfig: config, enableSend: true, });
        }
    }



     // Data Grid event & render handlers
    typeEditorRender = (cell: any): ReactNode => {
        const onValueChanged = this.onTypeChanged.bind(this, cell);
        return <SelectBox
            defaultValue={cell.value}
            dataSource={[
                { value: "Server" },
                { value: "User" }
            ]}
            valueExpr="value"
            displayExpr="value"
            onValueChanged={onValueChanged}
        />;
    }

    onTypeChanged = (cell: any, e: any): void => {
        cell.setValue(e.value);
    }

    onPhoneInserted = (e: any): void => {
        e.component.navigateToRow(e.key);
        this.setState({ phoneEdit: 0 });
    }
    onPhoneEditingStart = (e: any): void => {
        this.setState({ phoneEdit: e.key });
    }
    onPhoneEditCancelled = (): void => {
        this.setState({ phoneEdit: null });
    }
    onPhoneSaved = (e: any): void => {
        const me = this;
        // Update/Create record on API & reload store
        if (this.state.phoneEdit != null) {
            // Also called after delete, but nothing to edit
            const item = e.changes[0].data as APIPhoneNumber;
            if (this.state.phoneEdit == 0) {
                // New entry
                this.newPhoneDetails(item, null);
            }
            else {
                // Update entry
                const requestOptions = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(item)
                };

                CallGetAPI(CreateUrl('/api/Aquaguard/PhoneNumber?Id=' + item.id), requestOptions)
                    .then((data): void => {
                        if (data.status === undefined) {
                            item.lastUpdate = data.lastUpdate;
                            (me.state.phoneNumbers.store() as unknown as ArrayStore).update(item.id, item);
                        }
                        else {
                            me.createErrorNotification('Error Saving Changes');
                        }
                    })
                    .catch(function (error) {
                        me.createErrorNotification('Error Saving Changes');
                        console.log(error);
                    });
            }

            me.setState({ phoneEdit: null });
        }
    }

    newPhoneDetails = (phoneDetail: APIPhoneNumber, idx: number | null): void => {
        const me = this;

        let company = parseInt(sessionStorage.getItem('companyId') || "0");
        if (company == 0) {
            // Superadmin - get company from logger
            company = this.state.companyId;
        }

        const key = phoneDetail.id;
        phoneDetail.id = 0;
        phoneDetail.fkCompanyId = company;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(phoneDetail)
        };

        if (company != 0) {
            CallGetAPI(CreateUrl('/api/Aquaguard/PhoneNumber'), requestOptions)
                .then(data => {
                    if (data.status === undefined) {
                        phoneDetail.id = data.id;
                        phoneDetail.lastUpdate = data.lastUpdate;
                        (me.state.phoneNumbers.store() as unknown as ArrayStore).update(key, phoneDetail);
                        // set this as phone details in field passed if config loaded
                        if (me.state.deviceConfig && idx != null) {
                            const config = me.state.deviceConfig;
                            config.PhoneNumbers[idx] = phoneDetail;
                            me.setState({ deviceConfig: config });
                        }
                    }
                    else {
                        me.createErrorNotification('Error Saving Changes');
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Changes');
                    console.log(error);
                });
        }
    }

    onPhoneDeleted = (e: any): void => {
        const me = this;
        // Delete record on API & reload store
        const key = e.key;
        if (key > 0) {
            CallDeleteAPI(CreateUrl('/api/Aquaguard/PhoneNumber?id=' + key), {})
                .then(response => {
                    if (response.status !== 200) {
                        me.createErrorNotification('Error Saving Changes');
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Changes');
                    console.log(error);
                });
        }

        me.setState({ phoneEdit: null });
    }

    onGprsInserted = (e: any): void => {
        e.component.navigateToRow(e.key);
        this.setState({ gprsEdit: 0 });
    };
    onGprsEditingStart = (e: any): void => {
        this.setState({ gprsEdit: e.key });
    };
    onGprsEditCancelled = (): void => {
        this.setState({ gprsEdit: null });
    };
    onGprsSaved = (e: any): void => {
        const me = this;
        // Update/Create record on API & reload store
        if (this.state.gprsEdit != null) {
            // Also called after delete, but nothing to edit
            const item = e.changes[0].data as APIGprsDetail;
            if (this.state.gprsEdit == 0) {
                // New entry
                this.newGprsDetails(item);
            }
            else {
                // Update entry
                const requestOptions = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(item)
                };

                CallGetAPI(CreateUrl('/api/Aquaguard/GprsDetail?Id=' + item.id), requestOptions)
                    .then((data): void => {
                        if (data.status === undefined) {
                            item.lastUpdate = data.lastUpdate;
                            (me.state.gprsDetails.store() as unknown as ArrayStore).update(item.id, item);
                        }
                        else {
                            me.createErrorNotification('Error Saving Changes');
                        }
                    })
                    .catch(function (error) {
                        me.createErrorNotification('Error Saving Changes');
                        console.log(error);
                    });
            }

            me.setState({ gprsEdit: null });
        }
    };

    newGprsDetails = (gprsDetail: APIGprsDetail): void => {
        const me = this;

        let company = parseInt(sessionStorage.getItem('companyId') || "0");
        if (company == 0) {
            // Superadmin - get company from logger
            company = this.state.companyId;
        }

        const key = gprsDetail.id;
        gprsDetail.id = 0;
        gprsDetail.fkCompanyId = company;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(gprsDetail)
        };

        if (company != 0) {
            CallGetAPI(CreateUrl('/api/Aquaguard/GprsDetail'), requestOptions)
                .then(data => {
                    if (data.status === undefined) {
                        gprsDetail.id = data.id;
                        gprsDetail.lastUpdate = data.lastUpdate;
                        (me.state.gprsDetails.store() as unknown as ArrayStore).update(key, gprsDetail);
                        // set this as GPRS details if config loaded
                        if (me.state.deviceConfig) {
                            const config = me.state.deviceConfig;
                            config.DataCheckServer = gprsDetail.id.toString();
                            me.setState({ deviceConfig: config });
                        }
                    }
                    else {
                        me.createErrorNotification('Error Saving Changes');
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Changes');
                    console.log(error);
                });
        }
    }

    onGprsDeleted = (e: any): void => {
        const me = this;
        // Delete record on API & reload store
        const key = e.key;
        if (key > 0) {
            CallDeleteAPI(CreateUrl('/api/Aquaguard/GprsDetail?id=' + key), {})
                .then(response => {
                    if (response.status !== 200) {
                        me.createErrorNotification('Error Saving Changes');
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Changes');
                    console.log(error);
                });
        }

        me.setState({ gprsEdit: null });
    }

    onSimInserted = (e: any): void => {
        e.component.navigateToRow(e.key);
        this.setState({ simEdit: 0 });
    };
    onSimEditingStart = (e: any): void => {
        this.setState({ simEdit: e.key });
    };
    onSimEditCancelled = (): void => {
        this.setState({ simEdit: null });
    };
    onSimSaved = (e: any): void => {
        const me = this;
        // Update/Create record on API & reload store
        if (this.state.simEdit != null) {
            // Also called after delete, but nothing to edit
            const item = e.changes[0].data as APISimDetail;
            if (this.state.simEdit == 0) {
                // New entry
                this.newSimDetails(item);
            }
            else {
                // Update entry
                const requestOptions = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(item)
                };

                CallPutAPI(CreateUrl('/api/Aquaguard/SimDetail?Id=' + item.id), requestOptions)
                    .then((data): void => {
                        if (data.status === undefined) {
                            item.lastUpdate = data.lastUpdate;
                            (me.state.simDetails.store() as unknown as ArrayStore).update(item.id, item);
                        }
                        else {
                            me.createErrorNotification('Error Saving Changes');
                        }
                    })
                    .catch(function (error) {
                        me.createErrorNotification('Error Saving Changes');
                        console.log(error);
                    });
            }

            me.setState({ simEdit: null });
        }
    };

    newSimDetails = (simDetail: APISimDetail): void => {
        const me = this;

        let company = parseInt(sessionStorage.getItem('companyId') || "0");
        if (company == 0) {
            // Superadmin - get company from logger
            company = this.state.companyId;
        }

        const key = simDetail.id;
        simDetail.id = 0;
        simDetail.fkCompanyId = company;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(simDetail)
        };

        if (company != 0) {
            CallGetAPI(CreateUrl('/api/Aquaguard/SimDetail'), requestOptions)
                .then(data => {
                    if (data.status === undefined) {
                        simDetail.id = data.id;
                        simDetail.lastUpdate = data.lastUpdate;
                        (me.state.simDetails.store() as unknown as ArrayStore).update(key, simDetail);
                        // set this as SIM details if config loaded
                        if (me.state.deviceConfig) {
                            const config = me.state.deviceConfig;
                            config.SimSetting = simDetail.id.toString();
                            me.setState({ deviceConfig: config });
                        }
                    }
                    else {
                        me.createErrorNotification('Error Saving Changes');
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Changes');
                    console.log(error);
                });
        }
    }

    onSimDeleted = (e: any): void => {
        const me = this;
        // Delete record on API & reload store
        const key = e.key;
        if (key > 0) {
            CallDeleteAPI(CreateUrl('/api/Aquaguard/SimDetail?id=' + key), {})
                .then(response => {
                    if (response.status !== 200) {
                        me.createErrorNotification('Error Saving Changes');
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Changes');
                    console.log(error);
                });
        }

        me.setState({ simEdit: null });
    }

    // Modbus Modal
    displayModbusModal(idx: number): void {
        this.setState({
            tempModbus: this.state.deviceConfig?.ModbusConfig[idx - 1],
            modbusModal: true,
            modbusModalTitle: "Modbus Channel " + idx,
        })
    }
    hideModbusModal(): void {
        this.setState({
            modbusModal: false,
            modbusModalTitle: "",
        })

    }
    updateModal(): void {
        const idx = this.state.tempModbus?.Idx;

        if (idx && this.state.tempModbus) {
            const config = Object.assign({}, this.state.deviceConfig);
            config.ModbusConfig[idx - 1] = this.state.tempModbus;
            config.ModbusUpdated[idx - 1] = true;

            this.setState({
                deviceConfig: config,
                modbusModal: false,
                modbusModalTitle: "",
                enableSend: true,
            })
        }
    }

    handleModbusChange(e: any): void {
        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;

        const config = Object.assign({}, this.state.tempModbus);
        config[inputName] = inputValue;
        this.setState({ tempModbus: config });

    }
    handleModbusCbChange(e: any): void {
        const inputName: string = e.target.name;

        const config = Object.assign({}, this.state.tempModbus);
        config[inputName] = e.target.checked;
        this.setState({ tempModbus: config });

    }
    handleModbusNumericChange = (valueAsNumber: number, valueAsString: string, input: any): void => {

        const inputName: string = input.name;
        const inputValue: number = valueAsNumber;
        const config = Object.assign({}, this.state.tempModbus);
        config[inputName] = inputValue;

        this.setState({ tempModbus: config });

    }


    // Display render functions
    inputColumnCustomizeText(cellInfo: any): string {
        switch (cellInfo.value) {
            case 'A':
                return "Flow A";
            case 'B':
                return "Flow B";
            case 'C':
                return "Channel C";
            case 'D':
                return "Channel D";
            case 'E':
                return "Channel E";
            default:
                return "";
        }
    }

    channelUnits(input: string): string {
        switch (this.state.editAlarm?.Input) {
            case "A":
                return this.state.deviceConfig?.FlowAUnits || "";
            case "B":
                return this.state.deviceConfig?.FlowBUnits || "";
            case "C":
                return this.state.deviceConfig?.AnalogueCUnits || "";
            case "D":
                return this.state.deviceConfig?.AnalogueDUnits || "";
            case "E":
                return this.state.deviceConfig?.AnalogueEUnits || "";
            default:
                return "";
        }
    }

    makeDecimal(val: number | null | undefined): string {

        if (val) {
            if (isNaN(parseInt(val.toString()))) {
                return val + ".0";
            }
            else {
                return val.toString();
            }
        }
        else {
            return "0.0";
        }
    }

    


    render(): ReactNode {

        const simDetails = this.state.simDetails.store()._array;
        const gprsDetails = this.state.gprsDetails.store()._array;
        const phoneDetails = this.state.phoneNumbers.store()._array;

        const classes = makeStyles(style);

        const units = this.channelUnits(this.state.editAlarm?.Input || "");

        return (
            
            <div>
                
                <Modal
                    show={this.state.modbusModal}
                    onHide={(): void => this.hideModbusModal()}
                    dialogClassName="modal-100w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                    style={{zIndex: 1300}}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.state.modbusModalTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Grid container spacing={2}>
                            <Grid item xs={6} >
                                Slave Address
                            </Grid>
                            <Grid item xs={6} >
                                <Select
                                    name="SlaveAddress"
                                    style={{ margin: 0, paddingBottom: 0 }}
                                    value={this.state.tempModbus?.SlaveAddress}
                                    onChange={this.handleModbusChange.bind(this)}
                                >
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="3">3</MenuItem>
                                    <MenuItem value="4">4</MenuItem>
                                    <MenuItem value="5">5</MenuItem>
                                    <MenuItem value="6">6</MenuItem>
                                    <MenuItem value="7">7</MenuItem>
                                    <MenuItem value="8">8</MenuItem>
                                    <MenuItem value="9">9</MenuItem>
                                    <MenuItem value="10">10</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={6} >
                                Function Code
                            </Grid>
                            <Grid item xs={6} >
                                <Select
                                    name="FunctionCode"
                                    style={{ margin: 0, paddingBottom: 0 }}
                                    value={this.state.tempModbus?.FunctionCode}
                                    onChange={this.handleModbusChange.bind(this)}
                                >
                                    <MenuItem value="1">01</MenuItem>
                                    <MenuItem value="2">02</MenuItem>
                                    <MenuItem value="3">03</MenuItem>
                                    <MenuItem value="4">04</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={6} >
                                Function Code
                            </Grid>
                            <Grid item xs={6} >
                                <Select
                                    name="DataFormat"
                                    style={{ margin: 0, paddingBottom: 0 }}
                                    value={this.state.tempModbus?.DataFormat}
                                    onChange={this.handleModbusChange.bind(this)}
                                >
                                    <MenuItem value="0">UINT16</MenuItem>
                                    <MenuItem value="1">INT16</MenuItem>
                                    <MenuItem value="2">FLOAT</MenuItem>
                                    <MenuItem value="3">UINT32</MenuItem>
                                    <MenuItem value="4">INT32</MenuItem>
                                    <MenuItem value="5">UINT64</MenuItem>
                                    <MenuItem value="6">INT64</MenuItem>
                                    <MenuItem value="7">DOUBLE</MenuItem>
                                    <MenuItem value="8">UINT8</MenuItem>
                                    <MenuItem value="9">INT8</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={6} >
                                Scale Factor
                            </Grid>
                            <Grid item xs={6} >
                                <Select
                                    name="ScalingFactor"
                                    style={{ margin: 0, paddingBottom: 0 }}
                                    value={this.state.tempModbus?.ScalingFactor}
                                    onChange={this.handleModbusChange.bind(this)}
                                >
                                    <MenuItem value="\u002F100000">/100000</MenuItem>
                                    <MenuItem value="\u002F10000">/10000</MenuItem>
                                    <MenuItem value="\u002F1000">/1000</MenuItem>
                                    <MenuItem value="\u002F100">/100</MenuItem>
                                    <MenuItem value="\u002F10">/10</MenuItem>
                                    <MenuItem value="x1">x1</MenuItem>
                                    <MenuItem value="x10">x10</MenuItem>
                                    <MenuItem value="x100">x100</MenuItem>
                                    <MenuItem value="x1000">x1000</MenuItem>
                                    <MenuItem value="x10000">x10000</MenuItem>
                                    <MenuItem value="x100000">x100000</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={6} >
                                Little-Endian Format
                            </Grid>
                            <Grid item xs={6} >
                                <Checkbox
                                    name="LEF"
                                    checked={this.state.tempModbus?.LEF}
                                    onChange={this.handleModbusCbChange.bind(this)}
                                />
                            </Grid>

                            <Grid item xs={6} >
                                Swapped Register Order
                            </Grid>
                            <Grid item xs={6} >
                                <Checkbox
                                    name="SRO"
                                    checked={this.state.tempModbus?.SRO}
                                    onChange={this.handleModbusCbChange.bind(this)}
                                />
                            </Grid>

                            <Grid item xs={6} >
                                Start Address
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    name="StartAddress"
                                    value={this.state.tempModbus?.StartAddress}
                                    onChange={this.handleModbusChange.bind(this)}
                                />
                            </Grid>

                            <Grid item xs={6} >
                                Register Count
                            </Grid>
                            <Grid item xs={6} >
                                <Select
                                    name="RegisterCount"
                                    style={{ margin: 0, paddingBottom: 0 }}
                                    value={this.state.tempModbus?.RegisterCount}
                                    onChange={this.handleModbusChange.bind(this)}
                                >
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="4">4</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={6} >
                                Post-read Timeout
                            </Grid>
                            <Grid item xs={6} >
                                <FormControl style={{ marginTop: -5 }}>
                                    <NumericInput step={0.1} precision={1} min={0} max={2} snap
                                        style={{ input: { marginTop: 10, marginBottom: 10, height: 50, width: 100 } }}
                                        value={this.state.tempModbus?.PostReadTimeout}
                                        name="PostReadTimeout"
                                        onChange={this.handleModbusNumericChange.bind(this)}
                                    />
                                </FormControl> Secs
                            </Grid>

                        </Grid>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="primary" style={{marginRight: "10px" }} variant="contained" onClick={(): void => this.updateModal()}>Save</Button>
                        <Button color="secondary" variant="contained" onClick={(): void => this.hideModbusModal()}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <div style={{marginTop: "10px"} }>
                    <Button
                        id="read-button"
                        onClick={this.handleReadClick}
                        color="secondary"
                        variant="contained"
                        style={{marginLeft: "15px"} }
                    >
                        Load Config
                    </Button>
                    <Button
                        id="send-button"
                        onClick={this.handleSendClick}
                        disabled={!this.state.enableSend}
                        color="primary"
                        variant="contained"
                        style={{margin: "0 10px"} }
                    >
                        Send To Logger
                    </Button>
                    <Button
                        id="template-button"
                        aria-controls="template-menu"
                        aria-haspopup="true"
                        aria-expanded={this.state.openTemplateMenu ? 'true' : undefined}
                        onClick={this.handleTemplateClick}
                        color="primary"
                        variant="contained"
                    >
                        Templates
                    </Button>
                    <Menu
                        id="template-menu"
                        anchorEl={this.state.anchorEl}
                        open={this.state.openTemplateMenu}
                        onClose={this.handleTemplateMenuClose}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        MenuListProps={{
                            'aria-labelledby': 'template-button',
                        }}
                    >
                        <MenuItem onClick={this.showLoadTemplate}>Load from template</MenuItem>
                        <MenuItem onClick={this.showSaveTemplate} disabled={this.state.deviceConfig == null}>Save as template</MenuItem>
                    </Menu>
                    <Button
                        id="view-button"
                        aria-controls="view-menu"
                        aria-haspopup="true"
                        aria-expanded={this.state.openViewMenu ? 'true' : undefined}
                        onClick={this.handleViewClick}
                        color="primary"
                        variant="contained"
                        style={{marginLeft: "10px"} }
                    >
                        View
                    </Button>
                    <Menu
                        id="view-menu"
                        anchorEl={this.state.anchorEl}
                        open={this.state.openViewMenu}
                        onClose={this.handleViewMenuClose}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        MenuListProps={{
                            'aria-labelledby': 'view-button',
                        }}
                    >
                        {this.state.panel != "Config" &&
                            < MenuItem onClick={this.handleViewMenuClose}>Logger Config</MenuItem>
                        }
                        <MenuItem onClick={this.showSimDetails}>SIM Details</MenuItem>
                        <MenuItem onClick={this.showGprsDetails}>GPRS Details</MenuItem>
                        <MenuItem onClick={this.showPhoneNumbers}>Phone Numbers</MenuItem>
                    </Menu>
                    {this.state.deviceConfig &&
                        <div style={{ float: "right", paddingRight: "30px", paddingTop: "5px" }}>
                        Configuration as at {moment(this.state.configDate).format("DD/MM/yyyy HH:mm")}
                        {this.state.deviceConfig.UpdateJSON &&
                            <div> including pending updates</div>
                        }
                        </div>
                    }
                </div>
                {this.state.panel == "Config" &&
                    <CustomTabs
                        headerColor="info"
                        tabs={[
                            {
                                tabName: "Site Details",
                                tabContent: (
                                    <div>
                                        <div>Unit Details</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    Serial Number:
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {this.props.serial}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    Computer Time (UTC) [Last connection]
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {moment(this.state.deviceConfig?.ServerTime).format("DD/MM/YYYY HH:mm:ss")}
                                                </Grid>

                                                <Grid item xs={2}>
                                                    Product:
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {this.props.product}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    Unit Time (UTC)
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {moment(this.state.deviceConfig?.DeviceTime).format("DD/MM/YYYY HH:mm:ss")}
                                                </Grid>

                                                <Grid item xs={2}>
                                                    Model:
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {this.props.model}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    SW Version
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {this.state.deviceConfig?.SWversion}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    Battery Voltage
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {this.state.battery}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <div>Unit State</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    Current state
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {this.state.deviceConfig?.CurrentState}
                                                </Grid>
                                                <Grid item xs={6}>                                                  
                                                    {this.state.deviceConfig?.CurrentState == "Commissioned" &&
                                                        <div>Use &quot;Undeploy&quot; to decommission the device.</div>
                                                    }
                                                </Grid>
                                                <Grid item xs={1}>
                                                    GUID:
                                                </Grid>
                                                <Grid item xs={5}>
                                                    {this.state.deviceConfig?.Guid}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Switch name="HwWakeup" size="small" color="primary" checked={this.state.deviceConfig?.HwWakeup} onChange={this.handleToggleChange.bind(this)}/>
                                                    Hardware Wakeup
                                            </Grid>
                                            </Grid>
                                        </Box>
                                        <div>Site Details</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    Site Name:
                                            </Grid>
                                                <Grid item xs={9}>
                                                    <TextField
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        size="small"
                                                        name="Sitename"                                                       
                                                        value={this.state.deviceConfig?.Sitename}
                                                        onChange={this.handleTextfieldChange.bind(this)}
                                                    />

                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <div>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    Location
                                            </Grid>
                                                <Grid item xs={5}>
                                                    <div>
                                                        Lat: <TextField
                                                            fullWidth={false}
                                                            variant="outlined"
                                                            size="small"
                                                            type="number"
                                                            value={this.state.deviceConfig?.Latitude}
                                                            onChange={this.handleLatitudeChange}
                                                            />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <div>
                                                        Long: <TextField
                                                            fullWidth={false}
                                                            variant="outlined"
                                                            size="small"
                                                            type="number"
                                                            value={this.state.deviceConfig?.Longitude}
                                                            onChange={this.handleLongitudeChange}
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        {this.state.deviceConfig &&
                                            (this.state.loggerHold ?
                                                <div>
                                                Now double click new location to reposition the logger. <Button size="small" variant="contained" color="primary" onClick={(): void => this.handleLoggerHoldCancel()}>Cancel</Button>
                                                </div>
                                            :
                                                <div>
                                                    To relocate logger, Click and hold to select.
                                                </div>)
                                        }
                                        <Box border={2} padding={3}>
                                            {this.state.ready &&
                                                <WebMapView center={[this.state.deviceConfig?.Longitude || -3.979540, this.state.deviceConfig?.Latitude || 50.741127]}
                                                    height="30vh"
                                                    zoom={12}
                                                    colour={"primary"}
                                                    trunkSegments={[]}
                                                    regions={[]}
                                                    sites={[]}
                                                    showRegions={false}
                                                    showAllSites={false}
                                                    showRainfall={false}
                                                    alarmFilter={-1}
                                                    singlePoints={[
                                                        {
                                                            id: this.props.serial,
                                                            name: this.props.serial,
                                                            colour: this.state.loggerHold ? "red" : "black",
                                                            coordinates: [this.state.deviceConfig?.Longitude || 0, this.state.deviceConfig?.Latitude || 0],
                                                        }
                                                    ]}
                                                singlePointsDesc="Logger"
                                                onHold={this.handleLoggerHold}
                                                onRelease={this.setLoggerLocation}
                                                mouseWheelZoom={false}
                                                />
                                            }
                                        </Box>
                                    </div>
                                )
                            },
                            {
                                tabName: "Schedules",
                                disabled: !this.state.ready,
                                tabContent: (
                                    <div>
                                        <div>Primary Measurement Schedule</div>
                                        <Box border={2} padding={3}>

                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <Switch name="PrimarySchedule"size="small" color="primary" checked={this.state.deviceConfig?.PrimarySchedule} onChange={this.handleToggleChange.bind(this)}/> Enable Schedule
                                    </Grid>
                                                <Grid item xs={4}>
                                                    Sample Rate
                                    </Grid>
                                                <Grid item xs={4}>
                                                    <SampleRate
                                                        classes={style}
                                                        formControlProps={{
                                                            fullWidth: false,
                                                            margin: "none",
                                                            padding: "0"
                                                        }}
                                                        inputProps={{
                                                            name: "PrimarySample",
                                                            disabled: !this.state.deviceConfig?.PrimarySchedule,
                                                            value: (this.state.deviceConfig?.PrimarySampleValue || 0).toString() + (this.state.deviceConfig?.PrimarySampleUnit || 's'),
                                                            onChange: this.handleSelectChange
                                                        }}
                                                        id="outlined-input-PrimaryRate"
                                                        labelText={undefined}
                                                        error={false}
                                                        success={false}
                                                    />
                                                    
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Switch name="PrimaryWindow" size="small" color="primary" checked={this.state.deviceConfig?.PrimaryWindow} onChange={this.handleToggleChange.bind(this)} /> Enable Window
                                    </Grid>
                                                <Grid item xs={4}>
                                                    Window Period
                                    </Grid>
                                                <Grid item xs={4}>
                                                    <WindowPeriod
                                                        classes={style}
                                                        formControlProps={{
                                                            fullWidth: false
                                                        }}
                                                        inputProps={{
                                                            name: "PrimaryWindow",
                                                            disabled: !this.state.deviceConfig?.PrimaryWindow,
                                                            value: (this.state.deviceConfig?.PrimaryWindowStart || "00:00") + "-" + (this.state.deviceConfig?.PrimaryWindowEnd || "00:00"),
                                                            onChange: this.handleSelectChange
                                                        }}
                                                        id="outlined-input-PrimaryWindow"
                                                        labelText={undefined}
                                                        error={false}
                                                        success={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Switch name="PrimaryAlarm" size="small" color="primary" checked={this.state.deviceConfig?.PrimaryAlarm} onChange={this.handleToggleChange.bind(this)} /> Enable Alarm Rate
                                    </Grid>
                                                <Grid item xs={4}>
                                                    Alarm Sample Rate
                                    </Grid>
                                                <Grid item xs={4}>
                                                    <SampleRate
                                                        classes={style}
                                                        formControlProps={{
                                                            fullWidth: false
                                                        }}
                                                        inputProps={{
                                                            name: "PrimaryAlarm",
                                                            disabled: !this.state.deviceConfig?.PrimaryAlarm,
                                                            value: (this.state.deviceConfig?.PrimaryAlarmValue || 0).toString() + (this.state.deviceConfig?.PrimaryAlarmUnit || 's'),
                                                            onChange: this.handleSelectChange
                                                        }}
                                                        id="outlined-input-PrimaryAlarmRate"
                                                        labelText={undefined}
                                                        error={false}
                                                        success={false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <div>Secondary Measurement Schedule</div>
                                        <Box border={2} padding={3}>

                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <Switch name="SecondarySchedule" size="small" color="primary" checked={this.state.deviceConfig?.SecondarySchedule} onChange={this.handleToggleChange.bind(this)}/> Enable Schedule
                                            </Grid>
                                                <Grid item xs={4}>
                                                    Sample Rate
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SampleRate
                                                        classes={style}
                                                        formControlProps={{
                                                            fullWidth: false,
                                                            margin: "none",
                                                            padding: "0"
                                                        }}
                                                        inputProps={{
                                                            name: "SecondarySample",
                                                            disabled: !this.state.deviceConfig?.SecondarySchedule,
                                                            value: (this.state.deviceConfig?.SecondarySampleValue || 0).toString() + (this.state.deviceConfig?.SecondarySampleUnit || 's'),
                                                            onChange: this.handleSelectChange
                                                        }}
                                                        id="outlined-input-SecondaryRate"
                                                        labelText={undefined}
                                                        error={false}
                                                        success={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Switch name="SecondaryWindow" size="small" color="primary" checked={this.state.deviceConfig?.SecondaryWindow} onChange={this.handleToggleChange.bind(this)}/> Enable Window
                                            </Grid>
                                                <Grid item xs={4}>
                                                    Window Period
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <WindowPeriod
                                                        classes={style}
                                                        formControlProps={{
                                                            fullWidth: false
                                                        }}
                                                        inputProps={{
                                                            name: "SecondaryWindow",
                                                            disabled: !this.state.deviceConfig?.SecondaryWindow,
                                                            value: (this.state.deviceConfig?.SecondaryWindowStart || "00:00") + "-" + (this.state.deviceConfig?.SecondaryWindowEnd || "00:00"),
                                                            onChange: this.handleSelectChange
                                                        }}
                                                        id="outlined-input-SecondaryWindow"
                                                        labelText={undefined}
                                                        error={false}
                                                        success={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Switch name="SecondaryAlarm" size="small" color="primary" checked={this.state.deviceConfig?.SecondaryAlarm} onChange={this.handleToggleChange.bind(this)}/> Enable Alarm Rate
                                    </Grid>
                                                <Grid item xs={4}>
                                                    Alarm Sample Rate
                                    </Grid>
                                                <Grid item xs={4}>
                                                    <SampleRate
                                                        classes={style}
                                                        formControlProps={{
                                                            fullWidth: false,
                                                            margin: "none",
                                                            padding: "0"
                                                        }}
                                                        inputProps={{
                                                            name: "SecondaryAlarm",
                                                            disabled: !this.state.deviceConfig?.SecondaryAlarm,
                                                            value: (this.state.deviceConfig?.SecondaryAlarmValue || 0).toString() + (this.state.deviceConfig?.SecondaryAlarmUnit || 's'),
                                                            onChange: this.handleSelectChange
                                                        }}
                                                        id="outlined-input-SecondaryAlarm"
                                                        labelText={undefined}
                                                        error={false}
                                                        success={false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <div>Analogue Sample Rate</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <Switch name="AnalogueSchedule" size="small" color="primary" checked={this.state.deviceConfig?.AnalogueSchedule} onChange={this.handleToggleChange.bind(this)}/> Enable Sample Rate
                                                </Grid>
                                                <Grid item xs={4}>
                                                    Sample Rate
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <SampleRate
                                                        classes={style}
                                                        formControlProps={{
                                                            fullWidth: false,
                                                            margin: "none",
                                                            padding: "0"
                                                        }}
                                                        inputProps={{
                                                            name: "AnalogueSample",
                                                            disabled: !this.state.deviceConfig?.AnalogueSchedule,
                                                            value: (this.state.deviceConfig?.AnalogueSampleValue || 0).toString() + (this.state.deviceConfig?.AnalogueSampleUnit || 's'),
                                                            onChange: this.handleSelectChange
                                                        }}
                                                        id="outlined-input-AnalogueRate"
                                                        labelText={undefined}
                                                        error={false}
                                                        success={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Switch name="AnalogueAlarm" size="small" color="primary" checked={this.state.deviceConfig?.AnalogueAlarm} onChange={this.handleToggleChange.bind(this)}/> Enable Alarm Rate
                                            </Grid>
                                                <Grid item xs={4}>
                                                    Alarm Sample Rate
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <SampleRate
                                                        classes={style}
                                                        formControlProps={{
                                                            fullWidth: false,
                                                            margin: "none",
                                                            padding: "0"
                                                        }}
                                                        inputProps={{
                                                            name: "AnalogueAlarm",
                                                            disabled: !this.state.deviceConfig?.AnalogueAlarm,
                                                            value: (this.state.deviceConfig?.AnalogueAlarmValue || 0).toString() + (this.state.deviceConfig?.AnalogueAlarmUnit || 's'),
                                                            onChange: this.handleSelectChange
                                                        }}
                                                        id="outlined-input-AnalogueAlarm"
                                                        labelText={undefined}
                                                        error={false}
                                                        success={false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </div>
                                )
                            },
                            {
                                tabName: "Communication",
                                disabled: !this.state.ready,
                                tabContent: (
                                    <div>
                                        <div>General Settings</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    Preferred RAT
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <Select
                                                        name="PreferredRAT"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.PreferredRAT}
                                                        disabled={this.state.deviceConfig?.ModemType == "SIM800F Modem (2G)"}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem value="00">Disabled</MenuItem>
                                                        {this.state.deviceConfig?.ModemType == "SIM5300E Modem (3G)" ?
                                                            <MenuItem value="02">Auto LTE / UMTS</MenuItem>
                                                            :
                                                            <MenuItem value="02">Auto LTE / GSM</MenuItem>
                                                        }
                                                        {this.state.deviceConfig?.ModemType == "SIM5300E Modem (3G)" &&
                                                            <MenuItem value="03">UMTS preferred</MenuItem>
                                                        }
                                                        {this.state.deviceConfig?.ModemType == "SIM5300E Modem (3G)" &&
                                                            <MenuItem value="04">GSM preferred</MenuItem>
                                                        }
                                                        <MenuItem value="0D">GSM Only</MenuItem>
                                                        {this.state.deviceConfig?.ModemType == "SIM5300E Modem (3G)" &&
                                                            <MenuItem value="0E">UMTS Only</MenuItem>
                                                        }
                                                        {this.state.deviceConfig?.ModemType == "SIM7000E Modem (4G NB-IoT)" || this.state.deviceConfig?.ModemType == "SIM7070 Modem (NB-IoT)" &&
                                                            <MenuItem value="26">LTE Only</MenuItem>
                                                        }
                                                        {this.state.deviceConfig?.ModemType == "SIM7000E Modem (4G NB-IoT)" || this.state.deviceConfig?.ModemType == "SIM7070 Modem (NB-IoT)" &&
                                                            <MenuItem value="33">GSM / LTE Only</MenuItem>
                                                        }
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    Antenna
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Select
                                                        name="Antenna"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.Antenna}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                        disabled={!this.state.deviceConfig?.ExtAntennaAvail}
                                                    >
                                                        <MenuItem value=""></MenuItem>
                                                        <MenuItem value="Internal">Internal</MenuItem>
                                                        <MenuItem value="External">External</MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <i>Modem type:</i> {this.state.deviceConfig?.ModemType} <i>IMEI:</i> {this.state.deviceConfig?.ModemIMEI}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    Preferred 4G Mode
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <Select
                                                        name="Preferred4G"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.Preferred4G}
                                                        disabled={this.state.deviceConfig?.ModemType != "SIM7000E Modem (4G NB-IoT)" && this.state.deviceConfig?.ModemType != "SIM7070 Modem (NB-IoT)"}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem value="00">Disabled</MenuItem>
                                                        <MenuItem value="01">CAT-M</MenuItem>
                                                        <MenuItem value="02">NB-IoT</MenuItem>
                                                        <MenuItem value="03">CAT-M and NB-IoT</MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    SIM Settings
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <Select
                                                        name="SimSetting"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.SimSetting}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                        disabled={true}
                                                    >
                                                        <MenuItem key="0" value="0"></MenuItem>
                                                        {simDetails.map((sim: APISimDetail): ReactNode => {
                                                            return <MenuItem key={sim.id} value={sim.id}>{sim.name}</MenuItem>

                                                        })
                                                        }
                                                    </Select>
                                            </Grid>
                                            </Grid>
                                        </Box>
                                        <div>Signal Check</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    Phone Number
                                            </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        name="SignalCheckPhone"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.SignalCheckPhone}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem key="0" value="0">None</MenuItem>
                                                        {phoneDetails.map((phone: APIPhoneNumber): ReactNode => {
                                                            return <MenuItem key={phone.id} value={phone.id}>{phone.name}</MenuItem>;

                                                        })
                                                        }
                                                    </Select>
                                            </Grid>
                                                <Grid item xs={2}>
                                                    Trigger Signal check
                                            </Grid>
                                                <Grid item xs={2}>
                                                    Delay
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <Select
                                                        name="SignalCheckDelay"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.SignalCheckDelay}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem value="0">Not Valid</MenuItem>
                                                        <MenuItem value="1">1 Minute</MenuItem>
                                                        <MenuItem value="2">2 Minutes</MenuItem>
                                                        <MenuItem value="3">3 Minutes</MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    Duration
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <Select
                                                        name="SignalCheckDuration"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.SignalCheckDuration}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem value="0">Not Valid</MenuItem>
                                                        <MenuItem value="1">1 Minute</MenuItem>
                                                        <MenuItem value="2">2 Minutes</MenuItem>
                                                        <MenuItem value="5">5 Minutes</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <div>Data Check</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    Server
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <Select
                                                        name="DataCheckServer"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.DataCheckServer}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem key="0" value="0"></MenuItem>
                                                        {gprsDetails.map((gprs: APIGprsDetail): ReactNode => {
                                                            return <MenuItem key={gprs.id} value={gprs.id}>{gprs.name}</MenuItem>;

                                                        })
                                                        }
                                                    </Select>
                                            </Grid>
                                                <Grid item xs={2}>

                                                </Grid>
                                                <Grid item xs={4}>
                                                    Trigger Server check
                                            </Grid>
                                            </Grid>
                                        </Box>
                                        <div>Data Transmission Schedule</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <Switch name="DataTxSchedule" size="small" color="primary" checked={this.state.deviceConfig?.DataTxSchedule} onChange={this.handleToggleChange.bind(this)}/> Enable
                                            </Grid>
                                                <Grid item xs={2}>
                                                    Transmission Time
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <LocalizationProvider dateAdapter={DateAdapter}>
                                                        <TimePicker
                                                            label="Transmission Time"
                                                            value={this.state.deviceConfig?.DataTxTime}
                                                            onChange={this.handleDataTxDateChange}
                                                            disabled={!this.state.deviceConfig?.DataTxSchedule}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                            </Grid>
                                                <Grid item xs={2}>
                                                    Transmission Interval
                                            </Grid>
                                                <Grid item xs={2}>
                                                    <Select
                                                        name="DataTxInterval"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.DataTxInterval}
                                                        disabled={!this.state.deviceConfig?.DataTxSchedule}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem value="0">Never</MenuItem>
                                                        <MenuItem value="24">24 Hours</MenuItem>
                                                        <MenuItem value="12">12 Hours</MenuItem>
                                                        <MenuItem value="6">6 Hours</MenuItem>
                                                        <MenuItem value="3">3 Hours</MenuItem>
                                                        <MenuItem value="1">1 Hour</MenuItem>
                                                        <MenuItem value="30mins">30 Minutes</MenuItem>
                                                        <MenuItem value="15mins">15 Minutes</MenuItem>
                                                    </Select>
                                            </Grid>
                                            </Grid>
                                        </Box>
                                        <div>Listen Schedule</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <Switch name="ListenSchedule" size="small" color="primary" checked={this.state.deviceConfig?.ListenSchedule} onChange={this.handleToggleChange.bind(this)} /> Enable
                                                </Grid>
                                                <Grid item xs={2}>
                                                    Listen Time
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <LocalizationProvider dateAdapter={DateAdapter}>
                                                        <TimePicker
                                                            label="Listen Time"
                                                            value={this.state.deviceConfig?.ListenTime}
                                                            onChange={this.handleListenDateChange}
                                                            disabled={!this.state.deviceConfig?.ListenSchedule}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    Listen Interval
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Select
                                                        name="ListenInterval"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.ListenInterval}
                                                        disabled={!this.state.deviceConfig?.ListenSchedule}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem value="0">Never</MenuItem>
                                                        <MenuItem value="24">24 Hours</MenuItem>
                                                        <MenuItem value="12">12 Hours</MenuItem>
                                                        <MenuItem value="6">6 Hours</MenuItem>
                                                        <MenuItem value="3">3 Hours</MenuItem>
                                                        <MenuItem value="1">1 Hour</MenuItem>
                                                        
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <div>SMS Transmission Schedule</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <Switch name="SmsTxSchedule" size="small" color="primary" checked={this.state.deviceConfig?.SmsTxSchedule} onChange={this.handleToggleChange.bind(this)} /> Enable
                                            </Grid>
                                                <Grid item xs={2}>
                                                    Transmission Time
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <LocalizationProvider dateAdapter={DateAdapter}>
                                                        <TimePicker
                                                            label="Transmission Time"
                                                            value={this.state.deviceConfig?.SmsTxTime}
                                                            onChange={this.handleSMSTxDateChange}
                                                            disabled={!this.state.deviceConfig?.SmsTxSchedule}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    Transmission Interval
                                            </Grid>
                                                <Grid item xs={2}>
                                                    <Select
                                                        name="SmsTxInterval"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.SmsTxInterval}
                                                        disabled={!this.state.deviceConfig?.SmsTxSchedule}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem value="0">Never</MenuItem>
                                                        <MenuItem value="24">24 Hours</MenuItem>
                                                        <MenuItem value="12">12 Hours</MenuItem>
                                                        <MenuItem value="6">6 Hours</MenuItem>
                                                        <MenuItem value="3">3 Hours</MenuItem>
                                                        <MenuItem value="1">1 Hour</MenuItem>
                                                    </Select>
                                            </Grid>
                                                <Grid item xs={2}>
                                                    Server No 1
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <Select
                                                        name="SmsTxServer1"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.SmsTxServer1}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem key="0" value="0">None</MenuItem>
                                                        {phoneDetails.map((phone: APIPhoneNumber): ReactNode => {
                                                            if (phone.type == "Server") {
                                                                return <MenuItem key={phone.id} value={phone.id}>{phone.name}</MenuItem>;
                                                            }
                                                            else {
                                                                return null
                                                            }
                                                        })
                                                        }
                                                    </Select>
                                            </Grid>
                                                <Grid item xs={2}>
                                                    Server No 2
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <Select
                                                        name="SmsTxServer2"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.SmsTxServer2}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem key="0" value="0">None</MenuItem>
                                                        {phoneDetails.map((phone: APIPhoneNumber): ReactNode => {
                                                            if (phone.type == "Server") {
                                                                return <MenuItem key={phone.id} value={phone.id}>{phone.name}</MenuItem>;
                                                            }
                                                            else {
                                                                return null;
                                                            }
                                                        })
                                                        }
                                                    </Select>
                                            </Grid>
                                            </Grid>
                                        </Box>
                                        <div>SMS Alarm Destination</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    Alarm No 1
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <Select
                                                        name="SMSAlarm1"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.SMSAlarm1}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem key="0" value="0">None</MenuItem>
                                                        {phoneDetails.map((phone: APIPhoneNumber): ReactNode => {
                                                            if (phone.type == "User") {
                                                                return <MenuItem key={phone.id} value={phone.id}>{phone.name}</MenuItem>;
                                                            }
                                                            else {
                                                                return null;
                                                            }
                                                        })
                                                        }
                                                    </Select>
                                            </Grid>
                                                <Grid item xs={2}>
                                                    Alarm No 2
                                            </Grid>
                                                <Grid item xs={4}>
                                                    <Select
                                                        name="SMSAlarm2"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.SMSAlarm2}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem key="0" value="0">None</MenuItem>
                                                        {phoneDetails.map((phone: APIPhoneNumber): ReactNode => {
                                                            if (phone.type == "User") {
                                                                return <MenuItem key={phone.id} value={phone.id}>{phone.name}</MenuItem>;
                                                            }
                                                            else {
                                                                return null;
                                                            }
                                                        })
                                                        }
                                                    </Select>
                                            </Grid>
                                            </Grid>
                                        </Box>
                                    </div>
                                )
                            },
                            {
                                tabName: "Channels",
                                disabled: !this.state.ready,
                                tabContent: (
                                    <div>
                                        {// NOT MODBUS
                                            !this.state.deviceConfig?.ModbusOnly &&
                                            <div>
                                                {// Channel A
                                                    (this.state.channelsAvail & 0x01) != 0 &&
                                                    <div>
                                                        <div>Flow A</div>
                                                        <Box border={2} padding={3}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={3}>
                                                                    <Switch name="FlowA" size="small" color="primary" checked={this.state.deviceConfig?.FlowA} onChange={this.handleToggleChange.bind(this)} /> Enable
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                    Input Type:
                                                                    <Select
                                                                        name="FlowAType"
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.FlowAType}
                                                                        disabled={!this.state.deviceConfig?.FlowA}
                                                                        onChange={this.handleFlowTypeChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="OFF">OFF</MenuItem>
                                                                        <MenuItem value="Forward">Forward</MenuItem>
                                                                        <MenuItem value="Pulse">Pulse</MenuItem>
                                                                    </Select>

                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Channel Ref <TextField name="FlowARef" fullWidth={false} variant="outlined" size="small" value={this.state.deviceConfig?.FlowARef} onChange={this.handleTextfieldChange.bind(this)} disabled={!this.state.deviceConfig?.FlowA} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Meter Reading <TextField name="FlowATotal" fullWidth={false} variant="outlined" size="small" disabled={true} value={this.state.deviceConfig?.FlowATotal} onChange={this.handleTextfieldChange.bind(this)} /> {this.state.deviceConfig?.FlowAUnits}
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Pulse Multiplier
                                                                    <Select
                                                                        name="FlowAMultiplier"
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.FlowAMultiplier}
                                                                        disabled={!this.state.deviceConfig?.FlowA}
                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="0x10">OFF</MenuItem>
                                                                        <MenuItem value="0x11">0.1 litres</MenuItem>
                                                                        <MenuItem value="0x12">0.5 litres</MenuItem>
                                                                        <MenuItem value="0x13">1 litre</MenuItem>
                                                                        <MenuItem value="0x14">2.5 litres</MenuItem>
                                                                        <MenuItem value="0x15">5 litres</MenuItem>
                                                                        <MenuItem value="0x16">10 litres</MenuItem>
                                                                        <MenuItem value="0x17">25 litres</MenuItem>
                                                                        <MenuItem value="0x18">50 litres</MenuItem>
                                                                        <MenuItem value="0x19">100 litres</MenuItem>
                                                                        <MenuItem value="0x1A">250 litres</MenuItem>
                                                                        <MenuItem value="0x1B">500 litres</MenuItem>
                                                                        <MenuItem value="0x1C">1,000 litres</MenuItem>
                                                                        <MenuItem value="0x1D">2,500 litres</MenuItem>
                                                                        <MenuItem value="0x1E">5,000 litres</MenuItem>
                                                                        <MenuItem value="0x1F">10,000 litres</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                </Grid>
                                                                <Grid item xs={4}>

                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    Readings
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    Events
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    Minimal Log
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    Remove Duplicates
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Primary Schedule
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowAPrimaryReadings" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowA} checked={this.state.deviceConfig?.FlowAPrimaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowAPrimaryEvents" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowA || !this.state.deviceConfig?.FlowAPrimaryReadings} checked={this.state.deviceConfig?.FlowAPrimaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowAPrimaryMinLog" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowA || !this.state.deviceConfig?.FlowAPrimaryReadings} checked={this.state.deviceConfig?.FlowAPrimaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowAPrimaryDeDup" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowA || !this.state.deviceConfig?.FlowAPrimaryReadings} checked={this.state.deviceConfig?.FlowAPrimaryDeDup} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Secondary Schedule
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowASecondaryReadings" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowA} checked={this.state.deviceConfig?.FlowASecondaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowASecondaryEvents" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowA || !this.state.deviceConfig?.FlowASecondaryReadings} checked={this.state.deviceConfig?.FlowASecondaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowASecondaryMinLog" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowA || !this.state.deviceConfig?.FlowASecondaryReadings} checked={this.state.deviceConfig?.FlowASecondaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowASecondaryDeDup" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowA || !this.state.deviceConfig?.FlowASecondaryReadings} checked={this.state.deviceConfig?.FlowASecondaryDeDup} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </div>
                                                }
                                                {// Channel B
                                                    (this.state.channelsAvail & 0x02) != 0 &&
                                                    <div>
                                                        <div>Flow B</div>
                                                        <Box border={2} padding={3}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={3}>
                                                                    <Switch name="FlowB" size="small" color="primary" checked={this.state.deviceConfig?.FlowB} onChange={this.handleToggleChange.bind(this)} disabled={this.state.deviceConfig?.FlowBType == "Reverse" || this.state.deviceConfig?.FlowBType == "Direction"} /> Enable
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                    Input Type:
                                                                    <Select
                                                                        name="FlowBType"
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.FlowBType}
                                                                        disabled={!this.state.deviceConfig?.FlowB}
                                                                        onChange={this.handleFlowTypeChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="OFF">OFF</MenuItem>
                                                                        <MenuItem value="Forward">Forward</MenuItem>
                                                                        <MenuItem value="Reverse">Reverse</MenuItem>
                                                                        <MenuItem value="Direction">Direction</MenuItem>
                                                                    </Select>

                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Channel Ref <TextField name="FlowBRef" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.FlowB || this.state.deviceConfig?.FlowBType == "Reverse" || this.state.deviceConfig?.FlowBType == "Direction"} value={this.state.deviceConfig?.FlowBRef} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Meter Reading <TextField name="FlowBTotal" fullWidth={false} variant="outlined" size="small" disabled={true} value={this.state.deviceConfig?.FlowBTotal} onChange={this.handleTextfieldChange.bind(this)} />  {this.state.deviceConfig?.FlowBUnits}
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Pulse Multiplier
                                                                    <Select
                                                                        name="FlowBMultiplier"
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.FlowBMultiplier}
                                                                        disabled={!this.state.deviceConfig?.FlowB || this.state.deviceConfig?.FlowBType == "Reverse" || this.state.deviceConfig?.FlowBType == "Direction"}
                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="0x10">OFF</MenuItem>
                                                                        <MenuItem value="0x11">0.1 litres</MenuItem>
                                                                        <MenuItem value="0x12">0.5 litres</MenuItem>
                                                                        <MenuItem value="0x13">1 litre</MenuItem>
                                                                        <MenuItem value="0x14">2.5 litres</MenuItem>
                                                                        <MenuItem value="0x15">5 litres</MenuItem>
                                                                        <MenuItem value="0x16">10 litres</MenuItem>
                                                                        <MenuItem value="0x17">25 litres</MenuItem>
                                                                        <MenuItem value="0x18">50 litres</MenuItem>
                                                                        <MenuItem value="0x19">100 litres</MenuItem>
                                                                        <MenuItem value="0x1A">250 litres</MenuItem>
                                                                        <MenuItem value="0x1B">500 litres</MenuItem>
                                                                        <MenuItem value="0x1C">1,000 litres</MenuItem>
                                                                        <MenuItem value="0x1D">2,500 litres</MenuItem>
                                                                        <MenuItem value="0x1E">5,000 litres</MenuItem>
                                                                        <MenuItem value="0x1F">10,000 litres</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                </Grid>
                                                                <Grid item xs={4}>

                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    Readings
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    Events
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    Minimal Log
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    Remove Duplicates
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Primary Schedule
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowBPrimaryReadings" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowB || this.state.deviceConfig?.FlowBType == "Reverse" || this.state.deviceConfig?.FlowBType == "Direction"} checked={this.state.deviceConfig?.FlowBPrimaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowBPrimaryEvents" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowB || this.state.deviceConfig?.FlowBType == "Reverse" || this.state.deviceConfig?.FlowBType == "Direction" || !this.state.deviceConfig?.FlowBPrimaryReadings} checked={this.state.deviceConfig?.FlowBPrimaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowBPrimaryMinLog" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowB || this.state.deviceConfig?.FlowBType == "Reverse" || this.state.deviceConfig?.FlowBType == "Direction" || !this.state.deviceConfig?.FlowBPrimaryReadings} checked={this.state.deviceConfig?.FlowBPrimaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowBPrimaryDeDup" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowB || this.state.deviceConfig?.FlowBType == "Reverse" || this.state.deviceConfig?.FlowBType == "Direction" || !this.state.deviceConfig?.FlowBPrimaryReadings} checked={this.state.deviceConfig?.FlowBPrimaryDeDup} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Secondary Schedule
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowBSecondaryReadings" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowB || this.state.deviceConfig?.FlowBType == "Reverse" || this.state.deviceConfig?.FlowBType == "Direction"} checked={this.state.deviceConfig?.FlowBSecondaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowBSecondaryEvents" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowB || this.state.deviceConfig?.FlowBType == "Reverse" || this.state.deviceConfig?.FlowBType == "Direction" || !this.state.deviceConfig?.FlowBSecondaryReadings} checked={this.state.deviceConfig?.FlowBSecondaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowBSecondaryMinLog" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowB || this.state.deviceConfig?.FlowBType == "Reverse" || this.state.deviceConfig?.FlowBType == "Direction" || !this.state.deviceConfig?.FlowBSecondaryReadings} checked={this.state.deviceConfig?.FlowBSecondaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Switch name="FlowBSecondaryDeDup" size="small" color="primary" disabled={!this.state.deviceConfig?.FlowB || this.state.deviceConfig?.FlowBType == "Reverse" || this.state.deviceConfig?.FlowBType == "Direction" || !this.state.deviceConfig?.FlowBSecondaryReadings} checked={this.state.deviceConfig?.FlowBSecondaryDeDup} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </div>
                                                }
                                                {// Channel C
                                                    ((this.state.channelsAvail & 0x04) != 0 || (this.state.channelsAvail & 0x20) != 0) &&
                                                    <div>
                                                        <div>Analogue C</div>
                                                        <Box border={2} padding={3}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueC" size="small" color="primary" checked={this.state.deviceConfig?.AnalogueC} onChange={this.handleToggleChange.bind(this)} /> Enable
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Input type:  {this.state.deviceConfig?.AnalogueCType}
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    Transducer range: {this.state.deviceConfig?.AnalogueCSensor}
                                                                </Grid>

                                                                <Grid item xs={2}>
                                                                    Channel Ref:
                                                                </Grid>
                                                                <Grid item xs={10}>
                                                                    <TextField name="AnalogueCRef" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueC} value={this.state.deviceConfig?.AnalogueCRef} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </Grid>
                                                            </Grid>
                                                            {this.state.deviceConfig?.AnalogueCType != "Pressure" &&
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={4}>
                                                                        Start Point: 
                                                                        <TextField name="AnalogueCStartPoint" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueC} value={this.state.deviceConfig?.AnalogueCTransfer?.StartPoint} onChange={this.handleTransferChange.bind(this)} />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        Units: 
                                                                        <TextField name="AnalogueCUnits" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueC} value={this.state.deviceConfig?.AnalogueCUnits} onChange={this.handleTextfieldChange.bind(this)} />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        Decimal places:
                                                                        <TextField name="AnalogueCDp" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueC} value={this.state.deviceConfig?.AnalogueCDp} onChange={this.handleTextfieldChange.bind(this)} />
                                                                    </Grid>

                                                                    <Grid item xs={4}>
                                                                        End Point:
                                                                        <TextField name="AnalogueCEndPoint" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueC} value={this.state.deviceConfig?.AnalogueCTransfer?.EndPoint} onChange={this.handleTransferChange.bind(this)} />
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        &nbsp;
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                            {this.state.deviceConfig?.AnalogueCType == "Pressure" &&
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={6}>
                                                                        Units:
                                                                        <Select
                                                                            style={{ margin: 0, paddingBottom: 0 }}
                                                                            value={this.state.deviceConfig?.AnalogueCUnits}
                                                                            name="AnalogueCUnits"
                                                                            disabled={!this.state.deviceConfig?.AnalogueC}
                                                                            onChange={this.handleSelectChange.bind(this)}
                                                                        >
                                                                            <MenuItem value=""></MenuItem>
                                                                            <MenuItem value="Bar">Bar</MenuItem>
                                                                            <MenuItem value="mb">millibar</MenuItem>
                                                                            <MenuItem value="mwg">mwg</MenuItem>
                                                                            <MenuItem value="psi">psi</MenuItem>
                                                                            <MenuItem value="mm">mm (Rainfall)</MenuItem>
                                                                        </Select>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        Decimal places:
                                                                        <TextField name="AnalogueCDp" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueC} value={this.state.deviceConfig?.AnalogueCDp} onChange={this.handleTextfieldChange.bind(this)} />
                                                                    </Grid>
                                                                </Grid>
                                                            }

                                                            <Grid container spacing={2}>
                                                                <Grid item xs={3}>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Readings
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Events
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Minimal Log
                                                                </Grid>

                                                                <Grid item xs={3}>
                                                                    Primary Schedule
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueCPrimaryReadings" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueC} checked={this.state.deviceConfig?.AnalogueCPrimaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueCPrimaryEvents" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueC || !this.state.deviceConfig?.AnalogueCPrimaryReadings} checked={this.state.deviceConfig?.AnalogueCPrimaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueCPrimaryMinLog" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueC || !this.state.deviceConfig?.AnalogueCPrimaryReadings} checked={this.state.deviceConfig?.AnalogueCPrimaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>

                                                                <Grid item xs={3}>
                                                                    Secondary Schedule
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueCSecondaryReadings" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueC} checked={this.state.deviceConfig?.AnalogueCSecondaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueCSecondaryEvents" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueC || !this.state.deviceConfig?.AnalogueCSecondaryReadings} checked={this.state.deviceConfig?.AnalogueCSecondaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueCSecondaryMinLog" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueC || !this.state.deviceConfig?.AnalogueCSecondaryReadings} checked={this.state.deviceConfig?.AnalogueCSecondaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </div>
                                                }
                                                {// Channel D
                                                    ((this.state.channelsAvail & 0x08) != 0 || (this.state.channelsAvail & 0x40) != 0) &&
                                                    <div>
                                                        <div>Analogue D</div>
                                                        <Box border={2} padding={3}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueD" size="small" color="primary" checked={this.state.deviceConfig?.AnalogueD} onChange={this.handleToggleChange.bind(this)} /> Enable
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Input type: {this.state.deviceConfig?.AnalogueDType}  
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    Transducer range: {this.state.deviceConfig?.AnalogueDSensor}
                                                                </Grid>

                                                                <Grid item xs={2}>
                                                                    Channel Ref:
                                                                </Grid>
                                                                <Grid item xs={10}>
                                                                    <TextField name="AnalogueDRef" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueD} value={this.state.deviceConfig?.AnalogueDRef} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </Grid>
                                                            </Grid>

                                                            {this.state.deviceConfig?.AnalogueDType != "Pressure" &&
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={4}>
                                                                        Start Point:
                                                                        <TextField name="AnalogueDStartPoint" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueD} value={this.state.deviceConfig?.AnalogueDTransfer?.StartPoint} onChange={this.handleTransferChange.bind(this)} />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        Units:
                                                                        <TextField name="AnalogueDUnits" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueD} value={this.state.deviceConfig?.AnalogueDUnits} onChange={this.handleTextfieldChange.bind(this)} />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        Decimal places:
                                                                        <TextField name="AnalogueDDp" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueD} value={this.state.deviceConfig?.AnalogueDDp} onChange={this.handleTextfieldChange.bind(this)} />
                                                                    </Grid>

                                                                    <Grid item xs={4}>
                                                                        End Point:
                                                                        <TextField name="AnalogueDEndPoint" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueD} value={this.state.deviceConfig?.AnalogueDTransfer?.EndPoint} onChange={this.handleTransferChange.bind(this)} />
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        &nbsp;
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                            {this.state.deviceConfig?.AnalogueDType == "Pressure" &&
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={6}>
                                                                        Units:
                                                                        <Select
                                                                            style={{ margin: 0, paddingBottom: 0 }}
                                                                            value={this.state.deviceConfig?.AnalogueDUnits}
                                                                            name="AnalogueDUnits"
                                                                            disabled={!this.state.deviceConfig?.AnalogueD}
                                                                            onChange={this.handleSelectChange.bind(this)}
                                                                        >
                                                                            <MenuItem value=""></MenuItem>
                                                                            <MenuItem value="Bar">Bar</MenuItem>
                                                                            <MenuItem value="mb">millibar</MenuItem>
                                                                            <MenuItem value="mwg">mwg</MenuItem>
                                                                            <MenuItem value="psi">psi</MenuItem>
                                                                            <MenuItem value="mm">mm (Rainfall)</MenuItem>
                                                                        </Select>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        Decimal places:
                                                                        <TextField name="AnalogueDDp" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueD} value={this.state.deviceConfig?.AnalogueDDp} onChange={this.handleTextfieldChange.bind(this)} />
                                                                    </Grid>
                                                                </Grid>
                                                            }

                                                            <Grid container spacing={2}>
                                                                <Grid item xs={3}>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Readings
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Events
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Minimal Log
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Primary Schedule
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueDPrimaryReadings" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueD} checked={this.state.deviceConfig?.AnalogueDPrimaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueDPrimaryEvents" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueD || !this.state.deviceConfig?.AnalogueDPrimaryReadings} checked={this.state.deviceConfig?.AnalogueDPrimaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueDPrimaryMinLog" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueD || !this.state.deviceConfig?.AnalogueDPrimaryReadings} checked={this.state.deviceConfig?.AnalogueDPrimaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Secondary Schedule
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueDSecondaryReadings" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueD} checked={this.state.deviceConfig?.AnalogueDSecondaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueDSecondaryEvents" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueD || !this.state.deviceConfig?.AnalogueDSecondaryReadings} checked={this.state.deviceConfig?.AnalogueDSecondaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueDSecondaryMinLog" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueD || !this.state.deviceConfig?.AnalogueDSecondaryReadings} checked={this.state.deviceConfig?.AnalogueDSecondaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </div>
                                                }
                                                {// Channel E
                                                    ((this.state.channelsAvail & 0x10) != 0 || (this.state.channelsAvail & 0x80) != 0) &&
                                                    <div>
                                                        <div>Analogue E</div>
                                                        <Box border={2} padding={3}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueE" size="small" color="primary" checked={this.state.deviceConfig?.AnalogueE} onChange={this.handleToggleChange.bind(this)} /> Enable
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    Transducer range:
                                                                </Grid>
                                                                <Grid item xs={7}>
                                                                    {this.state.deviceConfig?.AnalogueEType}
                                                                </Grid>

                                                                <Grid item xs={2}>
                                                                    Channel Ref:
                                                                </Grid>
                                                                <Grid item xs={10}>
                                                                    <TextField name="AnalogueERef" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueE} value={this.state.deviceConfig?.AnalogueERef} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </Grid>

                                                                <Grid item xs={2}>
                                                                    Units
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Select
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.AnalogueEUnits}
                                                                        name="AnalogueEUnits"
                                                                        disabled={!this.state.deviceConfig?.AnalogueE}
                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        <MenuItem value="Bar">Bar</MenuItem>
                                                                        <MenuItem value="mb">millibar</MenuItem>
                                                                        <MenuItem value="mwg">mwg</MenuItem>
                                                                        <MenuItem value="psi">psi</MenuItem>
                                                                        <MenuItem value="mm">mm (Rainfall)</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Decimal places:
                                                                    <TextField name="AnalogueEDp" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueE} value={this.state.deviceConfig?.AnalogueEDp} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    Zero to Atmosphere
                                                                </Grid>

                                                                <Grid item xs={3}>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Readings
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Events
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Minimal Log
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Primary Schedule
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueEPrimaryReadings" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueE} checked={this.state.deviceConfig?.AnalogueEPrimaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueEPrimaryEvents" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueE || !this.state.deviceConfig?.AnalogueEPrimaryReadings} checked={this.state.deviceConfig?.AnalogueEPrimaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueEPrimaryMinLog" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueE || !this.state.deviceConfig?.AnalogueEPrimaryReadings} checked={this.state.deviceConfig?.AnalogueEPrimaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    Secondary Schedule
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueESecondaryReadings" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueE} checked={this.state.deviceConfig?.AnalogueESecondaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueESecondaryEvents" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueE || !this.state.deviceConfig?.AnalogueESecondaryReadings} checked={this.state.deviceConfig?.AnalogueESecondaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Switch name="AnalogueESecondaryMinLog" size="small" color="primary" disabled={!this.state.deviceConfig?.AnalogueE || !this.state.deviceConfig?.AnalogueESecondaryReadings} checked={this.state.deviceConfig?.AnalogueESecondaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {// MODBUS
                                            this.state.deviceConfig?.ModbusOnly &&
                                            <div>
                                                <div>Communication protocol and Device Supply</div>
                                                <Box border={2} padding={3}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={2}>
                                                            Baud Rate
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            Data bits
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            Parity
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            Device Supply
                                                        </Grid>
                                                        {// Second row 
                                                        }
                                                        <Grid item xs={2}>
                                                            <Select
                                                                style={{ margin: 0, paddingBottom: 0 }}
                                                                value={this.state.deviceConfig?.ModbusBitrate}
                                                                name="ModbusBitrate"
                                                                onChange={this.handleSelectChange.bind(this)}
                                                            >
                                                                <MenuItem value="01">300</MenuItem>
                                                                <MenuItem value="02">600</MenuItem>
                                                                <MenuItem value="03">1200</MenuItem>
                                                                <MenuItem value="04">2400</MenuItem>
                                                                <MenuItem value="05">4800</MenuItem>
                                                                <MenuItem value="06">9600</MenuItem>
                                                                <MenuItem value="07">19200</MenuItem>
                                                                <MenuItem value="08">38400</MenuItem>
                                                                <MenuItem value="09">57600</MenuItem>
                                                                <MenuItem value="0A">115200</MenuItem>
                                                            </Select>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Select
                                                                variant="outlined"
                                                                style={{ margin: 0, paddingBottom: 0 }}
                                                                value={this.state.deviceConfig?.ModbusDatabits}
                                                                name="ModbusDatabits"
                                                                onChange={this.handleSelectChange.bind(this)}
                                                            >
                                                                <MenuItem value="7">7</MenuItem>
                                                                <MenuItem value="8">8</MenuItem>
                                                            </Select>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Select
                                                                style={{ margin: 0, paddingBottom: 0 }}
                                                                value={this.state.deviceConfig?.ModbusParity}
                                                                name="ModbusParity"
                                                                onChange={this.handleSelectChange.bind(this)}
                                                            >
                                                                <MenuItem value="None">No Parity</MenuItem>
                                                                <MenuItem value="Odd">Odd Parity</MenuItem>
                                                                <MenuItem value="Even">Even Parity</MenuItem>
                                                            </Select>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                style={{ margin: 0, paddingBottom: 0 }}
                                                                value={this.state.deviceConfig?.ModbusVoltage}
                                                                name="ModbusVoltage"
                                                                onChange={this.handleSelectChange.bind(this)}
                                                            >
                                                                <MenuItem value="00">0V</MenuItem>
                                                                <MenuItem value="05">5V</MenuItem>
                                                                <MenuItem value="08">8V</MenuItem>
                                                                <MenuItem value="0A">10V</MenuItem>
                                                                <MenuItem value="0C">12V</MenuItem>
                                                            </Select>
                                                            <FormControl style={{ marginTop: -5}}>
                                                                <NumericInput step={0.1} precision={1} min={0} max={2} snap
                                                                    style={{ input: { marginTop: 10, marginBottom: 10, height: 50, width: 100 }}}
                                                                    value={this.state.deviceConfig?.ModbusSettle}
                                                                    name="ModbusSettle"
                                                                    onChange={this.handleNumericChange.bind(this)}
                                                                 />
                                                            </FormControl> Secs
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                    <div>Modbus 1 (Ch C)</div>
                                                    
                                                <Box border={2} padding={3}>
                                                        <Grid container spacing={2}>
                                                        <Grid item xs={3}>
                                                            <Switch name="AnalogueC" size="small" color="secondary" checked={this.state.deviceConfig?.AnalogueC} onChange={this.handleToggleChange.bind(this)} /> Enable
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                                <Button style={{marginTop: "-10px"}} color="primary" variant="contained" onClick={(): void => this.displayModbusModal(1)}>Configure MODBUS</Button>                                                            
                                                         </Grid>
                                                            <Grid item xs={3}>
                                                                <div style={{display: "flex", marginTop: "-15px"} }>
                                                                <div style={{margin: "15px 5px 0 0"} }>Offset:</div>
                                                                    <NumericInput step={this.state.decimalPlaces.c} min={-32768} max={32767}
                                                                        style={{ input: { marginTop: 5, marginBottom: 5, height: 40, width: 180 } }}
                                                                        value={this.state.deviceConfig?.Modbus1Offset}
                                                                        name="Modbus1Offset"
                                                                        onChange={this.handleNumericChange.bind(this)}
                                                                    />
                                                                </div>      
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="ModbusPower1" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueC} checked={this.state.deviceConfig?.ModbusPower1} onChange={this.handleToggleChange.bind(this)} /> Power Device
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                Channel Ref:
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <div style={{ marginTop: "-10px"}}>
                                                                <TextField name="AnalogueCRef" fullWidth={true}  size="small" disabled={!this.state.deviceConfig?.AnalogueC} value={this.state.deviceConfig?.AnalogueCRef} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <div style={{ display: "flex", marginTop: "-10px"}}>
                                                                    <div style={{margin: "10px 5px 0 0"} }>Units:</div>
                                                                    <TextField name="AnalogueCUnits" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueC} value={this.state.deviceConfig?.AnalogueCUnits} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </div> 
                                                            </Grid>
                                                           
                                                            <Grid item xs={3}>
                                                                <div style={{ display: "flex", marginTop: "-5px" }}>
                                                                    <div style={{ margin: "5px 5px 0 0" }}>Decimal places:</div>
                                                                    <FormControl style={{ marginTop: -10 }}>
                                                                        <NumericInput step={1} precision={0} min={-6} max={6} snap
                                                                            style={{ input: { marginTop: 5, marginBottom: 5, height: 40, width: 180 } }}
                                                                            value={this.state.deviceConfig?.AnalogueCDp ? this.state.deviceConfig?.AnalogueCDp : 0}
                                                                            disabled={!this.state.deviceConfig?.AnalogueC}
                                                                            name="AnalogueCDp"
                                                                            onChange={this.handleNumericChange.bind(this)}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </Grid>
                                                            

                                                        <Grid item xs={3}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            Readings
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            Events
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            Minimal Log
                                                        </Grid>
                                                            
                                                        <Grid item xs={3}>
                                                            Primary Schedule
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                                <Switch name="AnalogueCPrimaryReadings" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueC} checked={this.state.deviceConfig?.AnalogueCPrimaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                                <Switch name="AnalogueCPrimaryEvents" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueC || !this.state.deviceConfig?.AnalogueCPrimaryReadings} checked={this.state.deviceConfig?.AnalogueCPrimaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                                <Switch name="AnalogueCPrimaryMinLog" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueC || !this.state.deviceConfig?.AnalogueCPrimaryReadings} checked={this.state.deviceConfig?.AnalogueCPrimaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                        </Grid>
                                                        
                                                        <Grid item xs={3}>
                                                            Secondary Schedule
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                                <Switch name="AnalogueCSecondaryReadings" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueC} checked={this.state.deviceConfig?.AnalogueCSecondaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                                <Switch name="AnalogueCSecondaryEvents" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueC || !this.state.deviceConfig?.AnalogueCSecondaryReadings} checked={this.state.deviceConfig?.AnalogueCSecondaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                                <Switch name="AnalogueCSecondaryMinLog" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueC || !this.state.deviceConfig?.AnalogueCSecondaryReadings} checked={this.state.deviceConfig?.AnalogueCSecondaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                    <div>Modbus 2 (Ch D)</div>
                                                    <Box border={2} padding={3}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueD" size="small" color="secondary" checked={this.state.deviceConfig?.AnalogueD} onChange={this.handleToggleChange.bind(this)} /> Enable
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Button style={{ marginTop: "-10px" }} color="primary" variant="contained" onClick={(): void => this.displayModbusModal(2)}>Configure MODBUS</Button>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <div style={{ display: "flex", marginTop: "-15px" }}>
                                                                    <div style={{ margin: "15px 5px 0 0" }}>Offset:</div>
                                                                    <NumericInput step={this.state.decimalPlaces.d} min={-32768} max={32767}
                                                                        style={{ input: { marginTop: 5, marginBottom: 5, height: 40, width: 180 } }}
                                                                        value={this.state.deviceConfig?.Modbus2Offset}
                                                                        name="Modbus2Offset"
                                                                        onChange={this.handleNumericChange.bind(this)}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="ModbusPower2" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueD} checked={this.state.deviceConfig?.ModbusPower2} onChange={this.handleToggleChange.bind(this)} /> Power Device
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                Channel Ref:
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <div style={{ marginTop: "-10px" }}>
                                                                    <TextField name="AnalogueDRef" fullWidth={true} size="small" disabled={!this.state.deviceConfig?.AnalogueD} value={this.state.deviceConfig?.AnalogueDRef} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <div style={{ display: "flex", marginTop: "-10px" }}>
                                                                    <div style={{ margin: "10px 5px 0 0" }}>Units:</div>
                                                                    <TextField name="AnalogueDUnits" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueD} value={this.state.deviceConfig?.AnalogueDUnits} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </div>
                                                            </Grid>

                                                            <Grid item xs={3}>
                                                                <div style={{ display: "flex", marginTop: "-5px" }}>
                                                                    <div style={{ margin: "5px 5px 0 0" }}>Decimal places:</div>
                                                                    <FormControl style={{ marginTop: -10 }}>
                                                                        <NumericInput step={1} precision={0} min={-6} max={6} snap
                                                                            style={{ input: { marginTop: 5, marginBottom: 5, height: 40, width: 180 } }}
                                                                            value={this.state.deviceConfig?.AnalogueDDp ? this.state.deviceConfig?.AnalogueDDp : 0}
                                                                            disabled={!this.state.deviceConfig?.AnalogueD}
                                                                            name="AnalogueDDp"
                                                                            onChange={this.handleNumericChange.bind(this)}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </Grid>


                                                            <Grid item xs={3}>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                Readings
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                Events
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                Minimal Log
                                                            </Grid>

                                                            <Grid item xs={3}>
                                                                Primary Schedule
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueDPrimaryReadings" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueD} checked={this.state.deviceConfig?.AnalogueDPrimaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueDPrimaryEvents" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueD || !this.state.deviceConfig?.AnalogueDPrimaryReadings} checked={this.state.deviceConfig?.AnalogueDPrimaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueDPrimaryMinLog" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueD || !this.state.deviceConfig?.AnalogueDPrimaryReadings} checked={this.state.deviceConfig?.AnalogueDPrimaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                            </Grid>

                                                            <Grid item xs={3}>
                                                                Secondary Schedule
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueDSecondaryReadings" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueD} checked={this.state.deviceConfig?.AnalogueDSecondaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueDSecondaryEvents" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueD || !this.state.deviceConfig?.AnalogueDSecondaryReadings} checked={this.state.deviceConfig?.AnalogueDSecondaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueDSecondaryMinLog" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueD || !this.state.deviceConfig?.AnalogueDSecondaryReadings} checked={this.state.deviceConfig?.AnalogueDSecondaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                
                                                <div>Modbus 3 (Ch E)</div>
                                                    <Box border={2} padding={3}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueE" size="small" color="secondary" checked={this.state.deviceConfig?.AnalogueE} onChange={this.handleToggleChange.bind(this)} /> Enable
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Button style={{ marginTop: "-10px" }} color="primary" variant="contained" onClick={(): void => this.displayModbusModal(3)}>Configure MODBUS</Button>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <div style={{ display: "flex", marginTop: "-15px" }}>
                                                                    <div style={{ margin: "15px 5px 0 0" }}>Offset:</div>
                                                                    <NumericInput step={this.state.decimalPlaces.e} min={-32768} max={32767}
                                                                        style={{ input: { marginTop: 5, marginBottom: 5, height: 40, width: 180 } }}
                                                                        value={this.state.deviceConfig?.Modbus3Offset}
                                                                        name="Modbus3Offset"
                                                                        onChange={this.handleNumericChange.bind(this)}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="ModbusPower3" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueE} checked={this.state.deviceConfig?.ModbusPower3} onChange={this.handleToggleChange.bind(this)} /> Power Device
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                Channel Ref:
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <div style={{ marginTop: "-10px" }}>
                                                                    <TextField name="AnalogueERef" fullWidth={true} size="small" disabled={!this.state.deviceConfig?.AnalogueE} value={this.state.deviceConfig?.AnalogueERef} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <div style={{ display: "flex", marginTop: "-10px" }}>
                                                                    <div style={{ margin: "10px 5px 0 0" }}>Units:</div>
                                                                    <TextField name="AnalogueEUnits" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueE} value={this.state.deviceConfig?.AnalogueEUnits} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </div>
                                                            </Grid>

                                                            <Grid item xs={3}>
                                                                <div style={{ display: "flex", marginTop: "-5px" }}>
                                                                    <div style={{ margin: "5px 5px 0 0" }}>Decimal places:</div>
                                                                    <FormControl style={{ marginTop: -10 }}>
                                                                        <NumericInput step={1} precision={0} min={-6} max={6} snap
                                                                            style={{ input: { marginTop: 5, marginBottom: 5, height: 40, width: 180 } }}
                                                                            value={this.state.deviceConfig?.AnalogueEDp ? this.state.deviceConfig?.AnalogueEDp : 0}
                                                                            disabled={!this.state.deviceConfig?.AnalogueE}
                                                                            name="AnalogueEDp"
                                                                            onChange={this.handleNumericChange.bind(this)}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </Grid>


                                                            <Grid item xs={3}>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                Readings
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                Events
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                Minimal Log
                                                            </Grid>

                                                            <Grid item xs={3}>
                                                                Primary Schedule
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueEPrimaryReadings" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueE} checked={this.state.deviceConfig?.AnalogueEPrimaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueEPrimaryEvents" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueE || !this.state.deviceConfig?.AnalogueEPrimaryReadings} checked={this.state.deviceConfig?.AnalogueEPrimaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueEPrimaryMinLog" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueE || !this.state.deviceConfig?.AnalogueEPrimaryReadings} checked={this.state.deviceConfig?.AnalogueEPrimaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                            </Grid>

                                                            <Grid item xs={3}>
                                                                Secondary Schedule
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueESecondaryReadings" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueE} checked={this.state.deviceConfig?.AnalogueESecondaryReadings} onChange={this.handleToggleChange.bind(this)} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueESecondaryEvents" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueE || !this.state.deviceConfig?.AnalogueESecondaryReadings} checked={this.state.deviceConfig?.AnalogueESecondaryEvents} onChange={this.handleToggleChange.bind(this)} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Switch name="AnalogueESecondaryMinLog" size="small" color="secondary" disabled={!this.state.deviceConfig?.AnalogueE || !this.state.deviceConfig?.AnalogueESecondaryReadings} checked={this.state.deviceConfig?.AnalogueESecondaryMinLog} onChange={this.handleToggleChange.bind(this)} />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                            </div>
                                        }
                                    </div>
                                )
                            },
                            {
                                tabName: "Alarms",
                                disabled: !this.state.ready,
                                tabContent: (
                                    <div>
                                        {this.state.alarmTab == 0 ?
                                            <div>
                                                Double click row to edit alarm details
                                            </div>
                                            :
                                            <div>
                                                Use &quot;Save&quot; to update alarm settings
                                            </div>
                                        }
                                        <CustomTabs
                                            headerColor="primary"
                                            plainTabs
                                            value={this.state.alarmTab}
                                            onChange={this.handleAlarmTabChange}
                                            tabs={[
                                                {
                                                    tabName: "List",
                                                    tabContent: (
                                                        <div>
                                                            <Box border={2} padding={3}>
                                                                <DataGrid
                                                                    dataSource={this.state.deviceConfig?.Alarms}
                                                                    keyExpr="Idx"
                                                                    onRowDblClick={this.handleAlarmRowDblClick}
                                                                >
                                                                    <Selection mode="single"  />
                                                                    <Column dataField="Idx" dataType="number" width={40} />
                                                                    <Column dataField="Input" dataType="string" customizeText={this.inputColumnCustomizeText}/>
                                                                    <Column dataField="Message" dataType="string" />
                                                                    <Column dataField="SetThreshold" dataType="number" />
                                                                    <Column dataField="ClearThreshold" dataType="number" />
                                                                </DataGrid>
                                                            </Box>
                                                        </div>
                                                    )
                                                },
                                                {
                                                    tabName: "Details",
                                                    disabled: !this.state.editAlarm,
                                                    tabContent: (
                                                        <div>
                                                            <Box border={2} padding={3}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={2}>
                                                                        <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
                                                                            Alarm {this.state.editAlarm?.Idx}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <h5>Set Alarm {this.state.editAlarm?.Idx}</h5>
                                                                    </Grid>
                                                                    
                                                                    <Grid item xs={6}>
                                                                        <TextField name="Message" label="Message" fullWidth={true} variant="outlined" size="small" value={this.state.editAlarm?.Message} onChange={this.handleAlarmTextfieldChange.bind(this)} />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Switch name="EventAlarm" size="small" color="primary" checked={this.state.editAlarm?.EventAlarm} onChange={this.handleAlarmToggleChange.bind(this)} /> Event Alarm
                                                                    </Grid>

                                                                    <Grid item xs={3}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="input-select-label">Input</InputLabel>
                                                                            <Select
                                                                            name="Input"
                                                                            label="Input"
                                                                            labelId="input-select-label"
                                                                            size="small"
                                                                            style={{ margin: 0, paddingBottom: 0 }}
                                                                            value={this.state.editAlarm?.Input}
                                                                            onChange={this.handleAlarmSelectChange.bind(this)}
                                                                        >
                                                                                <MenuItem value="Off">Alarm Off</MenuItem>
                                                                                {(this.state.channelsAvail & 0x01) != 0 &&
                                                                                    <MenuItem value="A">Flow A</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x02) != 0 &&
                                                                                    <MenuItem value="B">Flow B</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x04) != 0 &&
                                                                                    <MenuItem value="C">Channel C</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x08) != 0 &&
                                                                                    <MenuItem value="D">Channel D</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x10) != 0 &&
                                                                                    <MenuItem value="E">Channel E</MenuItem>
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="operator-select-label">Set Operator</InputLabel>
                                                                            <Select
                                                                                name="SetOperator"
                                                                                label="Set Operator"
                                                                                labelId="operator-select-label"
                                                                                size="small"
                                                                                style={{ margin: 0, paddingBottom: 0 }}
                                                                                value={this.state.editAlarm?.SetOperator}
                                                                                onChange={this.handleAlarmSelectChange.bind(this)}
                                                                            >
                                                                                <MenuItem value="Off">Alarm Off</MenuItem>
                                                                                <MenuItem value="EQ">Equal to</MenuItem>
                                                                                <MenuItem value="NE">Not Equal to</MenuItem>
                                                                                <MenuItem value="LT">Less than</MenuItem>
                                                                                <MenuItem value="GT">Greater than</MenuItem>
                                                                                <MenuItem value="LE">Less than or equal to</MenuItem>
                                                                                <MenuItem value="GE">Greater than or equal to</MenuItem>
                                                                                <MenuItem value="BT">Bit Test</MenuItem>
                                                                            </Select>
                                                                            </FormControl>
                                                                    </Grid>
                                                                    

                                                                    <Grid item xs={2}>
                                                                        <div style={{display: "flex"}}>
                                                                            <TextField name="SetThreshold" label="Set Threshold" fullWidth={true} variant="outlined" size="small" value={this.makeDecimal(this.state.editAlarm?.SetThreshold)} onChange={this.handleAlarmTextfieldChange.bind(this)} />
                                                                            <p style={{margin: "10px 0 0 7px"} }>{(this.state.editAlarm?.Input == "A" || this.state.editAlarm?.Input == "B") ? "l/s" : units}</p>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <TextField name="SetCount" label="Set Count" fullWidth={true} variant="outlined" size="small" value={this.state.editAlarm?.SetCount} onChange={this.handleAlarmTextfieldChange.bind(this)} />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <TextField name="MaxAlarms" label="Max Alarms" fullWidth={false} variant="outlined" size="small" value={this.state.editAlarm?.MaxAlarms} onChange={this.handleAlarmTextfieldChange.bind(this)} />
                                                                    </Grid>
                                                                    

                                                                    <Grid item xs={4}>
                                                                        <TextField name="ClearThreshold" label="Clear Threshold" fullWidth={false} variant="outlined" size="small" value={this.makeDecimal(this.state.editAlarm?.ClearThreshold)} onChange={this.handleAlarmTextfieldChange.bind(this)} /> {(this.state.editAlarm?.Input == "A" || this.state.editAlarm?.Input == "B") ? "l/s" : units}
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField name="ClearCount" label="Clear Count" fullWidth={false} variant="outlined" size="small" value={this.state.editAlarm?.ClearCount} onChange={this.handleAlarmTextfieldChange.bind(this)} />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                    </Grid>

                                                                    <Grid item xs={6}>
                                                                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                                                            SMS Actions
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                                                            Data Actions
                                                                        </Typography>
                                                                    </Grid>
                                                                    

                                                                    <Grid item xs={1}>
                                                                        Set
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        Clear
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        Set
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        Clear
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                    </Grid>

                                                                    

                                                                    <Grid item xs={1}>
                                                                        <Switch name="SmsActionSetMessage" size="small" color="primary" checked={this.state.editAlarm?.SmsActionSetMessage} onChange={this.handleAlarmToggleChange.bind(this)} /> 
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Switch name="SmsActionClrMessage" size="small" color="primary" checked={this.state.editAlarm?.SmsActionClrMessage} onChange={this.handleAlarmToggleChange.bind(this)} /> 
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        Create Readable Message
                                                                    </Grid>

                                                                    <Grid item xs={1}>
                                                                        <Switch name="GprsActionSetConnect" size="small" color="primary" checked={this.state.editAlarm?.GprsActionSetConnect} onChange={this.handleAlarmToggleChange.bind(this)} />
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Switch name="GprsActionClrConnect" size="small" color="primary" checked={this.state.editAlarm?.GprsActionClrConnect} onChange={this.handleAlarmToggleChange.bind(this)} />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        Connect to Server
                                                                    </Grid>

                                                                    <Grid item xs={1}>
                                                                        <Switch name="SmsActionSetFastline" size="small" color="primary" checked={this.state.editAlarm?.SmsActionSetFastline} onChange={this.handleAlarmToggleChange.bind(this)} /> 
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Switch name="SmsActionClrFastline" size="small" color="primary" checked={this.state.editAlarm?.SmsActionClrFastline} onChange={this.handleAlarmToggleChange.bind(this)} /> 
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        Perform Fastline
                                                                    </Grid>

                                                                    <Grid item xs={1}>
                                                                        <Switch name="SmsActionSetSendData" size="small" color="primary" checked={this.state.editAlarm?.SmsActionSetSendData} onChange={this.handleAlarmToggleChange.bind(this)} /> 
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Switch name="SmsActionClrSendData" size="small" color="primary" checked={this.state.editAlarm?.SmsActionClrSendData} onChange={this.handleAlarmToggleChange.bind(this)} /> 
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        Send Unsent Data
                                                                    </Grid>

                                                                    <Grid item xs={1}>
                                                                        <Switch name="SmsActionSetSendUpdate" size="small" color="primary" checked={this.state.editAlarm?.SmsActionSetSendUpdate} onChange={this.handleAlarmToggleChange.bind(this)} /> 
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Switch name="SmsActionClrSendUpdate" size="small" color="primary" checked={this.state.editAlarm?.SmsActionClrSendUpdate} onChange={this.handleAlarmToggleChange.bind(this)} /> 
                                                                    </Grid>
                                                                    <Grid item xs={9}>
                                                                        Send Server Update SMS
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Button variant="contained" color="primary" onClick={(): void => this.updateAlarm()}>Save</Button>
                                                                    </Grid>
                                                                    

                                                                    
                                                                </Grid>
                                                            </Box>
                                                        </div>
                                                    )
                                                },
                                            ]}
                                        />
                                    </div>
                                )
                            },
                            sessionStorage.getItem("userLevel") == "useradmin" || sessionStorage.getItem("userLevel") == "identityadmin" ?
                                {
                                    tabName: "Custom",
                                    disabled: !this.state.ready,
                                    tabContent: (
                                        <div>
                                            <p>Custom Settings</p>
                                            <TextField
                                                id="generalUpdate"
                                                label="Updates 0000=xxx (one per line)"
                                                onChange={this.configUpdateChanged}
                                                variant="outlined"
                                                multiline
                                                fullWidth
                                            />
                                        </div>
                                    )
                                } : null,
                        ]}
                    />
                }
                {this.state.panel == "SIM Details" &&
                    <Card>
                        <CardHeader color="primary" >
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                edge="start"
                                color="inherit"
                                onClick={(): void => this.handleViewMenuClose()}
                                aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        SIM Details
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        </CardHeader>
                        <CardBody>
                        <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                            <DataGrid
                                dataSource={this.state.simDetails}
                                keyExpr="Id"
                                onRowInserted={this.onSimInserted.bind(this)}
                                onEditingStart={this.onSimEditingStart.bind(this)}
                                onEditCanceled={this.onSimEditCancelled.bind(this)}
                                onSaved={this.onSimSaved.bind(this)}
                                onRowRemoved={this.onSimDeleted.bind(this)}
                            >
                                    <Column dataField="name" dataType="string" />
                                    <Column dataField="apn" dataType="string" />
                                    <Column dataField="username" dataType="string" />
                                    <Column dataField="password" dataType="string" />
                                <Column dataField="description" dataType="string" />
                                <Editing
                                    mode="row"
                                    allowUpdating={true}
                                    allowDeleting={true}
                                    allowAdding={true} />
                            </DataGrid>
                            </div>
                        </CardBody>
                    </Card>
                }
                {this.state.panel == "GPRS Details" &&
                    <Card>
                        <CardHeader color="primary" >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={(): void => this.handleViewMenuClose()}
                                        aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    GPRS Details
                                </Typography>
                                </Toolbar>
                            </AppBar>
                        </CardHeader>
                    <CardBody>
                        <div>
                            <DataGrid
                                dataSource={this.state.gprsDetails}
                                keyExpr="Id"
                                onRowInserted={this.onGprsInserted.bind(this)}
                                onEditingStart={this.onGprsEditingStart.bind(this)}
                                onEditCanceled={this.onGprsEditCancelled.bind(this)}
                                onSaved={this.onGprsSaved.bind(this)}
                                onRowRemoved={this.onGprsDeleted.bind(this)}
                            >
                                <Column dataField="name" dataType="string" />
                                <Column dataField="hostname" dataType="string" />
                                <Column dataField="port" dataType="number" />
                                <Column dataField="description" dataType="string" />
                                <Editing
                                    mode="row"
                                    allowUpdating={true}
                                    allowDeleting={true}
                                    allowAdding={true} />
                            </DataGrid>
                            </div>
                        </CardBody>
                    </Card>
                }
                {this.state.panel == "Phone Numbers" &&
                    <Card>
                        <CardHeader color="primary" >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={(): void => this.handleViewMenuClose()}
                                        aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    Phone Numbers
                                </Typography>
                                </Toolbar>
                            </AppBar>
                        </CardHeader>
                        <CardBody>
                            <div>
                            <DataGrid
                                dataSource={this.state.phoneNumbers}
                                keyExpr="id"
                                onRowInserted={this.onPhoneInserted.bind(this)}
                                onEditingStart={this.onPhoneEditingStart.bind(this)}
                                onEditCanceled={this.onPhoneEditCancelled.bind(this)}
                                onSaved={this.onPhoneSaved.bind(this)}
                                onRowRemoved={this.onPhoneDeleted.bind(this)}
                            >
                                <Column dataField="name" dataType="string" />
                                <Column dataField="number" dataType="string" />
                                <Column dataField="description" dataType="string" />
                                <Column dataField="type" dataType="string" editCellRender={this.typeEditorRender}/>
                                <Editing
                                    mode="row"
                                    allowUpdating={true}
                                    allowDeleting={true}
                                    allowAdding={true} />
                            </DataGrid>
                            </div>
                                </CardBody>
                    </Card>
                }
                {this.state.panel == "Load Template" &&
                <Card>
                    <CardHeader color="primary" >
                        <div className={this.props.classes.cardTitleWhite} style={{ paddingLeft: "30px" }}>
                            <h4 className={this.props.classes.cardTitleWhite}>
                                Load config from template
                            </h4></div>
                    </CardHeader>
                    <CardBody>
                        <div style={{ paddingLeft: "30px", paddingTop: "20px" }}>
                            {this.state.templates.length > 0 ?
                                <div>
                                    <div>
                                        NOTE: Loading a template will NOT update Sitename or location details for current logger
                                    </div>
                                <Select
                                    size="medium"
                                    style={{ margin: 0, paddingBottom: 0 }}
                                    value={this.state.templateSelect}
                                    onChange={this.handleTemplateChange.bind(this)}
                                >
                                    <MenuItem key="" value="0">Select template</MenuItem>
                                    {this.state.templates.map((template: APIConfigTemplate): ReactNode => {
                                        return <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>;

                                    })
                                    }
                                    </Select>
                                </div>
                                :
                                <div>No templates available for {this.props.product}:{this.props.model}</div>
                            }
                        </div>
                        <div style={{ paddingLeft: "30px", paddingTop: "20px" }}>
                            <Button variant="contained" color="primary" onClick={(): void => this.handleLoadTemplate()} disabled={this.state.templates.length == 0}>Load</Button>
                            <Button variant="contained" color="secondary" onClick={(): void => this.handleTemplateMenuClose()}>Cancel</Button>
                        </div>

                    </CardBody>
                </Card>
                }
                {this.state.panel == "Save Template" &&
                    <Card>
                        <CardHeader color="primary" >
                            <div className={this.props.classes.cardTitleWhite} style={{ paddingLeft: "30px" }}>
                                <h4 className={this.props.classes.cardTitleWhite}>
                                    Save config as template
                            </h4></div>
                        </CardHeader>
                        <CardBody>
                            <div style={{ paddingLeft: "30px", paddingTop: "20px" }}>
                                <TextField
                                label={this.state.templateNameError ? this.state.templateNameErrorMsg : "Template title"}
                                fullWidth={true}
                                variant="outlined"
                                size="medium"
                                value={this.state.templateName}
                                onChange={this.handleTemplateNameChange.bind(this)}
                                error={this.state.templateNameError}
                                
                                />
                            </div>
                            <div style={{ paddingLeft: "30px", paddingTop: "20px" }}>
                                <Button variant="contained" color="primary" onClick={(): void => this.handleSaveTemplate()} disabled={this.state.templateName.length == 0}>Save</Button>
                                <Button variant="contained" color="secondary" onClick={(): void => this.handleTemplateMenuClose()}>Cancel</Button>
                            </div>

                        </CardBody>
                    </Card>
                    
                }
                {console.log(this.state.deviceConfig)}
                {this.state.panel == "Save Config" &&
                    <Card>
                        <CardHeader color="primary" >
                            <div className={this.props.classes.cardTitleWhite} style={{ paddingLeft: "30px" }}>
                                <h4 className={this.props.classes.cardTitleWhite}>
                                    Save config for next connection or scheduled update
                            </h4></div>
                        </CardHeader>
                        <CardBody>
                            <div style={{ paddingLeft: "30px", paddingTop: "20px" }}>
                            <LocalizationProvider dateAdapter={DateAdapter}>
                                <DateTimePicker
                                    label="Send Config After"
                                    value={this.state.sendAfter}
                                    onChange={this.handleSendAfterChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            </div>
                            <div style={{ paddingLeft: "30px", paddingTop: "20px" }}>
                            <Button variant="contained" color="primary" onClick={(): void => this.handleSendAfterClick()}>Update on next connection</Button>&nbsp;
                            <Button variant="contained" color="primary" onClick={(): void => this.handleSendAfterClick()} disabled={this.state.sendAfter == null}>Update after scheduled Date/Time</Button>&nbsp;
                            <Button variant="contained" color="secondary" onClick={(): void => this.setState({ panel: "Config" })}>Cancel</Button>
                            </div>

                        </CardBody>
                    </Card>
                }

                <NotificationContainer />
                
            </div>
        );
    }

}

export default withStyles(style)(ToolkitTOR);