export interface GraphSettings {
    flowAVisible: boolean
    flowAUnit: string
    flowAMinState: string
    flowAMaxState: string
    flowAMinValue: number
    flowAMaxValue: number
    flowAOriginalMax: number
    flowAOriginalMin: number
    flowAAxisPosition: string
    flowAGraphType: string
    flowALineWidth: number
    flowAColor: string
    flowALabelSize: number
    flowATotalVisible: boolean
    flowATotalPerDay: boolean
    flowATotalUnit: string
    flowATotalMinState: string
    flowATotalMaxState: string
    flowATotalMinValue: number
    flowATotalMaxValue: number
    flowATotalAxisPosition: string
    flowATotalGraphType: string
    flowATotalLineWidth: number
    flowATotalColor: string
    flowATotalLabelSize: number
    flowBVisible: boolean
    flowBUnit: string
    flowBMinState: string
    flowBMaxState: string
    flowBMinValue: number
    flowBMaxValue: number
    flowBOriginalMax: number
    flowBOriginalMin: number
    flowBAxisPosition: string
    flowBGraphType: string
    flowBLineWidth: number
    flowBColor: string
    flowBLabelSize: number
    flowBTotalVisible: boolean
    flowBTotalPerDay: boolean
    flowBTotalUnit: string
    flowBTotalMinState: string
    flowBTotalMaxState: string
    flowBTotalMinValue: number
    flowBTotalMaxValue: number
    flowBTotalAxisPosition: string
    flowBTotalGraphType: string
    flowBTotalLineWidth: number
    flowBTotalColor: string
    flowBTotalLabelSize: number
    pressureCVisible: boolean
    pressureCIsNotPressure: boolean
    pressureCUnit: string
    pressureCOriginalUnit: string
    pressureCMinState: string
    pressureCMaxState: string
    pressureCMinValue: number
    pressureCMaxValue: number
    pressureCOriginalMax: number
    pressureCOriginalMin: number
    pressureCValueAs: string
    pressureCAxisPosition: string
    pressureCGraphType: string
    pressureCLineWidth: number
    pressureCColor: string
    pressureCLabelSize: number
    pressureDVisible: boolean
    pressureDIsNotPressure: boolean
    pressureDUnit: string
    pressureDOriginalUnit: string
    pressureDMinState: string
    pressureDMaxState: string
    pressureDMinValue: number
    pressureDMaxValue: number
    pressureDOriginalMax: number
    pressureDOriginalMin: number
    pressureDValueAs: string
    pressureDAxisPosition: string
    pressureDGraphType: string
    pressureDLineWidth: number
    pressureDColor: string
    pressureDLabelSize: number
    pressureEVisible: boolean
    pressureEIsNotPressure: boolean
    pressureEUnit: string
    pressureEOriginalUnit: string
    pressureEMinState: string
    pressureEMaxState: string
    pressureEMinValue: number
    pressureEMaxValue: number
    pressureEOriginalMax: number
    pressureEOriginalMin: number
    pressureEValueAs: string
    pressureEAxisPosition: string
    pressureEGraphType: string
    pressureELineWidth: number
    pressureEColor: string
    pressureELabelSize: number
}

export interface GraphTemplate {
    serial: number | null
    siteId: number | null
    companyId: string
    userId: number
    alarms: Array
    isAshridgeDefault: boolean
    graphSettings: GraphSettings
    channelsAvail: number

}


export const graphTemplateModel = {
    serial: null,
    siteId: null,
    isDefault: false,
    fkCompanyId: parseInt(sessionStorage.getItem('companyId')),
    userId: null,
    isAshridgeDefault: false,
    channelsAvail: 0,
    alarms: [],
    graphSettings: {
        flowAVisible: true,
        flowAUnit: "l/s",
        flowAMinState: "default",
        flowAMaxState: "default",
        flowAMinValue: Number.MAX_VALUE,
        flowAMaxValue: Number.NEGATIVE_INFINITY,
        flowAOriginalMin: Number.MAX_VALUE,
        flowAOriginalMax: Number.NEGATIVE_INFINITY,
        flowAAxisPosition: "left",
        flowAGraphType: "spline",
        flowALineWidth: 2,
        flowAColor: "#B22222",
        flowALabelSize: 12,
        flowATotalVisible: true,
        flowATotalPerDay: true,
        flowATotalUnit: "l",
        flowATotalMinState: "default",
        flowATotalMaxState: "default",
        flowATotalMinValue: Number.MAX_VALUE,
        flowATotalMaxValue: Number.NEGATIVE_INFINITY,     
        flowATotalAxisPosition: "left",
        flowATotalGraphType: "bar",
        flowATotalLineWidth: 50,
        flowATotalColor: "#B8860B",
        flowATotalLabelSize: 12,
        flowBVisible: true,
        flowBUnit: "l/s",
        flowBMinState: "default",
        flowBMaxState: "default",
        flowBMinValue: Number.MAX_VALUE,
        flowBMaxValue: Number.NEGATIVE_INFINITY,
        flowBOriginalMin: Number.MAX_VALUE,
        flowBOriginalMax: Number.NEGATIVE_INFINITY,
        flowBAxisPosition: "right",
        flowBGraphType: "spline",
        flowBLineWidth: 2,
        flowBColor: "#006400",
        flowBLabelSize: 12,
        flowBTotalVisible: true,
        flowBTotalPerDay: true,
        flowBTotalUnit: "l",
        flowBTotalMinState: "default",
        flowBTotalMaxState: "default",
        flowBTotalMinValue: Number.MAX_VALUE,
        flowBTotalMaxValue: Number.NEGATIVE_INFINITY,
        flowBTotalAxisPosition: "right",
        flowBTotalGraphType: "bar",
        flowBTotalLineWidth: 50,
        flowBTotalColor: "#8B4513",
        flowBTotalLabelSize: 12,
        pressureCVisible: true,
        pressureCIsNotPressure: false,
        pressureCUnit: "bar",
        pressureCOriginalUnit: "",
        pressureCMinState: "default",
        pressureCMaxState: "default",
        pressureCMinValue: Number.MAX_VALUE,
        pressureCMaxValue: Number.NEGATIVE_INFINITY,
        pressureCOriginalMin: Number.MAX_VALUE,
        pressureCOriginalMax: Number.NEGATIVE_INFINITY,
        pressureCValueAs: "mean",
        pressureCAxisPosition: "left",
        pressureCGraphType: "spline",
        pressureCLineWidth: 2,
        pressureCColor: "#0000CD",
        pressureCLabelSize: 12,
        pressureDVisible: true,
        pressureDIsNotPressure: false,
        pressureDUnit: "bar",
        pressureDOriginalUnit: "",
        pressureDMinState: "default",
        pressureDMaxState: "default",
        pressureDMinValue: Number.MAX_VALUE,
        pressureDMaxValue: Number.NEGATIVE_INFINITY,
        pressureDOriginalMin: Number.MAX_VALUE,
        pressureDOriginalMax: Number.NEGATIVE_INFINITY,
        pressureDValueAs: "mean",
        pressureDAxisPosition: "right",
        pressureDGraphType: "spline",
        pressureDLineWidth: 2,
        pressureDColor: "#FF6F00",
        pressureDLabelSize: 12,
        pressureEVisible: false,
        pressureEIsNotPressure: false,
        pressureEUnit: "bar",
        pressureEOriginalUnit: "",
        pressureEMinState: "default",
        pressureEMaxState: "default",
        pressureEMinValue: Number.MAX_VALUE,
        pressureEMaxValue: Number.NEGATIVE_INFINITY,
        pressureEOriginalMin: Number.MAX_VALUE,
        pressureEOriginalMax: Number.NEGATIVE_INFINITY,
        pressureEValueAs: "mean",
        pressureEAxisPosition: "right",
        pressureEGraphType: "spline",
        pressureELineWidth: 2,
        pressureEColor: "#800080",
        pressureELabelSize: 12,
    }
}

const deepCopyGraphSettings = (settings: GraphSettings): GraphSettings => {
    console.log(settings)
    return {
        ...settings,
        flowAVisible: settings.flowAVisible,
        flowAUnit: settings.flowAUnit,
        flowAMinState: settings.flowAMinState,
        flowAMaxState: settings.flowAMaxState,
        flowAMinValue: settings.flowAMinValue,
        flowAMaxValue: settings.flowAMaxValue,
        flowAOriginalMin: settings.flowAOriginalMin,
        flowAOriginalMax: settings.flowAOriginalMax,
        flowAAxisPosition: settings.flowAAxisPosition,
        flowAGraphType: settings.flowAGraphType,
        flowALineWidth: settings.flowALineWidth,
        flowAColor: settings.flowAColor,
        flowALabelSize: settings.flowALabelSize,
        flowATotalVisible: settings.flowATotalVisible,
        flowATotalPerDay: settings.flowATotalPerDay,
        flowATotalUnit: settings.flowATotalUnit,
        flowATotalMinState: settings.flowATotalMinState,
        flowATotalMaxState: settings.flowATotalMaxState,
        flowATotalMinValue: settings.flowATotalMinValue,
        flowATotalMaxValue: settings.flowATotalMaxValue,
        flowATotalAxisPosition: settings.flowATotalAxisPosition,
        flowATotalGraphType: settings.flowATotalGraphType,
        flowATotalLineWidth: settings.flowATotalLineWidth,
        flowATotalColor: settings.flowATotalColor,
        flowATotalLabelSize: settings.flowATotalLabelSize,
        flowBVisible: settings.flowBVisible,
        flowBUnit: settings.flowBUnit,
        flowBMinState: settings.flowBMinState,
        flowBMaxState: settings.flowBMaxState,
        flowBMinValue: settings.flowBMinValue,
        flowBMaxValue: settings.flowBMaxValue,
        flowBOriginalMin: settings.flowBOriginalMin,
        flowBOriginalMax: settings.flowBOriginalMax,
        flowBAxisPosition: settings.flowBAxisPosition,
        flowBGraphType: settings.flowBGraphType,
        flowBLineWidth: settings.flowBLineWidth,
        flowBColor: settings.flowBColor,
        flowBLabelSize: settings.flowBLabelSize,
        flowBTotalVisible: settings.flowBTotalVisible,
        flowBTotalPerDay: settings.flowBTotalPerDay,
        flowBTotalUnit: settings.flowBTotalUnit,
        flowBTotalMinState: settings.flowBTotalMinState,
        flowBTotalMaxState: settings.flowBTotalMaxState,
        flowBTotalMinValue: settings.flowBTotalMinValue,
        flowBTotalMaxValue: settings.flowBTotalMaxValue,
        flowBTotalAxisPosition: settings.flowBTotalAxisPosition,
        flowBTotalGraphType: settings.flowBTotalGraphType,
        flowBTotalLineWidth: settings.flowBTotalLineWidth,
        flowBTotalColor: settings.flowBTotalColor,
        flowBTotalLabelSize: settings.flowBTotalLabelSize,
        pressureCVisible: settings.pressureCVisible,
        pressureCIsNotPressure: settings.pressureCIsNotPressure,
        pressureCUnit: settings.pressureCUnit,
        pressureCOriginalUnit: settings.pressureCOriginalUnit,
        pressureCMinState: settings.pressureCMinState,
        pressureCMaxState: settings.pressureCMaxState,
        pressureCMinValue: settings.pressureCMinValue,
        pressureCMaxValue: settings.pressureCMaxValue,
        pressureCOriginalMin: settings.pressureCOriginalMin,
        pressureCOriginalMax: settings.pressureCOriginalMax,
        pressureCValueAs: settings.pressureCValueAs,
        pressureCAxisPosition: settings.pressureCAxisPosition,
        pressureCGraphType: settings.pressureCGraphType,
        pressureCLineWidth: settings.pressureCLineWidth,
        pressureCColor: settings.pressureCColor,
        pressureCLabelSize: settings.pressureCLabelSize,
        pressureDVisible: settings.pressureDVisible,
        pressureDIsNotPressure: settings.pressureDIsNotPressure,
        pressureDUnit: settings.pressureDUnit,
        pressureDOriginalUnit: settings.pressureDOriginalUnit,
        pressureDMinState: settings.pressureDMinState,
        pressureDMaxState: settings.pressureDMaxState,
        pressureDMinValue: settings.pressureDMinValue,
        pressureDMaxValue: settings.pressureDMaxValue,
        pressureDOriginalMin: settings.pressureDOriginalMin,
        pressureDOriginalMax: settings.pressureDOriginalMax,
        pressureDValueAs: settings.pressureDValueAs,
        pressureDAxisPosition: settings.pressureDAxisPosition,
        pressureDGraphType: settings.pressureDGraphType,
        pressureDLineWidth: settings.pressureDLineWidth,
        pressureDColor: settings.pressureDColor,
        pressureDLabelSize: settings.pressureDLabelSize,
        pressureEVisible: settings.pressureEVisible,
        pressureEIsNotPressure: settings.pressureEIsNotPressure,
        pressureEUnit: settings.pressureEUnit,
        pressureEOriginalUnit: settings.pressureEOriginalUnit,
        pressureEMinState: settings.pressureEMinState,
        pressureEMaxState: settings.pressureEMaxState,
        pressureEMinValue: settings.pressureEMinValue,
        pressureEMaxValue: settings.pressureEMaxValue,
        pressureEOriginalMin: settings.pressureEOriginalMin,
        pressureEOriginalMax: settings.pressureEOriginalMax,
        pressureEValueAs: settings.pressureEValueAs,
        pressureEAxisPosition: settings.pressureEAxisPosition,
        pressureEGraphType: settings.pressureEGraphType,
        pressureELineWidth: settings.pressureELineWidth,
        pressureEColor: settings.pressureEColor,
        pressureELabelSize: settings.pressureELabelSize
    }
}

export const deepCopyAshridgeDefault = (template: GraphTemplate): GraphTemplate => {
    console.log(template)
    return {
        ...template,
        graphSettings: deepCopyGraphSettings(template.graphSettings),
    };
};