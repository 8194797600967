import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import Moment from 'react-moment';
import { APIGetLoggerSummaryModel, buildAPIGetLoggerSummaryModel } from "models/APIGetLoggerSummaryModel";
import { APIGetLoggerConnectionsModel, buildAPIGetLoggerConnectionsModel } from "models/APIGetLoggerConnectionsModel";

import { withStyles, createStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { compose, spacing, palette, border, sizing } from '@mui/system';
import { Button } from '@mui/material';



import Map from 'devextreme-react/map';
import SignalGauge from '../Connections/NewSignalGauge';
import BatteryData from '../Connections/BatteryInfoTor'


//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import ConnectionsGraph from '../Connections/ConnectionsGraph';
import BatterySignalGraph from '../Connections/BatterySignalGraph';
import { borderRadius } from 'react-select/src/theme';
import { Redirect } from 'react-router-dom';

// Device image
import SWG from "assets/SWG.jpg";
import { Height } from 'devextreme-react/chart';

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const overViewStyle = {
    backgroundColor: "#f7f7f7",
    borderRadius: "5px",
    padding: "10px 0 0 0",
    width: "90%",
    boxShadow: "2px 2px 10px #00000060",
    marginBottom: "30px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

const style = createStyles(styles);

const Box = styled('div')(compose(spacing, palette, sizing));

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serial: number;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    logger: APIGetLoggerSummaryModel | null;
    connectionsData: Array<APIGetLoggerConnectionsModel>;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        siteName: string;
        siteId: number | undefined;
        serial: string | undefined;
        deviceId: number | undefined;
    } | null;
    viewMapModal: boolean;

}

class SWGSummary extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            logger: null,
            connectionsData: [],
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            viewMapModal: false,
        };
    }

    componentDidMount(): void {
        let connectionsData = new Array<APIGetLoggerConnectionsModel>();

        //get detail on logger here
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/LoggerBySerial?serial=' + this.props.serial),{})
            .then(response => {
                    me.setState({
                    logger: buildAPIGetLoggerSummaryModel(response)
                });

            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false
                    })
            });

        CallGetAPI(CreateUrl('/api/aquaguard/LoggerConnections?serial=' + this.props.serial + "&startDate=" + moment().subtract(7,"days").format("yyyy-MM-DD") + "&endDate=" + moment().endOf("day").format("yyyy-MM-DDTHH:mm:ss")), {})
            .then(json => {
                connectionsData = connectionsData.concat(buildAPIGetLoggerConnectionsModel(json));

                me.setState({
                    connectionsData: connectionsData,
                });
            })
            .catch(function (ex) {
                me.setState(
                    {
                        authorized: false,
                        connectionsData: []
                    });
                console.log(ex);
            })

        this.setState({ loading: false, tableHidden: false });
    }

    viewSiteClick(): void {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/SiteDetail',
                redirectProps: {
                    siteName: this.state.logger?.SiteNameUserFriendly || this.state.logger?.SiteName || "",
                    siteId: this.state.logger?.FkSiteId,
                    serial: this.state.logger?.Serial.toString(),
                    deviceId: undefined
                }
            });
    }

    viewDeploymentClick(): void {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/DeploymentList',
                redirectProps: {
                    siteName: this.state.logger?.SiteNameUserFriendly || this.state.logger?.SiteName || "",
                    siteId: this.state.logger?.FkSiteId,
                    serial: this.state.logger?.Serial.toString(),
                    deviceId: this.state.logger?.Id
                }
            });
    }

    toggleMapModal(): void {
        this.setState({ viewMapModal: !this.state.viewMapModal });
    }


    render(): ReactNode {

         return (!this.state.redirect) ?
             (<div>
                  

                  {this.state.loading &&
                 <div style={{
                     position: 'absolute', left: '50%', top: '50%',
                     transform: 'translate(-50%, -50%)'
                 }}>
                     <ClipLoader
                         size={150}
                         color={"#123abc"}
                         loading={this.state.loading}
                     />
                 </div>
             }
             {!this.state.loading &&
                 <div>
                     {this.state.tableHidden &&
                             <ApiFailed />
                     }
                 {!this.state.tableHidden && this.state.logger &&
                     <Box padding={1}>
                     <GridContainer direction="row" justify="center" alignItems="stretch" margin-bottom={10} spacing={3}>
                         <GridItem xs={12} sm={6} md={4} style={{ marginBottom: 10 }}>
                            <div style={{ borderLeft: "10px solid #00BF00", ...overViewStyle, width: "100%" }}>
                                <Box border={1} padding={2} style={{ borderRadius: "25px", height: "100%", width: "100%" }}>
                                    <h5 style={{textAlign: "center"} }><b>Device</b></h5>
                                    {this.state.logger &&
                                        <div style={{textAlign: "center", marginBottom: "10px"} }>
                                            <img src={SWG} width={100} />
                                        </div>
                                                    }
                                                    
                                    <div>
                                        <b style={{fontWeight: 700} }>Device type:</b>&nbsp;&nbsp; {this.state.logger.Model}
                                    </div>
                                    <div>
                                        <b style={{fontWeight: 700} }>Serial:</b>&nbsp;&nbsp; {this.state.logger.Serial}
                                    </div>
                                    <div>
                                        <b style={{fontWeight: 700} }>SW Version:</b>&nbsp;&nbsp; {this.state.logger.Swversion}{this.state.logger.Devversion > 0 ? <span>rc{this.state.logger.Devversion}</span> : <span></span>}
                                    </div>
                                    <div>
                                                     <b style={{ fontWeight: 700 }}>Channels:</b>&nbsp;&nbsp; {this.state.logger.Channels.substr(6)}
                                    </div>
                                    <div>
                                        <b style={{fontWeight: 700} }>Last record:</b>&nbsp;&nbsp; <Moment format="HH:mm DD/MM/YYYY">{this.state.logger.LastReading}</Moment>
                                                    </div>
                                <hr />
                                    <div>
                                        <b style={{fontWeight: 700} }>Site:</b>&nbsp;&nbsp; {this.state.logger.SiteNameUserFriendly ? this.state.logger.SiteNameUserFriendly : this.state.logger.SiteName}
                                        {this.state.logger.SiteName &&
                                                            <Button size="small" variant="contained" color="secondary" onClick={this.viewSiteClick.bind(this)} style={{ float: "right", marginRight: "30px" }} >View site</Button>
                                        }
                                                    </div>
                                                    <div style={{marginTop: "15px"} }>
                                    {this.state.logger.Deployed ?
                                        <div>
                                                            <b style={{ fontWeight: 700 }}>Deployed:</b>&nbsp;&nbsp; {moment(this.state.logger.Deployed).format("DD/MM/yyyy")} <Button variant="contained" size="small" style={{ float: "right", marginRight: "30px" }} color="primary" onClick={this.viewDeploymentClick.bind(this)}>History</Button>
                                        </div>
                                        :
                                        <div>
                                                            <b style={{ fontWeight: 700 }}>Not deployed</b>&nbsp;&nbsp; <Button size="small" color="primary" variant="contained" style={{ float: "right", marginRight: "30px" }} onClick={this.viewDeploymentClick.bind(this)}>History</Button>
                                        </div>
                                                        }
                                    </div>
                                </Box>
                                </div>
                            </GridItem>
                                     {console.log(this.state.logger) }
                              <GridItem xs={12} sm={6} md={this.state.connectionsData.length > 0 ? 5 : 8}>

                                            <div>

                                                <Map
                                                    //zoom={15}
                                                    defaultZoom={15}
                                                    provider="google"
                                                    markers={[
                                                        { location: [this.state.logger.GpsLatitude, this.state.logger.GpsLongitude] }
                                                    ]}
                                                    apiKey={this.state.mapKey}
                                                    width="100%"
                                                    height={600}
                                                    controls={true}
                                                    type="hybrid"
                                                />
                                                <i>What3Words - {this.state.logger?.What3Words}</i>
                                            </div>

                                        </GridItem>

                                        <GridItem xs={12} sm={6} md={3} style={{ marginBottom: 10 }}>
                                            {this.state.connectionsData.length > 0 &&
                                                <div style={{ borderLeft: "10px solid #00ADBA", ...overViewStyle, width: "100%" }}>
                                                    <SignalGauge connectionsData={this.state.connectionsData} />
                                                </div>}
                                            {console.log(this.state.connectionsData)}
                                            {this.state.connectionsData.length > 0 &&
                                                <div style={{ borderLeft: "10px solid #009FE3", ...overViewStyle, width: "100%" }}>
                                                    <div style={{ marginLeft: "-20px" }}>
                                                        <ConnectionsGraph connectionsData={this.state.connectionsData} lastConnection={this.state.logger.LastConnected} />
                                                    </div>
                                                </div>}
                                            {this.state.connectionsData.length > 0 &&
                                                <div style={{ borderLeft: "10px solid #6F2DA8", ...overViewStyle, width: "100%" }}>
                                                    <BatteryData connectionsData={this.state.connectionsData} />
                                                </div>}
                                        </GridItem>
                     </GridContainer>
                     </Box>
                 }
                 </div>
             }

         </div>
             )
             :
             (<Redirect push to={{
                 pathname: this.state.redirectPath,
                 state: this.state.redirectProps
             }} />);
    }

}

export default withStyles(style)(SWGSummary);