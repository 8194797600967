import React, { useState, useEffect, useRef } from 'react'

import { CallGetAPI, CreateUrl, CallPostAPI, CallPutAPI, CallDeleteAPI } from '../../Utils/ApiHelper';
import { NotificationManager } from 'react-notifications';
import ClipLoader from "react-spinners/ClipLoader";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { DataGrid, Column as GridColumn, Export, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring, HeaderFilter, Selection } from 'devextreme-react/data-grid';
import { Button, Switch, Chip, Radio, Dialog, RadioGroup, FormControlLabel, IconButton, Tooltip, } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import moment from 'moment';
import { Accordion, Item } from 'devextreme-react/accordion';
import Modal from 'react-bootstrap/Modal';

const styles: Record<string, any> = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    
};

interface Props {
    classes: {
        cardTitleWhite: string;
        cardCategoryWhite: string;
    };
}

interface Report {
    id: number;
    reportName: string;
    loggerIds: Array<number>;
    reportDate: string;
    reportCreated: string;
    lastUpdated: string;
    dataInReport: string;
    loggerSerials: string;
    frequency: string;
}

interface Schedule {
    id: number;
    reportName: string;
    loggerIds: Array<string>;
    frequency: string;
    reportCreated: string;
    lastUpdated: string;
    dataInReport: string;
    loggerSerials: string;
    status: boolean;
}



const style = createStyles(styles);
const Reports: React.FC<Props> = ({ classes }) => {




    // STATE
    const [loading, setLoading] = useState<boolean>(true)
    const [reports, setReports] = useState<Array<Report>>()
    const [scheduleData, setScheduleData] = useState<Array<Schedule>>()
    const [admin, setAdmin] = useState<boolean>((sessionStorage.getItem('userLevel') === 'identityadmin' || sessionStorage.getItem('userLevel') === 'useradmin') ? true : false)
    const [downloadDialog, setDownloadDialog] = useState<boolean>(false)
    const [downloadType, setDownloadType] = useState<string>('CSV')


    // ReferenceError

    const gridRef = useRef(null)


    // FUNCTIONS
    const createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    const createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };



    const handleStatusChange = (e, newStatus: boolean): void => {

        const updatedSchedule = [...scheduleData];
        const updatedIndex = updatedSchedule.findIndex(item => e.target.id === item.id.toString());

        if (updatedIndex !== -1) {
            updatedSchedule[updatedIndex].status = newStatus;

            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    status: newStatus
                })
            };

            CallPutAPI(CreateUrl(`/api/aquaguard/reportsforcompany/${e.target.id}`), requestOptions)
                .then((): void => {
                    createSuccessNotification();
                    setScheduleData(updatedSchedule); // Update state here
                })
                .catch((): void => {
                    createErrorNotification();
                });
        } else {
            console.error('Item not found in scheduleData');
        }
    };

    const handleDelete = (id: number):void => {
        
        const check = window.confirm('are you sure you want to delete this schedule?')
        
        if (check) {
            const updatedSchedule = [...scheduleData]
            const removedDeleteItems = updatedSchedule.filter(item => id !== item.id);
            
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            }
            
            CallDeleteAPI(CreateUrl(`/api/aquaguard/reportsforcompany?Id=${id}`), requestOptions)
                .then(() => {
                    createSuccessNotification()
                    setScheduleData(removedDeleteItems);
                })
        }   
    }

    const handleDownload = (data:Report):void => {
        const arrayOfReportContent = data.dataInReport.split(", ")
        setDownloadDialog(!downloadDialog)
    }

    const toggleDownloadModal = () => {
        setDownloadDialog(!downloadDialog)
    }

    const downloadConfirm = (): void => {
        console.log('confirm')
        setDownloadDialog(!downloadDialog)
    }


    const clearState = (): void => {
        
        gridRef.current = null;
    }
   


    /// CELL RENDER TEMPLATES
    const statusTemplate = ({ data }) => {
        const newStatus = !data.status
        return (
            <Switch
                size="small"
                id={data.id.toString()}
                checked={data.status}
                onChange={(e): void => handleStatusChange(e, newStatus)}
            />
        );
    }


    const downloadTemplate = ({data }) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%" }}>
                <Button variant="contained" size="small" color="primary" onClick={():void => handleDownload(data)}>Download</Button>
                
            </div>
        )
    }

    const deleteTemplate = ({data }) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%" }}>
                <Button variant="contained" size="small" id={data.id} color="error" onClick={():void => handleDelete(data.id) }>Delete</Button>
            </div>
        )
    }


    const loggersTemplate = ({ data }) => {
        const chips = []
        const serials = data.loggerSerials.split(',')
        const ids = data.loggerIds.split(',')
        for (let i = 0; i < ids.length; i++) {
            chips.push(<Chip color="primary" key={ids[i]} label={serials[i]} />)
        }
        return chips
    }

    

    

    // USEEFFECT

    useEffect(() => {
        reloadData()
    }, [])

    const reloadData = ():void => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },

        };

        CallGetAPI(CreateUrl(`/api/aquaguard/reportsforcompany?companyId=${sessionStorage.getItem('companyId')}`), requestOptions)
            .then(data => {

                if (data.length > 0) {

                    const reports = []
                    const schedules = []
                    for (let i = 0; i < data.length; i++) {

                        //decode the filters from binary
                        const filterDecode = []
                        const filters = data[i].filtersWanted

                        if (filters.charAt(0) === '1') {
                            filterDecode.push('model')
                        }
                        if (filters.charAt(1) === '1') {
                            filterDecode.push('deployed')
                        }
                        if (filters.charAt(2) === '1') {
                            filterDecode.push('site name')
                        }
                        if (filters.charAt(3) === '1') {
                            filterDecode.push('site ref')
                        }
                        if (filters.charAt(4) === '1') {
                            filterDecode.push('device site name')
                        }
                        if (filters.charAt(5) === '1') {
                            filterDecode.push('gps')
                        }
                        if (filters.charAt(6) === '1') {
                            filterDecode.push('last connected')
                        }
                        if (filters.charAt(7) === '1') {
                            filterDecode.push('current signal')
                        }
                        if (filters.charAt(8) === '1') {
                            filterDecode.push('battery level')
                        }
                        if (filters.charAt(9) === '1') {
                            filterDecode.push('current firmware verison')
                        }
                        if (filters.charAt(10) === '1') {
                            filterDecode.push('loss % ')
                        }
                        if (filters.charAt(11) === '1') {
                            filterDecode.push('minimum flow in l/h')
                        }
                        if (filters.charAt(12) === '1') {
                            filterDecode.push('maximum flow in l/h')
                        }
                        if (filters.charAt(13) === '1') {
                            filterDecode.push('consumption in l')
                        }
                        if (filters.charAt(14) === '1') {
                            filterDecode.push('minimum flow in m3/h')
                        }
                        if (filters.charAt(15) === '1') {
                            filterDecode.push('maximum flow in m3/h')
                        }
                        if (filters.charAt(16) === '1') {
                            filterDecode.push('consumption in m3')
                        }

                        /// clacultae the ddifference between dat eof creation and current date for the number of reports to generate
                        const today = moment().startOf('day')
                        let numberOfReportsToCreate = 1
                        let frequency = 'days'
                        if (data[i].frequency === 'daily') {
                            numberOfReportsToCreate = today.diff(moment(data[i].created).startOf('day'), 'days') - 1
                        } else if (data[i].frequency === 'weekly') {
                            numberOfReportsToCreate = today.diff(moment(data[i].created).startOf('day'), 'weeks')
                            frequency = 'weeks'
                        } else if (data[i].frequency === 'monthly') {
                            numberOfReportsToCreate = today.diff(moment(data[i].created).startOf('day'), 'months')
                            frequency = 'months'
                        } else if (data[i].frequency === 'yearly') {
                            numberOfReportsToCreate = today.diff(moment(data[i].created).startOf('day'), 'years')
                            frequency = 'years'
                        }
                        const schedule: Schedule = {
                            id: data[i].id,
                            reportName: data[i].reportName,
                            loggerIds: data[i].loggerIds,
                            frequency: data[i].frequency,
                            reportCreated: moment(data[i].created).format("DD/MM/YYYY"),
                            lastUpdated: moment(data[i].updated).format("DD/MM/YYYY"),
                            dataInReport: filterDecode.join(', '),
                            loggerSerials: data[i].loggerSerials,
                            status: data[i].status


                        }

                        schedules.push(schedule)
                        setScheduleData(schedules)

                        for (let j = 0; j < numberOfReportsToCreate; j++) {
                            if (data[i].status !== false) {
                                const report: Report = {
                                    id: data[i].id,
                                    reportName: data[i].reportName,
                                    loggerIds: data[i].loggerIds,
                                    frequency: data[i].frequency,
                                    reportCreated: moment(data[i].created).format("DD/MM/YYYY"),
                                    lastUpdated: moment(data[i].updated).format("DD/MM/YYYY"),
                                    dataInReport: filterDecode.join(', '),
                                    loggerSerials: data[i].loggerSerials,
                                    reportDate: moment().startOf('day').subtract(j, frequency).format("DD/MM/YYYY")

                                }
                                reports.push(report)
                            }
                        }





                    }
                    setReports(reports)
                }
            })
            .catch(function (error) {
                createErrorNotification();
                console.log(error);
            });
        setLoading(false)
    }

    return (
        <div>
            {loading && <div style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={loading}
                />
            </div>}
            {!loading && <div>
                <Dialog open={downloadDialog}>
                    <Modal.Header closeButton onHide={(): void => toggleDownloadModal()}>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Export Data
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={12} >
                                Export as:&nbsp;
                                <RadioGroup aria-label="exportAs" name="exportAs" onChange={(e) => setDownloadType(e.target.value)} value={downloadType}>
                                    <FormControlLabel value="CSV" control={<Radio />} label="CSV" />
                                    <FormControlLabel value="XLSX" control={<Radio />} label="XLSX" />
                                    <FormControlLabel value="XLSXT" control={<Radio />} label="XLSX Table (filter options enabled)" />
                                </RadioGroup>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                            </GridItem>
                            
                        </GridContainer>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="primary" onClick={(): void => downloadConfirm()}>Export</Button>
                        <Button color="secondary" onClick={(): void => toggleDownloadModal()}>Cancel</Button>
                    </Modal.Footer>
                </Dialog>
                {console.log(downloadType) }
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div style={{ display: 'inline', float: "right" }}>
                                            <Tooltip title="clear sort/filter">
                                                <IconButton
                                                    edge="start"
                                                    color="inherit"
                                                    onClick={(): void => clearState()}
                                                    aria-label="clear sort/filter"
                                                >
                                                    <SettingsBackupRestoreRoundedIcon />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="refresh data">
                                                <IconButton
                                                    edge="start"
                                                    color="inherit"
                                                    onClick={(): void => reloadData()}
                                                    aria-label="refresh"
                                                >
                                                    <RefreshRoundedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <h4 className={classes.cardTitleWhite} style={{ width: 300 }}>Scheduled Reports</h4>
                                        <p className={classes.cardCategoryWhite} style={{ width: 400 }}>
                                            Scheduled reports identified with the server
                                        </p>
                                    </GridItem>
                                    

                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <Accordion
                                    collapsible={true}
                                    multiple={true}
                                    animationDuration={450}
                                    
                                >
                                    
                                    <Item title='Reports'>
                                        <div>
                                            <div>                                      
                                                <DataGrid
                                                    allowColumnResizing={true}
                                                    allowColumnReordering={true}
                                                    dataSource={reports}
                                                    keyExpr="id"
                                                    ref={gridRef}
                                                >
                                            
                                                    {/*<StateStoring enabled={true} type="localStorage" storageKey="reportListGrid" />*/}
                                                <FilterRow visible={true} />
                                                <HeaderFilter visible={true} />
                                                <GridColumn dataField="id" visible={false} dataType="number" />
                                                <GridColumn dataField="reportName" caption="Report Name" dataType="string" />
                                                <GridColumn dataField="loggerSerials" cellRender={loggersTemplate} caption="Loggers in report" width="150" />
                                                <GridColumn dataField="frequency" caption="Frequency" dataType="string"  />
                                                <GridColumn dataField="reportDate"  dataType="date" caption="Report date" />
                                                <GridColumn dataField="reportCreated"  dataType="date" caption="Created" />
                                                <GridColumn dataField="lastUpdated"  dataType="date" caption="Updated" />
                                                <GridColumn dataField="dataInReport" width="500" dataType="string" caption="Report content" />
                                                <GridColumn dataField="download" width="100" caption="Download" cellRender={downloadTemplate} allowFiltering={false} allowHeaderFiltering={true} />
                                                <Pager allowedPageSizes={[5,10, 20, 50, 100]} showPageSizeSelector={true} />
                                                <Paging defaultPageSize={5} />
                                                </DataGrid>
                                                
                                        </div>
                                        </div>
                                    </Item>
                                    {admin && <Item title='Schedules of reports'>
                                        <div>
                                            <div>
                                                <DataGrid
                                                    allowColumnResizing={true}
                                                    allowColumnReordering={true}
                                                    dataSource={scheduleData}
                                                    keyExpr="id"
                                                    ref={gridRef}
                                                >
                                                    
                                                    {/*<StateStoring enabled={true} type="localStorage" storageKey="reportListGrid" />*/}
                                                    <FilterRow visible={true} />
                                                    <HeaderFilter visible={true} />
                                                    <GridColumn dataField="id" visible={false} dataType="number" />
                                                    <GridColumn dataField="reportName" caption="Report Name" dataType="string" />
                                                    <GridColumn dataField="loggerSerials" cellRender={loggersTemplate} caption="Loggers in report" />
                                                    <GridColumn dataField="frequency" caption="Frequency" dataType="string"  />
                                                    <GridColumn dataField="status" caption="Status" dataType="boolean" cellRender={statusTemplate} />
                                                    <GridColumn dataField="reportCreated"  dataType="date" caption="Created" />
                                                    <GridColumn dataField="lastUpdated"  dataType="date" caption="Updated" />
                                                    <GridColumn dataField="dataInReport" width="500" dataType="string" caption="Report content" />
                                                    <GridColumn dataField="delete" caption="Delete" cellRender={deleteTemplate} allowFiltering={false} allowHeaderFiltering={false} width="100" />
                                                    <Pager allowedPageSizes={[5, 10, 20, 50, 100]} showPageSizeSelector={true} />
                                                    <Paging defaultPageSize={5} />
                                                </DataGrid>
                                                {console.log(gridRef) }
                                            </div>
                                        </div>
                                    </Item>}
                                </Accordion>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>}





        </div>
        
    )
}
export default withStyles(style)(Reports)