import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import { ReactComponent as Yoda } from '../../assets/yoda.svg';
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { APIGetLoggerConnectionsModel, buildAPIGetLoggerConnectionsModel } from "models/APIGetLoggerConnectionsModel";

// DevExtreme
import { DataGrid, Column, Export, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';

import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import dxDataGrid, { dxDataGridRowObject } from "devextreme/ui/data_grid";

const styles: Record<string, any> = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        display: "inline",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Props {
    classes: {
        cardTitleWhite: string;
        cardCategoryWhite: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: string;
    };
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    connections: APIGetLoggerConnectionsModel[];
    tempConnection: APIGetLoggerConnectionsModel | undefined;
    anchorEl: EventTarget & HTMLButtonElement | null;
    redirect: boolean;
    redirectPath: string | undefined;
    redirectProps: { serial: string | undefined } | undefined;
}

class ConnectionsList extends React.Component<Props, State> {

    gridRef: React.RefObject<DataGrid>;

    constructor(props: Props | Readonly<Props>) {
        super(props);
        // Ref to Datagrid to store Page, Sort & Filters
        this.gridRef = React.createRef();

        this.state = {
            tableHidden: false,
            visible: false,
            connections: [],
            tempConnection: undefined,
            anchorEl: null,
            loading: true,
            authorized: true,
            redirect: false,
            redirectPath: undefined,
            redirectProps: undefined
        };

    }
    get dataGrid(): dxDataGrid | undefined {
        return this.gridRef.current?.instance;
    }

    componentDidMount(): void {
        let connData = new Array<APIGetLoggerConnectionsModel>();
 
        const me = this;
        let uri = CreateUrl('/api/aquaguard/RecentConnections?includeUnidentified=true&companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId'));
        if (this.props.location.state == "Failed") {
            uri = CreateUrl('/api/aquaguard/FailedConnections?includeUnidentified=true&companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId'));
        } else if (this.props.location.state == "Unique") {
            uri = CreateUrl('/api/aquaguard/UniqueConnections?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId'));
        }


        CallGetAPI(uri, {})
            .then(data => {
                if (data != null) {
                    // Copy the data records into deviceData, adding the clickEvent
                    connData = buildAPIGetLoggerConnectionsModel(data);

                    me.setState(
                        {
                            connections: connData,
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            connections: [],
                            tableHidden: true,
                            loading: false
                        })
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false
                    })
            });
            
    }
    handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: dxDataGridRowObject): void {   //React.MouseEvent<HTMLButtonElement, MouseEvent>
        const connection = this.state.connections.find((row) => row.id === id.key);

        this.setState(
            {
                tempConnection: connection,
                anchorEl: event.currentTarget
            });
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }


    connectionDetailsClick = (): void => {

        if (this.state.tempConnection?.loggerSerial != null) {
            this.setState(
                {
                    redirect: true,
                    redirectPath: '/portal/DeviceDetail',
                    redirectProps: {
                        serial: this.state.tempConnection?.loggerSerial.toString()
                    }
                });
        }
        else {
            this.setState({ anchorEl: null });
        }
    }

    connectionOverviewClick = (): void => {

        if (this.state.tempConnection?.loggerSerial != null) {
            this.setState(
                {
                    redirect: true,
                    redirectPath: '/portal/DeviceOverview',
                    redirectProps: {
                        serial: this.state.tempConnection?.loggerSerial.toString()
                    }
                });
        }
        else {
            this.setState({ anchorEl: null });
        }
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm:ss");
    }

    /// Customise ms duration into seconds
    columnCustomizeDuration(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return (Math.round(cellInfo.value) / 1000).toFixed(3) + "s";
    }

    /// Customise connection result
    columnCustomizeResult(cellInfo: any): string {

        const results = ["FAILED",
            "COMPLETED",
            "SEND_ERROR",
            "READ_ERROR",
            "READ_TIMEOUT",
            "CLIENT_DISCONNECTED",
            "PARSE_ERROR",
            "UNSUPPORTED_CLIENT",
            "LOG_DOWNLOAD_FAILED",
            "LOG_CRC_MISSMATCH",
            "SUCCESS",
            "FAILED_TO_AUTHENTICATE",
            "UNKNOWN_REQUEST",
            "DATABASE_ERROR",
            "UNIDENTIFIED",
            "INVALID"
        ];

        const intVal = parseInt(cellInfo.value);

        if (cellInfo.value == null
            || intVal == undefined
            || intVal < 0
            || intVal >= results.length) {
            return "";
        }
        else {
            return results[intVal];
        }
    }

    serialColumnTemplate(item: any): ReactNode {
        // Set cell background yellow if config updates and red if firmware update flagged to send
        if (item == null)
            return <div></div>;
        else {
            if (item.data.sendToLogger && item.data.updateConfirmed === null) {
                return <div style={{ backgroundColor: "#fafa9b", margin: "-7px", padding: "7px" }}>{item.data.loggerSerial}</div>;
            }
            else if (((item.data.firmwareToUpdate && item.data.firmwareToUpdate > 0) || (item.data.firmwareSent && item.data.firmwareSent > 0))
                && item.data.firmwareConfirmed === null) {
                return <div style={{ backgroundColor: "#f4bfc4", margin: "-7px", padding: "7px" }}>{item.data.loggerSerial}</div>;
            }
            else {
                return <div>{item.data.loggerSerial}</div>;
            }
        }
    }


    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Readings');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    moreRender(key: dxDataGridRowObject): ReactNode {
        const connection = this.state.connections.find((row) => row.id === key.data.id);
        return (
            <IconButton
                size="small"
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => { this.handleClick(e, key); }}
                disabled={connection?.loggerSerial == null}
            >
                <MoreHorizIcon />
            </IconButton>
        );
    }

    render(): ReactNode {
        const { classes, history } = this.props;

        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                 (!this.state.redirect) ?
                (<div>

                    {this.state.loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    }
                    {!this.state.loading &&
                        <div>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                    <CardHeader color="primary">
                                        <div style={{ display: 'inline' }}>
                                            <IconButton
                                                style={{ display: 'inline' }}
                                                onClick={(): void => history.goBack()}
                                                size="large"><ArrowBackIcon style={{ fill: "white" }} />
                                            </IconButton>
                                            <h4 className={classes.cardTitleWhite}>{this.props.location.state} Connections</h4>
                                        </div>
                                        <p className={classes.cardCategoryWhite}>
                                                {this.props.location.state} connections received by the server
                                        </p>
                                    </CardHeader>

                                    {this.state.tableHidden &&
                                            <CardBody>
                                                <div style={{
                                                    position: 'absolute', left: '50%', top: '50%',
                                                    transform: 'translate(-50%, -50%)'
                                                }}>

                                                    <div className="text-center">
                                                        <span className="fa-2x ">No Data</span >
                                                    </div>
                                                </div>
                                            </CardBody>
                                     }
                                    {!this.state.tableHidden &&
                                        <CardBody>
                                            <DataGrid
                                            dataSource={this.state.connections}
                                            keyExpr="id"
                                            ref={this.gridRef}
                                            columnAutoWidth={true}
                                            columnMinWidth={80}
                                            onExporting={this.onExporting} >
                                            <StateStoring enabled={true} type="localStorage" storageKey="connectionsListGrid" />
                                            <Export enabled={true} />
                                            <GroupPanel visible={true} />
                                            <Grouping autoExpandAll={true} />
                                            <FilterRow visible={true} />
                                            <Column dataField="id" visible={false} dataType="number" />
                                            <Column dataField="loggerSerial" name="Logger" dataType="string" cellRender={this.serialColumnTemplate}/>
                                            <Column dataField="siteId" name="SiteId" dataType="string" />
                                            <Column dataField="clientEndPoint" name="From IP" dataType="string" />
                                            <Column dataField="connectionTime" name="Connection" customizeText={this.dateColumnCustomizeText} dataType="datetime" width="150" defaultSortIndex={1} defaultSortOrder="desc" caption="Connection Time" />
                                            <Column dataField="alarmState" dataType="number" />
                                            <Column dataField="rtcTime" name="Logger RTC" customizeText={this.dateColumnCustomizeText} dataType="datetime" width="150" caption="RTC Time" />
                                            <Column dataField="latestRssi" name="RSSI" dataType="number" />
                                            <Column dataField="readBatteryChannel" name="Battery" dataType="number" />
                                            <Column dataField="logFirst" dataType="number" />
                                            <Column dataField="logLast" dataType="number" />
                                            <Column dataField="logLastUpload" dataType="number" />
                                            <Column dataField="totalConnectionTime" customizeText={this.columnCustomizeDuration} dataType="number" />
                                            <Column dataField="logDownloadTime" customizeText={this.columnCustomizeDuration} dataType="number" />
                                            <Column dataField="stdParamsDlTime" customizeText={this.columnCustomizeDuration} dataType="number" />
                                            <Column dataField="calParamsDlTime" customizeText={this.columnCustomizeDuration} dataType="number" />
                                            <Column dataField="stdParamsUpdateTime" customizeText={this.columnCustomizeDuration} dataType="number" />
                                            <Column dataField="logsDownloaded" dataType="number" />
                                            <Column dataField="logsAvailable" dataType="number" />
                                            <Column dataField="connectionResult" customizeText={this.columnCustomizeResult} dataType="number" />

                                            <Column type="buttons" cellRender={this.moreRender.bind(this)} fixed={true} fixedPosition="right" />

                                            <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                            <Paging defaultPageSize={10} />
                                            </DataGrid>
                                        </CardBody>
                                    }
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </div>
                    }
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                            
                    >
                        <MenuItem onClick={this.connectionOverviewClick.bind(this)}>Overview</MenuItem>
                        <MenuItem onClick={this.connectionDetailsClick.bind(this)}>Details</MenuItem>
                    </Menu>
                </div>)
                :
                 (<Redirect push to={{
                    pathname: this.state.redirectPath,
                    state: this.state.redirectProps
                }}/>)
            )
            :
            (
                <Redirect to={'/Login'} />
            );
    }
}

export default withStyles(style)(ConnectionsList);

