import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerReadingsModel, buildAPIGetLoggerReadingsModel } from "models/APIGetLoggerReadingsModel";
import { APIGetLoggerAlarmLevelModel, buildAPIGetLoggerAlarmLevelModel } from "models/APIGetLoggerAlarmLevelModel";

//Moment date/time formatting
//https://momentjs.com/docs/
import moment, { Duration } from 'moment';

import { withStyles, createStyles } from '@mui/styles';

import Switch from '@mui/material/Switch';
import { decodeFlowMultiplier } from 'Utils/FlowMultiplier';

//DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Chart, { CommonSeriesSettings, ArgumentAxis, Series, Legend, ValueAxis, ZoomAndPan, ScrollBar, Tooltip, TickInterval, Aggregation, Point, Size, Title, Label, Crosshair, CommonAnnotationSettings, Annotation, Strip} from 'devextreme-react/chart';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ReadingTooltip from './ReadingTooltip';
import { locale } from "devextreme/localization";

const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    m3units: {
        color: "#F00",
    }
};

const style = createStyles(styles);

// Data to display on graph 
interface Readings {
    date: Date;
    channel: string;
    reading: number | null;
    altReading: number | null;      // conversion between m3/h & l/s
    readingMax: number | null;
    readingMin: number | null;
    readingSet: number | null;
    readingClr: number | null;
    flowRate: number | null;
    altFlowRate: number | null;
}


interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serial: string;
    startDate: Date;
    endDate: Date;
    channel: string;   // Channel to display ('C', 'D' or 'E')
    isprimary: string;
    title: string;
    units: string;
    colour: string;
    ref: React.RefObject<unknown>;
    cache?: Array<APIGetLoggerReadingsModel>;
    updateCache?: (channel: string, readings: Array<APIGetLoggerReadingsModel>) => void;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    startDate: Date;
    endDate: Date;
    maxValue: number;
    minValue: number;
    altMaxValue: number;
    altMinValue: number;
    readingsData: Array<APIGetLoggerReadingsModel>;
    graphData: Array<Readings> | undefined;
    channel: string;   // Channel to display ('C', 'D' or 'E')
    switchUnits: boolean;
    unitsm3: boolean;
    useFlow: boolean;
    minFlow: number | null;
    altMinFlow: number | null;
    alarmSet: boolean;
}

class ReadingsGraphTOR extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            maxValue: 0,
            minValue: Number.MAX_VALUE,
            altMaxValue: 0,
            altMinValue: Number.MAX_VALUE,
            startDate: props.startDate,
            endDate: props.endDate,
            readingsData: [],
            graphData: undefined,
            channel: "",
            switchUnits: true,
            unitsm3: true,
            useFlow: false,
            minFlow: null,
            altMinFlow: null,
            alarmSet: false,
        };

        // Set DevExtreme Locale (for date formats)
        locale("en-GB");
    }

    componentDidMount(): void {

        console.log("ReadingsGraphTOR DidMount - " + this.props.channel + " Data: " + this.state.readingsData.length + " Cache: " + this.props.cache?.length);
        //get alarms for logger here
        if (this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({ tableHidden: false });
        }
        else {
            this.setState({ tableHidden: true });
        }

    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.startDate != this.props.startDate
            || prevProps.endDate != this.props.endDate
            || prevProps.channel != this.props.channel
        ) {
            console.log("ReadingsGraphTOR DidUpdate - " + this.props.channel);
            this.setState({
                startDate: this.props.startDate,
                endDate: this.props.endDate,
                channel: this.props.channel,
            });
            this.reloadData(this.props.startDate, this.props.endDate);
        }

    }

    reloadData(start: Date, end: Date): boolean {

        this.setState({ loading: true });

        let readingsData = new Array<APIGetLoggerReadingsModel>();
        const graphData = new Array<Readings>();

        let maxValue = 0;
        let minValue = Number.MAX_VALUE;
        let altMaxValue = 0;
        let altMinValue = Number.MAX_VALUE;
        let minFlow = Number.MAX_VALUE;
        let altMinFlow = Number.MAX_VALUE;

        let alarmLevels = new Array<APIGetLoggerAlarmLevelModel>();

        //get alarms for logger here
        const me = this;

        const promises = new Array<Promise<any>>();
        // generate array of Promises each adding the readingsData for a logger
        if (this.props.cache == null || this.props.cache.length == 0) {
            promises.push(
                CallGetAPI(CreateUrl('/api/aquaguard/GetLogReadings?companyid=0&logger=' + this.props.serial + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59" + "&channel=" + this.props.channel), {})
                    .then(json => {
                        readingsData = readingsData.concat(buildAPIGetLoggerReadingsModel(json));
                    })
                    .catch(function (ex) {
                        me.setState(
                            {
                                tableHidden: true,
                                readingsData: [],
                                loading: false,
                            });
                        console.log(ex);
                    })
            );
        }
        else {
            readingsData = this.props.cache.slice();
        }

        promises.push(
            CallGetAPI(CreateUrl('/api/aquaguard/LoggerAlarmLevels?logger=' + this.props.serial + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"), {})
                .then(json => {
                    alarmLevels = buildAPIGetLoggerAlarmLevelModel(json);
                })
                .catch(function (ex) {
                    me.setState(
                        {
                            tableHidden: true,
                            loading: false,
                        });
                    console.log(ex);
                })
        );

        Promise.all(promises).then(() => {

            let units = "";
            let useFlow = false;

            // save readings to cache in parent component
            if (this.props.updateCache) {
                this.props.updateCache(this.props.channel, readingsData);
            }

            readingsData.forEach((v: APIGetLoggerReadingsModel) => {
                if (v.dateStamp != null) {

                    let value = v.value;
                    if (value == null) {
                        value = v.value2;
                    }

                    switch (this.props.channel) {
                        case "A":
                            if (v.channelletter == 'A') {
                                let conversion = 1000;          // Assume m3, conversion to l
                                //let flowConversion = 1000 / 3600;    // Assume m3, conversion to l/s
                                let flowConversion = 3600 / 1000;    // Assume l/s, conversion to m3/h

                                if (v.units) {
                                    units = v.units.substr(0, 1);
                                    if (units != "m") {
                                        conversion = 1 / 1000;              // units l, conversion to m3
                                        //flowConversion = 3600 / 1000;   // units l/s, conversion to m3/h
                                        flowConversion = 1000 / 3600;   // Assume m3, conversion to l/s 
                                    }
                                    else {
                                        conversion = 1;
                                        flowConversion = 3600 / 1000;
                                    }
                                    useFlow = true;
                                }
                                else {
                                    conversion = 1;
                                    flowConversion = 1;
                                }
                                graphData.push({
                                    date: new Date(v.dateStamp),
                                    channel: "Reading",
                                    reading: (value != -32768) ? value : null,
                                    altReading: (value && value != -32768) ? value * conversion : null,
                                    readingMax: (v.maxValue != -32768) ? v.maxValue : null,
                                    readingMin: (v.minValue != -32768) ? v.minValue : null,
                                    readingSet: null,
                                    readingClr: null,
                                    //flowRate: v.flowRate,
                                    //altFlowRate: v.flowRate ? v.flowRate * flowConversion : null,
                                    flowRate: v.flowRate ? v.flowRate * flowConversion : null,
                                    altFlowRate: v.flowRate

                                });
                                if (value != -32768) {
                                    if (value != null && value > maxValue) {
                                        maxValue = value;
                                    }
                                    if (value != null && value < minValue) {
                                        minValue = value;
                                    }
                                    if (value != null && value * conversion > altMaxValue) {
                                        altMaxValue = value * conversion;
                                    }
                                    if (value != null && value * conversion < altMinValue) {
                                        altMinValue = value * conversion;
                                    }
                                    if (v.flowRate != null && v.flowRate < minFlow) {
                                        minFlow = v.flowRate;
                                        altMinFlow = v.flowRate * flowConversion;
                                    }
                                }
                            }
                            break;

                        case "B":

                            if (v.channelletter == 'B') {
                                let conversion = 1000;          // Assume m3, conversion to l
                                //let flowConversion = 1000 / 3600;    // Assume m3, conversion to l/s
                                let flowConversion = 3600 / 1000;    // Assume l/s, conversion to m3/h

                                if (v.units) {
                                    units = v.units.substr(0, 1);
                                    if (units != "m") {
                                        conversion = 1 / 1000;              // units l, conversion to m3
                                        //flowConversion = 3600 / 1000;   // units l/s, conversion to m3/h
                                        flowConversion = 1000 / 3600;   // Assume m3, conversion to l/s 
                                    }
                                    else {
                                        conversion = 1;
                                        flowConversion = 3600 / 1000;
                                    }
                                    useFlow = true;
                                }
                                else {
                                    conversion = 1;
                                    flowConversion = 1;
                                }

                                graphData.push({
                                    date: new Date(v.dateStamp),
                                    channel: "Reading",
                                    reading: (value != -32768) ? value : null,
                                    altReading: (value && value != -32768) ? value * conversion : null,
                                    readingMax: (v.maxValue != -32768) ? v.maxValue : null,
                                    readingMin: (v.minValue != -32768) ? v.minValue : null,
                                    readingSet: null,
                                    readingClr: null,
                                    //flowRate: v.flowRate,
                                    //altFlowRate: v.flowRate ? v.flowRate * flowConversion : null,
                                    flowRate: v.flowRate ? v.flowRate * flowConversion : null,
                                    altFlowRate: v.flowRate

                                });
                                if (value != -32768) {
                                    if (value != null && value > maxValue) {
                                        maxValue = value;
                                    }
                                    if (value != null && value < minValue) {
                                        minValue = value;
                                    }
                                    if (value != null && value * conversion > altMaxValue) {
                                        altMaxValue = value * conversion;
                                    }
                                    if (value != null && value * conversion < altMinValue) {
                                        altMinValue = value * conversion;
                                    }
                                    if (v.flowRate != null && v.flowRate < minFlow) {
                                        minFlow = v.flowRate;
                                        altMinFlow = v.flowRate * flowConversion;
                                    }
                                }
                            }
                            break;

                        case "C":
                            if (v.channelletter == 'C') {
                                graphData.push({
                                    date: new Date(v.dateStamp),
                                    channel: "Reading",
                                    reading: (value != -32768) ? value : null,
                                    altReading: null,
                                    readingMax: (v.maxValue != -32768) ? v.maxValue : null,
                                    readingMin: (v.minValue != -32768) ? v.minValue : null,
                                    readingSet: null,
                                    readingClr: null,
                                    flowRate: null,
                                    altFlowRate: null,
                                });
                                if (value != -32768) {
                                    if (value != null && value > maxValue) {
                                        maxValue = value;
                                    }
                                    if (value != null && value < minValue) {
                                        minValue = value;
                                    }
                                }
                            }
                            break;

                        case "D":
                            if (v.channelletter == 'D') {
                                graphData.push({
                                    date: new Date(v.dateStamp),
                                    channel: "Reading",
                                    reading: (value != -32768) ? value : null,
                                    altReading: null,
                                    readingMax: (v.maxValue != -32768) ? v.maxValue : null,
                                    readingMin: (v.minValue != -32768) ? v.minValue : null,
                                    readingSet: null,
                                    readingClr: null,
                                    flowRate: null,
                                    altFlowRate: null,
                                });
                                if (value != -32768) {
                                    if (value != null && value > maxValue) {
                                        maxValue = value;
                                    }
                                    if (value != null && value < minValue) {
                                        minValue = value;
                                    }
                                }
                            }
                            break;

                        case "E":
                            if (v.channelletter == 'E') {
                                graphData.push({
                                    date: new Date(v.dateStamp),
                                    channel: "Reading",
                                    reading: (value != -32768) ? value : null,
                                    altReading: null,
                                    readingMax: (v.maxValue != -32768) ? v.maxValue : null,
                                    readingMin: (v.minValue != -32768) ? v.minValue : null,
                                    readingSet: null,
                                    readingClr: null,
                                    flowRate: null,
                                    altFlowRate: null,
                                });
                                if (value != -32768) {
                                    if (value != null && value > maxValue) {
                                        maxValue = value;
                                    }
                                    if (value != null && value < minValue) {
                                        minValue = value;
                                    }
                                }
                            }
                            break;
                    }

                }
            });

            let lastSet = 0, lastClr = 0;
            let lastLevel = start;

            alarmLevels.forEach((v: APIGetLoggerAlarmLevelModel) => {
                if (v.startTime != null) {

                    let levelTime = new Date(v.startTime);
                    if (levelTime < start) {
                        levelTime = start;
                    }

                    switch (this.props.channel) {
                        case "A":
                            graphData.push({
                                date: levelTime,
                                channel: "Level",
                                readingSet: v.channelASetLevel,
                                readingClr: v.channelAClearLevel,
                                reading: null,
                                altReading: null,
                                readingMax: null,
                                readingMin: null,
                                flowRate: null,
                                altFlowRate: null,
                            });

                            if ((v.channelASetLevel || 0) > maxValue) {
                                maxValue = v.channelASetLevel || 0;
                            }
                            if ((v.channelAClearLevel || Number.MAX_VALUE) < minValue) {
                                minValue = v.channelAClearLevel || Number.MAX_VALUE;
                            }
                            break;
                        case "B":
                            graphData.push({
                                date: levelTime,
                                channel: "Level",
                                readingSet: v.channelBSetLevel,
                                readingClr: v.channelBClearLevel,
                                reading: null,
                                altReading: null,
                                readingMax: null,
                                readingMin: null,
                                flowRate: null,
                                altFlowRate: null,
                            });

                            if ((v.channelBSetLevel || 0) > maxValue) {
                                maxValue = v.channelBSetLevel || 0;
                            }
                            if ((v.channelBClearLevel || Number.MAX_VALUE) < minValue) {
                                minValue = v.channelBClearLevel || Number.MAX_VALUE;
                            }
                            break;
                        case "C":
                            graphData.push({
                                date: levelTime,
                                channel: "Level",
                                readingSet: v.channelCSetLevel,
                                readingClr: v.channelCClearLevel,
                                reading: null,
                                altReading: null,
                                readingMax: null,
                                readingMin: null,
                                flowRate: null,
                                altFlowRate: null,
                            });

                            if ((v.channelCSetLevel || 0) > maxValue) {
                                maxValue = v.channelCSetLevel || 0;
                            }
                            if ((v.channelCClearLevel || Number.MAX_VALUE) < minValue) {
                                minValue = v.channelCClearLevel || Number.MAX_VALUE;
                            }
                            break;
                        case "D":
                            graphData.push({
                                date: levelTime,
                                channel: "Level",
                                readingSet: v.channelDSetLevel,
                                readingClr: v.channelDClearLevel,
                                reading: null,
                                altReading: null,
                                readingMax: null,
                                readingMin: null,
                                flowRate: null,
                                altFlowRate: null,
                            });

                            if ((v.channelDSetLevel || 0) > maxValue) {
                                maxValue = v.channelDSetLevel || 0;
                            }
                            if ((v.channelDClearLevel || Number.MAX_VALUE) < minValue) {
                                minValue = v.channelDClearLevel || Number.MAX_VALUE;
                            }
                            break;
                        case "E":
                            graphData.push({
                                date: levelTime,
                                channel: "Level",
                                readingSet: v.channelESetLevel,
                                readingClr: v.channelEClearLevel,
                                reading: null,
                                altReading: null,
                                readingMax: null,
                                readingMin: null,
                                flowRate: null,
                                altFlowRate: null,
                            });

                            if ((v.channelESetLevel || 0) > maxValue) {
                                maxValue = v.channelESetLevel || 0;
                            }
                            if ((v.channelEClearLevel || Number.MAX_VALUE) < minValue) {
                                minValue = v.channelEClearLevel || Number.MAX_VALUE;
                            }
                            break;
                    }

                    // Add extra level points every 00:00 for graph zooming
                    while (((new Date(v.startTime).getTime() - lastLevel.getTime()) / (1000 * 60 * 60)) > 24) {
                        const midnight = new Date(new Date(lastLevel.getFullYear(), lastLevel.getMonth(), lastLevel.getDate()).setHours(24, 0, 0, 0));
                        graphData.push({
                            date: midnight,
                            channel: "Level",
                            readingSet: lastSet,
                            readingClr: lastClr,
                            reading: null,
                            altReading: null,
                            readingMax: null,
                            readingMin: null,
                            flowRate: null,
                            altFlowRate: null,
                        });
                        lastLevel = midnight;
                    }

                    switch (this.props.channel) {
                        case "A":
                            lastSet = v.channelASetLevel || 0;
                            lastClr = v.channelAClearLevel || 0;
                            break;
                        case "B":
                            lastSet = v.channelBSetLevel || 0;
                            lastClr = v.channelBClearLevel || 0;
                            break;
                        case "C":
                            lastSet = v.channelCSetLevel || 0;
                            lastClr = v.channelCClearLevel || 0;
                            break;
                        case "D":
                            lastSet = v.channelDSetLevel || 0;
                            lastClr = v.channelDClearLevel || 0;
                            break;
                        case "E":
                            lastSet = v.channelESetLevel || 0;
                            lastClr = v.channelEClearLevel || 0;
                            break;
                    }

                }
            });

            // Add last alarm levels at end
            graphData.push({
                date: end,
                channel: "Level",
                readingSet: lastSet,
                readingClr: lastClr,
                reading: null,
                altReading: null,
                readingMax: null,
                readingMin: null,
                flowRate: null,
                altFlowRate: null,
            });

            // and any extra level points every 00:00 for graph zooming
            while (((end.getTime() - lastLevel.getTime()) / (1000 * 60 * 60)) > 24) {
                const midnight = new Date(new Date(lastLevel.getFullYear(), lastLevel.getMonth(), lastLevel.getDate()).setHours(24, 0, 0, 0));
                graphData.push({
                    date: midnight,
                    channel: "Level",
                    readingSet: lastSet,
                    readingClr: lastClr,
                    reading: null,
                    altReading: null,
                    readingMax: null,
                    readingMin: null,
                    flowRate: null,
                    altFlowRate: null,
                });

                lastLevel = midnight;
            }

            graphData.sort((a, b) => a.date.getTime() - b.date.getTime());

            console.log("Channel " + me.props.channel + " Min: " + minValue + " Max: " + maxValue);

            me.setState({
                minValue: 0,
                maxValue: maxValue * 2,
                altMinValue: 0,
                altMaxValue: altMaxValue * 2,
                readingsData: readingsData,
                graphData: graphData,
                unitsm3: units == 'm',
                useFlow: useFlow,
                minFlow: minFlow == Number.MAX_VALUE ? null : minFlow,
                altMinFlow: altMinFlow == Number.MAX_VALUE ? null : altMinFlow,
                loading: false,
                alarmSet: lastSet > 0,
            });

        });



    return true;
}

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Alarms');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("HH:mm DD/MM/YYYY");
    }

    dateLabelCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY");
    }

    annotationTemplate(annotation: any): ReactNode {
        return (
            <svg className="annotation">
                <text x="20" y="20" onClick={this.toggleFlowUnit.bind(this)}>
                    <tspan fill={this.state.switchUnits ? "red" : "black"}>l/s</tspan>
                    <tspan> : </tspan>
                    <tspan fill={this.state.switchUnits ? "black" : "red"}>m3/h</tspan>
                </text>
            </svg>
            );
    }

    toggleFlowUnit(): void {
        console.log("Switch!");
        this.setState({ switchUnits: !this.state.switchUnits });
    }


    render(): ReactNode {

        let units = "";
        let flowUnits = "";

        if (this.state.useFlow) {
            if ((this.state.unitsm3 && !this.state.switchUnits) || (!this.state.unitsm3 && this.state.switchUnits)) {
                units = "m3";
                flowUnits = "m3/h";
            }
            else {
                units = "m3";
                flowUnits = "l/s";
            }
        }
        else {
            if (this.props.units && this.props.units != "") {
                units = this.props.units;
            }
            else {
                units = "mBar";
            }
        }


        return (

            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'relative', left: '40%', top: '50%',
                    }}>
                        <ClipLoader
                            size={50}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                    {!this.state.tableHidden &&
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                            <p style={{ fontWeight: "bold", fontSize: "10pt", textAlign: "center" }}>
                                {this.props.title == "" ?
                                    <span>Channel {this.props.channel}</span>
                                    :
                                    <span>{this.props.title}</span>
                                }
                            </p>
                            <Chart dataSource={this.state.graphData} autoHidePointMarkers={false} >
                                <Size height={this.props.isprimary == "yes" ? 500 : 130} width="100%" />
                                    <CommonSeriesSettings
                                        argumentField="date"
                                        type="spline"
                                        ignoreEmptyPoints={true}>
                                        <Point size={4} />
                                    </CommonSeriesSettings>
                                <Series valueField="reading" name={this.props.channel} color={this.props.colour} axis="Value" point={{ visible: false }} visible={!this.state.switchUnits} showInLegend={!this.state.switchUnits} />
                                <Series valueField="altReading" name={this.props.channel} color={this.props.colour} axis="Value" point={{ visible: false }} visible={this.state.switchUnits} showInLegend={this.state.switchUnits} />
                                {this.state.alarmSet &&
                                    <Series valueField="readingSet" name={this.props.channel + " Set"} color={this.props.colour} showInLegend={false} dashStyle="dash" visible={true} />
                                }
                                {this.state.alarmSet &&
                                    <Series valueField="readingClr" name={this.props.channel + " Clear"} color={this.props.colour} showInLegend={false} dashStyle="dot" visible={true} />
                                }
                                <ValueAxis
                                    name="Value"
                                    visualRange={{ startValue: this.state.switchUnits ? this.state.altMinValue : this.state.minValue, endValue: this.state.switchUnits ? this.state.altMaxValue : this.state.maxValue }}
                                    allowDecimals={false}
                                    title={{ text: units, font: { size: this.props.isprimary == "yes" ? 12 : 9 } }}
                                >
                                </ValueAxis>
                                {this.state.useFlow && (this.props.channel == "A" || this.props.channel == "B") &&
                                    <Series valueField="flowRate" name="FlowRate" color="red" axis="Flow" point={{ visible: false }} visible={!this.state.switchUnits} showInLegend={!this.state.switchUnits}/>
                                }
                                {this.state.useFlow && (this.props.channel == "A" || this.props.channel == "B") &&
                                    <Series valueField="altFlowRate" name="FlowRate" color="red" axis="Flow" point={{ visible: false }} visible={this.state.switchUnits} showInLegend={this.state.switchUnits}/>
                                }
                                {this.state.useFlow && (this.props.channel == "A" || this.props.channel == "B") &&
                                    <ValueAxis name="Flow" title={{ text: "Flow (" + flowUnits + ")", font: { color: "red", size: this.props.isprimary == "yes" ? 12 : 9 } }} position="right" color="red" >
                                    <Label font={{ color: "red" }} />
                                    {this.props.isprimary == "yes" && this.state.minFlow && this.state.minFlow > 0 && !this.state.switchUnits &&
                                        <Strip label={{ text: "Min Flow", font: { color: "#337ab7" }, verticalAlignment: "top" }} color="#337ab7" startValue={this.state.minFlow} endValue={this.state.minFlow - (this.state.minFlow / 100)} />
                                    }
                                    {this.props.isprimary == "yes" && this.state.altMinFlow && this.state.altMinFlow > 0 && this.state.switchUnits &&
                                        <Strip label={{ text: "Min Flow", font: { color: "#337ab7" }, verticalAlignment: "top" }} color="#337ab7" startValue={this.state.altMinFlow} endValue={this.state.altMinFlow - (this.state.altMinFlow / 100)} />
                                    }
                                    </ValueAxis>
                                }
                                <ArgumentAxis
                                        allowDecimals={false}
                                        argumentType="datetime"
                                        minValueMargin={0.1}
                                        maxValueMargin={0.1}
                                        wholeRange={[this.state.startDate, this.state.endDate]}
                                        >
                                    <TickInterval days={1} />
                                    </ArgumentAxis>
                                {this.props.isprimary == "yes" &&
                                    <ZoomAndPan
                                        argumentAxis="both"
                                        valueAxis="none"
                                        dragToZoom
                                    />
                                }
                                    <ScrollBar
                                    visible={false}
                                    />
                                <Legend visible={this.props.isprimary == "yes"} />
                                <Tooltip
                                    enabled={true}
                                    contentRender={ReadingTooltip}
                                />
                                <Crosshair
                                    enabled={true}
                                />
                                {((this.props.channel == "A" || this.props.channel == "B") && this.props.isprimary == "yes" && this.state.useFlow ) &&
                                     <Annotation
                                        type="custom"
                                        render={this.annotationTemplate.bind(this)}
                                        allowDragging={true}
                                        x={150}
                                        y={40}
                                    >
                                    </Annotation>
                                }
                                </Chart>
                            </GridItem>
                        </GridContainer>

                    }
                    </div>
                }

            </div>

        )
    }

}

export default withStyles(style)(ReadingsGraphTOR);