import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Row, Col } from 'reactstrap';
import { Redirect } from "react-router-dom";

import { APILoggerDetail, buildAPILoggerDetail } from "models/APILoggerDetail";
import { APIGetSitesLoggersModel } from "models/APIGetSitesLoggersModel";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { withStyles, createStyles } from '@mui/styles';
import WebMapView from "components/Map/WebMapView";
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import XMLParser from "react-xml-parser";
import ClipLoader from "react-spinners/ClipLoader";

import { MapFilter } from "components/Map/MapFilter"
import { RainfallControl } from '../../components/Map/RainfallControl';

const style = createStyles(styles as Record<string, any>);

interface Props {
    classes: {
        cardTitleWhite: string;
        cardCategoryWhite: string;
    };
}

interface State {
    filters: Array<string>;
    regions: Array<Region>;
    sites: Array<APIGetSitesLoggersModel>;
    showRegions: boolean;
    showAllSites: boolean;
    showRainfall: boolean;
    alarmFilter: number;
    loading: boolean;
    authorized: boolean;
    slides: Array<{ time: number; path: string }>;
    currentSlide: number;
    trunkSegments: Array<any>;
    groupFilter: number;
    redirect: boolean;
    redirectPath: string | undefined;
    redirectProps: { serial: string | undefined } |
    {
        groupId: number | null;
        loggers: Array<number>;
    }
    | undefined;
}

interface Region {
    id: number;
    name: string;
    coordinates: Array<{ latitude: number; longitude: number }>;
}

interface Site {
    Id: number;
    SiteNameUserFriendly: string;
    SiteName: string;
    Latitude: number;
    Longitude: number;
    Loggers: Array<APILoggerDetail>;

}

export class MapView extends React.Component<Props, State> {

    regionData: Array<Region> = [];
    siteData: Array<APIGetSitesLoggersModel> = [];

    constructor(props: Props) {
        super(props);

        let groupFilter = window.sessionStorage.getItem("filterGroupId");
        if (groupFilter === null) {
            groupFilter = "0";
        }

        this.state = {
            filters: ["Regions", "Sites", "Rainfall", "Alarms"],
            regions: [],
            sites: [],
//            groups: [],
            loading: false,
            authorized: false,
            showRainfall: false,
            showAllSites: false,
            showRegions: false,
            slides: [],
            currentSlide: 0,
            alarmFilter: -1,
            trunkSegments: [],
            groupFilter: parseInt(groupFilter),
            redirect: false,
            redirectPath: undefined,
            redirectProps: undefined,
        };
    }


    toggleModal = (): void => {
        this.setState({

        });
    }

    componentDidMount(): void {

        this.loadSites();
    }

    filterChange(filters: boolean[]): void {

        // Regions=0, AllSites=1, Rainfall=2, Alarm0=3, Alarm1=4, AlarmN=5, Groups=6

        console.log(filters);
        if (filters[0]) {
            if (this.state.regions.length == 0) {
                this.loadRegions();
            }
            else {
                this.setState({ showRegions: true });
            }
        }
        else {
            this.setState({ showRegions: false });
        }
        if (filters[1]) {
            if (this.state.sites.length == 0) {
                this.loadSites();
            }
            else {
                this.setState({ showAllSites: true });
            }
        }
        else {
            this.setState({ showAllSites: false });
        }
        let alarmFilter = -1;
        if (filters[3] || filters[4] || filters[5]) {
            alarmFilter = 0;
            if (filters[3]) {
                alarmFilter += 1;
            }
            if (filters[4]) {
                alarmFilter += 2;
            }
            if (filters[5]) {
                alarmFilter += 4;
            }
        }

        this.setState(
            {
                showRainfall: filters[2],
                alarmFilter: alarmFilter,
            });
    }

    loadRegions(): void {

        if (this.regionData.length == 0) {

            //fetch regions

            const me = this;
            this.regionData = [];
            this.setState({ loading: true });

            CallGetAPI(CreateUrl('/api/aquaguard/RegionKMLs?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
                .then(data => {

                    if (data.length > 0) {

                        // Copy the data records into regionData

                        for (let i = 0; i < data.length; i++) {
                            const id = data[i].id;
                            const coordArray: Array<{ latitude: number; longitude: number }> = [];
                            const res = me.getXmlFileName(data[i].content).split(" ");
                            res.forEach((element: string) => {
                                const parts = element.split(",");
                                coordArray.push({ latitude: parseFloat(parts[0]), longitude: parseFloat(parts[1]) });
                            }
                            );

                            const rec = {
                                id: id,
                                name: data[i].name,
                                coordinates: coordArray

                            };
                            this.regionData.push(rec);
                        }

                        me.setState(
                            {
                                regions: this.regionData,
                                showRegions: true,
                                loading: false
                            });
                        console.log("Regions loaded");
                    }
                    else {
                        me.setState(
                            {
                                loading: false
                            });
                    }
                })
                .catch(function (error) {
                    me.setState(
                        {
                            authorized: false,
                            loading: false
                        });
                    console.log(error);
                });
        }
        else {
            this.setState({ regions: this.regionData });
        }

    }

    getXmlFileName = (rawXml: string): string => {
        const xml = new XMLParser().parseFromString(rawXml);
//        return xml.getElementsByTagName('name')[0].value;
        return xml.getElementsByTagName('coordinates')[0].value
    }

    loadSites(): void {

        if (this.siteData.length == 0) {
            //fetch sites

            const me = this;
            this.siteData = [];
            this.setState({ loading: true });

            CallGetAPI(CreateUrl('/api/aquaguard/SitesForCompany?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
                .then(data => {

                    if (data.length > 0) {

                        // Copy the data records into regionData

                        for (let i = 0; i < data.length; i++) {
                            const id = data[i].siteId;

                            const loggers: Array<APILoggerDetail> = [];
                            if (data[i].loggers) {
                                data[i].loggers.forEach((element: any) => {
                                    loggers.push(buildAPILoggerDetail(element));
                                });
                            }

                            const rec: APIGetSitesLoggersModel = {
                                SiteId: id,
                                SiteNameUserFriendly: data[i].siteNameUserFriendly ? data[i].siteNameUserFriendly : data[i].siteName,
                                SiteName: data.siteName,
                                Latitude: data[i].latitude || 0,
                                Longitude: data[i].longitude || 0,
                                Created: null,
                                Deleted: null,
                                FkCompanyId: 0,
                                DefaultSite: false,
                                LastUpdate: null,
                                FkEASiteId: null,
                                AlarmsEnabled: data[i].alarmsEnabled,
                                AlarmState: data[i].alarmState,
                                Loggers: loggers
                            };
                            if (rec.Latitude != 0 || rec.Longitude != 0) {
                                    me.siteData.push(rec);
                            }
                        }

                        me.setState(
                            {
                                sites: me.siteData,
                                loading: false
                            });
                        console.log("Sites loaded");
                    }
                    else {
                        me.setState(
                            {
                                loading: false
                            });
                    }
                })
                .catch(function (error) {
                    me.setState(
                        {
                            authorized: false,
                            loading: false
                        });
                    console.log(error);
                });
        }
        else {
            this.setState({ sites: this.siteData });
        }

    }

    loadRainfallOverlays(slides: Array<{ time: number; path: string }>): void {
        this.setState({ slides: slides, currentSlide: 0 });
        console.log("MapView: slides=", slides);
    }

    slideChange(idx: number): void {
        this.setState({ currentSlide: idx });
        console.log("MapView: change to slide: " + idx);
    }

    onButtonClick(sites: Array<number>): void {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/multiLoggerGraph',
                redirectProps: {
                    groupId: null,
                    loggers: sites,
                }
            });
    }

    render(): ReactNode  {
      
        const { classes } = this.props;

        return (
            (!this.state.redirect) ?
            <div>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                <h4 className={classes.cardTitleWhite}>
                                    Mapping
                                    {
                                        this.state.groupFilter > 0 &&
                                        <span>
                                            &nbsp;- All values filtered to sites in group {window.sessionStorage.getItem("filterGroupName")}
                                        </span>
                                    }
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={4}>
                                        Show/Filter: <MapFilter filters={this.state.filters} onFilterChange={this.filterChange.bind(this)} />
                                    </Col>
                                    <Col md={8}>
                                        <RainfallControl enabled={this.state.showRainfall} onReload={this.loadRainfallOverlays.bind(this)} onSlideChange={this.slideChange.bind(this)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                    {this.state.loading &&
                                        <div style={{
                                            position: 'absolute', left: '50%', top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            zIndex: 100
                                        }}>
                                            <ClipLoader
                                            size={150}
                                            color={"#123abc"}
                                            loading={this.state.loading}
                                            />
                                        </div>
                                        }
                                        <WebMapView center={[-3, 51]} zoom={8}
                                            trunkSegments={this.state.trunkSegments}
                                            regions={this.state.regions}
                                            showRegions={this.state.showRegions}
                                            sites={this.state.sites}
                                            showAllSites={this.state.showAllSites}
                                            showRainfall={this.state.showRainfall}
                                            slides={this.state.slides}
                                            currentSlide={this.state.currentSlide}
                                            colour='rgba(0, 0, 0, 1)'
                                            alarmFilter={this.state.alarmFilter}
                                            visibleSitesButton="Graph visible sites"
                                                onButtonClick={this.onButtonClick.bind(this)}
                                                mouseWheelZoom={true}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
            :
            (<Redirect push to={{
                pathname: this.state.redirectPath,
                state: this.state.redirectProps
            }} />)
        );
    }
}
export default withStyles(style)(MapView);