export enum enumChariotAlarmInput {
    Disabled = 0,
    FlowA = 1,
    FlowTotal = 2,
    Pressure1Latest = 3,
    Pressure2Latest = 7,
    FlowB = 9,              //AQGD-908
    FlowBTotal = 10,        //AQGD-908
    Pressure1Diff = 11,
    Pressure2Diff = 12,
    ChannelCompare = 20,
    Consumption = 21,       //AQGD-858
    ConsumptionB = 22,      //AQGD-908
    SignalStrength = 30,
    Battery = 31
}