//
// This file defined backgroundColor constants for device status

export const deviceNormal = "#ffffff";      // White
export const deviceConfigUpdate = "#fafa9b";    // Yellow
export const deviceFirmwareUpdate = "#FF6700"; // Pink
export const deviceUndeploy = "#dda3e0";  // Purple
export const deviceUndeployed = "#a3efe4";  // Cyan
export const deviceDormant = "#e6e6e6";  // Grey

// Returns the style object for a div tag
// Param: item.data - a Device format object as per APIGetLoggersModel

export function setDeviceBackground(item) {
    if (item.data.dormant) {
        return { backgroundColor: deviceDormant, margin: "-7px", padding: "7px" };
    }
    else if (item.data.undeploy) {
        return { backgroundColor: deviceUndeploy, margin: "-7px", padding: "7px" };
    }
    else if (item.data.sendToLogger && item.data.updateConfirmed === null) {
        return { backgroundColor: deviceConfigUpdate, margin: "-7px", padding: "7px" };
    }
    else if (((item.data.firmwareToUpdate && item.data.firmwareToUpdate > 0) || (item.data.firmwareSent && item.data.firmwareSent > 0))
        && item.data.firmwareConfirmed === null) {
        return { backgroundColor: deviceFirmwareUpdate, margin: "-7px", padding: "7px" };
    }
    else if (item.data.deployed == "Spare") {
        return { backgroundColor: deviceUndeployed, margin: "-7px", padding: "7px" };
    }
    else {
        return { backgroundColor: deviceNormal };
    }

}