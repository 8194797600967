export const roadTileMap = {tileId: "r", straightJunction_horizontal: 28, straightJunction_vertical: 18, bend_1: 27, bend_2: 17, bend_3: 30, bend_4: 29, tJunction_1: 44, tJunction_2: 43, tJunction_3: 42, tJunction_4: 45, cross: 41, outJunction_bend_1: 41, outJunction_bend_2: 41, outJunction_bend_3: 41, outJunction_bend_4: 41, edge_1: 48, edge_2: 49, edge_3: 47, edge_4: 46}
export const bankedRiverTileMap = {tileId: "rb", straightJunction_horizontal: 57, straightJunction_vertical: 55, bend_1: 56, bend_2: 54, bend_3: 59, bend_4: 58, tJunction_1: 57, tJunction_2: 57, tJunction_3: 57, tJunction_4: 57, cross: 57, outJunction_bend_1: 57, outJunction_bend_2: 57, outJunction_bend_3: 57, outJunction_bend_4: 57, edge_1: 57, edge_2: 55, edge_3: 57, edge_4: 55}
export const riverTileMap = {tileId: "rv", straightJunction_horizontal: 28, straightJunction_vertical: 18, bend_1: 27, bend_2: 17, bend_3: 30, bend_4: 29, tJunction_1: 44, tJunction_2: 43, tJunction_3: 42, tJunction_4: 45, cross: 41, outJunction_bend_1: 41, outJunction_bend_2: 41, outJunction_bend_3: 41, outJunction_bend_4: 41, edge_1: 28, edge_2: 18, edge_3: 28, edge_4: 18}
export const grassTileMap = {tileId: "gr", straightJunction_horizontal: 66, straightJunction_vertical: 66, bend_1: 66, bend_2: 66, bend_3: 66, bend_4: 66, tJunction_1: 66, tJunction_2: 66, tJunction_3: 66, tJunction_4: 66, cross: 66, outJunction_bend_1: 66, outJunction_bend_2: 66, outJunction_bend_3: 66, outJunction_bend_4: 66, edge_1: 66, edge_2: 66, edge_3: 66, edge_4: 66}
export const waterTileMap = {tileId: "w", straightJunction_horizontal: 3, straightJunction_vertical: 3, bend_1: 8, bend_2: 10, bend_3: 4, bend_4: 6, tJunction_1: 3, tJunction_2: 3, tJunction_3: 3, tJunction_4: 3, cross: 11, outJunction_bend_1: 13, outJunction_bend_2: 12, outJunction_bend_3: 15, outJunction_bend_4: 14, edge_1: 3, edge_2: 5, edge_3: 7, edge_4: 9}
export const beachTileMap = {tileId: "b", straightJunction_horizontal: 85, straightJunction_vertical: 85, bend_1: 86, bend_2: 84, bend_3: 89, bend_4: 87, tJunction_1: 3, tJunction_2: 3, tJunction_3: 3, tJunction_4: 3, cross: 11, outJunction_bend_1: 80, outJunction_bend_2: 79, outJunction_bend_3: 82, outJunction_bend_4: 81, edge_1: 85, edge_2: 83, edge_3: 88, edge_4: 90}

export const dirtTileMap = {tileId: "d", straightJunction_horizontal: 77, straightJunction_vertical: 77, bend_1: 77, bend_2: 77, bend_3: 77, bend_4: 77, tJunction_1: 77, tJunction_2: 77, tJunction_3: 77, tJunction_4: 77, cross: 77, outJunction_bend_1: 77, outJunction_bend_2: 77, outJunction_bend_3: 77, outJunction_bend_4: 77, edge_1: 77, edge_2: 77, edge_3: 77, edge_4: 77}
export const sandTileMap = {tileId: "s", straightJunction_horizontal: 78, straightJunction_vertical: 78, bend_1: 78, bend_2: 78, bend_3: 78, bend_4: 78, tJunction_1: 78, tJunction_2: 78, tJunction_3: 78, tJunction_4: 78, cross: 78, outJunction_bend_1: 78, outJunction_bend_2: 78, outJunction_bend_3: 78, outJunction_bend_4: 78, edge_1: 78, edge_2: 78, edge_3: 78, edge_4: 78}

export const pipesTileMap = {tileId: "p", straightJunction_horizontal: 119, straightJunction_vertical: 118, bend_1: 123, bend_2: 122, bend_3: 121, bend_4: 124, tJunction_1: 125, tJunction_2: 128, tJunction_3: 127, tJunction_4: 126, cross: 120, outJunction_bend_1: 120, outJunction_bend_2: 120, outJunction_bend_3: 120, outJunction_bend_4: 120, edge_1: 119, edge_2: 118, edge_3: 119, edge_4: 118}