import { adjustTime } from '../Utils/AdjustTime'

export interface APIGetLoggerEventsModel {
    /// <summary>
    /// Identity, Indexed, Required
    /// </summary>
    id: number;
    recordCode: number | string;
    eventCode: number | string;
    dateCreated: Date;
    details: string;
    siteName: string;
    loggerSerial: number;
    watchDogResets: number;
    porBor: number;
    userFatal: number;
    energyLevel: number;
    coverageClass: number;
    powerSavingConnsPerc: number;
    remainingBattPerc: number;
    remainingBattYears: number;
}

function recordCodeColumnCustomizeText(value: number | string): string {
    if (typeof value == "string")
        return value;
    if (value == null)
        return "";
    else {
        switch (value) {
            case 1:
                return "TEXT";
            case 2:
                return "AQREADING";
            case 3:
                return "ELREADING";
            case 4:
                return "SERIAL_CHANGE";
            case 5:
                return "DATA_SENT";
            case 6:
                return "PULSE_A";
            case 7:
                return "PULSE_B";
            case 13:
                return "PRESSURE";
            case 14:
                return "UPLOAD";
            case 15:
                return "STATE";
            case 16:
                return "TIMESTATE";
            case 17:
                return "ANALOGUE_0";
            case 18:
                return "SMREADING";
            case 19:
                return "SYSTEM_EVENT";
            case 20:
                return "PARAMETER";
            case 21:
                return "ANALOGUE_1";
            case 23:
                return "PRESSURE_EXT_1";
            case 24:
                return "PRESSURE_EXT_2";
            case 25:
                return "ALT_AQREADING";
            case 26:
                return "ALT_ELREADING";
            case 27:
                return "ALT_SMREADING";
            case 28:
                return "ALT_PULSE_A";
            case 29:
                return "ALT_PULSE_B";
            case 30:
                return "ALT_ANALOGUE_0";
            case 31:
                return "ALT_ANALOGUE_1";
            case 32:
                return "ALT_PRESSURE_EXT_1";
            case 33:
                return "ALT_PRESSURE_EXT_2";
            case 34:
                return "SYSTEM_STATUS";
            case 35:
                return "ALARM";
            case 36:
                return "ANALOGUE_2";
            case 37:
                return "ALT_ANALOGUE_2";
            case 38:
                return "GPS_LOCATION";
            case 39:
                return "BASELINE_COMPLETE";
            case 40:
                return "FLOW0_SMALL";
            case 41:
                return "FLOW1_SMALL";
            case 42:
                return "ALT_FLOW0_SMALL";
            case 43:
                return "ALT_FLOW1_SMALL";
            case 44:
                return "ANALOGUE0_SMALL";
            case 45:
                return "ANALOGUE1_SMALL";
            case 46:
                return "ANALOGUE2_SMALL";
            case 47:
                return "ALT_ANALOGUE0_SMALL";
            case 48:
                return "ALT_ANALOGUE1_SMALL";
            case 49:
                return "ALT_ANALOGUE2_SMALL";
            case 50:
                return "SIM_CHANGE";
            case 51:
                return "AUTO_PARAM_SAVE";
            case 52:
                return "BATTERY INFO";
            case 254:
                return "LOG_ERASED";
        }
    }

    return "UNKNOWN";

}

function eventCodeColumnCustomizeText(value: number | string): string {
    if (typeof value == "string")
        return value;
    if (value == null)
        return "";
    else {
        switch (value) {
            case 0:
                return "STARTUP";
            case 1:
                return "SW_CHANGE";
            case 2:
                return "GSM_ATTEMPT";
            case 3:
                return "GPRS_ATTEMPT";
            case 4:
                return "REMOTE_CONTROL";
            case 5:
                return "LOCAL_CONTROL";
            case 6:
                return "SMS_CONFIG";
            case 7:
                return "SMS_REPORT";
            case 8:
                return "SIGNAL_CHECK";
            case 9:
                return "SIGNAL_STATUS";
            case 10:
                return "MODEM_ON";
            case 11:
                return "MODEM_OFF";
            case 12:
                return "MODEM_ACTIVE";
            case 13:
                return "MODEM_TIMEOUT";
            case 14:
                return "DATA_TIMEOUT";
            case 15:
                return "LISTEN";
            case 16:
                return "GPS_ON";
            case 17:
                return "GPS_SLEEP";
            case 18:
                return "GPS_OFF";
            case 19:
                return "SMS_SENT_OK";
            case 20:
                return "SMS_SEND_FAI";
            case 21:
                return "MODEM_SIM_READY";
            case 22:
                return "GSM_REG";
            case 23:
                return "GSM_DATA_CONNECTED";
            case 24:
                return "GSM_DIAL_FAILED";
            case 25:
                return "GSM_DIALLING";
            case 26:
                return "GPRS_REG";
            case 27:
                return "DNS_LOOKUP";
            case 28:
                return "DNS_RESOLVED";
            case 29:
                return "TCP_CONNECT";
            case 30:
                return "TCP_OPEN";
            case 31:
                return "TCP_CONNECT_FAIL";
            case 32:
                return "SERVER_CONNECTED";
            case 33:
                return "DATACONN_DISCONNECT";
            case 34:
                return "DATACONN_DISCONNECTED";
            case 35:
                return "SMS_TX_TIMEOUT";
            case 36:
                return "SMS_RX_TIMEOUT";
            case 37:
                return "SMS_RX_COUNT";
            case 38:
                return "SMS_RX_MSG";
            case 39:
                return "AUTO_PARAM_SAVE";
            case 40:
                return "IRIDIUM_SBDIX";
            case 41:
                return "MODEM_LOG";
            case 42:
                return "LCL STATUS";
            case 45:
                return "BATTERY INFO";
        }
    }

    return "UNKNOWN";

}


export function buildAPIGetLoggerEventsModel(r: APIGetLoggerEventsModel[]): APIGetLoggerEventsModel[] {

    const result: APIGetLoggerEventsModel[] = new Array<APIGetLoggerEventsModel>();

    r?.forEach(function (rec: any) {
        rec.dateCreated = adjustTime(rec.dateCreated);
        rec.recordCode = recordCodeColumnCustomizeText(rec.recordCode);
        rec.eventCode = eventCodeColumnCustomizeText(rec.eventCode);
        result.push(rec);
    });

    return result;
}




