export const CreateInitials = (username) => {
    const res = username.slice(0, username.indexOf('@') );
    let result = "";
   
    let i;
    for (i = 0; i < res.length; i++) {
        if (res.charAt(i) === res.charAt(i).toUpperCase()
            && res.charAt(i) !== res.charAt(i).toLowerCase()) {
            result = result + res.charAt(i);
        } 
    }
    
    

    if(result === ""){
        result = res.slice(0, 1);
    }
    return result.toUpperCase();
}
