import React from 'react'
import Chart, { Grid as GridChart, CommonSeriesSettings, ArgumentAxis, Series, Legend, ValueAxis, ZoomAndPan, ScrollBar, Tooltip, TickInterval, Aggregation, Point, Title, AggregationInterval, Label, Height, Crosshair, Font, MinorTickInterval, Tick, MinorTick, Strip, CommonAxisSettings } from 'devextreme-react/chart';
import moment from 'moment'
import size from 'esri/smartMapping/renderers/size';



interface Props {
    startDate: Date;
    visible: boolean;
    channel: string;
    logger: string;
    unit: string;
    minAxis: number;
    maxAxis: number;
    axisPosition: string;
    graphType: string;
    lineWidth: number;
    color: string;
    labelSize: number;
}
const MiniGraph: React.FC<Props> = ({ startDate, visible, channel, logger, unit, minAxis, maxAxis, axisPosition, graphType, lineWidth, color, labelSize }) => {


    const demoData = [
        {
            date: startDate,
            value: 0
        },
        {
            date: moment(startDate).add(1, "days"),
            value: 5
        },
        {
            date: moment(startDate).add(2, "days"),
            value: 3
        },
        {
            date: moment(startDate).add(3, "days"),
            value: 1
        },
        {
            date: moment(startDate).add(4, "days"),
            value: 3
        },
        {
            date: moment(startDate).add(5, "days"),
            value: 2
        },
    ]
    return (
        <div>
            {visible &&
                <div>
                    <div>
                        <h6 style={{ fontSize: "14px", margin: "10px 0 0 30px ", color: "#000000" }}><b>Styling Example</b></h6>
                    </div>
                    <Chart dataSource={demoData} style={{ height: "200px", width: "400px", margin: "15px 70px 0 0" }}>
                        <CommonSeriesSettings
                            argumentField="date"
                            ignoreEmptyPoints={true}>
                            <Point size={15} />
                            <Font weight={900} size={40} />
                        </CommonSeriesSettings>
                        <CommonAxisSettings
                            valueMarginsEnabled={true}
                            minValueMargin={0.1}    //add extra 10% to show a 'gap'
                            maxValueMargin={0.1}    //add extra 10% to show a 'gap'
                        />
                            <Series color={`${color}`} type={`${graphType}`} axis={`${channel}`} valueField={`value`} name={`${channel}`} point={{ visible: false }} width={lineWidth} barWidth={lineWidth} />
                        <ValueAxis
                                width={2}
                                name={channel}
                                title={{ text: `${logger} ${channel} ${unit}`, font: { color: `${color}`, size: `${labelSize}` } }}
                                position={axisPosition}
                                color={`${color}`}
                                label={{ font: { color: `${color}`, size: `${labelSize}` } } }
                    
                    
                        >
                                <Label font={{ color: `${color}`}} />
                        </ValueAxis>
                        <ArgumentAxis
                            width={2}
                            allowDecimals={false}
                            argumentType="date"
                            label={{ font: {size: `${labelSize}`}} }
                        >
                            <GridChart visible={true} />
                        </ArgumentAxis>
                        <Legend
                            visible={false}
                        />
                    </Chart >
            </div>}
        </div>
 )
}
export default MiniGraph