import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import PropTypes, { bool } from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Button from '@mui/material/Button';

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";

import { CreateUrl, CallGetAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';

const useStyles = makeStyles(styles);

interface Props {
    color: "primary" | "info" | "success" | "warning" | "danger";
    styling: React.CSSProperties;
}

export default function SystemInfoBar(props: Props) {
    const classes = useStyles();

    const [messages, setMessages] = useState(new Array<{ label: string, value: number }>());
    const [checked, setChecked] = useState(false);

    useEffect((): void => {
        //fetch notifications

        const userId = sessionStorage.getItem('userId');
        if (!checked) {
            setChecked(true);
            CallGetAPI(CreateUrl('/api/aquaguard/GetSystemInfoForUser?userId=' + userId), {})
                .then(data => {
                    console.log(data)
                    if (data.length > 0) {

                        const messages:Array<{label: string, value: number}> = [];

                        for (let i = 0; i < data.length; i++) {
                            const name = data[i].message;
                            const id = data[i].id;
                            messages.push({ label: name, value: id });
                            
                        }

                        setMessages(messages);

                    }
                    else {
                        // NOT NEEDED
                        //me.setState(
                        //    {
                        //        tableHidden: false,
                        //        loading: false
                        //    })
                    }


                })
                .catch(function (error) {
                    // NOT NEEDED
                    //me.setState(
                    //    {
                    //        loading: false
                    //    })
                    //console.log(error);
                });
        }
    });

   
    return (
        <div style={{ width: "100%" }}>
            {messages.map((m) => {
                    
                    return <div key={m.value} >
                        <div style={{ borderLeft: "10px solid red", ...props.styling, width: "100%", paddingBottom: "15px" }} ><b>{m.label}</b></div>
                            </div>
                    })
                }
                {/*
                <Button variant="contained" onClick={() => readIt()}>
                    Read
                </Button>
                */}
            </div>
    );


}

SystemInfoBar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
};

