import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerEventsModel, buildAPIGetLoggerEventsModel } from "models/APIGetLoggerEventsModel";
// DevExtreme
import { DataGrid, Column, Export, Scrolling, FilterRow, StateStoring, Pager, Paging, HeaderFilter } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';


//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';

import { withStyles, createStyles } from '@mui/styles';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

//DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import DateBox from 'devextreme-react/date-box';
import {
    Popup
} from 'devextreme-react/popup';

const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serials: Array<string>;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    startDate: Date;
    endDate: Date;
    events: APIGetLoggerEventsModel[];
    filterValue: Array<string | string[]>;
    showKeyPopUp: boolean;
}

class EventsPanel extends React.Component<Props, State> {
    gridRef: React.RefObject<DataGrid>;

    constructor(props: Readonly<Props>) {
        super(props);
        this.gridRef = React.createRef();
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            startDate: moment().subtract(7, 'days').toDate(),
            endDate: moment().toDate(),
            events: [],
            filterValue: [],
            showKeyPopUp: false,
        };

        this.showKeyPopUp = this.showKeyPopUp.bind(this)
        this.hideKeyPopUp = this.hideKeyPopUp.bind(this)        

    }

    componentDidMount(): void {


        //get alarms for logger here
        if (this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({ loading: false, tableHidden: false });
        }
        else {
            this.setState({ loading: false, tableHidden: true });
        }

    }

    reloadData(start: Date, end: Date): boolean {
        let eventData = new Array<APIGetLoggerEventsModel>();

        //get alarms for logger here
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/LoggerEvents?serial=' + me.props.serials[0] + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"),{})
            .then(response => {
                eventData = buildAPIGetLoggerEventsModel(response);
                console.log(eventData)
                me.setState({
                    events: eventData
                });

            })
            .catch(function (ex) {
                me.setState(
                    {
                        authorized: false,
                        events: [] 
                    });
                console.log(ex);
                return false;
            });
        return true;
    }

    showKeyPopUp(): void {
        this.setState({ showKeyPopUp: true });
    }

    hideKeyPopUp(): void {
        this.setState({ showKeyPopUp: false });
    }


    startDateChanged(e: any): void {
        this.setState({
            startDate: e.value
        });

        if (!this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({ tableHidden: true });
        }
    }

    endDateChanged(e: any): void {
        this.setState({
            endDate: e.value
        });

        if (!this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({  tableHidden: true });
        }
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm");
    }


    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Events');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    render(): ReactNode {

        return (

            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                    {!this.state.tableHidden &&
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                                Start date :&nbsp;<DateBox id="startDate" displayFormat={"dd/MM/yyyy"} value={this.state.startDate} onValueChanged={this.startDateChanged.bind(this)} />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                End date :&nbsp;<DateBox id="endDate" displayFormat={"dd/MM/yyyy"} value={this.state.endDate} onValueChanged={this.endDateChanged.bind(this)} />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomTabs
                                    headerColor="info"
                                    tabs={[
                                        {
                                            tabName: "Data",
                                            tabIcon: TableChartOutlinedIcon,
                                            tabContent: (

                                                <div>
                                                    <div>
                                                        <a onClick={this.showKeyPopUp}><b>click for details <u>key</u></b></a>
                                                    </div>
                                                    <Popup
                                                        id="popupKeu"
                                                        showTitle={true}
                                                        title={"Key"}
                                                        visible={this.state.showKeyPopUp}
                                                        onHiding={this.hideKeyPopUp.bind(this)}
                                                        dragEnabled={true}
                                                        closeOnOutsideClick={false}
                                                        showCloseButton={true}
                                                        fullScreen={false}
                                                        maxWidth={400}
                                                        maxHeight={350}
                                                    >
                                                        <div>
                                                            <table style={{ padding: 15 } }>
                                                                <tr><td>MCC</td><td>&nbsp;</td><td>Mobile Country Code</td></tr>
                                                                <tr><td>MNC</td><td>&nbsp;</td><td>Mobile Network Code</td></tr>
                                                                <tr><td>ACT</td><td>&nbsp;</td><td>Access Type (NBIOT Narrow Band IOT or LTE/M)</td></tr>
                                                                <tr><td>CellId</td><td>&nbsp;</td><td>Cell Tower</td></tr>
                                                                <tr><td>TAC</td><td>&nbsp;</td><td>Tracking Area Code</td></tr>
                                                                <tr><td>Band</td><td>&nbsp;</td><td>Frequency Code</td></tr>
                                                                <tr><td>TAC</td><td>&nbsp;</td><td>Tracking Area Code</td></tr>
                                                                <tr><td>SIM ICCID</td><td>&nbsp;</td><td>Id of the SIM Card</td></tr>
                                                                <tr><td>Temp</td><td>&nbsp;</td><td>Unit temperature</td></tr>
                                                            </table>
                                                        </div>
                                                    </Popup>
                                                    {console.log(this.state.events) }
                                                    <DataGrid
                                                        dataSource={this.state.events}
                                                        keyExpr="id" 
                                                        ref={this.gridRef}
                                                        defaultFilterValue={this.state.filterValue}
                                                        allowColumnResizing={true}
                                                        columnAutoWidth={true}
                                                        columnMinWidth={80}
                                                        onExporting={this.onExporting} >
                                                        <Scrolling mode="standard" useNative={true} />
                                                        <FilterRow visible={true} />
                                                        <HeaderFilter visible={true} />
                                                        <Export enabled={true} />
                                                        <StateStoring enabled={true} type="localStorage" storageKey="eventsPanelGrid" />
                                                        <Column dataField="id" visible={false} dataType="number" />
                                                        <Column dataField="recordCode" width="100" dataType="string"/>
                                                        <Column dataField="eventCode" width="100" dataType="string" />
                                                        <Column dataField="dateCreated" width="120" customizeText={this.dateColumnCustomizeText} dataType="datetime" caption="Date created" allowHeaderFiltering={false} minWidth="100"/>
                                                        <Column dataField="energyLevel" width="100" dataType="number" />
                                                        <Column dataField="coverageClass" width="120" dataType="number" />
                                                        <Column dataField="details" dataType="string" />
                                                    </DataGrid>
                                                </div>
                                            )
                                        }
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>

                    }
                    </div>
                }

            </div>

        )
    }

}

export default withStyles(style)(EventsPanel);