/* /src/components/auth/callback.jsx */
import React from "react";
import { AuthConsumer } from "../../auth/authProvider.jsx";
import LoadingOverlay from '@ronchalant/react-loading-overlay';

/*
 * callback: This component is going to be used after login when the user is going to be redirected by the server
 * to the redirect_uri setted on the IDENTITY_CONFIG
 */

const fullScreen = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'rgba(51, 51, 51, 0.7)',
    zIndex: 10,
}

export const Callback = () => (
    <AuthConsumer>
        {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return <LoadingOverlay
                active={true}
                spinner
                text='Processing your login...'
                styles={{ wrapper: fullScreen }}
                >
                <p>loading - callback</p>
            </LoadingOverlay>
        }}
    </AuthConsumer>
);