import {
    drawerWidth,
    transition,
    container,
    primaryColor
} from "assets/jss/material-dashboard-react.js";

const appStyle = theme => ({
    wrapper: {
        position: "relative",
        top: "0",
    },
    mainPanel: {
        [theme.breakpoints.up("md")]: {
            width: `100%`
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    content: {
        marginTop: "70px",
        padding: "15px",
        minHeight: "calc(100vh - 70px)"
    },
    container,
    map: {
        marginTop: "0px",
        overflowY: "hidden"
    },
    color: {
        BG: primaryColor
    }
});

export default appStyle;
