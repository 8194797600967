import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerAlarmsModel, buildAPIGetLoggerAlarmsModel } from "models/APIGetLoggerAlarmsModel";

import { DataGrid, Column, Export, Scrolling, FilterRow, StateStoring, Pager, Paging, HeaderFilter } from 'devextreme-react/data-grid';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import { withStyles, createStyles } from '@mui/styles';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';

//DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Chart, { CommonSeriesSettings, ArgumentAxis, Series, Legend, Aggregation, ValueAxis, ZoomAndPan, ScrollBar } from 'devextreme-react/chart';
import DateBox from 'devextreme-react/date-box';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    siteId: number;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    maxAlarms: number;
    startDate: Date;
    endDate: Date;
    alarms: APIGetLoggerAlarmsModel[];
    graphData: Array<
        {
            date: Date;
            alarm0: number | null;
            alarm1: number | null;
            alarm2: number | null;
            alarm3: number | null;
            alarm4: number | null;
            alarm5: number | null;
            alarm6: number | null;
            alarm7: number | null;
            alarm8: number | null;
            alarm9: number | null;
            alarm10: number | null;
            alarm11: number | null;
            alarm12: number | null;
            alarm13: number | null;
            alarm14: number | null;
            alarm15: number | null;
        }
    > | undefined;
}

class SiteAlarmsPanel extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            maxAlarms: 0,
            startDate: moment().subtract(7, 'days').toDate(),
            endDate: moment().toDate(),
            alarms: [],
            graphData: undefined
        };
    }

    componentDidMount(): void {


        //get alarms for logger here
        if (this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({ loading: false, tableHidden: false });
        }
        else {
            this.setState({ loading: false, tableHidden: true });
        }

    }

    reloadData(start: Date, end: Date): boolean {
        let alarmData = new Array<APIGetLoggerAlarmsModel>();

        const graphData = new Array<
            {
                date: Date;
                alarm0: number | null;
                alarm1: number | null;
                alarm2: number | null;
                alarm3: number | null;
                alarm4: number | null;
                alarm5: number | null;
                alarm6: number | null;
                alarm7: number | null;
                alarm8: number | null;
                alarm9: number | null;
                alarm10: number | null;
                alarm11: number | null;
                alarm12: number | null;
                alarm13: number | null;
                alarm14: number | null;
                alarm15: number | null;
            }
            >();

        //get alarms for logger here
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/SiteAlarms?siteId=' + me.props.siteId + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"), {})
            .then(response => {
                let activeAlarms = 0;
                alarmData = buildAPIGetLoggerAlarmsModel(response);

                alarmData.forEach((v: any) => {
                    if (v.alarmState > activeAlarms) {
                        activeAlarms = v.alarmState;
                    }
                    graphData.push({
                        date: new Date(v.dateRecorded == undefined ? adjustTime(v.dateCreated) : adjustTime(v.dateRecorded)),
                        alarm0: v.alarmState & 0x01 ? 1 : null,
                        alarm1: v.alarmState & 0x02 ? 2 : null,
                        alarm2: v.alarmState & 0x04 ? 3 : null,
                        alarm3: v.alarmState & 0x08 ? 4 : null,
                        alarm4: v.alarmState & 0x10 ? 5 : null,
                        alarm5: v.alarmState & 0x20 ? 6 : null,
                        alarm6: v.alarmState & 0x40 ? 7 : null,
                        alarm7: v.alarmState & 0x80 ? 8 : null,
                        alarm8: v.alarmState & 0x100 ? 9 : null,
                        alarm9: v.alarmState & 0x200 ? 10 : null,
                        alarm10: v.alarmState & 0x400 ? 11 : null,
                        alarm11: v.alarmState & 0x800 ? 12 : null,
                        alarm12: v.alarmState & 0x1000 ? 13 : null,
                        alarm13: v.alarmState & 0x2000 ? 14 : null,
                        alarm14: v.alarmState & 0x4000 ? 15 : null,
                        alarm15: v.alarmState & 0x8000 ? 16 : null,
                    });
                    if (v.dateCleared != undefined) {
                        graphData.push({
                            date: adjustTime(new Date(v.dateCleared)),
                            alarm0: v.alarmMask & 0x01 ? 0 : null,
                            alarm1: v.alarmMask & 0x02 ? 0 : null,
                            alarm2: v.alarmMask & 0x04 ? 0 : null,
                            alarm3: v.alarmMask & 0x08 ? 0 : null,
                            alarm4: v.alarmMask & 0x10 ? 0 : null,
                            alarm5: v.alarmMask & 0x20 ? 0 : null,
                            alarm6: v.alarmMask & 0x40 ? 0 : null,
                            alarm7: v.alarmMask & 0x80 ? 0 : null,
                            alarm8: v.alarmMask & 0x100 ? 0 : null,
                            alarm9: v.alarmMask & 0x200 ? 0 : null,
                            alarm10: v.alarmMask & 0x400 ? 0 : null,
                            alarm11: v.alarmMask & 0x800 ? 0 : null,
                            alarm12: v.alarmMask & 0x1000 ? 0 : null,
                            alarm13: v.alarmMask & 0x2000 ? 0 : null,
                            alarm14: v.alarmMask & 0x4000 ? 0 : null,
                            alarm15: v.alarmMask & 0x8000 ? 0 : null,
                        });
                    }
                    else {
                        // Any current active alarms - create dummy graphdata at end of the search perios
                        graphData.push({
                            date: moment(end).add("d",1).toDate(),
                            alarm0: v.alarmMask & 0x01 ? 0 : null,
                            alarm1: v.alarmMask & 0x02 ? 0 : null,
                            alarm2: v.alarmMask & 0x04 ? 0 : null,
                            alarm3: v.alarmMask & 0x08 ? 0 : null,
                            alarm4: v.alarmMask & 0x10 ? 0 : null,
                            alarm5: v.alarmMask & 0x20 ? 0 : null,
                            alarm6: v.alarmMask & 0x40 ? 0 : null,
                            alarm7: v.alarmMask & 0x80 ? 0 : null,
                            alarm8: v.alarmMask & 0x100 ? 0 : null,
                            alarm9: v.alarmMask & 0x200 ? 0 : null,
                            alarm10: v.alarmMask & 0x400 ? 0 : null,
                            alarm11: v.alarmMask & 0x800 ? 0 : null,
                            alarm12: v.alarmMask & 0x1000 ? 0 : null,
                            alarm13: v.alarmMask & 0x2000 ? 0 : null,
                            alarm14: v.alarmMask & 0x4000 ? 0 : null,
                            alarm15: v.alarmMask & 0x8000 ? 0 : null,
                        });
                                    
                    }
                });

                graphData.sort((a, b) => a.date.getTime() - b.date.getTime());

                let maxAlarms = 0;
                while (maxAlarms < 16 && activeAlarms >= 1) {
                    activeAlarms /= 2;
                    maxAlarms++;
                }

                me.setState({
                    maxAlarms: maxAlarms,
                    alarms: alarmData,
                    graphData: graphData
                });

            })
            .catch(function (ex) {
                me.setState(
                    {
                        authorized: false,
                        alarms: [] 
                    });
                console.log(ex);
                return false;
            });
        return true;
    }



    startDateChanged(e: any): void {
        this.setState({
            startDate: e.value
        });

        if (!this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({ tableHidden: true });
        }
    }

    endDateChanged(e: any): void {
        this.setState({
            endDate: e.value
        });

        if (!this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({  tableHidden: true });
        }
    }

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Site Alarms');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm");
    }


    render(): ReactNode {

        return (

            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                    {!this.state.tableHidden &&
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                                Start date :&nbsp;<DateBox id="startDate" displayFormat={"dd/MM/yyyy"} value={this.state.startDate} onValueChanged={this.startDateChanged.bind(this)} />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                End date :&nbsp;<DateBox id="endDate" displayFormat={"dd/MM/yyyy"} value={this.state.endDate} onValueChanged={this.endDateChanged.bind(this)} />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomTabs
                                    headerColor="info"
                                    tabs={[
                                        {
                                            tabName: "Data",
                                            tabIcon: TableChartOutlinedIcon,
                                            tabContent: (
                                                <DataGrid
                                                    dataSource={this.state.alarms}
                                                    keyExpr="id" 
                                                    columnAutoWidth={true}
                                                    columnMinWidth={80}
                                                    onExporting={this.onExporting} >
                                                    <Scrolling mode="standard" useNative={true} />
                                                    <FilterRow visible={true} />
                                                    <HeaderFilter visible={true} />
                                                    <Export enabled={true} />
                                                    <StateStoring enabled={true} type="localStorage" storageKey="siteAlarmsPanelGrid" />
                                                    <Column dataField="id" visible={false} dataType="number" />
                                                    <Column dataField="alarmMask" visible={false} dataType="number"/>
                                                    <Column dataField="alarmMessage" dataType="string"/>
                                                    <Column dataField="dateCreated" customizeText={this.dateColumnCustomizeText} dataType="datetime" caption="Date Created" allowHeaderFiltering={false}/>
                                                    <Column dataField="dateRecorded" customizeText={this.dateColumnCustomizeText} dataType="datetime" caption="Date Recorded" allowHeaderFiltering={false}/>
                                                    <Column dataField="dateCleared" customizeText={this.dateColumnCustomizeText} dataType="datetime" caption="Date Cleared" allowHeaderFiltering={false}/>
                                                    <Column dataField="alarmState" dataType="number"/>
                                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                    <Paging defaultPageSize={10} />
                                                </DataGrid>
                                            )
                                        },
                                        {
                                            tabName: "Graph",
                                            tabIcon: PollOutlinedIcon,
                                            tabContent: (
                                                <Chart dataSource={this.state.graphData}>
                                                    <CommonSeriesSettings
                                                        argumentField="date"
                                                        type="steparea"
                                                        ignoreEmptyPoints={true}>
                                                        <Aggregation
                                                            enabled={true}
                                                            method="max"
                                                        />
                                                    </CommonSeriesSettings>
                                                    <ValueAxis
                                                        name="Alarms"
                                                        visualRange={{ startValue: 0, endValue: this.state.maxAlarms }}
                                                        allowDecimals={false}
                                                    />
                                                    <ArgumentAxis
                                                        aggregationInterval={{ minutes: 5 }}
                                                        allowDecimals={false}
                                                        argumentType="datetime"
                                                        minValueMargin={0.1}
                                                        maxValueMargin={0.1}
                                                        wholeRange={[this.state.startDate, this.state.endDate]}
                                                    />
                                                    <ZoomAndPan
                                                        argumentAxis="both"
                                                        valueAxis="none"
                                                    />
                                                    <ScrollBar
                                                        visible={true}
                                                    />
                                                    <Series valueField="alarm0" name="Alarm0" />
                                                    {this.state.maxAlarms > 1 && <Series valueField="alarm1" name="Alarm1" />}
                                                    {this.state.maxAlarms > 2 && <Series valueField="alarm2" name="Alarm2" />}
                                                    {this.state.maxAlarms > 3 && <Series valueField="alarm3" name="Alarm3" />}
                                                    {this.state.maxAlarms > 4 && <Series valueField="alarm4" name="Alarm4" />}
                                                    {this.state.maxAlarms > 5 && <Series valueField="alarm5" name="Alarm5" />}
                                                    {this.state.maxAlarms > 6 && <Series valueField="alarm6" name="Alarm6" />}
                                                    {this.state.maxAlarms > 7 && <Series valueField="alarm7" name="Alarm7" />}
                                                    {this.state.maxAlarms > 8 && <Series valueField="alarm8" name="Alarm8" />}
                                                    {this.state.maxAlarms > 9 && <Series valueField="alarm9" name="Alarm9" />}
                                                    {this.state.maxAlarms > 10 && <Series valueField="alarm10" name="Alarm10" />}
                                                    {this.state.maxAlarms > 11 && <Series valueField="alarm11" name="Alarm11" />}
                                                    {this.state.maxAlarms > 12 && <Series valueField="alarm12" name="Alarm12" />}
                                                    {this.state.maxAlarms > 13 && <Series valueField="alarm13" name="Alarm13" />}
                                                    {this.state.maxAlarms > 14 && <Series valueField="alarm14" name="Alarm14" />}
                                                    {this.state.maxAlarms > 15 && <Series valueField="alarm15" name="Alarm15" />}
                                                    <Legend visible={true} />
                                                </Chart>
                                            )
                                        }
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>

                    }
                    </div>
                }

            </div>

        )
    }

}

export default withStyles(style)(SiteAlarmsPanel);