import React, { useState, useEffect } from 'react';

const BatteryInfoDisplay: React.FC<{ connectionsData: any[] }> = ({ connectionsData }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a fetch operation delay
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer); // Cleanup the timeout
    }, []);


    return (
        <div>
            <h5 style={{ textAlign: "center" }}><b>Battery Voltage</b></h5>
            <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "4%" }}>
                <div style={{ width: "40%" }}>

                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                        <b>
                            {loading
                                ? 'Loading...'
                                : connectionsData.length > 0
                                    ? `${connectionsData[0].readBatteryChannel}V`
                                    : 'No data'}
                        </b>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default BatteryInfoDisplay;