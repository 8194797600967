import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import ApiFailed from '../../Utils/ApiFailed';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CreateUrl, CallGetAPI, CallPostAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import DeviceReportDialog from "components/Reports/DeviceReport";

import dxDataGrid from "devextreme/ui/data_grid" // DataGrid instance type
import { LoadPanel, DataGrid, Column, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring, HeaderFilter } from 'devextreme-react/data-grid';
import type { InitializedEvent } from 'devextreme/ui/data_grid';

import ArrayStore from 'devextreme/data/array_store';

import GroupMembers from './GroupMembers'
import Select from 'react-select'
import MuiSelect from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import makeAnimated from 'react-select/animated';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Sites from "@mui/icons-material/SettingsRemote";
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import Regions from '@mui/icons-material/LocationCity';
import DataSource from "devextreme/data/data_source";
import { dxEvent } from "devextreme/events";

import LoggerReportDialog from "components/Reports/LoggerReportDialog";

const animatedComponents = makeAnimated();

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    editModalStyle: {
        width: "90%",
        height: "600px",
    }
};


const style = createStyles(styles);

interface Group {
    id: number;
    groupId: number;
    name: string;
    parentId: Array<number>;
    path: string;
    lastUpdate: Date | null;
    level: string;
    subGroups: number;
    tags: Array<string>;
}

interface Options {
    value: number;
    label: string;
}

interface GroupOptions {
    value: number;
    label: string;
    parents: number;
    subGroups: number;
}

interface GroupMember {
    value: number;
    label: string;
    groupType: string;
    parentId: number;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
    };
    groupId: number;
    regionOptions: { value: number; label: string }[];
    dataGridInstance: dxDataGrid,
    saveInstance: (e: InitializedEvent) => void,
    history: {
        goBack: () => void;
    };
}

interface State {
    dataSource: DataSource;
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    selectedLogger: number;
    tempGroup: Group;
    edit: boolean;
    anchorEl: HTMLElement | null;
    modal: boolean;
    deleteModal: boolean;
    modalTitle: string;
    editGroup: boolean;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        group: Group;
        groupOptions: Array<GroupOptions>;
        userOptions: Array<Options>;
        siteOptions: Array<Options>;
        regionOptions: Array<Options>;
        contactOptions: Array<Options>;
    } |
    {
        groupId: number;
        loggers: Array<number>;
        loggerId: number | null;
        showBackButton: boolean;
    }
    | null;
    groupUsersOptions: Array<Options>;
    groupSitesOptions: Array<Options>;
    groupGroupsOptions: Array<GroupOptions>;
    groupRegionsOptions: Array<Options>;
    groupContactsOptions: Array<Options>;
    tempGroupUsers: Array<Options>;
    tempGroupSites: Array<Options>;
    tempGroupGroups: Array<GroupOptions>;
    tempGroupRegions: Array<Options>;
    tempGroupContacts: Array<Options>;
    tempGroupParent: number;
    orgGroupMembers: Array<GroupMember>;
    reportDialog: boolean;
    loggersInGroup: Array<LoggerDetails>;
}

interface LoggerDetails {
    id: number;
    serial: number;
}

export class Groups extends Component<Props, State> {
    store: ArrayStore;
    //dataSource: DataSource | Group[];
    gridRef: React.RefObject<DataGrid>;
    datagridInstance?: dxDataGrid;

    constructor(props: Readonly<Props>) {
        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: [],
            errorHandler: (error: Error): void => {
                console.log(error.message);
            }
        });
        this.store.clear();

        this.state = {
            dataSource : new DataSource(this.store),
            tableHidden: false,
            visible: false,
            selectedLogger: 0,
            tempGroup: {
                id: 0,
                groupId: 0,
                name: '',
                parentId: [0],
                path: '',
                lastUpdate: null,
                level: '',
                subGroups: 0,
                tags: [],
            },
            edit: false,
            anchorEl: null,
            modal: false,
            deleteModal: false,
            modalTitle : '',
            editGroup: false,
            loading: true,
            authorized: true,
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            groupUsersOptions: [],
            groupSitesOptions: [],
            groupGroupsOptions: [],
            groupRegionsOptions: [],
            groupContactsOptions: [],
            tempGroupUsers: [],
            tempGroupSites: [],
            tempGroupGroups: [],
            tempGroupRegions: [],
            tempGroupContacts: [],
            tempGroupParent: 0,
            orgGroupMembers: [],
            reportDialog: false,
            loggersInGroup: []
        };

        this.gridRef = React.createRef();

        this.handleClick = this.handleClick.bind(this);
        this.moreRender = this.moreRender.bind(this);
        this.saveInstance = this.saveInstance.bind(this);

    }

    saveInstance = (e: InitializedEvent): void => {
        this.datagridInstance = e.component;
        e.component?.beginCustomLoading('Loading...');
    }

    /*
    saveInstance = (e: { component: any; }): void => {
        this.datagridInstance = e.component;
    }
    */

    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (): void => {
        NotificationManager.error('Error creating new record', '', 5000);
    };

    createDuplicateInsertErrorNotification = (): void => {
        NotificationManager.error('Group with that name already exists', '', 5000);
    };

    createConflictNotification = (): void => {
        NotificationManager.warning('Changes conflict with another user. Refreshing groups', 'Click to retry', 5000, () => {
            this.reloadData(this.state.tempGroup.id);
        });
    };

    // Utility function to convert Array<GroupMembers> to Array<GroupOptions>
    groupMembersToGroups(members: Array<GroupMember>): Array<GroupOptions> {
        const output: Array<GroupOptions> = [];
        members.map((m: GroupMember) => {
            output.push({ value: m.value, label: m.label, parents: 0, subGroups: 0 });
        });

        return output;
    }

    // Utility function to convert Array<GroupMembers> to Array<Options>
    groupMembersToOptions(members: Array<GroupMember>): Array<Options> {
        const output: Array<Options> = [];
        members.map((m: GroupMember) => {
            output.push({ value: m.value, label: m.label });
        });

        return output;
    }

    componentDidMount(): void {

        setTimeout(() => {
            this.datagridInstance?.refresh();
        }, 1000);

        this.reloadData(null);

        this.datagridInstance?.endCustomLoading();
    }


    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {

        this.setState(
            {
                tableHidden: true,
                loading: true
            });

        const groupUsersOptions: Array<Options> = [];
        const groupSitesOptions: Array<Options> = [];
        const groupGroupsOptions: Array<GroupOptions> = [];
        const groupRegionsOptions: Array<Options> = [];
        const groupContactsOptions: Array<Options> = [];

        const me = this;
        CallGetAPI(CreateUrl('/api/aquaguard/GroupsForCompany?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {

                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent

                    const records = [];
                    for (let i = 0; i < data.length; i++) {
                        const groupId = data[i].groupId;
                        console.log(data)
                        const rec: Group = {
                            id: data[i].id,
                            groupId: groupId,
                            name: data[i].groupName,
                            parentId: data[i].generation == 0 ? [0] : [data[i].parentId],
                            path: data[i].path,
                            lastUpdate: data[i].lastUpdate,
                            level: data[i].generation == 0 ? "Main Group" : "Sub Group",
                            subGroups: data[i].subGroups,
                            tags: [],
                        };
                        // Group may already exist if multiple parents
                        if (groupGroupsOptions.some(g => g.value == groupId)) {
                            // Just add parentId to existing record and increment parents in groupGroupsOptions
                            records.filter(r => r.data.groupId == groupId)[0]?.data.parentId.push(data[i].parentId);
                            groupGroupsOptions.filter(g => g.value == groupId)[0].parents++;
                        }
                        else {
                            if (!groupGroupsOptions.some(g => g.value == groupId)) {
                                groupGroupsOptions.push({ value: groupId, label: rec.name, subGroups: data[i].subGroups, parents: rec.parentId.length });
                            }
                            records.push({ type: 'insert', data: rec, index: i });
                        }
 
                    }

                    me.store.clear();
                    me.store.push(records);


                    me.setState(
                        {
                            dataSource : new DataSource(me.store),
                            groupGroupsOptions : groupGroupsOptions,
                            tableHidden: false,
                            loading: false
                        })

                        if (editId != null) {
                            me.store.byKey(editId)
                                .then((group) => {

                                    this.setState(
                                        {
                                            tempGroup: group,
                                            editGroup: true,
                                            modal: !me.state.modal,
                                            anchorEl: null,
                                            modalTitle: "Edit Group"
                                        });
                                });
                    }
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            },
                // reject() - API error
                () => {
                    me.setState(
                        {
                            tableHidden: true,
                            loading: false
                        });
                    console.log("API Error");

                })
            .catch(function (error) {
                me.setState(
                    {
                        tableHidden: true,
                        loading: false
                    })
                console.log(error)
            });

        CallGetAPI(CreateUrl('/api/aquaguard/SitesForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
                .then(data => {
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            const siteName = data[i].siteNameUserFriendly + "(" + data[i].siteName + ")";
                            const id = data[i].siteId;
                            if (!groupSitesOptions.some(s => s.value == id)) {
                                groupSitesOptions.push({ value: id, label: siteName });
                            }
                        }
                        me.setState(
                            {
                                groupSitesOptions: groupSitesOptions,
                            })
                    }
                })
                .catch(function () {
                    me.setState(
                        {
                            loading: false
                        })
                });

        CallGetAPI(CreateUrl('/api/aquaguard/Users?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        const email = data[i].email;
                        const id = data[i].id;
                        groupUsersOptions.push({ value: id, label: email });
                    }
                    me.setState(
                        {
                            groupUsersOptions: groupUsersOptions,
                        });
                }
            })
            .catch(function () {
                me.setState(
                    {
                        loading: false
                    });
            });

        CallGetAPI(CreateUrl('/api/aquaguard/RegionsForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
                .then(data => {
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            const name = data[i].name;
                            const id = data[i].id;
                            groupRegionsOptions.push({ value: id, label: name });
                        }
                        me.setState(
                            {
                                groupRegionsOptions: groupRegionsOptions,
                            });
                    }
                })
                .catch(function () {
                    me.setState(
                        {
                            loading: false
                        });
                });


    }


    //new row
    newGroup = (): void => {
        this.setState({
            modal: !this.state.modal,
            editGroup: false,
            tempGroup: {
                id: -1,
                groupId: -1,
                name: 'New Group',
                parentId: [0],
                path: '',
                lastUpdate: null,
                level: '',
                subGroups: 0,
                tags: [],
            },
            tempGroupUsers: [],
            tempGroupSites: [],
            tempGroupGroups: [],
            tempGroupRegions: [],
            tempGroupParent: 0,
            modalTitle : "New Group"
        });
    }

    //edit row
    editGroup = (): void => {

        //fetch the selected group's members

        const me = this;
        CallGetAPI(CreateUrl('/api/aquaguard/AllMembersForGroup?groupid=' + me.state.tempGroup.groupId.toString()), {})
            .then(data => {
                const groupMembers: Array<GroupMember> = [];

                if (data.length > 0) {

                    // Copy the member records into correct array

                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].memberId;
                        console.log(data);
                        switch (data[i].groupType) {
                            case 'COMPANY':
                                // Ignore COMPANY members
                                break;

                            case 'GROUP': {
                                const group = me.state.groupGroupsOptions.filter(g => g.value == id);
                                if (group.length > 0) {
                                    groupMembers.push({ value: id, label: group[0].label, groupType: "GROUP", parentId: data[i].groupId });
                                }
                                break;
                            }
                            case 'USER': {
                                const user = me.state.groupUsersOptions.filter(g => g.value == id);
                                if (user.length > 0) {
                                    groupMembers.push({ value: id, label: user[0].label, groupType: "USER", parentId: data[i].groupId });
                                }
                                break;
                            }
                            case 'SITE': {
                                const site = me.state.groupSitesOptions.filter(g => g.value == id);
                                if (site.length > 0) {
                                    groupMembers.push({ value: id, label: site[0].label, groupType: "SITE", parentId: data[i].groupId });
                                }
                                break;
                            }
                            case 'REGION': {
                                const region = me.state.groupRegionsOptions.filter(g => g.value == id);
                                if (region.length > 0) {
                                    groupMembers.push({ value: id, label: region[0].label, groupType: "REGION", parentId: data[i].groupId });
                                }
                                break;
                            }

                        }



                    }

                }

                let tempGroupParent = 0;
                if (me.state.tempGroup.level != "Main Group") {
                    tempGroupParent = me.state.groupGroupsOptions.filter(g => g.value == me.state.tempGroup.parentId[0])[0].value;
                }

                me.setState({
                    editGroup: true,
                    modal: !me.state.modal,
                    anchorEl: null,
                    modalTitle : "Edit Group",
                    orgGroupMembers: groupMembers,      // Includes ALL members of hierarchy - only DIRECT children editable
                    tempGroupGroups: this.groupMembersToGroups(groupMembers.filter(g => g.groupType == "GROUP" && g.parentId == me.state.tempGroup.groupId)),
                    tempGroupUsers: this.groupMembersToOptions(groupMembers.filter(g => g.groupType == "USER" && g.parentId == me.state.tempGroup.groupId)),
                    tempGroupSites: this.groupMembersToOptions(groupMembers.filter(g => g.groupType == "SITE" && g.parentId == me.state.tempGroup.groupId)),
                    tempGroupRegions: this.groupMembersToOptions(groupMembers.filter(g => g.groupType == "REGION" && g.parentId == me.state.tempGroup.groupId)),
                    tempGroupParent: tempGroupParent
                });
            })
            .catch(function (error) {
                console.log(error)
            });
               
    }

    //save changes, for both edit and new
    saveGroup = (): void => {
        
        const me = this;

        if (this.state.editGroup === false) { //adding a new group
            const tempGroup = this.state.tempGroup;

            this.setState({
                tempGroup: tempGroup,
                modal: !this.state.modal,
            });

            //send group to API
           
           
            //save group
            //let parentId = 0;
            //if (me.state.tempGroupParent != 0) {
                 //parentId = me.state.groupGroupsOptions.filter(g => g.value == me.state.tempGroupParent)[0].value;
            //}

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json-patch+json' },
                //mode: 'no-cors',
                body: JSON.stringify({
                    parentId: me.state.tempGroupParent,
                    companyId: sessionStorage.getItem('companyId'),
                    name: tempGroup.name,
                    active: true
                })
            };
                     
            CallGetAPI(CreateUrl('/api/aquaguard/Group'), requestOptions)
                .then(data => {
                    const group = me.state.tempGroup;
                    group.id = data.id
                    group.lastUpdate = data.lastUpdate;
                    me.store.push([{ type: 'insert', key: group.id, data: group }]);
                    me.setState({
                        tempGroup: group,
                    });

                    // Add or remove group members
                    const tempGroupMembers: Array<GroupMember> = [];
                    me.state.tempGroupGroups.map(g => tempGroupMembers.push({ value: g.value, label: g.label, groupType: "GROUP", parentId: g.parents[0] }));
                    me.state.tempGroupUsers.map(u => tempGroupMembers.push({ value: u.value, label: u.label, groupType: "USER", parentId: 0 }));
                    me.state.tempGroupSites.map(s => tempGroupMembers.push({ value: s.value, label: s.label, groupType: "SITE", parentId: 0 }));
                    me.state.tempGroupRegions.map(r => tempGroupMembers.push({ value: r.value, label: r.label, groupType: "REGION", parentId: 0 }));

                    if (tempGroupMembers.length > 0) {
                        const updatePromises: Array<Promise<any>> = [];
                        const addedmembers = [];
                        tempGroupMembers.map((grp) => {
                            // Need to add member
                            const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json-patch+json' },
                                //mode: 'no-cors',
                                body: JSON.stringify({ groupId: group.id, memberId: grp.value, groupType: grp.groupType })
                            };
                            updatePromises.push(
                                CallPostAPI(CreateUrl('/api/aquaguard/AddGroupMember'), requestOptions)
                                    .then(() => {
                                        addedmembers.push({ memberId: grp.value, groupType: grp.groupType });
                                    })
                                    .catch(function (ex) {
                                        console.log(ex);
                                    })
                            );
                        });

                        Promise.all(updatePromises).then(() => {
                            me.createSuccessNotification();
                            //UI update
                            me.reloadData(null);

                        })
                            .catch(function (error) {
                                me.createFailedInsertErrorNotification();
                                console.log(error);
                            });
                    }
                    else {
                        // No members to add
                        me.createSuccessNotification();
                        //UI update
                        me.reloadData(null);
                    }

                },
                // reject() - insert failed
                    (data) => {
                        if (data.status == 409) {
                            me.createDuplicateInsertErrorNotification();
                        }
                        else {
                            me.createFailedInsertErrorNotification();
                        }
                })
            .catch(function (error) {
                    me.createFailedInsertErrorNotification()
                    console.log(error)
                });
        }
        else {//modifying an existing group
           
            const tempGroup = this.state.tempGroup;

            this.store.push([{ type: 'update', key: tempGroup.id, data: tempGroup }]);

            this.setState({
                modal: !this.state.modal,
            });

            //********************
            //send model to API
          
            const me = this;

            //save group
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
                body: JSON.stringify({
                    id: tempGroup.groupId,
                    name: tempGroup.name,
                    path: tempGroup.path,
                    active: true,
                    lastUpdate: tempGroup.lastUpdate,
                })
            };

            CallPutAPI(CreateUrl('/api/Aquaguard/Group/' + tempGroup.groupId), requestOptions)
                .then(async response => {
                    console.log(response);
                    if (response.status == 409) {
                        // Conflicting edit detected
                        me.createConflictNotification();
                        console.log("Edit conflict");
                        me.reloadData(tempGroup.id);
                    }
                    else {
                        if (response.status == 200) {
                            // Add or remove group members
                            const tempGroupMembers: Array<GroupMember> = [];
                            me.state.tempGroupGroups.map(g => tempGroupMembers.push({ value: g.value, label: g.label, groupType: "GROUP", parentId: g.parents[0] }));
                            me.state.tempGroupUsers.map(u => tempGroupMembers.push({ value: u.value, label: u.label, groupType: "USER", parentId: 0 }));
                            me.state.tempGroupSites.map(s => tempGroupMembers.push({ value: s.value, label: s.label, groupType: "SITE", parentId: 0 }));
                            me.state.tempGroupRegions.map(r => tempGroupMembers.push({ value: r.value, label: r.label, groupType: "REGION", parentId: 0 }));

                            const updatePromises: Array<Promise<any>> = [];
                            const removedMembers = [];
                            const addedMembers = [];
                            me.state.orgGroupMembers.map((grp) => {
                                if (!tempGroupMembers.some(g => g.value == grp.value && g.groupType == grp.groupType)) {
                                    // Need to remove member
                                    const requestOptions = {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json' },
                                        //mode: 'no-cors',
                                        body: JSON.stringify({ groupId: tempGroup.groupId, memberId: grp.value, groupType: grp.groupType, company: sessionStorage.getItem('companyId') })
                                    };
                                    updatePromises.push(
                                        CallPostAPI(CreateUrl('/api/aquaguard/RemoveGroupMember'), requestOptions)
                                            .then(() => {
                                                removedMembers.push({ memberId: grp.value, groupType: grp.groupType });
                                            })
                                            .catch(function (ex) {
                                                console.log(ex);
                                            })
                                    );
                                }
                            });
                            tempGroupMembers.map((grp) => {
                                if (!me.state.orgGroupMembers.some(g => g.value == grp.value && g.groupType == grp.groupType)) {
                                    // Need to add member
                                    const requestOptions = {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json' },
                                        //mode: 'no-cors',
                                        body: JSON.stringify({ groupId: tempGroup.groupId, memberId: grp.value, groupType: grp.groupType, company: sessionStorage.getItem('companyId') })
                                    };
                                    updatePromises.push(
                                        CallPostAPI(CreateUrl('/api/aquaguard/AddGroupMember'), requestOptions)
                                            .then(() => {
                                                addedMembers.push({ memberId: grp.value, groupType: grp.groupType });
                                            })
                                            .catch(function (ex) {
                                                console.log(ex);
                                            })
                                    );
                                }
                            });

                            Promise.all(updatePromises).then(() => {
                                me.createSuccessNotification();
                                //UI update
                                me.reloadData(null);

                            })
                            .catch(function (error) {
                                me.createFailedInsertErrorNotification();
                                console.log(error);
                            });
                        }
                        else
                            me.createErrorNotification();
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification()
                    console.log(error)
                });
        }

    }


    //do nothing, close the modal
    cancel = (): void => {
        this.toggleModal();
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }
    //show edit buttons
    toggleEdit = (): void => {
        this.setState({
            edit: !this.state.edit
        });
    }

    toggleDeleteModal = (): void => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            anchorEl: null,
            modalTitle: "Warning"
        });

    }

    deleteRow = (): void => {

        const me = this;

        this.setState({
            deleteModal: !this.state.deleteModal,
        });

        //delete from database
        CallDeleteAPI(CreateUrl('/api/aquaguard/Group?id=' + this.state.tempGroup.groupId), {})
            .then(async response => {
                if (response.status == 200) {
                    me.createSuccessNotification();
                    //remove the old version of the row
                    this.store.push([{ type: 'remove', key: me.state.tempGroup.id }]);
                }
                else {
                    me.createErrorNotification();
                }
                console.log(response);
            })
            .catch(function (error) {
                me.createErrorNotification();
                console.log(error);
            });

    }

    //field changed events---
    groupNameChanged = (event: any): void => {
        const tempGrp = this.state.tempGroup;
        tempGrp.name = event.target.value;
        this.setState( { tempGroup: tempGrp }); 
    }
    groupTagsChanged = (event: any): void => {
        const tempGrp = this.state.tempGroup;
        tempGrp.tags = event.target.value;
        this.setState( { tempGroup: tempGrp }); 
    }
    //-------------------------


    handleUsersInputChange = (event: any): void => {
        let mulitSelectContents = event
        if(mulitSelectContents === null){
            mulitSelectContents = [];
        }
        this.setState({
            tempGroupUsers: mulitSelectContents
        });
        
        //save the added item
    };

    handleSitesInputChange = (event: any): void => {
        let mulitSelectContents = event
        if(mulitSelectContents === null){
            mulitSelectContents = [];
        }
        this.setState({
            tempGroupSites: mulitSelectContents
        });
        //save the added item
    };

    handleRegionsInputChange = (event: any): void => {
        let mulitSelectContents = event;
        if (mulitSelectContents === null) {
            mulitSelectContents = [];
        }
        this.setState({
            tempGroupRegions: mulitSelectContents
        });
        //save the added item
    };

    handleGroupsInputChange = (event: any): void => {
        let mulitSelectContents = event
        if(mulitSelectContents === null){
            mulitSelectContents = [];
        }
        this.setState({
            tempGroupGroups: mulitSelectContents
        });

        //save the added item
    };

    handleParentInputChange = (event: any): void => {
        this.setState({
            tempGroupParent: event.target.value,
        })
    };

    handleClick(event: React.MouseEvent<HTMLElement>, id: number): void {
        //Prevent click re-drawing table
//        event.preventDefault();
//        event.stopPropagation();
        this.store.byKey(id)
            .then((group) => {

                this.setState(
                    {
                        tempGroup: group,
                        anchorEl: event.currentTarget
                    });
            },
            (error) => {
                console.log(error);
            });
    }

    moreRender(group: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, group.data.id); }}><MoreHorizIcon /></IconButton>
        );
    }

    groupMembers(key: any): React.ReactNode {
        //const site = this.state.rows.find((row) => row.id === key.data.id);
       
        //var group = this.state.rows.find((row) => row.id === id);
        return (
            <GroupMembers data={[{label: 'Group1', type: 'group'}, {label: '44404', type: 'device'}]} />
        );
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    groupDetailsClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/groupDetail',
                redirectProps: {
                    group: this.state.tempGroup,
                    groupOptions: this.state.groupGroupsOptions,
                    userOptions: this.state.groupUsersOptions,
                    siteOptions: this.state.groupSitesOptions,
                    regionOptions: this.state.groupRegionsOptions,
                    contactOptions: this.state.groupContactsOptions,
                }
            });
    }

    graphLoggersClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/multiLoggerGraph',
                redirectProps: {
                    groupId: this.state.tempGroup.groupId,
                    loggers: [],
                    loggerId: null,
                    showBackButton: true,
                }
            });
    }

    toggleReportDialog = (): void => {
        CallGetAPI(CreateUrl(`/api/aquaguard/GetLoggers?companyid=${sessionStorage.getItem('companyId')}&filterGroup=${this.state.tempGroup.groupId}`), {})
            .then(data => {
                const loggers = data.map(item => { return { id: item.id, serial: item.serial } })
                if (loggers.length > 0) {
                    this.setState({
                        loggersInGroup: loggers,
                        reportDialog: !this.state.reportDialog,
                        anchorEl: null
                    })
                } else {
                    alert("Group does not contain any loggers, please add some loggers to this group")
                }
            })
    }


    /// Replace Group ID's with name
    groupsRender(cellInfo: any): React.ReactNode {
        const names: string[] = [];
        if (cellInfo.value == null)
            return "";
        else
            cellInfo.value.map((id: number) => {
                if (id == 0 || this.state.groupGroupsOptions.filter(g => g.value == id).length == 0) {
                    names.push("NONE");
                }
                else {
                    names.push(this.state.groupGroupsOptions.filter(g => g.value == id)[0].label);
                }
            });

        return (<p><pre>{names.join('\n')}</pre></p>);
    }


    render(): React.ReactNode {
        const { classes } = this.props;
        const currentGroup = this.state.tempGroup; 

/*        const handleChange = (event) => {
            this.setState({
                Region: event.target.value
            });
        };
*/
        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                (!this.state.redirect) ?
                (
                <div>
                    <Modal
                        show={this.state.deleteModal}
                        onHide={(): void => this.toggleDeleteModal()}
                        dialogClassName="modal-50w"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                                >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            Delete Group {this.state.tempGroup.name}?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.deleteRow()}>Continue</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.toggleDeleteModal()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modal}
                        onHide={(): void => this.toggleModal()}
                        dialogClassName="modal-50w"
                        contentClassName={styles.editModalStyle}
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                                >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                                    <Grid container spacing={2}>

                                            <Grid item xs={12} >
                                                <TextField
                                                id="outlined-input-name"
                                                label="Name"
                                                defaultValue={this.state.tempGroup.name}
                                                variant="outlined"
                                                onChange={this.groupNameChanged}
                                                style={{width: "100%"} }
                                                />
                                            </Grid>
                                        <Grid item xs={12}>
                                            {this.state.editGroup ?
                                                <TextField
                                                    id="outlined-input-parent"
                                                    label="Parent Group"
                                                    variant="outlined"
                                                    disabled={this.state.editGroup}
                                                    defaultValue={this.state.tempGroupParent == 0 ? "NONE(Top level)" :
                                                        this.state.groupGroupsOptions.filter((g) => g.value == this.state.tempGroupParent)[0].label}
                                                    style={{ width: "100%" }}
                                                />
                                                :
                                                <FormControl variant="outlined"  >
                                                    <InputLabel id="parent-select-helper-label">Parent Group</InputLabel>
                                                    <MuiSelect
                                                        label="Parent Group"
                                                        labelId="parent-select-helper-label"
                                                        value={this.state.tempGroupParent}
                                                        onChange={this.handleParentInputChange.bind(this)}

                                                        displayEmpty={true}
                                                        style={{width: "100%"} }
                                                    >
                                                        <MenuItem value="0">NONE(Top level)</MenuItem>
                                                        {this.state.groupGroupsOptions.filter((g) => g.value != currentGroup.id)
                                                            .map((grp) => <MenuItem value={grp.value} key={grp.value}>{grp.label}</MenuItem>)
                                                        }
                                                    </MuiSelect>
                                                </FormControl>
                                            }
                                            </Grid>
                                            <Grid item md={12} >
                                            {sessionStorage.getItem('userLevel') == 'useradmin' ?
                                                <CustomTabs
                                                    headerColor={'success'}
                                                    tabs={[
                                                        {
                                                            tabName: "Users",
                                                            tabIcon: PeopleOutlineIcon,
                                                            tabContent: (
                                                                <div style={{ padding: 10 }}>
                                                                    <Select
                                                                        isMulti
                                                                        components={animatedComponents}
                                                                        options={this.state.groupUsersOptions}
                                                                        defaultValue={this.state.tempGroupUsers}
                                                                        onChange={this.handleUsersInputChange}
                                                                    />
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            tabName: "Sites",
                                                            tabIcon: Sites,
                                                            tabContent: (
                                                                <div style={{ padding: 10 }}>
                                                                    <Select
                                                                        isMulti
                                                                        components={animatedComponents}
                                                                        options={this.state.groupSitesOptions}
                                                                        defaultValue={this.state.tempGroupSites}
                                                                        onChange={this.handleSitesInputChange}
                                                                    />
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            tabName: "Regions",
                                                            tabIcon: Regions,
                                                            tabContent: (
                                                                <div style={{ padding: 10 }}>
                                                                    <Select
                                                                        isMulti
                                                                        components={animatedComponents}
                                                                        options={this.state.groupRegionsOptions}
                                                                        defaultValue={this.state.tempGroupRegions}
                                                                        onChange={this.handleRegionsInputChange}
                                                                    />
                                                                </div>
                                                            )
                                                        }, {
                                                            tabName: "Groups",
                                                            tabIcon: GroupWorkIcon,
                                                            tabContent: (
                                                                <div style={{ padding: 10 }}>
                                                                    <Select
                                                                        isMulti
                                                                        components={animatedComponents}
                                                                        options={this.state.groupGroupsOptions.filter((g) => {
                                                                            //const isParent = ("\\" + currentGroup.path + "\\").indexOf("\\" + g.label + "\\") >= 0;
                                                                            //return g.value != currentGroup.id && g.value != this.state.tempGroupParent && !currentGroup.parentId.includes(g.value) && (g.subGroups == 0 || g.parents < 2);
                                                                            return g.value != currentGroup.groupId && g.value != this.state.tempGroupParent && !currentGroup.parentId.includes(g.value) && (g.subGroups == 0 || g.parents < 2);

                                                                        })}
                                                                        defaultValue={this.state.tempGroupGroups}
                                                                        onChange={this.handleGroupsInputChange}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    ]}
                                                />
                                                :
                                                <CustomTabs
                                                    headerColor={'success'}
                                                    tabs={[
                                                        {
                                                            tabName: "Sites",
                                                            tabIcon: Sites,
                                                            tabContent: (
                                                                <div style={{ padding: 10 }}>
                                                                    <Select
                                                                        isMulti
                                                                        components={animatedComponents}
                                                                        options={this.state.groupSitesOptions}
                                                                        defaultValue={this.state.tempGroupSites}
                                                                        onChange={this.handleSitesInputChange}
                                                                    />
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            tabName: "Regions",
                                                            tabIcon: Regions,
                                                            tabContent: (
                                                                <div style={{ padding: 10 }}>
                                                                    <Select
                                                                        isMulti
                                                                        components={animatedComponents}
                                                                        options={this.state.groupRegionsOptions}
                                                                        defaultValue={this.state.tempGroupRegions}
                                                                        onChange={this.handleRegionsInputChange}
                                                                    />
                                                                </div>
                                                            )
                                                        }, {
                                                            tabName: "Groups",
                                                            tabIcon: GroupWorkIcon,
                                                            tabContent: (
                                                                <div style={{ padding: 10 }}>
                                                                    <Select
                                                                        isMulti
                                                                        components={animatedComponents}
                                                                        options={this.state.groupGroupsOptions.filter((g) => {
                                                                            //const isParent = ("\\" + currentGroup.path + "\\").indexOf("\\" + g.label + "\\") >= 0;
                                                                            return g.value != currentGroup.groupId && g.value != this.state.tempGroupParent && !currentGroup.parentId.includes(g.value) && (g.subGroups == 0 || g.parents < 2);
                                                                            //return g.value != currentGroup.id && (g.subGroups == 0 || g.parents < 2);
                                                                        })}
                                                                        defaultValue={this.state.tempGroupGroups}
                                                                        onChange={this.handleGroupsInputChange}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    ]}
                                                />
                                            }
                                            </Grid>
                            </Grid>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" variant="contained" style={{ marginRight: "10px" }}  onClick={(): void => this.saveGroup()}>Save</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.cancel()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>


                            <DeviceReportDialog
                                show={this.state.reportDialog}
                                title="Generate Report for Loggers on Sites in Group"
                                loggerIds={this.state.loggersInGroup.map(item => item.id)}
                                onCancel={this.toggleReportDialog.bind(this)}
                                deviceData={this.state.loggersInGroup}
                                fromGroup={true}
                                templateClicked={false}

                            />
                            {/*<LoggerReportDialog*/}
                            {/*    show={this.state.reportDialog}*/}
                            {/*    title="Generate Report for Loggers on Sites in Group"*/}
                            {/*    groupId={this.state.tempGroup.groupId}*/}
                            {/*    onSubmit={this.toggleReportDialog.bind(this)}*/}
                            {/*    onCancel={this.toggleReportDialog.bind(this)}*/}
                            {/*/>*/}

                    {//this.state.loading &&
                        <div hidden={!this.state.loading} style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    }
                            {console.log(this.state.loggersInGroup) }
                    {//!this.state.loading &&
                                <div hidden={this.state.loading}>
                                    {this.state.tableHidden &&
                                            <ApiFailed />
                                   }
                            {//!this.state.tableHidden &&
                                <div hidden={this.state.tableHidden}>
                                <GridContainer>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                                 <div style={{ display: 'flex', justifyContent: 'center' }}>  
                                                    <IconButton
                                                        style={{ display: 'inline' }}
                                                        onClick={(): void => this.props.history.goBack()}
                                                        size="large"><ArrowBackIcon style={{ fill: "white" }} />
                                                    </IconButton>
                                                    <h4 style={{ display: 'inline', paddingTop: 12 }} className={classes.cardTitleWhite}>Groups</h4>
                                                </div>
                                                <IconButton aria-label="add" onClick={(): void => this.newGroup()} size="large">
                                                    <AddCircleOutlineIcon style={{ fill: "white" }} />
                                                </IconButton>
                                            </CardHeader>
                                            <CardBody>
                                            
                                            <DataGrid
                                                onInitialized={this.saveInstance}
                                                //repaintChangesOnly={true}
                                                dataSource={this.state.dataSource}
                                                ref={this.gridRef}
                                                >
                                                <LoadPanel enabled={true} />
                                                <StateStoring enabled={true} type="localStorage" storageKey="groupsListGrid" />
                                                <GroupPanel visible={false} />
                                                <Grouping autoExpandAll={true} />
                                                <FilterRow visible={true} />
                                                <HeaderFilter visible={true} />

                                                    <Column dataField="level" visible={true} dataType="string" groupIndex={0} />
                                                    <Column dataField="name" visible={true} dataType="string" width={250}/>
                                                    <Column dataField="parentId" visible={true} caption="Parent Group(s)" dataType='string' cellRender={this.groupsRender.bind(this)} />
                                                    <Column dataField="subGroups" visible={true} dataType='number' width={100}/>
                                               
                                                {/* <Column caption="Members" width={200} fixed={true} fixedPosition='right'  type="buttons" cellRender={this.groupMembers.bind(this)} key='more'/> */}
                                                    <Column  fixed={true} fixedPosition='right'  type="buttons" cellRender={this.moreRender.bind(this)} key='more' />
                                                                                              
                                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                <Paging defaultPageSize={10} />
                                            </DataGrid>
                                               
                                            </CardBody>
                                        </Card>
                                    </GridItem>

                                </GridContainer>
                                </div>
                            }
                     </div>
                    }

                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                                onClose={this.handleClose}
                                disableScrollLock={true}
                    >
                        <MenuItem onClick={this.editGroup}>Edit</MenuItem>
                        <MenuItem onClick={this.groupDetailsClick}>Details</MenuItem>
                        <MenuItem onClick={this.graphLoggersClick.bind(this)}>Graph Loggers</MenuItem>
                        <MenuItem onClick={this.toggleReportDialog.bind(this)}>Export Logger Report</MenuItem>
                        <MenuItem onClick={this.toggleDeleteModal}>Delete</MenuItem>
                    </Menu>

                    <NotificationContainer />

                </div>
                )
                :
                (<Redirect push to={{
                    pathname: this.state.redirectPath,
                    state: this.state.redirectProps
                }}/>)
           )
            :
            (
                <Redirect to={'/Login'} />
            );
    }
}

export default withStyles(styles)(Groups);

