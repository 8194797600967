import React from "react";
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import 'react-notifications/lib/notifications.css';
import Select from 'react-select'
import SiteDeviceChips from './SiteDeviceChips'
import makeAnimated from 'react-select/animated';
import { withStyles, createStyles } from '@mui/styles';
import { FormControlLabel, Switch, FormControl, FormLabel, RadioGroup, Radio } from "@mui/material";
import DateBox from 'devextreme-react/date-box';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';


const animatedComponents = makeAnimated();

const styles = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

export class SiteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locEntry: "WGS84",
            fromDate: new Date()
        };
    }

    handleLocEntryChange(event) {
        this.setState({ locEntry: event.target.value });
    }

    fromDateChanged(event) {
        this.setState({ fromDate: event.value });
        this.props.fromDateChanged(event.value);
    }

    doNothing(label, value) {
        const x = value;
    }

    renderLocEntry() {
        if (this.state.locEntry == "OSGB") {
            return <Grid item xs={6} md={4}>
                <div style={{ padding: 10 }}>
                    <TextField
                        id="outlined-input-lat"
                        label="OS Grid Ref"
                        defaultValue={this.props.gridRef}
                        onChange={this.props.sitegridRefChanged}
                        variant="outlined"
                        error={this.props.gridRefError}
                        helperText={this.props.gridRefError ? "Grid ref must be 2 letters and up to 6 - 10 digits" : ""}
                    />
                </div>
            </Grid>;
        }
        else {
            return <Grid item xs={6} md={6}>
                <div style={{display: "flex"} }>
                        <div style={{ padding: 10 }}>
                            <TextField
                                id="outlined-input-lat"
                                label="Latitude"
                                defaultValue={this.props.lat}
                                onChange={this.props.siteLatChanged}
                                variant="outlined"
                                error={this.props.latError}
                                helperText={this.props.latError ? "Latitude must be numeric, between -90 and 90" : ""}
                            />
                        </div>
                        <div style={{ padding: 10 }}>
                            <TextField
                                id="outlined-input-long"
                                label="Longitude"
                                defaultValue={this.props.lon}
                                onChange={this.props.siteLonChanged}
                                variant="outlined"
                                error={this.props.longError}
                                helperText={this.props.longError ? "Longitude must be numeric, between -180 and 180" : ""}
                            />
                    </div>
                </div>
                    </Grid>;
        }
    }

    render() {
//        const { classes } = this.props;

        return (
            <Modal
                show={this.props.show}
                onHide={() => this.props.close()}
                dialogClassName="modal-50w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                        <Grid container spacing={2}>
                            <Grid item xs={6} >
                                <div style={{ padding: 10 }}>
                                    <TextField
                                        id="outlined-input-name"
                                        label="Name"
                                        defaultValue={this.props.siteName}
                                        onChange={this.props.siteNameChanged}
                                        variant="outlined"
                                        error={this.props.siteError}
                                        helperText={this.props.siteError ? this.props.siteErrorMessage : ""}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6} >
                                <div style={{ padding: 10 }}>
                                    <TextField
                                        id="outlined-input-name"
                                        label="Reference"
                                        defaultValue={this.props.siteRef}
                                        onChange={this.props.siteRefChanged}
                                        variant="outlined"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6} >
                                <div style={{ padding: 10 }}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Enter location as</FormLabel>
                                    <RadioGroup aria-label="locEntry" name="locEntry" value={this.state.locEntry} onChange={this.handleLocEntryChange.bind(this)} style={{ width: "100%" }}>
                                        <div style={{display: "flex"} }>
                                            <FormControlLabel value="OSGB" control={<Radio color="secondary" />} label="OS Grid" />
                                            <FormControlLabel value="WGS84" control={<Radio color="secondary" />} label="Lat & Long" />
                                        </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </Grid>

                            {this.renderLocEntry()}
            {/*
                            <Grid item md={12} >
                                <div style={{ padding: 10 }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                onChange={this.props.handleFilterChange}
                                                color="primary"
                                            />}
                                        label="Include all devices (not just unallocated)"
                                    />
                                </div>
                            </Grid>
                            <Grid item md={12} >
                                <div style={{ padding: 10 }}>
                                    Devices
                                    <Select
                        isMulti
                        components={animatedComponents}
                        options={this.props.devices}
                        defaultValue={this.props.defaultDevices}
                        onChange={this.props.handleInputChange}
                                    />
                                </div>
                            </Grid>
            */}
                        <Grid item md={12} >
                            <p style={{ fontSize: "16px", fontWeight: 400, marginLeft: "10px" }}>Devices:</p>
                            <SiteDeviceChips data={this.props.defaultDevices} deviceDetailsClick={this.doNothing} />
                        </Grid>
            {/*
                        <Grid item md={12} >
                            Enter effective date for device changes (default = Now)
                            <div style={{ padding: 10 }}>
                                Assign from:&nbsp;<DateBox id="fromDate" displayFormat={"dd/MM/yyyy"} value={this.state.fromDate} onValueChanged={this.fromDateChanged.bind(this)} />
                            </div>
                        </Grid>
            */}
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={() => this.props.save()} disabled={this.props.siteError || this.props.latError || this.props.longError }>Save</Button>
                    <Button color="secondary" variant="contained" onClick={() => this.props.close()}>Cancel</Button>
                </Modal.Footer>
            </Modal>
           
        );
    
    }
}
export default withStyles(style)(SiteModal);