//export const identityUrl = "https://zonelogidentity.net"; 
export const identityUrl = "https://ashridgeidentityserver.azurewebsites.net"; 
//export const identityUrl = "https://localhost:5001";


//!!!!! Remember to alter this value in the top level .env file 
/*

NOTE - REMEMBER TO SET .env file REACT_APP_AUTH_URL value
TO MATCH identityUrl.js


*/