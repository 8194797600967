import * as React from 'react';
import { Component, ChangeEvent } from "react";
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal'
import Dialog from '@mui/material/Dialog';
import { Container, Row, Col } from 'reactstrap';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClipLoader from "react-spinners/ClipLoader";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Select from '@mui/material/Select'
import makeAnimated from 'react-select/animated';

import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring, HeaderFilter } from 'devextreme-react/data-grid';
import { CreateUrl, CallGetAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";
import { AppBar, DialogContent, FormControl, Grid, InputLabel, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

const animatedComponents = makeAnimated();

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    dropZone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
    table: {
        minWidth: 250,
    },
};

const style = createStyles(styles);

interface Contact {
    id: number;
    fullname: string;
    userGuid: string | null;
    mobile: string;
    email: string;
    companyId: number;
    lastUpdate: Date | null;
    created: Date | null;
    contactCategories: Array<ContactCategory>;
}

interface ContactCategory {
    id: number;
    contactId: number;
    notificationCategoryId: number;
    notifyEmail: boolean;
    notifySms: boolean;
    groupId: number;
}

interface NotificationCategories {
    id: number;
    description: string;
    isPriority: boolean;
}

interface Group {
    id: number;
    groupId: number;
    name: string;
    parentId: Array<number>;
    path: string;
    lastUpdate: Date | null;
    level: string;
    subGroups: number;
    tags: Array<string>;
}

interface GroupOptions {
    value: number;
    label: string;
    parents: number;
    subGroups: number;
}


interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
        dropZone: string;
        table: string;
    };
    history: {
        goBack: () => void;
    };
    ContactOptions: { value: number; label: string }[];
    onChangeNumber: (newNumber: string) => void;
}


interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    selectedLogger: number;
    modal: boolean;
    colour: string;
    edit: boolean;
    editContact: boolean;
    tempContact: Contact;
    anchorEl: HTMLElement | null;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        contact: Contact;
        notificationCategories: Array<NotificationCategories>;
        groupFilterName: string;
    } | null;
    mapModal: boolean;
    modalTitle: string;
    tempKMLFileName: string;
    deleteModal: boolean;
    notificationCategories: Array<NotificationCategories>;
    groupGroupsOptions: Array<GroupOptions>;
    groupId: number;
    emailError: string;
    mobileError: string;
    number: string;
    nextPage: boolean;
    
}

export class Contacts extends Component<Props, State> {
    store: ArrayStore; //devextreme dataset store
    gridRef: React.RefObject<DataGrid>;

    constructor(props: Readonly<Props>) {
        super(props);

        //init the devextreme store
        this.store = new ArrayStore({
            key: 'id',
            data: [],
            errorHandler: (error: Error): void => {
                console.log(error.message);
            }
        });

        this.state = {
            tableHidden: true,
            visible: false,
            selectedLogger: 0,
            modal: false,
            colour: "",
            edit: false,
            editContact: false,
            tempContact: {
                id: -1,
                fullname: 'New Contact',
                userGuid: null,
                mobile: '',
                email: '',
                companyId: parseInt(sessionStorage.getItem('companyId') || '0'),
                lastUpdate: null,
                created: null,
                contactCategories: [],
            },
            anchorEl: null,
            loading: true,
            authorized: true,
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            mapModal: false,
            modalTitle: "",
            tempKMLFileName: 'No file selected',
            deleteModal: false,
            notificationCategories: [],
            groupGroupsOptions: [],
            groupId: -1,
            emailError: "",
            number: '',
            nextPage: false
            
        };

        this.gridRef = React.createRef();
        this.store.clear();
        
    }


    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (): void => {
        NotificationManager.error('Error creating new record', '', 5000);
    };

    createConflictNotification = (): void => {
        NotificationManager.warning('Changes conflict with another user. Refreshing Contacts', 'Click to retry', 5000, () => {
            this.reloadData(this.state.tempContact ? this.state.tempContact.id : null);
        });
    };


    //called the first time a component is loaded like pageLoad
    componentDidMount(): void {

        this.reloadData(null);
    }

    //get all the records for this pages grid
    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    // used in error cases
    reloadData = (editId: number | null): void => {

        const me = this;

        //fetch Contacts
        CallGetAPI(CreateUrl('/api/aquaguard/ContactsForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {

                //clear the datastore
                me.store.clear();
                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent

                    const records = [];
                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].id;
                        const rec: Contact = {
                            id: id,
                            fullname: data[i].fullname,
                            userGuid: data[i].userGuid,
                            mobile: data[i].mobile,
                            email: data[i].email,
                            companyId: data[i].companyId,
                            lastUpdate: data[i].lastUpdate,
                            created: data[i].created,
                            contactCategories: data[i].contactCategories,
                        };

                        records.push({ type: 'insert', data: rec, index: i });
                    }

                    me.store.push(records); //command above can be insert, update or delete

                    if (editId != null) { //indicates failed save

                        //get the record with this id from the store, which will have changed
                        me.store.byKey(editId)
                            .then((Contact) => {

                                this.setState(
                                    {
                                        tempContact: Contact,
                                        editContact: true,
                                        modal: !me.state.modal,
                                        anchorEl: null,
                                        modalTitle: "Edit Contact"
                                    });
                            });
                    }
                    else {

                        //need to redisplay the grid
                        me.setState(
                            {
                                tableHidden: false,
                                loading: false
                            })
                    }
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function (error) {
                me.setState(
                    {
                        loading: false
                    })
                console.log(error)
            });

        CallGetAPI(CreateUrl('/api/aquaguard/NotificationCategories'), {})
            .then(data => {
                
                if (window.location.hostname.includes('zonelog.net')) {
                    data = data.slice(1)
                }
                console.log(data)
                me.setState(
                    {
                        notificationCategories: data,
                    })


            })
            .catch(function (error) {
                me.setState(
                    {
                        loading: false
                    });
                console.log(error);
            });

        //grab all the company groups
        const groupGroupsOptions: Array<GroupOptions> = [];

        CallGetAPI(CreateUrl('/api/aquaguard/GroupsForCompany?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {

                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent

                    for (let i = 0; i < data.length; i++) {
                        const groupId = data[i].groupId;

                        const rec: Group = {
                            id: data[i].id,
                            groupId: groupId,
                            name: data[i].groupName,
                            parentId: data[i].generation == 0 ? [0] : [data[i].parentId],
                            path: data[i].path,
                            lastUpdate: data[i].lastUpdate,
                            level: data[i].generation == 0 ? "Main Group" : "Sub Group",
                            subGroups: data[i].subGroups,
                            tags: [],
                        };
                        // Group may already exist if multiple parents
                        if (groupGroupsOptions.some(g => g.value == groupId)) {
                            // Just add parentId to existing record and increment parents in groupGroupsOptions
                            groupGroupsOptions.filter(g => g.value == groupId)[0].parents++;
                        }
                        else {
                            if (!groupGroupsOptions.some(g => g.value == groupId)) {
                                groupGroupsOptions.push({ value: groupId, label: rec.name, subGroups: data[i].subGroups, parents: rec.parentId.length });
                            }
                        }
                    }

                    me.setState(
                        {
                            groupGroupsOptions: groupGroupsOptions,
                        })
                }
            },
                // reject() - API error
                () => {
                    console.log("API Error");

                })
            .catch(function (error) {
                console.log(error)
            });
    }



    //new row, when you click the plus button - this gets called
    //vreate the data model that sits behind the modal form
    //stored in the state as tempContact

    newContact = (): void => {

        const tmpContact: Contact = {
            id: -1,
            fullname: 'New Contact',
            userGuid: null,
            mobile: '',
            email: '',
            companyId: parseInt(sessionStorage.getItem('companyId') || '0'),
            lastUpdate: null,
            created: null,
            contactCategories: [],
        };

        //foreach
        this.state.notificationCategories.map((cat) => {
            const contactCat = {
                id: -1,
                contactId: -1,
                notificationCategoryId: cat.id,
                notifyEmail: false,
                notifySms: false,
                groupId: this.state.groupId,
            };
            tmpContact.contactCategories.push(contactCat);
        });


        this.setState({
            modal: !this.state.modal,
            editContact: false,
            tempContact: tmpContact,
            modalTitle: "New Contact"
        });



    }

    //edit row, tempCOntact already initialised in handleClick by clikcing the 3 dots
    editContact = (): void => {

        const tmpContact = this.state.tempContact;
        let groupId = -1;

        if (tmpContact.contactCategories.length > 0) { groupId = tmpContact.contactCategories[0].groupId } // all group ids will be the sAme

        // Rarely - Add any new Notification categories, new Notification cats may be in the DB that are not yet associated with this contact as a Contact Category
        this.state.notificationCategories.map((ncat) => {
            if (tmpContact.contactCategories.filter((cat) => { //filter the array based on the lamda
                cat.notificationCategoryId == ncat.id
            }).length == 0) { //no mathcing entry then add it
                const contactCat: ContactCategory = {
                    id: -1,
                    contactId: tmpContact.id,
                    notificationCategoryId: ncat.id,
                    notifyEmail: false,
                    notifySms: false,
                    groupId: groupId,
                };
                tmpContact.contactCategories.push(contactCat);
            }
        });

        //normally this all we do in edit
        this.setState({
            editContact: true,
            modal: !this.state.modal,
            modalTitle: "Edit Contact",
            groupId: groupId == null ? -1 : groupId,
            anchorEl: null, //closes the menu
        });

    }


    //save changes, for both edit and new
    saveContact = (): void => {

        //have to create a local copy of this, this is destroyed by an async call
        const me = this;

        this.setState({
            modal: !this.state.modal,
            loading: true,
            nextPage: false
        });


        if (this.state.editContact === false) { //adding a new Contact
            const tmpContact = this.state.tempContact;
            console.log()
            //********************
            //send model to API
            //save group
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json-patch+json' },
                //mode: 'no-cors',
                body: JSON.stringify(tmpContact)
            };

            CallGetAPI(CreateUrl('/api/aquaguard/Contact'), requestOptions)
                .then(data => {
                    console.log(data);
                    const contact = me.state.tempContact;
                    contact.id = data.id;
                    contact.lastUpdate = data.lastUpdate;

                    me.store.push([{ type: 'insert', key: contact?.id, data: contact }]);
                    me.setState({
                        tempContact: contact,
                    });
                    me.createSuccessNotification();
                    //UI update
                    //me.reloadData(data.id);
                    me.setState({
                        loading: false,
                    });

                })
                .catch(function (error) {
                    me.createFailedInsertErrorNotification();
                    console.log(error);
                    me.setState({
                        loading: false,
                    });
                });



        }
        else {//modifying an existing Contact

            const contact = this.state.tempContact;

            //********************
            //send model to API
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json-patch+json' },
                //mode: 'no-cors',
                body: JSON.stringify(contact),
            };


            CallGetAPI(CreateUrl('/api/aquaguard/Contact?id=' + contact.id), requestOptions)
                .then(async response => {
                    console.log(response);

                    //someone else had modififed in the meantime
                    if (response.status == 409) {
                        // Conflicting edit detected
                        me.createConflictNotification();
                        console.log("Edit conflict");

                        //reload from the new data
                        me.reloadData(contact.id);
                        me.setState({
                            loading: false,
                        });
                    }
                    else {
                        if (response.status == undefined) {  //successful update, response is the updated contact record

                            contact.lastUpdate = response.lastUpdate;

                            me.store.push([{ type: 'update', key: contact.id, data: contact }]);
                            me.setState({
                                tempContact: contact,

                            });
                            me.createSuccessNotification();
                            //UI update
                            //                            me.reloadData(null);
                            me.setState({
                                loading: false,
                            });
                        }
                        else {
                            me.createErrorNotification();
                            me.setState({
                                loading: false,
                            });
                        }
                    }
                })
                .catch(function (error) {
                    me.createFailedInsertErrorNotification();
                    console.log(error);
                    me.setState({
                        loading: false,
                    });
                });
        }

    }

    //this renders the 3 dots icon with the handle click function
    moreRender(Contact: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, Contact.data.id) }}><MoreHorizIcon /></IconButton>
        );
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    //when u click the 3 dots, you 
    handleClick(event: React.MouseEvent<HTMLElement>, id: number): void {
        const me = this;
        this.store.byKey(id)
            .then((contact: Contact) => {
                console.log(contact)
                console.log(contact)
                me.setState(
                    {
                        tempContact: contact,
                        anchorEl: event.currentTarget
                    });
            },
                (error) => {
                    console.log(error);
                });

    }

    //do nothing, close the modal
    cancel = (): void => {
        this.setState({
            modal: !this.state.modal,
            nextPage: false
        });
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal,
            nextPage: false
        });
    }
    //show edit buttons
    toggleEdit = (): void => {
        this.setState({
            edit: !this.state.edit
        });
    }

    //field changed events---
    ContactNameChanged = (event: any): void => {
        const tmpContact = this.state.tempContact;
        tmpContact.fullname = event.target.value;
        this.setState({ tempContact: tmpContact });
    }

    ContactMobileChanged = (number: string): void => {
        console.log(number)
        const tmpContact = this.state.tempContact;
        tmpContact.mobile = number
        this.setState({ tempContact: tmpContact, mobileError: "" });
    }


    ContactEmailChanged = (event: any): void => {
        const tmpContact = this.state.tempContact;
        const testEmail = event.target.value
        if (!this.validateEmail(testEmail)) {
            this.setState({ emailError: "Please enter a valid email address" });
        } else {
            tmpContact.email = testEmail
            this.setState({ tempContact: tmpContact, emailError: "" });    
        }
        
    }

    handleNotificationChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        console.log(event.target.value + "=" + checked);
        const parts = event.target.value.split('_');
        const ncid = parseInt(parts[1]);
        const contact = this.state.tempContact;
        contact.contactCategories.map(cat => {
            if (cat.notificationCategoryId == ncid) {
                if (parts[0] == "bySms") {
                    cat.notifySms = checked;
                }
                else if (parts[0] == "byEmail") {
                    cat.notifyEmail = checked;
                }
            }
        });

        this.setState({ tempContact: contact });
    }

    handleGroupChanged = (event: any): void => {

        console.log(event.target.value);
        const tmpContact = this.state.tempContact;

        tmpContact.contactCategories.map(cat => {
            cat.groupId = event.target.value;
        });

        if (!isNaN(parseInt(event.target.value))) {
            this.setState({
                groupId: parseInt(event.target.value),
                tempContact: tmpContact
            });
        }
    }


    ContactDetailsClick = (): void => {

        const tmpContact = this.state.tempContact;
        let groupId = -1;

        if (tmpContact.contactCategories.length > 0) { groupId = tmpContact.contactCategories[0].groupId } // all group ids will be the sAme

        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/ContactDetail',
                redirectProps: {
                    contact: this.state.tempContact,
                    notificationCategories: this.state.notificationCategories,
                    groupFilterName: (groupId == -1 || groupId == null) ? "None" : this.state.groupGroupsOptions.filter(g => g.value == groupId)[0].label
                }
            });
    }



    toggleDeleteModal = (): void => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            anchorEl: null,
            modalTitle: "Warning"
        });

    };

    deleteRow = (): void => {

        const me = this;

        this.setState({
            deleteModal: !this.state.deleteModal,
        });

        //delete from database
        CallDeleteAPI(CreateUrl('/api/aquaguard/Contact?id=' + this.state.tempContact.id), {})
            .then(async response => {
                if (response.status == 200) {
                    me.createSuccessNotification();
                    //remove the old version of the row
                    this.store.push([{ type: 'remove', key: me.state.tempContact.id }]);
                }
                else {
                    me.createErrorNotification();
                }
                console.log(response);
            })
            .catch(function (error) {
                me.createErrorNotification();
                console.log(error);
            });

    }

    validateEmail(email:string): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    


    


    //-------------------------


    render(): React.ReactNode {
        const { classes } = this.props;

        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
        (
            (!this.state.redirect) ?
            (
        <div>

        {/* Edit Contact modal  */}
            <Dialog
                PaperProps={{
                    sx: {
                        width: "50%",
                        minHeight: 400
                    }
                }}
                open={this.state.modal}
                onClose={(): void => this.toggleModal()}
                            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        
                        <Typography sx={{flex: 1 }} variant="h6" component="div">
                            {this.state.modalTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Modal.Body className="show-grid">
                                    <Grid container spacing={10}>
                                        {!this.state.nextPage && <Grid item xs={12}>
                                            <TextField
                                                id="outlined-input"
                                                label="Name"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                defaultValue={this.state.tempContact?.fullname}
                                                onChange={this.ContactNameChanged}
                                                error={this.state.tempContact?.fullname.length == 0}
                                                helperText={this.state.tempContact?.fullname.length == 0 ? "Name is required" : ""}
                                            />
                                            <PhoneInput
                                                country={'gb'}
                                                value={this.state.tempContact?.mobile}
                                                onChange={(num: string): void => this.ContactMobileChanged(num)}
                                                inputStyle={{ width: "100%", height: "inherit" }}
                                            />


                                            <TextField
                                                id="outlined-input"
                                                label="Email"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                defaultValue={this.state.tempContact?.email}
                                                onChange={this.ContactEmailChanged}
                                                error={!!this.state.emailError}
                                                helperText={this.state.emailError}
                                            />

                                            <div style={{ paddingTop: 10 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="grpFilterSelect">Filter Sites by Group?</InputLabel>
                                                    <Select id="grpFilterSelect"

                                                        value={this.state.groupId.toString()}
                                                        onChange={this.handleGroupChanged}
                                                        variant="outlined"
                                                    >
                                                        <MenuItem value="-1">No filter</MenuItem>
                                                        {this.state.groupGroupsOptions.map((g) => {
                                                            return <MenuItem value={g.value} key={g.value}> {g.label} </MenuItem>
                                                        })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>

                                        </Grid>}

                                        {this.state.nextPage && <Grid item xs={12}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Category</TableCell>
                                                        {!window.location.hostname.includes('zonelog.net') && <TableCell align="center">By SMS</TableCell>}
                                                        <TableCell align="center">By Email</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.notificationCategories.map((cat) => (
                                                        <TableRow key={cat.id}>
                                                            <TableCell component="th" scope="row">
                                                                {cat.description}
                                                            </TableCell>
                                                            {!window.location.hostname.includes('zonelog.net') && <TableCell align="center">
                                                                <Checkbox
                                                                    checked={this.state.tempContact.contactCategories.filter(c => c.notificationCategoryId == cat.id)[0]?.notifySms}
                                                                    onChange={this.handleNotificationChanged}
                                                                    inputProps={{ 'aria-label': 'primary checkbox', value: 'bySms_' + cat.id }}
                                                                />
                                                            </TableCell>}
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={this.state.tempContact.contactCategories.filter(c => c.notificationCategoryId == cat.id)[0]?.notifyEmail}
                                                                    onChange={this.handleNotificationChanged}
                                                                    inputProps={{ 'aria-label': 'primary checkbox', value: 'byEmail_' + cat.id }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>}
                        </Grid>
                </Modal.Body>

                                <Modal.Footer>
                                    
                                    <Button color="primary" style={{marginRight: "10px"}} variant="contained" onClick={this.state.nextPage ? (): void => this.saveContact() : (): void => this.setState({ nextPage: true })}>{this.state.nextPage ? "Save": "Continue"}</Button>
                                        <Button color="secondary" variant="contained" onClick={(): void => this.toggleModal()}>Cancel</Button>
                                    
                                </Modal.Footer>
            </Dialog>

           {/* Delete Contact modal  */}

            <Modal
                show={this.state.deleteModal}
                onHide={(): void => this.toggleDeleteModal()}
                dialogClassName="modal-50w"
                aria-labelledby="example-custom-modal-styling-title"
                style={{marginTop: "30px"} }
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.state.modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    Delete Contact {this.state.tempContact?.fullname}?
                </Modal.Body>
                <Modal.Footer>
                    <Button color="primary" variant="contained" style={{marginRight: "10px"}} onClick={(): void => this.deleteRow()}>Continue</Button>
                    <Button color="secondary" variant="contained" onClick={(): void => this.toggleDeleteModal()}>Cancel</Button>
                </Modal.Footer>
            </Modal>

        {this.state.loading && 
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
        }
        {!this.state.loading &&
            <div>

            {this.state.tableHidden &&

            <ApiFailed />
            }

            {!this.state.tableHidden &&
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                                                <Card>
                                                    
                        <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <IconButton
                                        style={{ display: 'inline' }}
                                        onClick={(): void => this.props.history.goBack()}
                                        size="large"><ArrowBackIcon style={{ fill: "white" }} />
                                    </IconButton>
                                    <h4 style={{ display: 'inline', paddingTop: 12 }} className={classes.cardTitleWhite}>Contacts</h4>
                                </div>
                                <IconButton aria-label="add" onClick={(): void => this.newContact()} size="large">
                                    <AddCircleOutlineIcon  style={{fill: "white"}}/>
                                </IconButton>
                            </CardHeader>
                            <CardBody>
                               
                                
                                <DataGrid
                                
                                repaintChangesOnly={true}
                                dataSource={new DataSource({ store: this.store })}
                                ref={this.gridRef} >
                                <StateStoring enabled={true} type="localStorage" storageKey="ContactListGrid" />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />

                                <Column dataField="fullname" visible={true} dataType="string" sortOrder="asc"/>
                                <Column dataField="mobile" visible={true} dataType="string" />
                                <Column dataField="email" visible={true} dataType="string" />
                                
                                <Column fixed={true} fixedPosition='right'  type="buttons" cellRender={this.moreRender.bind(this)} />
                                
                                
                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                <Paging defaultPageSize={10} />
                                </DataGrid>
                                                        
                               
                             
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                  }

                  </div>
              
            }
                    {
                        //Menu is the 3 dots pop up on the rhs of grid
                        //anchorEl tells it where to pop the menu up
                    }
                    <Menu
                    
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                                disableScrollLock={true}
                    >
                                {(sessionStorage.getItem('userLevel') === 'useradmin' || sessionStorage.getItem('userLevel') === 'identityadmin') && <MenuItem onClick={this.editContact}>Edit</MenuItem>}
                                <MenuItem onClick={this.ContactDetailsClick}>Details</MenuItem>
                                {(sessionStorage.getItem('userLevel') === 'useradmin' || sessionStorage.getItem('userLevel') === 'identityadmin') && <MenuItem onClick={this.toggleDeleteModal}>Delete</MenuItem>}
                    </Menu>

                    <NotificationContainer />

        </div>
            )
            :
            (<Redirect push to={{
                pathname: this.state.redirectPath,
                state: this.state.redirectProps
            }}/>)
        )
        :
        (
            <Redirect to={'/Login'} />
        );
    }
}


export default withStyles(style)(Contacts);

