import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AuthProvider } from "./auth/authProvider.jsx";
import AuthRoutes from "./auth/authRoutes.jsx";
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
if (window.location.hostname.includes('zonelog.net')) {
    import('./assets/css/material-dashboard-reactZL.css?v=1.8.0');
} else {
    import('./assets/css/material-dashboard-react.css?v=1.8.0');
}

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';


const theme = createTheme({
    palette: {
        primary: {
            /*main: '#EED202', */
            main: window.location.hostname.includes('zonelog.net') ? '#ffc222' : '#EED202'
        },
        secondary: {
            /* main: '#00BF00' */
            main: window.location.hostname.includes('zonelog.net') ? '#00a18e' :'#00BF00'

        }
        
    },
    components: {
        MuiButton: {
            styleOverrides: {
                primary: {
                    backgroundColor: '#B5FF80',
                },
                secondary: {
                    borderColor: '#143C67',
                    color: '#ffffff',
                },
            },
        },

    },

    
    
});

config({ licenseKey }); 

//import LandingPage from "./views/LandingPage/LandingPage.js";
const hist = createBrowserHistory();


const App = () => { 
    return (

            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <Router history={createBrowserHistory()} basename={"/"}>
                        <AuthRoutes />
                    </Router>
                </AuthProvider>
            </ThemeProvider>
    );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
