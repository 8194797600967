import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import { APIImageUpload, buildAPIImageUpload } from "models/APIImageUpload";
import MaterialButton from '@mui/material/Button';
//import { NotificationContainer, NotificationManager } from 'react-notifications';


//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';

import { withStyles, createStyles } from '@mui/styles';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// https://www.npmjs.com/package/pure-react-carousel
//import { CarouselProvider, Slider, Slide, ImageWithZoom, ButtonBack, ButtonNext, ButtonFirst, ButtonLast, DotGroup } from 'pure-react-carousel';
//import 'pure-react-carousel/dist/react-carousel.es.css';

//https://www.npmjs.com/package/react-zoom-pan-pinch
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

//https://js.devexpress.com/Documentation/Guide/UI_Components/Gallery/Overview/
//https://js.devexpress.com/Demos/WidgetsGallery/Demo/Gallery/ItemTemplate/React/Light/
import { Gallery } from 'devextreme-react/gallery';
import TileGallery from"./TileGallery"

const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    imageStyle: {
        width: "400px",
        height: "400px"
    }
};

const style = createStyles(styles);
let useAzure = false;

interface Props {
    classes: {
        cardTitleWhite: string;
        imageStyle: string;
    };
    siteId?: number | undefined;
    loggerId?: number | undefined;
    chariotId?: number | undefined;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    images: APIImageUpload[];
    numImages: number;
    imageURLs: string[];
}

class ImagesPanel extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            numImages: 0,
            images: [],
            imageURLs: []
        };
    }

    /*
    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            //alert('callback');
        });
    };
    */

    componentDidMount(): void {
        this.reloadData = this.reloadData.bind(this);
        this.reloadData();
    }

    removeImage = (img: APIImageUpload): void => {
        if (img.Id.toString() == "00000000-0000-0000-0000-000000000000") {
            useAzure = true;
        }
        if (useAzure) {
            //delete from Azure
            CallDeleteAPI(CreateUrl('/api/aquaguard/images?id=' + img.Filename), {})
                .then(async response => {
                    if (response.status == 200) {
                        alert('image deleted');
                        //img.Me.createSuccessNotification();
                        this.reloadData();
                    }
                    else {
                        //img.Me.createErrorNotification();
                    }
                    console.log(response);
                })
                .catch(function (error) {
                    //img.Me.createErrorNotification();
                    console.log(error);
                });
        } else {
            //delete from database
            CallDeleteAPI(CreateUrl('/api/aquaguard/images?id=' + img.Id), {})
                .then(async response => {
                    if (response.status == 200) {
                        alert('image deleted');
                        //img.Me.createSuccessNotification();
                        this.reloadData();
                    }
                    else {
                        //img.Me.createErrorNotification();
                    }
                    console.log(response);
                })
                .catch(function (error) {
                    //img.Me.createErrorNotification();
                    console.log(error);
                });
        }

    }

    reloadData(): void {
        const imageData = new Array<APIImageUpload>();
        const imageURLData = new Array<string>();

        //get images for site/logger here
        const me = this;
        
        CallGetAPI(CreateUrl('/api/aquaguard/Images?siteId=' + (this.props.siteId ? this.props.siteId : 0)
                + "&loggerId=" + (this.props.loggerId ? this.props.loggerId : 0)
                + "&chariotId=" + (this.props.chariotId ? (this.props.chariotId > 500000 ? this.props.chariotId - 500000 : this.props.chariotId) : 0)
        ), {})
            .then(response => {

                let imageCount = 0;
                for (let i = 0; i < response.length; i++) {
                    if (response[0].id.toString() == "00000000-0000-0000-0000-000000000000") {
                        useAzure = true;
                    }
                    if (useAzure==true) {
                        imageData.push(buildAPIImageUpload(response[i], me));
                        imageURLData.push(response[i].filename);
                        imageCount++;
                    }
                    else
                    {
                        imageData.push(buildAPIImageUpload(response[i], me));
                        imageURLData.push(CreateUrl('/api/aquaguard/Images/' + response[i].id.toString()));
                        imageCount++;
                   }
                }

                me.setState({
                    loading: false,
                    tableHidden: false,
                    numImages: imageCount,
                    images: imageData,
                    imageURLs: imageURLData
                });

            })
            .catch(function (ex) {
                me.setState(
                    {
                        loading: false,
                        tableHidden: true,
                        authorized: false,
                        numImages: 0,
                        images: [],
                        imageURLs: []
                    });
                console.log(ex);
            });
    }

    //https://js.devexpress.com/Demos/WidgetsGallery/Demo/Gallery/ItemTemplate/React/Light/
    renderItem(img: APIImageUpload): ReactNode {
        let caption = [<div key="-1"></div>];
        if (img.Caption) {
            caption = img.Caption.split('\n').map((str, j) => <div key={j}>{str}</div>)
        }
        if (img.Id.toString() == "00000000-0000-0000-0000-000000000000") {
            useAzure = true;
        }
    return (
        <div style={{ textAlign: 'center', margin: '0px auto 0px auto', border: '1px, solid' }}>

            <h6>{caption}</h6>
            {
                (sessionStorage.getItem("userLevel") == "useradmin" || sessionStorage.getItem("userLevel") == "identityadmin") &&
                <MaterialButton color="secondary" onClick={(): void => img.Me.removeImage(img)}>remove</MaterialButton>
            }
            <hr />
            {useAzure && img.ContentType.includes('pdf') &&
                <p>click <a href={img.Filename} target="_blank" rel="noopener noreferrer"><PictureAsPdfIcon /> here</a> to download</p>
            }
            {useAzure && !img.ContentType.includes('pdf') &&
                <TransformWrapper
                    initialScale={1}
                    initialPositionX={5}
                    initialPositionY={5}
                >
                    <TransformComponent>
                        <img src={img.Filename} style={{ width: '100%', height: '100%' }} />
                    </TransformComponent>
                </TransformWrapper>
            }
            {!useAzure && img.ContentType.includes('pdf') &&
                <p>click <a href={CreateUrl('/api/aquaguard/Images/' + img.Id)} target="_blank" rel="noopener noreferrer"><PictureAsPdfIcon /> here</a> to download</p>
            }
            {!useAzure && !img.ContentType.includes('pdf') &&
                <TransformWrapper
                    initialScale={1}
                    initialPositionX={5}
                    initialPositionY={5}
                >
                    <TransformComponent>
                        <img src={CreateUrl('/api/aquaguard/Images/' + img.Id.toString())} style={{ width: '100%', height: '100%' }} />
                    </TransformComponent>
                </TransformWrapper>
            }
        </div>
    );
    }
 
    //https://js.devexpress.com/Demos/WidgetsGallery/Demo/Gallery/ItemTemplate/React/Light/
    renderGallery(): ReactNode {
        return (
            <TileGallery uploads={this.state.images} removeImage={this.removeImage} loggerId={this.props.loggerId} reloadData={this.reloadData} />
        )}

    /*
    renderCarousel(): ReactNode {
        return <CarouselProvider
            visibleSlides={1}
            totalSlides={this.state.numImages}
            step={1}
            dragStep={1}
            naturalSlideWidth={400}
            naturalSlideHeight={400}
        >
            <Slider style={{height: 500} }>
            {
                this.state.images.map((img, i) => {
                    let caption = [<div key="-1"></div>];
                    if (img.Caption) {
                        caption = img.Caption.split('\n').map((str, j) => <div key={j}>{str}</div>)
                    }

                    if (img.ContentType == "application/pdf") {
                        return <Slide index={i} key={i}>
                            <a href={CreateUrl('/api/aquaguard/Images/' + img.Id)} target="_blank" rel="noopener noreferrer">
                                <PictureAsPdfIcon />
                            </a>
                            <div style={{width: 400} }>{caption}</div>
                        </Slide>
                    }
                    else {
                        return <Slide index={i} key={i} style={{height: 400} } >
                            <ImageWithZoom src={CreateUrl('/api/aquaguard/Images/' + img.Id)} className={this.props.classes.imageStyle} overlayClassName={this.props.classes.imageStyle} imageClassName={this.props.classes.imageStyle} />
                            <div style={{ width: 400 }}>{caption}</div>
                        </Slide>
                    }
                })
            }
            </Slider>

            <ButtonFirst>First</ButtonFirst>
            <ButtonBack>Back</ButtonBack>
            <ButtonNext>Next</ButtonNext>
            <ButtonLast>Last</ButtonLast>
            <DotGroup dotNumbers />
        </CarouselProvider>
    }
    */

    render(): ReactNode {

        const tabs = [];
        for (let x = 0; x < this.state.numImages; x++) {
            let caption = [<div key="-1"></div>];
            if (this.state.images[x].Caption) {
                caption = this.state.images[x].Caption.split('\n').map((str, i) => <div key={i}>{str}</div>)
            }
            if (this.state.images[x].Id.toString() == "00000000-0000-0000-0000-000000000000") {
                useAzure = true;
            }
            if (useAzure) {
                if (this.state.images[x].ContentType == "application/pdf") {
                    tabs.push({
                        tabName: this.state.images[x].Filename,
                        tabIcon: TableChartOutlinedIcon,
                        tabContent: (
                            <div key={x}>
                                <a href={this.state.images[x].Filename} target="_blank" rel="noopener noreferrer">
                                    <PictureAsPdfIcon />
                                </a>
                                <div>{caption}</div>
                            </div>
                        )
                    }
                    );
                }
                else {
                        tabs.push({
                            tabName: this.state.images[x].Filename,
                            tabIcon: TableChartOutlinedIcon,
                            tabContent: (
                                <div key={x}>
                                    <img src={this.state.images[x].Filename} width={400} />
                                    <div>{caption}</div>
                                </div>
                            )
                        }
                        );
                }
            } else {
                if (this.state.images[x].ContentType == "application/pdf") {
                    tabs.push({
                        tabName: this.state.images[x].Filename,
                        tabIcon: TableChartOutlinedIcon,
                        tabContent: (
                            <div key={x}>
                                <a href={CreateUrl('/api/aquaguard/Images/' + this.state.images[x].Id)} target="_blank" rel="noopener noreferrer">
                                    <PictureAsPdfIcon />
                                </a>
                                <div>{caption}</div>
                            </div>
                        )
                    }
                    );
                }
                else {
                         tabs.push({
                        tabName: this.state.images[x].Filename,
                        tabIcon: TableChartOutlinedIcon,
                        tabContent: (
                            <div key={x}>
                                <img src={CreateUrl('/api/aquaguard/Images/' + this.state.images[x].Id)} width={400} />
                                <div>{caption}</div>
                            </div>
                        )
                    }
                    );
                }
            }
            
        }

        return (

            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                    {!this.state.tableHidden && this.renderGallery()
                    }
                    </div>
                }

            </div>

        )
    }

}

export default withStyles(style)(ImagesPanel);