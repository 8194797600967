import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerReadingsModel, buildAPIGetLoggerReadingsModel } from "models/APIGetLoggerReadingsModel";
import { APIGetLoggerAlarmLevelModel, buildAPIGetLoggerAlarmLevelModel } from "models/APIGetLoggerAlarmLevelModel";

//Moment date/time formatting
//https://momentjs.com/docs/
import moment, { Duration } from 'moment';

import { withStyles, createStyles } from '@mui/styles';

//DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Chart, { CommonSeriesSettings, ArgumentAxis, Series, Legend, ValueAxis, ZoomAndPan, ScrollBar, Tooltip, TickInterval, Aggregation, Point, Size, Title, Crosshair, Label, Strip } from 'devextreme-react/chart';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ReadingTooltip from './ReadingTooltip';
import { locale } from "devextreme/localization";


const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

// Data to display on graph 
interface Readings {
    date: Date;
    channel: string;
    reading: number | null;
    readingMax: number | null;
    readingMin: number | null;
    readingSet: number | null;
    readingClr: number | null;

}


interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serial: string;
    startDate: Date;
    endDate: Date;
    channel: string;   // Channel to display ('C', 'D' or 'E')
    isprimary: string;
    title: string;
    ref: React.RefObject<unknown>;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    startDate: Date;
    endDate: Date;
    maxValue: number;
    minValue: number;
    readingsData: Array<APIGetLoggerReadingsModel>;
    graphData: Array<Readings> | undefined;
    channel: string;   // Channel to display ('C', 'D' or 'E')
}

class StripedGraphSWG extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            maxValue: 0,
            minValue: Number.MAX_VALUE,
            startDate: props.startDate,
            endDate: props.endDate,
            readingsData: [],
            graphData: undefined,
            channel: "",
        };
        // Set DevExtreme Locale (for date formats)
        locale("en-GB");

    }

    componentDidMount(): void {

        console.log("ReadingsGraphSWG DidMount - " + this.props.channel);
        //get alarms for logger here
        if (this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({ loading: false, tableHidden: false });
        }
        else {
            this.setState({ loading: false, tableHidden: true });
        }

    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.startDate != this.props.startDate
            || prevProps.endDate != this.props.endDate
            || prevProps.channel != this.props.channel
        ) {
            console.log("ReadingsGraphSWG DidUpdate - " + this.props.channel);
            this.setState({
                startDate: this.props.startDate,
                endDate: this.props.endDate,
                channel: this.props.channel,
            });
            this.reloadData(this.props.startDate, this.props.endDate);
        }

    }

    reloadData(start: Date, end: Date): boolean {

        this.setState({ loading: true });

        let readingsData = new Array<APIGetLoggerReadingsModel>();
        const graphData = new Array<Readings>();

        let maxValue = 0;
        let minValue = Number.MAX_VALUE;

        let alarmLevels = new Array<APIGetLoggerAlarmLevelModel>();

        //get alarms for logger here
        const me = this;

        const promises = new Array<Promise<any>>();
        // generate array of Promises each adding the readingsData for a logger
        promises.push(
            CallGetAPI(CreateUrl('/api/aquaguard/GetLogReadings?companyid=0&logger=' + this.props.serial + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59" + "&channel=" + this.props.channel), {})
                .then(json => {
                    readingsData = readingsData.concat(buildAPIGetLoggerReadingsModel(json));
                })
                .catch(function (ex) {
                    me.setState(
                        {
                            tableHidden: true,
                            readingsData: [],
                            loading: false,
                        });
                    console.log(ex);
                })
        );
        promises.push(
            CallGetAPI(CreateUrl('/api/aquaguard/LoggerAlarmLevels?logger=' + this.props.serial + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"), {})
                .then(json => {
                    alarmLevels = buildAPIGetLoggerAlarmLevelModel(json);
                })
                .catch(function (ex) {
                    me.setState(
                        {
                            tableHidden: true,
                            loading: false,
                        });
                    console.log(ex);
                })
        );

        Promise.all(promises).then(() => {

            readingsData.forEach((v: APIGetLoggerReadingsModel) => {
                if (v.dateStamp != null) {

                    switch (this.props.channel) {
                        case "C":
                            if (v.type == 'ANALOGUE C') {
                                graphData.push({
                                    date: new Date(v.dateStamp),
                                    channel: "Reading",
                                    reading: (v.value != -32768) ? v.value : null,
                                    readingMax: (v.maxValue != -32768) ? v.maxValue : null,
                                    readingMin: (v.minValue != -32768) ? v.minValue : null,
                                    readingSet: null,
                                    readingClr: null,
                                });
                                if (v.value != -32768) {
                                    if (v.value != null && v.value > maxValue) {
                                        maxValue = v.value;
                                    }
                                    if (v.value != null && v.value < minValue) {
                                        minValue = v.value;
                                    }
                                }
                            }
                            break;

                        case "D":
                            if (v.type == 'ANALOGUE D') {
                                graphData.push({
                                    date: new Date(v.dateStamp),
                                    channel: "Reading",
                                    reading: (v.value != -32768) ? v.value : null,
                                    readingMax: (v.maxValue != -32768) ? v.maxValue : null,
                                    readingMin: (v.minValue != -32768) ? v.minValue : null,
                                    readingSet: null,
                                    readingClr: null,
                                });
                                if (v.value != -32768) {
                                    if (v.value != null && v.value > maxValue) {
                                        maxValue = v.value;
                                    }
                                    if (v.value != null && v.value < minValue) {
                                        minValue = v.value;
                                    }
                                }
                            }
                            break;

                        case "E":
                            if (v.type == 'ANALOGUE E') {
                                graphData.push({
                                    date: new Date(v.dateStamp),
                                    channel: "Reading",
                                    reading: (v.value != -32768) ? v.value : null,
                                    readingMax: (v.maxValue != -32768) ? v.maxValue : null,
                                    readingMin: (v.minValue != -32768) ? v.minValue : null,
                                    readingSet: null,
                                    readingClr: null,
                                });
                                if (v.value != -32768) {
                                    if (v.value != null && v.value > maxValue) {
                                        maxValue = v.value;
                                    }
                                    if (v.value != null && v.value < minValue) {
                                        minValue = v.value;
                                    }
                                }
                            }
                            break;
                    }

                }
            });

            let lastSet = 0, lastClr = 0;
            let lastLevel = start;

            alarmLevels.forEach((v: APIGetLoggerAlarmLevelModel) => {
                if (v.startTime != null) {

                    let levelTime = new Date(v.startTime);
                    if (levelTime < start) {
                        levelTime = start;
                    }

                    switch (this.props.channel) {
                        case "C":
                            graphData.push({
                                date: levelTime,
                                channel: "Level",
                                readingSet: v.channelCSetLevel,
                                readingClr: v.channelCClearLevel,
                                reading: null,
                                readingMax: null,
                                readingMin: null,
                            });

                            if ((v.channelCSetLevel || 0) > maxValue) {
                                maxValue = v.channelCSetLevel || 0;
                            }
                            if ((v.channelCClearLevel || Number.MAX_VALUE) < minValue) {
                                minValue = v.channelCClearLevel || Number.MAX_VALUE;
                            }
                            break;
                        case "D":
                            graphData.push({
                                date: levelTime,
                                channel: "Level",
                                readingSet: v.channelDSetLevel,
                                readingClr: v.channelDClearLevel,
                                reading: null,
                                readingMax: null,
                                readingMin: null,
                            });

                            if ((v.channelDSetLevel || 0) > maxValue) {
                                maxValue = v.channelDSetLevel || 0;
                            }
                            if ((v.channelDClearLevel || Number.MAX_VALUE) < minValue) {
                                minValue = v.channelDClearLevel || Number.MAX_VALUE;
                            }
                            break;
                        case "E":
                            graphData.push({
                                date: levelTime,
                                channel: "Level",
                                readingSet: v.channelESetLevel,
                                readingClr: v.channelEClearLevel,
                                reading: null,
                                readingMax: null,
                                readingMin: null,
                            });

                            if ((v.channelESetLevel || 0) > maxValue) {
                                maxValue = v.channelESetLevel || 0;
                            }
                            if ((v.channelEClearLevel || Number.MAX_VALUE) < minValue) {
                                minValue = v.channelEClearLevel || Number.MAX_VALUE;
                            }
                            break;
                    }

                    // Add extra level points every 00:00 for graph zooming
                    while (((new Date(v.startTime).getTime() - lastLevel.getTime()) / (1000 * 60 * 60)) > 24) {
                        const midnight = new Date(new Date(lastLevel.getFullYear(), lastLevel.getMonth(), lastLevel.getDate()).setHours(24, 0, 0, 0));
                        graphData.push({
                            date: midnight,
                            channel: "Level",
                            readingSet: lastSet,
                            readingClr: lastClr,
                            reading: null,
                            readingMax: null,
                            readingMin: null,
                        });
                        lastLevel = midnight;
                    }

                    switch (this.props.channel) {
                        case "C":
                            lastSet = v.channelCSetLevel || 0;
                            lastClr = v.channelCClearLevel || 0;
                            break;
                        case "D":
                            lastSet = v.channelDSetLevel || 0;
                            lastClr = v.channelDClearLevel || 0;
                            break;
                        case "E":
                            lastSet = v.channelESetLevel || 0;
                            lastClr = v.channelEClearLevel || 0;
                            break;
                    }

                }
            });

            // Add last alarm levels at end
            graphData.push({
                date: end,
                channel: "Level",
                readingSet: lastSet,
                readingClr: lastClr,
                reading: null,
                readingMax: null,
                readingMin: null,
            });

            // and any extra level points every 00:00 for graph zooming
            while (((end.getTime() - lastLevel.getTime()) / (1000 * 60 * 60)) > 24) {
                const midnight = new Date(new Date(lastLevel.getFullYear(), lastLevel.getMonth(), lastLevel.getDate()).setHours(24, 0, 0, 0));
                graphData.push({
                    date: midnight,
                    channel: "Level",
                    readingSet: lastSet,
                    readingClr: lastClr,
                    reading: null,
                    readingMax: null,
                    readingMin: null,
                });

                lastLevel = midnight;
            }

            graphData.sort((a, b) => a.date.getTime() - b.date.getTime());

            console.log("Channel " + me.props.channel + " Min: " + minValue + " Max: " + maxValue);

            me.setState({
                minValue: minValue,
                maxValue: maxValue,
                readingsData: readingsData,
                graphData: graphData,
                loading: false,
            });

        });



    return true;
}

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Alarms');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("HH:mm DD/MM/YYYY");
    }

    dateLabelCustomizeText(cellInfo: any): ReactNode {
        if (cellInfo.value == null)
            return <p></p>
        else
            return <p>{moment(cellInfo.value).format("DD/MM/YYYY")}</p>
    }


    render(): ReactNode {

        return (

            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'relative', left: '40%', top: '50%',
                    }}>
                        <ClipLoader
                            size={50}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                    {!this.state.tableHidden &&
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                            <p style={{ fontWeight: "bold", fontSize: "10pt", textAlign: "center" }}>
                                {this.props.title == "" ?
                                    <span>Channel {this.props.channel}</span>
                                    :
                                    <span>{this.props.title}</span>
                                }
                            </p>
                            <Chart dataSource={this.state.graphData}>
                                <Size height={this.props.isprimary == "yes" ? 500 : 130} width="100%" />
                                    <CommonSeriesSettings
                                        argumentField="date"
                                        type="spline"
                                        ignoreEmptyPoints={true}>
                                        <Point size={4} />
                                    </CommonSeriesSettings>
                                <Series valueField="reading" name={this.props.channel} color="red" />
                                <ValueAxis
                                    name="Value"
                                    visualRange={{ startValue: this.state.minValue, endValue: this.state.maxValue }}
                                    allowDecimals={false}
                                >
                                    <Strip color="#ffe3d9" label={{ text: "Critical" }} startValue={6000} endValue={7000}  />
                                    <Strip color="#ebe9cb" label={{ text: "High" }} startValue={3000} endValue={4000}/>
                                    <Strip color="#d9f8f8" label={{ text: "Low" }} startValue={1000} endValue={2000}/>
                                </ValueAxis>
                                   <ArgumentAxis
                                        allowDecimals={false}
                                        argumentType="datetime"
                                        minValueMargin={0.1}
                                        maxValueMargin={0.1}
                                        wholeRange={[this.state.startDate, this.state.endDate]}
                                        >
                                    <TickInterval days={1} />
                                    </ArgumentAxis>
                                {this.props.isprimary == "yes" &&
                                    <ZoomAndPan
                                        argumentAxis="both"
                                        valueAxis="none"
                                    />
                                }
                                    <ScrollBar
                                    visible={this.props.isprimary == "yes"}
                                    />
                                <Legend visible={this.props.isprimary == "yes"} />
                                <Tooltip
                                    enabled={this.props.isprimary == "yes"}
                                    contentRender={ReadingTooltip}
                                />
                                <Crosshair
                                    enabled={true}
                                />
                                </Chart>
                            </GridItem>
                        </GridContainer>

                    }
                    </div>
                }

            </div>

        )
    }

}

export default withStyles(style)(StripedGraphSWG);