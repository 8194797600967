import React, { ReactNode } from "react";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import FormField from 'components/Fields/FormField';
import { ReactComponent as AshLogo } from 'assets/ashLogo.svg';
import { Redirect } from "react-router-dom";
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import BG from '../../assets/BG-Aquaguard.jpg';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';

import { withStyles, createStyles } from '@mui/styles';

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#AAAAAA",
            backgroundColor: "white",
            margin: "0",
            fontSize: "16px",
            marginTop: "20",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Props {
    classes: {
        formControl: string;
        cardCategoryWhite: string;
        cardTitleWhite: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: any;
        search: string;
    };
}

interface State {
    redirect: boolean;
    userLogin: string;
    company: string;
    password: string;
    accept: boolean;
    createError: boolean;
    enableSave: boolean;
    companyError: boolean;
    validationError: boolean;
}

export class Register extends React.Component<Props, State>  {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            redirect: false,
            userLogin: "",
            company: "",
            password: "",
            accept: false,
            createError: false,
            enableSave: false,
            companyError: false,
            validationError: false
        }
    }

    //save changes, for both edit and new
    saveUser = (): void => {
        if (!this.state.enableSave) {
            this.setState({ validationError: true })
            return
        }
        const me = this;

        //********************
        //send model to API

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                Username: me.state.userLogin,
                CompanyName: me.state.company,
                CompanyGuid: null,
                Level: "Useradmin",
            })
        };

        // Cannot use CallPostAPI as user not authenticated
        fetch(CreateUrl('/api/aquaguard/RegisterUser'), requestOptions)
            .then(async response => {
                if (response.ok) {
                    alert('Account Created Successfully.. please login. The initial password will be emailed to the email address you supplied, please make siure to check your junk / spam folder for any missing email notifications')
                    me.setState({
                        redirect: true,
                    });
                }
                else {
                    if (response.status == 409) {
                        me.setState({
                            companyError: true,
                            createError: false,
                        });
                    }
                    else {
                        me.setState({
                            companyError: false,
                            createError: true,
                        });
                    }
                }
            })

            .catch(function (error) {
                console.log(error)
                me.setState({
                    companyError: false,
                    createError: true,
                });
            });

    }

    //field changed events---
    userLoginChanged = (event: any): void => {
        this.setState({
            userLogin: event.target.value,
            enableSave: this.allComplete(event.target.value, this.state.company, this.state.accept),
        });
    }
    companyChanged = (event: any): void => {
        
        this.setState({
            company: event.target.value,
            enableSave: this.allComplete(this.state.userLogin, event.target.value, this.state.accept),
        });
    }
    acceptChanged = (event: any): void => {
        
        this.setState({
            accept: event.target.checked,
            enableSave: this.allComplete(this.state.userLogin, this.state.company, event.target.checked),
        });
    }

    allComplete = (login: string, company: string, accept: boolean): boolean => {
        return this.checkIfValidEmail(login)
            && company != ""
            && accept;
    }

    checkIfValidEmail = (emailAddress: string): boolean => {
        // Regular expression to check if string is a email address
        //https://stackoverflow.com/questions/940577/javascript-regular-expression-email-validation
        //const regexExp = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;  this fails if . before @
        const regexExp = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
        const result = regexExp.test(emailAddress);
        return result;
    }


    //do nothing, close the modal
    cancel = (): void => {
        this.setState({
            redirect: true,
        });
    }


    render(): ReactNode {

        const { classes, history } = this.props;

        return (!this.state.redirect ?
            (
                <div style={{
                    backgroundImage: `url(${BG})`,
                    width: '100vw',
                    height: '100vh',
                    overflow: 'hidden',


                }}>

                    <div className='some-page-wrapper'>
                        <div className='row'>

                            <div className='column' >
                                <div className='blue-column'>
                                    <Card style={{
                                        height: '90vh',
                                        width: '40vw',
                                        background: 'linear-gradient(130deg, rgba(0, 0, 0, 0.7) 0%, rgb(20,60,103, 0.6) 70%',
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: 'translate(-50%, -50%)',
                                        boxShadow: "2px 2px 10px black"

                                    }}>
                                        <CardHeader className={'center'} >
                                            <AshLogo style={{width: "100px", marginTop: "-50px"} } />
                                            <h5 className={classes.cardTitleWhite} style={{ marginTop: "-50px" }}>Register for AquaGuard account</h5>
                                        </CardHeader>
                                             <CardBody>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} >
                                                    <div style={{ padding: 10 }}>
                                                        <InputLabel style={{color: "white"}}>Your email - this will be used for your login:</InputLabel>
                                                        <TextField
                                                            id="outlined-input"
                                                            label="Login (Email)"
                                                            value={this.state.userLogin}
                                                            onChange={this.userLoginChanged}
                                                            onClick={(): void => this.setState({validationError: false}) }
                                                            variant="outlined"
                                                            type="email"
                                                            fullWidth
                                                            size="small"
                                                            inputProps={{
                                                                style: { color: '#ffffff' },
                                                            }}
                                                            style={{ backgroundColor: "#ffffff30" }}
                                                            sx={{
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        
                                                                        color: '#ffffff'// Border color when not focused
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#ffffff',
                                                                        color: '#ffffff'// Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#ffffff',
                                                                        color: '#ffffff'// Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    color: '#ffffff', // Label color when not focused
                                                                },
                                                                '&:hover .MuiInputLabel-root': {
                                                                    color: '#ffffff', // Label color on hover
                                                                },
                                                                '& .Mui-focused .MuiInputLabel-root': {
                                                                    color: '#ffffff', // Label color when focused
                                                                },
                                                            }}
                                                            
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <div style={{ padding: 10 }}>
                                                        <InputLabel style={{color: "white"}}>Company or your name:</InputLabel>
                                                        <TextField
                                                            id="outlined-input"
                                                            label="Company or your name"
                                                            value={this.state.company}
                                                            onChange={this.companyChanged}
                                                            onClick={(): void => this.setState({ validationError: false })}
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            inputProps={{
                                                                style: { color: '#ffffff' },
                                                            }}
                                                            style={{ backgroundColor: "#ffffff30"}}
                                                            sx={{
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        
                                                                        color: '#ffffff'// Border color when not focused
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#ffffff',
                                                                        color: '#ffffff'// Border color on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#ffffff',
                                                                        color: '#ffffff'// Border color when focused
                                                                    },
                                                                },
                                                                '& .MuiInputLabel-root': {
                                                                    color: '#ffffff', // Label color when not focused
                                                                },
                                                                '&:hover .MuiInputLabel-root': {
                                                                    color: '#ffffff', // Label color on hover
                                                                },
                                                                '& .Mui-focused .MuiInputLabel-root': {
                                                                    color: '#ffffff', // Label color when focused
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <div style={{ paddingLeft: 10, paddingRight: 10, marginTop: "-20px" }}>
                                                        <p style={{color: "white"} }>
                                                            The initial password will be emailed to the email address supplied, and you will be required to set a secure password after your first login.<br />
                                                            <div style={{textAlign: "center", marginTop: "10px", fontSize: "18px"}}><b>Please make sure to check your junk / spam folder for any missing email notifications</b></div>
                                                        </p>
                                                   </div>
                                                </Grid>
                                                <Grid item xs={12} style={{padding: 0} } >
                                                    <div style={{display: "flex", justifyContent: "space-around" }}>
                                                    <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    id="outlined-checkbox"
                                                                    value="Accept"
                                                                    checked={this.state.accept}
                                                                    onChange={this.acceptChanged}
                                                                    style={{ color: "white" }}
                                                                    onClick={(): void => this.setState({ validationError: false })}
                                                                />}
                                                            label={<div style={{color: "white"} }>
                                                                I accept Ashridge standard&nbsp;
                                                                <Link href="/termsandconditions" target="_blank"><span style={{textDecoration: "underline", color: "white"}}>Terms & Conditions</span></Link>
                                                            </div>
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                                
                                                <Grid item xs={12} >
                                                    <div style={{display: "flex", justifyContent: "space-around"}}>
                                                        <Button color="primary" variant="contained" onClick={(): void => this.saveUser()}>Create Account</Button>
                                                        <Button color="secondary" variant="contained" onClick={(): void => this.cancel()}>Cancel</Button>
                                                    </div>
                                                </Grid>
                                                {this.state.createError &&
                                                    <Grid item xs={12} >
                                                        <div style={{ paddingLeft: 10, paddingRight: 10, color: "white" }}>
                                                            There was an error creating your account - please check the details or contact <Link href="mailto:sales@ash-eng.com"><span className={classes.cardCategoryWhite} style={{ textDecoration: "underline", color: "red" }}>sales@ash-eng.com</span></Link>
                                                        </div>
                                                    </Grid>
                                                }
                                                {this.state.companyError &&
                                                    <Grid item xs={12} >
                                                        <div style={{ paddingLeft: 10, paddingRight: 10, color: "white" }}>
                                                            Your Company/Organisation already exists - please ask your administrator for a login or contact <Link href="mailto:sales@ash-eng.com"><span className={classes.cardCategoryWhite} style={{ textDecoration: "underline", color: "red" }}>sales@ash-eng.com</span></Link>
                                                        </div>
                                                    </Grid>
                                                }
                                                {this.state.validationError &&
                                                    <Grid item xs={12} >
                                                        <div style={{ paddingLeft: 10, paddingRight: 10, color: "white", display: "flex", justifyContent: "space-around" }}>
                                                            Please make sure that all information is complete
                                                        </div>
                                                    </Grid>
                                                }
                                            </Grid>
                                             </CardBody>
                                    </Card>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )
            :
            (
                <Redirect to={'/Login'} />
            )
        )
    }

}

export default withStyles(style)(Register);