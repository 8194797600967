import React, { useRef, useEffect, useState } from "react";
//import { Map } from '@esri/react-arcgis';
import EsriMap from "esri/Map";
import EsriMapView from "esri/views/MapView";
import EsriGraphicsLayer from "esri/layers/GraphicsLayer";
import EsriFeatureLayer from "esri/layers/FeatureLayer";
import EsriGroupLayer from "esri/layers/GroupLayer";
import EsriWebTileLayer from "esri/layers/WebTileLayer";
import EsriGraphic from "esri/Graphic";
import FeatureReductionCluster from "esri/layers/support/FeatureReductionCluster";
import LabelClass from "esri/layers/support/LabelClass";
import ColorVariable from "esri/renderers/visualVariables/ColorVariable";
import Button from '@mui/material/Button';

import { loadModules } from 'esri-loader';
import { loadCss } from 'esri-loader';

import { APIGetSitesLoggersModel } from 'models/APIGetSitesLoggersModel';
import { APILoggerDetail } from "models/APILoggerDetail";
import { CustomContent } from 'esri/popup/content';

interface Region {
    id: number;
    name: string;
    coordinates: Array<{ latitude: number; longitude: number }>;
}

interface Point {
    id: string | null;
    name: string | null;
    colour?: string;
    coordinates: Array<number>;
}


interface Props {
    center: Array<number>;
    height?: string;
    zoom: number;
    colour: string;
    trunkSegments?: Array<any>;
    singlePoints?: Array<Point>;
    singlePointsDesc?: string;
    showRegions: boolean;
    showAllSites: boolean;     //Show sites WITHOUT loggers
    showRainfall: boolean;
    sites: Array<APIGetSitesLoggersModel>;
    regions?: Array<Region> ;
    slides?: Array<{ time: number; path: string }>;
    currentSlide?: number;
    alarmFilter: number;
    onHold?: (latitude: number, longitude: number) => void;
    onRelease?: (latitude: number, longitude: number) => void;
    visibleSitesButton?: string;
    onButtonClick?: (siteIds: Array<number>) => void;
    mouseWheelZoom: boolean;
}


function WebMapView(props: Props): React.ReactElement {

    const mapDiv = useRef(null);

    const [webmap, setMap] = useState<EsriMap>();
    const [mapview, setMapView] = useState<EsriMapView>();
    const [siteLayer, setSiteLayer] = useState<EsriFeatureLayer>();
    const [regionLayer, setRegionLayer] = useState<EsriGroupLayer>();
    const [rainfallLayer, setRainfallLayer] = useState<EsriGroupLayer>();
    const [showAllSites, setShowAllSites] = useState<boolean>();
    const [alarmFilter, setAlarmFilter] = useState<number>();

    // Load CSS for ESRI maps
    loadCss();

    // Handle Sites layer.
    const showSites = (props: Props,
        webmap: EsriMap | undefined,
        FeatureLayer: any,
        Graphic: any,
        CustomContent: any
    ): void => {

        if (props.sites.length > 0) {

            const graphics: Array<EsriGraphic> = [];

            // Add all sites to the layer
            props.sites.map((site): void => {

                if (site.Latitude && site.Longitude) {
                    const point = {
                        type: "point",
                        x: site.Longitude,
                        y: site.Latitude,
                        //                                region: chamber.Region
                    };

                    let serial = site.SiteName;
                    let alarmState = 0;
                    let pointColour = 'rgba(0, 0, 255, 0.8)'; //blue by default for an empty chamber
                    let logger: APILoggerDetail | null = null;
                    if (site.Loggers) {
                        if (Array.isArray(site.Loggers))
                            logger = site.Loggers[0];
                        else
                            logger = site.Loggers;
                    }

                    if (logger) {

                        serial = logger.Serial.toFixed(0);
                        alarmState = 1;
                        if (site.AlarmState && site.AlarmState > 0) {
                            alarmState = BitCount(site.AlarmState) + 1;
                        }

                        alarmState += BitCount(logger.AlarmState);

                        pointColour = 'rgba(0, 255, 0, 0.8)'; //green if occupied
                        if (alarmState == 2) {
                            pointColour = 'rgba(255, 255, 0, 0.8)'; //yellow for 1 alarm 
                        }
                        if (alarmState == 3) {
                            pointColour = 'rgba(255, 153, 0, 0.8)'; //orange for 2 alarm
                        }
                        if (alarmState >= 4) {
                            pointColour = 'rgba(255, 0, 0, 0.8)'; //red for >2 alarm
                        }

                    }

                    // Aggregate alarm state if >1 logger
                    if (Array.isArray(site.Loggers)) {
                        alarmState = 1;
                        if (site.AlarmState && site.AlarmState > 0) {
                            alarmState = BitCount(site.AlarmState) + 1;
                        }
                        for (let i = 0; i < site.Loggers.length; i++) {
                            // select highest alarm state from loggers
                            const bits = BitCount(site.Loggers[i].AlarmState);
                            if ((bits + 1) > alarmState) {
                                alarmState = bits + 1;
                            }
                        }
                        if (alarmState == 2) {
                            pointColour = 'rgba(255, 255, 0, 0.8)'; //yellow for 1 alarm 
                        }
                        if (alarmState == 3) {
                            pointColour = 'rgba(255, 153, 0, 0.8)'; //orange for 2 alarm
                        }
                        if (alarmState >= 4) {
                            pointColour = 'rgba(255, 0, 0, 0.8)'; //red for >2 alarm
                        }
                    }



                    const attributes = {
                        LoggerID: logger?.LoggerId,
                        SiteID: site.SiteId,
                        Sitename: site.SiteNameUserFriendly,  // The name of the
                        LoggerSerial: logger?.Serial,  // The owner of the
                        LoggerChannels: logger?.Channels,
                        AlarmState: alarmState,
                        SiteAlarmState: site.AlarmState,
                        // NOTE: Link must be full valid URL and will open in new tab
                        DetailsLink: window.location.protocol + '//' + window.location.hostname + ":" + window.location.port + "/Portal/DeviceOverview?serial=" + serial,
                        ChariotLink: window.location.protocol + '//' + window.location.hostname + ":" + window.location.port + "/Portal/LCLDeviceDetail?serial=" + serial,
                        Loggers: JSON.stringify(site.Loggers),
                    };
                    // Create popup template
//                    const popupTemplate = {
//                        title: "Site: {Sitename}",
//                        content: "",
//                    };
//                    if (attributes.LoggerID && attributes.LoggerID < 50000) {
//                        popupTemplate.content = "<b><a href={DetailsLink}>{LoggerSerial}</a>:</b> Channels: {LoggerChannels}";
//                    }

                    // Create a symbol for rendering the graphic
                    const simpleMarkerSymbol = {
                        type: "simple-marker",
                        color: pointColour,
                        size: 6,
                        outline: {
                            color: [0, 0, 0], // black
                            width: 1
                        }
                    };

                    // Add the geometry and symbol to a new graphic
                    const pointGraphic: EsriGraphic = new Graphic({
                        geometry: point,
                        symbol: simpleMarkerSymbol,
                        name: serial,
                        //region: point.region,
                        fillColour: pointColour,
                        attributes: attributes,
//                        popupTemplate: popupTemplate
                    });

                    if (props.showAllSites || logger) {
                        if (props.alarmFilter == -1
                            || ((props.alarmFilter & 1) == 1 && alarmState == 1)
                            || ((props.alarmFilter & 2) == 2 && alarmState == 2)
                            || ((props.alarmFilter & 4) == 4 && alarmState >= 3)
                        ) {
                            graphics.push(pointGraphic);
                        }
                    }
                }
            });

            // CustomContent for site popup
            const sitePopup: CustomContent = new CustomContent({
                outFields: ["*"],
                creator: (event: any) => {
                    console.log(event.graphic.attributes);
                    const loggers = JSON.parse(event.graphic.attributes.Loggers);
                    let popup = "";
                    if (event.graphic.attributes.SiteAlarmState > 0) {
                        popup = "<div>Site Alarm state: " + event.graphic.attributes.SiteAlarmState + "</div>";
                    }
                    for (let i = 0; i < loggers.length; i++) {
                        let link = window.location.protocol + '//' + window.location.hostname + ":" + window.location.port + "/Portal/DeviceOverview?serial=" + loggers[i].Serial;
                        if (event.graphic.attributes["LoggerID"] > 500000) {
                            link = window.location.protocol + '//' + window.location.hostname + ":" + window.location.port + "/Portal/LCLDeviceDetail?serial=" + loggers[i].Serial;
                        }
                        popup += `<div><b><a href=${link}>${loggers[i].Serial}</a>:</b> Channels: ${loggers[i].Channels} Alarms: ${loggers[i].AlarmState} </div>`;
                    }
                    return popup;
                },

            });


            // Setup Clustering
            const siteLayer: EsriFeatureLayer = new FeatureLayer({
                id: "Sites",
                title: "Sites",
                source: graphics,
                fields: [{
                        name: "LoggerID",
                        alias: "LoggerID",
                        type: "integer"
                    },
                    {
                        name: "Sitename",
                        alias: "Sitename",
                        type: "string"
                    },
                    {
                        name: "LoggerSerial",
                        alias: "LoggerSerial",
                        type: "integer"
                    },
                    {
                        name: "LoggerChannels",
                        alias: "LoggerChannels",
                        type: "string"
                    },
                    {
                        name: "AlarmState",
                        alias: "AlarmState",
                        type: "integer"
                    },
                    {
                        name: "SiteAlarmState",
                        alias: "SiteAlarmState",
                        type: "integer"
                    },
                    {
                        name: "DetailsLink",
                        alias: "DetailsLink",
                        type: "string"
                    },
                    {
                        name: "ChariotLink",
                        alias: "ChariotLink",
                        type: "string"
                    },
                    {
                        name: "Loggers",
                        alias: "Loggers",
                        type: "string"
                    }
                ],
                objectIdField: "LoggerID",
                geometryType: "point",
                popupEnabled: true,
                popupTemplate: {
                    outFields: ["*"],
                    title: "Site: {Sitename}",
//                    content: "<b><a href={DetailsLink}>{LoggerSerial}</a>:</b> Channels: {LoggerChannels} Alarms: {AlarmState}",
                    content: [sitePopup],
                },
                renderer: {
                    type: "simple",
                    symbol: {
                        type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                        style: "circle",
                        size: 8,
                        outline: {
                            color: [0, 0, 0, 1], // black
                            width: 1
                        },
                    },
                    visualVariables: {
                        type: 'color',
                        field: "AlarmState",
                        stops: [
                            { value: 1, color: 'rgba(0, 255, 0, 0.8)' },
                            { value: 2, color: "rgba(255, 255, 0, 0.8)" },
                            { value: 3, color: "rgba(255, 153, 0, 0.8)" },
                            { value: 4, color: "rgba(255, 0, 0, 0.8)" },
                        ],
                    } as unknown as ColorVariable,
                },
            });

            siteLayer.featureReduction = {
                type: "cluster",
                clusterRadius: 80,
                clusterMinSize: 10,
                clusterMaxSize: 40,
                popupTemplate: {
                    content: [{
                            type: "text",
                            text: "This cluster represents <b>{cluster_count}</b> sites.",
                        },
                        {
                            type: "fields",
                            fieldInfos: [{
                                fieldName: "cluster_avg_AlarmState",
                                label: "Average Alarm State",
                                format: {
                                    digitSeparator: true,
                                    places: 0
                                }
                            }]
                        }
                    ]
                },
                visualVariables: {
                    type: 'color',
                    field: "cluster_avg_AlarmState",
                    stops: [
                        { value: 1, color: 'rgba(0, 255, 0, 0.8)' },
                        { value: 2, color: "rgba(255, 255, 0, 0.8)" },
                        { value: 3, color: "rgba(255, 153, 0, 0.8)" },
                        { value: 4, color: "rgba(255, 0, 0, 0.8)" },
                    ],
                } as unknown as ColorVariable,
                labelingInfo: [{
                    deconflictionStrategy: "none",
                    labelExpressionInfo: {
                        expression: "Text($feature.cluster_count, '#,###')"
                    },
                    symbol: {
                        type: "text",
                        color: [0,0,0],
                        font: {
                            weight: "bold",
                            family: "Noto Sans",
                            size: "10px"
                        }
                    },
                    labelPlacement: "center-center",
                } as unknown as LabelClass
                ] as Array<LabelClass>,
            } as unknown as FeatureReductionCluster;

            // Add layer to map and make it visible
            webmap?.add(siteLayer);
            setSiteLayer(siteLayer);
            
        }
    }

    // Handle Regions layer.
    const showRegions = (props: Props,
        webmap: EsriMap | undefined,
        GroupLayer: any,
        GraphicsLayer: any,
        Graphic: any
    ): void => {

        if (props.regions && props.regions.length > 0) {

            // Group layer for all Regions
            const regionGroup: EsriGroupLayer = new GroupLayer({
                id: "RegionGroup",
                title: "Regions",
                layers: [],
                visibilityMode: "inherited"
            });

            // Add layer to map and make it visible
            webmap?.add(regionGroup);
            setRegionLayer(regionGroup);

            // Add all regions as layer with polygon
            props.regions.map((region): void => {


                //handle the coordinates
                const coordinates: Array<number[]> = [];
                region.coordinates.forEach(coordinate => {
                    coordinates.push([ coordinate.latitude, coordinate.longitude ]);
                });

                // Create a polygon geometry
                const polygon = {
                    type: "polygon", // autocasts as new Polygon()
                    rings: [coordinates]
                };

                //handle the colour of the region
                const fillColour = [194, 214, 214, 0.5];

                // Create a symbol for rendering the graphic
                const fillSymbol = {
                    type: "simple-fill", // autocasts as new SimpleFillSymbol()
                    color: fillColour,
                    outline: { // autocasts as new SimpleLineSymbol()
                        color: [255, 255, 255],
                        width: 1
                    },
                    name: region.name
                };

                // Create Attributes & popup
                const attributes = {
                    Name: region.name, // The name of the
                };
                // Create popup template
                const popupTemplate = {
                    title: "{Name}",
                    content: "Region <b>{Name}</b>."
                };

                // Add the geometry and symbol to a new graphic
                const graphic = new Graphic({
                    geometry: polygon,
                    symbol: fillSymbol,
                    attributes: attributes,
                    popupTemplate: popupTemplate,
                    visible: true,
                });

                const regionLayer = new GraphicsLayer({
                    id: region.name,
                    title: region.name,
                    graphics: [graphic],
                    visible: true,
                });

                regionGroup.add(regionLayer);

            });



        }
    }

    // Handle Regions layer.
    const loadRainfall = (props: Props,
        webmap: EsriMap | undefined,
        GroupLayer: any,
        WebTileLayer: any
    ): void => {

        if (props.slides &&props.slides.length > 0) {

            let rainfallGroup: EsriGroupLayer;

            if (webmap?.allLayers.some((l) => l.id == "RainfallLayers")) {
                rainfallLayer?.removeAll();
                rainfallGroup = rainfallLayer as EsriGroupLayer;
            }
            else {
                // Group layer for all Slides
                rainfallGroup = new GroupLayer({
                    id: "RainfallLayers",
                    title: "Rainfall Radar",
                    layers: [],
                    visible: false,
                    visibilityMode: "exclusive"
                });
                setRainfallLayer(rainfallGroup);

                webmap?.add(rainfallGroup);

            }

            // Add all regions as layer with polygon
            props.slides.map((slide): void => {

                const tile: EsriWebTileLayer = new WebTileLayer({
                    id: slide.time.toString(),
                    title: slide.time.toString(),
                    urlTemplate: "https://tilecache.rainviewer.com/" + slide.path + "/256/{level}/{col}/{row}/2/1_0.png",
                    visible: false,
                    copyright: "Radar overlay: rainfallviewer.com",
                    opacity: 0.7,
//                    listMode: 'hide'
                });

                rainfallGroup.add(tile);

            });

        }
    }

    // Handle single points
    const showSinglePoints = (props: Props,
        webmap: EsriMap | undefined,
        FeatureLayer: any,
        Graphic: any
    ): void => {

        const graphics: Array<EsriGraphic> = [];

        if (props.singlePointsDesc && props.singlePoints && props.singlePoints.length > 0) {

            console.log(props.singlePoints.length + " single points");
            // Add all sites to the layer
            props.singlePoints.map((point): void => {

                if (point.coordinates.length == 2) {
                    const marker = {
                        type: "point",
                        x: point.coordinates[0],    //Longitude
                        y: point.coordinates[1],    //Latitude
                    };

                    let pointColour = 'rgba(0, 0, 0, 1)'; //black by default for a single point
                    if (point.colour) {
                        pointColour = point.colour;
                    }

                    const attributes = {
                        Name: point.name,  // The name of the
                    };
                    // Create popup template
                    const popupTemplate = {
                        title: "{Name}",
                        content: "",
                    };

                    // Create a symbol for rendering the graphic
                    const simpleMarkerSymbol = {
                        type: "simple-marker",
                        color: pointColour,
                        outline: {
                            color: [0, 0, 0], // black
                            width: 2
                        }
                    };

                    // Add the geometry and symbol to a new graphic
                    const spointGraphic: EsriGraphic = new Graphic({
                        geometry: marker,
                        symbol: simpleMarkerSymbol,
                        name: point.name,
                        fillColour: pointColour,
                        attributes: attributes,
                        popupTemplate: popupTemplate,
                    });

                    console.log("Add single point " + point.name);
                    graphics.push(spointGraphic);

                }
            });

            if (!webmap?.findLayerById(props.singlePointsDesc)) {
                const spLayer: EsriFeatureLayer = new FeatureLayer({
                    id: props.singlePointsDesc,
                    title: props.singlePointsDesc,
                    graphics: graphics,
                    visible: true,
                });

                // Add layer to map and make it visible
                webmap?.add(spLayer);
            }
 

        }
    }

    const onButtonClick = (): void => {
        if (webmap && props.onButtonClick && siteLayer) {
            const query = siteLayer.createQuery();
            if (mapview?.extent) {
                query.geometry = mapview?.extent;
                query.spatialRelationship = "intersects";
                siteLayer.queryFeatures(query)
                    .then((results) => {
                        const siteIds: Array<number> = [];
                        results.features.map((feature) => {
                            siteIds.push(feature.getAttribute("LoggerID"));
                        });
                        if (props.onButtonClick) {
                            props.onButtonClick(siteIds);
                        }
                    });
            }





        }
    }

    // Utility function to count bits set in integer
    const BitCount = (val: number): number => {
        let ret = 0;
        if (val != null && val > 0) {
            const bits = val.toString(2).match(/1/g)?.length;
            if (bits != undefined) {
                ret = bits;
            }
        }
        return ret;
    }

    useEffect(() => {

        let newmap: EsriMap;
        let view: EsriMapView;

        console.log("useEffect", props.showRegions, props.showAllSites, props.showRainfall, props.singlePointsDesc, props.alarmFilter);

        loadModules(["esri/Map",
            "esri/views/MapView",
            "esri/widgets/LayerList",
            "esri/widgets/Expand",
            "esri/layers/GraphicsLayer",
            "esri/layers/GroupLayer",
            "esri/layers/WebTileLayer",
            "esri/Graphic",
            "esri/layers/FeatureLayer",
            "esri/popup/content/CustomContent"
        ]).then(([Map, MapView, LayerList, Expand, GraphicsLayer, GroupLayer, WebTileLayer, Graphic, FeatureLayer, CustomContent]) => {

            if (!webmap) {
                /**
                 * Load basemap (OSM)
                 */

                newmap = new Map({
                    basemap: 'osm'
                });
                setMap(newmap);

                view = new MapView({
                    container: mapDiv.current,
                    map: newmap,
                    center: props.center,
                    zoom: props.zoom,
                    navigation: {
                        mouseWheelZoomEnabled: props.mouseWheelZoom,
                        browserTouchPanEnabled: props.mouseWheelZoom
                    }
                });

                // Include the layerList widget
                const layerList = new LayerList({
                    view,
                });

                const bkExpand = new Expand({
                    view,
                    content: layerList,
                    expanded: false
                });

                // Add the widget to the top-right corner of the view
                view.ui.add(bkExpand, "top-right");
                // Detect a 'hold' event on view (i.e. click and hold down)
                if (props.onHold != undefined) {
                    view.on("hold", function (event: any): void {
                        if (props.onHold != undefined) {
                            props.onHold(event.mapPoint.latitude, event.mapPoint.longitude);
                        }
                    });
                    if (props.onRelease != undefined) {
                        view.on("double-click", function (event: any): void {
                            if (props.onRelease != undefined) {
                                props.onRelease(event.mapPoint.latitude, event.mapPoint.longitude);
                            }
                        });
                    }
                }

                setMapView(view);
            }

            if (mapview) {
                // bonus - how many layers in the webmap?
                mapview.when(() => {
                    if (webmap?.allLayers && webmap?.allLayers.length) {
                        console.log("Layers: ", webmap?.allLayers.length);
                    } else {
                        console.log("No layers in this webmap.");
                    }

                });
            }
            // Now display any overlays

            // Existing or new webmap
            const map = webmap ? webmap : newmap;

            // Sites
            if (!map?.findLayerById("Sites")) {
                showSites(props, map, FeatureLayer, Graphic, CustomContent);
                setShowAllSites(props.showAllSites);
            }
            else if (props.showAllSites != showAllSites || props.alarmFilter != alarmFilter) {
                map?.findLayerById("Sites").destroy();
                showSites(props, map, FeatureLayer, Graphic, CustomContent);
                setShowAllSites(props.showAllSites);
                setAlarmFilter(props.alarmFilter);
            }

            // Regions
            if (!map?.findLayerById("RegionGroup")) {
                showRegions(props, map, GroupLayer, GraphicsLayer, Graphic);
            }

            // Rainfall
            if (!map?.findLayerById("RainfallLayers")) {
                loadRainfall(props, map, GroupLayer, WebTileLayer);
            }

            // Single points
            if (props.singlePointsDesc && !map?.findLayerById(props.singlePointsDesc)) {
                showSinglePoints(props, map, GraphicsLayer, Graphic);
            }



        }).catch((err) => console.error(err));

        if (webmap && mapview) {
            // Show/Hide layers

            if (siteLayer) {
                mapview.whenLayerView(siteLayer)
                    .then(function (layerView) {
                        // The layerview for the layer
                        layerView.visible = true;
                    })
                    .catch(function (error) {
                        // An error occurred during the layerview creation
                        console.log(error);
                    });
            }

            if (regionLayer) {
                mapview.whenLayerView(regionLayer)
                    .then(function (layerView) {
                        // The layerview for the layer
                        layerView.visible = props.showRegions;
                    })
                    .catch(function (error) {
                        // An error occurred during the layerview creation
                        console.log(error);
                    });
            }

            // Single points
            if (props.singlePointsDesc && props.singlePoints?.length == 1) {
                const layer: EsriGroupLayer = webmap.findLayerById(props.singlePointsDesc) as EsriGroupLayer;
                mapview.whenLayerView(layer)
                    .then(function (layerView) {
                        // The layerview for the layer
                        layerView.visible = true;
                        // Update Lat/Long for single graphic point
                        if (props.singlePoints) {
                            const point: Point = props.singlePoints[0];
                            (layerView.layer as EsriGraphicsLayer).removeAll();

                            const marker = {
                                type: "point",
                                x: point.coordinates[0],    //Longitude
                                y: point.coordinates[1],    //Latitude
                            };

                            let pointColour = 'rgba(0, 0, 0, 1)'; //black by default for a single point
                            if (point.colour) {
                                pointColour = point.colour;
                            }

                            const attributes = {
                                Name: point.name,  // The name of the
                            };
                            // Create popup template
                            const popupTemplate = {
                                title: "{Name}",
                                content: "",
                            };

                            // Create a symbol for rendering the graphic
                            const simpleMarkerSymbol = {
                                type: "simple-marker",
                                color: pointColour,
                                outline: {
                                    color: [0, 0, 0], // black
                                    width: 2
                                }
                            };
                            loadModules(["esri/Graphic",
                            ]).then(([Graphic]) => {

                                // Add the geometry and symbol to a new graphic
                                const spointGraphic: EsriGraphic = new Graphic({
                                    geometry: marker,
                                    symbol: simpleMarkerSymbol,
                                    name: point.name,
                                    fillColour: pointColour,
                                    attributes: attributes,
                                    popupTemplate: popupTemplate,
                                });

                                (layerView.layer as EsriGraphicsLayer).graphics.push(spointGraphic);
                                mapview.goTo({
                                    center: point.coordinates
                                });
                            });
                        }

                    })
                    .catch(function (error) {
                        // An error occurred during the layerview creation
                        console.log(error);
                    });
            }


            if (webmap?.allLayers.some((l) => l.id == "RainfallLayers")) {
                const layer: EsriGroupLayer = webmap.findLayerById("RainfallLayers") as EsriGroupLayer;
                layer.visible = props.showRainfall;

                if (props.showRainfall && props.slides) {
                    layer.layers.forEach((l) => {
                        if (props.slides) {
                            const slide = webmap.findLayerById(l.id);
                            slide.visible = (l.id == props.slides[props.currentSlide || 0].time.toString());
                        }
                    });
                }

            }
        }


    }, [props.showAllSites, props.showRegions, props.showRainfall, props.sites, props.slides, props.currentSlide, props.singlePoints, props.alarmFilter]);

    return (
        <div>
            {props.visibleSitesButton != "" &&
                <div style={{ float: 'right', display: 'inline' }}>
                    <Button color="primary" variant="contained" style={{ marginBottom: "10px" }} onClick={(): void => onButtonClick()}>{props.visibleSitesButton}</Button>
                </div>
            }
            <div className="mapDiv" ref={mapDiv} style={{ width: '100%', height: (props.height ? props.height : '70vh'), overflow: 'Hidden' }}></div>
        </div>
        )
/*    return (<Map
        style={{ width: '100%', height: '70vh', overflow: 'Hidden' }}
        mapProperties={{ basemap: 'osm' }}
        viewProperties={{
            center: props.center,
            zoom: props.zoom,
        }}
        onLoad={(map): void => setMap(map)}//handle the load of the map
        onFail={(e: Error): void => console.error(e)}//log the error to the console so we can debug}//incase loading fails

    />);
    */
   
}


export default WebMapView;
