export const terrainMap = [
    ["r", "r", "r", "r", "r", "r", "r", "r", "r"],
    ["r", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "r"],
    ["r", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "r"],
    ["r", "gr", "_", "_", "gr", "r", "r", "r", "r"],
    ["r", "r", "r", "_", "gr", "r", "rb", "rb", "r"],
    ["r", "gr", "r", "_", "gr", "r", "rb", "gr", "r"],
    ["r", "gr", "r", "r", "r", "r", "r", "r", "r"],
    ["r", "d", "r", "gr", "gr", "gr", "rb", "gr", "r"],
    ["r", "gr", "r", "gr", "gr", "gr", "rb", "gr", "r"],
    ["r", "r", "r", "r", "r", "r", "r", "r", "r"]
];

  // export var terrainMap = [
  //   [ "r", "r", "r", "r", "r", "r", "r", "r", "r", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["r" ,"gr", "gr", "gr", "gr", "gr", "gr", "gr", "r", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["r" ,"gr", "gr", "gr", "gr", "gr", "gr", "gr", "r", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["r" ,"gr", "gr", "gr", "gr", "gr", "gr", "gr", "r", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["r" ,"r",  "r",  "gr", "gr", "gr", "gr", "gr", "r", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["r" ,"gr",   "r",  "gr", "gr", "gr", "gr", "gr", "r", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["r" ,"gr",   "r",  "r", "r", "r", "r", "r", "r", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["r" ,"d",   "r",  "gr", "gr", "gr", "gr", "gr", "r", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["r" ,"gr",   "r",  "gr", "gr", "gr", "gr", "gr", "r", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["r" ,"r",   "r",  "r", "r", "r", "r", "r", "r", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["gr" ,"gr",   "gr",  "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["gr" ,"gr",   "gr",  "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["gr" ,"gr",   "gr",  "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["gr" ,"gr",   "gr",  "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["gr" ,"gr",   "gr",  "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["gr" ,"gr",   "gr",  "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["gr" ,"gr",   "gr",  "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["gr" ,"gr",   "gr",  "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["gr" ,"gr",   "gr",  "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  //   ["gr" ,"gr",   "gr",  "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr", "gr"],
  // ];