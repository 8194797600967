import React, { ReactNode } from "react";
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { User } from "oidc-client";
import {
    CircularGauge, Scale, Label, RangeContainer, Range, Font, Export, SubvalueIndicator, Size, Title, Margin, ValueIndicator,
} from 'devextreme-react/circular-gauge';

import { Border, Tick } from 'devextreme-react/chart';

// ##############################
// // // javascript library for creating charts
// #############################
const Chartist = require("chartist");

// ##############################
// // // variables used to create animation on charts
// #############################
const delays = 80,
  durations = 500;
const delays2 = 80,
  durations2 = 500;

// ##############################
// // // Daily Sales
// #############################
// ##############################
// // // Daily Sales
// #############################

const dailySalesChart = {
    data: {
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        series: [[12, 17, 7, 17, 23, 18, 38]]
    },
    options: {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
        }),
        low: 0,
        high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
    },
    // for animation
    animation: {
        draw: function (data: any): void {
            if (data.type === "line" || data.type === "area") {
                data.element.animate({
                    d: {
                        begin: 600,
                        dur: 700,
                        from: data.path
                            .clone()
                            .scale(1, 0)
                            .translate(0, data.chartRect.height())
                            .stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint
                    }
                });
            } else if (data.type === "point") {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: "ease"
                    }
                });
            }
        }
    }
};

// ##############################
// // // Daily Connections
// #############################

const dailyConnectionsChart = {
  data: {
    labels: ["M", "T", "W", "T", "F", "S", "S"],
    series: [[2000, 1997, 1998, 2171, 2200, 2017, 1996]]
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0
    }),
    low: 0,
    high: 2500, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  // for animation
  animation: {
    draw: function(data: any): void {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  },
    caption: "dailyConnectionsChart - No data received",
    legend: []
};

const hourlyConnectionsChart = {
    data: {
        labels: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
        series: [[2000, 1997, 1998, 2171, 2200, 2017, 1996, 2000, 1997, 1998, 2171, 2200, 2017, 1996, 2000, 1997, 1998, 2171, 2200, 2017, 1996, 2000, 1997, 1998]]
    },
    options: {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
        }),
        low: 0,
        high: 2500, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
    },
    // for animation
    animation: {
        draw: function (data: any): void {
            if (data.type === "line" || data.type === "area") {
                data.element.animate({
                    d: {
                        begin: 600,
                        dur: 700,
                        from: data.path
                            .clone()
                            .scale(1, 0)
                            .translate(0, data.chartRect.height())
                            .stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint
                    }
                });
            } else if (data.type === "point") {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: "ease"
                    }
                });
            }
        }
    },
    caption: "hourlyConnectionsChart - No data received",
    legend: []
};

// ##############################
// // // Log records generated
// #############################

const dailyRecordsGeneratedChart = {
    data: {
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        series: [[200, 197, 198, 217, 220, 201, 196]]
    },
    options: {
        axisX: {
            showGrid: false
        },
        low: 0,
        high: 1000,
        chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0
        }
    },
    responsiveOptions: [
        [
            "screen and (max-width: 640px)",
            {
                seriesBarDistance: 5,
                axisX: {
                    labelInterpolationFnc: function (value: any): any {
                        return value[0];
                    }
                }
            }
        ]
    ],
    animation: {
        draw: function (data: any): void {
            if (data.type === "bar") {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays2,
                        dur: durations2,
                        from: 0,
                        to: 1,
                        easing: "ease"
                    }
                });
            }
        }
    },
    caption: "Daily Log records - No data received",
    legend: []
};

const hourlyRecordsGeneratedChart = {
    data: {
        labels: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
        series: [[200, 197,  198,  217,  220,  201,  196,  200,  197,  198,  217,  220,  201,  196,  200,  197,  198,  217,  220,  201,  196,  200,  197,  198]]
    },
    options: {
        axisX: {
            showGrid: false
        },
        low: 0,
        high: 1000,
        chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0
        }
    },
    responsiveOptions: [
        [
            "screen and (max-width: 640px)",
            {
                seriesBarDistance: 5,
                axisX: {
                    labelInterpolationFnc: function (value: any): any {
                        return value[0];
                    }
                }
            }
        ]
    ],
    animation: {
        draw: function (data: any): void {
            if (data.type === "bar") {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays2,
                        dur: durations2,
                        from: 0,
                        to: 1,
                        easing: "ease"
                    }
                });
            }
        }
    },
    caption: "Hourly Log records - No data received",
    legend: []
};
// ##############################
// // // Daily Issues
// #############################

const dailyIssuesChart = {
    data: {
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        series: [
            [2, 1, 8, 7, 2, 2, 1],
            [1, 1, 1, 0, 1, 2, 1],
            [5, 6, 8, 7, 5, 5, 5],
               ]
    },
    options: {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
        }),
        low: 0,
        high: 20, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        onlyInteger: true,
        chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
    },
    // for animation
    animation: {
        draw: function (data: any): void {
            if (data.type === "line" || data.type === "area") {
                data.element.animate({
                    d: {
                        begin: 600,
                        dur: 700,
                        from: data.path
                            .clone()
                            .scale(1, 0)
                            .translate(0, data.chartRect.height())
                            .stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint
                    }
                });
            } else if (data.type === "point") {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: "ease"
                    }
                });
            }
        }
    },
    caption: "Daily issues - No data received",
    legend: [{ color: "rgba(255, 255, 255, 0.8)", title: "Non-Reporting Loggers" },
             { color: "#f05b4f", title: "Non-Generating Loggers" },
             { color: "#f4c63d", title: "Failed Connections" }]
};

const hourlyIssuesChart = {
    data: {
        labels: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
        series: [
            [2, 1, 8, 7, 2, 2, 1, 2, 1, 8, 7, 2, 2, 1, 2, 1, 8, 7, 2, 2, 1, 2, 1, 8],
            [1, 1, 1, 0, 1, 2, 1, 1, 1, 1, 0, 1, 2, 1, 1, 1, 1, 0, 1, 2, 1, 1, 1, 1],
            [5, 6, 8, 7, 5, 5, 5, 5, 6, 8, 7, 5, 5, 5, 5, 6, 8, 7, 5, 5, 5, 5, 6, 8],
        ]
    },
    options: {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
        }),
        low: 0,
        high: 20, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        onlyInteger: true,
        chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
    },
    // for animation
    animation: {
        draw: function (data: any): void {
            if (data.type === "line" || data.type === "area") {
                data.element.animate({
                    d: {
                        begin: 600,
                        dur: 700,
                        from: data.path
                            .clone()
                            .scale(1, 0)
                            .translate(0, data.chartRect.height())
                            .stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint
                    }
                });
            } else if (data.type === "point") {
                data.element.animate({
                    opacity: {
                        begin: (data.index + 1) * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: "ease"
                    }
                });
            }
        }
    },
    caption: "Hourly issues - No data received",
    legend: [{ color: "rgba(255, 255, 255, 0.8)", title: "Non-Reporting Loggers" },
    { color: "#f05b4f", title: "Non-Generating Loggers" },
    { color: "#f4c63d", title: "Failed Connections" }]
};

// ##############################
// // // Email Subscriptions
// #############################

const emailsSubscriptionChart = {
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]]
  },
  options: {
    axisX: {
      showGrid: false
    },
    low: 0,
    high: 1000,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0
    }
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function(value: any): any {
            return value[0];
          }
        }
      }
    ]
  ],
  animation: {
    draw: function(data: any): void {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  },
  legend: []
};

// ##############################
// // // Completed Tasks
// #############################

const completedTasksChart = {
  data: {
    labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
    series: [[230, 750, 450, 300, 280, 240, 200, 190]]
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0
    }),
    low: 0,
    high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  animation: {
    draw: function(data: any): void {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  },
  legend: []
};


 
// Chart refresh functions
export function refreshDailyConnections(): void {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 7);
    CallGetAPI(CreateUrl('/api/Aquaguard/GetDailyConnections?companyId=' + sessionStorage.getItem('companyId') + '&startDate=' + startDate.toJSON() + '&endDate=' + endDate.toJSON() + '&filterGroup=' + sessionStorage.getItem('filterGroupId')),{})
        .then(data => {
            // Update dailyConnectionsChart with values
            // data is [{date, connections}]
            let maxConn = 0;
            let totalConn = 0;
            const labels: string[] = [];
            const series: any[] = [];
            data.map((rec: { date: string | number | Date; connections: number }) => {
                const day = new Date(rec.date);
                labels.push(day.getDate()  + "/<br/>" + (day.getMonth() + 1));
                series.push(rec.connections);
                if (rec.connections > maxConn) {
                    maxConn = rec.connections;
                }
                totalConn += rec.connections;
            })
            dailyConnectionsChart.data.labels = labels;
            dailyConnectionsChart.data.series = [series];
            dailyConnectionsChart.options.high = maxConn + Math.round(maxConn / 10);
            dailyConnectionsChart.caption = "Total " + totalConn + " connections in last week.";
        })
        .catch(console.log)

}

export function refreshHourlyConnections(): void {
    CallGetAPI(CreateUrl('/api/Aquaguard/GetHourlyConnections?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')),{})
        .then(data => {
            // Update dailyConnectionsChart with values
            // data is [{date, connections}]
            let maxConn = 0;
            let totalConn = 0;
            const labels: string[] = [];
            const series: any[] = [];
            data.map((rec: { date: string | number | Date; connections: number }) => {
                const day = new Date(rec.date);
                const hour = ("0" + day.getHours()).substr(day.getHours() < 10 ? 0 : 1, 2);
                labels.push(hour.substr(0,1) + "<br/>" + hour.substr(1,1));
                series.push(rec.connections);
                if (rec.connections > maxConn) {
                    maxConn = rec.connections;
                }
                totalConn += rec.connections;
            })
            hourlyConnectionsChart.data.labels = labels;
            hourlyConnectionsChart.data.series = [series];
            hourlyConnectionsChart.options.high = maxConn + Math.round(maxConn / 10);
            hourlyConnectionsChart.caption = "Total " + totalConn + " connections in last 24 hours.";
        })
        .catch(console.log)

}

export function refreshDailyLogRecords(): void {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 7);
    CallGetAPI(CreateUrl('/api/Aquaguard/GetDailyLogRecords?companyId=' + sessionStorage.getItem('companyId') + '&startDate=' + startDate.toJSON() + '&endDate=' + endDate.toJSON() + '&filterGroup=' + sessionStorage.getItem('filterGroupId')),{})
        .then(data => {
            // Update recordsGeneratedChart with values
            // data is [{date, records}]
            let maxRec = 0;
            let totalRec = 0;
            const labels: string[] = [];
            const series: any[] = [];
            data.map((rec: { date: string | number | Date; records: number }) => {
                const day = new Date(rec.date);
                labels.push(day.getDate() + "/<br/>" + (day.getMonth() + 1));
                series.push(rec.records);
                if (rec.records > maxRec) {
                    maxRec = rec.records;
                }
                totalRec += rec.records;
            })
            dailyRecordsGeneratedChart.data.labels = labels;
            dailyRecordsGeneratedChart.data.series = [series];
            if (maxRec == 0) {
                dailyRecordsGeneratedChart.options.high = 1;
            }
            else {
                dailyRecordsGeneratedChart.options.high = maxRec + Math.round(maxRec / 10);
            }
            dailyRecordsGeneratedChart.caption = "Total " + totalRec + " records read in last week.";
        })
        .catch(console.log)

}

export function refreshHourlyLogRecords(): void {
    CallGetAPI(CreateUrl('/api/Aquaguard/GetHourlyLogRecords?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')),{})
        .then(data => {
            // Update recordsGeneratedChart with values
            // data is [{date, records}]
            let maxRec = 0;
            let totalRec = 0;
            const labels: string[] = [];
            const series: any[] = [];
            data.map((rec: { date: string | number | Date; records: number }) => {
                const day = new Date(rec.date);
                const hour = ("0" + day.getHours()).substr(day.getHours() < 10 ? 0 : 1, 2);
                labels.push(hour.substr(0, 1) + "<br/>" + hour.substr(1, 1));
                series.push(rec.records);
                if (rec.records > maxRec) {
                    maxRec = rec.records;
                }
                totalRec += rec.records;
            })
            hourlyRecordsGeneratedChart.data.labels = labels;
            hourlyRecordsGeneratedChart.data.series = [series];
            if (maxRec == 0) {
                hourlyRecordsGeneratedChart.options.high = 1;
            }
            else {
                hourlyRecordsGeneratedChart.options.high = maxRec + Math.round(maxRec / 10);
            }
            hourlyRecordsGeneratedChart.caption = "Total " + totalRec + " records read in last 24 hours.";
        })
        .catch(console.log)

}

export function refreshDailyIssues(): void {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 7);
    CallGetAPI(CreateUrl('/api/Aquaguard/GetDailyIssues?companyId=' + sessionStorage.getItem('companyId') + '&startDate=' + startDate.toJSON() + '&endDate=' + endDate.toJSON() + '&filterGroup=' + sessionStorage.getItem('filterGroupId')),{})
        .then(data => {
            // Update recordsGeneratedChart with values
            // data is [{date, records}]
            let maxRec = 0;
            let totalRec = 0;
            const labels: string[] = [];
            const seriesNR: any[] = [];
            const seriesNG: any[] = [];
            const seriesNC: any[] = [];
            data.map((rec: { date: string | number | Date; nonReportingLoggers: number; nonGeneratingLoggers: number; failedConnections: number }) => {
                const day = new Date(rec.date);
                labels.push(day.getDate() + "/<br/>" + (day.getMonth() + 1));
                seriesNR.push(rec.nonReportingLoggers);
                if (rec.nonReportingLoggers > maxRec) {
                    maxRec = rec.nonReportingLoggers;
                }
                seriesNG.push(rec.nonGeneratingLoggers);
                if (rec.nonGeneratingLoggers > maxRec) {
                    maxRec = rec.nonGeneratingLoggers;
                }
                seriesNC.push(rec.failedConnections);
                if (rec.failedConnections > maxRec) {
                    maxRec = rec.failedConnections;
                }
                totalRec += rec.nonReportingLoggers + rec.nonGeneratingLoggers + rec.failedConnections;
            })
            dailyIssuesChart.data.labels = labels;
            dailyIssuesChart.data.series = [seriesNR, seriesNG, seriesNC];
            dailyIssuesChart.options.high = maxRec + Math.round(maxRec / 10);
            dailyIssuesChart.caption = "Total " + totalRec + " issues last week.";
        })
        .catch(console.log)

}

export function refreshHourlyIssues(): void {
    CallGetAPI(CreateUrl('/api/Aquaguard/GetHourlyIssues?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')),{})
        .then(data => {
            // Update recordsGeneratedChart with values
            // data is [{date, records}]
            let maxRec = 0;
            let totalRec = 0;
            const labels: string[] = [];
            const seriesNR: any[] = [];
            const seriesNG: any[] = [];
            const seriesNC: any[] = [];
            data.map((rec: { date: string | number | Date; nonReportingLoggers: number; nonGeneratingLoggers: number; failedConnections: number }) => {
                const day = new Date(rec.date);
                const hour = ("0" + day.getHours()).substr(day.getHours() < 10 ? 0 : 1, 2);
                labels.push(hour.substr(0, 1) + "<br/>" + hour.substr(1, 1));
                seriesNR.push(rec.nonReportingLoggers);
                if (rec.nonReportingLoggers > maxRec) {
                    maxRec = rec.nonReportingLoggers;
                }
                seriesNG.push(rec.nonGeneratingLoggers);
                if (rec.nonGeneratingLoggers > maxRec) {
                    maxRec = rec.nonGeneratingLoggers;
                }
                seriesNC.push(rec.failedConnections);
                if (rec.failedConnections > maxRec) {
                    maxRec = rec.failedConnections;
                }
                totalRec += rec.nonReportingLoggers + rec.nonGeneratingLoggers + rec.failedConnections;
            })
            hourlyIssuesChart.data.labels = labels;
            hourlyIssuesChart.data.series = [seriesNR, seriesNG, seriesNC];
            hourlyIssuesChart.options.high = maxRec + Math.round(maxRec / 10);
            hourlyIssuesChart.caption = "Total " + totalRec + " issues last 24 hours.";
        })
        .catch(console.log)

}

// Needs to return Promise<ReactNode>
export function refreshInAlarm(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')),{})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.loggersInAlarm}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}

// Needs to return Promise<ReactNode>
export function refreshGuageInAlarm(): Promise<ReactNode> {
    const subValues = [5, 25];
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(
                        <div>
                            <div style={{ float: 'right', marginRight: 12}}>
                            <h2>
                                {data.loggersInAlarm}
                            </h2>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <CircularGauge
                                    id="alarmGauge"
                                    value={data.loggersInAlarm}
                                    redrawOnResize={true}>
                                    <ValueIndicator
                                        type="triangleNeedle"
                                        color="#8FBC8F"
                                    ></ValueIndicator>
                                    <Size width={240} height={200} />
                                    <Scale startValue={0} endValue={data.loggersUnlicenced}>
                                        <Label useRangeColors={true} />
                                    </Scale>
                                    <RangeContainer palette="material">
                                        <Range color="#FF0000" startValue={0} endValue={data.loggersInAlarm} />
                                        <Range color="#03AC13" startValue={data.loggersInAlarm} endValue={data.loggersUnlicenced} />
                                    </RangeContainer>
                                    <Export enabled={false} />
                                </CircularGauge>
                            </div>
                        </div>
                    );
                }
                else {
                    reject(<div>{"?"}</div>);
                }
            })
            .catch(console.log);
    });

}



// Needs to return Promise<ReactNode>
export function refreshRecentConnections(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')),{})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.connections24hour}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}
// Needs to return Promise<ReactNode>
export function refreshConnections7day(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.connections7day}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}

// Needs to return Promise<ReactNode>
export function refreshNonReporting(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')),{})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.loggersInactive24hour}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}

// Needs to return Promise<ReactNode>
export function refreshUnprocessedBlocks(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.unprocessedBlocks}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}

// Needs to return Promise<ReactNode>
export function refreshloggersActive24hour(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.loggersActive24hour}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}

// Needs to return Promise<ReactNode>
export function refreshloggersActive7day(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.loggersActive7day}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}
// Needs to return Promise<ReactNode>
export function refreshloggersInactive7day(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.loggersInactive7day}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}

// Needs to return Promise<ReactNode>
export function refreshloggersFailedConn24hour(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.loggersFailedConn24hour}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}
// Needs to return Promise<ReactNode>
export function refreshloggersFailedConn7day(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.loggersFailedConn7day}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}

// Needs to return Promise<ReactNode>
export function refreshloggersFailedConnUnidentified24hour(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.loggersFailedConnUnidentified24hour}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}
// Needs to return Promise<ReactNode>
export function refreshloggersFailedConnUnidentified7day(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.loggersFailedConnUnidentified7day}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}


// Needs to return Promise<ReactNode>
export function refreshUndeployedLoggers(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.undeployedLoggers}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}

// Needs to return Promise<ReactNode>
export function refreshFirmwareUpdates(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.firmwareUpdates}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}

// Needs to return Promise<ReactNode>
export function refreshUpdatesPending(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.updatesPending}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}

// Needs to return Promise<ReactNode>
export function refreshDormantLoggers(): Promise<ReactNode> {
    return new Promise((resolve, reject) => {
        CallGetAPI(CreateUrl('/api/Aquaguard/Dashboard?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data.success === true) {
                    resolve(<div>{data.dormantLoggers}</div>);
                }
                else {
                    reject(<div>{"?"}</div>);
                }

            })
            .catch(console.log);
    });

}

export  {
    dailySalesChart,
    dailyConnectionsChart,
    dailyRecordsGeneratedChart,
    dailyIssuesChart,
    hourlyConnectionsChart,
    hourlyRecordsGeneratedChart,
    hourlyIssuesChart,
    emailsSubscriptionChart,
    completedTasksChart,
};


// Make refresh... functions GLOBAL
(window as any).refreshInAlarm = refreshInAlarm;
(window as any).refreshGuageInAlarm = refreshGuageInAlarm;
(window as any).refreshRecentConnections = refreshRecentConnections;
(window as any).refreshConnections7day = refreshConnections7day;
(window as any).refreshNonReporting = refreshNonReporting;
(window as any).refreshUnprocessedBlocks = refreshUnprocessedBlocks;
(window as any).refreshloggersActive24hour = refreshloggersActive24hour;
(window as any).refreshloggersActive7day = refreshloggersActive7day;
(window as any).refreshloggersInactive7day = refreshloggersInactive7day;
(window as any).refreshloggersFailedConn24hour = refreshloggersFailedConn24hour;
(window as any).refreshloggersFailedConn7day = refreshloggersFailedConn7day;
(window as any).refreshloggersFailedConnUnidentified24hour = refreshloggersFailedConnUnidentified24hour;
(window as any).refreshloggersFailedConnUnidentified7day = refreshloggersFailedConnUnidentified7day;
(window as any).refreshUndeployedLoggers = refreshUndeployedLoggers;
(window as any).refreshFirmwareUpdates = refreshFirmwareUpdates;
(window as any).refreshUpdatesPending = refreshUpdatesPending;
(window as any).refreshDormantLoggers = refreshDormantLoggers;