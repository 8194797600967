import React from 'react';
import BatteryGauge from 'react-battery-gauge'
// STATE



const BatteryInfoDisplay: React.FC = ({eventData }) => {


    const custom = {
        batteryBody: {
            strokeWidth: 2
        },
        batteryCap: {
            strokeWidth: 2
        },
        batteryMeter: {
            fill: '#00BF00',
            lowBatteryValue: 15,
            lowBatteryFill: 'red',
            outerGap: 1,
            noOfCells: 1,
            interCellsGap: 1
        },
        readingText: {
            darkContrastColor: 'black'
        }
    }


    return (
        <div>
            <h5 style={{ textAlign: "center" }}><b>Battery Info</b></h5>
        <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "4%" }}>
        
            <div style={{width: "40%"} }>
                <p style={{textAlign: "center"} }>Remaining Battery Percentage *</p>
                <div style={{display: "flex", justifyContent: "center"} }>
                        {eventData && <BatteryGauge value={eventData.remainingBattPerc} orientation="horizontal" size={100} animated={true} customization={custom} />}
                    </div>
            </div>
                <div style={{ width: "40%"}}>
                    <p style={{ textAlign: "center" }}>Estimate Years Remaining *</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {eventData && <h4 style={{marginTop: "7%"}}><b>{eventData.remainingBattYears > 15 ? '15+' : eventData.remainingBattYears.toFixed(2)}</b></h4>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BatteryInfoDisplay;
