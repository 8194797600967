import React from 'react';


const formatDate = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false,
}).format;

const formatNumber = new Intl.NumberFormat('en-GB', {
    maximumFractionDigits: 2
}).format;

export default function ReadingTooltip(info: any): React.ReactNode {
    return (
        <div className="state-tooltip">
            <div className="readingDate">
                <span className="caption">Date/Time</span>: {formatDate(info.point.data.date)}
            </div>
            <div className="channel">
                <span className="caption">Channel</span>: {info.seriesName}
            </div>
            <div className="value">
                <span className="caption">Value</span>: {formatNumber(info.value)} {info.point.data.units}
            </div>
        </div>
    );
}