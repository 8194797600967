import React, { useState } from 'react'
import {
    LinearGauge,
    Scale,
    Tick,
    Label,
    RangeContainer,
    Range,
    ValueIndicator,
    SubvalueIndicator,
    Export,
    Title,
    Font,
} from 'devextreme-react/linear-gauge';
import { APIGetLoggerConnectionsModel } from "models/APIGetLoggerConnectionsModel";
import { Tooltip, ITooltipOptions } from 'devextreme-react/tooltip';
import ClipLoader from "react-spinners/ClipLoader";
import Modal from 'react-bootstrap/Modal'
import Dialog from '@mui/material/Dialog';
import MaterialButton from '@mui/material/Button';
import Chart, { Size, CommonSeriesSettings, Aggregation, ValueAxis, ArgumentAxis, Series, TickInterval, Point, CommonAxisSettings } from 'devextreme-react/chart';
import moment from 'moment';

interface Data {
    date: Date;
    battery: number | null;
    signal: number | null;
}

interface Props {
    connectionsData: Array<APIGetLoggerConnectionsModel> | null
}


const NewSignalGauge: React.FC<Props> = ({ connectionsData }) => {



    //STATE
    const [signalGraphToggle, setSignalGraphToggle] = useState<boolean>(false)



    let minSignal = 32;
    let maxSignal = 0;
    let valueColor = "";
    if (connectionsData && connectionsData.length > 0) {
        for (let i = 0; i < connectionsData.length; i++) {

            if (connectionsData[i].latestRssi < minSignal) {
                minSignal = connectionsData[i].latestRssi
            }
            if (connectionsData[i].latestRssi > maxSignal) {
                maxSignal = connectionsData[i].latestRssi
            }

        }


        if (connectionsData[0].latestRssi < 3) {
            valueColor = '#F5564A'
        } else if (connectionsData[0].latestRssi < 8) {
            valueColor = '#FA8F35'
        } else if (connectionsData[0].latestRssi < 13) {
            valueColor = '#FFC720'
        } else if (connectionsData[0].latestRssi < 18) {
            valueColor = '#CBC83E'
        } else {
            valueColor = '#97C95C'
        }
    }
    const subValues = [minSignal, maxSignal];


    return (
        <>
            {!connectionsData && connectionsData.length === 0 ?
                <div style={{ position: 'relative', left: '40%', top: '50%', }}>
                    <ClipLoader
                        size={50}
                        color={"#123abc"}
                        loading={true}
                    />
                </div>
                :
                <div style={{ display: "flex", flexDirection: "column" }} onClick={(): void => setSignalGraphToggle(!signalGraphToggle) }>
                    <h5 style={{ textAlign: "center" }}><b>Signal Info</b></h5>
                    <p style={{ textAlign: "center", marginBottom: "-10px" }}>Arrows show last 7 day range</p>
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <LinearGauge
                            id="gauge"
                            value={connectionsData[0].latestRssi}
                            subvalues={subValues}
                            width={250 }
                            
                        >
                            <Scale startValue={0} endValue={32} tickInterval={5}>
                                <Tick color="black" />
                            </Scale>
                            <RangeContainer>
                                <Range startValue={18} endValue={32} />
                                <Range startValue={13} endValue={18} />
                                <Range startValue={8} endValue={13} />
                                <Range startValue={3} endValue={8} />
                                <Range startValue={0} endValue={3} />
                            </RangeContainer>
                            
                            <ValueIndicator offset={10} color={valueColor} width={10} id="valueIndicator" />
                            <Tooltip target="#valueIndicator" showEvent="mouseenter" hideEvent="mouseleave">
                                <div>Data</div>
                                </Tooltip>
                            
                            <SubvalueIndicator offset={-20} color={'black'} />
                        </LinearGauge>
                    </div>
                    
                </div>}     
        </>
    )
}
export default NewSignalGauge