/* /src/components/auth/logout.jsx */
import React from "react";
import { AuthConsumer } from "../../auth/authProvider";
import LoadingOverlay from '@ronchalant/react-loading-overlay';

/*
 * logout: This component is going to logout the user.
 */

const fullScreen = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'rgba(51, 51, 51, 0.7)',
    zIndex: 10,
}

export const Logout = () => (
    <AuthConsumer>
        {({ signoutRedirectCallback }) => {
            signoutRedirectCallback();
            return <LoadingOverlay
                active={true}
                spinner
                text='Processing your logout...'
                styles={{ wrapper: fullScreen }}
            >
                <p>loading - logout</p>
            </LoadingOverlay>
        }}
    </AuthConsumer>
);