import React, { useState, useEffect } from 'react'
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import ClipLoader from "react-spinners/ClipLoader";
import { Button } from '@mui/material';
import Modal from 'react-bootstrap/Modal'
interface DeviceData {
    any_motion: number;
    battery: number;
    flooding: number;
    iccid: string;
    id: number;
    image_data: string;
    meter_reading: number;
    received: Date;
    rssi: number;
    sequence_no: number;
    temperature: number;
    timestamp: Date;
    unit_id: string;
}

const LaiwaDevicesOverview: React.FC = () => {
    const [devices, setDevices] = useState<DeviceData[]>()
    const [loading, setLoading] = useState<boolean>()
    const [imageModal, setImageModal] = useState<boolean>(false)
    const [selectedDevice, setSelectedDevice] = useState<DeviceData>()
    useEffect(() => {
        const deviceData: Array<DeviceData> = []
        CallGetAPI(CreateUrl(`/api/smartmeter`), {})
            .then(data => {
                for (let i = 0; i < data.length; i++) {
                    const rec: DeviceData = {
                        any_motion: data[i].any_motion,
                        battery: data[i].battery,
                        flooding: data[i].flooding,
                        iccid: data[i].iccid,
                        id: data[i].id,
                        image_data: data[i].image_data,
                        meter_reading: data[i].meter_reading,
                        received: data[i].received,
                        rssi: data[i].rssi,
                        sequence_no: data[i].sequence_no,
                        temperature: data[i].temperature,
                        timestamp: data[i].timestamp,
                        unit_id: data[i].unit_id,
                    }
                    
                    deviceData.push(rec)
                }
                setDevices(deviceData)
            })
    }, [])


    const buttonColumnTemplate = ({ data }) => {
        
        return <Button color="primary" onClick={(): void => handleImageButtonClick(data)} variant="contained">View Image</Button>
    }


    const handleImageButtonClick = (data):void => {
        setSelectedDevice(data)
        setImageModal(!imageModal)
    }

    return (
        <>
            {console.log(sessionStorage.getItem('userGuid')) }
            <div>
            {loading && <div style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={loading}
                />
            </div>}
            {!loading &&
                    <div>
                        <Modal show={imageModal} style={{ marginTop: "50px" }} dialogClassName="modal-50w">
                            <Modal.Header>
                                <Modal.Title>{`Device: ${selectedDevice?.iccid}`}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img src={selectedDevice?.image_data} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="contained" onClick={(): void => {setImageModal(!imageModal) } }>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    <h4>Laiwa Devices</h4>
                    <DataGrid
                    dataSource={devices}
                    allowColumnResizing={true}
                    repaintChangesOnly={true}
                    >
                        <FilterRow visible={true} />
                        <Column dataType="number" dataField='id' />
                        <Column dataType="string" dataField='iccid' />
                        <Column dataType="string" dataField='unit_id' />
                        <Column dataType="number" dataField='any_motion'/>
                        <Column dataType="number" dataField='battery'/>
                        <Column dataType="number" dataField='flooding'/>
                        <Column dataType="number" dataField='meter_reading'/>
                        <Column dataType="number" dataField='rssi'/>
                        <Column dataType="number" dataField='temperature'/>
                        <Column dataType="datetime" dataField='received'/>
                        <Column dataType="datetime" dataField='timestamp'/>
                        <Column cellRender={buttonColumnTemplate } dataField='image_data' />
                        
                        </DataGrid>
                </div>}
            </div>
        </>
    )
}
export default LaiwaDevicesOverview