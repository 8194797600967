import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI, CallPostAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import ClipLoader from "react-spinners/ClipLoader";
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MapTile from "components/Tiles/MapTile.js";
import MapIcon from '@mui/icons-material/Map';
import Update from "@mui/icons-material/Update";
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import makeAnimated from 'react-select/animated';
import SiteDeviceChips from './SiteDeviceChips'
import SiteModal from './SiteModal'
import { DataGrid, Column, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring, Format, Export, HeaderFilter } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

import { GT_OSGB } from "Utils/geotools2.js";
import { APILoggerDetail, buildAPILoggerDetail } from 'models/APILoggerDetail';
import UploadDialog from '../../components/ImageUpload/UploadDialog';


const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Site {
    id: number;
    SiteNameUserFriendly: string;
    SiteName: string;
    created: Date;
    deleted: Date | null;
    Latitude: number | null;
    Longitude: number | null;
    devices: Array<Device>;
    groups: Array<{ id: number; displayName: string }>;
    defaultSite: boolean;
    fkCompanyId: number;
    fkEASiteId: number | null;
    EASite: string;
    lastUpdate: Date | null;
    alarmState: number | null;
    alarmsEnabled: number | null;
    Loggers: Array<APILoggerDetail>;
    LastReading?: Date | null;
    HasActiveLoggers?: string;
}

interface Device {
    value: number;      //loggerId
    label: string;      // Serial
    key: string;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
    history: {
        goBack: () => void;
    };
}


interface State {
    tableHidden: boolean;
    visible: boolean;
    selectedLogger: number;
    defaultSiteId: number;
    groups: Array<{ id: number; displayName: string }>;
    devices: Array<Device>;
    unallocated: Array<Device>;
    tempSite: Site;
    tempSiteDevices: Array<Device>;
    orgSiteDevices: Array<Device>;
    showUnallocated: boolean;
    editSite: boolean;
    edit: boolean;
    anchorEl: HTMLElement | null;
    deleteModal: boolean;
    mapModal: boolean;
    loading: boolean;
    authorized: boolean;
    chipData: Array<{ key: number; label: string; icon: string }>;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        siteName: string;
        siteId: number | null;
        serial: string;
        deviceId: number | null;
    } | null;
    modal: boolean;
    modalTitle: string;
    latError: boolean;
    longError: boolean;
    siteError: boolean;
    siteErrorMessage: string
    gridRefError: boolean;
    fromDate: Date;
    imageDialog: boolean;
    siteNameArray: Array<string>
}

// const animatedComponents = makeAnimated();


export class Sites extends React.Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;

    constructor(props: Readonly<Props>) {
        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: []
        });

        this.store.clear();
        this.state = {
            tableHidden: false,
            visible: false,
            selectedLogger: 0,
            defaultSiteId: 0,
            groups: [],
            devices: [],
            unallocated: [],
            tempSite: {
                id: 0,
                SiteNameUserFriendly: '',
                SiteName: '',
                created: new Date(),
                deleted: null,
                Latitude: null,
                Longitude: null,
                devices: [],
                groups: [],
                defaultSite: false,
                fkCompanyId: 0,
                fkEASiteId: null,
                EASite: "",
                lastUpdate: null,
                alarmsEnabled: 0,
                alarmState: 0,
                Loggers: [],
            },
            tempSiteDevices: [],
            orgSiteDevices: [],
            showUnallocated: true,
            editSite: false,
            edit: false,
            anchorEl: null,
            deleteModal: false,
            mapModal: false,
            loading: true,
            authorized: true,
            chipData: [
                { key: 0, label: '44404', icon: 'PF' },
                { key: 1, label: '44405', icon: 'KC' },
                { key: 2, label: '44406', icon: 'MH' },
                { key: 3, label: '44407', icon: 'JM' }
            ],
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            modal: false,
            modalTitle: '',
            latError: false,
            longError: false,
            siteError: false,
            siteErrorMessage: "",
            gridRefError: false,
            fromDate: new Date(),
            imageDialog: false,
            siteNameArray: []

        };

        this.gridRef = React.createRef();
    }



    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (): void => {
        NotificationManager.error('Error creating new record', '', 5000);
    };

    createConflictNotification = (): void => {
        NotificationManager.warning('Changes conflict with another user. Refreshing sites', 'Click to retry', 5000, () => {
            this.reloadData(this.state.tempSite.id);
        });
    };


    componentDidMount(): void {

        this.reloadData(null);

    }

    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {
        const me = this;
        me.setState(
            {
                tableHidden: true,
                loading: true,
                authorized: true,
            });

            
        CallGetAPI(CreateUrl('/api/aquaguard/SitesForCompany?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                console.log(data)
                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent
                    const records: Array<{
                        type: "insert" | "update" | "remove";
                        data: any;
                        key: any;
                        index: number | undefined
                    }> = [];
                    let defaultSiteId = 0;
                    const siteNameList: Array<string> = [];

                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].siteId;

                        const siteDevices: Array<Device> = [];
                        const loggers: Array<APILoggerDetail> = [];
                        data[i].loggers.forEach((element: any) => {
                            siteDevices.push({ value: element.loggerId, label: element.serial, key: element.serial });
                            loggers.push(buildAPILoggerDetail(element));
                        });

                        const rec: Site = {
                            id: id,
                            SiteName: data[i].siteName,
                            SiteNameUserFriendly: data[i].siteNameUserFriendly,
                            created: data[i].created,
                            deleted: data[i].deleted,
                            Latitude: data[i].latitude,
                            Longitude: data[i].longitude,
                            devices: siteDevices,
                            fkCompanyId: data[i].fkCompanyId,
                            lastUpdate: data[i].lastUpdate,
                            groups: [],
                            defaultSite: data[i].defaultSite,
                            EASite: data[i].eaSite,
                            fkEASiteId: data[i].fkEASiteId,
                            alarmsEnabled: data[i].alarmsEnabled,
                            alarmState: data[i].alarmState,
                            Loggers: loggers,
                            LastReading: adjustTime(data[i].lastReading),
                            HasActiveLoggers: data[i].defaultSite ? "Unassigned": (data[i].hasActiveLoggers ? "Active" : "Inactive"),
                        };

                        siteNameList.push(rec.SiteName)
                        records.push({ type: "insert", data: rec, index: i, key: rec.id });

                        if (data[i].defaultSite) {
                            defaultSiteId = id;
                        }
                    }
                    console.log(records)
                    me.store.clear();
                    me.store.push(records);
                    
                    me.setState(
                        {
                            defaultSiteId: defaultSiteId,
                            tableHidden: false,
                            loading: false,
                            siteNameArray: siteNameList
                        });

                    if (editId != null) {
                        let tempSiteDevices: Array<Device> = [];
                        me.store.byKey(editId)
                            .then((site) => {
                                if (site !== null) {
                                    tempSiteDevices = me.state.devices.filter(function (device) { return site.devices.map((a: Device) => a.value).includes(device.value); });
                                }

                                this.setState(
                                    {
                                        orgSiteDevices: tempSiteDevices,
                                        tempSiteDevices: tempSiteDevices,
                                        tempSite: site,
                                        editSite: true,
                                        modal: !me.state.modal,
                                        anchorEl: null,
                                        modalTitle: "Edit Site"
                                    });
                            });
                    }
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        });
                }
            },
                // reject() - API error
                (data) => {
                    me.setState(
                        {
                            tableHidden: true,
                            loading: false
                        });
                    console.log(data);

                })
            .catch(function (error) {
                me.setState(
                    {
                        authorized: false
                    });
                console.log(error);

            });

        CallGetAPI(CreateUrl('/api/aquaguard/getloggers'), {})
            .then(data => {
                //console.log(data)
                if (data.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    const devices: Array<Device> = []; //clear dummy data
                    const unallocated: Array<Device> = [];

                    for (let i = 0; i < data.length; i++) {
                        const serial = data[i].serial;
                        const id = data[i].id;
                        const rec = {
                            value: id,
                            label: serial,
                            key: serial
                        };
                        devices.push(rec);
                        if (data[i].siteId == me.state.defaultSiteId) {
                            unallocated.push(rec);
                        }
                    }
                    me.setState(
                        {
                            devices: devices,
                            unallocated: unallocated,
                        });
                }
                else {
                    me.setState(
                        {
                            devices: [],
                        });
                    console.log("No devices");
                }
            },
                // reject() - API error
                () => {
                    me.setState(
                        {
                            devices: [],
                            tableHidden: true,
                            loading: false
                        });
                    console.log("API Error");

                })
            .catch(function (error) {
                me.setState(
                    {
                        authorized: false
                    });
                console.log(error);
            });
    }



    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    //new row
    newSite = (): void => {
        this.setState({
            modal: !this.state.modal,
            editSite: false,
            tempSite: {
                id: -1,
                SiteNameUserFriendly: 'New Site',
                SiteName: 'New Ref',
                created: new Date(),
                deleted: null,
                Latitude: null,
                Longitude: null,
                devices: [],
                fkCompanyId: parseInt(sessionStorage.getItem('companyId') || '0'),
                fkEASiteId: null,
                EASite: "",
                groups: [],
                defaultSite: false,
                lastUpdate: null,
                alarmsEnabled: 0,
                alarmState: 0,
                Loggers: [],
            },
            tempSiteDevices: [],
            modalTitle: "New Site"
        });


    }
    //edit row
    editSite = (): void => {
        this.setState({
            editSite: true,
            modal: !this.state.modal,
            anchorEl: null,
            modalTitle: "Edit Site"
        });
    }

    //save changes, for both edit and new
    saveSite = (): void => {

        const me = this;

        if (this.state.editSite === false) { //adding a new site
            const tempSite = me.state.tempSite;
            tempSite.devices = me.state.tempSiteDevices;

            //push new model to api
            me.setState({
                tempSite: tempSite,
                modal: !me.state.modal,
            });

            //********************
            //send model to API

            //save site
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SiteName: me.state.tempSite.SiteName,
                    SiteNameUserFriendly: me.state.tempSite.SiteNameUserFriendly,
                    Latitude: me.state.tempSite.Latitude || 0,
                    Longitude: me.state.tempSite.Longitude || 0,
                    CreatedDate: new Date(),
                    FkCompanyId: parseInt(sessionStorage.getItem('companyId') || '0'),
                    DefaultSite: me.state.tempSite.defaultSite,
                    FkEASiteId: me.state.tempSite.fkEASiteId,
                })
            };

            CallGetAPI(CreateUrl('/api/aquaguard/Site'), requestOptions)
                .then(data => {
                    const site = me.state.tempSite;
                    site.id = data.id;
                    site.lastUpdate = data.lastUpdate;
                    me.store.push([{ type: 'insert', key: site.id, data: site }]);
                    me.setState({
                        tempSite: site
                    });

                    if (me.state.tempSiteDevices.length == 0) {
                        me.createSuccessNotification();
                        //UI update
                        me.reloadData(null);
                    }
                    else {
                        //save loggers connected to sites
                        const loggerIds: Array<{ Id: number }> = [];
                        me.state.tempSiteDevices.forEach(element => loggerIds.push({ 'Id': element.value }));

                        requestOptions.body = JSON.stringify({
                            siteId: site.id,
                            lastUpdate: site.lastUpdate,
                            loggers: loggerIds,
                            assignLogReadingsFromDate: this.state.fromDate,
                        });

                        CallPostAPI(CreateUrl('/api/Aquaguard/AssignLoggersToSite'), requestOptions)
                            .then(async response => {
                                if (response.status == 200) {
                                    me.createSuccessNotification();
                                    //UI update
                                    me.reloadData(null);
                                }
                                else {
                                    me.createErrorNotification();
                                }
                                console.log(response)
                            })
                            .catch(function (error) {
                                me.createErrorNotification()
                                console.log(error)
                            });
                    }
                })

                .catch(function (error) {
                    me.createFailedInsertErrorNotification()
                    console.log(error)
                });


        }
        else {//modifying an existing site

            const tempTempSite = this.state.tempSite;
            tempTempSite.devices = this.state.tempSiteDevices;

            this.store.push([{ type: 'update', key: tempTempSite.id, data: tempTempSite }]);

            this.setState({
                modal: !this.state.modal,
            });

            //********************
            //send model to API

            const me = this;

            //save site
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Id: me.state.tempSite.id,
                    SiteName: me.state.tempSite.SiteName,
                    SiteNameUserFriendly: me.state.tempSite.SiteNameUserFriendly,
                    Created: me.state.tempSite.created,
                    Deleted: me.state.tempSite.deleted,
                    Latitude: me.state.tempSite.Latitude || 0,
                    Longitude: me.state.tempSite.Longitude || 0,
                    FKCompanyId: me.state.tempSite.fkCompanyId,
                    DefaultSite: me.state.tempSite.defaultSite,
                    LastUpdate: me.state.tempSite.lastUpdate,
                    FkEASiteId: me.state.tempSite.fkEASiteId,
                })
            };

            CallPutAPI(CreateUrl('/api/Aquaguard/Site/' + me.state.tempSite.id), requestOptions)
                .then(async response => {
                    console.log(response)
                    if (response.status == 409) {
                        // Conflicting edit detected
                        me.createConflictNotification();
                        console.log("Edit conflict");
                        me.reloadData(null);
                    }
                    else {

                        if ((me.state.tempSiteDevices.length == 0 && me.state.orgSiteDevices.length == 0)
                            || me.state.tempSiteDevices == me.state.orgSiteDevices) {
                            me.createSuccessNotification();
                            //UI update
                            //                        me.reloadData(null);
                        }
                        else {
                            //api update
                            const newloggerIds: Array<{ Id: number }> = [];
                            const removeloggerIds: Array<{ Id: number }> = [];

                            me.state.tempSiteDevices.forEach((element) => {
                                newloggerIds.push({ Id: element.value });
                            });

                            me.state.orgSiteDevices.forEach((element) => {
                                if (!me.state.tempSiteDevices.includes(element)) {
                                    removeloggerIds.push({ Id: element.value });
                                }
                            });

                            if (removeloggerIds.length > 0) {
                                requestOptions.body = JSON.stringify({ siteId: me.state.tempSite.id, LastUpdate: me.state.tempSite.lastUpdate, loggers: removeloggerIds, assignLogReadingsFromDate: null });

                                CallPostAPI(CreateUrl('/api/Aquaguard/DeassignLoggersFromSite'), requestOptions)
                                    .then(async response => {
                                        if (response.status == 200) {
                                            if (newloggerIds.length > 0) {
                                                requestOptions.body = JSON.stringify({
                                                    siteId: me.state.tempSite.id,
                                                    LastUpdate: me.state.tempSite.lastUpdate,
                                                    loggers: newloggerIds,
                                                    assignLogReadingsFromDate: this.state.fromDate,
                                                });

                                                CallPostAPI(CreateUrl('/api/Aquaguard/AssignLoggersToSite'), requestOptions)
                                                    .then(async response => {
                                                        if (response.status == 200) {
                                                            me.createSuccessNotification();
                                                            //UI update
                                                            me.reloadData(null);
                                                        }
                                                        else {
                                                            if (response.status == 409) {
                                                                me.createConflictNotification();
                                                                me.reloadData(null);
                                                            }
                                                            else
                                                                me.createErrorNotification();
                                                        }
                                                        console.log(response);
                                                    })
                                                    .catch(function (error) {
                                                        me.createErrorNotification();
                                                        console.log(error);
                                                    });
                                            }
                                            else {
                                                me.createSuccessNotification();
                                                //UI update
                                                me.reloadData(null);
                                            }
                                        }
                                        else {
                                            if (response.status == 409) {
                                                me.createConflictNotification();
                                                me.reloadData(null);
                                            }
                                            else
                                                me.createErrorNotification();
                                        }
                                        console.log(response);
                                    })
                                    .catch(function (error) {
                                        me.createErrorNotification();
                                        console.log(error);
                                    });
                            }
                            else {
                                if (newloggerIds.length > 0) {
                                    requestOptions.body = JSON.stringify({
                                        siteId: me.state.tempSite.id,
                                        LastUpdate: me.state.tempSite.lastUpdate,
                                        loggers: newloggerIds,
                                        assignLogReadingsFromDate: this.state.fromDate,
                                    });

                                    CallPostAPI(CreateUrl('/api/Aquaguard/AssignLoggersToSite'), requestOptions)
                                        .then(async response => {
                                            if (response.status == 200) {
                                                me.createSuccessNotification();
                                                //UI update
                                                me.reloadData(null);
                                            }
                                            else {
                                                if (response.status == 409) {
                                                    me.createConflictNotification();
                                                    me.reloadData(null);
                                                }
                                                else
                                                    me.createErrorNotification();
                                            }
                                            console.log(response);
                                        })
                                        .catch(function (error) {
                                            me.createErrorNotification();
                                            console.log(error);
                                        });
                                }
                            }

                        }
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification()
                    console.log(error)
                });

        }

        //        const dataSource = this.gridRef.current.instance.getDataSource();
        //        dataSource.load();
    }


    //do nothing, close the modal
    cancel = (): void => {
        this.toggleModal();
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleDeleteModal = (): void => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            anchorEl: null,
            modalTitle: "Warning"
        });

    }

    toggleMapModal = (): void => {

        const mapSiteModel = this.state.tempSite;
        //        mapSiteModel.coordinate = { longitude: this.state.tempSite.lon, latitude: this.state.tempSite.lat }

        this.setState({
            mapModal: !this.state.mapModal,
            //            mapSiteModel: mapSiteModel,
            //            lat: mapSiteModel.lat,
            //            lon: mapSiteModel.lon,
            anchorEl: null
        });
    }

    toggleImageModal = (): void => {
        this.setState({
            imageDialog: !this.state.imageDialog,
            anchorEl: null,
            modalTitle: "Image upload"
        });

    }

    siteDetailsClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/SiteDetail',
                redirectProps: {
                    siteName: this.state.tempSite.SiteNameUserFriendly || this.state.tempSite.SiteName,
                    siteId: this.state.tempSite.id,
                    serial: '',
                    deviceId: null
                }
            });
    }
    deviceDetailsClick = (serial: string, id: number): void => {
        if (id < 50000) {
            this.setState(
                {
                    redirect: true,
                    redirectPath: '/portal/DeviceOverview',
                    redirectProps: {
                        serial: serial,
                        deviceId: id,
                        siteId: null,
                        siteName: ''
                    }
                });
        }
        else {
            this.setState(
                {
                    redirect: true,
                    redirectPath: '/portal/LCLDeviceDetail',
                    redirectProps: {
                        serial: serial,
                        deviceId: id,
                        siteId: null,
                        siteName: ''
                    }
                });

        }
    }

    siteAlarmsClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/SiteAlarmConfigs',
                redirectProps: {
                    siteName: this.state.tempSite.SiteNameUserFriendly || this.state.tempSite.SiteName,
                    siteId: this.state.tempSite.id,
                    serial: '',
                    deviceId: null
                }
            });
    }

    deleteRow = (): void => {

        const me = this;

        this.setState({
            deleteModal: !this.state.deleteModal,
        });

        //delete from database
        CallDeleteAPI(CreateUrl('/api/aquaguard/Site?siteId=' + this.state.tempSite.id), {})
            .then(async response => {
                if (response.status == 200) {
                    me.createSuccessNotification();
                    //remove the old version of the row
                    this.store.push([{ type: 'remove', key: me.state.tempSite.id }]);
                }
                else {
                    me.createErrorNotification();
                }
                console.log(response);
            })
            .catch(function (error) {
                me.createErrorNotification();
                console.log(error);
            });

    }

    //field changed events---
    siteNameChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        let siteError = false;
        let siteErrorMessage = ""
        if (event.target.value === "") {
            siteError = true;
            siteErrorMessage = "Site name can't be blank"
        } else if (this.state.siteNameArray.includes(event.target.value)) {
            siteError = true;
            siteErrorMessage = "Site name already taken, please create a different name"
        } else {
            tempSite.SiteNameUserFriendly = event.target.value;
        }
        this.setState({ tempSite: tempSite, siteError: siteError, siteErrorMessage: siteErrorMessage });
    }
    siteRefChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        tempSite.SiteName = event.target.value;
        this.setState({ tempSite: tempSite });
    }

    siteLatChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        let latError = false;
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value > 90 || value < -90) {
            latError = true;
        }
        else {
            tempSite.Latitude = value;
        }
        this.setState({ tempSite: tempSite, latError: latError });
    }
    siteLonChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        let longError = false;
        const value = parseFloat(event.target.value);
        if (isNaN(value) || value > 180 || value < -180) {
            longError = true;
        }
        else {
            tempSite.Longitude = value;
        }
        this.setState({ tempSite: tempSite, longError: longError });
    }
    siteDeviceChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        tempSite.devices = event.target.value;
        this.setState({
            tempSite: tempSite
        });
    };

    sitegridRefChanged = (event: any): void => {
        const tempSite = this.state.tempSite;
        const osgb = new GT_OSGB();
        if (osgb.parseGridRef(event.target.value)) {
            const wgs84 = osgb.getWGS84();
            tempSite.Latitude = wgs84.latitude;
            tempSite.Longitude = wgs84.longitude;
            this.setState({
                tempSite: tempSite
            });
        }
        else {
            this.setState({ gridRefError: true });
        }
    };

    siteFromDateChanged = (newDate: Date): void => {
        this.setState({ fromDate: newDate });
    }

    //-------------------------

    handleClick(event: any, id: number): void {
        this.store.byKey(id)
            .then((site) => {
                let tempSiteDevices: Array<Device> = [];
                if (site !== null) {
                    tempSiteDevices = this.state.devices.filter(function (device) { return site.devices.map((a: Device) => a.value).includes(device.value); });
                }

                this.setState(
                    {
                        orgSiteDevices: tempSiteDevices,
                        tempSiteDevices: tempSiteDevices,
                        tempSite: site,
                        anchorEl: event.currentTarget
                    });
            },
                (error) => {
                    console.log(error);
                });
    }

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sites');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), window.location.hostname.includes('zonelog.net') ? 'ZONELOG Rxport.xlsx' : 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }


    moreRender(site: any): React.ReactNode {
        return <IconButton size="small" onClick={(e): void => { this.handleClick(e, site.data.id) }}><MoreHorizIcon /></IconButton>
    }

    chipsRender(site: any): React.ReactNode {
        return <SiteDeviceChips data={site.data.devices} deviceDetailsClick={this.deviceDetailsClick} />
    }

    handleInputChange = (event: any): void => {
        let mulitSelectContents = event
        if (mulitSelectContents === null) {
            mulitSelectContents = [];
        }
        this.setState({
            tempSiteDevices: mulitSelectContents
        });
    };

    handleFilterChange = (event: any): void => {
        this.setState({
            showUnallocated: !this.state.showUnallocated
        });
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm:ss");
    }

    render(): React.ReactNode {
        const { classes } = this.props;

        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                (!this.state.redirect) ?
                    (<div>

                        <Modal
                            show={this.state.deleteModal}
                            onHide={(): void => this.toggleDeleteModal()}
                            dialogClassName="modal-100w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {this.state.modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="show-grid">
                                Delete Site {this.state.tempSite.SiteNameUserFriendly || this.state.tempSite.SiteName} AND ALL associated Readings?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.deleteRow()}>Continue</Button>
                                <Button color="secondary" variant="contained" onClick={(): void => this.toggleDeleteModal()}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            style={{ backgroundColor: 'rgba(52, 52, 52, 0.8)' }}
                            show={this.state.mapModal}
                            onHide={(): void => this.toggleMapModal()}
                            dialogClassName="modal-100w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Body className="show-grid">
                                <MapTile
                                    icon={<MapIcon />}
                                    color={'success'}
                                    title={this.state.tempSite.SiteNameUserFriendly || this.state.tempSite.SiteName}
                                    content={''}
                                    footerContent={''}
                                    footerIcon={<Update />}
                                    zones={[]}
                                    sites={[this.state.tempSite]}
                                    zoom={15}
                                    center={[this.state.tempSite.Longitude || 0, this.state.tempSite.Latitude || 0]}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button color="secondary" variant="contained" onClick={(): void => this.toggleMapModal()}>Close</Button>
                            </Modal.Footer>
                        </Modal>

                        <UploadDialog
                            show={this.state.imageDialog}
                            title={this.state.modalTitle}
                            siteId={this.state.tempSite ? this.state.tempSite.id : 0}
                            loggerId={0}
                            onSubmit={this.toggleImageModal.bind(this)}
                            onCancel={this.toggleImageModal.bind(this)}
                        />


                        <SiteModal
                            title={this.state.modalTitle}
                            show={this.state.modal}
                            siteName={this.state.tempSite.SiteNameUserFriendly}
                            siteRef={this.state.tempSite.SiteName}
                            lat={this.state.tempSite.Latitude}
                            siteLatChanged={this.siteLatChanged}
                            siteLonChanged={this.siteLonChanged}
                            sitegridRefChanged={this.sitegridRefChanged}
                            lon={this.state.tempSite.Longitude}
                            siteNameChanged={this.siteNameChanged}
                            siteRefChanged={this.siteRefChanged}
                            save={this.saveSite}
                            close={this.toggleModal}
                            devices={this.state.showUnallocated ? this.state.unallocated : this.state.devices}
                            defaultDevices={this.state.tempSiteDevices}
                            handleInputChange={this.handleInputChange}
                            handleFilterChange={this.handleFilterChange}
                            latError={this.state.latError}
                            longError={this.state.longError}
                            siteError={this.state.siteError}
                            siteErrorMessage={this.state.siteErrorMessage}
                            gridRefError={this.state.gridRefError}
                            fromDateChanged={this.siteFromDateChanged}
                        />


                        {this.state.loading &&
                            <div hidden={!this.state.loading} style={{
                                position: 'absolute', left: '50%', top: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <ClipLoader
                                    size={150}
                                    color={"#123abc"}
                                    loading={this.state.loading}
                                />
                            </div>
                        }
                        {!this.state.loading &&
                            <div hidden={this.state.loading}>
                                {console.log(this.store) }

                                {this.state.tableHidden &&
                                    <ApiFailed />
                                }
                                {!this.state.tableHidden &&
                                    <GridContainer >
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Card>
                                                <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <IconButton
                                                            style={{ display: 'inline' }}
                                                            onClick={(): void => this.props.history.goBack()}
                                                            size="large"><ArrowBackIcon style={{ fill: "white" }} />
                                                        </IconButton>
                                                        <h4 style={{ display: 'inline', paddingTop: 12 }} className={classes.cardTitleWhite}>Sites</h4>
                                                    </div>   
                                                    <IconButton aria-label="newSite" onClick={(): void => this.newSite()} size="large">
                                                        <AddCircleOutlineIcon style={{ fill: "white" }} />
                                                    </IconButton>
                                                </CardHeader>
                                                <CardBody>
                                                    
                                                    <DataGrid
                                                        allowColumnResizing={true}
                                                        allowColumnReordering={true}
                                                        repaintChangesOnly={true}
                                                        dataSource={new DataSource({ store: this.store })}
                                                        ref={this.gridRef}
                                                        showRowLines={true}
                                                        rowAlternationEnabled={true}
                                                        onExporting={this.onExporting}
                                                    >
                                                   
                                                        <StateStoring enabled={true} type="localStorage" storageKey="siteListGrid" />
                                                        <Export enabled={true} />
                                                        <GroupPanel visible={false} />
                                                        <Grouping autoExpandAll={true} />
                                                        <FilterRow visible={true} />
                                                        <HeaderFilter visible={true} />

                                                        <Column dataField='SiteNameUserFriendly' visible={true} dataType='string' caption="Site Name" />
                                                        <Column dataField='SiteName' visible={true} dataType='string' caption="Site Ref" />
                                                        <Column dataField='Latitude' visible={true} dataType='number' width={100} allowHeaderFiltering={false} >
                                                            <Format type="fixedPoint" precision={2} />
                                                        </Column>
                                                        <Column dataField='Longitude' visible={true} dataType='number' width={100} allowHeaderFiltering={false}>
                                                            <Format type="fixedPoint" precision={2} />
                                                        </Column>
                                                        {/*<Column dataField="LastReading" dataType="string" customizeText={this.dateColumnCustomizeText} caption="Last Reading" allowFiltering={false} allowSorting={true} />*/}
                                                        <Column
                                                            dataField="HasActiveLoggers"
                                                            caption="State"
                                                            allowFiltering={false}
                                                            allowSorting={false}
                                                            groupIndex={0} 
                                                            visible={false}
                                                            dataType="string"
                                                        >
                                                        </Column>

                                                        <Column dataField='groups' visible={false} dataType='string' />
                                                        <Column dataField='EASite' caption="EA Station" visible={true} dataType="string" width={150} />
                                                        <Column dataField='alarmsEnabled' caption="Alarms" visible={true} dataType="string" width={50} />
                                                        <Column dataField='alarmState' caption="Alarm  state" visible={true} dataType="string" width={50} />
                                                        <Column fixed={true} fixedPosition='right' type="buttons" cellRender={this.chipsRender.bind(this)} key='devices' caption="Devices" />
                                                        <Column fixed={true} fixedPosition='right' type="edit" cellRender={this.moreRender.bind(this)} key='more' caption="Action" width={50} />
                                                        <Paging defaultPageSize={10} />
                                                        <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} showInfo={true} visible={true} infoText="{2} sites" />
                                                    </DataGrid>


                                                </CardBody>
                                            </Card>
                                        </GridItem>

                                    </GridContainer>
                                }

                            </div>
                        }

                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}
                            disableScrollLock={true}
                        >
                            <MenuItem onClick={this.editSite}>Edit</MenuItem>
                            <MenuItem onClick={this.siteDetailsClick}>Details</MenuItem>
                            {this.state.tempSite.Latitude && this.state.tempSite.Longitude ?
                                <MenuItem onClick={this.toggleMapModal}>View on map</MenuItem>
                                :
                                <span></span>
                            }
                            {!this.state.tempSite.defaultSite &&
                                <MenuItem onClick={this.siteAlarmsClick}>Alarm Configs</MenuItem>
                            }
                            {!this.state.tempSite.defaultSite &&
                                <MenuItem onClick={this.toggleImageModal}>Upload Picture</MenuItem>
                            }
                            {!this.state.tempSite.defaultSite && this.state.tempSiteDevices.length == 0 ?
                                <MenuItem onClick={this.toggleDeleteModal}>Delete</MenuItem>
                                :
                                <span></span>
                            }
                        </Menu>

                        <NotificationContainer />

                    </div>
                    )
                    :
                    (<Redirect push to={{
                        pathname: this.state.redirectPath,
                        state: this.state.redirectProps
                    }} />)
            )
            :
            (
                <Redirect to={'/Login'} />
            );

    }

}

export default withStyles(style)(Sites);

