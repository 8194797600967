import React from 'react';
import "./Tiles.scss";
import "react-isometric-tilemap/build/css/index.css";
import { ProcessPath, FindShortestPathPath } from './TileEngine/PathProcessor';
import { TileSwitcher, TileIdToDescription } from './TileEngine/TileSwitcher';
import { terrainMap } from './TileEngine/TerrainMap';
//import { items } from './TileEngine/ItemsMap';
import { MovableItems } from './TileEngine/MoveableItems';
import { terrain, processTiles, ProcessPipes } from './TileEngine/TileMapProcessor';
import { Button, ButtonGroup } from 'react-bootstrap';


const tileWidth = 100;
let subterrain;
let PipesLayer;
let items;
let bedrock;
let mapWidth = 0;
let mouseCoords = { x: 0, y: 0 }
let xAdjuster = 0;
let yAdjuster = 0;
let labelAnimationHeight = 0;
let reverse = false;
let i;
let j;

const dataLoggers = [];


class IsometricMap extends React.Component {

    constructor(props) {
        super(props);
        this.refs = React.createRef();

        this.loop = this.loop.bind(this);
        this.draw = this.draw.bind(this);
        this.getPosition = this.getPosition.bind(this);
        this.MouseMoveEvent = this.MouseMoveEvent.bind(this);
        mapWidth = terrainMap[0].length
        xAdjuster = Math.ceil(((mapWidth * tileWidth) / 2) / 100) * 100;
        yAdjuster = 200;

        this.state = {
            currentTileToSet: 0, drawTile: "", currentItemTileToSet: 0,
            selectMode: true
        };

        bedrock = new Array(terrainMap.length)

        for (i = 0; i < terrainMap.length; i++) {
            bedrock[i] = new Array(terrainMap[i].length)
            for (j = 0; j < terrainMap[i].length; j++) {
                bedrock[i][j] = 101;
            }
        }

        subterrain = new Array(terrainMap.length);
        PipesLayer = [
            ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""],
            ["", "", "p", "p", "p", "", "", "", ""],
            ["", "", "", "p", "", "", "", "", ""],
            ["", "", "", "p", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""]]

        //new Array(terrainMap.length);
        items =[
            [0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 132, 132, 94, 0, 108, 117, 117, 0],
            [0, 132, 94, 114, 94, 134, 117, 117, 0],
            [0, 115, 134, 0, 94, 0, 0, 0, 0],
            [0, 0, 0, 0, 94, 0, 0, 0, 0],
            [0, 129, 0, 134, 94, 0, 0, 130, 0],
            [0, 130, 0, 0, 0, 0, 0, 0, 0],
            [0, 135, 0, 116, 116, 116, 0, 116, 0],
            [0, 107, 0, 130, 129, 130, 0, 116, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0]]
        //new Array(terrainMap.length);
        for (i = 0; i < terrainMap.length; i++) {
            subterrain[i] = new Array(terrainMap[i].length)
            for (j = 0; j < terrainMap[i].length; j++) {
                subterrain[i][j] = 77;
            }
        }
        //for (var i = 0; i < terrainMap.length; i++) {
        //    PipesLayer[i] = new Array(terrainMap[i].length)
        //    for (var j = 0; j < terrainMap[i].length; j++) {
        //        PipesLayer[i][j] = "";
        //    }
        //}
        //for(var i = 0; i < terrainMap.length; i++) {
        //items[i] = new Array(terrainMap[i].length)
        //for (var j = 0; j < terrainMap[i].length; j++) {
        //    items[i][j] = 0;
        //}
        //}

    }

    loop() {
        if (this.refs.canvas)
            this.draw();

        requestAnimationFrame(this.loop);
    }

    draw() {

        const canvas = this.refs.canvas

        //canvas.addEventListener("mousedown", this.getPosition, false);
        //canvas.addEventListener("mousemove", this.MouseMoveEvent, false);


        const ctx = canvas.getContext("2d")
        let img = this.refs.dirt

        try {

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            //bedrock
            for (i = 0; i < bedrock.length; i++) {
                for (j = 0; j < bedrock[i].length; j++) {
                    if (bedrock[i][j] !== 0) {
                        img = TileSwitcher(bedrock[i][j], this.refs)
                        if (img.complete) {
                            ctx.drawImage(img, xAdjuster + (50 * j) - (50 * i), yAdjuster + (25 * j) + (25 * i) + 30)
                        }
                    }
                }
            }

            //subterrain
            for (i = 0; i < subterrain.length; i++) {
                for (j = 0; j < subterrain[i].length; j++) {
                    if (subterrain[i][j] !== 0) {
                        img = TileSwitcher(subterrain[i][j], this.refs)
                        ctx.drawImage(img, xAdjuster + (50 * j) - (50 * i), yAdjuster + (25 * j) + (25 * i) + 15)
                    }
                }
            }

            //Pipes
            const pipesToDraw = ProcessPipes(PipesLayer)
            for (i = 0; i < pipesToDraw.length; i++) {
                for (j = 0; j < pipesToDraw[i].length; j++) {
                    if (pipesToDraw[i][j] !== 0) {
                        img = TileSwitcher(pipesToDraw[i][j], this.refs)
                        ctx.drawImage(img, xAdjuster + (50 * j) - (50 * i), yAdjuster + (25 * j) + (25 * i) + 15)
                    }
                }
            }

            //terrain
            const terrainToDraw = processTiles(terrainMap)
            for (i = 0; i < terrainToDraw.length; i++) {
                for (j = 0; j < terrainToDraw[i].length; j++) {
                    if (terrainToDraw[i][j] !== 0) {
                        img = TileSwitcher(terrainToDraw[i][j], this.refs)
                        //ctx.globalAlpha = 0.4;
                        ctx.drawImage(img, xAdjuster + (50 * j) - (50 * i), yAdjuster + (25 * j) + (25 * i))



                        if (i === mouseCoords.y && j === mouseCoords.x) {
                            ctx.globalAlpha = 0.5;
                            if (this.state.selectMode) {
                                img = TileSwitcher(106, this.refs)
                            }
                            else {
                                img = TileSwitcher(this.state.currentItemTileToSet, this.refs)
                            }

                            if (img.height > 65) {
                                const extraHeight = img.height - 65
                                ctx.drawImage(img, xAdjuster + (50 * j) - (50 * i), yAdjuster + (25 * j) + (25 * i) - extraHeight)
                            }
                            else {
                                ctx.drawImage(img, xAdjuster + (50 * j) - (50 * i), yAdjuster + (25 * j) + (25 * i))
                            }
                            ctx.globalAlpha = 1;
                        }

                    }
                }
            }

            //moveable Items  
            for (let z = 0; z < MovableItems.length; z++) {
                const item = MovableItems[z];
                const path = item.path

                let realPath = []

                if (item.shortestPath === false) {
                    realPath = ProcessPath(path, xAdjuster, yAdjuster)
                }
                else {

                    realPath = FindShortestPathPath(item.path[0], item.path[1], xAdjuster, yAdjuster)

                    if (item.position === undefined) {
                        item.position = realPath[0];
                    }

                    if (item.stage === realPath.length - 1) {
                        item.path = [item.path[1], item.path[0]];
                        realPath = FindShortestPathPath(item.path[0], item.path[1], xAdjuster, yAdjuster)
                        item.stage = 0;
                    }
                }


                let goingLeft = false;
                let tileToDraw = item.tile1;

                if (item.position === undefined) {
                    item.position = realPath[0];
                }
                if (item.stage < realPath.length - 1) {
                    const NextPosition = realPath[item.stage + 1]
                    if (NextPosition.x < item.position.x) {
                        item.position.x -= item.speed;
                        goingLeft = true;
                    }
                    else {
                        item.position.x += item.speed;
                        goingLeft = false;
                    }
                    if (NextPosition.y < item.position.y) {
                        item.position.y -= item.speed / 2;
                        if (goingLeft) {
                            tileToDraw = item.tile2;
                        }
                        else {
                            tileToDraw = item.tile1;
                        }
                    }
                    else {
                        item.position.y += item.speed / 2;
                        if (goingLeft) {
                            tileToDraw = item.tile3;
                        }
                        if (!goingLeft) {
                            tileToDraw = item.tile4;
                        }
                    }

                    if (NextPosition.x === Math.round(item.position.x) && NextPosition.y === Math.round(item.position.y)) {
                        item.stage++;
                    }
                }
                else if (item.repeat === true) {
                    item.stage = 0;
                    item.position = realPath[0];
                }
                const itemToMove = TileSwitcher(tileToDraw, this.refs)
                ctx.drawImage(itemToMove, item.position.x, item.position.y)
            }

            ctx.globalAlpha = 1;
            //items
            for (i = 0; i < items.length; i++) {
                for (j = 0; j < items[i].length; j++) {
                    if (items[i][j] !== 0) {
                        img = TileSwitcher(items[i][j], this.refs)

                        if (img.height > 65) {
                            const extraHeight = img.height - 65
                            ctx.drawImage(img, xAdjuster + (50 * j) - (50 * i), yAdjuster + (25 * j) + (25 * i) - extraHeight)
                        }
                        else {
                            ctx.drawImage(img, xAdjuster + (50 * j) - (50 * i), yAdjuster + (25 * j) + (25 * i))
                        }

                        //if the item is a text logger, draw the electromagnetic waves
                        if (items[i][j] === 134 || items[i][j] === 135) {
                            let dataLogger = dataLoggers.find(function (element) {
                                return element.x === j && element.y === i;
                            });
                            if (dataLogger === undefined) {
                                dataLogger = { x: j, y: i, r1: 0, r2: 16, r3: 32 };
                                dataLoggers.push(dataLogger)
                            }

                            let startAngle = Math.PI * 1.25;
                            let endAngle = 1.75 * Math.PI
                            let xMod = 50;
                            let yMod = 0;
                            if (Math.round(dataLogger.r1) === 44) {
                                dataLogger.r1 = 0;
                            }
                            if (Math.round(dataLogger.r2) === 44) {
                                dataLogger.r2 = 0;
                            }
                            if (Math.round(dataLogger.r3) === 44) {
                                dataLogger.r3 = 0;
                            }

                            dataLogger.r1 += 0.4;
                            dataLogger.r2 += 0.4;
                            dataLogger.r3 += 0.4;

                            //                        if (items[i][j] === 134) {

                            //                        }
                            if (items[i][j] === 135) {


                                startAngle = Math.PI * 0.5;
                                endAngle = 1 * Math.PI;

                                xMod = 125;
                                yMod = -85


                            }

                            ctx.lineWidth = 5;
                            ctx.strokeStyle = "#4286f4";

                            ctx.beginPath();
                            ctx.arc(xAdjuster + (50 * j) - (50 * i) + xMod, yAdjuster + (25 * j) + (25 * i) + yMod, dataLogger.r1, startAngle, endAngle);
                            ctx.stroke();

                            ctx.beginPath();
                            ctx.arc(xAdjuster + (50 * j) - (50 * i) + xMod, yAdjuster + (25 * j) + (25 * i) + yMod, dataLogger.r2, startAngle, endAngle);
                            ctx.stroke();

                            ctx.beginPath();
                            ctx.arc(xAdjuster + (50 * j) - (50 * i) + xMod, yAdjuster + (25 * j) + (25 * i) + yMod, dataLogger.r3, startAngle, endAngle);
                            ctx.stroke();
                        }




                    }
                }
            }

            //Labels
            for (i = 0; i < items.length; i++) {
                for (j = 0; j < items[i].length; j++) {
                    if (items[i][j] !== 0 && items[i][j] !== 113) {
                        img = TileSwitcher(items[i][j], this.refs)

                        if (i === mouseCoords.y && j === mouseCoords.x) {
                            if (items[i][j] !== 0) {

                                const x = j
                                const y = i


                                if (labelAnimationHeight === 50) {
                                    reverse = true;
                                }
                                if (labelAnimationHeight === 0) {
                                    reverse = false;
                                }


                                labelAnimationHeight = reverse ? labelAnimationHeight - 0.05 : labelAnimationHeight + 0.05;

                                const adjuster = Math.sin(labelAnimationHeight)

                                const realCoord = { x: (xAdjuster + (50 * x) - (50 * y)), y: (yAdjuster + (25 * x) + (25 * y) + adjuster * 15) }



                                const circleWidth = 100;


                                ctx.fillStyle = 'white';
                                ctx.beginPath();
                                ctx.arc(realCoord.x + circleWidth / 2, realCoord.y - circleWidth * 1.5, circleWidth, 0, 2 * Math.PI);
                                ctx.stroke();
                                ctx.fill();

                                ctx.fillStyle = '#005EB8';
                                ctx.beginPath();
                                ctx.arc(realCoord.x + circleWidth / 2, realCoord.y - circleWidth * 1.5, circleWidth - 5, 0, 2 * Math.PI);
                                ctx.stroke();
                                ctx.fill();

                                img = this.refs.ashlogo
                                ctx.drawImage(img, realCoord.x + 8, realCoord.y - circleWidth * 2.3)

                                ctx.fillStyle = 'white';
                                ctx.font = "30px Arial";
                                ctx.textAlign = "center";
                                ctx.fillText(TileIdToDescription(items[i][j]), realCoord.x + circleWidth / 2, realCoord.y - circleWidth * 1.4);
                            }
                        }
                    }
                }
            }
        }
        catch (error) {
//            console.log(error);
        }


    }

    //Get Mouse Position
    getMousePos(canvas, evt) {
        const rect = canvas.getBoundingClientRect();
        return {
            x: evt.clientX - rect.left,
            y: evt.clientY - rect.top
        };
    }

    getPosition(event) {
        if (event !== undefined) {

            const canvas = this.refs.canvas
            const mousePos = this.getMousePos(canvas, event);
            const x = mousePos.x;
            const y = mousePos.y;
            const tiles = [];



            for (i = 0; i < terrainMap.length; i++) {
                for (j = 0; j < terrainMap[i].length; j++) {
                    const tileX = xAdjuster + (50 * j) - (50 * i);
                    const tileY = yAdjuster + (25 * j) + (25 * i);
                    const centreX = tileX + 50;
                    const centreY = tileY + 25;
                    const distanceToCentre = Math.pow((Math.pow((x - centreX), 2) + Math.pow((y - centreY), 2)), 0.5)
                    tiles.push({ x: j, y: i, d: distanceToCentre });
                }
            }

            const minD = Math.min(...tiles.map(d => d.d));
            const tile = tiles.find(x => x.d === minD);

            //if drawing tile is set
            if (this.state.drawTile !== "") {

                if (this.state.drawTile !== "p") {
                    if (this.state.drawTile !== "_") {
                        terrainMap[tile.y][tile.x] = "";
                    }
                    terrainMap[tile.y][tile.x] = this.state.drawTile

                    if (items[tile.y][tile.x] !== 0) {
                        items[tile.y][tile.x] = 0
                    }
                } else {
                    PipesLayer[tile.y][tile.x] = this.state.drawTile
                }
            }

            if (this.state.currentTileToSet !== 0) {
                terrain[tile.y][tile.x] = this.state.currentTileToSet
            }

            if (this.state.currentItemTileToSet !== 0) {
                console.log(terrainMap[tile.y][tile.x])
                items[tile.y][tile.x] = this.state.currentItemTileToSet
                if (terrainMap[tile.y][tile.x] === "r") {
                    terrainMap[tile.y][tile.x] = "d"
                }
                if (terrainMap[tile.y][tile.x] === "_") {
                    console.log(PipesLayer[tile.y][tile.x])
                    PipesLayer[tile.y][tile.x] = 113
                }
            }


            const tileId = terrain[tile.y][tile.x]

            if (this.state.selectMode === true) {
                const modal = this.refs.myModal
                modal.style.display = "block";
                this.refs.modalImage.src = TileSwitcher(tileId, this.refs).src;
            }

        }

    }

    MouseMoveEvent(event) {
        if (event !== undefined) {
            const canvas = this.refs.canvas
            const mousePos = this.getMousePos(canvas, event);
            const x = mousePos.x;
            const y = mousePos.y;
            const tiles = [];

            for (i = 0; i < terrainMap.length; i++) {
                for (j = 0; j < terrainMap[i].length; j++) {
                    const tileX = xAdjuster + (50 * j) - (50 * i);
                    const tileY = yAdjuster + (25 * j) + (25 * i);
                    const centreX = tileX + 50;
                    const centreY = tileY + 25;
                    const distanceToCentre = Math.pow((Math.pow((x - centreX), 2) + Math.pow((y - centreY), 2)), 0.5)
                    tiles.push({ x: j, y: i, d: distanceToCentre });
                }
            }

            const minD = Math.min(...tiles.map(d => d.d));
            const tile = tiles.find(x => x.d === minD);

            mouseCoords = { x: tile.x, y: tile.y };
        }

    }

    componentDidMount() {
        requestAnimationFrame(this.loop);
    }

    render() {
        return (
            <div className="center">




                {/* <!-- The Modal --> */}
                <div ref="myModal" id="myModal" className="modal">

                    {/* <!-- The Close Button --> */}
                    <span className="close" onClick={() => { this.refs.myModal.style.display = "none" }}>&times;</span>

                    {/* <!-- Modal Content (The Image) --> */}
                    <img alt="" ref="modalImage" className="modal-content" id="img01" />

                    {/* <!-- Modal Caption (Image Text) --> */}
                    <div id="caption"></div>
                </div>

                <ButtonGroup vertical className="float-left" style={{ display: 'none' }}>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 0, drawTile: "", selectMode: true }) }}>Select</Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 113, drawTile: "", selectMode: false }) }}><img alt="" src={require("./images/bulldozer.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 0, drawTile: "_", selectMode: false }) }}><img alt="" src={require("./images/spade.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 0, drawTile: "r", selectMode: false }) }} ><img alt="" src={require("./images/roadEW.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 0, drawTile: "gr", selectMode: false }) }} ><img alt="" src={require("./images/grass.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 0, drawTile: "rb", selectMode: false }) }} ><img alt="" src={require("./images/riverBankedEW.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 0, drawTile: "rv", selectMode: false }) }} ><img alt="" src={require("./images/riverEW.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 0, drawTile: "b", selectMode: false }) }} ><img alt="" src={require("./images/beachES.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 0, drawTile: "w", selectMode: false }) }} ><img alt="" src={require("./images/waterNW.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 0, drawTile: "d", selectMode: false }) }} ><img alt="" src={require("./images/dirtDouble.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 0, drawTile: "s", selectMode: false }) }} ><img alt="" src={require("./images/beach.png")} /></Button>
                </ButtonGroup>
                <ButtonGroup vertical className="float-left" style={{ display: 'none' }}>

                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 107, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/building.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 129, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/building3.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 130, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/building4.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 114, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/boreHole.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 115, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/waterTower.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 116, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/building2.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 117, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/treatmentPond.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 108, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/office.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 91, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/coniferAltShort.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 92, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/coniferAltTall.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 93, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/coniferShort.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 94, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/coniferTall.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 95, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/treeAltShort.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 96, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/treeAltTall.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 132, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/pineForest.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 97, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/treeShort.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 98, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/treeTall.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 99, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/tank.png")} /></Button>

                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 131, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/dam.png")} /></Button>

                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 0, drawTile: "p", selectMode: false }) }} ><img alt="" src={require("./images/pipe1.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 134, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/dataLogger.png")} /></Button>
                    <Button onClick={() => { this.setState({ currentTileToSet: 0, currentItemTileToSet: 135, drawTile: "", selectMode: false }) }} ><img alt="" src={require("./images/ashridgeHQ.png")} /></Button>
                </ButtonGroup>



                <canvas ref="canvas" id="canvas" width={1000} height={1000} style={{ height: '100vh' }} />




                <div className="terrainVisibility" ref="terrainTiles" style={{ display: 'none' }}>
                    {/* water */}
                    <img className={this.state.currentTileToSet === 3 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 3, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="waterN" src={require("./images/waterN.png").default} />
                    <img className={this.state.currentTileToSet === 4 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 4, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="waterNE" src={require("./images/waterNE.png").default} />
                    <img className={this.state.currentTileToSet === 5 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 5, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="waterE" src={require("./images/waterE.png").default} />
                    <img className={this.state.currentTileToSet === 6 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 6, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="waterES" src={require("./images/waterES.png").default} />
                    <img className={this.state.currentTileToSet === 7 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 7, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="waterS" src={require("./images/waterS.png").default} />
                    <img className={this.state.currentTileToSet === 8 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 8, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="waterSW" src={require("./images/waterSW.png").default} />
                    <img className={this.state.currentTileToSet === 9 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 9, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="waterW" src={require("./images/waterW.png").default} />
                    <img className={this.state.currentTileToSet === 10 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 10, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="waterNW" src={require("./images/waterNW.png").default} />
                    <img className={this.state.currentTileToSet === 11 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 11, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="water" src={require("./images/water.png").default} />
                    <img className={this.state.currentTileToSet === 12 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 12, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="waterCornerES" src={require("./images/waterCornerES.png").default} />
                    <img className={this.state.currentTileToSet === 13 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 13, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="waterCornerNE" src={require("./images/waterCornerNE.png").default} />
                    <img className={this.state.currentTileToSet === 14 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 14, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="waterCornerNW" src={require("./images/waterCornerNW.png").default} />
                    <img className={this.state.currentTileToSet === 15 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 15, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="waterCornerSW" src={require("./images/waterCornerSW.png").default} />

                    {/* road */}
                    <img className={this.state.currentTileToSet === 16 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 16, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="road" src={require("./images/road.png").default} />
                    <img className={this.state.currentTileToSet === 17 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 17, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadES" src={require("./images/roadES.png").default} />
                    <img className={this.state.currentTileToSet === 18 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 18, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadEW" src={require("./images/roadEW.png").default} />
                    <img className={this.state.currentTileToSet === 19 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 19, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadHill2E" src={require("./images/roadHill2E.png").default} />
                    <img className={this.state.currentTileToSet === 20 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 20, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadHill2N" src={require("./images/roadHill2N.png").default} />
                    <img className={this.state.currentTileToSet === 21 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 21, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadHill2S" src={require("./images/roadHill2S.png").default} />
                    <img className={this.state.currentTileToSet === 22 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 22, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadHill2W" src={require("./images/roadHill2W.png").default} />
                    <img className={this.state.currentTileToSet === 23 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 23, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadHillE" src={require("./images/roadHillE.png").default} />
                    <img className={this.state.currentTileToSet === 24 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 24, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadHillN" src={require("./images/roadHillN.png").default} />
                    <img className={this.state.currentTileToSet === 25 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 25, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadHillS" src={require("./images/roadHillS.png").default} />
                    <img className={this.state.currentTileToSet === 26 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 26, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadHillW" src={require("./images/roadHillW.png").default} />
                    <img className={this.state.currentTileToSet === 27 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 27, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadNE" src={require("./images/roadNE.png").default} />
                    <img className={this.state.currentTileToSet === 28 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 28, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadNS" src={require("./images/roadNS.png").default} />
                    <img className={this.state.currentTileToSet === 29 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 29, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadNW" src={require("./images/roadNW.png").default} />
                    <img className={this.state.currentTileToSet === 30 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 30, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="roadSW" src={require("./images/roadSW.png").default} />

                    <img className={this.state.currentTileToSet === 31 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 31, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="lotE" src={require("./images/lotE.png").default} />
                    <img className={this.state.currentTileToSet === 32 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 32, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="lotES" src={require("./images/lotES.png").default} />
                    <img className={this.state.currentTileToSet === 33 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 33, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="lotN" src={require("./images/lotN.png").default} />
                    <img className={this.state.currentTileToSet === 34 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 34, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="lotNE" src={require("./images/roadSW.png").default} />
                    <img className={this.state.currentTileToSet === 35 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 35, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="lotNW" src={require("./images/lotNW.png").default} />
                    <img className={this.state.currentTileToSet === 36 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 36, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="lotS" src={require("./images/lotS.png").default} />
                    <img className={this.state.currentTileToSet === 37 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 37, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="lotSW" src={require("./images/lotSW.png").default} />
                    <img className={this.state.currentTileToSet === 38 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 38, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="lotW" src={require("./images/lotW.png").default} />

                    <img className={this.state.currentTileToSet === 39 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 39, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="bridgeEW" src={require("./images/bridgeEW.png").default} />
                    <img className={this.state.currentTileToSet === 40 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 40, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="bridgeNS" src={require("./images/bridgeNS.png").default} />
                    <img className={this.state.currentTileToSet === 41 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 41, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="crossroad" src={require("./images/crossroad.png").default} />
                    <img className={this.state.currentTileToSet === 42 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 42, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="crossroadESW" src={require("./images/crossroadESW.png").default} />
                    <img className={this.state.currentTileToSet === 43 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 43, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="crossroadNES" src={require("./images/crossroadNES.png").default} />
                    <img className={this.state.currentTileToSet === 44 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 44, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="crossroadNEW" src={require("./images/crossroadNEW.png").default} />
                    <img className={this.state.currentTileToSet === 45 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 45, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="crossroadNSW" src={require("./images/crossroadNSW.png").default} />
                    <img className={this.state.currentTileToSet === 46 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 46, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="endE" src={require("./images/endE.png").default} />
                    <img className={this.state.currentTileToSet === 47 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 47, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="endN" src={require("./images/endN.png").default} />
                    <img className={this.state.currentTileToSet === 48 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 48, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="endS" src={require("./images/endS.png").default} />
                    <img className={this.state.currentTileToSet === 49 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 49, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="endW" src={require("./images/endW.png").default} />
                    <img className={this.state.currentTileToSet === 50 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 50, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="exitE" src={require("./images/exitE.png").default} />
                    <img className={this.state.currentTileToSet === 51 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 51, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="exitN" src={require("./images/exitN.png").default} />
                    <img className={this.state.currentTileToSet === 52 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 52, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="exitS" src={require("./images/exitS.png").default} />
                    <img className={this.state.currentTileToSet === 53 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 53, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="exitW" src={require("./images/exitW.png").default} />

                    {/* river */}
                    <img className={this.state.currentTileToSet === 54 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 54, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverBankedES" src={require("./images/riverBankedES.png").default} />
                    <img className={this.state.currentTileToSet === 55 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 55, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverBankedEW" src={require("./images/riverBankedEW.png").default} />
                    <img className={this.state.currentTileToSet === 56 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 56, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverBankedNE" src={require("./images/riverBankedNE.png").default} />
                    <img className={this.state.currentTileToSet === 57 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 57, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverBankedNS" src={require("./images/riverBankedNS.png").default} />
                    <img className={this.state.currentTileToSet === 58 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 58, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverBankedNW" src={require("./images/riverBankedNW.png").default} />
                    <img className={this.state.currentTileToSet === 59 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 59, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverBankedSW" src={require("./images/riverBankedSW.png").default} />
                    <img className={this.state.currentTileToSet === 60 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 60, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverES" src={require("./images/riverES.png").default} />
                    <img className={this.state.currentTileToSet === 61 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 61, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverEW" src={require("./images/riverEW.png").default} />
                    <img className={this.state.currentTileToSet === 62 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 62, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverNE" src={require("./images/riverNE.png").default} />
                    <img className={this.state.currentTileToSet === 63 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 63, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverNS" src={require("./images/riverNS.png").default} />
                    <img className={this.state.currentTileToSet === 64 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 64, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverNW" src={require("./images/riverNW.png").default} />
                    <img className={this.state.currentTileToSet === 65 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 65, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverSW" src={require("./images/riverSW.png").default} />

                    {/* grass */}
                    <img className={this.state.currentTileToSet === 66 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 66, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="grass" src={require("./images/grass.png").default} />
                    <img className={this.state.currentTileToSet === 67 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 67, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="grassWhole" src={require("./images/grassWhole.png").default} />
                    <img className={this.state.currentTileToSet === 68 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 68, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="hillE" src={require("./images/hillE.png").default} />
                    <img className={this.state.currentTileToSet === 69 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 69, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="hillES" src={require("./images/hillES.png").default} />
                    <img className={this.state.currentTileToSet === 70 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 70, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="hillN" src={require("./images/hillN.png").default} />
                    <img className={this.state.currentTileToSet === 71 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 71, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="hillNE" src={require("./images/hillNE.png").default} />
                    <img className={this.state.currentTileToSet === 72 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 72, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="hillNW" src={require("./images/hillNW.png").default} />
                    <img className={this.state.currentTileToSet === 73 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 73, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="hillS" src={require("./images/hillS.png").default} />
                    <img className={this.state.currentTileToSet === 74 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 74, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="hillSW" src={require("./images/hillSW.png").default} />
                    <img className={this.state.currentTileToSet === 75 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 75, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="hillW" src={require("./images/hillW.png").default} />

                    {/* dirt */}
                    <img className={this.state.currentTileToSet === 76 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 76, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="dirt" src={require("./images/dirt.png").default} />
                    <img className={this.state.currentTileToSet === 77 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 77, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="dirtDouble" src={require("./images/dirtDouble.png").default} />

                    {/* beach */}
                    <img className={this.state.currentTileToSet === 78 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 78, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beach" src={require("./images/beach.png").default} />
                    <img className={this.state.currentTileToSet === 79 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 79, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beachCornerES" src={require("./images/beachCornerES.png").default} />
                    <img className={this.state.currentTileToSet === 80 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 80, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beachCornerNE" src={require("./images/beachCornerNE.png").default} />
                    <img className={this.state.currentTileToSet === 81 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 81, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beachCornerNW" src={require("./images/beachCornerNW.png").default} />
                    <img className={this.state.currentTileToSet === 82 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 82, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beachCornerSW" src={require("./images/beachCornerSW.png").default} />
                    <img className={this.state.currentTileToSet === 83 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 83, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beachE" src={require("./images/beachE.png").default} />
                    <img className={this.state.currentTileToSet === 84 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 84, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beachES" src={require("./images/beachES.png").default} />
                    <img className={this.state.currentTileToSet === 85 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 85, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beachN" src={require("./images/beachN.png").default} />
                    <img className={this.state.currentTileToSet === 86 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 86, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beachNE" src={require("./images/beachNE.png").default} />
                    <img className={this.state.currentTileToSet === 87 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 87, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beachNW" src={require("./images/beachNW.png").default} />
                    <img className={this.state.currentTileToSet === 88 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 88, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beachS" src={require("./images/beachS.png").default} />
                    <img className={this.state.currentTileToSet === 89 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 89, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beachSW" src={require("./images/beachSW.png").default} />
                    <img className={this.state.currentTileToSet === 90 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 90, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="beachW" src={require("./images/beachW.png").default} />

                    <img className={this.state.currentTileToSet === 100 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 100, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="empty" src={require("./images/empty.png").default} />

                    <img className={this.state.currentTileToSet === 101 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 101, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="rock" src={require("./images/rock.png").default} />

                    <img className={this.state.currentTileToSet === 102 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 102, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverBridge1" src={require("./images/riverBridge1.png").default} />
                    <img className={this.state.currentTileToSet === 103 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 103, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverBridge2" src={require("./images/riverBridge2.png").default} />
                    <img className={this.state.currentTileToSet === 104 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 104, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverBridge3" src={require("./images/riverBridge3.png").default} />
                    <img className={this.state.currentTileToSet === 105 ? "selected" : "unselected"} onClick={() => { this.setState({ currentTileToSet: 105, currentItemTileToSet: 0, drawTile: "" }) }} alt="" ref="riverBridge4" src={require("./images/riverBridge4.png").default} />
                </div>

                <div className="itemsVisibility" ref="itemsTiles" style={{ display: 'none' }}>
                    {/* conifer trees */}
                    <img className={this.state.currentItemTileToSet === 91 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 91, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="coniferAltShort" src={require("./images/coniferAltShort.png").default} />
                    <img className={this.state.currentItemTileToSet === 92 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 92, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="coniferAltTall" src={require("./images/coniferAltTall.png").default} />
                    <img className={this.state.currentItemTileToSet === 93 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 93, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="coniferShort" src={require("./images/coniferShort.png").default} />
                    <img className={this.state.currentItemTileToSet === 94 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 94, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="coniferTall" src={require("./images/coniferTall.png").default} />

                    {/* non conifer trees */}
                    <img className={this.state.currentItemTileToSet === 95 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 95, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="treeAltShort" src={require("./images/treeAltShort.png").default} />
                    <img className={this.state.currentItemTileToSet === 96 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 96, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="treeAltTall" src={require("./images/treeAltTall.png").default} />
                    <img className={this.state.currentItemTileToSet === 97 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 97, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="treeShort" src={require("./images/treeShort.png").default} />
                    <img className={this.state.currentItemTileToSet === 98 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 98, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="treeTall" src={require("./images/treeTall.png").default} />

                    <img className={this.state.currentItemTileToSet === 99 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 99, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="tank" src={require("./images/tank.png").default} />


                    <img alt="" ref="selectedGrid" src={require("./images/selectedGrid.png").default} />

                    <img className={this.state.currentItemTileToSet === 107 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 107, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="building" src={require("./images/building.png").default} />
                    <img className={this.state.currentItemTileToSet === 108 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 108, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="office" src={require("./images/office.png").default} />

                    <img className={this.state.currentItemTileToSet === 109 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 109, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="car1" src={require("./images/car1.png").default} />
                    <img className={this.state.currentItemTileToSet === 110 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 110, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="car2" src={require("./images/car2.png").default} />
                    <img className={this.state.currentItemTileToSet === 111 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 111, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="car3" src={require("./images/car3.png").default} />
                    <img className={this.state.currentItemTileToSet === 112 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 112, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="car4" src={require("./images/car4.png").default} />
                    <img className={this.state.currentItemTileToSet === 113 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 113, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="emptyItem" src={require("./images/empty.png").default} />

                    <img className={this.state.currentItemTileToSet === 114 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 114, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="boreHole" src={require("./images/boreHole.png").default} />
                    <img className={this.state.currentItemTileToSet === 115 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 115, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="waterTower" src={require("./images/waterTower.png").default} />
                    <img className={this.state.currentItemTileToSet === 116 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 116, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="building2" src={require("./images/building2.png").default} />
                    <img className={this.state.currentItemTileToSet === 117 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 117, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="treatmentPond" src={require("./images/treatmentPond.png").default} />

                    <img className={this.state.currentItemTileToSet === 118 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 118, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="pipe1" src={require("./images/pipe1.png").default} />
                    <img className={this.state.currentItemTileToSet === 119 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 119, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="pipe2" src={require("./images/pipe2.png").default} />
                    <img className={this.state.currentItemTileToSet === 120 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 120, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="pipe3" src={require("./images/pipe3.png").default} />
                    <img className={this.state.currentItemTileToSet === 121 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 121, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="pipe4" src={require("./images/pipe4.png").default} />
                    <img className={this.state.currentItemTileToSet === 122 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 122, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="pipe5" src={require("./images/pipe5.png").default} />
                    <img className={this.state.currentItemTileToSet === 123 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 123, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="pipe6" src={require("./images/pipe6.png").default} />
                    <img className={this.state.currentItemTileToSet === 124 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 124, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="pipe7" src={require("./images/pipe7.png").default} />
                    <img className={this.state.currentItemTileToSet === 125 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 124, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="pipe8" src={require("./images/pipe8.png").default} />
                    <img className={this.state.currentItemTileToSet === 126 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 126, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="pipe9" src={require("./images/pipe9.png").default} />
                    <img className={this.state.currentItemTileToSet === 127 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 127, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="pipe10" src={require("./images/pipe10.png").default} />
                    <img className={this.state.currentItemTileToSet === 128 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 128, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="pipe11" src={require("./images/pipe11.png").default} />

                    <img className={this.state.currentItemTileToSet === 129 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 129, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="building3" src={require("./images/building3.png").default} />
                    <img className={this.state.currentItemTileToSet === 130 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 130, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="building4" src={require("./images/building4.png").default} />

                    <img className={this.state.currentItemTileToSet === 131 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 131, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="dam" src={require("./images/dam.png").default} />
                    <img className={this.state.currentItemTileToSet === 132 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 132, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="pineForest" src={require("./images/pineForest.png").default} />
                    <img className={this.state.currentItemTileToSet === 133 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 133, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="ashlogo" src={require("./images/ashlogo.png").default} />

                    <img className={this.state.currentItemTileToSet === 134 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 134, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="dataLogger" src={require("./images/dataLogger.png").default} />

                    <img className={this.state.currentItemTileToSet === 135 ? "selected" : "unselected"} onClick={() => { this.setState({ currentItemTileToSet: 135, currentTileToSet: 0, drawTile: "" }) }} alt="" ref="ashridgeHQ" src={require("./images/ashridgeHQ.png").default} />
                </div>
            </div>
        )
    }
}


 

export default IsometricMap;



