import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';

//Moment date/time formatting
//https://momentjs.com/docs/
import Moment from 'moment';

import ClipLoader from "react-spinners/ClipLoader";
//modal window imports
import Grid from '@mui/material/Grid';
import Modal from 'react-bootstrap/Modal'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { TextField, Select, Switch, Menu, MenuItem, Button, FormControl } from '@mui/material';
import FilePicker from 'components/File/FilePicker';
import BackupIcon from '@mui/icons-material/Backup';
import Chip from '@mui/material/Chip';
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import makeAnimated from 'react-select/animated';
import { CreateUrl, CallGetAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";
import zIndex from '@mui/material/styles/zIndex';


const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);
let fileReader: FileReader;

interface Download {
    id: number;
    title: string;
    category: string;
    filename: string;
    filetype: string;
    product: string;
    model: string;
    adminonly: boolean;
    description: string;
    filecontent: string | null;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
}


interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    modal: boolean;
    deleteModal: boolean;
    colour: string;
    editDownload: boolean;
    tempDownload: Download;
    anchorEl: HTMLElement | null;
    modalTitle: string;
    allowSave: boolean;
    file: ArrayBuffer;
    fileDownloadUrl: string;
}

export class Downloads extends Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;
    dofileDownload: HTMLAnchorElement | null;

    constructor(props: Readonly<Props>) {

        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: []
        });

        this.state = {
            loading: true,
            tableHidden: false,
            visible: false,
            modal: false,
            deleteModal: false,
            colour: '',
            editDownload: false,
            tempDownload: {
                id: 0,
                title: "",
                category: "",
                filename: "No file selected",
                filetype: "",
                product: window.location.hostname.includes('zonelog.net') ? "CHFL" : "",
                model: "All",
                adminonly: false,
                description: "",
                filecontent: null
            },
            anchorEl: null,
            authorized: true,
            modalTitle: "",
            allowSave: false,
            file: new ArrayBuffer(0),
            fileDownloadUrl: "",
        };

        this.gridRef = React.createRef();
        this.store.clear();

    }



    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createDownloadNotification = (): void => {
        NotificationManager.success('File download in progress', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (err: string): void => {
        NotificationManager.error(err, 'Error creating new record', 5000);
    };

    componentDidMount(): void {
        if (sessionStorage.getItem('portalUpdate') === 'true') {
            this.clearState()
        }
        this.reloadData(null);

    }

    clearState = (): void => {
        this.gridRef.current?.instance.state(null);
    }

    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {
        const me = this;

      
        CallGetAPI(CreateUrl('/api/aquaguard/Downloads'), {})
            .then(data => {
                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent
                    const records = [];
                    for (let i = 0; i < data.length; i++) {
                        records.push({ type: 'insert', data: data[i], index: i });
                    }

                    me.store.push(records);

                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: true,
                        tableHidden: true,
                        loading: false

                    })
            });


    }

   

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    //new row
    newDownload = (): void => {
        this.setState({
            modal: true,
            editDownload: false,
            tempDownload: {
                id: 0,
                title: "",
                category: "",
                filename: "No file selected",
                filetype: "",
                product: "",
                model: "All",
                adminonly: false,
                description: "",
                filecontent: null
            },
            modalTitle: "New Download",
            allowSave: false,
        });

    }
    //edit row
    editDownload = (): void => {
        this.setState({
            editDownload: true,
            modal: !this.state.modal,
            anchorEl: null,
            modalTitle: "Edit Download",
            allowSave: true,
        });
    }

    //save changes, for both edit and new
    saveDownload = (): void => {

        const me = this;

        this.setState({
            loading: true,
        });

        //********************
        //send model to API

        const tmpDownload = Object.assign({}, this.state.tempDownload);
        const content = this.state.file as ArrayBuffer;
        if (content.byteLength > 0) {
            tmpDownload.filecontent = btoa(new Uint8Array(content)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
        }
          
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(tmpDownload)
        };

        CallGetAPI(CreateUrl('/api/aquaguard/Downloads'), requestOptions)
            .then(data => {
                if (data.status === undefined) {
                    const dl: Download = me.state.tempDownload;
                    dl.id = data.id

                    if (this.state.editDownload === false) { //adding a new Download
                        me.store.push([{ type: 'insert', key: dl.id, data: dl }]);
                    }
                    else {
                        me.store.push([{ type: 'update', key: me.state.tempDownload.id, data: me.state.tempDownload }]);
                    }

                    me.setState({
                        tempDownload: dl,
                    });

                    me.createSuccessNotification();
                }
                else {
                    me.createFailedInsertErrorNotification(data.statusText);
                    console.log(data);
                }
                me.toggleModal();
                me.setState({
                    file: new ArrayBuffer(0),       // Clear any existing file for upload
                    loading: false,
                });
            })
            .catch (function (error) {
                me.createFailedInsertErrorNotification(error.statusText);
                        console.log(error);
                        me.toggleModal();
                        me.setState({
                            loading: false,
                        });
                    });

    }

    checkRemoveDownload = (): void => {
        this.setState({
            anchorEl: null,
            deleteModal: true
        });
    }

    removeDownload = (): void => {

        const me = this;

        CallDeleteAPI(CreateUrl('/api/Aquaguard/Downloads/' + this.state.tempDownload.id), {})
            .then(async response => {
                if (response.status == 200) {

                    me.createSuccessNotification()

                    me.store.push([{ type: 'remove', key: me.state.tempDownload.id }]);

                    me.setState({
                        deleteModal: false,
                    });
                }
                else {
                    me.setState({
                        deleteModal: false,
                    });
                }
                console.log(response)

            })
            .catch(function (error) {
                me.createErrorNotification()
                console.log(error)
                me.setState({
                    deleteModal: false,
                });
            });
    }

    //do nothing, close the modal
    cancel = (): void => {
        this.setState({
            anchorEl: null
        });
        if (this.state.modal)
            this.toggleModal();

    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    // file handling
    readFile = (file: Array<File>): void => {
        fileReader = new FileReader();
        fileReader.onloadend = this.handleFileRead;
        fileReader.readAsArrayBuffer(file[0]);

        const tmpDownload = this.state.tempDownload;
        tmpDownload.filename = file[0].name;
        const namebits = file[0].name.split(".");
        tmpDownload.filetype = namebits[namebits.length - 1].toUpperCase();
        this.setState(
            {
                tempDownload: tmpDownload,
            });
    }

    handleFileRead = (e: any): void => {
        const content = fileReader.result as ArrayBuffer;
        this.setState({
            file: content,    
            allowSave: true,
        });
    };

    removeFile = (): void => {
        const tmpDownload = this.state.tempDownload;
        tmpDownload.filename = 'No file selected';
        tmpDownload.filetype = "";
        this.setState(
            {
                tempDownload: tmpDownload,
                allowSave: false,
            });
    }

    // Download content as file
    doDownload = (): void => {
        CallGetAPI(CreateUrl('/api/aquaguard/Downloads/' + this.state.tempDownload.id), {})
            .then(data => {
                if (data.fileContent.length > 0) {

                    const blob = this.b64toBlob(data.fileContent);
                    const fileDownloadUrl = URL.createObjectURL(blob);
                    this.setState({ fileDownloadUrl: fileDownloadUrl, anchorEl: null },
                        () => {
                            this.dofileDownload?.click();
                            URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.
                            this.setState({ fileDownloadUrl: "" })
                        })

                    this.createDownloadNotification();
                }
                else {
                    console.log("No file content");
                }
            })
            .catch(function (err) {
                console.log(err);
            });

    }

    b64toBlob = (b64Data: any, contentType = '', sliceSize = 512): Blob => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    //field changed events---
    titleChanged = (event: any): void => {
        const tempDownload = this.state.tempDownload;
        tempDownload.title = event.target.value;
        this.setState( {
            tempDownload: tempDownload,
            allowSave: this.checkSave(event.target.value, this.state.tempDownload.category),
            });
    }

    categoryChanged = (event: any): void => {
        const tempDownload = this.state.tempDownload;
        tempDownload.category = event.target.value;
        this.setState({
            tempDownload: tempDownload,
            allowSave: this.checkSave(event.target.value, this.state.tempDownload.category),
        });
    }

    productChanged = (event: any): void => {
        const tempDownload = this.state.tempDownload;
        tempDownload.product = event.target.value;
        this.setState({
            tempDownload: tempDownload,
        });
    }

    modelChanged = (event: any): void => {
        const tempDownload = this.state.tempDownload;
        tempDownload.model = event.target.value;
        this.setState({
            tempDownload: tempDownload,
        });
    }

    descriptionChanged = (event: any): void => {
        const tempDownload = this.state.tempDownload;
        tempDownload.description = event.target.value;
        this.setState({
            tempDownload: tempDownload,
        });
    }
    handleToggleChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: boolean = e.target.checked;
        const tempDownload = this.state.tempDownload;
        tempDownload[inputName] = inputValue;

        this.setState({ tempDownload: tempDownload });

    }

    checkSave = (title: string, category: string): boolean => {

        return title != ""
            && category != "";
    }

    //-------------------------

    handleClick(event: any, id: number): void {
        this.store.byKey(id)
            .then((dl) => {

                this.setState(
                    {
                        tempDownload: dl,
                        anchorEl: event.currentTarget,
                    });
                },
                (error) => {
                    console.log(error);
                });
    }

    moreRender(Task: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, Task.data.id); }}><MoreHorizIcon /></IconButton>
        );
    }

    descRender(item: any): React.ReactNode {
        return (
            <div style={{ width: (item.column.width - 20), height: 100, whiteSpace: "normal", overflowWrap: 'break-word', overflowY: "scroll"  }}>{item.value}</div>
        )
    }



    render(): React.ReactNode {
        const { classes } = this.props;


        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                <div>

                    <Modal
                        show={this.state.modal}
                            onHide={(): void => this.toggleModal()}
                        dialogClassName="modal-100w"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                        >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        <FormControl fullWidth >
                                            <TextField
                                                id="outlined-input-name"
                                                label="Title"
                                                value={this.state.tempDownload.title}
                                                onChange={this.titleChanged}
                                                variant="outlined"
                                                />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={6} >
                                    <div style={{ padding: 10 }}>
                                        Category:
                                        <Select
                                            id="outlined-category-name"
                                            style={{ margin: 0, paddingBottom: 0 }}
                                            value={this.state.tempDownload?.category}
                                            onChange={this.categoryChanged.bind(this)}
                                        >
                                            <MenuItem value="">Select....</MenuItem>
                                            <MenuItem value="Manual">Manual</MenuItem>
                                            <MenuItem value="Program">Program</MenuItem>
                                            <MenuItem value="Spec">Spec</MenuItem>
                                            <MenuItem value="ReleaseNote">Release Note</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                    </div>
                                </Grid>
                                {!window.location.hostname.includes('zonelog.net') && <Grid item xs={6} >
                                    <div style={{ padding: 10 }}>
                                        Product:
                                        <Select
                                            id="outlined-category-name"
                                            style={{ margin: 0, paddingBottom: 0 }}
                                            value={this.state.tempDownload?.product}
                                            onChange={this.productChanged.bind(this)}
                                        >
                                            <MenuItem value="">Select....</MenuItem>
                                            <MenuItem value="All">All</MenuItem>
                                            <MenuItem value="TOR-N">TOR-N</MenuItem>
                                            <MenuItem value="SWG">SWG</MenuItem>
                                            <MenuItem value="HYG">HYG</MenuItem>
                                            <MenuItem value="Textlog">Textlog</MenuItem>
                                            <MenuItem value="CHFL">CharIot</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                    </div>
                                </Grid>}
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        <TextField
                                            id="outlined-input-name"
                                            label="Model"
                                            value={this.state.tempDownload.model}
                                            onChange={this.modelChanged}
                                            variant="outlined"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        <FormControl fullWidth >
                                            <TextField
                                            id="outlined-input-name"
                                            label="Description"
                                            value={this.state.tempDownload.description}
                                            onChange={this.descriptionChanged}
                                            variant="outlined"
                                            multiline
                                            minRows={4}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    Admin Users Only: <Switch name="adminonly" size="small" color="primary" checked={this.state.tempDownload?.adminonly} onChange={this.handleToggleChange.bind(this)} />
                                </Grid>
                                <Grid item xs={12}>
                                    <h6>Upload file:</h6>
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                    {!this.state.tempDownload?.filetype &&
                                        <FormControl fullWidth >
                                            <FilePicker
                                                onChange={this.readFile}
                                            >
                                                <IconButton aria-label="delete" size="large">
                                                    <BackupIcon />
                                                </IconButton>
                                            </FilePicker>
                                        </FormControl>

                                    }
                                    {this.state.tempDownload?.filetype &&
                                        <Chip
                                            label={this.state.tempDownload.filename} onDelete={(): void => this.removeFile()} color="primary" />
                                            }
                                    </div>
                                </Grid>
                            </Grid>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.saveDownload()} disabled={!this.state.allowSave} >Save</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.cancel()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal
                        show={this.state.deleteModal}
                        onHide={(): void => this.setState({ deleteModal: false})}
                        dialogClassName="modal-100w"
                        aria-labelledby="example-delete-modal-styling-title"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-delete-modal-styling-title">
                                Confirm deletion
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            Remove {this.state.tempDownload.filename} from available downloads?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.removeDownload()} >Remove</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.setState({ deleteModal: false })}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    {this.state.loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2000
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>

                    }
                    {!this.state.loading &&

                        <div>


                            {this.state.tableHidden &&
                                    <ApiFailed />
                            }
                            {!this.state.tableHidden &&
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                        <h4 className={classes.cardTitleWhite}>Downloads</h4>
                                            <IconButton aria-label="add" color="primary" onClick={(): void => this.newDownload()} size="large">
                                                    <AddCircleOutlineIcon />
                                                </IconButton>
                                            </CardHeader>
                                            <CardBody>
                                            <DataGrid

                                                repaintChangesOnly={true}
                                                dataSource={new DataSource({ store: this.store })}
                                                ref={this.gridRef} >
                                                <StateStoring enabled={true} type="localStorage" storageKey="DownloadListGrid" />
                                                <FilterRow visible={true} />

                                                <Column dataField="title" visible={true} dataType="string" />
                                                <Column dataField="category" visible={true} dataType="string" groupIndex={1} />
                                            <Column dataField="filename" visible={true} dataType="string" width={200}/>
                                                <Column dataField="filetype" visible={true} dataType="string" />
                                                <Column dataField="product" visible={true} dataType="string" />
                                                <Column dataField="model" visible={true} dataType="string" />
                                                <Column dataField="adminOnly" visible={true} dataType="boolean" />
                                            <Column dataField="description" visible={true} dataType="string" width={200} cellRender={this.descRender} />

                                                <Column fixed={true} fixedPosition='right' type="buttons" cellRender={this.moreRender.bind(this)} width={35} />


                                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                <Paging defaultPageSize={10} />
                                            </DataGrid>
                                            </CardBody>
                                        </Card>
                                    </GridItem>

                                </GridContainer>
                            }

                        </div>
                    }


                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                            disableScrollLock={true}
                    >
                            <MenuItem onClick={this.editDownload.bind(this)}>Edit</MenuItem>
                            <MenuItem onClick={this.doDownload.bind(this)}>Download</MenuItem>
                            <MenuItem onClick={this.checkRemoveDownload.bind(this)}>Delete</MenuItem>
                    </Menu>

                    <NotificationContainer />
                    <a style={{display: 'none'}}
                        download={this.state.tempDownload.filename}
                        href={this.state.fileDownloadUrl}
                        ref={e => this.dofileDownload = e}
                    >download it</a>

                </div>
                )

            :
            (
                <Redirect to={'/Login'} />
            );

    }

}

export default withStyles(style)(Downloads);

