// import { number, string } from 'prop-types'; Commented out because this is not needed for TypeScript as it itself provides static type checking.

export interface APIGetSitesModel {
    Id: number;
    SiteName: string;
    SiteNameUserFriendly: string;
    Latitude: number | null;
    Longitude: number | null;
    CreatedDate: Date | null;
    DeletedDate: Date | null;
    LastUpdate: Date | null;
    LastReading: Date | null;
    HasActiveLoggers: boolean | null;
    DefaultSite: boolean;
    FkCompanyId: number | null;
    FkEASiteId: number | null;
    AlarmsEnabled: number;
    AlarmState: number;
    AlarmMessage0: string;
    AlarmMessage1: string;
    AlarmMessage2: string;
    AlarmMessage3: string;
    AlarmMessage4: string;
    AlarmMessage5: string;
    AlarmMessage6: string;
    AlarmMessage7: string;
    AlarmMessage8: string;
    AlarmMessage9: string;
    AlarmMessage10: string;
    AlarmMessage11: string;
    AlarmMessage12: string;
    AlarmMessage13: string;
    AlarmMessage14: string;
    AlarmMessage15: string;
}

export function buildAPIGetSitesModel(r: any): APIGetSitesModel {
    return {
        Id: r.id,
        SiteName: r.siteName,
        SiteNameUserFriendly: r.siteNameUserFriendly,
        Latitude: r.latitude,
        Longitude: r.longitude,
        CreatedDate: r.createdDate,
        DeletedDate: r.deletedDate,
        LastUpdate: r.lastUpdate,
        LastReading: r.lastReading,
        HasActiveLoggers: r.hasActiveLoggers,
        DefaultSite: r.defaultSite,
        FkCompanyId: r.fkCompanyId,
        FkEASiteId: r.fkEASiteId,
        AlarmsEnabled: r.alarmsEnabled,
        AlarmState: r.alarmState,
        AlarmMessage0: r.alarmMessage0,
        AlarmMessage1: r.alarmMessage1,
        AlarmMessage2: r.alarmMessage2,
        AlarmMessage3: r.alarmMessage3,
        AlarmMessage4: r.alarmMessage4,
        AlarmMessage5: r.alarmMessage5,
        AlarmMessage6: r.alarmMessage6,
        AlarmMessage7: r.alarmMessage7,
        AlarmMessage8: r.alarmMessage8,
        AlarmMessage9: r.alarmMessage9,
        AlarmMessage10: r.alarmMessage10,
        AlarmMessage11: r.alarmMessage11,
        AlarmMessage12: r.alarmMessage12,
        AlarmMessage13: r.alarmMessage13,
        AlarmMessage14: r.alarmMessage14,
        AlarmMessage15: r.alarmMessage15,
    }
}