import React, { ReactNode } from "react";
import { withStyles, createStyles } from '@mui/styles';
import { Slider, Box } from "@mui/material";

import IconButton from '@mui/material/IconButton';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import FastRewindOutlinedIcon from '@mui/icons-material/FastRewindOutlined';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

const styles: Record<string, any> = {
    controlBox: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);


interface RainviewerConfig {
    version: string;
    generated: number;
    host: string;
    radar: {
        past: Array<{ time: number; path: string }>;
        nowcast: Array<{ time: number; path: string }>;
    };
    satellite: {
        infrared: Array<{ time: number; path: string }>;
    };
}

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    enabled: boolean;
    onSlideChange: (slide: number) => void;
    onReload: (slides: Array<{ time: number; path: string }>) => void;
}

interface State {
    play: boolean;
    slides: Array<{ time: number; path: string }>;
    current: number;
    rainviewerConfig: RainviewerConfig | null;
    marks: Array<{ value: number; label: string }>;
    loaded: boolean;
}

export class RainfallControl extends React.Component<Props, State> {
    timer: ReturnType<typeof setTimeout> | null;
    refreshCountdown: number;

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            play: false,
            slides: [],
            current: -1,
            rainviewerConfig: null,
            marks: [],
            loaded: false,
        };

        this.refreshCountdown = 100;
        this.startTimer = this.startTimer.bind(this);
        this.advance = this.advance.bind(this);

    }

    componentDidMount(): void {

        this.checkRainfallCharts(true);
        if (this.props.enabled) {
            this.startTimer();
            this.setState({ play: true });
        }
    }

    // Model only updated after initial render - reload data again
    componentDidUpdate(prevProps: Props): void {
        if (prevProps.enabled != this.props.enabled) {
            if (this.props.enabled) {
                this.checkRainfallCharts(this.state.play);
                this.startTimer();
            }
            else {
                this.stopTimer();
                this.setState({ play: false, rainviewerConfig: null });
            }
        }

    }

    componentWillUnmount(): void {
        this.stopTimer();
    }


    checkRainfallCharts(play: boolean): void {

        const me = this;

        fetch('https://api.rainviewer.com/public/weather-maps.json', {})
            .then(async response => {
                if (response.status == 204) {
                    return null;
                }
                else if (response.ok) {
                    return response.json();
                }
                else {
                    console.log(response);
                }
            })
            .then(data => {
                if (me.state.rainviewerConfig == null || me.state.rainviewerConfig.generated != data.generated) {
                    const marks: Array<{ value: number; label: string }> = [];
                    data.radar.past.map((rec: { time: number; path: string }, i: number) => {
                        marks.push({ value: i, label: moment(adjustTime(new Date(rec.time * 1000))).format("HH:mm") });
                    });
                    me.setState({
                        rainviewerConfig: data,
                        slides: data.radar.past,
                        marks: marks,
                        loaded: true,
                        play: play,
                        current: 1
                    },
                        );
                    console.log("Update rainviewer url to " + data.radar.past[12].time + " = " + new Date(data.radar.past[12].time * 1000));
                    if (me.props.onReload) {
                        me.props.onReload(data.radar.past);
                    }
                }

            })
            .catch(function () {
                me.setState(
                    {
                        rainviewerConfig: null
                    });
            });



    }

    // Start a 3 second timer to advance slides and check for updated rainfall charts every 5 minutes (100 ticks)
    startTimer(): void {
        if (this.timer == null ) {
            this.timer = setInterval(this.tick.bind(this), 3000);
        }
    }

    stopTimer(): void {
        if (this.timer != null) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    tick(): void {
        if (this.refreshCountdown-- == 0) {
            this.checkRainfallCharts(this.state.play);
            this.refreshCountdown = 100;
        }

        if (this.state.play && this.state.current >= 0) {
            let slide = this.state.current;
            slide++;
            if (slide > this.state.slides?.length) {
                slide = 1;
            }
            this.setState({ current: slide });
            if (this.props.onSlideChange) {
                this.props.onSlideChange(slide - 1);
            }
            console.log("slide " + slide + " on timer " + this.timer);
        }
    }

    advance(by: number): void {

        let slide = this.state.current + by;
        if (slide < 1) {
            slide = 13;
        }
        if (slide > 13) {
            slide = 1;
        }
        this.setState({ current: slide });
        if (this.props.onSlideChange) {
            this.props.onSlideChange(slide - 1);
        }


    }

    render(): ReactNode {

        return this.props.enabled &&
            <Box border={1} paddingLeft="20px" paddingRight="20px" >
                <h5>Rainfall Control</h5>
                {this.state.loaded &&
                    <div>
                        <Slider
                            defaultValue={0}
                            marks={this.state.marks}
                            aria-labelledby="discrete-slider-custom"
                            step={1}
                            min={0}
                            max={12}
                        valueLabelDisplay="auto"
                        value={this.state.current - 1 || 0}
                    />
                    <div style={{ display: "table" }} >
                        <span style={{ display: "table-cell", float: "left" }}>
                            {moment(new Date(this.state.slides[0].time * 1000)).format("DD/MM/yyyy")}
                        </span>
                        <span style={{ display: "table-cell", textAlign: "center", width: "100%" }} >

                            <IconButton aria-label="rewind" onClick={(): void => this.advance(-1) } size="large">
                            <FastRewindOutlinedIcon />
                        </IconButton>
                            {this.state.play ?
                                <IconButton
                                    aria-label="pause"
                                    onClick={(): void => this.setState({ play: false })}
                                    size="large">
                                <PauseCircleOutlineOutlinedIcon fontSize="large"/>
                            </IconButton>
                            :
                            <IconButton
                                aria-label="play"
                                onClick={(): void => this.setState({ play: true })}
                                size="large">
                                <PlayCircleFilledWhiteOutlinedIcon fontSize="large"/>
                            </IconButton>
                        }
                            <IconButton aria-label="forward" onClick={(): void => this.advance(1)} size="large">
                            <FastForwardOutlinedIcon />
                            </IconButton>
                        </span>
                        <span style={{ display: "table-cell", float: "right" }}>
                        {moment(new Date(this.state.slides[10].time * 1000)).format("DD/MM/yyyy")}
                        </span>
                        </div>
                    </div>
                }
            </Box>;

    }
}
export default withStyles(style)(RainfallControl);