import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import { ReactComponent as Yoda } from '../../assets/yoda.svg';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerEventsModel, buildAPIGetLoggerEventsModel } from "models/APIGetLoggerEventsModel";
// DevExtreme
import { DataGrid, Column, Export, Scrolling, FilterRow, StateStoring } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';


//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';

import { withStyles, createStyles } from '@mui/styles';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

//DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { DateBox, SelectBox } from 'devextreme-react';
import { Checkbox } from "@mui/material";

const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

//Options for the dropdown for recent periods

interface DatePeriod {
    ID: number;
    Name: string;
}

const datePeriods: DatePeriod[] = [
    {
        ID: 1,
        Name: 'Last 24 hours',
     
    },
    {
        ID: 2,
        Name: 'Last Week',
    },
    {
        ID: 3,
        Name: 'Last Month',
    },
];


interface Props {
    classes: {
        cardTitleWhite: string;
    };
    startDate: Date;
    endDate: Date;
    rainfall: boolean;
    onDateChange?: (startDate: Date, endDate: Date, rainfall: boolean) => void;
    type: string;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    startDate: Date;
    endDate: Date;
    showRainfall: boolean;
    
}


class DateRange extends React.Component<Props, State> {
    gridRef: React.RefObject<DataGrid>;

    constructor(props: Readonly<Props>) {
        super(props);
        this.gridRef = React.createRef();
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            showRainfall: false
            
        };
    }
    
    //componentDidUpdate(prevProps: Props): void {
    //    if (prevProps.startDate != this.props.startDate
    //        || prevProps.endDate != this.props.endDate
    //    ) {
    //        this.setState({ startDate: this.props.startDate, endDate: this.props.endDate })

    //    }
    //}

    startDateChanged(e: any): void {
        let endDate = this.state.endDate;
        if (e.value > this.state.endDate) {
            endDate = e.value;
        }

        this.setState({
            startDate: e.value,
            endDate: endDate
        });

        if (this.props.onDateChange) {
            this.props.onDateChange(e.value, endDate, this.state.showRainfall);
        }
    }
    
    endDateChanged(e: any): void {
        const endDate = e.value
        let startDate = this.state.startDate;
        if (e.value < this.state.startDate) {
            startDate = e.value;
        }

        

       

        if (this.props.onDateChange) {
            this.props.onDateChange(startDate, endDate, this.state.showRainfall);
        }
        this.setState({
            startDate: startDate,
            endDate: endDate
        });
    }

    rainfallChanged(e: any): void {
        this.setState({
            showRainfall: e.target.checked
        });

        if (this.props.onDateChange) {
            this.props.onDateChange(this.state.startDate, this.state.endDate, e.target.checked);
        }
    }

    dateRangeChanged(e: any): void {
        const currentDate = moment() 
        let startDate;
        

        if (e.value === 1) {
            startDate = moment().subtract(1, 'days');
            
            

        }
        else if (e.value === 2) {
            startDate = moment().subtract(7, 'days');
            
        }
        else if (e.value === 3) {
            startDate = moment().subtract(1, 'month');
            
        }

        

        
        
        //this.startDateChanged(startDate)
        //this.endDateChanged(currentDate)
        
        if (this.props.onDateChange) {
            this.props.onDateChange(startDate, currentDate, this.state.showRainfall);
        }

        this.setState({
            startDate: startDate,
            endDate: currentDate
        });
    }

    

    

    render(): ReactNode {


        const offset = sessionStorage.getItem('utcOffset') ? parseInt(sessionStorage.getItem('utcOffset').slice(1)) : 0
        // console.log("DRAWGRAPH Push to API -> " + '/api/aquaguard/GetLogReadings?companyid=0&logger=' + logger.Serial + "&startDate=" + moment(this.state.startDate).format("yyyy-MM-DD") + "&endDate=" + moment(this.state.endDate).format("yyyy-MM-DD") + "T23:59:59");
        let offsetStartDate
        let offsetEndDate
        if (sessionStorage.getItem('utcOffset').slice(0, 1) === '+') {
            offsetStartDate = moment(this.state.startDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDate = moment(this.state.endDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
        } else if (sessionStorage.getItem('utcOffset').slice(0, 1) === '-') {
            offsetStartDate = moment(this.state.startDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDate = moment(this.state.endDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
        } else {
            offsetStartDate = moment(this.state.startDate).format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDate = moment(this.state.endDate).format("yyyy-MM-DDTHH:mm:ss");
        }
        return (

            <div>
                
                <GridContainer spacing={3}>
                    <GridItem xs={12} sm={12} md={6} lg={4}>
                    
                    <div>
                            <p style={{ fontSize: "16px", marginBottom: 0 } }>Start date:</p>
                            <DateBox id="startDate" displayFormat={"dd/MM/yyyy HH:mm"} value={this.state.startDate} onValueChanged={this.startDateChanged.bind(this)} type={this.props.type} />
                    </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={4}>
                        <div>
                            <p style={{ fontSize: "16px", marginBottom: 0 }}>End date:</p>
                            <DateBox id="endDate" displayFormat={"dd/MM/yyyy HH:mm"} value={this.state.endDate > moment() ? moment() : this.state.endDate} onValueChanged={this.endDateChanged.bind(this)} type={this.props.type} />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={4}>
                        <div>
                            <p style={{ fontSize: "16px", marginBottom: 0 }}>Recent period:</p>
                            <SelectBox
                            onValueChanged={this.dateRangeChanged.bind(this)}
                            dataSource={datePeriods}
                            valueExpr="ID"
                            displayExpr="Name"
                            
                                              />
                        </div>
                    </GridItem>
                    <GridItem xs={12}>
                        <p style={{marginTop: "10px"} }>{`This query is based in the time frame for the UTC offset you have set in your user preferences.            In UTC this query is from ${moment(offsetStartDate).format('DD/MM/YY HH:mm')} to ${moment.utc(offsetEndDate).format('DD/MM/YY HH:mm')}`}</p>
                    </GridItem>
                    {this.props.rainfall &&
                        <GridItem xs={12} sm={12} md={6} lg={4}>
                        <div style={{ paddingTop: 10 }}>
                            EA Rainfall:<Checkbox onChange={this.rainfallChanged.bind(this)} checked={this.state.showRainfall} />
                        </div>
                        </GridItem>
                    }
                </GridContainer>
            </div>

        )
    }

}

export default withStyles(style)(DateRange);