export interface APIGetLoggerAlarmsModel {
    /// <summary>
    /// Identity, Indexed, Required
    /// </summary>
    id: number;

    alarmState: number;

    alarmMask: number;

    dateRecorded: Date | null;

    dateCreated: Date;

    dateCleared: Date | null;

    source: string;

    cleared: string | null;

    alarmMessage: string;

    siteName: string;

    loggerSerial: number;

}

export function buildAPIGetLoggerAlarmsModel(r: any): APIGetLoggerAlarmsModel[] {

    const result: APIGetLoggerAlarmsModel[] = new Array<APIGetLoggerAlarmsModel>();

    r?.forEach(function (rec: APIGetLoggerAlarmsModel) {
        result.push(rec);
    });

    return result;
}




