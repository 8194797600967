export class APIClearResendsModel {
    serial: number;
    toDate: string | null;
    toSeq: number | null;
}





