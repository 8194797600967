import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerAlarmsModel, buildAPIGetLoggerAlarmsModel } from "models/APIGetLoggerAlarmsModel";

import { DataGrid, Column, Export, Scrolling, FilterRow, StateStoring, Pager, Paging } from 'devextreme-react/data-grid';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import { withStyles, createStyles } from '@mui/styles';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';

//DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Chart, { CommonSeriesSettings, ArgumentAxis, Series, Legend, Aggregation, ValueAxis, ZoomAndPan, ScrollBar } from 'devextreme-react/chart';
import DateBox from 'devextreme-react/date-box';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    EASiteId: number;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    maxRainfall: number;
    startDate: Date;
    endDate: Date;
    readings: Array<{ id: number; dateTime: Date; value: number }> ;
    graphData: Array<
        {
            date: Date;
            rainfall: number | null;
        }
    > | undefined;
}

class RainfallPanel extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            maxRainfall: 0,
            startDate: moment().subtract(7, 'days').toDate(),
            endDate: moment().toDate(),
            readings: [],
            graphData: undefined
        };
    }

    componentDidMount(): void {


        //get alarms for logger here
        if (this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({ loading: false, tableHidden: false });
        }
        else {
            this.setState({ loading: false, tableHidden: true });
        }

    }

    reloadData(start: Date, end: Date): boolean {
        const rainfallData = new Array<{ id: number; dateTime: Date; value: number }>();

        const graphData = new Array<
            {
                date: Date;
                rainfall: number | null;
            }
            >();

        //get alarms for logger here
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/EAMeasures?EASiteId=' + me.props.EASiteId + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"), {})
            .then(response => {
                let maxRainfall = 0;

                for (let i = 0; i < response.length; i++) {
                    rainfallData.push({
                        id: response[i].id,
                        dateTime: adjustTime(response[i].dateTime),
                        value: response[i].value
                    });

                    if (response[i].value > maxRainfall) {
                        maxRainfall = response[i].value;
                    }
                    graphData.push({
                        date: adjustTime(new Date(response[i].dateTime)),
                        rainfall: response[i].value,
                    });

                }

                graphData.sort((a, b) => a.date.getTime() - b.date.getTime());

                me.setState({
                    maxRainfall: maxRainfall,
                    readings: rainfallData,
                    graphData: graphData
                });

            })
            .catch(function (ex) {
                me.setState(
                    {
                        authorized: false,
                        readings: [] 
                    });
                console.log(ex);
                return false;
            });
        return true;
    }



    startDateChanged(e: any): void {
        this.setState({
            startDate: e.value
        });

        if (!this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({ tableHidden: true });
        }
    }

    endDateChanged(e: any): void {
        this.setState({
            endDate: e.value
        });

        if (!this.reloadData(this.state.startDate, this.state.endDate)) {
            this.setState({  tableHidden: true });
        }
    }

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Alarms');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm");
    }


    render(): ReactNode {

        return (

            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                    {!this.state.tableHidden &&
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                                Start date :&nbsp;<DateBox id="startDate" displayFormat={"dd/MM/yyyy"} value={this.state.startDate} onValueChanged={this.startDateChanged.bind(this)} />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                End date :&nbsp;<DateBox id="endDate" displayFormat={"dd/MM/yyyy"} value={this.state.endDate} onValueChanged={this.endDateChanged.bind(this)} />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomTabs
                                    headerColor="info"
                                    tabs={[
                                        {
                                            tabName: "Data",
                                            tabIcon: TableChartOutlinedIcon,
                                            tabContent: (
                                                <DataGrid
                                                    dataSource={this.state.readings}
                                                    keyExpr="id" 
                                                    columnAutoWidth={true}
                                                    columnMinWidth={80}
                                                    onExporting={this.onExporting} >
                                                    <Scrolling mode="standard" useNative={true} />
                                                    <FilterRow visible={true} />
                                                    <Export enabled={true} />
                                                    <StateStoring enabled={true} type="localStorage" storageKey="rainfallPanelGrid" />
                                                    <Column dataField="id" visible={false} dataType="number" />
                                                    <Column dataField="dateTime" customizeText={this.dateColumnCustomizeText} dataType="datetime" caption="Date of reading" sortIndex={0} sortOrder="desc"/>
                                                    <Column dataField="value" caption="mm" dataType="number"/>
                                                    <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                    <Paging defaultPageSize={10} />
                                                </DataGrid>
                                            )
                                        },
                                        {
                                            tabName: "Graph",
                                            tabIcon: PollOutlinedIcon,
                                            tabContent: (
                                                <Chart dataSource={this.state.graphData}>
                                                    <CommonSeriesSettings
                                                        argumentField="date"
                                                        type="line"
                                                        ignoreEmptyPoints={true}>
                                                        <Aggregation
                                                            enabled={true}
                                                            method="sum"
                                                        />
                                                    </CommonSeriesSettings>
                                                    <ValueAxis
                                                        name="Rainfall"
                                                        visualRange={{ startValue: 0, endValue: this.state.maxRainfall }}
                                                        allowDecimals={true}
                                                    />
                                                    <ArgumentAxis
                                                        aggregationInterval={{ minutes: 5 }}
                                                        allowDecimals={false}
                                                        argumentType="datetime"
                                                        minValueMargin={0.1}
                                                        maxValueMargin={0.1}
                                                        wholeRange={[this.state.startDate, this.state.endDate]}
                                                    />
                                                    <ZoomAndPan
                                                        argumentAxis="both"
                                                        valueAxis="none"
                                                    />
                                                    <ScrollBar
                                                        visible={true}
                                                    />
                                                    <Series valueField="rainfall" name="Rainfall(mm)" />
                                                    <Legend visible={true} />
                                                </Chart>
                                            )
                                        }
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>

                    }
                    </div>
                }

            </div>

        )
    }

}

export default withStyles(style)(RainfallPanel);