import React from "react";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { ReactComponent as AshLogo } from '../../assets/ashLogo.svg';
import CardFooter from '../../components/Card/CardFooter.js';
import BG from '../../assets/BG-Aquaguard.jpg';
import Footer from 'components/Navbars/footer'
import { Link } from 'react-router-dom'

const PrivacyPolicy: React.FC = () => {

    
    
    return (
        <>
            <div style={{
                backgroundImage: `url(${BG})`,
                width: '100vw',
                height: '100vh',
                


            }}>

                <div className='some-page-wrapper'>
                    <div>

                        <div className='column' >
                            <div>
                                <Card style={{
                                    margin: "3vw 0 0 0",
                                    background: 'linear-gradient(130deg, rgba(0, 0, 0, 0.7) 0%, rgb(20,60,103, 0.6) 70%',
                                    width: "90vw",
                                    height: "80vh",
                                    boxShadow: "2px 2px 10px black",
                                    padding: "5vh 0 0 0",
                                    overflow: "auto",
                                    msOverflowStyle: "none",  
                                    scrollbarWidth: "none"    
                                }}>
                                    <div>
                                        <CardHeader>
                                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"} }>
                                            <AshLogo style={{width: "50%", marginTop: "-50px", textAlign: "center"}} />
                                                <h3 style={{ color: "white", marginTop: "-30px", fontSize: "28px" } }>AquaGuard</h3>
                                                <h1 style={{marginTop: "10px", color: "white", fontSize: "33px"} }>Privacy Policy</h1>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div style={{ color: "white", margin: "0 20px"}}>
                                                <h4 style={{fontSize: "22px" } }>Introduction</h4>
                                                <p style={{fontSize: "14px"} }>This privacy policy sets out how Seiche Water Technology Group uses and protects any information that
                                                you give Seiche Water Technology Group when you use our website and/or provide us with personal
                                                information via email, in person or in writing.
                                                Seiche Water Technology Group is committed to ensuring that your privacy is protected. Should we ask
                                                you to provide certain information by which you can be identified when using this website, then you can
                                                    be assured that it will only be used in accordance with this privacy statement. </p>
                                                <h4 style={{ fontSize: "22px" }}>What we collect</h4>
                                                <p style={{fontSize: "22px" } }>We may collect the following information:</p>
                                                <ul>
                                                    <li style={{ fontSize: "14px" }}>name and job title</li>
                                                    <li style={{ fontSize: "14px" }}>contact information including email address</li>
                                                    <li style={{ fontSize: "14px" }}>demographic information such as postcode, preferences, and interests</li>
                                                    <li style={{ fontSize: "14px" }}>other information relevant to customer surveys and/or offers</li>
                                                </ul>
                                                <h4 style={{ fontSize: "22px" }}>What we do with the information we gather</h4>
                                                <p style={{ fontSize: "14px" }}>We require this information to understand your needs and provide you with a better service, and in
                                                    particular for the following reasons:</p>
                                                <ul>
                                                    <li style={{ fontSize: "14px" }}>Internal record keeping</li>
                                                    <li style={{ fontSize: "14px" }}>We may use the information to improve our products and services</li>
                                                    <li>We may periodically send promotional emails about new products, special offers or other
                                                        information which we think you may find interesting using the email address which you have
                                                        provided</li>
                                                    <li style={{ fontSize: "14px" }}>From time to time, we may also use your information to contact you for market research purposes.
                                                        We may contact you by email, phone, or mail. We may use the information to customise the
                                                        website according to your interests</li>
                                                </ul>
                                                <h4 style={{ fontSize: "22px" }}>Security</h4>
                                                <p style={{ fontSize: "14px" }}>We are committed to ensuring that your information is secure. In order to prevent unauthorised access
                                                    or disclosure, we have put in place suitable physical, electronic and managerial procedures to
                                                    safeguard and secure the information we collect online.</p>
                                                <h4 style={{ fontSize: "22px" }}>How we use cookies</h4>
                                                <p style={{ fontSize: "14px" }}>A cookie is a small file which asks permission to be placed on your computer&#39;s hard drive. Once you
                                                    agree, the file is added, and the cookie helps analyse web traffic or lets you know when you visit a
                                                    particular site. Cookies allow web applications to respond to you as an individual. The web application
                                                    can tailor its operations to your needs, likes and dislikes by gathering and remembering information
                                                    about your preferences.
                                                    We use traffic log cookies to identify which pages are being used. This helps us analyse data about web
                                                    page traffic and improve our website in order to tailor it to customer needs. We only use this information
                                                    for statistical analysis purposes and then the data is removed from the system.
                                                    Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you
                                                    find useful and which you do not. A cookie in no way gives us access to your computer or any
                                                    information about you, other than the data you choose to share with us.
                                                    You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but
                                                    you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from
                                                    taking full advantage of the website.</p>
                                                <h4 style={{ fontSize: "22px" }}>Links to other websites</h4>
                                                <p style={{ fontSize: "14px" }}>Our website may contain links to other websites of interest. However, once you have used these links to
                                                    leave our site, you should note that we do not have any control over that other website. Therefore, we
                                                    cannot be responsible for the protection and privacy of any information which you provide whilst visiting
                                                    such sites and such sites are not governed by this privacy statement. You should exercise caution and
                                                    look at the privacy statement applicable to the website in question.
                                                </p>
                                                <h4 style={{ fontSize: "22px" }}>Controlling your personal information</h4>
                                                <p style={{ fontSize: "14px" }}>You may choose to restrict the collection or use of your personal information in the following ways:</p>
                                                <ul>
                                                    <li style={{ fontSize: "14px" }}>whenever you are asked to fill in a form on the website, look for the box that you can click to
                                                        indicate that you do not want the information to be used by anybody for direct marketing
                                                        purposes</li>
                                                    <li style={{ fontSize: "14px" }}>if you have previously agreed to us using your personal information for direct marketing purposes,
                                                        you may change your mind at any time by writing to or emailing us at <a style={{textDecoration: "underline", color: "white"}} href="mailto:marketing@seiche.com">marketing@seiche.com</a></li>
                                                </ul>
                                                <p style={{ fontSize: "14px" }}>We will not sell, distribute, or lease your personal information to third parties unless we have your
                                                    permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you
                                                    wish this to happen.
                                                    You may request details of personal information which we hold about you under the Data Protection
                                                    Act 1998. A small fee will be payable. If you would like a copy of the information held on you please
                                                    write to: SWTG, Seiche Ltd, Bradworthy Industrial Estate, Langdon Road, Bradworthy, Holsworthy, Devon,
                                                    United Kingdom, EX22 7SF.
                                                    If you believe that any information, we are holding on you is incorrect or incomplete, please write to or
                                                    email us as soon as possible, at the above address. We will promptly correct any information found to
                                                    be incorrect.
                                                    We will hold your information for 24 months, after which is will be deleted securely.
                                                </p>
                                                <h4 style={{ fontSize: "22px" }}>Maintaining this Policy</h4>
                                                <p style={{ fontSize: "14px" }}>SWTG will monitor the effectiveness of this policy and its general compliance within SWTG.
                                                    This policy will be kept up to date and amended accordingly to reflect any changes in response to
                                                    revised legislation and applicable standards and guidelines.
                                                </p>
                                         </div>   
                                        </CardBody>
                                        <CardFooter>
                                            <div style={{ margin: "20px auto" }}>
                                                <Link to={sessionStorage.getItem('userId') ? "/Portal/Dashboard" : "/"}>
                                                    <Button variant="contained">Back to homepage</Button>
                                                </Link>
                                            </div>
                                        </CardFooter>
                                    </div>
                                </Card>
                                
                            </div>
                        </div>

                    </div>
                    <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
                        <Footer />
                    </div>
                </div>

            </div>
        </>
    )
}
export default PrivacyPolicy