import React, { ReactNode } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerReadingsModel, buildAPIGetLoggerReadingsModel } from "models/APIGetLoggerReadingsModel";
import { APIGetLoggerAlarmLevelModel, buildAPIGetLoggerAlarmLevelModel } from "models/APIGetLoggerAlarmLevelModel";

import { decodeFlowMultiplier } from "Utils/FlowMultiplier";

//Moment date/time formatting
//https://momentjs.com/docs/
import moment, { Duration } from 'moment';

import { styled } from '@mui/material/styles';
import { compose, spacing, palette, border } from '@mui/system';

const Box = styled('div')(compose(spacing, palette, border));


interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    serial: string;
    channels: string[];
    startDate: Date;
    endDate: Date;
    isprimary: string;
    title: string;
    channelsAvail: number;
    ChannelAUnits: string;
    ChannelBUnits: string;
    ChannelCDp: number | null;
    ChannelCUnits: string;
    ChannelDDp: number | null;
    ChannelDUnits: string;
    ChannelEDp: number | null;
    ChannelEUnits: string;
    flowUnits: string;
    flowFactor: number;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    maxValue: number;
    minValue: number;
    maxFlow: number;
    minFlow: number;
    totalVolume: number;
    totalRainfall: number;
    readingsData: Array<APIGetLoggerReadingsModel>;
//    flowMultiplierA: FlowMultiplier;
//    flowMultiplierB: FlowMultiplier;
    intervalAAvg: number;
    haveAnalog: boolean;
    maxCValue: number;
    minCValue: number;
    maxDValue: number;
    minDValue: number;
    maxEValue: number;
    minEValue: number;
}

export class PeriodStatsTOR extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            maxValue: -1,
            minValue: Number.MAX_VALUE,
            maxFlow: -1,
            minFlow: Number.MAX_VALUE,
            totalVolume: -1,
            totalRainfall: -1,
            readingsData: [],
//            flowMultiplierA: decodeFlowMultiplier(0),
//            flowMultiplierB: decodeFlowMultiplier(0),
            intervalAAvg: 0,
            haveAnalog: props.ChannelCUnits != "" || props.ChannelDUnits != "" || props.ChannelEUnits != "",

            /*
            maxCValue: -1,
            minCValue: Number.MAX_VALUE,
            maxDValue: -1,
            minDValue: Number.MAX_VALUE,
            maxEValue: -1,
            minEValue: Number.MAX_VALUE,
            */

            maxCValue: Number.NEGATIVE_INFINITY,
            minCValue: Number.MAX_VALUE,
            maxDValue: Number.NEGATIVE_INFINITY,
            minDValue: Number.MAX_VALUE,
            maxEValue: Number.NEGATIVE_INFINITY,
            minEValue: Number.MAX_VALUE,

        };
    }

    componentDidMount(): void {

        //get alarms for logger here
        if (this.reloadData(this.props.startDate, this.props.endDate)) {
            this.setState({ tableHidden: false });
        }
        else {
            this.setState({ tableHidden: true });
        }

    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.startDate != this.props.startDate
            || prevProps.endDate != this.props.endDate
        ) {
            this.reloadData(this.props.startDate, this.props.endDate);
        }

    }

    reloadData(start: Date, end: Date): boolean {

        this.setState({ loading: true });

        let readingsData = new Array<APIGetLoggerReadingsModel>();
        let alarmLevels = new Array<APIGetLoggerAlarmLevelModel>();



        let maxValue = Number.NEGATIVE_INFINITY;
        let minValue = Number.MAX_VALUE;
        let maxFlow = Number.NEGATIVE_INFINITY;
        let minFlow = Number.MAX_VALUE;
        let maxCValue = Number.NEGATIVE_INFINITY;
        let minCValue = Number.MAX_VALUE;
        let maxDValue = Number.NEGATIVE_INFINITY;
        let minDValue = Number.MAX_VALUE;
        let maxEValue = Number.NEGATIVE_INFINITY;
        let minEValue = Number.MAX_VALUE;

        /*
        let maxValue = -1;
        let minValue = Number.MAX_VALUE;
        let maxFlow = -1;
        let minFlow = Number.MAX_VALUE;
        let maxCValue = -1;
        let minCValue = Number.MAX_VALUE;
        let maxDValue = -1;
        let minDValue = Number.MAX_VALUE;
        let maxEValue = -1;
        let minEValue = Number.MAX_VALUE;
        */

        let firstVolTS = this.props.endDate;
        let startVol = 0;
        let lastVolTS = this.props.startDate;
        let endVol = 0;

        let totalRainfall = 0;

        let intervalATotal = 0;
        let intervalACount = 0;

        //get alarms for logger here
        const me = this;

        const promises = new Array<Promise<any>>();
        // generate array of Promises each adding the readingsData for a logger
        promises.push(
            CallGetAPI(CreateUrl('/api/aquaguard/GetLogReadings?companyid=0&logger=' + this.props.serial + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"), {})
                .then(json => {
                    readingsData = readingsData.concat(buildAPIGetLoggerReadingsModel(json));
                })
                .catch(function (ex) {
                    me.setState(
                        {
                            tableHidden: true,
                            readingsData: [],
                            loading: false,
                        });
                    console.log(ex);
                })
        );
        promises.push(
            CallGetAPI(CreateUrl('/api/aquaguard/LoggerAlarmLevels?logger=' + this.props.serial + "&startDate=" + moment(start).format("yyyy-MM-DD") + "&endDate=" + moment(end).format("yyyy-MM-DD") + "T23:59:59"), {})
                .then(json => {
                    alarmLevels = buildAPIGetLoggerAlarmLevelModel(json);
                })
                .catch(function (ex) {
                    me.setState(
                        {
                            tableHidden: true,
                            loading: false,
                        });
                    console.log(ex);
                })
        );

        Promise.all(promises).then(() => {

            readingsData.forEach((v: APIGetLoggerReadingsModel) => {
                if (v.dateStamp != null) {

                    let multiplier = decodeFlowMultiplier(0);
                    if (v.multiplier != null) {
                        multiplier = decodeFlowMultiplier(v.multiplier);
                    }
                    let value = v.value;
                    if (value == null) {
                        value = v.value2;
                    }

                    if (v.channelletter == 'A') {
                        if (this.props.ChannelAUnits != "mm") {
                            if (value != -32768) {
                                if (value != null && moment(v.dateStamp).toDate() < firstVolTS) {
                                    firstVolTS = moment(v.dateStamp).toDate();
                                    startVol = value;
                                }
                                if (value != null && moment(v.dateStamp).toDate() > lastVolTS) {
                                    lastVolTS = moment(v.dateStamp).toDate();
                                    endVol = value;
                                }
                            }
                            if (v.flowRate != 0) {
                                if (v.flowRate != null && v.flowRate > maxFlow) {
                                    maxFlow = v.flowRate;
                                }
                                if (v.flowRate != null && v.flowRate < minFlow) {
                                    minFlow = v.flowRate;
                                }
                            }
                        }
                        else {
                            // Rain gauge
                            if (value != -32768) {
                                if (v.difference != null && v.difference > 0) {
                                    totalRainfall += v.difference * multiplier.factor;
                                }
                            }
                            if (v.difference != null && (v.difference * multiplier.factor) > maxFlow) {
                                maxFlow = v.difference * multiplier.factor;
                            }

                        }
                        intervalATotal += v.interval ? v.interval : 0;
                        intervalACount++;
                    }

                    if (v.channelletter == 'B') {
                        if (value != -32768) {
                            if (value != null && value > maxValue) {
                                maxValue = value;
                            }
                            if (value != null && value < minValue) {
                                minValue = value;
                            }
                        }
                    }

                    if (v.channelletter == 'C') {
                        if (value != -32768) {
                            if (value != null && value > maxCValue) {
                                maxCValue = value;
                            }
                            if (value != null && value < minCValue) {
                                minCValue = value;
                            }
                        }
                    }

                    if (v.channelletter == 'D') {
                        if (value != -32768) {
                            if (value != null && value > maxDValue) {
                                maxDValue = value;
                            }
                            if (value != null && value < minDValue) {
                                minDValue = value;
                            }
                        }
                    }

                    if (v.channelletter == 'E') {
                        if (value != -32768) {
                            if (value != null && value > maxEValue) {
                                maxEValue = value;
                            }
                            if (value != null && value < minEValue) {
                                minEValue = value;
                            }
                        }
                    }

                }
            });

            alarmLevels.forEach((v: APIGetLoggerAlarmLevelModel) => {
                if (v.startTime != null) {

                    let levelTime = new Date(v.startTime);
                    if (levelTime < start) {
                        levelTime = start;
                    }

                    if (me.props.channels.findIndex((v) => v == "A") >= 0) {
                        if ((v.channelASetLevel || 0) > maxValue) {
                            maxValue = v.channelASetLevel || 0;
                        }
                        if ((v.channelAClearLevel || Number.MAX_VALUE) < minValue) {
                            minValue = v.channelAClearLevel || Number.MAX_VALUE;
                        }
                    }
                    if (me.props.channels.findIndex((v) => v == "B") >= 0) {
                        if ((v.channelBSetLevel || 0) > maxValue) {
                            maxValue = v.channelBSetLevel || 0;
                        }
                        if ((v.channelBClearLevel || Number.MAX_VALUE) < minValue) {
                            minValue = v.channelBClearLevel || Number.MAX_VALUE;
                        }
                    }
                    if (me.props.channels.findIndex((v) => v == "C") >= 0) {
                        if ((v.channelCSetLevel || 0) > maxValue) {
                            maxValue = v.channelCSetLevel || 0;
                        }
                        if ((v.channelCClearLevel || Number.MAX_VALUE) < minValue) {
                            minValue = v.channelCClearLevel || Number.MAX_VALUE;
                        }
                    }
                    if (me.props.channels.findIndex((v) => v == "D") >= 0) {
                        if ((v.channelDSetLevel || 0) > maxValue) {
                            maxValue = v.channelDSetLevel || 0;
                        }
                        if ((v.channelDClearLevel || Number.MAX_VALUE) < minValue) {
                            minValue = v.channelDClearLevel || Number.MAX_VALUE;
                        }
                    }
                    if (me.props.channels.findIndex((v) => v == "E") >= 0) {
                            if ((v.channelESetLevel || 0) > maxValue) {
                                maxValue = v.channelESetLevel || 0;
                            }
                            if ((v.channelEClearLevel || Number.MAX_VALUE) < minValue) {
                                minValue = v.channelEClearLevel || Number.MAX_VALUE;
                            }
                    }

                }
            });


            console.log("Channel " + me.props.channels + " Min: " + minValue + " Max: " + maxValue);

            me.setState({
                minValue: minValue,
                maxValue: maxValue,
                minFlow: this.props.flowUnits !== 'm3/h' ? minFlow * this.props.flowFactor : minFlow * 3.6,
                maxFlow: this.props.flowUnits !== 'm3/h' ? maxFlow * this.props.flowFactor : maxFlow * 3.6,
                totalVolume: endVol - startVol,
                totalRainfall: totalRainfall,
                readingsData: readingsData,
//                flowMultiplierA: decodeFlowMultiplier(this.props.FlowMultiplierA),
//                flowMultiplierB: decodeFlowMultiplier(this.props.FlowMultiplierB),
                intervalAAvg: intervalATotal / intervalACount,
                maxCValue: maxCValue,
                minCValue: minCValue,
                maxDValue: maxDValue,
                minDValue: minDValue,
                maxEValue: maxEValue,
                minEValue: minEValue,
                loading: false,
            });

        });



        return true;
    }




    render(): ReactNode {

        return (
        <div>
            {
                this.state.loading &&
                    <div style={{
                        position: 'relative', left: '40%', top: '50%',
                    }}>
                        <ClipLoader
                            size={this.props.isprimary == "yes" ? 150 : 50}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
            }
                {!this.state.loading &&

                    <div>
                        <GridContainer spacing={1} >
                            
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div style={{ whiteSpace: "nowrap", fontSize: "18px", marginBottom: "5px" }}>
                                    <b>Stats for Period : </b>
                                </div>
                                
                            </GridItem>
                        {/*(this.props.meterConfig & 0x2900) > 0 &&
                            <GridItem xs={12} sm={12} md={6} lg={4}>
                            <div>
                                Max pressure : {this.state.maxValue == -1 ? "Not set" : this.state.maxValue + " mBar"}
                            </div>
                            </GridItem>
                        */}
                        {/*(this.props.meterConfig & 0x2900) > 0 &&
                            <GridItem xs={12} sm={12} md={6} lg={4}>
                            <div>
                                Min pressure : {this.state.minValue == Number.MAX_VALUE ? "Not set" : this.state.minValue + " mBar"}
                            </div>
                            </GridItem>
                        */}

                        {/*(this.props.channelsAvail & 0x0003) > 0 &&
                                <GridItem xs={12} sm={12} md={6} lg={4}>
                                    <div>
                                Max (N/A)
                                    </div>
                                </GridItem>
                        */}
                        {/*(this.props.channelsAvail & 0x0003) > 0 &&
                                <GridItem xs={12} sm={12} md={6} lg={4}>
                                    <div>
                                Min (N/A)
                                    </div>
                                </GridItem>
                        */}

                        {/*
                        {(this.props.meterConfig & 0x04FF) > 0 && this.props.ChannelAUnits != "mm" &&
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                            <div>
                                Max flow : {this.state.maxFlow == -1 ? "Not_set" : this.state.maxFlow.toFixed(4)}&nbsp;{this.props.ChannelAUnits == "m3" ? <span>m<sup>3</sup></span> : this.props.ChannelAUnits}/h
                            </div>
                            </GridItem>
                        }
                        */}
                        {(this.props.channelsAvail & 0x01) > 0 && this.props.ChannelAUnits != "mm" &&
                                <GridItem xs={12} sm={6} md={4} lg={2}>
                                    <div style={{fontSize: "18px"}}>
                                        Max flow : {this.state.maxFlow == -1 ? "Not_set" : this.state.maxFlow.toFixed(3)}&nbsp;{this.props.flowUnits}
                                    </div>
                                </GridItem>
                        }

                        {(this.props.channelsAvail & 0x01) > 0 && this.props.ChannelAUnits == "mm" &&
                                <GridItem xs={12} sm={6} md={4} lg={2}>
                                    <div style={{ fontSize: "18px" }}>
                                        Max rainfall rate : {this.state.maxFlow == -1 ? "Not_set" : (this.state.maxFlow * (3600 / this.state.intervalAAvg)).toFixed(3)}&nbsp;{this.props.ChannelAUnits}/h
                                    </div>
                                </GridItem>
                        }

                        {/*
                        {(this.props.meterConfig & 0x04FF) > 0 && this.props.ChannelAUnits != "mm" &&
                            <GridItem xs={12} sm={6} md={4} lg={6}>
                            <div>
                                Min flow : {this.state.minFlow == Number.MAX_VALUE ? "Not_set" : this.state.minFlow.toFixed(4)}&nbsp;{this.props.ChannelAUnits == "m3" ? <span>m<sup>3</sup></span> : this.props.ChannelAUnits}/h
                            </div>
                            </GridItem>
                        }
                        */}
                        {(this.props.channelsAvail & 0x01) > 0 && this.props.ChannelAUnits != "mm" &&
                                <GridItem xs={12} sm={6} md={4} lg={2}>
                                    <div style={{ fontSize: "18px" }}>
                                        Min flow : {this.state.minFlow == Number.MAX_VALUE ? "Not_set" : this.state.minFlow.toFixed(3)}&nbsp;{this.props.flowUnits}
                                    </div>
                                </GridItem>
                        }

                        {(this.props.channelsAvail & 0x01) > 0 && this.props.ChannelAUnits != "mm" &&
                                <GridItem xs={12} sm={6} md={4} lg={3}>
                                    <div style={{ fontSize: "18px" }}>
                                        Consumption : {this.state.totalVolume == -1 ? "Not_set" : this.state.totalVolume.toFixed(3)}&nbsp;{this.props.ChannelAUnits == "m3" ? <span>m<sup>3</sup></span> : this.props.ChannelAUnits}
                                    </div>
                                </GridItem>
                        }
                        {(this.props.channelsAvail & 0x01) > 0 && this.props.ChannelAUnits == "mm" &&
                                <GridItem xs={12} sm={6} md={4} lg={2}>
                                    <div style={{ fontSize: "18px" }}>
                                        Total rainfall : {this.state.totalRainfall == -1 ? "Not_set" : this.state.totalRainfall.toFixed(3)}&nbsp;{this.props.ChannelAUnits}
                                    </div>
                                </GridItem>
                                }
                           
                    </GridContainer>
                            {this.state.haveAnalog &&
                            <GridContainer spacing={3}>
                                
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div style={{ marginTop: "8px", fontSize: "18px" }} >
                                        <b>{window.location.hostname.includes('zonelog.net') ? "Pressure" : "Analogue"} :</b>
                                        </div>
                                    </GridItem>
                                {(this.props.channelsAvail & 0x04) > 0 &&
                                    <div>
                                        <GridItem xs={12} sm={6} md={3} lg={3}>
                                            <div style={{ whiteSpace: "nowrap", fontSize: "18px" }}>
                                                Chan C Max value : {this.state.maxCValue == -1 ? "Not_set" : this.state.maxCValue.toFixed(3) + " " + this.props.ChannelCUnits}
                                            </div>
                                        </GridItem>
                                
                                    <GridItem xs={12} sm={6} md={3} lg={3}>
                                            <div style={{ whiteSpace: "nowrap", fontSize: "18px" }}>
                                            Chan C Min value : {this.state.minCValue == Number.MAX_VALUE ? "Not_set" : this.state.minCValue.toFixed(3) + " " + this.props.ChannelCUnits}
                                        </div>
                                        </GridItem>
                                    </div>
                                }
                                {(this.props.channelsAvail & 0x08) > 0 &&
                                    <GridItem xs={12} sm={6} md={3} lg={3}>
                                        <div style={{ whiteSpace: "nowrap", fontSize: "18px" }}>
                                                Chan D Max value : {this.state.maxDValue == -1 ? "Not_set" : this.state.maxDValue.toFixed(3) + " " + this.props.ChannelDUnits}
                                            </div>
                                        </GridItem>
                                }
                                {(this.props.channelsAvail & 0x08) > 0 &&
                                    <GridItem xs={12} sm={6} md={3} lg={3}>
                                        <div style={{ whiteSpace: "nowrap", fontSize: "18px" }}>
                                                Chan D Min value : {this.state.minDValue == Number.MAX_VALUE ? "Not_set" : this.state.minDValue.toFixed(3) + " " + this.props.ChannelDUnits}
                                            </div>
                                        </GridItem>
                                }
                                {(this.props.channelsAvail & 0x10) > 0 &&
                                    <GridItem xs={12} sm={6} md={3} lg={3}>
                                        <div style={{ whiteSpace: "nowrap", fontSize: "18px" }}>
                                                Chan E Max value : {this.state.maxEValue == -1 ? "Not_set" : this.state.maxEValue.toFixed(3) + " " + this.props.ChannelEUnits}
                                            </div>
                                        </GridItem>
                                }
                                {(this.props.channelsAvail & 0x10) > 0 &&
                                    <GridItem xs={12} sm={6} md={3} lg={3}>
                                        <div style={{ whiteSpace: "nowrap", fontSize: "18px" }}>
                                        Chan E Min value : {this.state.minEValue == Number.MAX_VALUE ? "Not_set" : this.state.minEValue.toFixed(3) + " " + this.props.ChannelEUnits}
                                            </div>
                                        </GridItem>
                                    }
                                   
                                </GridContainer>
                        }
                    </div>
            }
        </div>
        );

    }
}
export default PeriodStatsTOR;