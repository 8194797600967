import React, { Component } from "react";
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal'
import { Container, Row, Col } from 'reactstrap';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import XMLParser from 'react-xml-parser';
import FilePicker from 'components/File/FilePicker';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClipLoader from "react-spinners/ClipLoader";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import MapTile from "components/Tiles/MapTile.js";
import MapIcon from '@mui/icons-material/Map';
import Update from "@mui/icons-material/Update";
import BackupIcon from '@mui/icons-material/Backup';
import Chip from '@mui/material/Chip';
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring, HeaderFilter } from 'devextreme-react/data-grid';
import { CreateUrl, CallGetAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    dropZone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
};

const style = createStyles(styles);
let fileReader: FileReader;

interface Region {
    id: number | null;
    name: string;
    xml: XMLDocument | null;
    kmlFile: string | null;
    rawXml: string | ArrayBuffer | null;
    FkCompanyId: number;
    lastUpdate: Date | null;
    created: Date | null;
    zone: Zone | null;
}

interface Zone {
    coordinates: Array<{ latitude: number; longitude: number }>;
    center: Array<number>;
    kmlFile: string | null;
    name: string;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
        dropZone: string;
    };
    groupId: number;
    regionOptions: { value: number; label: string }[];
    history: {
        goBack: () => void;
    };
}


interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    selectedLogger: number;
    modal: boolean;
    colour: string;
    edit: boolean;
    editRegion: boolean;
    tempRegion: Region;
    anchorEl: HTMLElement | null;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        region: Region;
    } | null;
    mapModal: boolean;
    modalTitle: string;
    tempKMLFileName: string;
    deleteModal: boolean;
}

export class Regions extends Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;

    constructor(props: Readonly<Props>) {
        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: [],
            errorHandler: (error: Error): void => {
                console.log(error.message);
            }
        });

        this.state = {
            tableHidden: true,
            visible: false,
            selectedLogger: 0,
            modal: false,
            colour: "",
            edit: false,
            editRegion: false,
            tempRegion: {
                id: -1,
                name: 'New Region',
                xml: null,
                kmlFile: 'No file selected',
                rawXml: '',
                FkCompanyId: parseInt(sessionStorage.getItem('companyId') || '0'),
                lastUpdate: null,
                created: null,
                zone: null
            },            anchorEl: null,
            loading: true,
            authorized: true,
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            mapModal: false,
            modalTitle: "",
            tempKMLFileName: 'No file selected',
            deleteModal: false,
        };

        this.gridRef = React.createRef();
        this.store.clear();
    }


    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (): void => {
        NotificationManager.error('Error creating new record', '', 5000);
    };

    createConflictNotification = (): void => {
        NotificationManager.warning('Changes conflict with another user. Refreshing regions', 'Click to retry', 5000, () => {
            this.reloadData(this.state.tempRegion.id);
        });
    };


    componentDidMount(): void {

        this.reloadData(null);
    }

    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {

        const me = this;
       
        //fetch regions
        CallGetAPI(CreateUrl('/api/aquaguard/RegionsForCompany?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {

                me.store.clear();
                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent

                    const records = [];
                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].id;
                        const rec: Region = {
                            id: id,
                            name: data[i].name,
                            kmlFile: data[i].kmlFile,
                            FkCompanyId: data[i].fkCompanyId,
                            rawXml: null,
                            xml: null,
                            lastUpdate: data[i].lastUpdate,
                            created: data[i].created,
                            zone: null
                        };
                        records.push({ type: 'insert', data: rec, index: i });
                    }

                    me.store.push(records);
                       
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })

                    if (editId != null) {
                        me.store.byKey(editId)
                            .then((region) => {

                                this.setState(
                                    {
                                        tempRegion: region,
                                        editRegion: true,
                                        modal: !me.state.modal,
                                        anchorEl: null,
                                        modalTitle: "Edit Region"
                                    });
                            });
                    }
                            
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function (error) {
                me.setState(
                    {
                        loading: false
                    })
                console.log(error)
            });
        
    }



    //new row
    newRegion = (): void => {
        this.setState({
            modal: !this.state.modal,
            editRegion: false,
            tempRegion: {
                id: -1,
                name: 'New Region',
                xml: null,
                kmlFile: 'No file selected',
                rawXml: '',
                FkCompanyId: parseInt(sessionStorage.getItem('companyId') || '0'),
                lastUpdate: null,
                created: null,
                zone: null
            },
            modalTitle: "New Region"
        });


        
    }
    //edit row
    editRegion = (): void => {
        this.setState({
            editRegion: true,
            modal: !this.state.modal,
            modalTitle: "Edit Region",
            anchorEl: null,
        });
        
    }

    
    //save changes, for both edit and new
    saveRegion = (): void => {

        const me = this;

        this.setState({
            modal: !this.state.modal,
            loading: true,
        });

        if (this.state.editRegion === false) { //adding a new region
            const tmpRegion = this.state.tempRegion;

            //********************
            //send model to API
            //save group
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json-patch+json' },
                //mode: 'no-cors',
                body: JSON.stringify(tmpRegion)
            };

            CallGetAPI(CreateUrl('/api/aquaguard/RegionKML'), requestOptions)
                .then(data => {
                    console.log(data);
                    const region = me.state.tempRegion;
                    region.id = data.id;
                    region.lastUpdate = data.lastUpdate;
                    me.store.push([{ type: 'insert', key: region.id, data: region }]);
                    me.setState({
                        tempRegion: region,
                    });
                    me.createSuccessNotification();
                    //UI update
                    //me.reloadData(null);
                    me.setState({
                        loading: false,
                    });

                })
                .catch(function (error) {
                    me.createFailedInsertErrorNotification();
                    console.log(error);
                    me.setState({
                        loading: false,
                    });
                });

        }
        else {//modifying an existing region

            const region = this.state.tempRegion;

            //********************
            //send model to API
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json-patch+json' },
                //mode: 'no-cors',
                body: JSON.stringify(region),
            };

            CallPutAPI(CreateUrl('/api/aquaguard/RegionKML?id=' + region.id), requestOptions)
                .then(async response => {
                    console.log(response);
                    if (response.status == 409) {
                        // Conflicting edit detected
                        me.createConflictNotification();
                        console.log("Edit conflict");
                        me.reloadData(region.id);
                        me.setState({
                            loading: false,
                        });
                    }
                    else {
                        if (response.status == 204) {
                            me.store.push([{ type: 'update', key: region.id, data: region }]);
                            me.setState({
                                tempRegion: region,

                            });
                            me.createSuccessNotification();
                            //UI update
//                            me.reloadData(null);
                            me.setState({
                                loading: false,
                            });
                        }
                        else {
                            me.createErrorNotification();
                            me.setState({
                                loading: false,
                            });
                        }
                    }
                })
                .catch(function (error) {
                    me.createFailedInsertErrorNotification();
                    console.log(error);
                    me.setState({
                        loading: false,
                    });
                });
        }

    }

    moreRender(region: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, region.data.id) }}><MoreHorizIcon /></IconButton>
        );
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    handleClick(event: React.MouseEvent<HTMLElement>, id: number): void {
        this.store.byKey(id)
            .then((region) => {
                this.setState(
                    {
                        tempRegion: region,
                        anchorEl: event.currentTarget
                    });
                },
                (error) => {
                    console.log(error);
                });
       
    }

    //do nothing, close the modal
    cancel = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }
    //show edit buttons
    toggleEdit = (): void => {
        this.setState({
            edit: !this.state.edit
        });
    }

    readFile =  (file: Array<File>): void => {
        fileReader = new FileReader();
        fileReader.onloadend = this.handleFileRead;
        fileReader.readAsText(file[0]);
        
        const tmpRegion = this.state.tempRegion;
        tmpRegion.kmlFile = file[0].name;
        this.setState(
            {
                tempRegion: tmpRegion,
            });
    }


    getXmlFileName= (rawXml: string): string => {
        const xml = new XMLParser().parseFromString(rawXml);
        return xml.getElementsByTagName('name')[0].value;
    }



    extractRegionData = (xml: XMLDocument, region: Region): Zone | null => {
        if(xml.getElementsByTagName('coordinates').length === 0){
            console.log('corrupt file');
            return null;
        }
        else{
            const coordinates = (xml.getElementsByTagName('coordinates')[0] as any).value;
            const kmlName = (xml.getElementsByTagName('name')[0] as any).value;
            const res: Array<string> = coordinates.split(" ");
            const coordArray: Array<{ latitude: number; longitude: number }> = [];
            res.forEach((value: string) => 
                {
                const parts = value.split(",");
                    coordArray.push({latitude: parseFloat(parts[0]), longitude: parseFloat(parts[1])});
                }
            );
            
            const zone: Zone = { coordinates: coordArray, center: this.center(coordArray), kmlFile: region.kmlFile, name: region.name}

            return zone;
        }
    }

    handleFileRead = (e: any): void => {
        const content = fileReader.result;
        const xml = new XMLParser().parseFromString(content);
        const tmpRegion = this.state.tempRegion;
        tmpRegion.rawXml = content;
        tmpRegion.xml = xml;
        tmpRegion.zone = this.extractRegionData(xml, tmpRegion);
        this.setState({
            tempRegion: tmpRegion,
        });
      };

    removeFile= (): void => {
        const tmpRegion = this.state.tempRegion;
        tmpRegion.zone = null;
        tmpRegion.kmlFile = 'No file selected';
        this.setState(
            {
                tempRegion: tmpRegion,
            });
    }

    //field changed events---
    regionNameChanged = (event: any): void => {
        const tmpRegion = this.state.tempRegion;
        tmpRegion.name = event.target.value;
        this.setState( { tempRegion: tmpRegion });
    }

    regionDetailsClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/regionDetail',
                redirectProps: {
                    region: this.state.tempRegion,
                }
            });
    }

    toggleDeleteModal = (): void => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            anchorEl: null,
            modalTitle: "Warning"
        });

    };

    deleteRow = (): void => {

        const me = this;

        this.setState({
            deleteModal: !this.state.deleteModal,
        });

        //delete from database
        CallDeleteAPI(CreateUrl('/api/aquaguard/RegionKML?id=' + this.state.tempRegion.id), {})
            .then(async response => {
                if (response.status == 200) {
                    me.createSuccessNotification();
                    //remove the old version of the row
                    this.store.push([{ type: 'remove', key: me.state.tempRegion.id }]);
                }
                else {
                    me.createErrorNotification();
                }
                console.log(response);
            })
            .catch(function (error) {
                me.createErrorNotification();
                console.log(error);
            });

    }

    //-------------------------

    center = function (arr: Array<{ latitude: number; longitude: number }>): Array<number>
    {
        let minX = 90,
            maxX = -90,
            minY = 180,
            maxY = -180;
        for (let i = 0; i < arr.length; i++)
        {
            minX = (arr[i].latitude < minX) ? arr[i].latitude : minX;
            maxX = (arr[i].latitude > maxX) ? arr[i].latitude : maxX;
            minY = (arr[i].longitude < minY) ? arr[i].longitude : minY;
            maxY = (arr[i].longitude > maxY) ? arr[i].longitude : maxY;
        }
        return [(minX + maxX) / 2, (minY + maxY) / 2];
    }

    toggleMapModal = (): void => {

        const me = this;
        if (!this.state.mapModal && this.state.tempRegion.zone == null) {
            // Need to read region coordinates now
            CallGetAPI(CreateUrl('/api/aquaguard/RegionKML?Id=' + this.state.tempRegion.id), {})
                .then(data => {
                    if (data.content != null) {
                        const rec: Region = {
                            id: data.id,
                            name: data.name,
                            rawXml: data.content,
                            xml: new XMLParser().parseFromString(data.content),
                            kmlFile: data.kmlFile,
                            lastUpdate: data.lastUpdate,
                            created: data.created,
                            FkCompanyId: data.fkCompanyId,
                            zone: null,
                        };

                        if (rec.xml !== null) {
                            rec.zone = this.extractRegionData(rec.xml, rec);
                        }
                        const record = { type: 'update', data: rec, key: data.id };
                        me.store.push([record]);

                        me.setState({
                            mapModal: !this.state.mapModal,
                            tempRegion: rec,
                            anchorEl: null
                        });
                    }
                });
        }
        else {
            this.setState({
                mapModal: !this.state.mapModal,
                anchorEl: null
            });
        }
    }

    render(): React.ReactNode {
        const { classes } = this.props;

        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
        (
            (!this.state.redirect) ?
            (
        <div>

        {/* Edit Region modal  */}
            <Modal
                show={this.state.modal}
                onHide={(): void => this.toggleModal()}
                dialogClassName="modal-50w"
                                aria-labelledby="example-custom-modal-styling-title"
                                centered
                            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.state.modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col xs={6} md={4}>
                                <TextField
                                    id="outlined-input"
                                    label="Name"
                                    variant="outlined"
                                    defaultValue={this.state.tempRegion?.name}
                                    onChange={this.regionNameChanged}
                                />
                            </Col>
                           
                            <Col  md={8}>
                                {!this.state.tempRegion?.zone &&
                                                    <div className='row align-items-center'>
                                                        <h6>Upload KML file:</h6>
                                                        <FilePicker 
                                                                onChange={this.readFile}
                                                        >
                                                        <IconButton aria-label="delete" size="large">
                                                            <BackupIcon />
                                                        </IconButton>
                                                        </FilePicker>
                                                    </div>
                                   
                                }
                                {this.state.tempRegion?.zone &&
                                    <Chip 
                                    style={{
                                        position: 'absolute', left: '15%', top: '50%',
                                        transform: 'translate(-0%, -50%)'
                                        }}
                                        label={this.state.tempRegion.kmlFile} onDelete={(): void => this.removeFile()} color="primary" />
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.saveRegion()}>Save</Button>
                    <Button color="secondary" variant="contained" onClick={(): void => this.toggleModal()}>Cancel</Button>
                </Modal.Footer>
            </Modal>

           {/* Delete Region modal  */}

            <Modal
                show={this.state.deleteModal}
                onHide={(): void => this.toggleDeleteModal()}
                dialogClassName="modal-100w"
                                aria-labelledby="example-custom-modal-styling-title"
                                centered
                            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.state.modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    Delete Region {this.state.tempRegion?.name}?
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained" style={{ marginRight: "10px" }} color="primary" onClick={(): void => this.deleteRow()}>Continue</Button>
                <Button color="secondary" variant="contained" onClick={(): void => this.toggleDeleteModal()}>Cancel</Button>
                </Modal.Footer>
            </Modal>

           {/* Region map modal  */}

            <Modal
                style={{ backgroundColor: 'rgba(52, 52, 52, 0.8)' }}
                show={this.state.mapModal}
                onHide={(): void => this.toggleMapModal()}
                dialogClassName="modal-100w"
                                aria-labelledby="example-custom-modal-styling-title"
                                centered
                            >
                <Modal.Body className="show-grid">
                                    {this.state.tempRegion?.zone &&
                                        <MapTile
                                            icon={<MapIcon />}
                                            color={'success'}
                                            title={this.state.tempRegion?.name}
                                            content={''}
                                            footerContent={''}
                                            footerIcon={<Update />}
                                            sites={[]}
                                            zones={[this.state.tempRegion?.zone]}
                                            zoom={9}
                                            center={this.state.tempRegion?.zone?.center}
                                        />
                                    }
                </Modal.Body>
                <Modal.Footer>
                <Button color="secondary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.toggleMapModal()}>Close</Button>
                </Modal.Footer>
            </Modal>

        {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
            <div>

            {this.state.tableHidden &&

                    <ApiFailed />
            }
            {!this.state.tableHidden &&
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                 <div style={{ display: 'flex',justifyContent: 'center' }}>                                 
                                    <IconButton
                                        style={{ display: 'inline' }}
                                        onClick={(): void => this.props.history.goBack()}
                                        size="large"><ArrowBackIcon style={{ fill: "white" }} />
                                    </IconButton>
                                    <h4 style={{ display: 'inline', paddingTop: 12}} className={classes.cardTitleWhite}>Regions</h4>
                                </div>  
                                <IconButton aria-label="add" onClick={(): void => this.newRegion()} size="large">
                                    <AddCircleOutlineIcon style={{ fill: "white" }} />
                                </IconButton>
                            </CardHeader>
                            <CardBody>
                               
                                
                                <DataGrid
                                
                                repaintChangesOnly={true}
                                dataSource={new DataSource({ store: this.store })}
                                ref={this.gridRef} >
                                <StateStoring enabled={true} type="localStorage" storageKey="regionListGrid" />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />

                                <Column dataField="name" visible={true} dataType="string" />
                                <Column dataField="kmlFile" visible={true} dataType="string" allowHeaderFiltering={false}/>
                                
                                <Column fixed={true} fixedPosition='right'  type="buttons" cellRender={this.moreRender.bind(this)} />
                                
                                
                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                <Paging defaultPageSize={10} />
                                </DataGrid>
                               
                               
                             
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                  }

                  </div>
              
            }
                    <Menu
                    
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                        disableScrollLock={true}
                    >
                        <MenuItem onClick={this.editRegion}>Edit</MenuItem>
                        <MenuItem onClick={this.toggleMapModal}>View on map</MenuItem>
                        <MenuItem onClick={this.toggleDeleteModal}>Delete</MenuItem>
                    </Menu>

                    <NotificationContainer />

        </div>
            )
            :
            (<Redirect push to={{
                pathname: this.state.redirectPath,
                state: this.state.redirectProps
            }}/>)
        )
        :
        (
            <Redirect to={'/Login'} />
        );
    }
}


export default withStyles(style)(Regions);

