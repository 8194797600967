import React, { ReactNode } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { MenuItem, Select } from '@mui/material';
// @mui/icons-material
import Clear from "@mui/icons-material/Clear";
import Check from "@mui/icons-material/Check";


interface Props {
    classes: any;
    labelText: ReactNode;
    labelProps: any;
    id: string;
    inputProps: any;
    formControlProps: any;
    error: boolean;
    success: boolean;
}


export default function SampleRate(props: Props) {
    const {
        classes,
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        success
    } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });
    const marginTop = classNames({
        [classes.marginTop]: labelText === undefined
    });
    const inputValue = inputProps.value;
    const valueNum = parseInt(inputValue.substring(0, inputValue.length - 1));
    const valueUnit = inputValue.substr(inputValue.length - 1, 1)

    return (
            <div>
            <FormControl
                {...formControlProps}
                className={formControlProps.className + " " + classes.formControl}
                style={{margin: 0, paddingBottom: 0}}
            >
                {labelText !== undefined ? (
                    <InputLabel
                        className={classes.labelRoot + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                    >
                        {labelText}
                    </InputLabel>
                ) : null}
                <Select
                    classes={{
                        root: marginTop,
                        disabled: classes.disabled,
                    }}
                    id={id}
                    value={valueNum}
                    disabled={inputProps.disabled}
                    name={inputProps.name + "Value"}
                    onChange={inputProps.onChange}
                >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={60}>60</MenuItem>
                </Select>
                {error ? (
                    <Clear className={classes.feedback + " " + classes.labelRootError} />
                ) : success ? (
                    <Check className={classes.feedback + " " + classes.labelRootSuccess} />
                ) : null}
            </FormControl>
            <FormControl
                    {...formControlProps}
                    className={formControlProps.className + " " + classes.formControl}
                    style={{ margin: 0, paddingBottom: 0 }}
                >
                    {labelText !== undefined ? (
                        <InputLabel
                            className={classes.labelRoot + labelClasses}
                            htmlFor={id}
                            {...labelProps}
                        >
                            {labelText}
                        </InputLabel>
                    ) : null}
                <Select
                    classes={{
                        root: marginTop,
                        disabled: classes.disabled,
                    }}
                    id={id}
                    value={valueUnit}
                    disabled={inputProps.disabled}
                    name={inputProps.name + "Unit"}
                    onChange={inputProps.onChange}
                >
                    <MenuItem value="s">s</MenuItem>
                    <MenuItem value="m">m</MenuItem>
                </Select>
                {error ? (
                    <Clear className={classes.feedback + " " + classes.labelRootError} />
                ) : success ? (
                    <Check className={classes.feedback + " " + classes.labelRootSuccess} />
                ) : null}
            </FormControl>
            </div>
    );
}

SampleRate.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool
};