import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { APIUnproceeedBlocksModel, buildAPIUnproceeedBlocksModel } from "models/APIUnproceeedBlocksModel";

// DevExtreme
import { DataGrid, Column, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';

import { CreateUrl, CallGetAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import dxDataGrid, { dxDataGridRowObject } from "devextreme/ui/data_grid";

const styles: Record<string, any> = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        display: "inline",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Props {
    classes: {
        cardTitleWhite: string;
        cardCategoryWhite: string;
    };
    history: {
        goBack: () => void;
    };
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    connections: APIUnproceeedBlocksModel[];
    tempConnection: APIUnproceeedBlocksModel | undefined;
    anchorEl: EventTarget & HTMLButtonElement | null;
    redirect: boolean;
    redirectPath: string | undefined;
    redirectProps: { serial: string | undefined } | undefined;
}

class UnprocessedBlocksList extends React.Component<Props, State> {

    gridRef: React.RefObject<DataGrid>;

    constructor(props: Props | Readonly<Props>) {
        super(props);
        // Ref to Datagrid to store Page, Sort & Filters
        this.gridRef = React.createRef();

        this.state = {
            tableHidden: false,
            visible: false,
            connections: [],
            tempConnection: undefined,
            anchorEl: null,
            loading: true,
            authorized: true,
            redirect: false,
            redirectPath: undefined,
            redirectProps: undefined
        };

    }
    get dataGrid(): dxDataGrid | undefined {
        return this.gridRef.current?.instance;
    }

    createSuccessNotification = (): void => {
        NotificationManager.success('Processing blocks', 'Success');

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Processing blocks', 'Click me!', 5000, () => {
            alert('callback');
        });
    };


    componentDidMount(): void {

        this.reload();
    }

    reload(): void {
        let connData = new Array<APIUnproceeedBlocksModel>();
 
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/UnprocessedBlocks?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {
                if (data.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    connData = buildAPIUnproceeedBlocksModel(data);

                    me.setState(
                        {
                            connections: connData,
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            connections: [],
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function (error) {
                me.setState(
                    {
                        connections: [],
                        tableHidden: false,
                        loading: false
                    })
                console.log(error);
            });
            
    }
    handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: dxDataGridRowObject): void {   //React.MouseEvent<HTMLButtonElement, MouseEvent>
        const connection = this.state.connections.find((row) => row.id === id.key);

        this.setState(
            {
                tempConnection: connection,
                anchorEl: event.currentTarget
            });
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }


    connectionDetailsClick = (): void => {

        if (this.state.tempConnection?.loggerSerial != null) {
            this.setState(
                {
                    redirect: true,
                    redirectPath: '/portal/DeviceDetail',
                    redirectProps: {
                        serial: this.state.tempConnection?.loggerSerial.toString()
                    }
                });
        }
        else {
            this.setState({ anchorEl: null });
        }
    }

    reprocessClick = (): void => {

        const me = this;

        // Close menu
        this.setState({ anchorEl: null });

        if (this.state.tempConnection != null) {
            //save site
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify([this.state.tempConnection])
            };

            CallPostAPI(CreateUrl('/api/aquaguard/UnprocessedBlocks'), requestOptions)
                .then(() => {
                    me.createSuccessNotification();
                    me.reload();
                })
                .catch (function (error) {
                me.createErrorNotification();
                console.log(error);
                });
        }
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm:ss");
    }

    blocksColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return cellInfo.value.join(',');
    }


    moreRender(key: dxDataGridRowObject): ReactNode {
        return (
            <IconButton size="small" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => { this.handleClick(e, key); }}><MoreHorizIcon /></IconButton>
        );
    }

    render(): ReactNode {
        const { classes, history } = this.props;

        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                 (!this.state.redirect) ?
                (<div>

                    {this.state.loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    }
                    {!this.state.loading &&
                        <div>

                            {this.state.tableHidden &&

                                    <ApiFailed />
                            }
                            {!this.state.tableHidden &&
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                        <CardHeader color="primary">
                                            <div style={{ display: 'inline' }}>
                                                <IconButton
                                                    style={{ display: 'inline' }}
                                                    onClick={(): void => history.goBack()}
                                                    size="large"><ArrowBackIcon style={{ fill: "white" }} />
                                                </IconButton>
                                                <h4 className={classes.cardTitleWhite}>Unprocessed blocks</h4>
                                            </div>
                                            <p className={classes.cardCategoryWhite}>
                                                Unprocessed log blocks received by the server
                                            </p>
                                        </CardHeader>
                                        <CardBody>
                                            <DataGrid
                                            dataSource={this.state.connections}
                                            keyExpr="id"
                                            ref={this.gridRef}
                                            columnAutoWidth={true}
                                            columnMinWidth={80} >
                                            <StateStoring enabled={true} type="localStorage" storageKey="unprocessedBlocksListGrid" />
                                            <GroupPanel visible={true} />
                                            <Grouping autoExpandAll={true} />
                                            <FilterRow visible={true} />
                                            <Column dataField="id" visible={false} dataType="number" />
                                            <Column dataField="loggerSerial" name="Logger" dataType="string" />
                                            <Column dataField="connectionTime" name="Connection" customizeText={this.dateColumnCustomizeText} dataType="datetime" width="150" defaultSortIndex={1} defaultSortOrder="desc" />
                                            <Column dataField="blockIds" name="Blocks" customizeText={this.blocksColumnCustomizeText} dataType="string" />

                                            <Column type="buttons" cellRender={this.moreRender.bind(this)} fixed={true} fixedPosition="right" />

                                            <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                            <Paging defaultPageSize={10} />
                                            </DataGrid>
                                        </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            }
                        </div>
                    }
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                    >
                            <MenuItem onClick={this.reprocessClick}>Process blocks</MenuItem>
                            <MenuItem onClick={this.connectionDetailsClick}>Details</MenuItem>
                    </Menu>

                        <NotificationContainer />
                </div>)
                :
                 (<Redirect push to={{
                    pathname: this.state.redirectPath,
                    state: this.state.redirectProps
                }}/>)
            )
            :
            (
                <Redirect to={'/Login'} />
            );
    }
}

export default withStyles(style)(UnprocessedBlocksList);

