import {
    container,
    defaultFont,
    primaryColor,
    defaultBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    whiteColor,
    blackColor,
    grayColor,
    colour5,
    Ashridge
} from "assets/jss/material-dashboard-react.js";
import { BackgroundImage } from "devextreme-react/range-selector";


const headerStyle = () => ({
    appBar: {
       
        backgroundColor: window.location.hostname.includes('zonelog.net') ? 'none' : Ashridge + " !important",
        backgroundImage: window.location.hostname.includes('zonelog.net') ? `linear-gradient(45deg, ${Ashridge}, #13B5EA , #0079C1)` : 'none',
        /*backgroundImage: `linear-gradient(45deg, ${Ashridge}, #13B5EA , #0079C1)`,*/
        boxShadow: "none",
        borderBottom: "0",
        marginBottom: "0",
        position: "absolute",
        width: "100%",
//        paddingTop: "10px",
        zIndex: "1029",
        color: grayColor[7],
        border: "0",
        borderRadius: "0px",
        padding: "2px 0",
        transition: "all 150ms ease 0s",
        minHeight: "50px",
        display: "block"
    },
    container: {
        ...container,
        minHeight: "50px"
    },
    flex: {
        flex: 1
    },
    title: {
        ...defaultFont,
        letterSpacing: "unset",
        lineHeight: "30px",
        fontSize: "18px",
        borderRadius: "3px",
        textTransform: "none",
        color: whiteColor,
        margin: "0",
        "&:hover,&:focus": {
            background: "transparent"
        }
    },
    appResponsive: {
        top: "8px"
    },
    primary: {
        backgroundColor: primaryColor[0],
        color: whiteColor,
        ...defaultBoxShadow
    },
    info: {
        backgroundColor: infoColor[0],
        color: whiteColor,
        ...defaultBoxShadow
    },
    success: {
        backgroundColor: successColor[0],
        color: whiteColor,
        ...defaultBoxShadow
    },
    warning: {
        backgroundColor: warningColor[0],
        color: whiteColor,
        ...defaultBoxShadow
    },
    danger: {
        backgroundColor: dangerColor[0],
        color: whiteColor,
        ...defaultBoxShadow
    }
});

export default headerStyle;
