/* /src/routes/privateRoute.jsx */
import React from "react";
import { Route } from "react-router-dom";
import { AuthConsumer } from "../auth/authProvider.jsx";

/* PrivateRoute: this component validates the user access,
 * if the user is not authenticated they will be redirected to the login screen and after will come back to the requested route.
 */

export const PrivateRoute = ({ component, ...rest }) => {
    // eslint-disable-next-line react/display-name
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect }) => {
                if (!!Component && isAuthenticated()) {
                    return <Component {...props} />;
                } else {
                    signinRedirect();
                    return <span>loading - login</span>;
                }
            }}
        </AuthConsumer>
    );

    return <Route {...rest} render={renderFn(component)} />;
};

