
// Moment date/time formatting
// https://momentjs.com/docs/
import moment from 'moment';


// Utility function to adjust time by window.sessionStorage.getItem("utcOffset")

export const adjustTime = (time: Date): Date => {
    const adj: string | null = window.sessionStorage.getItem("utcOffset") != "null" ? window.sessionStorage.getItem("utcOffset") : "0";

    if (adj == null || adj == "0" || adj == "undefined") {
        return time;
    }
    else {
        return moment(time).add(parseInt(adj), 'h').toDate();
    }
}