import { IDENTITY_CONFIG, METADATA_OIDC } from "../auth/authConst";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";

/*
 * To manage the object provided by oidc we are going to create a class that is going to instance the object
 * and is going to store the UserManager provided by the library.
 * In this class we are going to create the methods to manage the user authentication and authorization flow.
 *
 * ref: https://medium.com/@franciscopa91/how-to-implement-oidc-authentication-with-react-context-api-and-react-router-205e13f2d49
 */

export default class AuthService {
    UserManager;

    constructor() {
        this.UserManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            metadata: {
                ...METADATA_OIDC
            }
        }); 

        // Bind this to methods
        this.signinRedirectCallback = this.signinRedirectCallback.bind(this);
        this.getUser = this.getUser.bind(this);
        this.signinRedirect = this.signinRedirect.bind(this);
        this.signinSilent = this.signinSilent.bind(this);
        this.signinSilentCallback = this.signinSilentCallback.bind(this);
        this.createSigninRequest = this.createSigninRequest.bind(this);
        this.logout = this.logout.bind(this);
        this.signoutRedirectCallback = this.signoutRedirectCallback.bind(this);

        // Logger
        Log.logger = console;
        Log.level = Log.DEBUG;
        this.UserManager.events.addUserLoaded((user) => {
            if (window.location.href.indexOf("callback") !== -1) {
                console.log("UserLoaded: " + user.profile.name);
                this.navigateToScreen();
            }
        });
        this.UserManager.events.addSilentRenewError((e) => {
            console.log("silent renew error", e.message);
            this.logout()
                .then(() => {
                    window.parent.location.replace(process.env.REACT_APP_PUBLIC_URL);
                });
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            console.log("token expired");
            this.signinSilent();
        });
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then((user) => {
            console.log("signinRedirectCallback for " + user.profile.name);
        });
    };


    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }
        
        return user;
    };

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };


    signinRedirect = () => {
        if (window.location.pathname != "/" && window.location.pathname != "/Login") {
            localStorage.setItem("redirectUri", window.location.pathname + window.location.search);
        }
        else {
            localStorage.removeItem("redirectUri");
        }
        this.UserManager.signinRedirect({
            response_type: "code"
        });
    };
    

    navigateToScreen = () => {
        if (localStorage.getItem("redirectUri")) {
            window.location.replace(localStorage.getItem("redirectUri"));
        }
        else {
            window.location.replace("/Portal/Dashboard");
        }
    };


    isAuthenticated = () => {
        const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`));

        return (!!oidcStorage && !!oidcStorage.access_token);
    };

    signinSilent = () => {

        this.UserManager.signinSilent({
            response_type: "code"
        })
            .then((user) => {
                console.log("silent signed in", user);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    signinSilentCallback = async () => {
        const lastApiCall = new Date(parseInt(sessionStorage.getItem("lastCallAPI")));
        console.log("Since LastAPI: " + (((new Date()).getTime() - lastApiCall.getTime()) / 1000));

        if (new Date(lastApiCall.getTime() + process.env.REACT_APP_SESSION_TIMEOUT_MINS * 60000) < new Date()) {
            console.log("Session expired, force logout");
            this.logout()
                .then(() => {
                    window.parent.location.replace(process.env.REACT_APP_PUBLIC_URL);
                });
        }
        else {
            this.UserManager.signinSilentCallback();
        }
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = async () => {
 //       this.UserManager.removeUser();
        const user = await this.UserManager.getUser();
        if (user) {
            this.UserManager.signoutRedirect({
                id_token_hint: user.id_token
            });
        }
        this.UserManager.clearStaleState();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            window.location.replace(process.env.REACT_APP_PUBLIC_URL);
        });
        this.UserManager.clearStaleState();
    };
}