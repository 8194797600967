import React, { ReactNode } from "react";

import GridItem from "components/Grid/GridItem.js";
import SimpleTile from "components/Tiles/SimpleTile.js";
import GraphTile from "components/Tiles/GraphTile.js";
import RefreshTile from "components/Tiles/RefreshTile";
import Icon from "@mui/material/Icon";
import Warning from "@mui/icons-material/Warning";
import Update from "@mui/icons-material/Update";
import AccessTime from "@mui/icons-material/AccessTime";
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { TileModel } from "models/DashboardModel";
import GuageTile from './GuageTile';

interface Layout {
    smallTile_xs: number;
    smallTile_sm: number;
    smallTile_md: number;

    mediumTile_xs: number;
    mediumTile_sm: number;
    mediumTile_md: number;

    largeTile_xs: number;
    largeTile_sm: number;
    largeTile_md: number;
}

interface Props {
    tile: TileModel;
    edit: boolean;
    onDelete: (tile: TileModel) => void;
    layout: Layout;
    innerRef?: (element?: HTMLElement | null | undefined) => any;
    isDragging?: boolean;
    onTileClick: (id: string) => void;
}

interface State {
    todo: number;
}

export class Tile extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            todo: 0,
        };
    }


    render(): ReactNode {

        const tile = this.props.tile;

        switch(tile.type) {
            case "Simple":
                return (
                    <GridItem xs={this.props.layout.smallTile_xs} sm={this.props.layout.smallTile_sm} md={this.props.layout.smallTile_md} key={tile.tileName}>
                        <div {...this.props} ref={this.props.innerRef} style={{ backgroundColor: this.props.isDragging ? 'lightgreen' : 'white'}}>
                        {this.props.edit &&
                            <IconButton
                                aria-label="delete"
                                onClick={(): void => this.props.onDelete(tile)}
                                size="large">
                                <DeleteForeverIcon />
                            </IconButton>
                        }
                        <SimpleTile
                            icon={<Warning />}
                            color={tile.tileColor}
                            title={tile.title}
                            content={tile.content}
                            footerContent={'Get more space'}
                            footerIcon={<Update />}
                        />
                    
                        </div>
                    </GridItem>
                );
            case "Line":
                return (
                    <GridItem xs={12} sm={12} md={4} key={tile.tileName}>
                        <div {...this.props} ref={this.props.innerRef} style={{ backgroundColor: this.props.isDragging ? 'lightgreen' : 'white' }}>
                            {this.props.edit &&
                                <IconButton
                                    aria-label="delete"
                                    onClick={(): void => this.props.onDelete(tile)}
                                    size="large">
                                    <DeleteForeverIcon />
                                </IconButton>
                            }
                            <GraphTile
                                icon={<Icon>info_outline</Icon>}
                                color={tile.tileColor}
                                title={tile.title}
                                chartType={'Line'}
                                chartData={tile.chartData}
                                content={tile.chartData.caption}
                                footerContent={'updated 4 minutes ago'}
                                footerIcon={<AccessTime />}
                                legend={tile.chartData.legend}
                            />
                    
                        </div>
                    </GridItem>
                );
            case "Bar":
                return (
                    <GridItem xs={12} sm={12} md={4} key={tile.tileName}>
                        <div {...this.props} ref={this.props.innerRef} >
                            {this.props.edit &&
                                <IconButton
                                    aria-label="delete"
                                    onClick={(): void => this.props.onDelete(tile)}
                                    size="large">
                                    <DeleteForeverIcon />
                                </IconButton>
                            }
                            <GraphTile
                                icon={<Icon>info_outline</Icon>}
                                color={tile.tileColor}
                                title={tile.title}
                                chartType={'Bar'}
                                chartData={tile.chartData}
                                content={tile.chartData.caption}
                                footerContent={'updated 4 minutes ago'}
                                footerIcon={<AccessTime />}
                                legend={tile.chartData.legend}
                            />
                        </div>
                    </GridItem>
                );
            case "Refresh":
                return (
                    <GridItem xs={this.props.layout.smallTile_xs} sm={this.props.layout.smallTile_sm} md={this.props.layout.smallTile_md} key={tile.tileName}>
                        <div {...this.props} ref={this.props.innerRef}>
                            {this.props.edit &&
                            <div style={{ backgroundColor: this.props.isDragging ? 'lightgreen' : 'transparent' }}>
                                <IconButton
                                    aria-label="delete"
                                    onClick={(): void => this.props.onDelete(tile)}
                                    size="large">
                                    <DeleteForeverIcon />
                                </IconButton>
                            </div>
                        }
                        {this.props.edit ?
                            <SimpleTile
                                icon={<Warning />}
                                color={tile.tileColor}
                                title={tile.title}
                                content={tile.content}
                                footerContent={''}
                                footerIcon={<Update />}
                            />
                            :
                            <RefreshTile
                                id={tile.tileName}
                                icon={<Warning />}
                                color={tile.tileColor}
                                title={tile.title}
                                content={tile.content}
                                footerContent={''}
                                refreshTime={tile.refreshTime}
                                onRefresh={tile.onRefresh}
                                onClick={this.props.onTileClick}
                            />
                        }
                        
                        </div>
                    </GridItem>
                );
            case "Guage":
                return (
                    <GridItem xs={this.props.layout.smallTile_xs} sm={this.props.layout.smallTile_sm} md={this.props.layout.smallTile_md} key={tile.tileName}>
                        <div {...this.props} ref={this.props.innerRef}>
                            {this.props.edit &&
                                <div style={{ backgroundColor: this.props.isDragging ? 'lightgreen' : 'transparent' }}>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={(): void => this.props.onDelete(tile)}
                                        size="large">
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </div>
                            }
                            {this.props.edit ?
                                <SimpleTile
                                    icon={<Warning />}
                                    color={tile.tileColor}
                                    title={tile.title}
                                    content={tile.content}
                                    footerContent={''}
                                    footerIcon={<Update />}
                                />
                                :
                                <GuageTile
                                    id={tile.tileName}
                                    icon={<Warning />}
                                    color={tile.tileColor}
                                    title={tile.title}
                                    content={tile.content}
                                    footerContent={''}
                                    refreshTime={tile.refreshTime}
                                    onRefresh={tile.onRefresh}
                                    onClick={this.props.onTileClick}
                                />
                            }

                        </div>
                    </GridItem>
                );

            default:
                return null;
            }
    }
}