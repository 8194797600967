import { Checkbox } from "@mui/material";
import React, { ReactNode } from "react";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    channels: string[];
    channelFilters: boolean[];
    onFilterChange: (channelFilter: boolean[]) => void;
}

interface State {
    ChannelA: boolean;
    ChannelB: boolean;
    ChannelC: boolean;
    ChannelD: boolean;
    ChannelE: boolean;
}

export class ChannelFilter extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            ChannelA: props.channelFilters[0],
            ChannelB: props.channelFilters[1],
            ChannelC: props.channelFilters[2],
            ChannelD: props.channelFilters[3],
            ChannelE: props.channelFilters[4],
        };
    }

    filterChange(e: any): void {
        const filter = [this.state.ChannelA, this.state.ChannelB, this.state.ChannelC, this.state.ChannelD, this.state.ChannelE]
        if (e.target.value == "A") {
            this.setState({ ChannelA: !this.state.ChannelA });
            filter[0] = !this.state.ChannelA;
        }
        if (e.target.value == "B") {
            this.setState({ ChannelB: !this.state.ChannelB });
            filter[1] = !this.state.ChannelB;
        }
        if (e.target.value == "C") {
            this.setState({ ChannelC: !this.state.ChannelC });
            filter[2] = !this.state.ChannelC;
        }
        if (e.target.value == "D") {
            this.setState({ ChannelD: !this.state.ChannelD });
            filter[3] = !this.state.ChannelD;
        }
        if (e.target.value == "E") {
            this.setState({ ChannelE: !this.state.ChannelE });
            filter[4] = !this.state.ChannelE;
        }
        this.props.onFilterChange(filter);
    }

    render(): ReactNode {

        return (
            <div >
                { this.props.channels[0] != "" && <span><Checkbox checked={this.state.ChannelA} value={"A"} onChange={this.filterChange.bind(this)}/>{this.props.channels[0]}</span>}
                { this.props.channels[1] != "" && <span><Checkbox checked={this.state.ChannelB} value={"B"} onChange={this.filterChange.bind(this)}/>{this.props.channels[1]}</span>}
                { this.props.channels[2] != "" && <span><Checkbox checked={this.state.ChannelC} value={"C"} onChange={this.filterChange.bind(this)}/>{this.props.channels[2]}</span>}
                { this.props.channels[3] != "" && <span><Checkbox checked={this.state.ChannelD} value={"D"} onChange={this.filterChange.bind(this)}/>{this.props.channels[3]}</span>}
                { this.props.channels[4] != "" && <span><Checkbox checked={this.state.ChannelE} value={"E"} onChange={this.filterChange.bind(this)}/>{this.props.channels[4]}</span>}
            </div>
        );

    }
}
export default ChannelFilter;