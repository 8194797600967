import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import CardBody from "components/Card/CardBody.js";


import WebMapView from "components/Map/WebMapView";

import { withStyles, createStyles } from '@mui/styles';
const style = createStyles(styles);

export class MapTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        
        console.log(props.center);
    }

    render() {
       

        return (
            <div >
                <Card>
                    <CardHeader stats icon>

                        <CardIcon color={this.props.color}>
                            {this.props.icon}
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <WebMapView center={this.props.center}
                            zoom={this.props.zoom}
                            style={{ overflow: "hidden" }}
                            trunkSegments={this.state.trunkSegments}
                            regions={this.props.zones}
                            sites={this.props.sites}
                            showAlarmOverlay={this.state.showAlarmOverlay}
                            showRegions={this.props.zones?.length > 0}
                            showSites={this.props.sites?.length > 0}
                            showAllSites={true}
                            showRainfall={false}
                            alarmFilter={-1}
                            singlePoints={this.props.singlePoints}
                            singlePointsDesc={this.props.singlePointsDesc}
                            mouseWheelZoom={true}
                        />
                    </CardBody>
                    
                </Card>
            </div>
        );

    }
}
export default withStyles(style)(MapTile);