import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Modal from 'react-bootstrap/Modal'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import ClipLoader from "react-spinners/ClipLoader";
import MaterialButton from '@mui/material/Button';
import { Redirect } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NotificationContainer, NotificationManager } from 'react-notifications';

// DevExtreme
import { DataGrid, Column, Export, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import { CreateUrl, CallGetAPI, CallPostAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import dxDataGrid, { dxDataGridRowObject } from "devextreme/ui/data_grid";
import DateBox from 'devextreme-react/date-box';
import Select from 'react-select'
// import makeAnimated from 'react-select/animated';

import { Grid } from '@mui/material';
import UndeployDialog from '../../components/Device/UndeployDialog';
import DeployDialog from '../../components/Device/DeployDialog';

// const animatedComponents = makeAnimated();

const styles: Record<string, any> = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

let modalTitle = "";

const style = createStyles(styles);

interface History {
    id: number;
    startDate: Date;
    endDate: Date | null;
    configGuid: string;
    serial: string;
    loggerId: number;
    site: string;
    lastDatapoint: Date | null;
    siteName: string;
    siteRef: string;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        cardCategoryWhite: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: {
            siteName: string;
            siteId: number | undefined;
            serial: string | undefined;
            deviceId: number | undefined;
        };
    };
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    selectedLogger: number;
    history: Array<History>;
    filterValue: Array<string | string[]>;
    tempDevice: History | undefined;
    anchorEl: EventTarget & HTMLButtonElement | null;
    deleteModal: boolean;
    modal: boolean;
    redirect: boolean;
    redirectPath: string | undefined;
    redirectProps: { serial: string | undefined } | undefined;
    deployModal: boolean;
    fromDate: Date | undefined;
    sites: Array<{ value: number; label: string; lastUpdate: Date }>;
    deployed: boolean;
}

class DeploymentList extends React.Component<Props, State> {

    gridRef: React.RefObject<DataGrid>;

    constructor(props: Props | Readonly<Props>) {
        super(props);
        // Ref to Datagrid to store Page, Sort & Filters
        this.gridRef = React.createRef();

        this.state = {
            tableHidden: false,
            visible: false,
            selectedLogger: 0,
            history: [],
            loading: true,
            authorized: true,
            filterValue: [],
            tempDevice: undefined,
            anchorEl: null,
            deleteModal: false,
            modal: false,
            redirect: false,
            redirectPath: undefined,
            redirectProps: undefined,
            deployModal: false,
            fromDate: undefined,
            sites: [],
            deployed: false,
        };

    }
    get dataGrid(): dxDataGrid | undefined {
        return this.gridRef.current?.instance;
    }

    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success');

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createConflictNotification = (): void => {
        NotificationManager.warning('Changes conflict with another user. Refreshing sites', 'Click to retry', 5000, () => {
            this.reloadData(this.state.tempDevice?.id || null);
        });
    };



    componentDidMount(): void {

        this.reloadData(null);

    }

    reloadData(editId: number | null): void {
        const historyData = new Array<History>();
 
        const me = this;

        if (this.props.location.state.serial) {
            CallGetAPI(CreateUrl('/api/aquaguard/HistoryForLogger?serial=' + this.props.location.state.serial), {})
                .then(data => {
                    if (data.length > 0) {
                        let deployed = false;
                        let rec: History | undefined = undefined;
                        // Copy the data records into deviceData, adding the clickEvent
                        historyData.length = 0; //clear dummy data
                        for (let i = 0; i < data.length; i++) {
                            const serial = data[i].serial;
                            const id = parseInt(data[i].id);
                            rec = {
                                id: id,
                                startDate: adjustTime(data[i].startDate),
                                endDate: adjustTime(data[i].endDate),
                                configGuid: data[i].configGuid,
                                serial: serial,
                                loggerId: data[i].loggerId,
                                site: data[i].siteId ? data[i].siteId.replace(/[^A-Za-z0-9]/g, '') : '',
                                lastDatapoint: data[i].lastDatapoint ? adjustTime(moment(data[i].lastDatapoint).toDate()) : null,
                                siteRef: data[i].sitename,
                                siteName: data[i].siteNameUserFriendly,
                            };
                            historyData.push(rec);
                            if (data[i].endDate == null) {
                                deployed = true;
                            }
                        }

                        me.setState(
                            {
                                history: historyData,
                                tempDevice: rec,
                                deployed: deployed,
                                tableHidden: false,
                                loading: false
                            })

                        if (editId != null) {
                            const device = me.state.history.filter(d => d.id == editId);
                            this.setState(
                                {
                                    tempDevice: device[0],
                                    modal: !me.state.deployModal,
                                    anchorEl: null,
                                });

                        }
                    }
                    else {
                        me.setState(
                            {
                                history: [],
                                tableHidden: false,
                                loading: false
                            })
                        console.log("No devices")
                    }
                },
                    // reject() - API error
                    () => {
                        me.setState(
                            {
                                history: [],
                                tableHidden: true,
                                loading: false
                            });
                        console.log("API Error")

                    })
                .catch(function () {
                    me.setState(
                        {
                            authorized: false
                        })
                });
        }
        else {
            me.setState(
                {
                    history: [],
                    tableHidden: false,
                    loading: false
                })
            console.log("No serial")

        }
            
    }

    getSites(): void {
        const me = this;

        const sites: Array<{ value: number; label: string; lastUpdate: Date }> = []

        CallGetAPI(CreateUrl('/api/aquaguard/SitesForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {
                if (data.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    for (let i = 0; i < data.length; i++) {
                        const siteId = parseInt(data[i].siteId);
                        const rec = {
                            value: siteId,
                            label: data[i].siteName,
                            lastUpdate: data[i].lastUpdate,
                        };
                        sites.push(rec);
                    }

                    me.setState(
                        {
                            sites: sites,
                        });
                }
                else {
                    me.setState(
                        {
                            sites: [],
                        });
                    console.log("No sites");
                }
            },
                // reject() - API error
                () => {
                    me.setState(
                        {
                            sites: [],
                        });
                    console.log("API Error");

                })
            .catch(function () {
                console.log("API Error");
            });
    }

    handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: dxDataGridRowObject): void {   //React.MouseEvent<HTMLButtonElement, MouseEvent>
        const device = this.state.history.find((row) => row.id === id.key);

        this.setState(
            {
                tempDevice: device,
                anchorEl: event.currentTarget
            });

        if (this.state.sites.length == 0) {
            this.getSites();
        }
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    //edit row
    editDevice = (): void => {
        this.setState({
            modal: !this.state.modal,
            anchorEl: null
        });
        modalTitle = "Edit Device"
    }

    toggleDeleteModal = (): void => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            anchorEl: null
        });
        modalTitle = "Warning"
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }




    //-------------------------


    deviceDetailsClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/DeviceDetail',
                redirectProps: { 
                            serial: this.state.tempDevice?.serial
                        }
            });
    }


    toggleDeployModal = (): void => {
        this.setState({
            deployModal: !this.state.deployModal,
            anchorEl: null,
            fromDate: new Date()
        });
        /*
        if (this.state.tempDevice?.deployed == "Spare") {
            modalTitle = "Deploy logger";
        }
        else {
            modalTitle = "Terminate logger deployment";
        }
        */
    };

    fromDateChanged = (event: any): void => {
        this.setState({ fromDate: event.value });
    }

    //field changed events---
    deviceSiteChanged = (event: any): void => {
        const tempDevice = this.state.tempDevice;
        if (tempDevice) {
            tempDevice.site = event.target.value;
            this.setState({ tempDevice: tempDevice });
        }
    }

    deployConfirm = (): void => {

        const me = this;
/*
        const loggerIds: Array<{ Id: number }> = [{ Id: this.state.tempDevice?.id || 0 }];
        if (this.state.tempDevice?.deployed == "Spare") {

            //save logger connected to site
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    siteId: this.state.tempDevice.siteId,
                    latUpdate: null,
                    loggers: loggerIds,
                    assignLogReadingsFromDate: this.state.fromDate,
                }),
            };

            CallPostAPI(CreateUrl('/api/Aquaguard/AssignLoggersToSite'), requestOptions)
                .then(async response => {
                    if (response.status == 200) {
                        me.createSuccessNotification();
                        //UI update
                        me.reloadData(null);
                    }
                    else {
                        me.createErrorNotification();
                    }
                    console.log(response);
                })
                .catch(function (error) {
                    me.createErrorNotification();
                    console.log(error);
                });
        }
        else {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    siteId: this.state.tempDevice?.siteId,
                    LastUpdate: new Date(),
                    loggers: loggerIds,
                    assignLogReadingsFromDate: this.state.fromDate
                }),
            };

            CallPostAPI(CreateUrl('/api/Aquaguard/DeassignLoggersFromSite'), requestOptions)
                .then(async response => {
                    if (response.status == 200) {

                            me.createSuccessNotification();
                            //UI update
                            me.reloadData(null);
                    }
                    else {
                        if (response.status == 409) {
                            me.createConflictNotification();
                            me.reloadData(this.state.tempDevice?.id || null);
                        }
                        else
                            me.createErrorNotification();
                    }
                    console.log(response);
                })
                .catch(function (error) {
                    me.createErrorNotification();
                    console.log(error);
                });
        }
*/
    }

    dialogResponse(result: number, update: boolean): void {

        if (result == 0) {
            this.createSuccessNotification();
            this.toggleDeployModal();
            if (update) {
                //UI update - after 2 secs to allow server to update deployment
                setTimeout(this.reloadData.bind(this), 2000);
            }
        }
        else {
            this.createErrorNotification();
        }
    }


    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm:ss");
    }

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Readings');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    moreRender(key: dxDataGridRowObject): ReactNode {
        return (
            key.data.endDate != null ?
                <div></div>
                :
                <IconButton size="small" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => { this.handleClick(e, key); }}><MoreHorizIcon /></IconButton>
        );
    }

    render(): ReactNode {
        const { classes } = this.props;
        if (this.state.deployed ) {
            modalTitle = "Terminate device deployment - " + this.state.tempDevice?.serial;
        }
        else {
             modalTitle = "Deploy device " + this.state.tempDevice?.serial;
        }


        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                 (!this.state.redirect) ?
                (<div>
                        {this.state.deployed ?
                            <UndeployDialog
                                show={this.state.deployModal}
                                title={modalTitle}
                                loggerId={this.state.tempDevice ? [this.state.tempDevice.loggerId] : []}
                                onSubmit={this.dialogResponse.bind(this)}
                                onCancel={this.toggleDeployModal.bind(this)}
                                isChariot={this.state.tempDevice ? this.state.tempDevice.loggerId > 500000 : false}
                            />
                            :
                            <DeployDialog
                                show={this.state.deployModal}
                                title={modalTitle}
                                site={undefined}
                                loggerId={this.state.tempDevice?.loggerId}
                                configurationId={undefined}
                                onSubmit={this.dialogResponse.bind(this)}
                                onCancel={this.toggleDeployModal.bind(this)}

                            />

                            }
                        {/*
                    <Modal
                        show={this.state.deployModal}
                        onHide={(): void => this.toggleDeployModal()}
                        dialogClassName="modal-100w"
                        aria-labelledby="example-custom-modal-styling-title">
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                                {this.state.tempDevice?.endDate != null ?
                                    <div>
                                        Logger is not currently deployed - please select a site the logger is deployed to, and date deployed.<br/>
                                        If the site is not listed, visit Sites and create new site and allocate logger there.
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                    Site:&nbsp;
                                                    <Select
                                                        
                                                        options={this.state.sites}
                                                        defaultValue={this.state.tempDevice.site}
                                                        onChange={this.deviceSiteChanged}
                                                    />

                                            </Grid>
                                            <Grid item xs={12}>
                                                Assign from:&nbsp;<DateBox id="fromDate" displayFormat={"dd/MM/yyyy"} value={this.state.fromDate} onValueChanged={this.fromDateChanged.bind(this)} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                :
                                    <div>
                                        This logger is currently deployed to site {this.state.tempDevice?.siteName}. Please confirm it has been removed and will be marked as unallocated.<br/>
                                        Readings will no longer be associated with the site.
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                   Removal date:&nbsp;<DateBox id="fromDate" displayFormat={"dd/MM/yyyy"} value={this.state.fromDate} onValueChanged={this.fromDateChanged.bind(this)} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                        </Modal.Body>
                        <Modal.Footer>
                                <MaterialButton color="primary" onClick={(): void => this.deployConfirm()}>Confirm</MaterialButton>
                                <MaterialButton color="secondary" onClick={(): void => this.toggleDeployModal()}>Cancel</MaterialButton>
                        </Modal.Footer>
                        </Modal>
                        */}

                    {this.state.loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    }
                    {!this.state.loading &&
                        <div>

                            {this.state.tableHidden &&
                                    <ApiFailed />
                            }
                            {!this.state.tableHidden &&
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                        <CardHeader color="primary">
                                            <GridContainer>
                                                <GridItem xs={10} sm={10} md={10} >
                                                    <h4 className={classes.cardTitleWhite}>
                                                        <IconButton
                                                            style={{ display: 'inline' }}
                                                            onClick={(): void => this.props.history.goBack()}
                                                            size="large"><ArrowBackIcon style={{ fill: "white" }} /></IconButton>
                                                        Deployments for {this.props.location.state.serial}
                                                    </h4>
                                                    <p className={classes.cardCategoryWhite}>
                                                            Deployment history for logger
                                                    </p>
                                                </GridItem>
                                                <GridItem xs={2} sm={2} md={2}>
                                                {!this.state.deployed &&
                                                    <MaterialButton variant="contained" color="secondary" onClick={(): void => { this.toggleDeployModal(); }}>Deploy</MaterialButton>
                                                }
                                                </GridItem>
                                            </GridContainer>
                                            </CardHeader>
                                            <CardBody>
                                                <DataGrid
                                                dataSource={this.state.history}
                                                keyExpr="id"
                                                ref={this.gridRef}
                                                defaultFilterValue={this.state.filterValue}
                                                onExporting={this.onExporting} >
                                                <StateStoring enabled={true} type="localStorage" storageKey="historyListGrid" />
                                                <Export enabled={true} />
                                                <GroupPanel visible={true} />
                                                <Grouping autoExpandAll={true} />
                                                <FilterRow visible={true} />
                                                <Column dataField="id" visible={false} dataType="number" />
                                                <Column dataField="serial" dataType="string" sortOrder={'asc'} />
                                                <Column dataField="siteName" caption="Site Name"/>
                                                <Column dataField="siteRef" caption="Site Ref" />
                                                <Column dataField="site" dataType="string" caption="Device SiteId" />
                                                <Column dataField="startDate" dataType="datetime" customizeText={this.dateColumnCustomizeText} />
                                                <Column dataField="endDate" dataType="datetime" customizeText={this.dateColumnCustomizeText} />
                                                <Column dataField="lastDatapoint" dataType="datetime" customizeText={this.dateColumnCustomizeText} caption="Last Data point"/>

                                                <Column type="buttons" cellRender={this.moreRender.bind(this)} fixed={true} fixedPosition="right" />

                                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                <Paging defaultPageSize={10} />
                                                </DataGrid>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            }
                        </div>
                    }
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                        disableScrollLock={true}
                    >
                            <MenuItem onClick={this.toggleDeployModal}>
                                {this.state.tempDevice?.endDate != null ? "Deploy" : "Un-Deploy"}
                            </MenuItem>
                    </Menu>

                    <NotificationContainer />
                </div>)
                :
                 (<Redirect push to={{
                    pathname: this.state.redirectPath,
                    state: this.state.redirectProps
                }}/>)
            )
            :
            (
                <Redirect to={'/Login'} />
            );
    }
}

export default withStyles(style)(DeploymentList);

