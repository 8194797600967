import React, { useState, useRef, useEffect } from 'react'
import ClipLoader from "react-spinners/ClipLoader";
import GridContainer from "components/Grid/GridContainer.js";

import ApiFailed from '../../Utils/ApiFailed';
import { compose, spacing, palette, border } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Button } from "@mui/material"

import Modal from 'react-bootstrap/Modal';
import { APILoggerDetail, buildAPILoggerDetail } from '../../models/APILoggerDetail';

import { DataGrid, Column as GridColumn, Export, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring, HeaderFilter, Selection } from 'devextreme-react/data-grid';

import { check } from 'prettier';
import { ModalDialog } from 'react-bootstrap';


import './custom-modal.css'
import ReportScheduler from './ReportScheduler';
import { template } from '@babel/core';
interface DeviceReportProps {
    show: boolean;
    title: string;
    onCancel: () => void;
    loggerIds: Array<number>;
    deviceData: Array<DeviceData>;
    removeLogger?: (numberToRemove: number) => void;
    fromGroup: boolean
    templateClicked: boolean
}

interface DeviceData {
    id: number;
    serial: string;
    site: string;
    model: string;
    channels: string;
    lastConnected: Date | null;
    alarmState: string;
    siteId: number | null;
    siteName: string;
    siteRef: string;
    deployed: string;
    sendToLogger: boolean;
    updateRequested: Date | null;
    updateConfirmed: Date | null;
    firmwareToUpdate: number | null;
    firmwareSent: Date | null;
    firmwareConfirmed: Date | null;
    gpsLatitude: number | null;
    gpsLongitude: number | null;
    configurationId: string;
    undeploy: boolean;
    shutdown: boolean;
    dormant: boolean;
    company: string;
    modelId: string;
    channelsAvail: number;
    meterConfig: number;
}

//STATE 
const DeviceReport: React.FC<DeviceReportProps> = ({ show, onCancel, deviceData, loggerIds, removeLogger, fromGroup, templateClicked }) => {
    //const [loading, setLoading] = useState<boolean>(false)
    const [tableHidden, setTableHidden] = useState<boolean>(false)


    const Box = styled('div')(compose(spacing, palette));



    


   




//MAIN RENDER
    return (
        <div>
            
                <div>
                    {tableHidden &&
                        <ApiFailed />
                    }
                    {!tableHidden &&
                        <Box padding={1}>
                            
                            <GridContainer>
                                
                                <Modal show={show} style={{ zIndex: 1100, maxWidth: "90% !important", width: "100vw"}}>
                                        
                                    
                                        <ReportScheduler
                                    deviceData={deviceData}
                                    loggerIds={loggerIds}
                                    removeLogger={removeLogger}
                                    onCancel={onCancel}
                                    fromGroup={fromGroup}
                                    templateClicked={templateClicked}
                                        />
                                            
                                       
                                        
                                    </Modal>
                               
                                  
                            </GridContainer>
                        </Box>}
                </div>
        </div>
            
                
                
            
    )
}
export default DeviceReport