import React, { ReactNode } from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import Update from "@mui/icons-material/Update";

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';

import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';

const styles = createStyles({
    formControl: {
        minWidth: 120,
    },
    cardCategory: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgb(153,153,153)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
            paddingTop: "10px"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000000"
        }
    },
    cardTitle: {
        color: "#3C4858",
        minHeight: "auto",
        fontWeight: 300,
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    stats: {
        color: "#999",
        fontSize: "12px",
        lineHeight: "22px",
        display: "inline-flex"
    }
});

interface Props extends WithStyles<typeof styles>{
    id: string;
    color: string;
    icon: ReactNode;
    title: string;
    content: ReactNode;
    footerContent: ReactNode;
    refreshTime: number;
//    onRefresh: (() => Promise<ReactNode>) | undefined;
    onRefresh: string| undefined;
    onClick: ((tileName: string) => void) | undefined;
}

interface State {
    lastRefresh: Date | undefined;
    content: ReactNode;
}


export class GuageTile extends React.Component<Props, State> {
    interval: any;

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            lastRefresh: undefined,
            content: this.props.content
        };

    }

    componentDidMount(): void {

        if (this.props.onRefresh) {
            if (this.props.refreshTime > 0) {
                this.interval = setInterval((): void => { this.refresh(); }, this.props.refreshTime * 1000);
            }
            this.refresh();
        }

    }

    componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    refresh(): void  {
        const me = this;
        if (this.props.onRefresh) {
            eval(this.props.onRefresh + "()")
                .then((data: any) => { 
                    const ddata: any = data;
                    console.log("Guage Refresh: " + ddata?.props.children);
                    me.setState({ lastRefresh: new Date(Date.now()), content: data })
                })
                .catch((error: any) => {
                    console.log(error);
                    me.setState({ content: <div>?</div> });
                })

        }

    }

    onClick(): void {
        if (this.props.onClick)
            this.props.onClick(this.props.id);
    }


    render(): ReactNode {
        const { classes } = this.props;

        return (
            <div>
                <Card>
                    <CardHeader stats icon>
                        <CardIcon color={this.props.color}>
                            {this.props.icon}
                        </CardIcon>
                        <p className={classes.cardCategory}><b>{this.props.title}</b></p>
                    </CardHeader>
                    <div className={classes.cardTitle}>
                        {this.state.content}
                    </div>
                    <CardFooter stats>
                        <div className={classes.stats} onClick={(): void => this.refresh()}>
                            <Update /> Last refresh:{moment(this.state.lastRefresh).format("HH:mm:ss")}
                            {this.props.footerContent}
                        </div>
                    </CardFooter>
                </Card>
            </div>
        );
    
    }
}
export default withStyles(styles)(GuageTile);