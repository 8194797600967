import React, { ReactNode } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { withStyles, createStyles } from '@mui/styles';
import Grid from "@mui/material/Grid";

const styles = {
    grid: {
        padding: "0 15px !important"
    }
};

const style = createStyles(styles);

interface Props {
    children: Array<ReactNode>;
}

interface State {
    primary: number;
}

class Selector extends React.Component < Props, State > {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            primary: 0
        };

    }

    onClick(e: any): void {
        let selected = e.target.parentNode;
        while (!selected.id) {
            selected = selected.parentNode;
        }

        this.setState({ primary: selected.id });
        console.log("Making " + selected.id + " primary");
    }

    primaryChild(): ReactNode {

        const primary = this.props.children[this.state.primary];

        if (React.isValidElement(primary)) {
            return React.cloneElement(primary, { isprimary: "yes" } as React.Attributes);
        }
        else {
            return primary;
        }
    }


    render(): ReactNode {


        return (
            <Grid container>
                <Grid item className="grid" lg={8} md={8} sm={8}>
                    {this.primaryChild()}
                </Grid>
                <Grid item className="grid" lg={4} md={4} sm={4}>
                    <Grid container spacing={2} >
                        {
                            this.props.children.map((node, i): ReactNode => {
                                if (i != this.state.primary) {
                                    let child = node;
                                    if (React.isValidElement(node)) {
                                        child = React.cloneElement(node, { isprimary: "no" });
                                    }
                                    return <Grid item id={i.toString()} key={i} lg={12} md={12} sm={12} onClick={this.onClick.bind(this)}>
                                            {child}
                                           </Grid>
                                }
                                else {
                                    return <div key={i}></div>
                                }
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(Selector);
