import React, { useState, useCallback } from "react";
import { CreateUrl } from 'Utils/ApiHelper.js';
import Gallery from "react-photo-gallery";
import FadeIn from "react-fade-in";
import Carousel, { Modal, ModalGateway } from "react-images";
import MaterialButton from '@mui/material/Button';
import Box from '@mui/material/Box';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import UploadDialog from "./UploadDialog"

interface Photo {
    src: string;
    height: number;
    width: number;
    caption: string;
    Id: string;
}

interface TileGalleryProps {
    uploads: any[];
    removeImage: any;
    loggerId: number;
    reloadData: () => void;

}

interface CustomStyles {
    navigationPrev: (base: React.CSSProperties, state: any) => React.CSSProperties;
    navigation: (base: React.CSSProperties, state: any) => React.CSSProperties;
    navigationNext: (base: React.CSSProperties, state: any) => React.CSSProperties;
    container: (base: React.CSSProperties, state: any) => React.CSSProperties;
}

interface FooterProps {
    currentView?: {
        Id: string;
        caption: string;
        // Other properties of your currentView object
    };
}


//Main Function Component
const TileGallery: React.FC<TileGalleryProps> = ({ uploads, removeImage, loggerId, reloadData }) => {

//STATES    
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState<null | number>(null);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);


//VARIABLES
    let hasImages = false;
    let hasPdf = false;
    let useAzure = false;


//STYLES FUNCTION FOR REACT-IMAGES
    const customStyles: CustomStyles = {
        navigation: (base) => ({
            ...base,
            background: "none",
        }),
        container: (base) => ({
            ...base,
            width: "600px",
            
        }),
        navigationNext: (base) => ({
            ...base,
            backgroundColor: "#143C67",
            '&:hover': {
                backgroundColor: '#143C67',
                cursor: 'pointer',
                transform: 'scale(1.1)',
            },
        }),
        navigationPrev: (base) => ({
            ...base,
            backgroundColor: "#143C67",
            '&:hover': {
                backgroundColor: '#143C67',
                cursor: 'pointer',
                transform: 'scale(1.1)',
            },
        }),
    };


    // fUNCTIONS AND CALLBACKS
    const openLightbox = useCallback(
        (event, { index }) => {
            setCurrentImage(index);
            setViewerIsOpen(true);
        },
        []
    );

    const closeLightbox = (): void => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const confirmDelete = (Id: string): void => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            removeImage(Id);
            closeLightbox();   
        }
    };
    const confirmDeleteAzure = (Filename: string): void => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            removeImage(Filename);
            closeLightbox();
        }
    };


    const handleDownload = (url: string, filename: string, type: string): void => {
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                const file = new Blob([blob], { type: type });
                const element = document.createElement("a");
                element.href = URL.createObjectURL(file);
                element.download = filename;
                document.body.appendChild(element);
                element.click();
            })
                .catch(error => console.error('Error fetching the image:', error));
                
    };

    const handlePdfClick = (url: string): void => {
        window.open(url, '_blank');
    };

    const handleMouseEnter = (index: number): void => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = (): void => {
        setHoveredIndex(null);
    };

    const handleUploadButtonClick = (): void => {
        setUploadDialogOpen(true);
    };


//MAPS
    // PICTURES
    const photos: (Photo | null)[] = uploads?.map((obj: any, idx: number) => {
        if (obj.ContentType === "application/pdf") {
            return null;
        }
        hasImages = true;

       if (obj.Filename.indexOf("blob.core.windows.net") > 0) {
            useAzure = true;
        }
        if (useAzure) {
            return {
                src: obj.Filename,
                height: 9,
                width: 16,
                caption: obj.Caption,
                Id: obj.Id,
                Filename: obj.Filename
            }
        } else {
            return {
                src: CreateUrl('/api/aquaguard/Images/' + obj.Id),
                height: 9,
                width: 16,
                caption: obj.Caption,
                Id: obj.Id,
                Filename: obj.Filename
            }
        }
        
    });

    const filteredPhotos = photos.filter((photo) => photo !== null);


    // PDF'S
    const documents = uploads.map((obj: any, idx: number) => {
        if (obj.ContentType === "application/pdf") {
            hasPdf = true;
            const url = CreateUrl('/api/aquaguard/Images/' + obj.Id);
            return (
                <div
                    key={idx}
                    style={{ display: "inline-block", position: "relative", height: "150%" }}
                    onMouseEnter={(): void => handleMouseEnter(idx)}
                    onMouseLeave={handleMouseLeave}
                >
                    <iframe style={{ border: "none", marginTop: "2px", width: "95%", height: "15em", overflow: "hidden" }} src={`${url}#toolbar=0&navpanes=0&scrollbar=0`} />
                    {hoveredIndex === idx && (
                        <div style={{ position: "absolute", top: 0, left: 0, width: "95%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", background: "#00000090" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                {obj.Caption && <p style={{ textAlign: "center", color: "white" }}>{obj.Caption.length > 100 ? `${obj.Caption.slice(0, 100)}...` : obj.Caption}</p>}
                                <Box sx={{ '& button': { m: 1 } }}>
                                    <MaterialButton variant="contained" onClick={(): void => handlePdfClick(url)}>View</MaterialButton>
                                    {useAzure && (sessionStorage.getItem("userLevel") === "useradmin" || sessionStorage.getItem("userLevel") === "identityadmin") &&
                                        <MaterialButton variant="contained" color="error" onClick={(): void => confirmDeleteAzure(obj.Filename)}>Remove</MaterialButton>}
                                    {!useAzure && (sessionStorage.getItem("userLevel") === "useradmin" || sessionStorage.getItem("userLevel") === "identityadmin") &&
                                        <MaterialButton variant="contained" color="error" onClick={(): void => confirmDelete(obj)}>Remove</MaterialButton>}
                                </Box>
                                <Box sx={{ '& button': { m: 1 } }}>
                                    <MaterialButton variant="contained" color="warning" onClick={(): void => handleDownload(url, obj.Filename, 'application / pdf')}>Download</MaterialButton>    
                                </Box>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    });



    // main return
    return (
        <>
            <UploadDialog
                show={uploadDialogOpen}
                title="Upload Image"
                siteId={0}
                loggerId={loggerId}
                onSubmit={(): void => {
                    setUploadDialogOpen(false)
                    reloadData()
                    
                }}
                onCancel={(): void => setUploadDialogOpen(false)}
            />
        <GridContainer >
            {hasImages && <GridItem xs={12} sm={12} md={filteredPhotos.length > 1 && filteredPhotos.length < 2 || hasPdf ? 6 : 6} >
                <FadeIn>
                    <div className="homeContainer" >
                        <h2>Images</h2>
                        <Gallery photos={filteredPhotos as Photo[]} onClick={openLightbox} />
                        <ModalGateway>
                            {viewerIsOpen ? (
                                <Modal onClose={closeLightbox}>
                                    <Carousel
                                        currentIndex={currentImage}
                                            styles={customStyles}
                                        
                                        views={filteredPhotos.map(x => ({
                                            ...x,
                                            srcset: x.srcSet,
                                        }))}
                                        components={{
                                            Footer: ({ currentView }) => (
                                                <div style={{display: "flex", justifyContent: "space-around"} }>
                                                    <div style={{ margin: '0 auto', textAlign: 'center', position: "absolute", bottom: "50px"}}>
                                                        {currentView?.caption && <p style={{ color: 'white' }}>{currentView?.caption}</p>}
                                                        <Box sx={{ '& button': { m: 1 } }}>
                                                            <MaterialButton
                                                                variant="contained"
                                                                color="warning"
                                                                onClick={(): void => handleDownload(currentView?.src, currentView?.Filename, 'image / jpeg')}
                                                            >
                                                                Download
                                                            </MaterialButton>
                                                            {console.log('exists') }
                                                            {(sessionStorage.getItem("userLevel") === "useradmin" || sessionStorage.getItem("userLevel") === "identityadmin") &&
                                                                <MaterialButton variant="contained" color="error" onClick={(): void => confirmDelete(currentView)}>
                                                                    Remove
                                                                </MaterialButton>}
                                                        </Box>
                                                    </div>
                                                </div>
                                            ),

                                        }}
                                    />
                                </Modal>
                            ) : null}
                        </ModalGateway>
                    </div>
                </FadeIn>
            </GridItem>}
            {hasPdf && <GridItem xs={12} sm={12} md={6}>
                <h2>PDF&apos;s</h2>
                <div style={{ display: "flex", flexWrap: "wrap", overflowX: "auto" }}>
                    {documents}
                </div>
            </GridItem>}
            </GridContainer>
            <div style={{marginTop: "10px", marginBottom: "30px", marginLeft: "10px"} }>
            <MaterialButton
                variant="contained"
                onClick={handleUploadButtonClick}
            >
                Upload
                </MaterialButton>
            </div>
        </>
            );

}

export default TileGallery
