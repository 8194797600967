import * as React from "react";
import { Route, Switch } from "react-router-dom";

import PrivacyPolicy from 'views/LandingPage/PrivacyPolicy.tsx'
import { Callback } from "../components/auth/callback.jsx";
import { Logout } from "../components/auth/logout.jsx";
import { LogoutCallback } from "../components/auth/logoutCallback.jsx";
import { PrivateRoute } from "../routes/privateRoute.jsx";
import { SilentRenew } from "../components/auth/silentRenew.jsx";
import LandingPage from "../views/LandingPage/LandingPage.js";
import ForgotPassword from "../views/LandingPage/ForgotPassword.js";
import Register from "../views/LandingPage/Register";
import TermsAndConditions from "../views/LandingPage/TermsAndConditions";
import Welcome from "../views/LandingPage/Welcome";
import Portal from "../layouts/Portal.js";
import Lockout from "../views/LandingPage/LockoutPage";

/*
 * create the routes and the auth components associated to each route.
  * import { Register } from "../components/auth/register";
  *      <Route exact={true} path="/register" component={Register} />
 */

const authRoutes = () => (
    <Switch>
        <Route exact={true} path="/callback" component={Callback} />
        <Route exact={true} path="/logout" component={Logout} />
        <Route exact={true} path="/logout/callback" component={LogoutCallback} />
        <Route exact={true} path="/silentrenew" component={SilentRenew} />
        <PrivateRoute path="/portal" component={Portal} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/register" component={Register} />
        <Route path="/legal" component={TermsAndConditions} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/chariot" component={Welcome} />
        <Route path="/lockout" component={Lockout} />
        <Route path="/" component={LandingPage} />
        
    </Switch>
);

export default authRoutes;