export interface APILoggerDetail {
    LoggerId: number;

    Serial: number;

    Model: string;

    Channels: string;

    AlarmState: number;

    AlarmsEnabled: number;

    AlarmMessage0: string;

    AlarmMessage1: string;

    AlarmMessage2: string;

    AlarmMessage3: string;

    AlarmMessage4: string;

    AlarmMessage5: string;

    AlarmMessage6: string;

    AlarmMessage7: string;

    AlarmMessage8: string;

    AlarmMessage9: string;

    AlarmMessage10: string;

    AlarmMessage11: string;

    AlarmMessage12: string;

    AlarmMessage13: string;

    AlarmMessage14: string;

    AlarmMessage15: string;

    ModelId: string;     // Varient for TOR-N

    MeterConfig: number | null;

    ChannelsAvail: number | null;

    ChannelARef: string;
    ChannelBRef: string;
    ChannelCRef: string;
    ChannelDRef: string;
    ChannelERef: string;

    SiteName: string;
    SiteNameUserFriendly: string;

    Latitude: number | null;
    Longitude: number | null;

    FkSiteId: number | null;
}

export function buildAPILoggerDetail(r: any): APILoggerDetail {
    return {
        LoggerId: r.loggerId,
        Serial: r.serial,
        Model: r.model,
        Channels: r.channels,
        AlarmState: r.alarmState,
        AlarmsEnabled: r.alarmsEnabled,
        AlarmMessage0: r.alarmMessage0,
        AlarmMessage1: r.alarmMessage1,
        AlarmMessage2: r.alarmMessage2,
        AlarmMessage3: r.alarmMessage3,
        AlarmMessage4: r.alarmMessage4,
        AlarmMessage5: r.alarmMessage5,
        AlarmMessage6: r.alarmMessage6,
        AlarmMessage7: r.alarmMessage7,
        AlarmMessage8: r.alarmMessage8,
        AlarmMessage9: r.alarmMessage9,
        AlarmMessage10: r.alarmMessage10,
        AlarmMessage11: r.alarmMessage11,
        AlarmMessage12: r.alarmMessage12,
        AlarmMessage13: r.alarmMessage13,
        AlarmMessage14: r.alarmMessage14,
        AlarmMessage15: r.alarmMessage15,
        ModelId: r.modelId,
        MeterConfig: r.meterConfig,
        ChannelsAvail: r.channelsAvail,
        ChannelARef: r.channelARef ? r.channelARef : 'A',
        ChannelBRef: r.channelBRef ? r.channelBRef : 'B',
        ChannelCRef: r.channelCRef ? r.channelCRef : 'C',
        ChannelDRef: r.channelDRef ? r.channelDRef : 'D',
        ChannelERef: r.channelERef ? r.channelERef : 'E',
        SiteName: r.siteName,
        SiteNameUserFriendly: r.siteNameUserFriendly,
        Latitude: r.latitude,
        Longitude: r.longitude,
        FkSiteId: r.fkSiteId,
    };
}